import React, { ChangeEventHandler, useRef } from 'react';
import styles from './styles.module.css';
import mag_glass from '../../assets/icons/mag_glass.png';

interface SearchBarProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  className?: string;
}
const SearchBar = ({ className, placeholder = 'Search...', onChange }: SearchBarProps) => {
  const inputRef = useRef(null);

  const handleClick = () => inputRef.current.focus();

  return (
    <div className={`${className || ''} ${styles.main}`} onClick={handleClick}>
      <img src={mag_glass} alt="" />
      <input ref={inputRef} type="text" placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default SearchBar;
