import VytracLayout from 'components/VyTracLayout';
import ProviderProfileInner from './provider-profile-inner';
import { ProviderProfileCtxProvider } from './store/ProviderProfileContextProvider';

const ProviderProfile = () => {
  return (
    <VytracLayout selectedRoute="profile">
      <ProviderProfileCtxProvider>
        <ProviderProfileInner />
      </ProviderProfileCtxProvider>
    </VytracLayout>
  );
};
export default ProviderProfile;
