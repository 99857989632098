export enum EmailSettingType {
  invitation_provider = 'invitation_provider',
  invitation_patient = 'invitation_patient',
  verification_code = 'verification_code',
  password_recovery_provider = 'password_recovery_provider',
  password_recovery_patient = 'password_recovery_patient',
  first_login = 'first_login',
  custom = 'custom',
}

interface EmailSetting {
  id: number;
  type: EmailSettingType;
  subject: string;
  body: string;
  active: boolean;
  name: string;
  //json to parse from backend. We're saving state here so that we can reproduce the rich text from backend db saved data.
  editor_body?: string;
}

export default EmailSetting;
