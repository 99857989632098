interface ActivityPointsIconProps {
  fill?: string;
  height?: number;
  width?: number;
  className?: string;
}
const ActivityPointsIcon = ({
  fill = '#42DAB4',
  height = 94,
  width = 113,
  className = '',
}: ActivityPointsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 113 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M61.133 8.58159L56.5009 13.0498L51.8462 8.57071C47.039 3.93898 40.7546 0.994871 34.0008 0.210529C27.2471 -0.573813 20.4152 0.847052 14.6009 4.24527C8.78651 7.64348 4.32654 12.8222 1.93617 18.9508C-0.454208 25.0795 -0.636488 31.8031 1.41855 38.0433H21.1897L29.2111 21.747C29.9987 20.1465 31.2348 18.7894 32.7804 17.8283C34.326 16.8672 36.1197 16.3403 37.9597 16.307C39.7996 16.2737 41.6126 16.7352 43.1947 17.6396C44.7768 18.544 46.0652 19.8555 46.9148 21.4263L58.0996 42.1201L67.0079 30.9062C67.8974 29.7874 69.034 28.8726 70.3356 28.228C71.6373 27.5833 73.0714 27.2249 74.5346 27.1786C75.9977 27.1323 77.4533 27.3993 78.7961 27.9603C80.1389 28.5213 81.3355 29.3622 82.2995 30.4224L89.2307 38.0433H111.6C113.639 31.8015 113.443 25.0821 111.045 18.9598C108.647 12.8375 104.184 7.66602 98.3707 4.27236C92.5574 0.878702 85.7294 -0.541052 78.9787 0.24016C72.2279 1.02137 65.9444 3.95842 61.133 8.58159Z"
        fill={fill}
      />
      <path
        d="M53.4981 92.8077L16.3509 57.0677H27.4397C29.3124 57.0676 31.1466 56.5556 32.7288 55.5914C34.3109 54.6271 35.5758 53.2502 36.3762 51.6211L38.5511 47.2018L47.7249 64.1722C48.4926 65.5921 49.6203 66.8025 51.0034 67.6916C52.3865 68.5806 53.9804 69.1194 55.6374 69.2582C57.2945 69.3969 58.9611 69.1311 60.483 68.4853C62.0049 67.8395 63.3329 66.8347 64.3439 65.5638L75.3649 51.6917L77.2969 53.8117C79.1723 55.8773 81.8894 57.0623 84.7365 57.0623H96.6556L59.4859 92.8077C58.6915 93.5712 57.6147 94 56.492 94C55.3692 94 54.2924 93.5712 53.4981 92.8077Z"
        fill={fill}
      />
      <path
        d="M41.9369 23.9914C41.5809 23.3087 41.0327 22.7359 40.3549 22.3385C39.677 21.941 38.8968 21.7347 38.1031 21.7432C37.3093 21.7517 36.534 21.9746 35.8656 22.3865C35.1971 22.7984 34.6623 23.3828 34.3222 24.0729L24.7755 43.4785H4.25874C3.1351 43.4785 2.05749 43.9081 1.26296 44.6726C0.468428 45.4372 0.0220661 46.4741 0.0220661 47.5553C0.0220661 48.6366 0.468428 49.6735 1.26296 50.4381C2.05749 51.2026 3.1351 51.6321 4.25874 51.6321H27.4588C28.2611 51.6323 29.047 51.4132 29.725 51.0004C30.403 50.5876 30.9453 49.998 31.2887 49.3002L38.2594 35.1347L52.7206 62.9657C53.0457 63.5934 53.5336 64.1299 54.1372 64.5231C54.7407 64.9164 55.4395 65.1531 56.1659 65.2105C56.8924 65.2679 57.6219 65.144 58.284 64.8507C58.9461 64.5574 59.5184 64.1048 59.9456 63.5364L75.1863 43.2285L81.5695 50.2406C81.9665 50.6785 82.4561 51.0297 83.0055 51.2706C83.555 51.5115 84.1517 51.6367 84.7555 51.6376H108.763C109.887 51.6376 110.965 51.2081 111.759 50.4435C112.554 49.679 113 48.642 113 47.5608C113 46.4795 112.554 45.4426 111.759 44.678C110.965 43.9135 109.887 43.484 108.763 43.484H86.6761L78.0559 34.0095C77.6366 33.5482 77.1146 33.1839 76.5288 32.9435C75.943 32.7032 75.3084 32.593 74.6722 32.6212C74.036 32.6494 73.4146 32.8153 72.8542 33.1064C72.2938 33.3976 71.8089 33.8066 71.4354 34.303L57.172 53.3064L41.9425 23.9968L41.9369 23.9914Z"
        fill={fill}
      />
    </svg>
  );
};
export default ActivityPointsIcon;
