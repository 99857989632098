import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import {
  createQuestionnaire,
  deleteQuestionnaire,
  getAllQuestionnaries,
} from 'services/questionnaireService';
import { getResourceList } from 'services/resourcesService';
import ActionsCard from './components/ActionsCard';
import { IResourceResult } from 'types/Resources/Resources';
import { Col, Container, Row } from 'react-bootstrap';
import QuestionnaireModal from './components/QuestionnaireModal';
import { ActionCardType } from '../../util';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { setQuestionnaireList } from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';

const CalendarActions = () => {
  const {
    dispatch,
    templateState: {
      templateCarePlan: { questionnaireList },
    },
  } = useTemplateContext();
  const [resources, setResources] = React.useState<IResourceResult[]>([]);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState<boolean>(false);
  const [questionnaireModalAction, setQuestionnaireModalAction] = useState<'add' | 'delete'>(null);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const data = await getAllQuestionnaries();
        dispatch(setQuestionnaireList(data));
      } catch (err) {
        throw err;
      }
    };

    const fetchResources = async () => {
      let resourceList: IResourceResult[];
      try {
        resourceList = await getResourceList();
      } catch (err) {
        throw err;
      }
      setResources(resourceList);
    };

    fetchQuestionnaires();
    fetchResources();
  }, []);

  const onQuestionnaireAdd = () => {
    setQuestionnaireModalAction('add');
    setShowQuestionnaireModal(true);
  };

  const onQuestionnaireAddConfirm = async (elements, name: string, template_id: number) => {
    const newTemplateCopy = await createQuestionnaire(elements, name, template_id);
    const updatedQuestionnaireList = [...questionnaireList, newTemplateCopy.questionnaire];
    dispatch(setQuestionnaireList(updatedQuestionnaireList));
    setShowQuestionnaireModal(false);
  };

  const onQuestionnaireDelete = () => {
    setQuestionnaireModalAction('delete');
    setShowQuestionnaireModal(true);
  };

  const onQuestionnaireDeleteConfirm = async (questionnaireId: number) => {
    await deleteQuestionnaire(questionnaireId);
    const updatedQuestionnaireList = questionnaireList.filter(
      (questionnaire) => questionnaire.id !== questionnaireId
    );
    dispatch(setQuestionnaireList(updatedQuestionnaireList));
    setShowQuestionnaireModal(false);
  };

  const templateCopyQuestionnaires = useMemo(() => {
    return questionnaireList.filter((q) => q.template_id && !q.patient_id);
  }, [questionnaireList]);

  return (
    <Container className="px-0 py-4 ml-0" style={{ maxWidth: '1000px' }}>
      <Row>
        <Col xs={4} className="pl-0">
          <ActionsCard
            showDelete
            addElement={onQuestionnaireAdd}
            deleteElement={onQuestionnaireDelete}
            calendarActionType={ActionCardType.QUESTIONNAIRE}
            data={templateCopyQuestionnaires}
          />
        </Col>
        <Col xs={4} className="px-1">
          <ActionsCard
            calendarActionType={ActionCardType.MEDICATION}
            data={[]}
            addElement={() => null}
          />
        </Col>
        {/* <Col xs={4} className="pr-0">
          <ActionsCard
            addElement={() => null}
            calendarActionType={ActionCardType.RESOURCES}
            data={resources}
          />
        </Col> */}
      </Row>
      <QuestionnaireModal
        show={showQuestionnaireModal}
        onClose={() => setShowQuestionnaireModal(false)}
        action={questionnaireModalAction}
        onAdd={onQuestionnaireAddConfirm}
        onDelete={onQuestionnaireDeleteConfirm}
      />
    </Container>
  );
};

export default CalendarActions;
