import React from 'react';
import styles from './styles.module.css';
import {ReactComponent as ArrowIcon} from '../../../../../../../../../../assets/icons/arrow_left.svg';

const DateSelector = () => {
  return (
    <div className={styles.date}>
      <span>01.02.2021</span>
      <span>to</span>
      <span>05.11.2021</span>
      <ArrowIcon fill="#8FAAC3" style={{transform: 'rotate(90deg)'}} />
    </div>
  );
};

export default DateSelector;
