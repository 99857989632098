import Card from 'components/Card';
import React from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import styles from './styles.module.css';

interface IDateCard {
  date: DatePickerValue;
}
const DateCard = ({ date }: IDateCard) => {
  return (
    <Card bodyClassName={styles.dateCard}>
      {date.month}.{date.day}.{date.year}
    </Card>
  );
};
export default DateCard;
