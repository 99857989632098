import { useEffect, useState } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import EditIcon from 'assets/icons/edit.png';
import DeleteIcon from 'assets/icons/delete.png';
import styles from './styles.module.css';
import GenericButton from 'components/GenericButton';
import GenericTable from 'components/GenericTable';
import { getRuleList } from 'services/ruleService';
import GenericColumn from 'types/Shared/GenericColumn';

const columns: GenericColumn[] = [
  {
    title: 'name',
    key: 'name',
    render: ({ rule_name }) => (
      <span className={`cursor-pointer ${styles['rule-name']}`}>{rule_name}</span>
    ),
  },
  {
    title: 'used for',
    key: 'used-for',
    render: ({ used_for }) => used_for,
    textAlign: 'center',
  },
  {
    title: 'action',
    key: 'action',
    render: () => (
      <div className={styles.iconContainer}>
        <GenericButton icon={EditIcon} alt="edit" />
        <GenericButton icon={DeleteIcon} alt="delete" />
      </div>
    ),
    textAlign: 'center',
  },
  {
    title: 'status',
    key: 'status',
    render: ({ status }) => <FormCheck type="switch" checked={status} />,
    textAlign: 'end',
  },
];

const PlatformRulesBody = () => {
  const [ruleList, setRuleList] = useState([]);

  useEffect(() => {
    const fetchRules = async () => {
      try {
        const ruleList = await getRuleList();
        setRuleList(ruleList);
      } catch (exception) {
        console.error(exception);
      }
    };

    fetchRules();
  }, []);

  return <GenericTable columns={columns} data={ruleList} />;
};

export default PlatformRulesBody;
