import { FC } from 'react';
import styles from './styles.module.css';

interface TextSurroundedWithLinesProps {
  text: string;
  className?: string;
}

const TextSurroundedWithLines: FC<TextSurroundedWithLinesProps> = ({
  text,
  className,
}: TextSurroundedWithLinesProps) => {
  return (
    <p className={`m-0 p-0 ${styles.decorated} ${className}`}>
      <span>{text}</span>
    </p>
  );
};
export default TextSurroundedWithLines;
