import colors from 'styles/colors';

const vytracSelectStyle = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: colors.lightGray,
    outline: '0',
    '&:hover': {},
  }),
  placeholder: (base, state) => ({
    ...base,
    color: colors.lowRiskAlerts,
    fontSize: '11px',
  }),
  option: (base, state) => {
    return {
      ...base,
      fontSize: '11px',
    };
  },
};

export default vytracSelectStyle;
