import { takeActionOnAlert } from 'services/dashboardService';
import {
  IProviderAction,
  IProviderActionResponse,
  ProviderActionType,
} from 'types/ApiModels/Providers/ProviderAction';

export const onSubmit = async (
  values: Partial<IProviderAction>,
  actionType: ProviderActionType,
  alertId: number
): Promise<IProviderActionResponse> => {
  const providerAction: Partial<IProviderAction> = {
    type: actionType,
    alert: alertId,
  };

  switch (actionType) {
    case 'vytal_request':
      providerAction.vytal_type = values.vytal_type;
      break;
    case 'assign':
      providerAction.assign_to = values.assign_to;
      providerAction.note = values.note;
      break;
    case 'send_automated_chat':
      //TODO: attach message somewhere (not defined at the moment :/)
      break;
    case 'call_patient':
      //TODO: not defined at the moment
      break;
    case 'provide_questionnaire':
      providerAction.questionnaire_id = values.questionnaire_id;
      providerAction.note = values.note;
      break;
    case 'request_appointment':
    case 'complete':
      providerAction.note = values.note;
      //Do nothing extra
      break;
  }
  const alertResponse = await takeActionOnAlert(providerAction);
  return alertResponse;
};
