import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard/dashboard_icon.svg';
import { ReactComponent as PatientsIcon } from 'assets/icons/dashboard/patients_icon.svg';
import { ReactComponent as PatientCasesIcon } from 'assets/icons/dashboard/patient_cases_icon.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/dashboard/schedule_icon.svg';
import { ReactComponent as AppointmentsIcon } from 'assets/icons/dashboard/appointments_icon.svg';
import { ReactComponent as MessagesIcon } from 'assets/icons/dashboard/messages_icon.svg';
import { ReactComponent as TemplatesIcon } from 'assets/icons/dashboard/templates_icon.svg';
import { ReactComponent as RevenueManagementIcon } from 'assets/icons/dashboard/revenue_management_icon.svg';
import { ReactComponent as AdministrationIcon } from 'assets/icons/dashboard/administration_icon.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/dashboard/analytics_icon.svg';
import { ReactComponent as ResourcesIcon } from 'assets/icons/dashboard/resources_icon.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/dashboard/support_icon.svg';

// import ProviderDashboard from '../screens/ProviderDashboard';
import Patients from '../screens/Patients';
import Schedule from '../screens/Schedule';
import Appointments from '../screens/Appointments';
import Administration from '../screens/Administration';
import PatientCases from '../screens/PatientCases';
import Messages from '../screens/messages';
import Billing from '../screens/Billing';
import Analytics from '../screens/Analytics';
import Resources from '../screens/Resources';
import Support from '../screens/Support';
import Templates from '../screens/Templates';
import RouteItem from '../types/Shared/RouteItem';
import ProviderDashboard from '../screens/ProviderDashboard';
import ProviderProfile from 'screens/provider-profile';
import { PermissionKeys } from 'types/ApiModels/Administration';

//TODO: couldn't type this correctly bc ProviderDashboard is class comp??
// const routes: RouteItem[] = [
const routes: RouteItem[] = [
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: ProviderDashboard,
    icon: DashboardIcon,
    stroke: false,
    relatedPermission: PermissionKeys.DASHBOARD,
  },
  {
    path: '/patients',
    exact: false,
    name: 'Patients',
    component: Patients,
    icon: PatientsIcon,
    stroke: false,
    relatedPermission: PermissionKeys.PATIENTS,
  },
  {
    path: '/patient-cases',
    exact: true,
    name: 'Patient cases',
    component: PatientCases,
    icon: PatientCasesIcon,
    stroke: false,
    relatedPermission: PermissionKeys.PATIENT_CASES,
  },
  {
    path: '/schedule',
    exact: true,
    name: 'Schedule',
    component: Schedule,
    icon: ScheduleIcon,
    stroke: true,
    relatedPermission: PermissionKeys.SCHEDULE,
  },
  {
    path: '/appointments',
    exact: true,
    name: 'Appointments',
    component: Appointments,
    icon: AppointmentsIcon,
    stroke: false,
    relatedPermission: PermissionKeys.APPOINTMENTS,
  },
  {
    path: '/messages',
    exact: false,
    name: 'Messages',
    component: Messages,
    icon: MessagesIcon,
    stroke: false,
    relatedPermission: PermissionKeys.MESSAGES,
  },
  {
    path: '/templates',
    exact: false,
    name: 'Templates',
    component: Templates,
    icon: TemplatesIcon,
    stroke: true,
    relatedPermission: PermissionKeys.TEMPLATES,
  },
  {
    path: '/revenue-management',
    exact: false,
    name: 'Revenue Management',
    component: Billing,
    icon: RevenueManagementIcon,
    stroke: false,
    relatedPermission: PermissionKeys.REVENUE_MANAGEMENT,
  },
  {
    path: '/administration',
    exact: false,
    name: 'Administration',
    component: Administration,
    icon: AdministrationIcon,
    stroke: true,
    relatedPermission: PermissionKeys.ADMINISTRATION,
  },
  {
    path: '/analytics',
    exact: false,
    name: 'Analytics',
    component: Analytics,
    icon: AnalyticsIcon,
    stroke: true,
    relatedPermission: PermissionKeys.ANALYTICS,
  },
  {
    path: '/resources',
    exact: true,
    name: 'Resources',
    component: Resources,
    icon: ResourcesIcon,
    stroke: false,
    relatedPermission: PermissionKeys.RESOURCES,
  },
  {
    path: '/support',
    exact: true,
    name: 'Support',
    component: Support,
    icon: SupportIcon,
    stroke: false,
    relatedPermission: PermissionKeys.SUPPORT,
  },
  {
    path: '/profile',
    exact: false,
    name: 'Profile',
    component: ProviderProfile,
    hiddenFromNav: true,
  },
];

export default routes;
