import CompareBarGraph from 'components/CompareBarGraph/CompareBarGraph';
import FiltersButton from 'components/FiltersButton';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import useDevices from 'screens/Analytics/store/hooks/useDevices';
import { getAnalyticsDevicesCompare } from 'services/analyticsService';
import styles from '../styles.module.css';

const PatientDevices = () => {
  const [devicesList, __, refetch] = useDevices();
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [dataToModalCompare, setDataToModalCompare] = useState({ labels: [], datasets: [] });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (devicesList) setDevicesChart();
  }, [devicesList]);

  const setDevicesChart = () => {
    const data = {
      labels: devicesList.map((d) => d.model),
      datasets: [
        {
          data: devicesList.map((d) => d.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setData(data);
  };

  const setCompareDevices = (monthsInfo) => {
    const data = {
      labels: monthsInfo.map(x => { return `${x.model} Month:${x.month}`}),
      datasets: [
        {
          data: monthsInfo?.map((a) => a.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setDataToModalCompare(data);
  };

  const devicesSum = () => {
    let sum = 0;
    devicesList?.forEach((p) => (sum += p.count));
    return sum;
  };

  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const fetchDataforCompare = async (filters: string[]) => {
    const months = filters.map(month => Number.parseInt(month));
    const monthsInfo = await getAnalyticsDevicesCompare(months);
    setCompareDevices(monthsInfo);
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>Patients Devices</div>
        <div className={styles.controls}>
        <i onClick={() => setShowModal(!showModal)} className={styles.expandArrow}></i>
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      <div className={styles.bodyDevices}>
        <h6 className="d-flex ml-4 mt-3">
          {`${devicesSum()} devices`}
          <button className={`${styles.filterbutton} ml-3`}>Total</button>
          <button className={styles.filterbutton}>Assigned</button>{' '}
        </h6>
        <div className="d-flex ml-4"></div>

        <Bar options={options} data={data} />
      </div>
      <CompareBarGraph
        title="Compare Patients Devices"
        handleChange={(months) => {
          fetchDataforCompare(months);
        }}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        options={options}
        data={dataToModalCompare}
      ></CompareBarGraph>
    </div>
  );
};

export default PatientDevices;
