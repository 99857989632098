import React from 'react';
import {Tabs as BootstrapTabs, Tab as BootstrapTab} from 'react-bootstrap';
import tabNameMap from './util/tabNameMap';
import Tab from './components/Tab';
import tabEnum from './util/tabEnum';
import {useEffect} from 'react';
import filterFunctionMap from './util/filterFunctionMap';
const CodeTabs = ({codes}) => {
  const [activeTab, setActiveTab] = React.useState(tabEnum.ALL_CODES);
  const [allCodes, setAllCodes] = React.useState([]);
  const [readyToSubmitCodes, setReadyToSubmitCodes] = React.useState([]);
  const [submmitedCodes, setSubmittedCodes] = React.useState([]);
  const [paidCodes, setPaidCodes] = React.useState([]);

  const mapTabNameToCodes = (tabName) => {
    switch (tabName) {
      case tabEnum.ALL_CODES:
        return allCodes;
      case tabEnum.READY_TO_SUBMIT:
        return readyToSubmitCodes;
      case tabEnum.SUBMITTED:
        return submmitedCodes;
      case tabEnum.PAID:
        return paidCodes;
      default:
        return [];
    }
  };

  useEffect(() => {
    Object.keys(filterFunctionMap).forEach((key) => {
      const filterFunction = filterFunctionMap[key];
      const filteredCodes = filterFunction(codes);
      switch (key) {
        case tabEnum.ALL_CODES:
          setAllCodes(filteredCodes);
          break;
        case tabEnum.READY_TO_SUBMIT:
          setReadyToSubmitCodes(filteredCodes);
          break;
        case tabEnum.SUBMITTED:
          setSubmittedCodes(filteredCodes);
          break;
        case tabEnum.PAID:
          setPaidCodes(filteredCodes);
          break;
        default:
          break;
      }
    });
  }, [codes]);
  return (
    <BootstrapTabs
      id="patients-tab"
      className="tabs"
      onSelect={(tabKey) => setActiveTab(tabKey)}
      activeKey={activeTab}
    >
      {Object.values(tabEnum).map((tabKey) => {
        return (
          <BootstrapTab
            key={tabKey}
            tabClassName={activeTab === tabKey ? 'tab-selected' : 'tab'}
            eventKey={tabKey}
            title={`${tabNameMap[tabKey]} (${
              mapTabNameToCodes(tabKey)?.length
            })`}
          >
            <Tab data={mapTabNameToCodes(tabKey)} />
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
};

export default CodeTabs;
