import tabEnum from './tabEnum';

const tabNameMap = {
  [tabEnum.ALL_CODES]: 'all codes',
  [tabEnum.READY_TO_SUBMIT]: 'ready to submit',
  [tabEnum.SUBMITTED]: 'submitted',
  [tabEnum.PAID]: 'paid',
};

export default tabNameMap;
