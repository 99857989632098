import DeleteIcon from 'assets/icons/delete.png';
import EditIcon from 'assets/icons/edit.png';
import GenericButton from 'components/GenericButton';
import { FC } from 'react';

interface ActionButtonsProps {
  handleEdit: () => void;
  handleDelete: () => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
}

const EditDeleteActionButtons: FC<ActionButtonsProps> = ({
  handleEdit,
  handleDelete,
}: ActionButtonsProps) => {
  return (
    <div className="d-flex">
      <GenericButton icon={EditIcon} alt="edit" className="mr-3" onClick={handleEdit} />
      <GenericButton icon={DeleteIcon} alt="delete" onClick={handleDelete} />
    </div>
  );
};
export default EditDeleteActionButtons;
