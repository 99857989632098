import React, { useState } from 'react';
import { Tabs as BootstrapTabs, Tab as BootstrapTab } from 'react-bootstrap';
import Card from '../../../../../../../../components/Card';
import tabEnum from './utils/tabEnum';
import tabNameMap from './utils/tabNameMap';
import tabSubtitleMap from './utils/tabSubtitleMap';
import styles from './styles.module.css';
import tabComponentMap from './utils/tabComponentMap';

const data = {
  [tabEnum.ALL_CODES]: [
    [
      {
        code: '99453',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
      {
        code: '99454',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Not eligible',
      },
    ],
    [
      {
        code: '99490',
        value: '$250',
        timeSpent: '20min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
    ],
    [
      {
        code: '99981',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Not eligible',
      },
    ],
  ],
  [tabEnum.READY_TO_SUBMIT]: [
    [
      {
        code: '99453',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
    ],
    [
      {
        code: '99490',
        value: '$250',
        timeSpent: '20min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
    ],
    [],
  ],
  [tabEnum.SUBMITTED]: [
    [
      {
        code: '99453',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
      {
        code: '99454',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Not eligible',
      },
    ],
    [
      {
        code: '99490',
        value: '$250',
        timeSpent: '20min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
    ],
    [
      {
        code: '99981',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Not eligible',
      },
    ],
  ],
  [tabEnum.PAID]: [
    [
      {
        code: '99453',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
      {
        code: '99454',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Not eligible',
      },
    ],
    [
      {
        code: '99490',
        value: '$250',
        timeSpent: '20min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Eligible',
      },
    ],
    [
      {
        code: '99981',
        value: '$850',
        timeSpent: '120min',
        dueBillDate: '05.30.2021',
        lastBillDate: '04.29.2021',
        dateOfService: '05.30.2021',
        providers: '2 providers',
        requiredTime: '20 minutes',
        status: 'Not eligible',
      },
    ],
  ],
};

const CodeDetails = () => {
  const [activeTab, setActiveTab] = useState(tabEnum.ALL_CODES);

  return (
    <BootstrapTabs
      id="patients-tab"
      className={`tabs ${styles['tabs-container']}`}
      onSelect={(tabKey) => setActiveTab(tabKey)}
      activeKey={activeTab}
    >
      {Object.values(tabEnum).map((tabKey) => {
        const Component = tabComponentMap[tabKey];
        const numberOfElements = data[tabKey].flatMap((cat) => cat).length;
        return (
          <BootstrapTab
            key={tabKey}
            tabClassName={`${activeTab === tabKey ? 'tab-selected' : 'tab'} ${styles['tab-item']}`}
            eventKey={tabKey}
            title={`${tabNameMap[tabKey]} (${numberOfElements})`}
          >
            <Card
              headers={[`${tabSubtitleMap[tabKey]}`]}
              className={styles.container}
              bodyClassName={styles.cardBody}
            >
              <Component data={data[tabKey]} />
            </Card>
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
};

export default CodeDetails;
