import Button from 'components/Button';
import { SelectFormik } from 'components/FormikComponents';
import { IModalBody } from '../ActionModalBody';
import styles from './styles.module.css';
const automatedMessageOptions = [
  { label: 'Im not sure, please call me later', value: 1 },
  { label: 'I am not available today', value: 2 },
  { label: 'I am away right, now please contact me here ', value: 3 },
  { label: 'I am not available right now', value: 4 },
  { label: 'I am not available right now', value: 5 },
];

const SendAutomatedChatBody = ({ onCancel }: IModalBody) => {
  return (
    <div>
      <div className={`${styles['question']} mb-4`}>
        <div>Select message to send</div>
        <SelectFormik name={'note'} options={automatedMessageOptions} />
      </div>
      <div className="d-flex justify-content-between w-100">
        <Button label="Cancel" onClick={onCancel} variant="cancel" />
        <Button label="Confirm" type="submit" variant="confirm" />
      </div>
    </div>
  );
};
export default SendAutomatedChatBody;
