import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import TimePeriodDropdown from 'components/TimePeriodDropdown';

interface HeaderProps {
  auditNumber: number;
  onChange: (timePeriod: number) => void;
}
const Header = ({ auditNumber, onChange }: HeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className="d-flex gap align-items-center">
        <div>Audits ({auditNumber})</div>
        <div>
          <TimePeriodDropdown onChange={onChange} />
        </div>
      </div>
      <div className="d-flex gap">
        <SearchBar onChange={() => null} />
        <FiltersButton />
      </div>
    </div>
  );
};
export default Header;
