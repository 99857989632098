import React from 'react';
import Button from '../../../../Button';
import styles from './styles.module.css';

interface IActionModalFooter {
  onConfirm: () => void;
  onCancel: () => void;
}
const ActionModalFooter = ({ onConfirm, onCancel }: IActionModalFooter) => {
  return (
    <div className={styles.rowContainer}>
      <Button label="Cancel" variant="cancel" className={styles.button} onClick={onCancel} />
      <Button label="Confirm" variant="confirm" className={styles.button} onClick={onConfirm} />
    </div>
  );
};
export default ActionModalFooter;
