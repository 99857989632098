import { setBreadcrumTitle } from 'util/themeUtils';
import TabEnum from './TabEnum';
import TabRouteMap from './TabRouteMap';

export const templatesInitialBreadCrumbPathList = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'templates',
    url: TabRouteMap[TabEnum.CAREPLAN_TEMPLATES],
  },
];
