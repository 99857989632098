import { ReactComponent as DownArrow } from 'assets/icons/arrow_down.svg';
import { Dispatch, SetStateAction } from 'react';
import styles from './styles.module.css';

interface CollapseLineProps {
  toggleOpen: () => void;
  isOpen: boolean;
}

const CollapseLine = ({ toggleOpen, isOpen }: CollapseLineProps) => {
  return (
    <div className="cursor-pointer" onClick={() => toggleOpen()}>
      <div className={`${styles.collapseButton} px-2`}>
        <span>Collapse</span>
        <DownArrow
          fill="#CFD6E2"
          className={styles.arrow}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'initial',
            transition: 'transform 0.2s',
          }}
        />
        <hr className={styles.horizontalLine} />
      </div>
    </div>
  );
};

export default CollapseLine;
