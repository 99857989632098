/**
 * TODO: wire this service function to
 * the backend once the endpoint is available
 * and don't forget to use a try/catch block
 * for the http request and in the catch block
 * just propagate the exception to the upper level
 * where the function is being called.
 */
export const getRuleList = () =>
  Promise.resolve([
    {
      rule_id: 1,
      rule_name: 'Rule Name 1',
      used_for: 'Condition Name 1',
      status: true,
    },
    {
      rule_id: 2,
      rule_name: 'Rule Name 2',
      used_for: 'Condition Name 2',
      status: false,
    },
    {
      rule_id: 3,
      rule_name: 'Rule Name 3',
      used_for: 'Condition Name 3',
      status: true,
    },
    {
      rule_id: 4,
      rule_name: 'Rule Name 4',
      used_for: 'Condition Name 4',
      status: false,
    },
  ]);
