import Header from './Header';
import styles from './styles.module.css';
const CasesContainer = ({ children, title, className = '' }) => {
  return (
    <div className={`${styles.container}`}>
      <Header title={title} onFiltersClick={() => null} className="mb-3" />
      <div className={className}>{children}</div>
    </div>
  );
};
export default CasesContainer;
