export const patientSections = {
  PATIENTS_patient_status: {
    name: 'Patient Status',
    key: 'patientStatus',
  },
  PATIENTS_patient_timeline: {
    name: 'Patient Timeline',
    key: 'patientTimeline',
  },
  PATIENTS_team_notes: {
    name: 'Team Notes',
    key: 'teamNotes',
  },
  PATIENTS_care_plan: {
    name: 'Care Plan',
    key: 'carePlan',
  },
  PATIENTS_patient_reports: {
    name: 'Patient Reports',
    key: 'patientReports',
  },
  PATIENTS_encounters: {
    name: 'Encounters',
    key: 'encounters',
  },
  PATIENTS_goals_threshold: {
    name: 'Goals Threshold',
    key: 'goalsThreshold',
  },
  PATIENTS_questionnaires: {
    name: 'Questionnaires',
    key: 'questionnaires',
  },
  PATIENTS_patient_management: {
    name: 'Patient Management',
    key: 'patientManagement',
  },
};
export const administrationSections = {
  ADMINISTRATION_dashboard: {
    name: 'Dashboard',
    key: 'dashboard',
  },
  ADMINISTRATION_providers: {
    name: 'Providers',
    key: 'providers',
  },
  ADMINISTRATION_roles: {
    name: 'Roles',
    key: 'roles',
  },
  ADMINISTRATION_patients: {
    name: 'Patients',
    key: 'patients',
  },
  ADMINISTRATION_patient_settings: {
    name: 'Patient Settings',
    key: 'patientSettings',
  },
  ADMINISTRATION_forms: {
    name: 'Forms',
    key: 'forms',
  },
  ADMINISTRATION_email_settings: {
    name: 'Email Settings',
    key: 'emailSettings',
  },
  ADMINISTRATION_account_settings: {
    name: 'Account Settings',
    key: 'accountSettings',
  },
  ADMINISTRATION_reports: {
    name: 'Reports',
    key: 'reports',
  },
  ADMINISTRATION_rules_engine: {
    name: 'Rules Engine',
    key: 'rulesEngine',
  },
  ADMINISTRATION_messages: {
    name: 'Messages',
    key: 'messages',
  },
  ADMINISTRATION_care_plan_templates: {
    name: 'Care Plan Templates',
    key: 'carePlanTemplates',
  },
  ADMINISTRATION_audit_tracking: {
    name: 'Audit Tracking',
    key: 'auditTracking',
  },
};

export const templateSections = {
  TEMPLATES_care_plan_templates: {
    name: 'Care Plan Templates',
    key: 'carePlanTemplates',
  },
  TEMPLATES_questionnaires: {
    name: 'Questionnaires',
    key: 'questionnaires',
  },
  TEMPLATES_surveys: {
    name: 'Surveys',
    key: 'surveys',
  },
};

export const sectionsByType = {
  patients: patientSections,
  administration: administrationSections,
  templates: templateSections,
};
