import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const QuestionnaireIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.72222 1.2V0.5H7.27778V1.2V1.7H7.77778H9.44833L9.44842 1.7C9.4497 1.69998 9.45809 1.69987 9.47145 1.7143C9.48577 1.72977 9.5 1.75741 9.5 1.7958V11.4037C9.49997 11.4411 9.48585 11.47 9.47138 11.4856C9.46454 11.493 9.4586 11.4965 9.45494 11.4982C9.45137 11.4998 9.4491 11.5 9.44779 11.5H0.552208C0.550905 11.5 0.548629 11.4998 0.545057 11.4982C0.541404 11.4965 0.535459 11.493 0.528621 11.4856C0.514172 11.47 0.500072 11.4412 0.5 11.4039V1.7958C0.5 1.75741 0.514229 1.72977 0.528547 1.7143C0.54191 1.69987 0.550305 1.69998 0.551577 1.7C0.551612 1.7 0.551642 1.7 0.551667 1.7H2.22222H2.72222V1.2ZM2.72222 2.4V1.9H2.22222H1.11111H0.611111V2.4V10.8V11.3H1.11111H8.88889H9.38889V10.8V2.4V1.9H8.88889H7.77778H7.27778V2.4V3.1H2.72222V2.4ZM7.16667 1.2V0.7H6.66667H3.33333H2.83333V1.2V2.4V2.9H3.33333H6.66667H7.16667V2.4V1.2ZM2.83333 8.9V9.1H2.72222V8.9H2.83333ZM2.83333 7.1V7.3H2.72222V7.1H2.83333ZM2.83333 5.3V5.5H2.72222V5.3H2.83333Z"
        stroke={color}
      />
    </svg>
  );
};

export default QuestionnaireIcon;
