import sortEnum from './sortEnum';
export const defaultSorting = (patientA, patientB) =>
  patientA.firstName - patientB.firstName;

export const sortingFunctionMap = {
  [sortEnum.ALL_ELIGIBILITIES]: (patientA, patientB) => {},
  [sortEnum.BILLED]: (patientA, patientB) => {},
  [sortEnum.PAID]: (patientA, patientB) => {},
  [sortEnum.READY_TO_SUBMIT]: (patientA, patientB) => {},
  [sortEnum.SUBMITTED]: defaultSorting,
};
