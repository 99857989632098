import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';

export enum PatientQuestionnairesActionTypes {
  SET_QUESTIONNAIRE_LIST = 'SET_QUESTIONNAIRE_LIST',
  SET_AVAILABLE_QUESTIONNAIRES = 'SET_AVAILABLE_QUESTIONNAIRES',
  SET_TEMPLATE_QUESTIONNAIRES = 'SET_TEMPLATE_QUESTIONNAIRES',
  SET_SELECTED_QUESTIONNAIRE = 'SET_SELECTED_QUESTIONNAIRE',
  TOGGLE_CREATE_NEW = 'TOGGLE_CREATE_NEW',
}

export type PatientQuestionnaireAction =
  | {
      type: PatientQuestionnairesActionTypes.SET_QUESTIONNAIRE_LIST;
      payload: { questionnaireList: IPatientQuestionnaire[] };
    }
  | {
      type: PatientQuestionnairesActionTypes.SET_AVAILABLE_QUESTIONNAIRES;
      payload: { availableQuestionnaires: IPatientQuestionnaire[] };
    }
  | {
      type: PatientQuestionnairesActionTypes.SET_TEMPLATE_QUESTIONNAIRES;
      payload: { templateQuestionnaires: IQuestionnaire[] };
    }
  | {
      type: PatientQuestionnairesActionTypes.SET_SELECTED_QUESTIONNAIRE;
      payload: { selectedQuestionnaire: IPatientQuestionnaire };
    }
  | {
      type: PatientQuestionnairesActionTypes.TOGGLE_CREATE_NEW;
      payload: { createNew: boolean };
    };
