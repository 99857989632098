import tabEnum from './tabEnum';
import CarePlanActions from '../components/PatientCarePlan/Actions';
import GoalsThresholdsActions from '../components/GoalsThreshold/Actions';
import PatientManagementActions from '../components/PatientManagement/Actions';
import QuestionnaireActions from '../components/Questionnaires/Actions';
import EmergencyButton from '../../EmergencyButton';

const tabActionsComponentMap = {
  [tabEnum.PATIENT_STATUS]: EmergencyButton,
  [tabEnum.PATIENT_TIMELINE]: EmergencyButton,
  [tabEnum.TEAM_NOTES]: EmergencyButton,
  [tabEnum.CARE_PLAN]: CarePlanActions,
  [tabEnum.PATIENT_MESSAGES]: EmergencyButton,
  [tabEnum.PATIENT_REPORTS]: EmergencyButton,
  [tabEnum.ENCOUNTERS]: EmergencyButton,
  [tabEnum.GOALS_THRESHOLD]: GoalsThresholdsActions,
  [tabEnum.QUESTIONNAIRES]: QuestionnaireActions,
  [tabEnum.PATIENT_MANAGEMENT]: PatientManagementActions,
};

export default tabActionsComponentMap;
