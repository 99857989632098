import React from 'react';
import AddIcon from '../../assets/icons/addCircleButton.png';
import styles from './style.module.css';
const AddButton = ({onClick}) => {
  return (
    <img
      onClick={onClick}
      src={AddIcon}
      alt="Add"
      className={styles.addButton}
    />
  );
};

export default AddButton;
