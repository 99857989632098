import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import {
  PatientQuestionnaireAction,
  PatientQuestionnairesActionTypes,
} from './patientQuestionnairesActionTypes';

export interface IPatientQuestionnaresState {
  questionnaireList: IPatientQuestionnaire[];
  availableQuestionnaires: IPatientQuestionnaire[];
  templateQuestionnaires: IQuestionnaire[];
  selectedQuestionnaire: IPatientQuestionnaire;
  createNew: boolean;
}

export const patientQuestionnaireInitialState: IPatientQuestionnaresState = {
  questionnaireList: [],
  availableQuestionnaires: [],
  templateQuestionnaires: [],
  selectedQuestionnaire: null,
  createNew: false,
};

const patientQuestionnairesReducer = (
  state: IPatientQuestionnaresState,
  actions: PatientQuestionnaireAction
): IPatientQuestionnaresState => {
  switch (actions.type) {
    case PatientQuestionnairesActionTypes.SET_QUESTIONNAIRE_LIST: {
      return { ...state, questionnaireList: actions.payload.questionnaireList };
    }

    case PatientQuestionnairesActionTypes.SET_AVAILABLE_QUESTIONNAIRES: {
      return { ...state, availableQuestionnaires: actions.payload.availableQuestionnaires };
    }

    case PatientQuestionnairesActionTypes.SET_TEMPLATE_QUESTIONNAIRES: {
      return { ...state, templateQuestionnaires: actions.payload.templateQuestionnaires };
    }

    case PatientQuestionnairesActionTypes.SET_SELECTED_QUESTIONNAIRE: {
      return { ...state, selectedQuestionnaire: actions.payload.selectedQuestionnaire };
    }

    case PatientQuestionnairesActionTypes.TOGGLE_CREATE_NEW: {
      return { ...state, createNew: actions.payload.createNew };
    }
  }
};
export default patientQuestionnairesReducer;
