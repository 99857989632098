export enum MarkType {
  MEDICATION_CHANGE = 'Medication Change',
  HOSPITAL_ADMISSION = 'Hospital Admission',
  PATIENT_HOLD = 'Patient Hold/Pause (ex. vacation)',
  ER_VISIT = 'ER Visit',
  CARE_TRANSITION = 'Care Transition',
  PATIENT_REACTIVATED = 'Patient "Reactivated"',
  OTHER_CUSOTMIZABLE = 'Other/Customizable',
  TBD_ADD_TO_ANALYTICS = 'TBD - Add to analytics',
}
