import Button from 'components/Button';
import React from 'react';

interface IRecurrenceConfirmationModalBody {
  onAllEvents: () => void;
  onOnlyThisEvent: () => void;
}
const RecurrenceConfirmationModalBody = ({
  onAllEvents,
  onOnlyThisEvent,
}: IRecurrenceConfirmationModalBody) => {
  return (
    <div>
      <div
        style={{
          fontSize: '12px',
          paddingBottom: '35px',
        }}
      >
        Do you want to apply this change to all events in this series?
      </div>
      <div className="d-flex justify-content-between">
        <Button label="all events" variant="confirm" onClick={onAllEvents} />
        <Button label="only this event" variant="confirm" onClick={onOnlyThisEvent} />
      </div>
    </div>
  );
};
export default RecurrenceConfirmationModalBody;
