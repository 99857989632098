import { iconType } from 'types/Patients/constants';

export const rpmActionToUnit: Record<iconType, string> = {
  oxygen_level: '%',
  bpm: 'BPM',
  blood_pressure: '',
  weight: 'LBS',
  temperature: 'F',
  stairs: '',
  steps: '',
  sleep: 'hours',
  blood_sugar: '',
  stress_level: '',
  check_up: '',
  questionnaire: '',
  upload_file: '',
  select_from_patient_documents: '',
  medication: '',
};
