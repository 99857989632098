import Button from 'components/Button';
import VytracModal from 'components/Modal';
import { useCallback } from 'react';
import {
  deleteForm,
  setOpenDeleteFormModal,
} from 'screens/Administration/store/Forms/formActionCreators';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { deleteAdminForm } from 'services/administrationService';
import styles from './styles.module.css';

const DeleteAdminFormModal = () => {
  const [{ openDeleteFormModal, currentForm }, dispatch] = useAdminForms();

  const handleClose = useCallback(() => {
    dispatch(setOpenDeleteFormModal(false));
  }, [dispatch]);

  const handleDeleteForm = useCallback(async () => {
    await deleteAdminForm(currentForm.id);
    dispatch(deleteForm(currentForm.id));
    handleClose();
  }, [dispatch, handleClose, currentForm]);

  return (
    <VytracModal
      centered
      show={openDeleteFormModal}
      onClose={handleClose}
      title="Are you sure?"
      body={
        <>
          <div className={styles['text']}>
            Are you sure you want to delete this form? This action is permanent and cannot be
            undone.
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Button label="cancel" variant="cancel" onClick={handleClose} />
            <Button label="Confirm" variant="confirm" onClick={handleDeleteForm} />
          </div>
        </>
      }
    />
  );
};
export default DeleteAdminFormModal;
