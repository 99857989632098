import React, { ReactNode } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import Card from '../Card';

/**
 * Returns a React Component that has the layout of vytrac's graph builders. (e.g. Questionnaire Builder)
 */
interface IGraphBuilder {
  actions: () => void;
  elements: any;
  setElements: any;
  title: string;
  children: ReactNode;
  containerClassName?: string;
}
const GraphBuilder = ({
  actions = () => null,
  elements = [],
  setElements = () => null,
  title = '',
  children,
  containerClassName = '',
}: IGraphBuilder) => {
  const BuilderHeader = (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div>{title}</div>
      {actions()}
    </div>
  );

  return (
    <Card
      headers={[BuilderHeader]}
      bodyClassName="h-100 w-100"
      className={`d-flex flex-column flex-grow-1 ${containerClassName}`}
    >
      <ReactFlowProvider>{children}</ReactFlowProvider>
    </Card>
  );
};
export default GraphBuilder;
