import VyTracModal from 'components/Modal';
import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { FormCheck } from 'react-bootstrap';
import InputField from 'components/InputField';
import Button from 'components/Button';
import TagsManagement from './TagsManagement';

interface CodesConfigModalProps {
  handleClose: () => void;
  handleChange: (month) => void;
  showModal: boolean;
}

const CodesConfigModal: FC<CodesConfigModalProps> = ({
  handleClose,
  handleChange,
  showModal,
}: CodesConfigModalProps) => {
  const [selectedBasicRule, setSelectedBasicRule] = useState<boolean>(true);

  const tagsOptions = {
    category: [
      {
        id: 1,
        name: 'RPM',
        is_active: true,
      },
    ],
    insurer_modifier: [
      {
        id: 1,
        name: 'Veterans Association',
        is_active: true,
      },
    ],
    activities: [],
    population: [],
    provider_title: [
      {
        id: 1,
        name: 'Physician',
        is_active: true,
      },
      {
        id: 2,
        name: 'NPP',
        is_active: true,
      },
    ],
    time_required: [
      {
        id: 1,
        name: '20 minutes',
        is_active: true,
      },
    ],
    repeatable: [
      {
        id: 1,
        name: 'Yes',
        is_active: true,
      },
    ],
    timeframe: [
      {
        id: 1,
        name: 'Every 30 days',
        is_active: true,
      },
    ],
  };

  return (
    <VyTracModal
      show={showModal}
      onClose={handleClose}
      title={
        <div className={styles.container}>
          <InputField
            value={null}
            onChange={(e) => {}}
            className={styles.codeField}
            labelClassName={styles.currentScheduleLabel}
            containerClassName={styles.currentScheduleContainer}
            name="scheduleName"
            required
          />
          <FormCheck type="switch" checked={true} />
        </div>
      }
      size="lg"
      bodyClassName={styles.modalBody}
      body={
        <div className="d-flex flex-column gap">
          <div className={styles.rowContainer}>
            <div className={styles.content}>
              <Button
                className={styles.button}
                color="#282556"
                label="Basic rules"
                onClick={() => {
                  setSelectedBasicRule(true);
                }}
                selected={selectedBasicRule}
              />
              <Button
                className={styles.button}
                color="#282556"
                label="advanced rules"
                onClick={() => {
                  setSelectedBasicRule(false);
                }}
                selected={!selectedBasicRule}
              />
            </div>
          </div>
          {selectedBasicRule ? (
            <div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Category</div>
                <div className={styles.content}>
                  <TagsManagement multiple={false} options={tagsOptions.category} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Insurer modifier</div>
                <div className={styles.content}>
                  <TagsManagement multiple={false} options={tagsOptions.insurer_modifier} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Activities</div>
                <div className={styles.content}>
                  <TagsManagement multiple={true} options={tagsOptions.activities} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Population</div>
                <div className={styles.content}>
                  <TagsManagement multiple={true} options={tagsOptions.population} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Provider title</div>
                <div className={styles.content}>
                  <TagsManagement multiple={true} options={tagsOptions.provider_title} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Time required</div>
                <div className={styles.content}>
                  <TagsManagement multiple={false} options={tagsOptions.time_required} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Repeatable</div>
                <div className={styles.content}>
                  <TagsManagement multiple={false} options={tagsOptions.repeatable} />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.title}>Timeframe</div>
                <div className={styles.content}>
                  <TagsManagement multiple={false} options={tagsOptions.timeframe} />
                </div>
              </div>
            </div>
          ) : (
            <div>advanced rules</div>
          )}
        </div>
      }
      footer={
        <div className="d-flex w-100 px-3 justify-content-between">
          <Button label="cancel" variant="cancel" />
          <Button label="save changes" variant="confirm" onClick={() => {}} />
        </div>
      }
    />
  );
};
export default CodesConfigModal;
