import FiltersButton from 'components/FiltersButton';

const AssignedPatientsHeader = ({ count }) => {
  return (
    <div className="d-flex w-100 justify-content-between">
      <div>Assigned patients ({count})</div>
      <div className="d-flex">
        <div className="text-primary d-flex align-items-center mr-2 cursor-pointer">
          Assign patient
        </div>
        <FiltersButton onFiltersClick={() => null} />
      </div>
    </div>
  );
};
export default AssignedPatientsHeader;
