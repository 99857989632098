import { FC } from 'react';
import AllDayActivityItem from './AllDayActivityItem';
import styles from '../../ActivityItems.module.css';
import { Activity } from 'types/Patients/PatientTimeline/ActivityTimeline';

interface AllDayActivityItemProps {
  allDayItems: Activity[];
  currentIdx: number;
  isFirst: boolean;
  isLast: boolean;
  fractions: Record<number, number[]>;
}

const AllDayActivityItems: FC<AllDayActivityItemProps> = ({
  allDayItems,
  fractions,
  currentIdx,
  isFirst,
  isLast,
}: AllDayActivityItemProps) => {
  return (
    <>
      {allDayItems.map((adi) => (
        <AllDayActivityItem
          key={`all-day-item-${adi.id}`}
          chunk={fractions[adi.id][currentIdx]}
          label={adi.type}
          renderLabel={isFirst}
          className={
            isFirst
              ? `${styles['range-activity-border-left']} rounded-left`
              : isLast
              ? `${styles['range-activity-border-right']} rounded-right`
              : ''
          }
        />
      ))}
    </>
  );
};

export default AllDayActivityItems;
