import { Field, useFormikContext } from 'formik';
import { FormCheck } from 'react-bootstrap';

const fieldName = 'isAppUser';
const PatientAccessSwitch = () => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <div className={`d-flex py-3 w-100 font-size-medium gap-lg align-items-center`}>
      <p className="m-0 p-0">Patient has access to the mobile or web platform</p>
      <div className="d-flex align-items-center">
        <p className="m-0 pr-2">No</p>
        <Field name={fieldName}>
          {({ field }) => (
            <FormCheck
              id={fieldName}
              type="switch"
              checked={field.value}
              onChange={() => {
                setFieldTouched(fieldName, true);
                setFieldValue(fieldName, !field.value);
              }}
            />
          )}
        </Field>
        <p className="m-0 p-0">Yes</p>
      </div>
    </div>
  );
};
export default PatientAccessSwitch;
