import { CareTeamProvider } from 'types/ApiModels/CareTeam';
import CareTeamTier from 'types/ApiModels/CareTeam/CareTeamTier';

export enum careTeamColumnKey {
  name = 'name',
  team_lead = 'team_lead',
  members = 'members',
  patients_count = 'patients_count',
  providers_count = 'providers_count',
  action = 'action',
  id = 'id',
}

export interface CareTeamRow {
  [careTeamColumnKey.id]: number;
  [careTeamColumnKey.name]: string;
  [careTeamColumnKey.team_lead]: CareTeamProvider;
  [careTeamColumnKey.members]: CareTeamProvider[];
  [careTeamColumnKey.patients_count]: number;
  [careTeamColumnKey.providers_count]: number;
}

export interface CareTeamCreateEditFormikFields {
  id: number;
  name: string;
  tiers: CareTeamTier[];
}
