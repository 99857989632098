import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';

const PatientManagementInput = ({ value, type = 'text', name, onChange = () => {}, error }) => {
  const [backgroundColor, setBackgroundColor] = useState('#F8F9FB');

  const handleFocus = () => setBackgroundColor('#FFFFFF');

  const handleBlur = () => setBackgroundColor('#F8F9FB');

  return (
    <div className={styles.font}>
      <Form.Control
        style={{
          backgroundColor,
          borderColor: !!error ? 'red' : undefined,
        }}
        className={styles.font}
        onFocus={handleFocus}
        onBlur={handleBlur}
        size="sm"
        type={type}
        value={value}
        name={name}
        onChange={onChange}
      />
      {error && <span style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{error}</span>}
    </div>
  );
};

export default PatientManagementInput;
