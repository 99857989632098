import DashboardAdherence from 'types/ApiModels/Dashboard/DashboardAdherence';
import { DashboardEscalated } from 'types/ApiModels/Dashboard/DashboardEscalated';
import DashboardOutstandingChats from 'types/ApiModels/Dashboard/DashboardOutstandingChats';
import DashboardRedAlert from 'types/ApiModels/Dashboard/DashboardRedAlert';
import DashboardRedAlertDetail from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';
import DashboardSchedule from 'types/ApiModels/Dashboard/DashboardSchedule';
import { IDashboardToDo, IDashboardToDoSingle } from 'types/ApiModels/Dashboard/DashboardToDo';
import { DashboardStickynotes } from 'types/ApiModels/Dashboard/DashboardStickynotes';
import { DashboardUrgency } from 'types/ApiModels/Dashboard/DashboardUrgency';
import DashboardYellowAlert from 'types/ApiModels/Dashboard/DashboardYellowAlert';
import DashboardYellowAlertDetail from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { PaginatedResponse } from 'types/ApiModels/General';
import { DashboardAction, DashboardTypes } from './DashboardActionTypes';
import IVytalChart from 'types/Dashboard/VytalChart';
import { IAdherenceGraphic } from 'types/Dashboard/AdherenceGraphic';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import { ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { CurrentProviderCareTeam } from 'types/ApiModels/CareTeam';

export const addTrackerTime = (): DashboardAction => ({
  type: DashboardTypes.ADD_TRACKER_TIME,
});

export const toggleTimeIsTracking = (isTracking: boolean): DashboardAction => ({
  type: DashboardTypes.TOGGLE_IS_TRACKING,
  payload: { isTracking },
});

export const changeSelectedTimer = (patientId: number): DashboardAction => ({
  type: DashboardTypes.CHANGE_SELECTED_TIMER,
  payload: { patientId },
});

export const setTrackingActivityId = (activityId: number): DashboardAction => ({
  type: DashboardTypes.SET_TRACKING_ACTIVITY_ID,
  payload: { activityId },
});

export const resetTrackingDuration = (): DashboardAction => ({
  type: DashboardTypes.RESET_TRACKING_DURATION,
});

export const setTrackingType = (trackingType: TrackingType): DashboardAction => ({
  type: DashboardTypes.SET_TRACKING_TYPE,
  payload: { trackingType },
});

export const setToDoItems = (toDoItems: IDashboardToDo[]): DashboardAction => ({
  type: DashboardTypes.SET_TODO_ITEMS,
  payload: { toDoItems },
});

export const setToDoItemsSingle = (
  toDoItemsSingle: Record<number, IDashboardToDoSingle>
): DashboardAction => ({
  type: DashboardTypes.SET_TODO_ITEMS_SINGLE,
  payload: { toDoItemsSingle },
});

export const setRedAlerts = (
  redAlerts: Record<number, PaginatedResponse<DashboardRedAlert>>
): DashboardAction => ({
  type: DashboardTypes.SET_RED_ALERTS,
  payload: { redAlerts },
});

export const setYellowAlerts = (yellowAlerts: DashboardYellowAlert[]): DashboardAction => ({
  type: DashboardTypes.SET_YELLOW_ALERTS,
  payload: { yellowAlerts },
});

export const setOutstandingChats = (
  outstandingChats: Record<number, PaginatedResponse<DashboardOutstandingChats>>
): DashboardAction => ({
  type: DashboardTypes.SET_OUTSTANDING_CHATS,
  payload: { outstandingChats },
});

export const setAdherence = (
  adherence: Record<number, PaginatedResponse<DashboardAdherence>>
): DashboardAction => ({
  type: DashboardTypes.SET_ADHERENCE,
  payload: { adherence },
});

export const setUrgency = (
  urgency: Record<number, PaginatedResponse<DashboardUrgency>>
): DashboardAction => ({
  type: DashboardTypes.SET_URGENCY,
  payload: { urgency },
});

export const setEscalated = (
  escalated: Record<number, PaginatedResponse<DashboardEscalated>>
): DashboardAction => ({
  type: DashboardTypes.SET_ESCALATED,
  payload: { escalated },
});

export const setSchedule = (
  schedule: Record<number, PaginatedResponse<DashboardSchedule>>
): DashboardAction => ({
  type: DashboardTypes.SET_SCHEDULE,
  payload: { schedule },
});

export const setStickynotes = (
  stickynotes: Record<number, PaginatedResponse<DashboardStickynotes>>
): DashboardAction => ({
  type: DashboardTypes.SET_STICKYNOTES,
  payload: { stickynotes },
});

export const setAdherenceGraphic = (adherenceGraphic: IAdherenceGraphic[]): DashboardAction => ({
  type: DashboardTypes.SET_ADHERENCE_GRAPHIC,
  payload: { adherenceGraphic },
});

export const setSelectedRedAlert = (
  selectedRedAlert: DashboardRedAlertDetail | null
): DashboardAction => ({
  type: DashboardTypes.SET_SELECTED_RED_ALERT,
  payload: { selectedRedAlert },
});

export const setSelectedYellowAlert = (
  selectedYellowAlert: DashboardYellowAlertDetail | null
): DashboardAction => ({
  type: DashboardTypes.SET_SELECTED_YELLOW_ALERT,
  payload: { selectedYellowAlert },
});

export const setSelectedAdherence = (selectedAdherence: DashboardAdherence): DashboardAction => ({
  type: DashboardTypes.SET_SELECTED_ADHERENCE,
  payload: { selectedAdherence },
});

export const setVytalChart = (vytalChart: IVytalChart, patientId: number): DashboardAction => ({
  type: DashboardTypes.SET_VYTAL_CHART,
  payload: { vytalChart, patientId },
});

export const setTotalPatients = (totalPatients: number): DashboardAction => ({
  type: DashboardTypes.SET_TOTAL_PATIENTS,
  payload: { totalPatients },
});

export const addSingleToDo = (toDoSingle: IDashboardToDoSingle): DashboardAction => ({
  type: DashboardTypes.ADD_SINGLE_TODO,
  payload: { toDoSingle },
});

export const setShowActionModal = (
  showActionModal: boolean,
  actionType: ProviderActionType,
  openedAlertId: number
): DashboardAction => ({
  type: DashboardTypes.SET_SHOW_ACTION_MODAL,
  payload: { showActionModal, actionType, openedAlertId },
});

export const setQuestionnaireTemplates = (
  questionnaireTemplates: Record<number, PaginatedResponse<IQuestionnaire>>
): DashboardAction => ({
  type: DashboardTypes.SET_QUESTIONNAIRE_TEMPLATES,
  payload: { questionnaireTemplates },
});

export const setPatientQuestionnaires = (
  patientQuestionnaires: IPatientQuestionnaire[],
  patientId: number
): DashboardAction => ({
  type: DashboardTypes.SET_PATIENT_QUESTIONNAIRES,
  payload: { patientQuestionnaires, patientId },
});

export const setCurrentToDo = (currentTodo: IDashboardToDoSingle): DashboardAction => ({
  type: DashboardTypes.SET_CURRENT_TODO,
  payload: { currentTodo },
});

export const setCurrentProviderCareTeam = (
  currentProviderCareTeam: CurrentProviderCareTeam
): DashboardAction => ({
  type: DashboardTypes.SET_CURRENT_PROVIDER_CARE_TEAM,
  payload: { currentProviderCareTeam },
});
