import { VytalType } from 'types/ApiModels/Patients/Vytal';

type GoalLabelType =
  | 'All'
  | 'Weight'
  | 'Pulse Oxygen'
  | 'Heart Rate'
  | 'Temperature'
  | 'Steps'
  | 'Stairs'
  | 'Sleep'
  | 'Blood Pressure'
  | 'Glucose Level';

export const mapGoalToLabel = (goal: VytalType): GoalLabelType => {
  switch (goal) {
    case VytalType.WEIGHT:
      return 'Weight';
    case VytalType.OXYGEN_LEVEL:
      return 'Pulse Oxygen';
    case VytalType.HEART_RATE:
      return 'Heart Rate';
    case VytalType.TEMPERATURE:
      return 'Temperature';
    case VytalType.STEPS:
      return 'Steps';
    case VytalType.STAIRS:
      return 'Stairs';
    case VytalType.SLEEP:
      return 'Sleep';
    case VytalType.BLOOD_PRESSURE:
      return 'Blood Pressure';
    case VytalType.BLOOD_SUGAR:
      return 'Glucose Level';
    default:
      return 'All';
  }
};

export const mapLabelToGoal = (label: GoalLabelType): VytalType => {
  switch (label) {
    case 'Weight':
      return VytalType.WEIGHT;
    case 'Pulse Oxygen':
      return VytalType.OXYGEN_LEVEL;
    case 'Heart Rate':
      return VytalType.HEART_RATE;
    case 'Temperature':
      return VytalType.TEMPERATURE;
    case 'Steps':
      return VytalType.STEPS;
    case 'Stairs':
      return VytalType.STAIRS;
    case 'Sleep':
      return VytalType.SLEEP;
    case 'Blood Pressure':
      return VytalType.BLOOD_PRESSURE;
    case 'Glucose Level':
      return VytalType.BLOOD_SUGAR;
    default:
      return null;
  }
};
