import React from 'react';
import UserBubble from '../../../../../../../../components/UserBubble';
import styles from './styles.module.css';

const BillingProvider = ({ patient }) => {
  // Functions and subroutines
  const createProviderList = (patient) => [
    {
      firstName: 'Thomas',
      lastName: 'Wayne',
    },
    {
      firstName: 'Martha',
      lastName: 'Wayne',
    },
    {
      firstName: 'Thomas',
      lastName: 'Wayne',
    },
  ];

  // Variables and constants
  const providerList = createProviderList(patient);
  return (
    <div className="dashboard-detail">
      <div className="dashboard-item-header font-weight-md">Billing providers</div>
      <div className={styles.body}>
        {providerList.map((provider, idx) => (
          <UserBubble
            user={provider}
            containerClassNameList={['ml-0', styles['user-bubble']]}
            //TODO: add a better key (surely there's gonna be a provider id)
            key={`${provider.firstName}-${provider.lastName}-${idx}`}
          />
        ))}
      </div>
    </div>
  );
};

export default BillingProvider;
