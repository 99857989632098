import Card from 'components/Card';
import { useState } from 'react';
import { PointsMultiplier as PointsMultiplierType } from 'types/ActivityPoints';
import { PointsMultiplierItem } from './points-multiplier-item';

const PointsMultiplier = () => {
  const [pointsMultipliers, setPointsMultipliers] = useState<PointsMultiplierType[]>([
    {
      days: 2,
      multiplier: 0.05,
    },
    {
      days: 5,
      multiplier: 0.1,
    },
    { days: 10, multiplier: 0.15, current: true },
    { days: 15, multiplier: 0.2 },
    { days: 30, multiplier: 0.3 },
  ]);

  return (
    <Card headers={['Activity points multiplier']} className="bg-white">
      {pointsMultipliers.map((pointsMultiplier, index) => (
        <PointsMultiplierItem
          key={index}
          days={pointsMultiplier.days}
          multiplier={pointsMultiplier.multiplier}
          current={pointsMultiplier.current}
        />
      ))}
    </Card>
  );
};
export default PointsMultiplier;
