import DatePicker from 'components/DatePicker';
import Select from 'components/form/Select';
import {
  ActionTypeLabel,
  mapActionTypeToLabel,
  mapLabelToActionType,
} from 'components/RPM/utils/typeMapper';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import styles from './styles.module.css';
import Checkbox from 'components/form/Checkbox';
import RecurrentOptions from 'components/RPM/ActionModal/components/ActionModalBody/components/RecurrentOptions';
import DatePickerValue from 'types/Shared/DatePicker';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { ActionModalType } from 'components/RPM/ActionModal/CarePlanActionModal';
import RelativeDateSelector from 'components/RelativeDateSelector';
import { getAbsoluteDate, getRelativeDate } from 'util/calendarUtils/transformers';
import { getAllQuestionnaries } from 'services/questionnaireService';
import { useFetchList } from 'hooks';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';

interface IRecurrenceEditionModalBody {
  data: IPatientCarePlanAction;
  setData: Dispatch<SetStateAction<IPatientCarePlanAction>>;
  modalType: ActionModalType;
  relativeMinDate: Date;
  patientId: number;
}

const vitalsOptions = [...Object.values(ActionTypeLabel)];

const RecurrenceEditionModalBody = ({
  setData,
  data,
  modalType,
  relativeMinDate,
  patientId,
}: IRecurrenceEditionModalBody) => {
  const [allQuestionnaires] = useFetchList(getAllQuestionnaries);

  const questionnaireList = useMemo(() => {
    if (modalType === 'patient') {
      return allQuestionnaires.filter((questionnaire) => (questionnaire.patient_id = patientId));
    } else {
      return allQuestionnaires.filter((questionnaire) => questionnaire.template_id);
    }
  }, [modalType]);

  const onTypeChange = (newType: ActionTypeLabel) => {
    setData({
      ...data,
      type: mapLabelToActionType(newType),
    });
  };

  const onAbsolutStartDateChange = (date: DatePickerValue) => {
    const newStartDate = moment(convertDatepickToDate(date));
    if (data.event_type === 'goal') {
      const prevStartDate = moment(data.start_date);
      const prevEndDate = moment(data.end_date);
      const dayDiff = newStartDate.diff(prevStartDate, 'days');
      const endDate = prevEndDate.clone().add(dayDiff, 'days');
      setData({ ...data, start_date: newStartDate.toISOString(), end_date: endDate.toISOString() });
    } else {
      setData({ ...data, start_date: newStartDate.toISOString() });
    }
  };

  const onRelativeStartDateChange = (newRelativeTime: IRelativeTime) => {
    const newAbsoluteDate = getAbsoluteDate(newRelativeTime, relativeMinDate);
    setData({
      ...data,
      start_date: newAbsoluteDate.toISOString(),
    });
  };

  return (
    <div className="font-size-big">
      <div className={styles.rowContainer}>
        <div>Type</div>
        <div className={styles.formEditor}>
          <Select
            currentValue={mapActionTypeToLabel(data?.type)}
            options={vitalsOptions}
            onChange={(e) => onTypeChange(e.target.value as ActionTypeLabel)}
          />
        </div>
      </div>
      <div className={`${styles.rowContainer} ${styles.border} pt-3`}>
        <div>Occurs on</div>
        {data?.start_date && (
          <div className={styles.formEditor}>
            {modalType === 'patient' ? (
              <DatePicker
                placeHolder="Select Date"
                value={convertDateToDatePickValue(new Date(data?.start_date), true)}
                style={{ maxHeight: 19.5 }}
                onChange={onAbsolutStartDateChange}
                hasFooter={!data?.recurrence?.multiple_times_a_day}
              />
            ) : (
              <RelativeDateSelector
                value={getRelativeDate(data?.start_date, relativeMinDate)}
                onChange={onRelativeStartDateChange}
                containerClassName="w-100 px-2"
              />
            )}
          </div>
        )}
      </div>
      {modalType === 'patient' && (
        <div className={`${styles.rowContainer} ${styles.border}`}>
          <div>Status</div>
          <div className={styles.formEditor}>
            <Form.Switch
              id="status-switch"
              label="Active"
              className="d-flex align-items-center"
              checked={data?.is_active}
              onChange={() => setData({ ...data, is_active: !data.is_active })}
            />
          </div>
        </div>
      )}

      {data?.event_type !== 'goal' && (
        <div
          className={`${styles.rowContainer} ${styles.border}`}
          style={{ backgroundColor: '#f8f9fb', padding: '0 20px' }}
        >
          <RecurrentOptions
            data={data}
            setData={setData}
            modalType={modalType}
            questionnaireList={questionnaireList}
          />
        </div>
      )}

      {modalType === 'patient' && (
        <div className={styles.rowContainer}>
          <Checkbox
            onClick={() => setData({ ...data, is_part_of_rpm_plan: !data.is_part_of_rpm_plan })}
            value={data?.is_part_of_rpm_plan}
            label="Add as part of Care Plan"
          />
        </div>
      )}
    </div>
  );
};
export default RecurrenceEditionModalBody;
