import { ControlButtonLink } from 'components/ControlButton';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { adminPatientSettingsControls } from './utils';

const AdminPatientSettings = () => {
  const { url, path } = useRouteMatch();
  return (
    <div className="container-with-padding d-flex gap flex-column">
      <div className="d-flex gap">
        {adminPatientSettingsControls.map((aspc) => (
          <ControlButtonLink
            key={aspc.enumValue}
            to={`${url}${aspc.route ? '/' + aspc.route : ''}`}
          >
            {aspc.label}
          </ControlButtonLink>
        ))}
      </div>

      <Switch>
        <Redirect from={`${path}`} to={`${path}/population`} exact />
        {adminPatientSettingsControls.map((apsc) => (
          <Route path={`${path}/${apsc.route}`} key={apsc.enumValue}>
            <apsc.component />
          </Route>
        ))}
      </Switch>
    </div>
  );
};

export default AdminPatientSettings;
