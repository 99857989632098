import React, { CSSProperties } from 'react';
import { resolveClassName } from '../util/utils';

export interface Tag {
  text?: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
  onHover?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  style?: CSSProperties;
  icon?: JSX.Element;
}

export const Tagitem = ({
  text,
  className = '',
  style = undefined,
  icon = undefined,
  onClick = () => null,
  onHover = () => null,
  onMouseLeave = () => null,
}: Tag) => (
  <div
    onClick={onClick}
    onMouseOver={onHover}
    onMouseLeave={onMouseLeave}
    className={`tag-container ${className || ''}`}
    style={style}
  >
    {icon ? (
      <div className="d-flex gap-sm justify-content-between align-items-center">
        <div>{icon}</div>
        <div>{text}</div>
      </div>
    ) : (
      text
    )}
  </div>
);

interface IRoundTagItem {
  text?: string;
  img?: string;
  containerClassNameList?: string[];
  imgClassNameList?: string[];
  onRemove?: () => void;
  onAdd?: () => void;
  includeMargin?: boolean;
}

export const RoundTagitem = ({
  text = '?',
  containerClassNameList = [],
  img = null,
  imgClassNameList = [],
  onRemove = null,
  onAdd = null,
  includeMargin = true,
}: IRoundTagItem) => (
  <>
    {img ? (
      <img src={img} className={resolveClassName(imgClassNameList, 'rounded-circle')} alt={text} />
    ) : (
      <div
        className={`${resolveClassName(
          containerClassNameList,
          onRemove == null
            ? onAdd == null
              ? 'tag-container-round'
              : 'tag-container-round-addable'
            : 'tag-container-round-removable'
        )}${includeMargin ? ' tag-container-round-margin' : ''}`}
      >
        {text}
      </div>
    )}
  </>
);
