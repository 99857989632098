import { PatientQuickFilter } from 'screens/Patients/Content/components/PatientListWithFilters/QuickFilters';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import { PatientListAction, PatientListActionTypes } from './patientListActionType';

export const setPatientCompleteList = (completeList: PatientGet[]): PatientListAction => ({
  type: PatientListActionTypes.SET_COMPLETE,
  payload: {
    completeList,
  },
});

export const setListFilter = (filter: PatientQuickFilter): PatientListAction => ({
  type: PatientListActionTypes.SET_FILTER,
  payload: {
    filter,
  },
});

export const setSearchValue = (searchValue: string): PatientListAction => ({
  type: PatientListActionTypes.SET_SEARCH_VALUE,
  payload: {
    searchValue,
  },
});

export const setPatientPageList = (pageList: PatientGet[][]): PatientListAction => ({
  type: PatientListActionTypes.SET_PAGE,
  payload: {
    pageList,
  },
});
