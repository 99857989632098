import VytracSpinner from 'components/vytrac-spinner';
import { useEffect, useMemo, useRef } from 'react';
import Populations from 'screens/ProviderDashboard/Populations';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import { YellowAlert } from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { iconType } from 'types/Patients/constants';
import styles from './styles.module.css';
import YellowIncident from './YellowIncident';
interface YellowAlertPopupProps {
  onClickOutSide?: () => void;
}

export interface ConsecutiveYellowAlerts {
  alertType: iconType;
  title: string;
  alerts: {
    creationDate: string;
    description: string;
    status: string;
    alertType: iconType;
  }[];
}

const YellowAlertPopup = ({ onClickOutSide }: YellowAlertPopupProps) => {
  const {
    state: { selectedYellowAlert },
  } = useDashboardContext();

  const ref = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      event.stopPropagation();
      if (ref && ref.current && !ref.current.contains(event.target)) {
        onClickOutSide?.();
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
      ref.current = null;
    };
  }, [ref, onClickOutSide]);

  const alertsByType = useMemo(() => {
    if (!selectedYellowAlert) return {};
    const alertsByType: { [key: string]: YellowAlert[] } = {};
    selectedYellowAlert.alerts.forEach((alert) => {
      if (!alertsByType[alert.vytal.type]) {
        alertsByType[alert.vytal.type] = [];
      }
      alertsByType[alert.vytal.type].push(alert);
    });
    return alertsByType;
  }, [selectedYellowAlert]);

  return (
    <>
      {selectedYellowAlert ? (
        <div className={styles['popup']} ref={ref}>
          <Populations
            birth_date={selectedYellowAlert.birth_date}
            phone_number={selectedYellowAlert.phone_number}
            email={selectedYellowAlert.email}
            populations={selectedYellowAlert.populations}
            user_id={selectedYellowAlert.id}
          />
          {Object.keys(alertsByType).map((alertType) => (
            <YellowIncident
              iconType={alertType as iconType}
              consecutiveAlerts={alertsByType[alertType]}
              key={alertType}
            />
          ))}
        </div>
      ) : (
        <div className={`${styles['popup']} justify-content-center d-flex`} ref={ref}>
          <VytracSpinner />
        </div>
      )}
    </>
  );
};
export default YellowAlertPopup;
