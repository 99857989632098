import moment from 'moment';
import React from 'react';
import UserBubble from 'components/UserBubble';
import phoneCall from 'assets/icons/phone_call.png';

import styles from './styles.module.css';

export default function CallMessage({ message, className, timeStampClassName, from }) {
  const formatDuration = (duration) => {
    const minutes = duration.split(':')[0];
    const seconds = duration.split(':')[1];
    return minutes + 'm ' + seconds + 's';
  };
  return (
    <div className={className}>
      <div>
        <UserBubble user={from} />
      </div>
      <div className={styles.iconContainer}>
        <img src={phoneCall} alt="phone call" className={styles.icon} />
      </div>

      <div className="d-flex flex-column">
        <span>Call lasted {formatDuration(message.duration)} seconds</span>
        <span className={timeStampClassName}>{moment(message.createdAt).format('h:mm a')}</span>
      </div>
    </div>
  );
}
