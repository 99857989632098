import moment from 'moment';
import React from 'react';
import styles from './styles.module.css';
import voiceIcon from 'assets/icons/phone_call_white.png';
import videoIcon from 'assets/icons/video_white.png';
const Appointment = ({ from, to, subject, note, type }) => {
  //If appointment is in the past sets opacity to 0.5
  const isBefore = to < moment().get('hour');

  //Formats hours to AM/PM
  const formatHour = (hour) => {
    return hour < 12 ? `${hour !== 0 ? hour : 12} AM` : `${hour - 12} PM`;
  };

  const renderIcon = (type) => {
    switch (type) {
      case 'voice':
        return <img src={voiceIcon} alt="voice" />;
      case 'video':
        return <img src={videoIcon} alt="video" />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.appointment} style={{ opacity: isBefore ? 0.5 : 1 }}>
      <div className={styles.dotContainer}>
        <div className={styles.dot}></div>
      </div>
      <div className="d-flex flex-column">
        <div>
          <span>{formatHour(from)}</span> - <span>{formatHour(to)}</span>
          <span className={styles.icon}>{renderIcon(type)}</span>
        </div>
        <div className={styles.subject}>{subject}</div>
        <div className={styles.note}>{note}</div>
      </div>
    </div>
  );
};

export default Appointment;
