import React from 'react';
import * as d3 from 'd3';
const Slice = ({pie, radius, colors}) => {
  const arc1 = d3.arc().innerRadius(0).outerRadius(radius);
  const arc2 = d3
    .arc()
    .innerRadius(0)
    .outerRadius(radius - 3);

  return pie.map((slice, index) => {
    if (slice.value === 0) return null;
    let sliceColor = colors[index];
    return (
      <path
        d={index === 0 ? arc1(slice) : arc2(slice)}
        fill={sliceColor}
        key={index}
      />
    );
  });
};

export default Slice;
