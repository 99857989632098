import { NavLink } from 'react-router-dom';
import styles from './styles.module.css';

interface ControlTabLinkProps {
  to: string;
  children: string;
  className?: string;
  exact?: boolean;
}
const ControlTabLink = ({ to, children, className, exact }: ControlTabLinkProps) => (
  <NavLink
    activeClassName={styles['selected']}
    className={`${styles['button-control']} ${className || ''}`}
    exact={exact}
    to={to}
  >
    {children}
  </NavLink>
);
export default ControlTabLink;
