import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import {
  IPatientCarePlan,
  IPatientCarePlanAction,
  IPatientTimelineAction,
} from 'types/ApiModels/Patients/CarePlan';
import { PatientCarePlanAction, PatientCarePlanActionTypes } from './patientCarePlanActionType';

/**
 * @param carePlanState care plan of the patient
 * @param previousPatientActions actions before any modification or creation modals were opened
 * @param selectedDate current date of the calendar
 * @param patientActions actions associated with a patient (regardless if they are part of the care plan)
 * @param timelineActions patient actions and actions created from recurrence
 * @param selectedAction actions being displayed on a modal
 * @param periodSelected time period selected of the calendar
 * @param carePlanModalEventType string value to display on the title on the creation modal
 */
export interface IPatientCarePlanState {
  carePlanState: IPatientCarePlan;
  previousPatientActions: IPatientCarePlanAction[];
  selectedDate: Date;
  patientActions: IPatientCarePlanAction[];
  timelineActions: IPatientTimelineAction[];
  selectedAction: IPatientTimelineAction;
  periodSelected: TimePeriodOptions;
  carePlanModalEventType: CarePlanActionEventType;
}
export const patientCarePlanInitialState: IPatientCarePlanState = {
  carePlanState: {
    rpm_actions: [],
  },
  previousPatientActions: [],
  selectedDate: new Date(),
  patientActions: [],
  timelineActions: [],
  selectedAction: null,
  periodSelected: TimePeriodOptions.WEEK,
  carePlanModalEventType: null,
};

const patientCarePlanReducer = (
  state = patientCarePlanInitialState,
  action: PatientCarePlanAction
) => {
  switch (action.type) {
    case PatientCarePlanActionTypes.SET_CAREPLAN_STATE: {
      return { ...state, carePlanState: action.payload.carePlanState };
    }
    case PatientCarePlanActionTypes.SET_PREVIOUS_PATIENT_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.previousPatientActions = [...state.patientActions];
      return stateCopy;
    }
    case PatientCarePlanActionTypes.SET_SELECTED_DATE: {
      const stateCopy = { ...state };
      stateCopy.selectedDate = action.payload.selectedDate;
      return stateCopy;
    }
    case PatientCarePlanActionTypes.SET_PATIENT_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.patientActions = action.payload.patientActions;
      return stateCopy;
    }
    case PatientCarePlanActionTypes.SET_TIMELINE_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.timelineActions = action.payload.timelineActions;
      return stateCopy;
    }
    case PatientCarePlanActionTypes.SET_PERIOD_SELECTED: {
      const stateCopy = { ...state };
      stateCopy.periodSelected = action.payload.timePeriod;
      return stateCopy;
    }
    case PatientCarePlanActionTypes.SET_SELECTED_ACTION: {
      const stateCopy = { ...state };
      stateCopy.selectedAction = action.payload.selectedAction;
      return stateCopy;
    }
    case PatientCarePlanActionTypes.SET_CAREPLAN_MODAL_EVENT_TYPE: {
      const stateCopy = { ...state };
      stateCopy.carePlanModalEventType = action.payload.carePlanModalEventType;
      return stateCopy;
    }

    default:
      return state;
  }
};

export default patientCarePlanReducer;
