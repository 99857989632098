import {
  IWebSocketAdherenceAlertMessage,
  IWebSocketQuestionnaireAlertMessage,
  IWebSocketVytalAlertMessage,
} from 'types/WebSocket/WebSocket';
import { WebSocketAlertAction, WebSocketAlertActionTypes } from './webSocketAlertsActionTypes';

export interface WebSocketAlertsState {
  redAlert: IWebSocketVytalAlertMessage;
  yellowAlert: IWebSocketVytalAlertMessage;
  criticalAlert: IWebSocketVytalAlertMessage;
  adherenceAlert: IWebSocketAdherenceAlertMessage;
  questionnaireAlert: IWebSocketQuestionnaireAlertMessage;
}

export const alertsInitialState: WebSocketAlertsState = {
  redAlert: null,
  yellowAlert: null,
  criticalAlert: null,
  adherenceAlert: null,
  questionnaireAlert: null,
};

const adminPatientsReducer = (
  state: WebSocketAlertsState,
  action: WebSocketAlertAction
): WebSocketAlertsState => {
  switch (action.type) {
    case WebSocketAlertActionTypes.RECEIVE_RED_ALERT:
      return {
        ...state,
        redAlert: action.payload.alert,
      };
    case WebSocketAlertActionTypes.RECEIVE_YELLOW_ALERT:
      return {
        ...state,
        yellowAlert: action.payload.alert,
      };
    case WebSocketAlertActionTypes.RECEIVE_CRITICAL_ALERT:
      return {
        ...state,
        criticalAlert: action.payload.alert,
      };
    case WebSocketAlertActionTypes.RECEIVE_ADHERENCE_ALERT:
      return {
        ...state,
        adherenceAlert: action.payload.alert,
      };
    case WebSocketAlertActionTypes.RECEIVE_QUESTIONNAIRE_ALERT:
      return {
        ...state,
        questionnaireAlert: action.payload.alert,
      };
    default:
      return state;
  }
};

export default adminPatientsReducer;
