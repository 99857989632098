import CompareBarGraph from 'components/CompareBarGraph/CompareBarGraph';
import DateRangePicker from 'components/DateRangePicker';
import FiltersButton from 'components/FiltersButton';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import usePatientAccrued from 'screens/Analytics/store/hooks/usePatientsAccrued';
import { getAnalyticsAccruedPatientsCompare } from 'services/analyticsService';
import styles from '../styles.module.css';

const PatientsAccrued = () => {
  const [date, setDate] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  });

  const [patientsAccrued, __, refetch] = usePatientAccrued(date.from, date.to);
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [dataToModalCompare, setDataToModalCompare] = useState({ labels: [], datasets: [] });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (patientsAccrued) setPatientsAccrued();
  }, [patientsAccrued]);

  const setPatientsAccrued = () => {
    const data = {
      labels: ['Patients'],
      datasets: [
        {
          data: [patientsAccrued.count],
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setData(data);
  };

  const setComparePatientsAccrued = (monthsInfo) => {
    const data = {
      labels: monthsInfo.map(x => {return `Month:${x.month}`}),
      datasets: [
        {
          data: monthsInfo?.map((a) => a.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setDataToModalCompare(data);
  };

  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const fetchDataforCompare = async (filters: string[]) => {
    const months = filters.map(month => Number.parseInt(month));
    const monthsInfo = await getAnalyticsAccruedPatientsCompare(months);
    setComparePatientsAccrued(monthsInfo);

  };
  
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>Patients Accrued</div>
        <div className={styles.controls}>
        <DateRangePicker
          currentDateRange={date}
          handleDateRangePickerChange={(range) => setDate(range)}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.datePickerWrapper}
          inputClassName={styles.datePicker}
        />
          <i onClick={() => setShowModal(!showModal)} className={styles.expandArrow}></i>
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      {patientsAccrued && (
        <>
          <h6 className="d-flex ml-4 mt-3">{`${patientsAccrued.count} new patients`} </h6>
          <div className={styles.body}>
            <Bar options={options} data={data} />
          </div>
        </>
      )}
      <CompareBarGraph
        title="Compare Patient Accrued"
        handleChange={(months) => {
          fetchDataforCompare(months);
        }}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        options={options}
        data={dataToModalCompare}
      ></CompareBarGraph>
    </div>
  );
};

export default PatientsAccrued;
