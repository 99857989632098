import BreadcrumbsWithActions from 'components/BreadcrumbsWithActions';
import { useDialogContext } from 'components/VyTracLayout/store';
import { useMessagesContext } from '../store/messages-context';

const NavigationHeader = () => {
  const {
    state: { breadcrumbs, Actions },
  } = useMessagesContext();
  const { dialog: DialogComponent } = useDialogContext();

  return (
    <>
      <BreadcrumbsWithActions breadcrumbs={breadcrumbs}>
        {Actions && <Actions />}
      </BreadcrumbsWithActions>
      {DialogComponent ? <DialogComponent /> : null}
    </>
  );
};

export default NavigationHeader;
