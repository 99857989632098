import { PaginationControls, QuickFilters } from 'components/Pagination';
import { FC } from 'react';
import { QuickFilter } from 'types/Shared/Pagination';
import { adminPatientFilterKeys } from '../AdminPatientsTable/utils/adminPatientsTableUtils';

interface AdminPatientListHeaderProps {
  patientsCount: number;
}

const filters = Object.entries(adminPatientFilterKeys).map<QuickFilter>(([key, value]) => ({
  label: value,
  sortKey: key,
  //TODO: specify. Maybe we'll have to do this inside of component
  onClick: () => null,
  selected: false,
}));

const AdminPatientListHeader: FC<AdminPatientListHeaderProps> = ({
  patientsCount,
}: AdminPatientListHeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <span>All patients ({patientsCount})</span>
    </div>
  );
};

export const AdminPatientQuickFiltersHeader = () => <QuickFilters filters={filters} />;
export default AdminPatientListHeader;
