import FiltersButton from "components/FiltersButton";
import { Col, Row } from "react-bootstrap";
import cloudArrowDown from 'assets/icons/cloudArrowDown.png';

const Header = () => {
  return (
    <Row className="justify-content-between w-100 align-items-center">
      <Col>Patient files</Col>
      <Col lg={6}>
        <Row className="gap align-items-center justify-content-end">
          <img
            style={{ width: 15, height: 13, marginRight: 10 }}
            className="cursor-pointer"
            src={cloudArrowDown}
            alt=""
            onClick={(e) => e.stopPropagation()}
          />
          <FiltersButton onFiltersClick={() => null} />
        </Row>
      </Col>
    </Row>
  )
}
export default Header;