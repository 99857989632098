import { setSelectedAppointment, useAppointmentsContext } from 'screens/Appointments/store';
import { createAppointment } from 'services/appointmentsService';

export const useAppointmentsModalActions = () => {
  const {
    dispatch,
    appointmentsState: { selectedAppointment },
  } = useAppointmentsContext();

  const onModalClose = () => {
    dispatch(setSelectedAppointment(null));
  };

  const onCreate = async () => {
    const newAppointment = await createAppointment(selectedAppointment);
    // TODO: add appointment to list
    dispatch(setSelectedAppointment(null));
  };

  const onConfirm = () => null;

  const onSave = () => null;
  const onRemove = () => null;

  return {
    onModalClose,
    onCreate,
    onConfirm,
    onSave,
    onRemove,
  };
};
