import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ITemplateParams } from 'screens/Templates/components/Content/Content';
import {
  setPatientActions,
  setSelectedAction,
} from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { templateCarePlanStartDate } from '../../../../util';
import { deleteAllEvents } from './deleteAllEvents';
import { deleteOnlyThisEvent } from './deleteOnlyThisEvent';
import { updateAllEvents } from './updateAllEvents';
import { updateOnlyThisEvent } from './updateOnlyThisEvent';

const useCalendarModification = (): {
  showRecurrenceEditionModal: boolean;
  showEditionConfirmationModal: boolean;
  showDeletionConfirmationModal: boolean;
  onCancel: () => void;
  onUpdateAllEvents: () => void;
  onUpdateOnlyThisEvent: () => void;
  onRecurrenceEditionSave: () => void;
  onDeleteAllEvents: () => void;
  onDeleteOnlyThisEvent: () => void;
  onRecurrenceDelete: () => void;
} => {
  const {
    dispatch,
    templateState: {
      templateCarePlan: { selectedAction, previousPatientActions, patientActions },
    },
  } = useTemplateContext();

  const params = useParams<ITemplateParams>();

  const [showRecurrenceEditionModal, setShowRecurrenceEditionModal] = useState<boolean>(false);
  const [showEditionConfirmationModal, setShowEditionConfirmationModal] = useState<boolean>(false);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);

  /** Recurrence edition modal behaviour */
  useEffect(() => {
    if (typeof selectedAction?.id !== 'number') {
      setShowRecurrenceEditionModal(false);
      return;
    }

    setShowRecurrenceEditionModal(true);
  }, [selectedAction?.id]);

  /** Recurrence confirmation modal behaviour */
  useEffect(() => {
    if (!selectedAction) {
      setShowEditionConfirmationModal(false);
      return;
    }
  }, [selectedAction]);

  const onCancel = () => {
    dispatch(setPatientActions(previousPatientActions));
    dispatch(setSelectedAction(null));
    setShowDeletionConfirmationModal(false);
    setShowEditionConfirmationModal(false);
  };

  /** Update event */

  const onUpdateAllEvents = async () => {
    const updatedCarePlanActions = await updateAllEvents(
      Number(params.id),
      patientActions,
      selectedAction,
      templateCarePlanStartDate
    );
    dispatch(setPatientActions(updatedCarePlanActions));
    dispatch(setSelectedAction(null));
    setShowEditionConfirmationModal(false);
  };

  const onUpdateOnlyThisEvent = async () => {
    const updatedCarePlanActions = await updateOnlyThisEvent(
      Number(params.id),
      patientActions,
      selectedAction,
      templateCarePlanStartDate
    );
    dispatch(setPatientActions(updatedCarePlanActions));
    dispatch(setSelectedAction(null));
    setShowEditionConfirmationModal(false);
  };

  const onRecurrenceEditionSave = () => {
    setShowRecurrenceEditionModal(false);
    if (selectedAction.recurrence) {
      setShowEditionConfirmationModal(true);
    } else {
      onUpdateAllEvents();
    }
  };

  /** Delete event */

  const onDeleteAllEvents = async () => {
    const updatedCarePlanActions = await deleteAllEvents(
      Number(params.id),
      patientActions,
      selectedAction,
      templateCarePlanStartDate
    );
    dispatch(setPatientActions(updatedCarePlanActions));
    dispatch(setSelectedAction(null));
    setShowDeletionConfirmationModal(false);
  };

  const onDeleteOnlyThisEvent = async () => {
    const updatedCarePlanActions = await deleteOnlyThisEvent(
      Number(params.id),
      patientActions,
      selectedAction,
      templateCarePlanStartDate
    );
    dispatch(setPatientActions(updatedCarePlanActions));
    dispatch(setSelectedAction(null));
    setShowDeletionConfirmationModal(false);
  };

  const onRecurrenceDelete = async () => {
    if (selectedAction.recurrence) {
      setShowDeletionConfirmationModal(true);
    } else {
      await onDeleteAllEvents();
    }
    setShowRecurrenceEditionModal(false);
  };

  return {
    showRecurrenceEditionModal,
    showEditionConfirmationModal,
    showDeletionConfirmationModal,
    onCancel,
    onUpdateAllEvents,
    onUpdateOnlyThisEvent,
    onRecurrenceEditionSave,
    onDeleteAllEvents,
    onDeleteOnlyThisEvent,
    onRecurrenceDelete,
  };
};

export default useCalendarModification;
