import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
  roles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Role name is required'),
      DASHBOARD: Yup.string().required('Role dashboard permission is required'),
      PATIENTS: Yup.string().required('Role patients permission is required'),
      PATIENT_CASES: Yup.string().required('Role patient cases permission is required'),
      SCHEDULE: Yup.string().required('Role schedule permission is required'),
      APPOINTMENTS: Yup.string().required('Role appointments permission is required'),
      MESSAGES: Yup.string().required('Role messages permission is required'),
      TEMPLATES: Yup.string().required('Role templates permission is required'),
      REVENUE_MANAGEMENT: Yup.string().required('Role revenue management permission is required'),
      ADMINISTRATION: Yup.string().required('Role administration permission is required'),
      ANALYTICS: Yup.string().required('Role analytics permission is required'),
      RESOURCES: Yup.string().required('Role resources permission is required'),
      SUPPORT: Yup.string().required('Role support permission is required'),
      active: Yup.boolean().required('Role active status is required'),
    })
  ),
});
export default validationSchema;
