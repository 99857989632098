export enum AccountSettingsActionTypes {
  SET_ACCOUNT_SETTINGS_SUBMIT_FORM = 'SET_ACCOUNT_SETTINGS_SUBMIT_FORM',
  SET_ACCOUNT_SETTINGS_RESET_FORM = 'SET_ACCOUNT_SETTINGS_RESET_FORM',
  SET_ACCOUNT_SETTINGS_FORM_DIRTY = 'SET_ACCOUNT_SETTINGS_FORM_DIRTY',
}

export type AccountSettingsAction =
 
  | { type: AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_RESET_FORM; payload: { resetForm: () => void } }
  | { type: AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_SUBMIT_FORM; payload: { submitForm: () => void } }
  | { type: AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_FORM_DIRTY; payload: { dirty: boolean } };
