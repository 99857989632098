import { Chart, Filler } from 'chart.js';
import { useMemo, useState } from 'react';
import AlertGraph from 'screens/ProviderDashboard/AlertGraph';
import { RedAlert } from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';
import { iconType } from 'types/Patients/constants';
import RedAlertDetail from './RedAlertDetail';
Chart.register(Filler);

export interface alert {
  id: string;
  alertType: iconType;
  creationDate: string;
  alertTitle: string;
  description: string;
  critical?: boolean;
  label: string;
  threshold: number;
}

interface GraphAlertProps {
  patientId?: number;
  showTopIcons?: boolean;
  alerts?: RedAlert[];
}
const GraphAlert = ({ showTopIcons = true, alerts, patientId }: GraphAlertProps) => {
  const [selectedAlertIndex, setSelectedAlertIndex] = useState<number>(0);
  const selectedAlert = useMemo(() => alerts?.[selectedAlertIndex], [alerts, selectedAlertIndex]);
  return (
    <div>
      <RedAlertDetail
        selectedAlert={selectedAlert}
        setSelectedAlertIndex={setSelectedAlertIndex}
        selectedAlertIndex={selectedAlertIndex}
        alerts={alerts}
        showTopIcons={showTopIcons}
      />

      <AlertGraph vytal={selectedAlert.vytal} patientId={patientId} />
    </div>
  );
};
export default GraphAlert;
