import React from 'react';
import * as d3 from 'd3';
import Slice from './components/Slice';
const GoalPieChart = ({
  data = [],
  width,
  height,
  radius,
  colors = data.map(() => '#fff'),
}) => {
  let pie = d3.pie();
  pie = (data.every((elem) => elem > 0) ? pie.padAngle(0.05) : pie).sort(null)(
    data,
  );

  return (
    <svg height={height} width={width}>
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <Slice pie={pie} radius={radius} colors={colors} />
      </g>
    </svg>
  );
};
export default GoalPieChart;
