import { useReducer, useRef, useState } from 'react';
import { administrationInitialBreadCrumbPathList } from '../util';
import AdministrationContext from './AdministrationContext';
import AdministrationContextV2, {
  adminFetchRegistryInitialValues,
  initialAdministrationStateV2,
} from './AdministrationContextV2';
import administrationReducer from './administrationReducer';

const AdministrationCtxProvider = ({ children }) => {
  const [administrationStateV2, dispatch] = useReducer(
    administrationReducer,
    initialAdministrationStateV2
  );
  //need this to ensure requests are not fired in parallel at the very beginning if using a slice hook
  const fetchRegistry = useRef(adminFetchRegistryInitialValues);
  const [administrationState, setAdministrationState] = useState({
    breadcrumbPathList: administrationInitialBreadCrumbPathList,
    actions: null,
    state: {
      providers: {
        showInviteModal: false,
      },
      roles: {
        addRole: () => null,
        hasUnsavedChanges: false,
        submitForm: () => null,
        resetForm: () => null,
      },
    },
  });

  const context = {
    breadcrumbPathList: administrationState.breadcrumbPathList,
    setBreadcrumbPathList: (breadcrumbPathList) =>
      setAdministrationState((prevState) => ({ ...prevState, breadcrumbPathList })),
    actions: administrationState.actions,
    setActions: (actions) => setAdministrationState((prevState) => ({ ...prevState, actions })),
    state: administrationState.state,
    setState: (state) => setAdministrationState((prevState) => ({ ...prevState, state })),
  };

  return (
    <AdministrationContextV2.Provider value={{ administrationStateV2, dispatch, fetchRegistry }}>
      <AdministrationContext.Provider value={context}>{children}</AdministrationContext.Provider>
    </AdministrationContextV2.Provider>
  );
};
export default AdministrationCtxProvider;
