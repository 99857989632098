import { Row } from 'react-bootstrap';
import AlertItem from 'components/Alertitem';
import styles from './styles.module.css';
import ArrowDownTodayDatePicker from 'components/ArrowDownTodayDatePicker';
import { Fragment, useCallback, useEffect, useState } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import GenericPatientPreview from 'components/GenericPatientPreview';
import useDashboardToDo from '../store/hooks/useDashboardTodo';
import PatientToDo from './PatientToDo';
import { useDashboardContext } from '../store/DashboardContext';
import { changeSelectedTimer } from '../store/DashboardActionCreators';
import VytracSpinner from 'components/vytrac-spinner';
const ToDoList = () => {
  const {
    dispatch,
    state: { toDoItems, trackingPatientId, currentToDo },
  } = useDashboardContext();

  const [date, setDate] = useState<DatePickerValue>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  });

  const [currentToDoIndex, setCurrentToDoIndex] = useState<number>(0);
  const {
    loadingToDos,
    paginatedToDoList: {
      count: totalPatients,
      extra: {
        total_critical_alerts,
        total_red_alerts,
        total_yellow_alerts,
        total_normal_readings,
      },
    },
  } = useDashboardToDo(date, currentToDoIndex);

  // Tracking
  useEffect(() => {
    if (!currentToDo) return;
    if (currentToDo.id === trackingPatientId) return;
    dispatch(changeSelectedTimer(currentToDo.id));
  }, [currentToDo, dispatch]);

  const handleNext = () => {
    if (currentToDoIndex === toDoItems.length) return;
    setCurrentToDoIndex(currentToDoIndex + 1);
  };

  const handlePrevious = () => {
    if (currentToDoIndex === 0) return;
    setCurrentToDoIndex(currentToDoIndex - 1);
  };

  const handleFirst = () => {
    if (currentToDoIndex === 0) return;
    setCurrentToDoIndex(0);
  };

  const handleLast = () => {
    if (currentToDoIndex === toDoItems.length - 1) return;
    setCurrentToDoIndex(toDoItems.length - 1);
  };

  const getLineClassName = useCallback(
    (index: number) => {
      if (!toDoItems) return;

      const { alerts_completed, alerts_count, alerts_new } = toDoItems[currentToDoIndex];
      if (index === currentToDoIndex) return 'patient-line-green';
      if (alerts_count === alerts_new) return 'patient-line-gray';
      if (alerts_count === alerts_completed) return 'patient-line-green-faint';
      return 'patient-line-dashed';
    },
    [currentToDoIndex, toDoItems]
  );

  return (
    <div>
      <div className={styles.todo}>
        <Row className="w-100 d-flex justify-content-between h-100">
          <div className="d-flex h-100">
            <div className={styles.text}>To Do</div>
            <ArrowDownTodayDatePicker
              value={date}
              onChange={(date) => setDate(date)}
              inputClassName={styles.pickerInput}
              overrideWrapperClassName={styles.pickerWrapper}
            />
            <div className="dashboard-alert-container">
              <AlertItem
                type="hexagon"
                text={total_critical_alerts}
                className={`${!total_critical_alerts ? 'd-none' : ''}`}
              />
              <AlertItem
                color="#F5485C"
                text={total_red_alerts}
                className={`${!total_red_alerts ? 'd-none' : ''}`}
              />
              <AlertItem
                color="#F8CA54"
                text={total_yellow_alerts}
                className={`${!total_yellow_alerts ? 'd-none' : ''}`}
              />
              <AlertItem
                color="#8FAAC3"
                text={total_normal_readings}
                className={`${!total_normal_readings ? 'd-none' : ''}`}
              />
            </div>
          </div>
          <div>
            <div className="to-do-paginator align-items-center h-100">
              <button
                className={styles.button}
                disabled={currentToDoIndex === 0}
                onClick={() => handleFirst()}
              >
                FIRST
              </button>
              <button
                className={styles.button}
                disabled={currentToDoIndex === 0}
                onClick={() => handlePrevious()}
              >
                PREVIOUS
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{totalPatients > 0 ? currentToDoIndex + 1 : 0} of{' '}
              {totalPatients} Patients&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className={styles.button}
                disabled={currentToDoIndex >= totalPatients - 1}
                onClick={() => handleNext()}
              >
                NEXT
              </button>
              <button
                className={styles.button}
                disabled={currentToDoIndex >= totalPatients - 1}
                onClick={() => handleLast()}
              >
                LAST
              </button>
            </div>
          </div>
        </Row>
      </div>

      <div className="dashboard-patient-lines">
        {toDoItems?.map((_, index: number) => (
          <Fragment key={index}>
            <div
              key={index}
              onClick={() => setCurrentToDoIndex(index)}
              className={`${getLineClassName(index)}`}
            ></div>
          </Fragment>
        ))}
      </div>
      {loadingToDos && (
        <div className={styles.loadingToDos}>
          <VytracSpinner />
        </div>
      )}
      <GenericPatientPreview populations={toDoItems ? toDoItems[currentToDoIndex]?.population : []}>
        <PatientToDo
          patientToDo={toDoItems ? currentToDo ?? toDoItems[currentToDoIndex] : null}
          loadingToDos={loadingToDos}
        />
      </GenericPatientPreview>
    </div>
  );
};
export default ToDoList;
