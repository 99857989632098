import moment, { Moment } from 'moment';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';
import { convertStringToTimeHours } from 'util/dateUtils';

/**
 * Transforms a relative start date to an absolute start date
 * @param relativeStartDate
 * @param blockStartDate Start of the adherence block to use as starting point
 * @returns Absolute date
 */
export const getAbsoluteDate = (relativeStartDate: IRelativeTime, blockStartDate: Date): Date => {
  const blockStartMoment = moment(blockStartDate);
  const absoluteMoment: Moment = blockStartMoment.clone();
  absoluteMoment.weekday(relativeStartDate.day_of_week);

  if (blockStartMoment.weekday() > relativeStartDate.day_of_week) {
    absoluteMoment.add(1, 'week');
  }

  const weekQty = relativeStartDate.week_number ?? 1;
  if (weekQty > 1) {
    absoluteMoment.add(weekQty - 1, 'week');
  }

  const startTime = convertStringToTimeHours(relativeStartDate.time);
  absoluteMoment.hours(startTime.hour);
  absoluteMoment.minutes(startTime.minute);
  return absoluteMoment.toDate();
};
