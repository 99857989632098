import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { activeScheduleInitialState, IActiveScheduleState } from '../reducers/ActiveScheduleReducer';


interface IInitialContext {
  activeScheduleState: IActiveScheduleState;
  dispatch: Dispatch<SetStateAction<any>>;
}

const initialContext: IInitialContext = {
  activeScheduleState: activeScheduleInitialState,
  dispatch: () => {},
};

const ActiveScheduleContext = createContext(initialContext);

export const useActiveScheduleContext = () => {
  return useContext(ActiveScheduleContext);
};

export default ActiveScheduleContext;
