import React from 'react';
import Content from './Content';
import VyTracLayout from 'components/VyTracLayout';
import { AppointmentsCtxProvider } from './store';

const Appointments = () => {
  return (
    <VyTracLayout selectedRoute="appointments">
      <AppointmentsCtxProvider>
        <Content />
      </AppointmentsCtxProvider>
    </VyTracLayout>
  );
};

export default Appointments;
