import React, { useState } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import EncounterDetail from './components/EncounterDetail';
import Table from './components/Table';

const Encounters = () => {
  const { selectedPatient } = usePatientFormikContext();
  const [encounterId, setEncounterId] = useState(null);

  return (
    <div className="container-with-padding">
      {encounterId ? (
        <EncounterDetail
          encounterId={encounterId}
          onBack={() => setEncounterId(null)}
          patient={selectedPatient}
        />
      ) : (
        <Table onIdClick={(row) => setEncounterId(row[0])} />
      )}
    </div>
  );
};

export default Encounters;
