import { useCallback } from 'react';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { updateThreshold } from 'screens/Patients/store/PatientGoalsThresholds';
import { UmbralType, IGoalThreshold, IGoalThresholdUmbral } from 'types/ApiModels/Patients/Goal';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import { isDoubleThreshold } from 'util/goalChart';
import mapKeyToUmbralValues from '../../../GoalDetails/util/mapKeyToUmbralType';
import { ThresholdFormikKeys } from '../../../GoalDetails/util/thresholdFormikUtils';

export const getUmbral = (
  thresholds: Record<VytalType, IGoalThreshold>,
  vytalType: VytalType,
  umbralType: UmbralType
): IGoalThresholdUmbral =>
  thresholds[vytalType].threshold_umbrals.find((umbral) => umbral.type === umbralType);

export const updateThresholdValues = (
  thresholds: Record<VytalType, IGoalThreshold>,
  vytalType: VytalType,
  thresholdDispatcher: (updatedThreshold: IGoalThreshold) => void,
  key: ThresholdFormikKeys,
  value: number
) => {
  const updatedThreshold = { ...thresholds[vytalType] };

  mapKeyToUmbralValues(key).forEach((umbralValue) => {
    getUmbral(thresholds, vytalType, umbralValue.type)[umbralValue.limitType] = Math.round(value);
  });
  thresholdDispatcher(updatedThreshold);
};

const useGoalChart = ({ type: vytalType }: IGoalThreshold) => {
  const {
    dispatch,
    patientState: {
      patientGoalsThresholds: { thresholds },
    },
  } = usePatientContext();

  const thresholdDispatcher = useCallback(
    (updatedThreshold: IGoalThreshold) => dispatch(updateThreshold(vytalType, updatedThreshold)),
    [dispatch, vytalType]
  );

  const getLocalUmbral = useCallback(
    (umbralType: UmbralType) => getUmbral(thresholds, vytalType, umbralType),
    [thresholds, vytalType]
  );

  const onDragThreshold = (datasetIndex: number, value: number) => {
    if (value < 0) return;
    if (!thresholds[vytalType].is_enabled) return;

    if (
      datasetIndex === 0 &&
      value > getLocalUmbral(UmbralType.YELLOW_ZONE_LOWER).min_value &&
      ((!isDoubleThreshold(vytalType) && value < thresholds[vytalType].max_value) ||
        value < getLocalUmbral(UmbralType.YELLOW_ZONE_UPPER).min_value)
    ) {
      updateThresholdValues(
        thresholds,
        vytalType,
        thresholdDispatcher,
        ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP,
        value
      );
    } else if (
      datasetIndex === 1 &&
      value > getLocalUmbral(UmbralType.RED_ZONE_LOWER).min_value &&
      value < getLocalUmbral(UmbralType.YELLOW_ZONE_LOWER).max_value
    ) {
      updateThresholdValues(
        thresholds,
        vytalType,
        thresholdDispatcher,
        ThresholdFormikKeys.LOWER_RED_ZONE_TOP,
        value
      );
    } else if (datasetIndex === 2 && value < getLocalUmbral(UmbralType.RED_ZONE_LOWER).max_value) {
      updateThresholdValues(
        thresholds,
        vytalType,
        thresholdDispatcher,
        ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM,
        value
      );
    } else if (
      datasetIndex === 3 &&
      isDoubleThreshold(vytalType) &&
      value > getLocalUmbral(UmbralType.RED_ZONE_UPPER).min_value
    ) {
      updateThresholdValues(
        thresholds,
        vytalType,
        thresholdDispatcher,
        ThresholdFormikKeys.UPPER_RED_ZONE_TOP,
        value
      );
    } else if (
      datasetIndex === 4 &&
      isDoubleThreshold(vytalType) &&
      value < getLocalUmbral(UmbralType.RED_ZONE_UPPER).max_value &&
      value > getLocalUmbral(UmbralType.YELLOW_ZONE_UPPER).min_value
    ) {
      updateThresholdValues(
        thresholds,
        vytalType,
        thresholdDispatcher,
        ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM,
        value
      );
    } else if (
      datasetIndex === 5 &&
      value > getLocalUmbral(UmbralType.NORMAL_ZONE).min_value &&
      isDoubleThreshold(vytalType) &&
      value < getLocalUmbral(UmbralType.YELLOW_ZONE_UPPER).max_value
    ) {
      updateThresholdValues(
        thresholds,
        vytalType,
        thresholdDispatcher,
        ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM,
        value
      );
    } else {
      return;
    }
    return;
  };
  return {
    onDragThreshold,
  };
};
export default useGoalChart;
