import { ConversationMetadata } from 'types/ApiModels/conversations';
import { PaginatedResponse } from 'types/ApiModels/General';
import { PatientEncounter } from 'types/ApiModels/Patients/encounter';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
//mocks
import { MockBroadcast } from '../mocks/types';
import { MessagesAction, MessagesTypes } from './messages-action-types';

export const setBreadcrumbs = (breadcrumbs: BreadCrumbPath[]): MessagesAction => ({
  type: MessagesTypes.SET_BREADCRUMBS,
  payload: breadcrumbs,
});
export const switchLastBreadcrumb = (breadcrumb: BreadCrumbPath): MessagesAction => ({
  type: MessagesTypes.SWITCH_LAST_BREADCRUMB,
  payload: breadcrumb,
});
export const setActions = (actions: React.FC<any>): MessagesAction => ({
  type: MessagesTypes.SET_ACTIONS,
  payload: actions,
});
export const toggleNewConversationModal = (): MessagesAction => ({
  type: MessagesTypes.TOGGLE_NEW_CONVERSATION_MODAL,
});
export const setPatientList = (
  patientList: Record<number, PaginatedResponse<PatientGet>>
): MessagesAction => ({
  type: MessagesTypes.SET_PATIENT_LIST,
  payload: patientList,
});
export const setSelectedPatient = (selectedPatient: PatientEncounter): MessagesAction => ({
  type: MessagesTypes.SET_SELECTED_PATIENT,
  payload: { selectedPatient },
});

export const setSelectedChat = (conversationSID: string): MessagesAction => ({
  type: MessagesTypes.SET_SELECTED_CHAT,
  payload: { conversationSID },
});

export const setBroadcasts = (broadcasts: MockBroadcast[]): MessagesAction => ({
  type: MessagesTypes.SET_BROADCASTS,
  payload: broadcasts,
});

export const setBroadcastTemplates = (broadcastTemplates: MockBroadcast[]): MessagesAction => ({
  type: MessagesTypes.SET_BROADCAST_TEMPLATES,
  payload: broadcastTemplates,
});

export const addToSelectedBroadcast = (selectedBroadcast: number): MessagesAction => ({
  type: MessagesTypes.ADD_TO_SELECTED_BROADCAST,
  payload: selectedBroadcast,
});

export const removeFromSelectedBroadcast = (selectedBroadcast: number): MessagesAction => ({
  type: MessagesTypes.REMOVE_FROM_SELECTED_BROADCAST,
  payload: selectedBroadcast,
});

export const setOpenedBroadcast = (openedBroadcast: MockBroadcast): MessagesAction => ({
  type: MessagesTypes.SET_OPENED_BROADCAST,
  payload: openedBroadcast,
});

export const setOpenedBroadcastTemplate = (
  openedBroadcastTemplate: MockBroadcast
): MessagesAction => ({
  type: MessagesTypes.SET_OPENED_BROADCAST_TEMPLATE,
  payload: openedBroadcastTemplate,
});

export const setMetadataByParticipantSID = (
  getMetadataByParticipantSID: (participantSID: string) => ConversationMetadata
): MessagesAction => ({
  type: MessagesTypes.SET_METADATA_MAPPER,
  payload: { getMetadataByParticipantSID },
});
