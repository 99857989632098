import { FC } from 'react';
import { communicationColor, ICommunicationIcon } from './utils';

const StarIcon: FC<ICommunicationIcon> = ({
  width,
  height,
  isActive,
  onClick,
  style,
  color,
}: ICommunicationIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 16 15`}
    fill={isActive ? communicationColor.active : communicationColor.inactive}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ ...style, cursor: 'pointer' }}
  >
    <path
      d="M13.6121 5.87341L10.0148 5.37156L8.40666 2.24209C8.36274 2.15641 8.29048 2.08705 8.20122 2.04489C7.97736 1.9388 7.70532 2.02721 7.59339 2.24209L5.98528 5.37156L2.38791 5.87341C2.28873 5.88701 2.19805 5.93189 2.12863 5.9999C2.0447 6.08271 1.99845 6.19411 2.00004 6.30964C2.00163 6.42516 2.05094 6.53535 2.13713 6.616L4.73987 9.05184L4.12496 12.4914C4.11054 12.5714 4.11976 12.6537 4.15159 12.7289C4.18341 12.8042 4.23655 12.8693 4.305 12.9171C4.37344 12.9648 4.45444 12.9931 4.53882 12.9989C4.6232 13.0047 4.70757 12.9876 4.78238 12.9497L8.00003 11.3258L11.2177 12.9497C11.3055 12.9946 11.4075 13.0096 11.5053 12.9932C11.7518 12.9524 11.9176 12.728 11.8751 12.4914L11.2602 9.05184L13.8629 6.616C13.9338 6.54936 13.9805 6.46231 13.9947 6.36711C14.033 6.1291 13.8601 5.90877 13.6121 5.87341V5.87341Z"
      fill={
        !color && isActive
          ? communicationColor.active
          : !color
          ? communicationColor.inactive
          : color
      }
    />
  </svg>
);

export default StarIcon;
StarIcon;
