import colors from 'styles/colors';
import { activityStatus } from 'types/Patients/constants';
import { ActivityColumn, ActivityHourColumns } from 'types/Patients/PatientTimeline';
import { Activity } from 'types/Patients/PatientTimeline/ActivityTimeline';
import { hoursInDay } from 'util/dateUtils/shared';
import { activityTimelineMaxColumnsCount } from '../../../utils';

/**Center the activity timeline on the current hour of the client */
export const initialIndexes = (() => {
  const currentHour = new Date().getHours();
  if (currentHour < 4) return { startIndex: 0, endIndex: activityTimelineMaxColumnsCount - 1 };
  if (currentHour > 20) return { startIndex: 17, endIndex: 23 };
  return {
    startIndex: currentHour - 3,
    endIndex: currentHour - 3 + activityTimelineMaxColumnsCount - 1,
  };
})();

export const activityStatusToColor: Record<activityStatus, string> = {
  missed: colors.lowRiskAlerts,
  yellow: colors.mediumRiskAlerts,
  regular: colors.regularBlack,
  red: colors.highRiskAlerts,
};

export const activityStatusToBgClass: Partial<Record<activityStatus, string>> = {
  yellow: 'yellow-alert-bg',
  red: 'red-alert-bg',
  missed: 'missed-alert-bg',
};

export const getDayColumns = (actions: Activity[], date: Date): ActivityHourColumns => {
  const allDayItems: Activity[] = [];
  const itemsByHour: Activity[][] = [];
  actions.forEach((a) => {
    const [begin, end] = [new Date(a.start_date), new Date(a.end_date)];
    if (begin.getMonth() !== date.getMonth()) return;

    begin.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    const dateIsBetweenBeginAndEnd = date >= begin && date <= end;
    if (begin.getDate() !== end.getDate() && dateIsBetweenBeginAndEnd) {
      allDayItems.push(a);
      return;
    }

    if (begin.getDate() !== date.getDate() && end.getDate() !== date.getDate()) return;
    const activityDate = new Date(a.start_date);
    const currentHour = activityDate.getHours();
    itemsByHour[currentHour] = !itemsByHour[currentHour] ? [a] : [...itemsByHour[currentHour], a];
  });

  return {
    allDayItems,
    hourActivities: hoursInDay.map<ActivityColumn>((h, idx) => ({
      headerLabel: h.label,
      items: itemsByHour[idx],
    })),
  };
};

export const getFractionsByActionId = (
  columnsRange: number[],
  hourColumns: ActivityHourColumns
) => {
  const fractionsByActionId = hourColumns.allDayItems.reduce<Record<number, number[]>>(
    (result, hc) => {
      // goalCompletion
      //const fractionInColumns = (hc.vytal.value / hc.vytal.goal) * activityTimelineMaxColumnsCount;
      const fractionInColumns = 0 * activityTimelineMaxColumnsCount;
      result[hc.id] = columnsRange.map((_, idx) => {
        const diff = fractionInColumns - (idx + 1);
        if (diff > 0) return 1;
        if (diff > -1) return 1 - Math.abs(diff);
        return 0;
      });
      return result;
    },
    {}
  );

  return fractionsByActionId;
};
