import styles from './../../../ActivityItems.module.css';

interface AllDayActivityItemProps {
  chunk: number;
  label: string;
  renderLabel: boolean;
  className: string;
}

const AllDayActivityItem = ({ chunk, label, renderLabel, className }: AllDayActivityItemProps) => {
  return (
    <div className={`d-flex ${styles['range-activity-container']} ${className}`}>
      <div
        className={`position-relative ${styles['range-activity-completed']}`}
        style={{ flexBasis: `${chunk * 100}%` }}
      >
        <div
          className={`font-size-medium h-100 d-flex align-items-center pl-3 ${styles['range-activity-label']}`}
        >
          {renderLabel ? label : ''}
        </div>
      </div>
      {chunk === 1 ? null : <div></div>}
    </div>
  );
};

export default AllDayActivityItem;
