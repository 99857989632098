import {
  PatientSignup,
  ProviderSignup,
  RoleSignup,
} from 'types/ApiModels/Administration/Dashboard';
import { DashboardAction, DashboardActionTypes } from './dashboardActionTypes';

export const setProviderSignups = (providerSignups: ProviderSignup[]): DashboardAction => ({
  type: DashboardActionTypes.SET_PROVIDER_SIGNUPS,
  payload: { providerSignups },
});

export const setPatientSignups = (patientSignups: PatientSignup[]): DashboardAction => ({
  type: DashboardActionTypes.SET_PATIENT_SIGNUPS,
  payload: { patientSignups },
});

export const setRolesAdded = (rolesAdded: RoleSignup[]): DashboardAction => ({
  type: DashboardActionTypes.SET_ROLES_ADDED,
  payload: { rolesAdded },
});
