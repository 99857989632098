import { useMemo } from 'react';
import { Tag, Tagitem } from './Tagitem';

interface TagListProps {
  tagList: Tag[];
  maxCount?: number;
  className?: string;
}

const TagList = ({ tagList = [], maxCount = 0, className = '' }: TagListProps) => {
  //? Had added memos for these two but caused React errors whenever used these comps in GenericTable component. Whenever the table changed of amount of rows, it would crash with "Rendered fewer/more hooks than previous render".
  const hiddenTagCount =
    maxCount !== 0 && tagList.length > maxCount ? tagList.length - maxCount : undefined;

  const trimmedList = maxCount ? tagList.slice(0, maxCount) : tagList;

  return (
    <div className={`d-flex ${className}`}>
      {trimmedList.map((t, idx) => (
        <Tagitem
          className={t.className}
          icon={t.icon}
          onClick={t.onClick}
          onHover={t.onHover}
          onMouseLeave={t.onMouseLeave}
          style={t.style}
          text={t.text}
          key={`tagitem-${idx}`}
        />
      ))}
      {hiddenTagCount && <Tagitem text={`${hiddenTagCount.toString()} +`} />}
    </div>
  );
};

export default TagList;
