import CompareBarGraph from 'components/CompareBarGraph/CompareBarGraph';
import DateRangePicker from 'components/DateRangePicker';
import FiltersButton from 'components/FiltersButton';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import useNewPatients from 'screens/Analytics/store/hooks/useNewPatients';
import { getAnalyticsNewPatientsCompare } from 'services/analyticsService';
import styles from '../styles.module.css';

const NewPatients = () => {
  const [date, setDate] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  });
  const [newPatients, __, refetch] = useNewPatients(date.from, date.to);
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [dataToModalCompare, setDataToModalCompare] = useState({ labels: [], datasets: [] });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (newPatients) setNewPatientsChart();
  }, [newPatients]);

  const setNewPatientsChart = () => {
    const data = {
      labels: newPatients.map((n) => n.month.toString()),
      datasets: [
        {
          data: newPatients.map((x) => x.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setData(data);
  };
  const setComparePatients = (monthsInfo) => {
    const data = {
      labels: monthsInfo.map(x => x.month),
      datasets: [
        {
          data: monthsInfo?.map((a) => a.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setDataToModalCompare(data);
  };

  const patientSum = () => {
    let sum = 0;
    newPatients.forEach((p) => (sum += p.count));
    return sum;
  };

  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const fetchDataforCompare = async (filters: string[]) => {
    const months = filters.map(month => Number.parseInt(month));
    const monthsInfo = await getAnalyticsNewPatientsCompare(months);
    setComparePatients(monthsInfo);
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>New Patients</div>
        <div className={styles.controls}>
          <DateRangePicker
            currentDateRange={date}
            handleDateRangePickerChange={(range) => setDate(range)}
            inputClassName={styles.datePicker}
          />
          <i onClick={() => setShowModal(!showModal)} className={styles.expandArrow}></i>
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      {newPatients && (
        <>
          <h6 className="d-flex ml-4 mt-3">{`${patientSum()} new patients`} </h6>
          <div className={styles.body}>
            <Bar options={options} data={data} />
          </div>
        </>
      )}
       <CompareBarGraph
        title="Compare New Patients"
        handleChange={(months) => {
          fetchDataforCompare(months);
        }}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        options={options}
        data={dataToModalCompare}
      ></CompareBarGraph>
    </div>
  );
};

export default NewPatients;
