import { Col, Row } from 'react-bootstrap';
import Billing from './Components/Billing';
import PatientAlerts from './Components/PatientAlerts';
import PatientEligibility from './Components/PatientEligibility';
import PatientEnrollement from './Components/PatientEnrollement';

const EligibilityAndAlertsTab = () => {
  return (
    <div>
      <Row>
        <Col className="my-3" lg={6}>
          <PatientEnrollement></PatientEnrollement>
        </Col>
        <Col className="my-3" lg={6}>
          <PatientEligibility></PatientEligibility>
        </Col>
        {/* <Col className="my-3" lg={6}>
          <Billing></Billing>
        </Col> */}
        <Col className="my-3" lg={6}>
          <PatientAlerts></PatientAlerts>
        </Col>
      </Row>
    </div>
  );
};

export default EligibilityAndAlertsTab;
