import React from 'react';
import { Tagitem } from './Tagitem';

const Tags = ({ tagList }) => {
  // Functions and subroutines
  if (!tagList?.length) return <></>;
  const resolveFirstTag = (tagList) => tagList[0];
  const resolveSecondTag = (tagList) => tagList[1];
  const calculateHiddenTagCount = (tagList) => (tagList.length > 2 ? tagList.length - 2 : 0);

  // Variables and constants
  const firstTag = resolveFirstTag(tagList);
  const secondTag = resolveSecondTag(tagList);
  const hiddenTagCount = calculateHiddenTagCount(tagList);

  return (
    <div className="d-flex">
      {firstTag && <Tagitem text={firstTag} />}
      {secondTag && <Tagitem text={secondTag} />}
      {hiddenTagCount ? <Tagitem text={`+${hiddenTagCount}`} /> : null}
    </div>
  );
};

export default Tags;
