import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import {
  ITemplateActionRecurrence,
  ITemplateCarePlanAction,
} from 'types/ApiModels/Templates/CarePlan';
import { getRelativeDate } from './getRelativeDate';

export const transformToTemplateAction = (
  action: IPatientCarePlanAction,
  blockStartDate: Date
): ITemplateCarePlanAction => {
  const relativeStartDate = getRelativeDate(action.start_date, blockStartDate);

  let relativeAction: ITemplateCarePlanAction = {
    ...(action as ITemplateCarePlanAction),
    relative_start_date: relativeStartDate,
    relative_end_date: relativeStartDate,
  };

  if (action.recurrence) {
    const relativeRecurrence = {
      ...action.recurrence,
      relative_end_date: getRelativeDate(action.recurrence.end_date, blockStartDate),
    } as ITemplateActionRecurrence;

    relativeAction = {
      ...relativeAction,
      recurrence: relativeRecurrence,
    };
  }

  return relativeAction;
};
