import { PaginatedResponse } from 'types/ApiModels/General';
import Patient from 'types/ApiModels/Patients/Patient';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PatientAction, PatientActionTypes } from './patientsActionTypes';

export const setPatientsBook = (
  patientsBook: Record<number, PaginatedResponse<PatientGet>>
): PatientAction => ({
  type: PatientActionTypes.SET_PATIENTS_BOOK,
  payload: { patientsBook },
});

export const toggleShowInvitePatient = (): PatientAction => ({
  type: PatientActionTypes.TOGGLE_SHOW_INVITE_PATIENT,
});

export const setSinglePatientFormReset = (resetForm: () => void): PatientAction => ({
  type: PatientActionTypes.SET_SINGLE_PATIENT_RESET_FORM,
  payload: { resetForm },
});

export const setSinglePatientFormSubmit = (submitForm: () => void): PatientAction => ({
  type: PatientActionTypes.SET_SINGLE_PATIENT_SUBMIT_FORM,
  payload: { submitForm },
});

export const setSinglePatientFormDirty = (dirty: boolean): PatientAction => ({
  type: PatientActionTypes.SET_SINGLE_PATIENT_FORM_DIRTY,
  payload: { dirty },
});

export const setSinglePatientArchive = (archivePatient: () => void): PatientAction => ({
  type: PatientActionTypes.SET_SINGLE_PATIENT_ARCHIVE,
  payload: { archivePatient },
});

export const setPatientSelected = (patient: PatientGetSingle): PatientAction => ({
  type: PatientActionTypes.SET_SELECTED_PATIENT,
  payload: { patient },
});
