import DatePicker from '@amir04lm26/react-modern-calendar-date-picker';
import { ReactComponent as DownArrow } from 'assets/icons/arrow_down.svg';
import { FC, RefObject } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, formatDottedDate } from 'util/dateUtils';
import styles from './styles.module.css';
import { datePickerDefault } from './utils/defaultDatepickerValue';

interface ArrowDownDatePickerProps {
  value: DatePickerValue;
  onChange: (date: DatePickerValue) => void;
  defaultValue?: DatePickerValue;
  overrideWrapperClassName?: string;
  today?: boolean;
  inputClassName?: string;
  calendarClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ArrowDownTodayDatePicker: FC<ArrowDownDatePickerProps> = ({
  value,
  onChange,
  defaultValue = datePickerDefault,
  overrideWrapperClassName = undefined,
  today = false,
  inputClassName = '',
  calendarClassName = '',
  onClick = () => null,
}: ArrowDownDatePickerProps) => (
  <DatePicker
    value={value}
    onChange={onChange}
    calendarPopperPosition="bottom"
    calendarClassName={calendarClassName || ''}
    colorPrimary="#393E48"
    wrapperClassName={overrideWrapperClassName || styles['messages-date-filter-wrapper']}
    renderInput={({ ref }) => (
      <>
        <div className="d-flex align-items-center gap-lg" onClick={onClick}>
          <div
            className={`d-flex gap-sm align-items-center ${inputClassName || ''}`}
            onClick={() => {
              // ref.current.focus();
            }}
          >
            <input
              readOnly
              className={`date-filter-input text-right`}
              ref={ref as RefObject<HTMLInputElement>}
              size={10}
              value={
                value
                  ? formatDottedDate(convertDatepickToDate(value))
                  : formatDottedDate(convertDatepickToDate(defaultValue))
              }
            />
            <DownArrow fill="#8FAAC4" />
          </div>
          {today ? (
            <button
              className={styles['today-button']}
              onClick={() => {
                onChange(datePickerDefault);
              }}
            >
              Today
            </button>
          ) : null}
        </div>
      </>
    )}
  />
);
export default ArrowDownTodayDatePicker;
