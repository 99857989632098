import React, { useState } from 'react';
import VyTracLayout from '../../components/VyTracLayout';
import Content from './components/Content';
import NavigationHeader from './components/NavigationHeader';
import TemplateCtxProvider from './store/TemplateCtxProvider';

const Templates = () => {
  return (
    <VyTracLayout selectedRoute="templates">
      <TemplateCtxProvider>
        <NavigationHeader />
        <Content />
      </TemplateCtxProvider>
    </VyTracLayout>
  );
};

export default Templates;
