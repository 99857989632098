import { AccountSettingsAction, AccountSettingsActionTypes } from './accountSettingsActionTypes'

//--AppendCreator

export const setAccountSettingsFormReset = (resetForm: () => void): AccountSettingsAction => ({
    type: AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_RESET_FORM,
    payload: { resetForm },
  });
  
  export const setAccountSettingsFormSubmit = (submitForm: () => void): AccountSettingsAction => ({
    type: AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_SUBMIT_FORM,
    payload: { submitForm },
  });
  
  export const setAccountSettingsFormDirty = (dirty: boolean): AccountSettingsAction => ({
    type: AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_FORM_DIRTY,
    payload: { dirty },
  });