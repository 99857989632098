import GenericButton from 'components/GenericButton';
import GenericColumn from 'types/Shared/GenericColumn';
import styles from './styles.module.css';
import EditIcon from 'assets/icons/edit.png';
import DeleteIcon from 'assets/icons/delete.png';
import GenericTable from 'components/GenericTable';
import DetailIcon from 'assets/icons/detail.png';
import ShareIcon from 'assets/icons/share.png';
import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import { ReactComponent as GearIcon } from '../../../../../../assets/icons/gear.svg';
import { getResourceList } from 'services/resourcesService';
import { useEffect, useState } from 'react';

const LastColumn = ({refetch, handleDelete, handleEdit}) => {

  const [resources, setResources] = useState(null);


  useEffect(() => {
    const fetchResources = async () => {
      const data = await getResourceList();
      setResources(data);
    };
    fetchResources();
    return () => setResources(null);
  }, [refetch]);

  const onHandleEdit = (id) =>{
    const resourceToEdit = resources.find(r => r.id == id);
    handleEdit(resourceToEdit);
  }

  const searchResource = () => {}

  const columns: GenericColumn[] = [
    {
      title: 'Id',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Type',
      key: 'iconType',
      render: () => <GearIcon fill="#8FAAC3" />,
    },
    {
      title: 'Name',
      key: 'name',
      tdClassName: 'w-50',
      render: ({name}) => 
        <span className={styles.backgroundBlue}>{name}</span>
    },
    {
      title: 'Type',
      key: 'location',
    },
    {
      title: 'Format',
      key: 'type',
    },
    {
      title: 'Added by',
      key: 'addedBy',
      render: ({addedBy}) => 
      <span className={styles.backgroundBlue}>{addedBy}</span>
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ action, ...rowInfo }) => (
        <div className='d-flex'>
          <GenericButton
            icon={ShareIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
          />
          <GenericButton
            icon={EditIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
            onClick={() => onHandleEdit(rowInfo.id)}

          />
          <GenericButton
            icon={DeleteIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
            onClick={() => handleDelete(rowInfo.id)}
          />
          <GenericButton
            icon={DetailIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
          />
        </div>
      ),
    },
  ];

  return (
    <div
      className="dashboard-detail"
      style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05))' }}
    >
      <div className="dashboard-item-header d-flex justify-content-between align-items-center">
        <div>Available Resources</div>
        <div className="d-flex">
          <SearchBar placeholder="Search resources" className={styles.search} onChange={() => {searchResource()}} />
          <FiltersButton />
        </div>
      </div>
      <GenericTable columns={columns} data={resources} />
    </div>
  );
};

export default LastColumn;
