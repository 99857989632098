import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import { FC } from 'react';
import { CareTeam } from 'types/ApiModels/CareTeam';
import { getCareTeamProviderInitials } from 'util/careTeamUtils';

interface CareTeamOptionProps {
  label: string;
  careTeam: CareTeam;
}

const CareTeamOption: FC<CareTeamOptionProps> = ({ label, careTeam }: CareTeamOptionProps) => {
  const initials = getCareTeamProviderInitials(careTeam, 4);
  return (
    <div className="d-flex align-items-center gap">
      <UserBubbleGroupCollapsed initials={initials} />
      <div>{label}</div>
    </div>
  );
};
export default CareTeamOption;
