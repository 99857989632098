import { useAuthContext } from 'auth';
import { DialogsContextProvider } from 'components/VyTracLayout/store';
import { useTwilioToken } from 'hooks';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideNavigationMenu from 'screens/Common/SideNavigationMenu';
import { TwilioProvider } from 'store/twilio-client';
import Content from './Content';
import { PatientCtxProvider } from './store';
import { PatientFormikContext } from './store/PatientFormikContext';

const Patients = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const { currentUser } = useAuthContext();
  const token = useTwilioToken(currentUser?.twilio_identity);

  return (
    <PatientCtxProvider>
      <PatientFormikContext.Provider
        value={{
          selectedPatient,
          setSelectedPatient,
        }}
      >
        <TwilioProvider token={token}>
          <DialogsContextProvider>
            <Container className="px-0">
              <Row>
                <SideNavigationMenu selectedRoute="patients" />
                <Col className="component-content p-0">
                  <Content />
                </Col>
              </Row>
            </Container>
          </DialogsContextProvider>
        </TwilioProvider>
      </PatientFormikContext.Provider>
    </PatientCtxProvider>
  );
};

export default Patients;
