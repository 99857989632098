import moment, { Moment } from 'moment';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { ITimesHours } from 'types/Shared/ITimesHours';
import { isTimeEqual } from 'util/dateUtils';
import { getNextRecurrentDate } from './getNextRecurrentDate';
import { getRecurrenceEndDate } from './getRecurrenceEndDate';
import { updateDurationDays } from './updateDurationDays';

const generateNewAction = (
  originalAction: IPatientCarePlanAction,
  newDate: Moment,
  newActionId: number
): IPatientCarePlanAction => {
  const newAction = {
    ...originalAction,
    id: newActionId,
    start_date: newDate.toISOString(),
    end_date: newDate.toISOString(),
  } as IPatientTimelineAction;
  newAction.originalActivityId = originalAction.id;

  if (originalAction.recurrence.end_date_type === 'duration') {
    // update duration
    const updatedRecurrence = updateDurationDays(newDate, originalAction);
    return { ...newAction, recurrence: updatedRecurrence };
  }

  return newAction;
};

const isExcluded = (
  currentDate: Moment,
  exceptions: string[],
  relativeExceptions: IRelativeTime[]
): boolean => {
  /** Absolute exceptions */
  if (exceptions && exceptions[Symbol.iterator]) {
    for (const exception of exceptions) {
      if (currentDate.isSame(moment(exception))) return true;
    }
  }

  /** Relative exceptions */
  if (relativeExceptions && relativeExceptions[Symbol.iterator]) {
    for (const relativeException of relativeExceptions) {
      // day of week
      if (
        currentDate.day() === relativeException.day_of_week &&
        currentDate.format('HH:mm').concat(':00') === relativeException.time
      ) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Adds the first action to the timeline actions array
 * @param action Selected action for recurrence generation
 * @returns timeline actions array
 */
const addFirstRecurrentAction = (
  timelineActions: IPatientTimelineAction[],
  action: IPatientCarePlanAction
): void => {
  const startDate = moment(action.start_date);
  if (isExcluded(startDate, action.recurrence.exceptions, action.recurrence.relative_exceptions)) {
    return;
  }

  if (action.recurrence.multiple_times_a_day) {
    // check if start date is included in multiple times and is not excluded
    const startTime: ITimesHours = { hour: startDate.hours(), minute: startDate.minutes() };
    const isTimeIncluded = action.recurrence.multiple_times_hours.find((time) =>
      isTimeEqual(startTime, time)
    );
    if (isTimeIncluded) {
      timelineActions.push(action);
    }
  } else {
    timelineActions.push(action);
  }
};

/**
 * generates recurrence from patientCarePlan actions
 * @param patientActions Patient action coming from including non-carePlan actions
 * @param currentCalendarDate current date displayed con timeline
 * @param blockStartDate start date of the adherence block
 * @returns actions to display on timeline with generated recurrence
 */
export const generateTimelineActions = (
  patientActions: IPatientCarePlanAction[],
  currentCalendarDate: Date,
  blockStartDate: Date
): IPatientTimelineAction[] => {
  const blockStartMoment = moment(blockStartDate);
  let timelineActions: IPatientTimelineAction[] = [];

  // recurrent indexes are negative to be distinguished from real actions
  let recurrentIndex = -2;

  for (const action of patientActions) {
    if (!action.recurrence) {
      // action is not recurrent
      timelineActions.push(action);
      continue;
    }

    // set recurrent period
    const recurrenceStartDate = moment(action.start_date);
    const recurrenceEndDate = getRecurrenceEndDate(action, currentCalendarDate, blockStartDate);

    // add first recurrent action to timeline
    addFirstRecurrentAction(timelineActions, action);

    // add recurrent actions to timeline
    let currentDate = getNextRecurrentDate(recurrenceStartDate, action, blockStartMoment);
    while (recurrenceEndDate.isAfter(currentDate)) {
      // check if current date is contained in exceptions
      if (
        !isExcluded(
          currentDate,
          action.recurrence.exceptions,
          action.recurrence.relative_exceptions
        )
      ) {
        let newRecurrentActions: IPatientTimelineAction[] = [];

        const newAction = generateNewAction(action, currentDate, recurrentIndex--);
        newRecurrentActions = [...newRecurrentActions, newAction];
        timelineActions = [...timelineActions, ...newRecurrentActions];
      }

      currentDate = getNextRecurrentDate(currentDate, action, blockStartMoment);
    }
  }
  return timelineActions;
};
