import * as yup from 'yup';

export const AddEditSpecialtyValidationSchema = (currentSpecialtyNames: string[]) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Required')
      .test({
        name: 'Check specialty with same name does not exist already',
        test: (value) => {
          return currentSpecialtyNames.every((cs) => cs.toLowerCase() !== value.toLowerCase());
        },
        message: 'The chosen name already exists',
      }),
  });
