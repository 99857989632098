import {
  IWebSocketAdherenceAlertMessage,
  IWebSocketQuestionnaireAlertMessage,
  IWebSocketVytalAlertMessage,
} from 'types/WebSocket/WebSocket';
import { WebSocketAlertAction, WebSocketAlertActionTypes } from './webSocketAlertsActionTypes';

export const receiveRedAlert = (alert: IWebSocketVytalAlertMessage): WebSocketAlertAction => ({
  type: WebSocketAlertActionTypes.RECEIVE_RED_ALERT,
  payload: { alert },
});
export const receiveYellowAlert = (alert: IWebSocketVytalAlertMessage): WebSocketAlertAction => ({
  type: WebSocketAlertActionTypes.RECEIVE_YELLOW_ALERT,
  payload: { alert },
});
export const receiveCriticalAlert = (alert: IWebSocketVytalAlertMessage): WebSocketAlertAction => ({
  type: WebSocketAlertActionTypes.RECEIVE_CRITICAL_ALERT,
  payload: { alert },
});
export const receiveAdherenceAlert = (
  alert: IWebSocketAdherenceAlertMessage
): WebSocketAlertAction => ({
  type: WebSocketAlertActionTypes.RECEIVE_ADHERENCE_ALERT,
  payload: { alert },
});
export const receiveQuestionnaireAlert = (
  alert: IWebSocketQuestionnaireAlertMessage
): WebSocketAlertAction => ({
  type: WebSocketAlertActionTypes.RECEIVE_QUESTIONNAIRE_ALERT,
  payload: { alert },
});
