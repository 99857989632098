import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import GenericColumn from 'types/Shared/GenericColumn';
import { ReactComponent as Arrow } from 'assets/icons/arrow_left.svg';

import styles from './styles.module.css';
interface GridTableWithDropdownProps {
  columns: GenericColumn[];
  data: AtLeast<any, 'id'>[];
  dropdownChildren?: (rowData: any) => React.ReactNode;
  dropdownContainerClassName?: string;
  openOnRowClick?: boolean;
  onOpen?: (rowData: any) => void;
  onClose?: () => void;
  showDropDown: (rowdata: any) => boolean;
}
const GridTableWithDropdown = ({
  columns,
  data,
  dropdownChildren,
  dropdownContainerClassName,
  openOnRowClick,
  onOpen,
  onClose,
  showDropDown,
}: GridTableWithDropdownProps) => {
  const arrowRef = useRef<Record<number, HTMLDivElement>>({});

  const [opened, setOpened] = useState(-1);

  const visibleColumns = useMemo(() => columns.filter((column) => !column.hidden), [columns]);

  const handleToggleOpen = useCallback(
    (id: number) => {
      if (id === opened) {
        setOpened(-1);
        onClose && onClose();
      } else {
        setOpened(id);
        onOpen && onOpen(data[id]);
      }
    },
    [opened, data, onOpen, onClose]
  );

  useEffect(() => {
    if (Object.keys(arrowRef.current).length > 0) {
      if (opened === -1) {
        Object.keys(arrowRef.current).forEach((arrowKey) => {
          if (arrowRef.current[arrowKey].className === styles.arrowContainerOpened) {
            arrowRef.current[arrowKey].className = styles.arrowContainer;
          }
        });
      } else {
        Object.keys(arrowRef.current).forEach((arrowKey) => {
          if (arrowRef.current[arrowKey].className === styles.arrowContainerOpened) {
            arrowRef.current[arrowKey].className = styles.arrowContainer;
          }
        });
        arrowRef.current[opened].className = styles.arrowContainerOpened;
      }
    }
  }, [opened]);

  return (
    <div
      className={`${styles['table']} accordion`}
      style={{ gridTemplateColumns: visibleColumns.map((c) => c.width || '1fr').join(' ') }}
    >
      <div className={styles['headers-container']}>
        {visibleColumns.map((column, index) => (
          <div className={styles['header']} key={index}>
            {column.title}
          </div>
        ))}
      </div>
      {data.map((rowData, rowIndex) => (
        <div className={styles['elements-container']} key={rowIndex}>
          <div
            className={styles['elements-container']}
            key={rowIndex}
            onClick={
              openOnRowClick && showDropDown(rowData)
                ? () => handleToggleOpen(rowData.id)
                : () => null
            }
          >
            {visibleColumns.map((column, index) => (
              <div className={styles['element']} key={`${rowIndex}_${index}`}>
                {index === 0 && showDropDown(rowData) && (
                  <div
                    ref={(ref) =>
                      ref && !Object.keys(arrowRef.current).includes(rowData.id)
                        ? (arrowRef.current[rowData.id] = ref)
                        : null
                    }
                    className={styles.arrowContainer}
                    onClick={
                      openOnRowClick && showDropDown(rowData)
                        ? () => null
                        : () => handleToggleOpen(rowData.id)
                    }
                  >
                    <Arrow fill="#393E48" className={styles.noSelect} />
                  </div>
                )}
                {column.render ? column.render(rowData) : rowData[column.key]}
              </div>
            ))}
          </div>
          {showDropDown(rowData) && (
            <Collapse in={rowData.id === opened}>
              <div
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: visibleColumns.length + 1,
                }}
                className={dropdownContainerClassName}
              >
                {dropdownChildren?.(rowData)}
              </div>
            </Collapse>
          )}
        </div>
      ))}
    </div>
  );
};
export default GridTableWithDropdown;
