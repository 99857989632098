import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import verticalGrip from '../../../assets/icons/GripVertical.png';
import { ActionEventTypeLabel } from '../utils/eventTypeMapper';
import styles from './styles.module.css';

interface IRpmActionButton {
  text: ActionEventTypeLabel;
  id: string;
  index: number;
}
const RpmActionButton = ({ text, id = '', index }: IRpmActionButton) => {
  return (
    <Draggable draggableId={`actionButton-${id}`} index={-1}>
      {(provided) => (
        <div
          className={`${styles.main} d-flex align-items-center justify-content-between`}
          id={id}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <span className="font-size-medium">{text}</span>
          <img src={verticalGrip} alt="" />
        </div>
      )}
    </Draggable>
  );
};

export default RpmActionButton;