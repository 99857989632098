import React from 'react';
import EncounterControls from './components/EncounterControls';
import EncounterSummary from './components/EncounterSummary';
import styles from './styles.module.css';

const EncounterContainer = ({ patient }) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className={styles.encounterControlsContainer}>
        <EncounterControls />
      </div>
      <div className="flex-grow-1">
        <EncounterSummary />
      </div>
    </div>
  );
};

export default EncounterContainer;
