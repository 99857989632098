import React, { CSSProperties } from 'react';
import Content from './components/Content';
import Options from './components/Options';
import styles from './styles.module.css';

interface IControls {
  name: string;
  setName: (newName: string) => void;
  usedFor: any;
  items: any;
  header: string;
  label: string;
  style?: CSSProperties;
}

const Controls = ({ name, setName, usedFor, items, header, label, style = {} }: IControls) => {
  return (
    <div className={`${styles.controlsContainer || ''}`} style={style}>
      <Options setName={setName} name={name} usedFor={usedFor} header={header} label={label} />
      <Content items={items} />
    </div>
  );
};

export default Controls;
