import { AnalyticsAction, AnalyticsTypes } from './AnalyticsActionTypes';
import { PatientEnrollement } from 'types/ApiModels/Analytics/AnalyticsPatientEnrollement';
import { PatientEligibility } from 'types/ApiModels/Analytics/AnalyticsPatientEligibility';
import { Patients } from 'types/ApiModels/Analytics/AnalyticsPatients';
import { ComplianceIssues } from 'types/ApiModels/Analytics/AnalyticsComplianceIssues';
import { Satisfaction } from 'types/ApiModels/Analytics/AnalyticsSatisfaction';
import { NewPatients } from 'types/ApiModels/Analytics/AnalyticsNewPatients';
import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import { DroppedPatients } from 'types/ApiModels/Analytics/AnalyticsDropppedPatients';
import { Devices } from 'types/ApiModels/Analytics/AnalyticsDevices';
import { PatientAccrued } from 'types/ApiModels/Analytics/AnalyticsPatientAccrued';

export const setPatientEnrollement = (patientEnrollement: PatientEnrollement): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENT_ENROLLEMENT,
  payload: { patientEnrollement },
});

export const setPatientEligibility = (patientEligibility: PatientEligibility): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENT_ELIGIBILITY,
  payload: { patientEligibility },
});

export const setPatients = (patients: Patients): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENTS,
  payload: { patients },
});

export const setComplianceIssues = (complianceIssues: ComplianceIssues): AnalyticsAction => ({
  type: AnalyticsTypes.SET_COMPLIANCE_ISSUES,
  payload: { complianceIssues },
});

export const setSatisfaction = (satisfaction: Satisfaction): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENT_SATISFACTION,
  payload: { satisfaction },
});

export const setNewPatients = (newPatients: NewPatients[]): AnalyticsAction => ({
  type: AnalyticsTypes.SET_NEW_PATIENTS,
  payload: { newPatients },
});

export const setAlerts = (alerts: Alerts[]): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENT_ALERTS,
  payload: { alerts },
});

export const setDroppedPatients = (droppedPatients: DroppedPatients): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENTS_DROPPED,
  payload: { droppedPatients },
});

export const setDevices = (devices: Devices[]): AnalyticsAction => ({
  type: AnalyticsTypes.SET_DEVICES,
  payload: { devices },
});

export const setPatientAccrued = (patientAccrued: PatientAccrued): AnalyticsAction => ({
  type: AnalyticsTypes.SET_PATIENTS_ACCRUED,
  payload: { patientAccrued },
});