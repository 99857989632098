export enum AlertTypeOptions {
  ADHERENCE = 'adherence',
  QUESTIONNAIRE = 'questionnaire',
  MEDICATION = 'medication',
  OXYGEN_LEVEL = 'oxygen level',
  HEART_RATE = 'heart rate',
  BLOOD_PRESSURE = 'blood pressure',
  WEIGHT = 'weight',
  TEMPERATURE = 'temperature',
  STEPS = 'steps',
  STAIRS = 'stairs',
  SLEEP = 'sleep',
  BLOOD_SUGAR = 'blood sugar',
  STRESS_LEVEL = 'stress level',
  EMERGENCY = '911 call',
  HOSPITALIZATION = 'hospitalization',
  ENCOUNTER = 'encounter',
}

export enum AlertImportanceOptions {
  NORMAL = 'normal',
  YELLOW = 'yellow',
  RED = 'red',
  CRITICAL = 'critical',
  MISSED = 'missed',
}
