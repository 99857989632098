import { ChangeEventHandler, FC } from 'react';

import Card from 'components/Card';
import SearchBar, { SearchBarAlt } from 'components/SearchBar';

import Header from './header';
import styles from './styles.module.css';

interface ListCardProps {
  counter: number;
  title: string;
  searchPlaceholder: string;
  searchValue?: string;
  handleSearch?: ChangeEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
}

const ListCard: FC<ListCardProps> = ({
  counter,
  title,
  searchPlaceholder,
  handleSearch,
  searchValue,
  children,
}: ListCardProps) => {
  return (
    <Card
      className={styles['card']}
      headers={[<Header key="global-messages-header" counter={counter} title={title} />]}
      bodyClassName="pr-0"
    >
      <SearchBarAlt
        placeholder={searchPlaceholder}
        value={searchValue}
        onChange={handleSearch}
        className="mr-4"
      />
      {children}
    </Card>
  );
};
export default ListCard;
