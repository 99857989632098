import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import PointsMultiplier from './points-multiplier/points-multiplier';
import RecentRewards from './recent-rewards';
import YourActivityPoints from './your-activity-points';

const ProfileActivityPoints: FC = () => {
  return (
    <Row className="w-100">
      <Col md={9} className="d-flex flex-column gap-md pl-0">
        <Row className="w-100">
          <YourActivityPoints />
        </Row>
      </Col>
      <Col md={3} className="d-flex flex-column gap-md" style={{ position: 'relative', top: -40 }}>
        <PointsMultiplier />
        <RecentRewards />
      </Col>
    </Row>
  );
};
export default ProfileActivityPoints;
