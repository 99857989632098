import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import PatientTimelineResponse from 'types/ApiModels/Patients/PatientTimeline';

export enum PatientTimelineActionTypes {
  SET_SELECTED_GOAL = 'SET_SELECTED_GOAL',
  SET_PATIENT_ACTIVITIES = 'SET_PATIENT_ACTIVITIES',
  SET_TIMLEINE_SELECTED_DATE = 'SET_TIMLEINE_SELECTED_DATE',
  SET_TIMELINE_PERIOD_SELECTED = 'SET_TIMELINE_PERIOD_SELECTED',
}

export type PatientTimelineAction =
  | {
      type: PatientTimelineActionTypes.SET_SELECTED_GOAL;
      payload: {
        goal: VytalType;
      };
    }
  | {
      type: PatientTimelineActionTypes.SET_PATIENT_ACTIVITIES;
      payload: {
        patientActivities: PatientTimelineResponse[];
      };
    }
  | {
      type: PatientTimelineActionTypes.SET_TIMLEINE_SELECTED_DATE;
      payload: {
        selectedDate: Date;
      };
    }
  | {
      type: PatientTimelineActionTypes.SET_TIMELINE_PERIOD_SELECTED;
      payload: {
        periodSelected: TimePeriodOptions;
      };
    };
