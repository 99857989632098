import React from 'react';
import Button from '../../../../components/Button';

import styles from './styles.module.css';

const ButtonGroup = ({
  hasUnsavedChanges,
  isRemovable,
  onSave,
  onCancel,
  onDelete,
  onCreateNew,
  isNew,
}) => {
  const buttons = [
    {
      label: 'Cancel',
      color: '#8FAAC3',
      style: {
        opacity: hasUnsavedChanges ? 1 : 0.3,
        cursor: hasUnsavedChanges ? 'pointer' : 'default',
      },
      disabled: !hasUnsavedChanges,
      onClick: () => onCancel(),
    },
    {
      label: 'Save Changes',
      color: '#86B463',
      style: {
        opacity: hasUnsavedChanges ? 1 : 0.3,
        cursor: hasUnsavedChanges ? 'pointer' : 'default',
      },
      disabled: !hasUnsavedChanges,
      type: 'submit',
      onClick: () => onSave(),
    },
    isRemovable && {
      label: 'Delete Schedule',
      color: '#F5485C',
      onClick: () => onDelete(),
    },
    {
      label: 'Create new',
      color: '#1890FF',
      onClick: () => onCreateNew(),
      style: {
        opacity: !isNew ? 1 : 0.3,
        cursor: !isNew ? 'pointer' : 'default',
      },
      disabled: isNew,
    },
  ];

  return (
    <div className={styles.buttonsContainer}>
      {buttons.map((button, index) => {
        return (
          button && (
            <Button
              key={index}
              label={button.label}
              color={button.color}
              className={styles.button}
              style={button.style}
              disabled={button.disabled}
              onClick={button.onClick}
              type={button.type}
            />
          )
        );
      })}
    </div>
  );
};

export default ButtonGroup;
