import FiltersButton from 'components/FiltersButton';
import SearchBar, { SearchBarAlt } from 'components/SearchBar';
import { ChangeEventHandler } from 'react';

interface TableHeaderProps {
  providerCount?: number;
  search: string;
  handleSearch: ChangeEventHandler<HTMLInputElement>;
  onFiltersClick: () => void;
}

const TableHeader = ({
  providerCount = 0,
  search,
  handleSearch,
  onFiltersClick,
}: TableHeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <div>Providers ({providerCount})</div>
      <div className="d-flex gap">
        <SearchBarAlt onChange={handleSearch} value={search} placeholder="Search provider..." />
        <FiltersButton onFiltersClick={onFiltersClick} />
      </div>
    </div>
  );
};
export default TableHeader;
