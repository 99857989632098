import React from 'react';
import { Table } from 'react-bootstrap';
import styles from './styles.module.css';

const TimeSummary = () => {
  return (
    <Table className={styles.main} borderless size="sm">
      <tbody>
        <tr>
          <td>Start date</td>
          <td>05.10.2021 • 3:25 pm</td>
        </tr>
        <tr>
          <td>End date</td>
          <td>-</td>
        </tr>
        <tr>
          <td>Duration</td>
          <td>-</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TimeSummary;
