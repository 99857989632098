import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { validateContext } from 'util/utils';

interface IPatientFormikContext {
  selectedPatient: PatientGetSingle | null;
  setSelectedPatient: Dispatch<SetStateAction<PatientGetSingle | null>>;
}

const initialPatientFormikContext: IPatientFormikContext = {
  selectedPatient: null,
  setSelectedPatient: () => undefined,
};

export const PatientFormikContext = createContext<IPatientFormikContext>(
  initialPatientFormikContext
);

export const usePatientFormikContext = () => {
  const context = useContext(PatientFormikContext);
  validateContext(context);
  return context;
};
