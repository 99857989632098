import Card from 'components/Card';
import { FC } from 'react';
import RegisterLayout from '../RegisterLayout';
import styles from '../styles.module.css';

const CheckingInvitation: FC = () => {
  return (
    <RegisterLayout>
      <Card
        headers={[
          <div key="placeholder" className="font-size-large">
            Invitation check
          </div>,
        ]}
        className={`vytrac-card ${styles['register-card']}`}
      >
        Checking your invitation... Shouldn&apos;t take long
      </Card>
    </RegisterLayout>
  );
};
export default CheckingInvitation;
