import { FC, useMemo } from 'react';
import colors from 'styles/colors';
import SVGIconProps from 'types/Shared/SVGIconProps';

interface AlertItemSvgProps {
  SvgComponent?: FC<SVGIconProps> | null;
  color: string;
  type?: 'hexagon' | null;
  width?: number;
  height?: number;
  lg?: boolean;
}

const AlertItemSvg = ({
  SvgComponent,
  color,
  type,
  width = 13,
  height = 13,
  lg = false,
}: AlertItemSvgProps) => {
  const styles = useMemo(() => {
    const colorStyle = color || colors.highRiskAlerts;
    const borderColor = color || (type === 'hexagon' ? 'transparent' : colors.highRiskAlerts);
    const border = type === 'hexagon' ? '' : `1px solid ${borderColor}`;
    const className = type === 'hexagon' ? 'alert-item-container-hexagon' : 'alert-item-container';

    return {
      colorStyle,
      border,
      className,
    };
  }, [color, type]);

  return (
    <div
      className={`${styles.className}${
        lg ? '-lg' : ''
      } d-flex align-items-center justify-content-center`}
      style={{
        color: styles.colorStyle,
        border: styles.border,
      }}
    >
      {SvgComponent && (
        <SvgComponent width={`${width}`} height={`${height}`} color={styles.colorStyle} />
      )}
    </div>
  );
};

export default AlertItemSvg;
