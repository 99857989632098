import VyTracModal from 'components/Modal';
import { FormikContextType } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { InvitePatientForm } from './InvitePatientForm';
import styles from './styles.module.css';

interface InvitePatientModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const InvitePatientModal = ({ show, handleClose, handleConfirm }: InvitePatientModalProps) => {
  const [showPopulationsModal, setShowPopulationsModal] = useState(false);
  const [previousPopulations, setPreviousPopulations] = useState<number[]>([]);
  const className = useMemo(() => {
    if (showPopulationsModal) {
      return 'd-none';
    } else return '';
  }, [showPopulationsModal]);
  const formRef = useRef<FormikContextType<InvitePatient>>(null);
  return (
    <>
      <VyTracModal
        className={className}
        backdrop={false}
        headerClassName={styles['header']}
        title="Invite new patient"
        body={
          <InvitePatientForm
            onCancel={handleClose}
            onConfirm={handleConfirm}
            setShowPopulationsModal={setShowPopulationsModal}
            showPopulationsModal={showPopulationsModal}
            setPreviousPopulations={setPreviousPopulations}
            previousPopulations={previousPopulations}
            formRef={formRef}
          />
        }
        onClose={handleClose}
        show={show}
        footer={undefined}
        centered
        size="lg"
        bodyClassName="p-0"
      />
    </>
  );
};
export default InvitePatientModal;
