import { ReactComponent as AdherenceIcon } from 'assets/icons/adherence_empty.svg';
import EmptyList from '../../../components/EmptyList';
const EmptyAdherence = () => {
  return (
    <EmptyList
      Icon={AdherenceIcon}
      title="Great job. There are no adnerence problems."
      fill="#E6F5FA"
    />
  );
};
export default EmptyAdherence;
