import BreadcrumbsWithActions from 'components/BreadcrumbsWithActions';
import Button from 'components/Button';
import React from 'react';
import { setSelectedAppointment, useAppointmentsContext } from '../store';
import { setAppointmentsModalType } from '../store/appointmentsActionCreators';
import { AppointmentsModalType } from './components/AppointmentsModal/utils';
import { initialAppointment, initialBreadcrumbPathList } from './utils';

const Actions = () => {
  const { dispatch } = useAppointmentsContext();
  const onAddAppointment = () => {
    dispatch(setAppointmentsModalType(AppointmentsModalType.CREATE));
    dispatch(setSelectedAppointment(initialAppointment));
  };
  return (
    <BreadcrumbsWithActions breadcrumbs={initialBreadcrumbPathList}>
      <Button label="Add new appointment" onClick={onAddAppointment} />
    </BreadcrumbsWithActions>
  );
};
export default Actions;
