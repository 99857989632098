import React, { useReducer, useRef } from 'react';
import AnalyticsContext, { analyticsFetchRegistryInitialValues, analyticsStateInitialValue } from './AnalyticsContext';
import AnalyticsReducer from './AnalyticsReducer';

const AnalyticsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnalyticsReducer, analyticsStateInitialValue);
  const fetchRegistry = useRef(analyticsFetchRegistryInitialValues);

  return (
    <AnalyticsContext.Provider value={{ state, dispatch, fetchRegistry }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
export default AnalyticsProvider;
