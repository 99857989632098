import React from 'react';
import Calendar from './components/Calendar';
import styles from './styles.module.css';
import { Col, Row } from 'react-bootstrap';
import CalendarPicker from 'components/CalendarPicker';
import Appointment from './components/Appointment';
import { fetchMockAppointments } from './utils/mock';
import { useAppointmentsContext } from '../store/AppointmentsContext';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import DatePickerValue from 'types/Shared/DatePicker';
import { setSelectedAppointment, setSelectedDate } from '../store';
import AppointmentsModal from './components/AppointmentsModal';
import { useAppointmentsModalActions } from './utils';
import AppointmentsActions from './Actions';

const appointments = fetchMockAppointments();

const Content = () => {
  const {
    dispatch,
    appointmentsState: { selectedDate, selectedAppointment, modalType },
  } = useAppointmentsContext();

  const { onModalClose, onCreate, onConfirm, onRemove, onSave } = useAppointmentsModalActions();

  const onDateChange = (datePickValue: DatePickerValue) => {
    const newDateValue = convertDatepickToDate(datePickValue);
    dispatch(setSelectedDate(newDateValue));
  };

  return (
    <div className="d-flex flex-column">
      <AppointmentsActions />
      <div className="py-4">
        <Row>
          <Col className="px-3" lg={3}>
            <CalendarPicker
              value={convertDateToDatePickValue(selectedDate)}
              onChange={onDateChange}
              className={styles.calendar}
              containerClassName={styles.calendarContainer}
              titleClassName={styles.calendarTitle}
              title="Calendar"
            />
            <div
              className={` dashboard-detail ${styles.shadow}`}
              style={{ height: 'auto', marginTop: '20px' }}
            >
              <div className="dashboard-item-header py-0 align-items-center">
                <label className="mb-0 pr-3">My Appointments</label>
                <label
                  className={styles.todayButton}
                  onClick={() => dispatch(setSelectedDate(new Date()))}
                >
                  Today
                </label>
              </div>
              <div style={{ padding: '20px' }}>
                {appointments.map((appointment, index) => (
                  <Appointment key={index} {...appointment} />
                ))}
              </div>
            </div>
          </Col>
          <Col className="px-3" lg={9}>
            <Calendar />
          </Col>
        </Row>
      </div>
      <AppointmentsModal
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={(appointment) => dispatch(setSelectedAppointment(appointment))}
        type={modalType}
        onClose={onModalClose}
        onCreate={onCreate}
        onConfirm={onConfirm}
        onRemove={onRemove}
        onSave={onSave}
      />
    </div>
  );
};

export default Content;
