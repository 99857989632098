import TabEnum from '../../../../../util/TabEnum';
import { CarePlanTemplatesActions } from '../components/CarePlan';
import { QuestionnaireActions } from '../components/Questionnaires';
import { SurveyActions } from '../components/Surveys';

const TabActionComponentMap = {
  [TabEnum.CAREPLAN_TEMPLATES]: CarePlanTemplatesActions,
  [TabEnum.QUESTIONNAIRES]: QuestionnaireActions,
  [TabEnum.SURVEYS]: SurveyActions,
};

export default TabActionComponentMap;
