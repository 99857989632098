enum AdminTabEnum {
  DASHBOARD = 'dashboard',
  PROVIDERS = 'providers',
  ROLES = 'roles',
  PATIENTS = 'patients',
  PATIENT_SETTINGS = 'patient-settings',
  NOTIFICATIONS = 'notifications',
  FORMS = 'forms',
  EMAIL_SETTINGS = 'email-settings',
  ACCOUNT_SETTINGS = 'account-settings',
  REPORTS = 'reports',
  RULES_ENGINE = 'rules-engine',
  AUDIT_TRACKING = 'audit-tracking',
  HEALTH_SCORING = 'health-scoring',
  ACTIVITY_POINTS = 'activity-points',
}

export default AdminTabEnum;
