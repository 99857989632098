import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';

const InfomartionSummary = () => {
  const Label = ({ children }) => (
    <div className={styles.labelContainer}>
      <span className={styles.label}>{children}</span>
    </div>
  );

  const Input = (props) => <Form.Control size="sm" readOnly className={styles.input} {...props} />;

  return (
    <div>
      <div>
        <Label>Category</Label>
        <Form.Control size="sm" as="select" readOnly className={styles.input}>
          <option>No category</option>
        </Form.Control>
      </div>
      <div>
        <Label>Subjective </Label>
        <Input value="Headache" />
      </div>
      <div>
        <Label>Objective </Label>
        <Input value="Dehydrated" />
      </div>
      <div>
        <Label>Assessments </Label>
        <Input value="Drink extra water today" />
      </div>
      <div>
        <Label>Plan</Label>
        <Form.Control size="sm" as="select" readOnly className={styles.input}>
          <option>1 day</option>
          <option>1 week</option>
          <option>1 month</option>
        </Form.Control>
      </div>
    </div>
  );
};

export default InfomartionSummary;
