import PatientActions, { PatientActionsProps } from 'screens/ProviderDashboard/PatientActions';
import styles from './styles.module.css';

interface ActionButtonsProps extends PatientActionsProps {
  wrapperClassName?: string;
}
const ActionButtons = ({ wrapperClassName = '', ...props }: ActionButtonsProps) => {
  return (
    <div className={`${styles['container']} ${wrapperClassName || ''}`}>
      <PatientActions {...props} />
    </div>
  );
};
export default ActionButtons;
