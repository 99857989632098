import React, {useContext, useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Context from '../../../../util/Context';
import {breadcrumbsPathMap} from '../../util/breadcrumbsPathMap';
import {routeEnum} from '../../util/routeEnum';
import CodeDetails from './components/CodeDetails';
import PatientDetails from './components/PatientDetails';
const PatientBilling = ({findPatientById}) => {
  const {state, setState} = useContext(Context);
  const {params} = useRouteMatch();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedPatient: findPatientById(params.patientId),
      breadcrumbPathList: breadcrumbsPathMap(
        `${state.selectedPatient?.patient.firstName} ${state.selectedPatient?.patient.lastName}`,
        state.selectedPatient?.patient.id,
      )[routeEnum.PATIENT_BILLING],
    }));
  }, [params.patientId, state.selectedPatient]);

  return (
    <div>
      <PatientDetails patient={state.selectedPatient} />
      <CodeDetails />
    </div>
  );
};

export default PatientBilling;
