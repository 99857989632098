import PatientProfileRing from 'components/indicators/GaugeIndicator';
interface HeaderProps {
  patientAdherence: number;
  totalPatients: number;
}
const Header = ({ patientAdherence, totalPatients }: HeaderProps) => {
  return (
    <div className="dashboard-item-header" style={{ textTransform: 'capitalize' }}>
      Adherence
      <div style={{ flex: 1, paddingRight: 40 }}>
        <div style={{ float: 'right' }}>
          <PatientProfileRing
            key={patientAdherence}
            color="#80CCE4"
            percentage={(patientAdherence / totalPatients) * 100}
            text={`${patientAdherence}`}
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
