import { useCallback, useMemo, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import ActionButtons from 'screens/ProviderDashboard/ActionButtons';
import LastAction from 'screens/ProviderDashboard/LastAction';
import { MockPatient } from 'screens/ProviderDashboard/utils';
import { YellowAlert } from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { iconType } from 'types/Patients/constants';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import { rpmActionTypeToString } from 'util/rpmActionTypeToString';
import { ConsecutiveYellowAlerts } from '../YellowAlertPopup';
import AlertsHover from './AlertsHover';
import styles from './styles.module.css';

interface YellowIncidentProps {
  iconType: iconType;
  consecutiveAlerts: YellowAlert[];
}

const YellowIncident = ({ consecutiveAlerts, iconType }: YellowIncidentProps) => {
  const [showCollapse, setShowCollapse] = useState(false);
  const [showHoverAlerts, setShowHoverAlerts] = useState(false);
  const numberOfDays = useMemo(() => {
    let dayCount = 0;
    consecutiveAlerts.reduce((acc, curr) => {
      if (!acc[curr.created_at.slice(0, 10)]) {
        acc[curr.created_at.slice(0, 10)] = true;
        dayCount++;
      }
      return acc;
    }, {});
    return dayCount;
  }, [consecutiveAlerts]);

  const Icon = useMemo(() => rpmActionTypeToIcon[iconType], [iconType]);

  const alertType = useMemo(
    () => rpmActionTypeToString[consecutiveAlerts[0].vytal.type],
    [consecutiveAlerts]
  );

  const mouseEnterTimeout = useRef(null);
  const mouseLeaveTimeout = useRef(null);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(mouseLeaveTimeout.current);
    mouseEnterTimeout.current = setTimeout(() => {
      setShowHoverAlerts(true);
    }, 500);
  }, []);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(mouseEnterTimeout.current);
    mouseLeaveTimeout.current = setTimeout(() => {
      setShowHoverAlerts(false);
    }, 500);
  }, []);

  return (
    <div
      className={styles['container']}
      style={showCollapse ? { backgroundColor: 'var(--light-gray)' } : {}}
    >
      <div
        className={`d-flex gap align-items-center pb-1 cursor-pointer`}
        onClick={() => setShowCollapse((prev) => !prev)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="alert-item-container mr-3"
          style={{ minWidth: 26, width: 26, border: '1px solid #F8CA54' }}
        >
          <Icon width="12px" height="11px" color="#F8CA54" />
        </div>
        <span>
          {alertType}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
          {consecutiveAlerts.length} alerts&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
          {numberOfDays} day
          {numberOfDays > 1 ? 's' : ''}
        </span>
        <AlertsHover show={showHoverAlerts} alerts={consecutiveAlerts} />
      </div>
      <Collapse in={showCollapse}>
        <div>
          <ActionButtons
            wrapperClassName={styles['actions']}
            alertId={consecutiveAlerts[0].id}
            callPatient
            provideQuestionnaire
            requestTelehealthAppointment
            assign
            complete
            sendAutomatedChat
            adjustThreshold
          />
          {/* <LastAction
            type={patient.lastAction}
            status={patient.lastActionStatus}
            actions={patient.lastActionActions}
          /> */}
        </div>
      </Collapse>
    </div>
  );
};
export default YellowIncident;
