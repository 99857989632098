import styles from './styles.module.css';

interface ISatisfactionBar {
  value: number;
  className?: string;
}
const SatisfactionBar = ({ value, className = '' }: ISatisfactionBar) => {
  return <progress max="100" value={value} className={`${styles.meter} ${className || ''}`} />;
};
export default SatisfactionBar;
