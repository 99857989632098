import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { Appointment } from 'types/ApiModels/Appointments/Appointment';
import { AppointmentsModalType } from '../Content/components/AppointmentsModal/utils';

export enum AppointmentsActionTypes {
  SET_SELECTED_DATE = 'SET_SELECTED_DATE',
  SET_PERIOD_SELECTED = 'SET_PERIOD_SELECTED',
  SET_SELECTED_APPOINTMENT = 'SET_SELECTED_APPOINTMENT',
  SET_MODAL_TYPE = 'SET_MODAL_TYPE',
  SET_APPOINTMENTS = 'SET_APPOINTMENTS',
  SET_PREVIOUS_APPOINTMENTS = 'SET_PREVIOUS_APPOINTMENTS',
}

export type AppointmentsAction =
  | {
      type: AppointmentsActionTypes.SET_SELECTED_DATE;
      payload: {
        selectedDate: Date;
      };
    }
  | {
      type: AppointmentsActionTypes.SET_PERIOD_SELECTED;
      payload: {
        periodSelected: TimePeriodOptions;
      };
    }
  | {
      type: AppointmentsActionTypes.SET_SELECTED_APPOINTMENT;
      payload: {
        selectedAppointment: Appointment;
      };
    }
  | {
      type: AppointmentsActionTypes.SET_MODAL_TYPE;
      payload: {
        modalType: AppointmentsModalType;
      };
    }
  | {
      type: AppointmentsActionTypes.SET_APPOINTMENTS;
      payload: {
        appointments: Appointment[];
      };
    }
  | {
      type: AppointmentsActionTypes.SET_PREVIOUS_APPOINTMENTS;
    };
