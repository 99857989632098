import Specialty from 'types/ApiModels/Providers/Specialty';
// showEditSpecialty: boolean;
// showAddSpecialty: boolean;
// showProvidersBySpecialty: boolean;
// resetSpecialtiesForm: () => void;

export enum SpecialtyActionTypes {
  SET_SPECIALTIES = 'SET_SPECIALTIES',
  TOGGLE_SHOW_EDIT_SPECIALTY = 'TOGGLE_SHOW_EDIT_SPECIALTY',
  TOGGLE_SHOW_ADD_SPECIALTY = 'TOGGLE_SHOW_ADD_SPECIALTY',
  TOGGLE_SHOW_PROVIDERS_BY_SPECIALTY = 'TOGGLE_SHOW_PROVIDERS_BY_SPECIALTY',
  SET_RESET_SPECIALTIES_FORM = 'SET_RESET_SPECIALTIES_FORM',
  SET_CURRENT_SPECIALTYID = 'SET_CURRENT_SPECIALTYID',
  CLEAR_ALL_CHANGES = 'CLEAR_ALL_CHANGES',
  APPEND_UPDATED_SPECIALTY = 'APPEND_UPDATED_SPECIALTY',
  APPEND_DELETED_SPECIALTY = 'APPEND_DELETED_SPECIALTY',
  APPEND_ADDED_SPECIALTY = 'APPEND_ADDED_SPECIALTY',
  SET_SUBMIT_SPECIALTY_FORM = 'SET_SUBMIT_SPECIALTY_FORM',
}

export type SpecialtyAction =
  | {
      type: SpecialtyActionTypes.SET_SPECIALTIES;
      payload: { specialties: Specialty[] };
    }
  | { type: SpecialtyActionTypes.TOGGLE_SHOW_EDIT_SPECIALTY }
  | { type: SpecialtyActionTypes.TOGGLE_SHOW_PROVIDERS_BY_SPECIALTY }
  | { type: SpecialtyActionTypes.TOGGLE_SHOW_ADD_SPECIALTY }
  | { type: SpecialtyActionTypes.SET_RESET_SPECIALTIES_FORM; payload: { resetForm: () => void } }
  | { type: SpecialtyActionTypes.SET_CURRENT_SPECIALTYID; payload: { specialtyId: number } }
  | { type: SpecialtyActionTypes.CLEAR_ALL_CHANGES }
  | { type: SpecialtyActionTypes.APPEND_ADDED_SPECIALTY; payload: { specialty: Specialty } }
  | { type: SpecialtyActionTypes.APPEND_DELETED_SPECIALTY; payload: { specialtyId: number } }
  | { type: SpecialtyActionTypes.APPEND_UPDATED_SPECIALTY; payload: { specialty: Specialty } }
  | { type: SpecialtyActionTypes.SET_SUBMIT_SPECIALTY_FORM; payload: { submitForm: () => void } };
