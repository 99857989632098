import DropdownMenu from 'components/DropdownMenu';
import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { CareTeamProvider, CareTeamTier } from 'types/ApiModels/CareTeam';
import { getCareTeamInitialsByTiers } from 'util/getTeamInitials';
import styles from './styles.module.css';
interface CareTeamTierSelectProps {
  careTeamName: string;
  tiers: CareTeamTier[];
  ProviderItem: React.ComponentType<{
    provider: CareTeamProvider;
    enableDelete: boolean;
    enabledSelect: boolean;
    handleSelectProvider: (provider: CareTeamProvider, checked: boolean) => void;
    selected: boolean;
  }>;
  onProviderSelect: (provider: CareTeamProvider, checked: boolean) => void;
  selectedProviders: number[];
}
const CareTeamTierSelect = ({
  careTeamName,
  tiers,
  ProviderItem,
  onProviderSelect,
  selectedProviders,
}: CareTeamTierSelectProps) => {
  const teamInitials = useMemo(() => {
    if (!tiers) return [];
    return getCareTeamInitialsByTiers(tiers);
  }, [tiers]);
  const sortedTiers = useMemo(() => {
    return tiers.sort((a, b) => a.number - b.number);
  }, [tiers]);

  const isProviderSelected = useCallback(
    (id: number) => selectedProviders.includes(id),
    [selectedProviders]
  );

  return (
    <>
      <div className={styles.popupHeader}>
        <Col className="d-flex align-items-center">
          <UserBubbleGroupCollapsed initials={teamInitials} />
          <div className={styles.popupHeaderText}>{careTeamName}</div>
        </Col>
      </div>
      {sortedTiers.map((tier: CareTeamTier) => (
        <DropdownMenu name={`Tier ${tier.number}`} key={tier.number}>
          <div>
            {tier.providers.map((provider, idx) => (
              <div className={styles.providerContainer} key={idx}>
                <ProviderItem
                  selected={isProviderSelected(provider.id)}
                  handleSelectProvider={onProviderSelect}
                  provider={provider}
                  enableDelete={false}
                  enabledSelect={true}
                />
              </div>
            ))}
          </div>
        </DropdownMenu>
      ))}
    </>
  );
};
export default CareTeamTierSelect;
