import React from 'react';
import styles from './styles.module.css';

interface IScore {
  value: number;
}

const Score = ({ value }: IScore) => {
  return (
    <div className={styles.main}>
      <div className={styles.title}>score</div>
      <hr className="my-0" />
      {value !== null ? (
        <div className={styles.text}>{value}</div>
      ) : (
        <div className={styles.disabledText}>No score available</div>
      )}
    </div>
  );
};
export default Score;
