import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react';
import PaginationState from 'types/Shared/Pagination';
import { PaginationAction, PaginationActionType } from './store/types';
import initialPaginationState from './util/initialPaginationValue';

interface PaginationContextState {
  paginationState: PaginationState<string>;
  paginationDispatch: Dispatch<PaginationAction>;
}
const PaginationContext = createContext<PaginationContextState>({
  paginationState: initialPaginationState,
  paginationDispatch: () => null,
});

export const ELEMENTS_PER_PAGE = 10;

export const paginationReducer = (
  state = initialPaginationState,
  action: PaginationAction
): PaginationState<string> => {
  switch (action.type) {
    case PaginationActionType.FIRST_PAGE:
      return { ...state, currentPageIndex: 0 };
    case PaginationActionType.PREVIOUS_PAGE:
      return {
        ...state,
        currentPageIndex:
          state.currentPageIndex > 0 ? state.currentPageIndex - 1 : state.currentPageIndex,
      };
    case PaginationActionType.NEXT_PAGE:
      return {
        ...state,
        currentPageIndex:
          state.currentPageIndex < state.maxPage
            ? state.currentPageIndex + 1
            : state.currentPageIndex,
      };
    case PaginationActionType.LAST_PAGE:
      return { ...state, currentPageIndex: state.maxPage };
    case PaginationActionType.SET_FILTER:
      return { ...state, sortKey: action.payload.sortKey };
    case PaginationActionType.SET_MAX_PAGE:
      return { ...state, maxPage: action.payload.maxPage };
    case PaginationActionType.SET_MAX_COUNT:
      return { ...state, maxCount: action.payload.maxCount };
    case PaginationActionType.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    default: {
      return state;
    }
  }
};

export const PaginationProvider = ({ children }: { children: ReactNode }) => {
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    initialPaginationState
  );
  const value: PaginationContextState = { paginationState, paginationDispatch };
  return <PaginationContext.Provider value={value}> {children} </PaginationContext.Provider>;
};

export const usePagination = () => {
  const context = useContext(PaginationContext);
  if (context === undefined) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};
