import { loginViews } from 'types/Login';
import LoginForm from '../LoginForm';
import VerificationToken from '../../Common/VerificationToken';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';

//TODO: since some are classes I couldn't type this right. Research later
const LoginComponentByEnum: Record<loginViews, any> = {
  forgotPassword: ForgotPassword,
  login: LoginForm,
  token: VerificationToken,
  resetPassword: ResetPassword,
};

export default LoginComponentByEnum;
