import { TextMessage } from 'components/Messages';
import { Alert } from 'types/PatientCases';
import styles from './styles.module.css';
interface OriginalAlertProps {
  alert: Alert;
}
const OriginalAlert = ({ alert }: OriginalAlertProps) => {
  return (
    <div>
      <TextMessage
        from={{
          firstName: alert.originalAlertProvider.split(' ')[0].charAt(0),
          lastName: alert.originalAlertProvider.split(' ')[1].charAt(0),
        }}
        message={{
          data: alert.name,
          createdAt: alert.created_at,
        }}
        messageClassName={styles.message}
        className="d-flex"
        timeStampClassName={styles.time}
        dataClassName={styles.data}
        bubbleClassName={styles.bubble}
      />
      {alert.originalStatus && <div className={styles.status}>{alert.originalStatus}</div>}
    </div>
  );
};
export default OriginalAlert;
