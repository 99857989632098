import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { Fragment, useCallback } from 'react';
import { DayView, MonthView, WeekView } from '../ActivityTimelineView';

interface SwitchActivityTimelineViewProps {
  timePeriodOptionKey: TimePeriodOptions;
}

const SwitchActivityTimelineView = ({ timePeriodOptionKey }: SwitchActivityTimelineViewProps) => {
  const ComponentByView = useCallback(() => {
    switch (timePeriodOptionKey) {
      case TimePeriodOptions.DAY: {
        return <DayView />;
      }
      case TimePeriodOptions.WEEK: {
        return <WeekView />;
      }
      case TimePeriodOptions.MONTH: {
        return <MonthView />;
      }
      case TimePeriodOptions.CARE_PLAN_PERIOD: {
        return <MonthView isCarePlanPeriod />;
      }
      default:
        return <Fragment />;
    }
  }, [timePeriodOptionKey]);

  return <ComponentByView />;
};
export default SwitchActivityTimelineView;
