import { Dispatch, useCallback, useEffect } from 'react';
import { getPopulations } from 'services/administrationService';
import { useAdministrationContextV2 } from '..';
import { AllAdminActions } from '../administrationReducer';
import { AdminPopulationsState, setAdminPopulations } from '../Populations';

const useAdminPopulations = (): [AdminPopulationsState, Dispatch<AllAdminActions>] => {
  const {
    administrationStateV2: { populations },
    dispatch,
  } = useAdministrationContextV2();

  //TODO: move this to react-query. And add mutations
  const fetchPopulations = useCallback(async () => {
    const populationsRes = await getPopulations();
    dispatch(setAdminPopulations(populationsRes));
  }, [dispatch]);

  useEffect(() => {
    if (!populations.list) fetchPopulations();
  }, [fetchPopulations, populations.list]);

  return [populations, dispatch];
};

export default useAdminPopulations;
