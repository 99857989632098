import FiltersButton from 'components/FiltersButton';
import { SearchBarAlt } from 'components/SearchBar';
import { ChangeEventHandler } from 'react';

interface PopulationCardHeaderProps {
  handleSearch: ChangeEventHandler<HTMLInputElement>;
  search: string;
  onFiltersClick: () => void;
}

const PopulationCardHeader = ({
  handleSearch,
  onFiltersClick,
  search,
}: PopulationCardHeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <div>Populations </div>
      <div className="d-flex gap-sm">
        <SearchBarAlt onChange={handleSearch} value={search} placeholder="Search population..." />
        <FiltersButton onFiltersClick={onFiltersClick} />
      </div>
    </div>
  );
};
export default PopulationCardHeader;
