import TabEnum from './TabEnum';

const templateRoute = '/templates';

const TabRouteMap = {
  [TabEnum.CAREPLAN_TEMPLATES]: `${templateRoute}/careplan-templates`,
  [TabEnum.QUESTIONNAIRES]: `${templateRoute}/questionnaires`,
  [TabEnum.SURVEYS]: `${templateRoute}/surveys`,
};

export default TabRouteMap;
