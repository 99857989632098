import { Role } from 'types/ApiModels/Administration';
import { AdminUsersAction, AdminUsersActionTypes } from './adminUsersActionTypes';

export interface AdminUsersState {
  roles: Role[];
  maritalStatus: string[];
}
export const usersInitialState: AdminUsersState = {
  roles: null,
  maritalStatus: ['Married', 'Single', 'Divorced', 'Widowed'],
};

const adminUsersReducer = (state: AdminUsersState, action: AdminUsersAction): AdminUsersState => {
  switch (action.type) {
    case AdminUsersActionTypes.UPSERT_ROLE: {
      const roleIdx = state.roles.findIndex((r) => r.id === action.payload.role.id);
      if (roleIdx === -1) {
        return { ...state, roles: [...state.roles, action.payload.role] };
      }
      const rolesCopy = [...state.roles];
      rolesCopy[roleIdx] = action.payload.role;
      return { ...state, roles: rolesCopy };
    }
    case AdminUsersActionTypes.UPSERT_ROLE_BULK: {
      const rolesCopy = [...state.roles];
      action.payload.roles.forEach((role) => {
        const roleIdx = rolesCopy.findIndex((r) => r.id === role.id);
        if (roleIdx === -1) {
          rolesCopy.push(role);
        } else {
          rolesCopy[roleIdx] = role;
        }
      });
      return { ...state, roles: rolesCopy };
    }
    case AdminUsersActionTypes.SET_ROLES: {
      return { ...state, roles: action.payload.roles };
    }
    default: {
      console.error('Unhandled action in adminUserReducer');
      return state;
    }
  }
};

export default adminUsersReducer;
