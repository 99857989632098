import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const HospitalizationIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 12V7.33333H7.5V12H10V0H7.5V4.66667H2.5V0H0V12H2.5Z"
        fill={color}
      />
    </svg>
  );
};

export default HospitalizationIcon;
