import { Col, Row } from 'react-bootstrap';
import ComplianceIssues from './Components/ComplianceIssues';
import PatientSatisfaction from './Components/PatientSatisfaction';

const ComplianceAndSatisfactionTab = () => {
  return (
   <div>
     <Row>
        <Col className="my-3" lg={6}>
         <ComplianceIssues></ComplianceIssues>
        </Col>
        <Col className="my-3" lg={4} >
          <PatientSatisfaction></PatientSatisfaction>
        </Col>
      </Row>
   </div>
  );
};

export default ComplianceAndSatisfactionTab;
