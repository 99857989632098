import { CareTeam, CareTeamCreateUpdateRequest } from 'types/ApiModels/CareTeam';
import { PaginatedResponse } from 'types/ApiModels/General';

export enum CareTeamActionTypes {
  RESET_CARE_TEAM_FORM = 'RESET_CARE_TEAM_FORM',
  SET_CARE_TEAMS_SUBMIT_FORM = 'SET_CARE_TEAMS_SUBMIT_FORM',
  RESET_UPDATED_CARE_TEAMS = 'RESET_UPDATED_CARE_TEAMS',
  RESET_DELETED_CARE_TEAMS = 'RESET_DELETED_CARE_TEAMS',
  SET_CARE_TEAMS_BOOK = 'SET_CARE_TEAMS_BOOK',
  SET_CURRENT_CARE_TEAM = 'SET_CURRENT_CARE_TEAM',
  SET_CARE_TEAMS_TABLE_BOOK = 'SET_CARE_TEAMS_TABLE_BOOK',
  MATCH_CARE_TEAMS_TABLE_BOOK_WITH_BOOK = 'MATCH_CARE_TEAMS_TABLE_BOOK_WITH_BOOK',
  UPDATE_CARE_TEAM_TABLE_PAGE = 'UPDATE_CARE_TEAM_TABLE_PAGE',
  UPDATE_CARE_TEAM_DELETE = 'UPDATE_CARE_TEAM_DELETE',
  UPDATE_CURRENT_BOOK_PAGE = 'UPDATE_CURRENT_BOOK_PAGE',
  SET_CARE_TEAMS_BOOK_AND_MATCH_TABLE = 'SET_CARE_TEAMS_BOOK_AND_MATCH_TABLE',
  SET_CREATE_CARE_TEAM = 'SET_CREATE_CARE_TEAM',
  APPEND_CREATED_CARE_TEAM = 'APPEND_CREATED_CARE_TEAM',
  REMOVE_CREATED_CARE_TEAM = 'REMOVE_CREATED_CARE_TEAM',
  APPEND_UPDATED_CARE_TEAM = 'APPEND_UPDATED_CARE_TEAM',
  RESET_UPDATE_CREATED_CARE_TEAMS = 'RESET_UPDATE_CREATED_CARE_TEAMS',
  //--AppendType
}

export type CareTeamAction =
  | {
      type: CareTeamActionTypes.SET_CARE_TEAMS_BOOK;
      payload: { careTeamBook: Record<number, PaginatedResponse<CareTeam>> };
    }
  | { type: CareTeamActionTypes.SET_CURRENT_CARE_TEAM; payload: { careTeamId: number } }
  | {
      type: CareTeamActionTypes.SET_CARE_TEAMS_BOOK_AND_MATCH_TABLE;
      payload: { careTeamsBook: Record<number, PaginatedResponse<CareTeam>> };
    }
  | {
      type: CareTeamActionTypes.UPDATE_CARE_TEAM_DELETE;
      payload: { careTeamId: number; page: number };
    }
  | {
      type: CareTeamActionTypes.RESET_CARE_TEAM_FORM;
    }
  | {
      type: CareTeamActionTypes.SET_CARE_TEAMS_SUBMIT_FORM;
      payload: { submitForm: () => void };
    }
  | {
      type: CareTeamActionTypes.RESET_UPDATED_CARE_TEAMS;
    }
  | {
      type: CareTeamActionTypes.RESET_DELETED_CARE_TEAMS;
    }
  | {
      type: CareTeamActionTypes.SET_CREATE_CARE_TEAM;
      payload: { createCareTeam: () => void };
    }
  | {
      type: CareTeamActionTypes.APPEND_CREATED_CARE_TEAM;
      payload: {
        careTeam: CareTeamCreateUpdateRequest;
      };
    }
  | {
      type: CareTeamActionTypes.REMOVE_CREATED_CARE_TEAM;
      payload: {
        localId: number;
      };
    }
  | {
      type: CareTeamActionTypes.APPEND_UPDATED_CARE_TEAM;
      payload: {
        updatedCareTeam: CareTeamCreateUpdateRequest;
      };
    }
  | {
      type: CareTeamActionTypes.RESET_UPDATE_CREATED_CARE_TEAMS;
      payload: {
        updatedCareTeams: CareTeam[];
      };
    } /**--AppendAction */;
