import { object, string, array, number } from 'yup';

const REQUIRED_MESSAGE = 'Required';

export const CareTeamEditValidationSchema = object().shape({
  name: string().required(REQUIRED_MESSAGE),
  tiers: array().of(
    object().shape({
      id: number(),
      name: string().required(REQUIRED_MESSAGE),
      number: number(),
      providers: array().of(object()),
    })
  ),
});

export const CareTeamCreateValidationSchema = object().shape({
  name: string().required(REQUIRED_MESSAGE),
  tiers: array().of(
    object().shape({
      id: number(),
      name: string().required(REQUIRED_MESSAGE),
      number: number(),
      providers: array()
        .of(object())
        .min(1, 'Select at least one provider for this tier')
        .required(REQUIRED_MESSAGE),
    })
  ),
});
