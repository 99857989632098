import { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as EmptyChatsIcon } from 'assets/icons/chats_empty.svg';
import { ControlTabLink, ControlTabLinkContainer } from 'components/ControlTabLink';
import EmptyList from 'components/EmptyList';

import styles from './styles.module.css';
import BroadcastItem from './broadcast-item';
import { MockBroadcast } from 'screens/messages/mocks/types';

interface BroadcastListProps {
  broadcasts: MockBroadcast[];
  openedBroadcastId?: number;
}
const BroadcastList: FC<BroadcastListProps> = ({
  broadcasts,
  openedBroadcastId,
}: BroadcastListProps) => {
  const { pathname } = useLocation();

  const emptyListMessage = useMemo(
    () =>
      pathname === '/messages/broadcast'
        ? 'Your old broadcast messages will appear here'
        : 'Your broadcast templates will appear here',
    [pathname]
  );

  const selectable = useMemo(() => (pathname === '/messages/broadcast' ? true : false), [pathname]);

  const checkOpened = useCallback(
    (currentId: number) => {
      return currentId === openedBroadcastId;
    },
    [openedBroadcastId]
  );

  return (
    <>
      <ControlTabLinkContainer className="mr-4 mt-4">
        <ControlTabLink to={'/messages/broadcast'} exact>
          Past broadcasts
        </ControlTabLink>
        <ControlTabLink to={'/messages/broadcast/templates'} exact>
          Broadcast templates
        </ControlTabLink>
      </ControlTabLinkContainer>
      <div className={styles['chats-container']}>
        {broadcasts.length > 0 ? (
          broadcasts.map((broadcast) => (
            <BroadcastItem
              broadcast={broadcast}
              key={broadcast.id}
              selectable={selectable}
              opened={checkOpened(broadcast.id)}
            />
          ))
        ) : (
          <EmptyList
            Icon={EmptyChatsIcon}
            className="my-5"
            title={emptyListMessage}
            fill="#EBE3F1"
          />
        )}
      </div>
    </>
  );
};
export default BroadcastList;
