import Button from 'components/Button';
import InputField from 'components/InputField';
import VyTracModal from 'components/Modal';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { IResourceCategory, IResourceResult } from 'types/Resources/Resources';
import styles from './styles.module.css';

interface ResourcesModalProps {
  newResource: IResourceResult;
  setNewResource: Dispatch<SetStateAction<IResourceResult>>;
  locationList: Array<any>;
  resourceTypeList: Array<any>;
  categories: IResourceCategory[];
  handleClose: () => void;
  handleConfirm: (newCategory: IResourceCategory) => void;
  showModal: boolean;
}

const ResourcesModal: FC<ResourcesModalProps> = ({
  newResource,
  setNewResource,
  categories,
  locationList,
  resourceTypeList,
  handleClose,
  handleConfirm,
  showModal,
}: ResourcesModalProps) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);

  useEffect(() => {
    setSelectedType(null);
    setSelectedLocation(null);
    setSelectedCategories(null);
    if (newResource.type) setSelectedType({ label: newResource.type, value: newResource.type });
    if (newResource.location)
      setSelectedLocation({ label: newResource.location, name: newResource.location });
    if (newResource.categories) {
      newResource.categories.forEach((cat) => {
        setSelectedCategories({ label: cat.name, value: cat.id });
      });
    }
  }, [newResource]);

  const setCategoriesList = useMemo(
    () => categories?.map((c) => ({ label: c.name, value: c.id })) ?? [],
    [categories]
  );

  const onChangeName = (value: string) => {
    setNewResource({ ...newResource, name: value });
  };
  const onChangeType = (option: any) => {
    setNewResource({ ...newResource, type: option.value });
  };
  const onChangeCategory = (category: any) => {
    const categorySelected: IResourceCategory = { id: category.value, name: category.label };
    const categories: IResourceCategory[] = [];
    categories.push(categorySelected);
    setNewResource({ ...newResource, categories: categories });
    setSelectedCategories(category);
  };
  const onChangeLocation = (option: any) => {
    setNewResource({ ...newResource, location: option.value });
  };
  const onChangeUrl = (value: string) => {
    setNewResource({ ...newResource, url: value });
  };

  return (
    <VyTracModal
      show={showModal}
      title="Add new resource"
      onClose={handleClose}
      body={
        <div className="d-flex flex-column gap">
          <div className={styles.rowContainer}>
            <div className={styles.title}>Name</div>
            <div className={styles.content}>
              <InputField
                onChange={(e) => onChangeName(e.target.value)}
                value={newResource.name}
                className={styles.input}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Category</div>
            <div className={styles.content}>
              <Select
                options={setCategoriesList}
                placeholder="Assign Categories"
                onChange={(values) => onChangeCategory(values)}
                className={styles.select}
                value={selectedCategories}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Resource Type</div>
            <div className={styles.content}>
              <Select
                options={locationList}
                placeholder="Assign Resource Type"
                onChange={(values) => onChangeType(values)}
                className={styles.select}
                value={selectedType}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Location</div>
            <div className={styles.content}>
              <Select
                options={resourceTypeList}
                placeholder="Assign Location"
                onChange={(values) => onChangeLocation(values)}
                className={styles.select}
                value={selectedLocation}
              />
            </div>
          </div>
          <div className={styles.marginResourceAddress}>
            <div className={styles.title}>Resource address</div>
            <div className={styles.content}>
              <InputField
                onChange={(e) => onChangeUrl(e.target.value)}
                value={newResource.url}
                className={styles.inputLink}
              />
            </div>
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={handleClose} />
          <Button label="save" onClick={() => handleConfirm(newResource)} />
        </div>
      }
    />
  );
};
export default ResourcesModal;
