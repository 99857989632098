import moment from 'moment';
import React, { useCallback } from 'react';
import styles from './syles.module.css';

const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

interface IWeekDays {
  actionStartDate: string;
  weekDaysSelected: string[];
  onRepeatsParamChange: (newParam: string[]) => void;
}
const WeekDays = ({ actionStartDate, weekDaysSelected, onRepeatsParamChange }: IWeekDays) => {
  const onDayClick = useCallback(
    (day: string) => {
      if (moment(actionStartDate).day().toString() === day) return;

      const updatedDaysSelected = [...weekDaysSelected];
      if (!weekDaysSelected.includes(day)) {
        updatedDaysSelected.push(day);
      } else {
        const dayPosition = updatedDaysSelected.indexOf(day);
        updatedDaysSelected.splice(dayPosition, 1);
      }
      onRepeatsParamChange(updatedDaysSelected);
    },
    [actionStartDate, onRepeatsParamChange, weekDaysSelected]
  );

  return (
    <div className="d-flex justify-content-between">
      {weekDays.map((day, index) => (
        <div
          key={index}
          onClick={() => {
            onDayClick(index.toString());
          }}
          className={
            weekDaysSelected.includes(index.toString()) ? styles.weekDaySelected : styles.weekDay
          }
        >
          {day}
        </div>
      ))}
    </div>
  );
};
export default WeekDays;
