import patientIcon1 from '../assets/images/dashboard/patient2.png';
import patientIcon2 from '../assets/images/dashboard/patient3.png';
import patientIcon3 from '../assets/images/dashboard/patient4.png';
import patientIcon4 from '../assets/images/dashboard/patient5.png';
import patientIcon5 from '../assets/images/dashboard/patient6.png';
import patientIcon6 from '../assets/images/dashboard/patient7.png';

export const getBillingList = async () => {
  return await Promise.resolve([
    {
      patient: {
        firstName: 'Diana',
        lastName: 'Prince',
        profileIcon: patientIcon1,
        username: 'dprince',
      },
      id: '2934292',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [
        {
          name: '99453',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
        {
          name: '99452',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
        {
          name: '99490',
          category: 'CCM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
        {
          name: '99975',
          category: 'RTM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
      ],
    },
    {
      patient: {
        firstName: 'John',
        lastName: 'Constantine',
        username: 'jconstantine',
        profileIcon: patientIcon2,
      },
      id: '2934293',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [
        {
          name: '99453',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
      ],
    },
    {
      patient: {
        firstName: 'Bruce',
        lastName: 'Wayne',
        username: 'bwayne',
        profileIcon: patientIcon3,
      },
      id: '2934296',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [],
    },
    {
      patient: {
        firstName: 'John',
        lastName: 'Constantine',
        username: 'jconstantine',
        profileIcon: patientIcon2,
      },
      id: '2934293',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [
        {
          name: '99453',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
      ],
    },
    {
      patient: {
        firstName: 'Shiera',
        lastName: 'Sanders Hall',
        username: 'ssanders',
        profileIcon: patientIcon4,
      },
      id: '2934297',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [
        {
          name: '99453',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
      ],
    },
    {
      patient: {
        firstName: 'Arthur',
        lastName: 'Curry',
        username: 'acurry',
        profileIcon: patientIcon5,
      },
      id: '2934298',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [
        {
          name: '99453',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
      ],
    },
    {
      patient: {
        firstName: 'Carter',
        lastName: 'Hall',
        username: 'chall',
        profileIcon: patientIcon6,
      },
      id: '2934299',
      eligibleCodes: 4,
      readyToSubmit: 2,
      submitted: 2,
      paid: '$2800 / $5820',
      codes: [
        {
          name: '99453',
          category: 'RPM',
          value: '$850',
          timeSpent: '120 min ',
          dueBillDate: '05.30.2021',
          lastBillDate: '04.29.2021',
          dateOfService: '05.30.2021',
          providers: '2 providers',
          requiredTime: '20 minutes',
          status: 'Eligible',
        },
      ],
    },
  ]);
};
