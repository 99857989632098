import DropdownMenu from 'components/DropdownMenu';
import ProviderTierItem from 'components/ProviderTierItem';
import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import React, { useEffect, useMemo, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { CareTeam, CareTeamProvider, CareTeamTier } from 'types/ApiModels/CareTeam';
import getCareTeamInitials from 'util/getTeamInitials';
import styles from './styles.module.css';

interface ICareTeamPopup {
  careTeam: CareTeam;
  showPopup: boolean;
  leftPosition: number;
  onProviderClick: (provider: CareTeamProvider, topPosition: number) => void;
  closeCareTeamPopup: () => void;
}

const CareTeamPopup = ({
  careTeam,
  showPopup,
  leftPosition,
  onProviderClick,
  closeCareTeamPopup,
}: ICareTeamPopup) => {
  const containerRef = useRef<HTMLDivElement>();

  const sortedTiers = useMemo(() => {
    return careTeam.tiers.sort((a, b) => a.number - b.number);
  }, [careTeam]);

  const teamInitials: string[] = useMemo(() => {
    if (!careTeam) return [];
    return getCareTeamInitials(careTeam);
  }, [careTeam]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        closeCareTeamPopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, closeCareTeamPopup]);

  return (
    <div className={styles.popupContainer} style={{ left: leftPosition - 700 }} ref={containerRef}>
      <div className={styles.popupHeader}>
        <Col className="d-flex align-items-center">
          <UserBubbleGroupCollapsed initials={teamInitials} />
          <div className={styles.popupHeaderText}>{careTeam.name}</div>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <div className={styles.manageTeams}>Manage teams</div>
        </Col>
      </div>
      {sortedTiers.map((tier: CareTeamTier) => (
        <DropdownMenu name={`Tier ${tier.number}`} open={showPopup} key={tier.number}>
          <div>
            {tier.providers.map((provider, idx) => (
              <div className={styles.providerContainer} key={idx}>
                <ProviderTierItem
                  provider={provider}
                  enableDelete={false}
                  onNameClick={onProviderClick}
                />
              </div>
            ))}
          </div>
        </DropdownMenu>
      ))}
    </div>
  );
};
export default CareTeamPopup;
