import { useContext } from 'react';
import GenericSearchBar from 'components/SearchBar';
import Context from '../../../../../util/Context';

const SearchBar = () => {
  const { setState } = useContext(Context);

  const handleChange = (event) => setState((state) => ({ ...state, searchValue: event.target.value }));

  return <GenericSearchBar className="mr-4" onChange={handleChange} />;
};

export default SearchBar;
