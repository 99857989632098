import Button from 'components/Button';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';

const modalOptions: { name: string; onClick: (e: React.MouseEvent) => void }[] = [
  {
    name: 'Contact emergency contact',
    onClick: () => null,
  },
  {
    name: 'Contact care champion',
    onClick: () => null,
  },
  {
    name: 'Call 911 for patient',
    onClick: () => null,
  },
  {
    name: 'Log 911 call',
    onClick: () => null,
  },
  {
    name: 'Log hospitalization',
    onClick: () => null,
  },
  {
    name: 'Set patient deceased',
    onClick: () => null,
  },
];

const EmergencyButton = () => {
  const [showEmergencyOptions, setShowEmergencyOptions] = useState<boolean>(false);
  const emergencyOptionsRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emergencyOptionsRef.current && !emergencyOptionsRef.current.contains(event.target)) {
        setShowEmergencyOptions(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emergencyOptionsRef]);

  return (
    <>
      <Button
        className="position-relative mx-1"
        label="emergency"
        variant="delete"
        onClick={() => setShowEmergencyOptions(true)}
      />
      {showEmergencyOptions && (
        <div className={styles.emergencyOptionContainer} ref={emergencyOptionsRef}>
          {modalOptions.map(({ name, onClick }) => (
            <div key={name} onClick={onClick}>
              {name}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default EmergencyButton;
