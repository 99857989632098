import React from 'react';
import GenericPatientTable from 'components/GenericPatientTable';
import cloudArrowDown from 'assets/icons/cloudArrowDown.png';
import styles from './styles.module.css';
import SatisfactionBar from 'components/SatisfactionBar';

const Body = ({ onIdClick, ...props }) => {
  // Functions and subroutines
  const mapPropsToTableContent = (props) => ({
    header: [
      'Reference ID',
      'Satisfaction',
      'Status',
      'Last interaction',
      'Files',
      'Chat time',
      'Audio/video time',
      'Date',
      'Download',
    ],
    body: [
      [
        '#34235553',
        <SatisfactionBar value={70} />,
        'Escalated to Hugo Strange',
        'Thomas Wayne',
        '3',
        '0m 36s',
        'n/a',
        '05.10.2021   •   3:25 pm',
        <img
          className="cursor-pointer"
          src={cloudArrowDown}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />,
      ],
      [
        '#34235553',
        <SatisfactionBar value={70} />,
        'Escalated to Hugo Strange',
        'Thomas Wayne',
        '4',
        '0m 36s',
        'n/a',
        '05.10.2021   •   3:25 pm',
        <img
          className="cursor-pointer"
          src={cloudArrowDown}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />,
      ],
      [
        '#34235553',
        <SatisfactionBar value={70} />,
        'Adjusted thresholds',
        'Thomas Wayne',
        '5',
        '0m 36s',
        'n/a',
        '05.10.2021   •   3:25 pm',
        <img
          className="cursor-pointer"
          src={cloudArrowDown}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />,
      ],
      [
        '#34235553',
        <SatisfactionBar value={70} />,
        'Completed',
        'Thomas Wayne',
        '1',
        '0m 36s',
        'n/a',
        '05.10.2021   •   3:25 pm',
        <img
          className="cursor-pointer"
          src={cloudArrowDown}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />,
      ],
    ],
  });

  // Variables and constants
  const { header, body } = mapPropsToTableContent(props);

  return (
    <GenericPatientTable
      header={header}
      body={body}
      thClassName={styles.th}
      tdClassName={styles.td}
      onIdClick={onIdClick}
    />
  );
};

export default Body;
