import React from 'react';
import Card from 'components/Card';
import InputField from 'components/InputField';
import { Tagitem } from 'components/Tagitem';
import styles from './styles.module.css';

interface IOptions {
  name: string;
  setName: (newName: string) => void;
  usedFor?: any;
  header: string;
  label: string;
}
const Options = ({ name, setName = () => null, usedFor, header, label }: IOptions) => {
  return (
    <Card
      className={styles.container}
      headers={[header]}
      bodyClassName={styles.body}
      headerClassName={styles.header}
    >
      <InputField
        containerClassName="flex-column"
        label={label}
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {usedFor && usedFor.length > 0 && (
        <>
          <div>Used for</div>
          <div className={styles.tags}>
            {usedFor?.map((item, index) => (
              <Tagitem key={index} text={item} />
            ))}
          </div>
        </>
      )}
    </Card>
  );
};

export default Options;
