import React, { useMemo } from 'react';
import { IDashboardToDoAlert } from 'types/ApiModels/Dashboard/DashboardToDo';
import { rpmActionToUnit } from 'util/rpmActionToUnit';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import { ReactComponent as ActivityPointsIcon } from 'assets/icons/activity-points-icon.svg';
import styles from './styles.module.css';

interface TodoAlertProps {
  event: IDashboardToDoAlert;
}

export const CompletedAlertInfo = () => {
  return (
    <div className="d-flex align-items-center" style={{ color: 'var(--green-completed' }}>
      <div className="font-size-big" style={{ marginRight: '5px' }}>
        Completed
      </div>
      <ActivityPointsIcon fill="var(--green-completed)" height={10} width={12} />
      <b className="font-size-big">+3</b>
    </div>
  );
};

const TodoAlert = ({ event }: TodoAlertProps) => {
  const Icon = useMemo(() => rpmActionTypeToIcon[event?.vytal?.type], [event]);
  const alertColor = useMemo(() => {
    if (event.is_completed) return 'var(--green-completed)';
    if (event?.type == 'red' || event?.type == 'critical') return 'var(--red-high-risk)';
    if (event?.type === 'yellow') return 'var(--yellow-medium-risk)';
  }, [event]);

  return (
    <div className={styles['header']}>
      <div className={styles['circle']} style={{ borderColor: alertColor }}>
        {Icon && <Icon width="12px" height="11px" color={alertColor} />}
      </div>
      <span className={styles['title']} style={{ color: event.is_completed && alertColor }}>
        {event.vytal.name} {parseFloat(event.vytal.value)} {rpmActionToUnit[event.vytal.type]}
      </span>
    </div>
  );
};
export default TodoAlert;
