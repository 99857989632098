import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import TabEnum from 'screens/Templates/util/TabEnum';
import TabRouteMap from 'screens/Templates/util/TabRouteMap';
import styles from './styles.module.css';

const Actions = () => {
  const history = useHistory();
  return (
    <div className={styles.surveyTemplateActions}>
      <Button
        label="create new survey"
        onClick={() => history.push(`${TabRouteMap[TabEnum.SURVEYS]}/create`)}
      />
    </div>
  );
};
export default Actions;
