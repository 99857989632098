import { PopulationWithCount } from 'types/ApiModels/Patients/Population';

export enum AdminPopulationActionTypes {
  SET_POPULATIONS = 'SET_POPULATIONS',
  APPEND_UPDATE_POPULATION = 'APPEND_UPDATE_POPULATION',
  APPEND_DELETE_POPULATION = 'APPEND_DELETE_POPULATION',
  SET_RESET_CHANGES = 'SET_RESET_CHANGES',
  SET_SUBMIT_CHANGES = 'SET_SUBMIT_CHANGES',
  CLEAR_POPULATION_CHANGES = 'CLEAR_POPULATION_CHANGES',
}

export type AdminPopulationAction =
  | {
      type: AdminPopulationActionTypes.SET_POPULATIONS;
      payload: { populations: PopulationWithCount[] };
    }
  | {
      type: AdminPopulationActionTypes.APPEND_UPDATE_POPULATION;
      payload: { updatedValue: { id: number; value: boolean } };
    }
  | {
      type: AdminPopulationActionTypes.APPEND_DELETE_POPULATION;
      payload: { populationId: number };
    }
  | {
      type: AdminPopulationActionTypes.SET_RESET_CHANGES;
      payload: { resetChanges: () => void };
    }
  | {
      type: AdminPopulationActionTypes.SET_SUBMIT_CHANGES;
      payload: { submitChanges: () => void };
    }
  | {
      type: AdminPopulationActionTypes.CLEAR_POPULATION_CHANGES;
    };
