import { Dispatch, useCallback, useEffect, useMemo } from 'react';
import DashboardAdherence from 'types/ApiModels/Dashboard/DashboardAdherence';
import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { setAdherence } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useDashboardAdherence = (): [
  PaginatedRequestHook<DashboardAdherence>,
  Dispatch<DashboardAction>
] => {
  const {
    state: { adherence },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const paginationRequest = usePaginatedRequest(
    'providers/dashboard/adherence/',
    10,
    adherence,
    false
  );

  const init = useMemo(() => {
    return paginationRequest.init;
  }, [paginationRequest.init]);

  const adherenceInit = useCallback(async () => {
    fetchRegistry.current.adherence.list = true;
    await init();
    fetchRegistry.current.adherence.list = false;
  }, [fetchRegistry, init]);

  useEffect(() => {
    if (!adherence && !fetchRegistry.current.adherence.list) {
      adherenceInit();
    }
  }, [fetchRegistry, adherence, adherenceInit]);

  useEffect(() => {
    dispatch(setAdherence(paginationRequest.book));
  }, [dispatch, paginationRequest.book]);

  return [paginationRequest, dispatch];
};

export default useDashboardAdherence;
