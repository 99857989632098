import { EndDateType } from 'types/ApiModels/CarePlan/EndDateType';

export enum EndDateTypeLabel {
  DURATION = 'Duration',
  FIXED_DATE = 'Fixed Date',
  END_OF_ADHERENCE_BLOCK = 'End Of Adherence Block',
  NONE = 'None',
}

export const mapLabelToEndDateType: (label: EndDateTypeLabel) => EndDateType = (label) => {
  switch (label) {
    case EndDateTypeLabel.DURATION:
      return 'duration';
    case EndDateTypeLabel.FIXED_DATE:
      return 'fixed_date';
    case EndDateTypeLabel.END_OF_ADHERENCE_BLOCK:
      return 'end_of_adherence_block';
    case EndDateTypeLabel.NONE:
      return 'none';
    default:
      return null;
  }
};

export const mapEndDateTypeLabel: (endDateType: EndDateType) => string = (eventType) => {
  switch (eventType) {
    case 'duration':
      return EndDateTypeLabel.DURATION;
    case 'fixed_date':
      return EndDateTypeLabel.FIXED_DATE;
    case 'end_of_adherence_block':
      return EndDateTypeLabel.END_OF_ADHERENCE_BLOCK;
    case 'none':
      return EndDateTypeLabel.NONE;
    default:
      return 'Add Care Plan Action';
  }
};
