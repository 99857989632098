import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface IConfirmCancelModal {
  show: boolean;
  onCancel: () => void;
  onDiscard: () => void;
}
const ConfirmCancelModal = ({ onCancel, onDiscard, show }: IConfirmCancelModal) => {
  return (
    <VytracModal
      show={show}
      title="Cancel modifications"
      body={
        <span className="font-size-big">
          You have unsaved changes. are you sure you want to discard them?
        </span>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={onCancel} />
          <Button label="discard" variant="delete" onClick={onDiscard} />
        </div>
      }
      onClose={onCancel}
    />
  );
};
export default ConfirmCancelModal;
