import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { useMemo } from 'react';
import ReactSelect from 'react-select';

const timePeriods = [1, 5, 10, 15, 30];
interface TimePeriodDropdownProps {
  onChange: (period: number) => void;
}
const TimePeriodDropdown = ({ onChange }: TimePeriodDropdownProps) => {
  const selectOptions = useMemo(
    () =>
      timePeriods.map((period) => ({
        label: `${period} day${period > 1 ? 's' : ''}`,
        value: period,
      })),
    []
  );

  return (
    <ReactSelect
      styles={{
        ...vytracSelectStyle,
        control: (provided) => ({
          ...provided,
          ...vytracSelectStyle.control(provided),
          backgroundColor: '#fff',
        }),
      }}
      options={selectOptions}
      onChange={(option) => onChange(option.value)}
    />
  );
};
export default TimePeriodDropdown;
