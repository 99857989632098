import React, { ChangeEventHandler, useRef, useState } from 'react';
import styles from './styles.module.css';
import mag_glass from '../../assets/icons/mag_glass.png';

interface SearchBarAltProps {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  className?: string;
}

/**
 * A controlled alternative to SearchBar
 */
export const SearchBarAlt = ({
  className,
  placeholder = 'Search tags',
  onChange,
  value: controlledValue,
}: SearchBarAltProps) => {
  const [innerValue, setInnerValue] = useState('');
  const inputRef = useRef(null);

  const handleClick = () => inputRef.current.focus();

  const innerHandleChange = (e) => {
    setInnerValue(e.target.value);
  };

  const value = controlledValue ?? innerValue;
  const handleChange = controlledValue != null ? onChange : innerHandleChange;

  return (
    <div className={`${className || ''} ${styles.main}`} onClick={handleClick}>
      <img src={mag_glass} alt="" />
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};
