import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getAnalyticsNewPatients } from 'services/analyticsService';
import { NewPatients } from 'types/ApiModels/Analytics/AnalyticsNewPatients';
import DatePickerValue from 'types/Shared/DatePicker';
import { setNewPatients } from '../AnalyticsActionCreators';
import { AnalyticsAction } from '../AnalyticsActionTypes';
import { useAnalyticsContext } from '../AnalyticsContext';

const useNewPatients = (
dateFrom: DatePickerValue,
dateTo: DatePickerValue
): [NewPatients[], Dispatch<AnalyticsAction>, () => void] => {
  const {
    state: { newPatients },
    dispatch,
    fetchRegistry,
  } = useAnalyticsContext();

  const [newPatientsState, setNewPatientsState] = useState<NewPatients[]>(null);

  const previousDateFrom = useRef(dateFrom);
  const previousDateTo = useRef(dateTo);
  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    previousDateFrom.current = { year: null, month: null, day: null };
    previousDateTo.current = { year: null, month: null, day: null };
    fetchRegistry.current.newPatients.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchNewPatients = useCallback(async () => {
    if (!dateFrom || !dateTo) return;
    const data = await getAnalyticsNewPatients(
      `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`,
      `${dateTo.year}-${dateTo.month}-${dateTo.day}`
    );
    setNewPatientsState(data);
  }, [dateFrom, dateTo]);

  const newPatientInit = useCallback(async () => {
    fetchRegistry.current.newPatients.list = true;
    await fetchNewPatients();
    fetchRegistry.current.newPatients.list = false;
  }, [fetchRegistry, fetchNewPatients]);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    if (
      previousDateFrom.current.year != dateFrom.year ||
      previousDateFrom.current.month != dateFrom.month ||
      previousDateFrom.current.day != dateFrom.day ||
      previousDateTo.current.year != dateTo.year ||
      previousDateTo.current.month != dateTo.month ||
      previousDateTo.current.day != dateTo.day ||
      (!newPatients && !fetchRegistry.current.newPatients.list)
    ) {
        newPatientInit();
      previousDateFrom.current = { ...dateFrom };
      previousDateTo.current = { ...dateTo };
    }
  }, [fetchRegistry, newPatients, newPatientInit, dateFrom, dateTo,  _refetch.current]);

  useEffect(() => {
    dispatch(setNewPatients(newPatientsState));
  }, [dispatch, newPatientsState]);

  return [newPatientsState, dispatch, refetch];
};

export default useNewPatients;
