import Card from 'components/Card';
import CardWithCounter from 'components/CardWithCounter';
import { Provider } from 'types/ApiModels/Providers/Provider';
interface ProviderPatientCasesProps {
  selectedProvider: Provider;
}

const ProviderPatientCases = ({ selectedProvider }: ProviderPatientCasesProps) => {
  return (
    <Card
      className="w-100 card-bg-border"
      bodyClassName="d-flex flex-column gap"
      headers={['Patient cases']}
    >
      <CardWithCounter label="Require follow-up" count={selectedProvider.follow} />
      <CardWithCounter label="Open cases" count={selectedProvider.open_cases} />
      <CardWithCounter label="Completed" count={selectedProvider.completed_cases} />
    </Card>
  );
};
export default ProviderPatientCases;
