import { durationUnit } from 'types/ApiModels/Templates/CarePlan';

export enum DurationLabel {
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
  NOT_APPLICABLE = 'n/a',
}

export const mapDurationLabelToUnit = (label: DurationLabel): durationUnit => {
  switch (label) {
    case DurationLabel.DAY: {
      return 'days';
    }
    case DurationLabel.WEEK: {
      return 'weeks';
    }
    case DurationLabel.MONTH: {
      return 'months';
    }
    case DurationLabel.NOT_APPLICABLE: {
      return null;
    }
  }
};

export const mapDurationUnitToLabel = (unit: durationUnit): DurationLabel => {
  switch (unit) {
    case 'days': {
      return DurationLabel.DAY;
    }
    case 'weeks': {
      return DurationLabel.WEEK;
    }
    case 'months': {
      return DurationLabel.MONTH;
    }
    case null: {
      return DurationLabel.NOT_APPLICABLE;
    }
  }
};
