interface RowsRenderer {
  /**
   * Get a number that is not currently in use
   */
  renderNew: (registryId: number, renderPosition: number) => number;
  getExisting(registryId: number);
  /**
   * Either render a new value or get the existing registry by the item id
   */
  renderNewOrGetExisting: (registryId: number, renderPosition: number) => number;
  /**
   * Free up a number so that it becomes available for future `renderNew` calls
   */
  popRow: (row: number) => void;
  popRowByRegistryId: (registryId: number) => void;
  clear: () => void;
}

export const createRowsRenderer = (spanSize: number): RowsRenderer => {
  let unavailable: Record<number, boolean> = {};
  let registry: Record<number, number> = {};
  let renderRowAvailability: Record<number, boolean[]> = {};
  const baseRenderRowSpan = Array(spanSize).fill(true);

  const changeRenderRowAvailability = (row, position, value) => {
    if (!renderRowAvailability[row]) {
      renderRowAvailability[row] = [...baseRenderRowSpan];
    }
    renderRowAvailability[row][position] = value;
  };

  const renderNew = (registryId: number, renderPosition: number): number => {
    let row = 0;
    let span = renderRowAvailability[row] ?? [...baseRenderRowSpan];

    while (unavailable[row] || !span[renderPosition]) {
      row++;
      span = renderRowAvailability[row] ?? [...baseRenderRowSpan];
    }
    unavailable[row] = true;
    registry[registryId] = row;
    changeRenderRowAvailability(row, renderPosition, false);
    return row;
  };

  const getExisting = (registryId: number) => {
    return registry[registryId];
  };

  const renderNewOrGetExisting = (registryId: number, renderPosition: number): number => {
    const existingRegistryRenderRow = getExisting(registryId);
    if (existingRegistryRenderRow >= 0) {
      changeRenderRowAvailability(existingRegistryRenderRow, renderPosition, false);
      return registry[registryId];
    }
    return renderNew(registryId, renderPosition);
  };

  const popRow = (row: number): void => {
    unavailable[row] = undefined;
    for (const [key, value] of Object.entries(registry)) {
      if (value === row) {
        registry[key] = undefined;
      }
    }
  };

  const popRowByRegistryId = (registryId: number): void => {
    const registryEntry = registry[registryId];
    if (registryEntry >= 0) popRow(registryEntry);
  };

  const clear = () => {
    unavailable = {};
    registry = {};
    renderRowAvailability = {};
  };

  return {
    renderNew,
    getExisting,
    renderNewOrGetExisting,
    popRow,
    popRowByRegistryId,
    clear,
  };
};
