import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { ITemplateCarePlan, ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { TemplateCarePlanAction, TemplateCarePlanActionTypes } from './templateCarePlanActionType';

export const resetState = (): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.RESET_STATE,
});

export const setCarePlanState = (carePlanState: ITemplateCarePlan): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_CAREPLAN_STATE,
  payload: {
    carePlanState,
  },
});

export const setPreviousPatientActions = (): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_PREVIOUS_PATIENT_ACTIONS,
});

export const setSelectedDate = (selectedDate: Date): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_SELECTED_DATE,
  payload: { selectedDate },
});

export const setPatientActions = (
  patientActions: IPatientCarePlanAction[]
): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_PATIENT_ACTIONS,
  payload: { patientActions },
});

export const setTimelineActions = (
  timelineActions: ITemplateCarePlanAction[]
): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_TIMELINE_ACTIONS,
  payload: {
    timelineActions,
  },
});

export const setPeriodSelected = (timePeriod: TimePeriodOptions): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_PERIOD_SELECTED,
  payload: {
    timePeriod,
  },
});

export const setShowCarePlanModal = (showCarePlanModal: boolean): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_SHOW_CAREPLAN_MODAL,
  payload: {
    showCarePlanModal,
  },
});

export const setSelectedAction = (
  selectedAction: ITemplateCarePlanAction
): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_SELECTED_ACTION,
  payload: {
    selectedAction,
  },
});

export const setCarePlanModalEventType = (
  carePlanModalEventType: CarePlanActionEventType
): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_CAREPLAN_MODAL_EVENT_TYPE,
  payload: {
    carePlanModalEventType,
  },
});

export const setHasUnsavedChanges = (hasUnsavedChanges: boolean): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_HAS_UNSAVED_CHANGES,
  payload: {
    hasUnsavedChanges,
  },
});

export const setUserIsStaff = (userIsStaff: boolean): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_USER_IS_STAFF,
  payload: {
    userIsStaff,
  },
});

export const setQuestionnaireList = (
  questionnaireList: IQuestionnaire[]
): TemplateCarePlanAction => ({
  type: TemplateCarePlanActionTypes.SET_QUESTIONNAIRE_LIST,
  payload: {
    questionnaireList,
  },
});
