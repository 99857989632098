import ReactWaves from '@dschoon/react-waves';
import React from 'react';
import styles from './styles.module.css';
import playButtonImage from 'assets/images/black_play_button.png';
export default function AudioWaves({ audio }) {
  const [playing, setPlaying] = React.useState(false);

  return (
    <>
      <ReactWaves
        audioFile={audio}
        options={{
          barGap: 1,
          barWidth: 2,
          barHeight: 2,
          barRadius: 3,
          cursorWidth: 0,
          height: 30,
          hideScrollbar: true,
          progressColor: '#1890FF',
          responsive: true,
          waveColor: '#8FAAC3',
          maxCanvasWidth: 90,
        }}
        className={styles.audio}
        volume={1}
        zoom={1}
        playing={playing}
      />
      <div style={{ position: 'relative' }}>
        <img
          style={{
            opacity: playing ? '0' : '100',
          }}
          className={styles.playButton}
          onClick={() => setPlaying((prev) => !prev)}
          alt="play"
          src={playButtonImage}
        />
      </div>
    </>
  );
}
