import Card from 'components/Card';

const ProviderInvitations = () => {
  return (
    <Card className="w-100 card-bg-border" headers={['Invitations']} divideChildren>
      <button className="link"> Generate invitation</button>
    </Card>
  );
};
export default ProviderInvitations;
