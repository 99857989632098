import { ReactComponent as EmptyChatsIcon } from 'assets/icons/chats_empty.svg';
import EmptyList from 'components/EmptyList';
import VytracSpinner from 'components/vytrac-spinner';
import { FC, useCallback } from 'react';
//mocks
import { ChatData } from 'screens/messages/mocks/types';
import { setSelectedChat } from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import ChatElement from './chat-element';
import styles from './styles.module.css';

interface MessagesCardProps {
  chats: ChatData[];
  areChatsLoading: boolean;
}

const MessagesList: FC<MessagesCardProps> = ({ chats, areChatsLoading }: MessagesCardProps) => {
  const {
    state: { selectedConversationSID },
    dispatch,
  } = useMessagesContext();

  const handleElementClick = useCallback(
    (chat: ChatData) => {
      dispatch(setSelectedChat(chat.sid));
    },
    [dispatch]
  );

  return (
    <div className={styles['chats-container']}>
      {chats?.length > 0 && !areChatsLoading ? (
        chats.map((chat, index) => (
          <ChatElement
            onClick={() => handleElementClick(chat)}
            chat={chat}
            key={index}
            isCurrent={chat.sid === selectedConversationSID}
          />
        ))
      ) : (
        <EmptyList
          Icon={EmptyChatsIcon}
          className="my-5"
          title="Click on start a new discussion to start a conversation"
          fill="#EBE3F1"
        >
          {areChatsLoading ? <VytracSpinner /> : undefined}
        </EmptyList>
      )}
    </div>
  );
};
export default MessagesList;
