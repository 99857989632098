import { createContext, Dispatch, useContext } from 'react';
import { INavigationState } from 'util/commonContext/NavigationContext';
import { getInitialNavigationState } from 'util/commonContext/NavigationContext/getInitialNavigationState';
import { patientsInitialBreadcrumbPathList } from '../util/initialBreadcrumbPathList';
import { IPatientCarePlanState, patientCarePlanInitialState } from './PatientCarePlan';
import {
  goalsThresholdsInitialState,
  IPatientGoalsThresholdsState,
} from './PatientGoalsThresholds';
import { IPatientListState, patientListInitialState } from './PatientList';
import { patientManagementInitialState, IPatientManagementState } from './PatientManagement';
import {
  IPatientQuestionnaresState,
  patientQuestionnaireInitialState,
} from './PatientQuestionnaires';
import { AllPatientActions } from './patientReducer';
import { IPatientTimelineState, patientTimelineInitialState } from './PatientTimeline';
import { patientTrackingInitialState, IPatientTrackingState } from './PatientTracking';

export interface PatientState {
  patientNavigation: INavigationState;
  patientList: IPatientListState;
  patientManagement: IPatientManagementState;
  patientCarePlan: IPatientCarePlanState;
  patientQuestionnaires: IPatientQuestionnaresState;
  patientTimeline: IPatientTimelineState;
  patientGoalsThresholds: IPatientGoalsThresholdsState;
  patientTracking: IPatientTrackingState;
}

export const patientStateInitialValue: PatientState = {
  patientNavigation: getInitialNavigationState(patientsInitialBreadcrumbPathList),
  patientList: patientListInitialState,
  patientManagement: patientManagementInitialState,
  patientCarePlan: patientCarePlanInitialState,
  patientQuestionnaires: patientQuestionnaireInitialState,
  patientTimeline: patientTimelineInitialState,
  patientGoalsThresholds: goalsThresholdsInitialState,
  patientTracking: patientTrackingInitialState,
};

interface PatientContextState {
  patientState: PatientState;
  dispatch: Dispatch<AllPatientActions>;
}

const PatientContext = createContext<PatientContextState>({} as PatientContextState);

export const usePatientContext = () => useContext(PatientContext);

export default PatientContext;
