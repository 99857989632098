import React from 'react';
import './styles.css';

interface ICheckbox {
  label?: string;
  onChange?: (e) => void;
  value?: boolean;
  onClick?: (e) => void;
  hidden?: boolean;
  labelClassName?: string;
}

const Checkbox = ({
  label = '',
  onChange = () => null,
  value = false,
  onClick = () => null,
  hidden = false,
  labelClassName = '',
}: ICheckbox) => {
  return (
    <div hidden={hidden} className="d-flex" style={{ marginTop: '0.6rem' }}>
      <input
        type="checkbox"
        className="vytrac-checkbox"
        id={`${label}-id`}
        onChange={onChange}
        checked={value}
        onClick={onClick}
      />
      <label
        className={`vytrac-checkbox-label ${labelClassName}`}
        onClick={(e) => onClick({ ...e, target: { ...e.target, checked: !value } })}
      >
        {label}
      </label>
    </div>
  );
};
export default Checkbox;
