import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const MedicationIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.88901 5.51562H3.90463V5.5V2.75C3.90463 2.09085 3.3752 1.5558 2.7223 1.5558C2.06941 1.5558 1.53998 2.09085 1.53998 2.75V5.5V5.51562H1.5556H3.88901ZM12.8591 4.31523L12.8591 4.31524C14.2244 5.69196 14.3407 7.8318 13.244 9.36287C13.1793 9.4507 13.0472 9.46 12.9688 9.38075L7.84502 4.20486C7.76623 4.12526 7.77357 3.99165 7.86259 3.92645C9.37567 2.81861 11.4962 2.93852 12.8591 4.31523ZM0.015625 2.75C0.015625 1.23861 1.22652 0.015625 2.7223 0.015625C4.21808 0.015625 5.42898 1.23861 5.42898 2.75V8.25C5.42898 9.76139 4.21808 10.9844 2.7223 10.9844C1.22652 10.9844 0.015625 9.76139 0.015625 8.25V2.75ZM7.3832 9.84727C6.02031 8.47051 5.90155 6.32817 6.99828 4.79957C7.06299 4.71179 7.19505 4.70252 7.27349 4.78175L12.3973 9.95764C12.476 10.0372 12.4687 10.1709 12.3797 10.2361C10.8666 11.3439 8.74605 11.224 7.3832 9.84727Z"
        fill={color}
        stroke={color}
        strokeWidth="0.03125"
      />
    </svg>
  );
};

export default MedicationIcon;
