import Card from 'components/Card';
import { FC, Fragment, ReactNode } from 'react';

interface CardLinkButtonProps {
  bodyClassName?: string;
  header: {
    text: string;
    linkLabel: string;
    onClick?: () => void;
  };
  children: ReactNode;
}

const CardWithLinkButton: FC<CardLinkButtonProps> = ({
  bodyClassName,
  header: { text, linkLabel, onClick },
  children,
}: CardLinkButtonProps) => {
  //if we ever need this not to be width-100 we could extract it as prop, I'll leave as we don't care rn
  return (
    <Card
      className="card-bg-border w-100"
      headerClassName="d-flex justify-content-between"
      bodyClassName={bodyClassName}
      headers={[
        <Fragment key={text}>
          <div>{text}</div>
          <button className="link" onClick={onClick}>
            {linkLabel}
          </button>
        </Fragment>,
      ]}
    >
      {children}
    </Card>
  );
};
export default CardWithLinkButton;
