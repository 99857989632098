import React from 'react';
import microphone from 'assets/icons/microphone.png';
import video from 'assets/icons/video.png';
import text from 'assets/icons/text.png';
import styles from './styles.module.css';
export default function Duration({ videoDuration, voiceDuration, textDuration }) {
  //Returns the duration in the desired format
  const getDuration = (duration) => {
    const minutes = duration.split(':')[0];
    const seconds = duration.split(':')[1];
    return `${minutes}m${seconds}s`;
  };

  return (
    <div className="d-flex flex-grow-1 justify-content-between">
      <div className={styles.value}>
        <div>
          <img src={microphone} alt="microphone" className={styles.microphone} />
          {getDuration(voiceDuration)}
        </div>
      </div>
      <div className={styles.value}>
        <div>
          <img src={video} alt="video" className={styles.video} />
          {getDuration(videoDuration)}
        </div>
      </div>
      <div className={styles.value}>
        <div>
          <img src={text} alt="text" className={styles.text} />
          {getDuration(textDuration)}
        </div>
      </div>
    </div>
  );
}
