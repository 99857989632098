import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';

export const initialAction: IPatientCarePlanAction = {
  is_part_of_rpm_plan: false,
  is_active: true,
  type: 'oxygen_level',
  end_date_type: 'fixed_date',
  questionnaire_when: 'before_event',
  questionnaire_when_minutes: 5,
  resource_when: 'before_event',
  resource_when_minutes: 5,
};
