import moment from 'moment';
import React from 'react';
import styles from './styles.module.css';

interface IStartDate {
  value: string;
}
const StartDate = ({ value }: IStartDate) => {
  return (
    <div className={styles.main}>
      <div className={styles.title}>started</div>
      <hr className="my-0" />
      <div className={styles.text}>{moment(value).format('MM.DD.YYYY')}</div>
    </div>
  );
};
export default StartDate;
