import { Tagitem } from 'components/Tagitem';
import { FC } from 'react';
import styles from './styles.module.css';

interface PopulationNameElementProps {
  name;
}

const PopulationNameElement: FC<PopulationNameElementProps> = ({
  name,
}: PopulationNameElementProps) => {
  return (
    <div className={styles['tag-item']}>
      <Tagitem text={name} />
    </div>
  );
};
export default PopulationNameElement;
