import Select from 'components/form/Select';
import { useEffect, useState } from 'react';

const RolesSelect = ({
  onSelect,
  value,
  onBlur,
  custom = false,
  selectProps = {},
  selectClassName = '',
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const mainOptions = ['View & Manage', 'View Only', 'Hidden'];
    if (custom) {
      setOptions([...mainOptions, 'Custom']);
    } else setOptions([...mainOptions]);
  }, []);
  return (
    <Select
      options={options}
      placeholder="Select"
      onChange={onSelect}
      currentValue={value}
      onBlur={onBlur}
      selectProps={selectProps}
      selectClassName={selectClassName}
    />
  );
};
export default RolesSelect;
