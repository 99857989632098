import React, { useReducer } from 'react';
import {FormCheck} from 'react-bootstrap';
import InputField from '../../../../../../../../../../components/InputField';
import { setOutOfOffice, setOutOfOfficeMessage } from '../../../ActiveSchedule/store/actions/actionCreators';
import { useActiveScheduleContext } from '../../../ActiveSchedule/store/context/ActiveScheduleContext';
import styles from './styles.module.css';

const Footer = ()  => {

  const { activeScheduleState, dispatch } = useActiveScheduleContext();

  const onChangeOutOfOffice = (outOfOffice : boolean) => {
    dispatch(setOutOfOffice(outOfOffice));
  };

  const onChangeOutOfOfficeMessage = (outOfOfficeMessage : string) => {
    dispatch(setOutOfOfficeMessage(outOfOfficeMessage));
  };


  return (
    <div className={styles.footerContainer}>
      <hr className={styles.horizontalLine} />
      <div className={styles.container}>
        <div className={styles.text}>Out of Office Today</div>
        <div onClick= {() => onChangeOutOfOffice(!activeScheduleState.outOfOffice)}>
          <FormCheck type="switch" checked={activeScheduleState.outOfOffice}/>
        </div> 
      </div>
      {activeScheduleState.outOfOffice && (
        <div className={styles.outgoingMessageContainer}>
          <div className={styles.outgoingMessageTitle}>
            Automated Outgoing Mesage
          </div>
          <div className={styles.outgoingMessage}>
            <InputField
              className={styles.outgoingMessageInput}
              value= {activeScheduleState.outOfOfficeMessage}
              placeholder="Add outgoing message..."
              onChange={(value) => onChangeOutOfOfficeMessage(value.currentTarget.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
