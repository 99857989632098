import { Dispatch, useCallback, useEffect, useMemo, useRef } from 'react';
import DashboardRedAlert from 'types/ApiModels/Dashboard/DashboardRedAlert';
import DatePickerValue from 'types/Shared/DatePicker';
import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { setRedAlerts, setTotalPatients } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useDashboardRedAlerts = (
  date: DatePickerValue
): [PaginatedRequestHook<DashboardRedAlert>, Dispatch<DashboardAction>] => {
  const {
    state: { redAlerts },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const previousDate = useRef(date);

  const paginationRequest = usePaginatedRequest(
    'providers/dashboard/red_alerts/',
    10,
    redAlerts,
    false,
    { date_from: `${date.year}-${date.month}-${date.day}` }
  );

  const init = useMemo(() => {
    return paginationRequest.init;
  }, [paginationRequest.init]);

  const redAlertsInit = useCallback(async () => {
    fetchRegistry.current.redAlerts.list = true;
    await init();
    fetchRegistry.current.redAlerts.list = false;
    fetchRegistry.current.totalPatients = true;
  }, [fetchRegistry, init]);

  useEffect(() => {
    if (
      previousDate.current.year != date.year ||
      previousDate.current.month != date.month ||
      previousDate.current.day != date.day ||
      (!redAlerts && !fetchRegistry.current.redAlerts.list)
    ) {
      redAlertsInit();
      previousDate.current = { ...date };
    }
  }, [fetchRegistry, redAlerts, redAlertsInit, date]);

  useEffect(() => {
    dispatch(setRedAlerts(paginationRequest.book));
    dispatch(setTotalPatients(paginationRequest.extra?.patients_total));
  }, [dispatch, paginationRequest.book, paginationRequest.extra]);

  return [paginationRequest, dispatch];
};

export default useDashboardRedAlerts;
