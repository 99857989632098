import { ReactComponent as AlertIcon } from 'assets/icons/empty_alerts_icon.svg';
import EmptyList from '../../../components/EmptyList';
const EmptyRedAlerts = () => {
  return (
    <EmptyList
      Icon={AlertIcon}
      title="Great job. You have acted on all red alerts for your patients."
      fill="#FFDDDD"
    />
  );
};
export default EmptyRedAlerts;
