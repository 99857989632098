import { createContext, Dispatch, MutableRefObject, useContext } from 'react';
import DashboardAdherence from 'types/ApiModels/Dashboard/DashboardAdherence';
import { DashboardEscalated } from 'types/ApiModels/Dashboard/DashboardEscalated';
import DashboardOutstandingChats from 'types/ApiModels/Dashboard/DashboardOutstandingChats';
import DashboardRedAlert from 'types/ApiModels/Dashboard/DashboardRedAlert';
import DashboardRedAlertDetail from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';
import DashboardSchedule from 'types/ApiModels/Dashboard/DashboardSchedule';
import { IDashboardToDo, IDashboardToDoSingle } from 'types/ApiModels/Dashboard/DashboardToDo';
import { DashboardStickynotes } from 'types/ApiModels/Dashboard/DashboardStickynotes';
import { DashboardUrgency } from 'types/ApiModels/Dashboard/DashboardUrgency';
import DashboardYellowAlert from 'types/ApiModels/Dashboard/DashboardYellowAlert';
import DashboardYellowAlertDetail from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { PaginatedResponse } from 'types/ApiModels/General';
import { DashboardAction } from './DashboardActionTypes';
import IVytalChart from 'types/Dashboard/VytalChart';
import { IAdherenceGraphic } from 'types/Dashboard/AdherenceGraphic';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import { ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { CurrentProviderCareTeam } from 'types/ApiModels/CareTeam';

interface DashboardFetchRegistry {
  redAlerts: {
    list: boolean;
  };
  yellowAlerts: {
    list: boolean;
  };
  outstandingChats: {
    list: boolean;
  };
  adherence: {
    list: boolean;
  };
  adherenceGraphic: {
    list: boolean;
  };
  urgency: {
    list: boolean;
  };
  escalated: {
    list: boolean;
  };
  schedule: {
    list: boolean;
  };
  stickynotes: {
    list: boolean;
  };
  redAlertDetail: {
    list: Record<string, DashboardRedAlertDetail>;
  };
  yellowAlertDetail: {
    list: Record<string, DashboardYellowAlertDetail>;
  };
  vytals: {
    list: Record<string, boolean>;
  };
  questionnaireTemplates: {
    list: boolean;
  };
  patientQuestionnaires: {
    list: boolean;
  };
  currentProviderCareTeam: boolean;
  totalPatients: boolean;
}

export const dashboardFetchRegistryInitialValues: DashboardFetchRegistry = {
  redAlerts: {
    list: false,
  },
  yellowAlerts: {
    list: false,
  },
  outstandingChats: {
    list: false,
  },
  adherence: {
    list: false,
  },
  adherenceGraphic: {
    list: false,
  },
  urgency: {
    list: false,
  },
  escalated: {
    list: false,
  },
  schedule: {
    list: false,
  },
  stickynotes: {
    list: false,
  },
  redAlertDetail: {
    list: {},
  },
  yellowAlertDetail: {
    list: {},
  },
  vytals: {
    list: {},
  },
  questionnaireTemplates: {
    list: false,
  },
  patientQuestionnaires: {
    list: false,
  },
  currentProviderCareTeam: false,
  totalPatients: false,
};

export interface DashboardState {
  patientIdTimeMap: Map<number, number>;
  trackingPatientId: number;
  timerIsTracking: boolean;
  trackingActivityId: number;
  trackingDuration: number;
  trackingType: TrackingType;
  toDoItems: IDashboardToDo[];
  toDoItemsSingle: Record<number, IDashboardToDoSingle>;
  redAlerts: Record<number, PaginatedResponse<DashboardRedAlert>>;
  yellowAlerts: DashboardYellowAlert[];
  outstandingChats: Record<number, PaginatedResponse<DashboardOutstandingChats>>;
  adherence: Record<number, PaginatedResponse<DashboardAdherence>>;
  adherenceGraphic: IAdherenceGraphic[];
  urgency: Record<number, PaginatedResponse<DashboardUrgency>>;
  escalated: Record<number, PaginatedResponse<DashboardEscalated>>;
  schedule: Record<number, PaginatedResponse<DashboardSchedule>>;
  stickynotes: Record<number, PaginatedResponse<DashboardStickynotes>>;
  selectedRedAlert: DashboardRedAlertDetail;
  selectedYellowAlert: DashboardYellowAlertDetail;
  selectedAdherence: DashboardAdherence;
  vytalsChart: Record<string, IVytalChart>;
  totalPatients: number;
  showActionModal: boolean;
  actionModalType: ProviderActionType;
  openedAlertId: number;
  patientQuestionnaires: Record<number, IPatientQuestionnaire[]>;
  questionnaireTemplates: Record<number, PaginatedResponse<IQuestionnaire>>;
  currentToDo: IDashboardToDoSingle;
  currentProviderCareTeam: CurrentProviderCareTeam;
}

export interface DashboardContextState {
  state: DashboardState;
  dispatch: Dispatch<DashboardAction>;
  fetchRegistry: MutableRefObject<DashboardFetchRegistry>;
}

export const DashboardContext = createContext<DashboardContextState>({} as DashboardContextState);
export const useDashboardContext = () => useContext(DashboardContext);

export default DashboardContext;
