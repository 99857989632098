import { ReactComponent as AlertIcon } from 'assets/icons/empty_alerts_icon.svg';
import EmptyList from '../../../components/EmptyList';
const EmptyYellowAlerts = () => {
  return (
    <EmptyList
      Icon={AlertIcon}
      title="Great job. You have acted on all consecutive yellow alerts for your patients."
      fill="#FCECC3"
    />
  );
};
export default EmptyYellowAlerts;
