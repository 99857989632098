import moment from 'moment';
import React from 'react';
import styles from './styles.module.css';

const TimeLabel = ({time, shift, firstCell, startOfShift}) => {
  const labelStyle = (shift) => {
    let retVal = null;
    if (shift === 1)
      retVal = {
        backgroundColor: '#FBF9F4',
        color: '#E5954C',
        borderTop: startOfShift ? '1px solid #E5954C' : '',
      };
    else if (shift === 2)
      retVal = {
        backgroundColor: '#F4F3FC',
        color: '#7872D8',
        borderTop: startOfShift ? '1px solid #7872D8' : '',
      };
    else if (shift === 3)
      retVal = {
        backgroundColor: '#F4F8FB',
        color: '#03718A',
        borderTop: startOfShift ? '1px solid #03718A' : '',
      };

    if (firstCell) retVal = {...retVal, borderTop: 0};

    return retVal;
  };

  return (
    <div className={styles.timeLabel} style={labelStyle(shift)}>
      <div>{moment(time).format('h A')}</div>
    </div>
  );
};

export default TimeLabel;
