import React from 'react';
import styles from './styles.module.css';

const DateCell = ({
  date,
  ref,
  selected,
  firstCell,
  shift,
  startOfShift,
  disabled,
}) => {
  const containerStyle = (selected, firstCell) => {
    let retValue;
    if (selected) {
      const opacity = disabled ? 0.2 : 0.7;
      retValue = {borderTop: `1px solid rgb(121,205,146,${opacity})`};
    }
    if (startOfShift) {
      if (shift === 1)
        retValue = {
          ...retValue,
          borderTop: '1px solid #E5954C',
        };
      else if (shift === 2)
        retValue = {
          ...retValue,
          borderTop: '1px solid #7872D8',
        };
      else if (shift === 3)
        retValue = {
          ...retValue,
          borderTop: '1px solid #03718A',
        };
    }

    if (firstCell) retValue = {...retValue, borderTop: 0};

    return retValue;
  };

  const cellStyle = (selected) => {
    let retValue;
    if (selected) {
      retValue = {
        backgroundColor: '#A4E7B7',
        opacity: disabled ? 0.2 : 0.5,
      };
    }
    return retValue;
  };

  return (
    <div
      ref={ref}
      className={styles.dateCellContainer}
      style={containerStyle(selected, firstCell)}
    >
      <div className={styles.dateCell} style={cellStyle(selected)}></div>
    </div>
  );
};

export default DateCell;
