import {
  PatientSignup,
  ProviderSignup,
  RoleSignup,
} from 'types/ApiModels/Administration/Dashboard';

export enum DashboardActionTypes {
  SET_PROVIDER_SIGNUPS = 'SET_PROVIDER_SIGNUPS',
  SET_PATIENT_SIGNUPS = 'SET_PATIENT_SIGNUPS',
  SET_ROLES_ADDED = 'SET_ROLES_ADDED',
  SET_PROVIDER_ACTIVITY_POINTS = 'SET_PROVIDER_ACTIVITY_POINTS',
  SET_PATIENT_ACTIVITY_POINTS = 'SET_PATIENT_ACTIVITY_POINTS',
}

export type DashboardAction =
  | {
      type: DashboardActionTypes.SET_PROVIDER_SIGNUPS;
      payload: { providerSignups: ProviderSignup[] };
    }
  | {
      type: DashboardActionTypes.SET_PATIENT_SIGNUPS;
      payload: { patientSignups: PatientSignup[] };
    }
  | {
      type: DashboardActionTypes.SET_ROLES_ADDED;
      payload: { rolesAdded: RoleSignup[] };
    }
  | {
      type: DashboardActionTypes.SET_PROVIDER_ACTIVITY_POINTS;
      //TODO:
      payload: { providerActivityPoints: any[] };
    }
  | {
      type: DashboardActionTypes.SET_PATIENT_ACTIVITY_POINTS;
      //TODO:
      payload: { patientActivityPoints: any[] };
    };
