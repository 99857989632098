import { Cross } from 'components/Icons';
import { FC, useState } from 'react';

interface DismissableDialogProps {
  bgColor: string;
  textColor: string;
  label: string;
  text: string;
}

const DismissableDialog: FC<DismissableDialogProps> = ({
  bgColor,
  textColor,
  label,
  text,
}: DismissableDialogProps) => {
  const [dismiss, setDismiss] = useState(false);

  return dismiss ? null : (
    <div
      className={`d-flex justify-content-between my-2 py-2 px-3 rounded font-size-big`}
      style={{
        color: textColor,
        background: bgColor,
      }}
    >
      <div className="d-flex gap-lg">
        <p className={`m-0 font-weight-medium`}>{label}</p>
        <p className={`m-0`}>{text}</p>
      </div>
      <button
        type="button"
        className={`unstyled-button cursor-pointer`}
        onClick={() => setDismiss(true)}
      >
        <Cross width="12" height="12" color={textColor} />
      </button>
    </div>
  );
};
export default DismissableDialog;
