import React from 'react';
import {Col, Row} from 'react-bootstrap';
import FirstColumn from './components/FirstColumn';
import LastColumn from './components/LastColumn';

const Content = ({
  setUnSavedChanges,
  setIsRemovable,
  activeSchedule,
  setActiveSchedule,
  initialState,
  newSchedule,
  setNewSchedule,
  refetch,
}) => {
  return (
    <Row>
      <Col className="px-3" lg={3}>
        <FirstColumn
          refetch={refetch}
          activeSchedule={activeSchedule}
          setActiveSchedule={setActiveSchedule}
        />
      </Col>
      <Col className="px-3" lg={9}>
        <LastColumn
          activeSchedule={activeSchedule}
          setUnSavedChanges={setUnSavedChanges}
          initialState={initialState}
          newSchedule={newSchedule}
          setNewSchedule={setNewSchedule}
        />
      </Col>
    </Row>
  );
};

export default Content;
