import React from 'react';
import CarePlanBreakdownHeader from './components/CarePlanBreakdownHeader';
import CarePlanBreakdownBody from './components/CarePlanBreakdownBody/CarePlanBreakdownBody';

const CarePlanBreakdown = () => (
  <div className="dashboard-detail py-0">
    <CarePlanBreakdownHeader />
    <CarePlanBreakdownBody />
  </div>
);

export default CarePlanBreakdown;
