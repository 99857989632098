import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface IDeleteModal {
  name: string;
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}
const DeleteModal = ({ name, show, onClose, onDelete }: IDeleteModal) => {
  return (
    <VytracModal
      show={show}
      title="Delete Patient"
      body={<div className="font-size-big">Are you sure you wish to delete {name}?</div>}
      footer={
        <div className="w-100 d-flex justify-content-end">
          <Button label="delete" variant="delete" onClick={onDelete} />
        </div>
      }
      onClose={onClose}
    />
  );
};

export default DeleteModal;
