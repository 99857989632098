import React, { createContext, Dispatch, useContext } from 'react';
import { ConversationMetadata } from 'types/ApiModels/conversations';
import { PaginatedResponse } from 'types/ApiModels/General';
import { PatientEncounter } from 'types/ApiModels/Patients/encounter';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
import { validateContext } from 'util/utils';
//mocks
import { ChatData, MockBroadcast, MockChat } from '../mocks/types';
import { MessagesAction } from './messages-action-types';

export interface MessagesState {
  selectedPatient: PatientEncounter;
  breadcrumbs: BreadCrumbPath[];
  Actions: React.FC<any>;
  newConversationModal: boolean;
  /**
   * For modal
   */
  patientList: Record<number, PaginatedResponse<PatientGet>>;
  selectedConversationSID: string;
  broadcasts: Record<number, MockBroadcast>;
  selectedBroadcast: number[];
  openedBroadcast: MockBroadcast;
  broadcastTemplates: Record<number, MockBroadcast>;
  openedBroadcastTemplate: MockBroadcast;
  getMetadataByParticipantSID: (psid: string) => ConversationMetadata;
}

export interface MessagesContextState {
  state: MessagesState;
  dispatch: Dispatch<MessagesAction>;
}

export const MessagesContext = createContext<MessagesContextState>(null);
export const useMessagesContext = () => {
  const context = useContext(MessagesContext);
  validateContext(context);
  return context;
};

export default MessagesContext;
