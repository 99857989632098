import { FC } from 'react';
import { communicationColor, ICommunicationIcon } from './utils';

const VoiceCallIcon: FC<ICommunicationIcon> = ({
  width,
  height,
  isActive,
  ...rest
}: ICommunicationIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 14 14`}
    fill={isActive ? communicationColor.active : communicationColor.inactive}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.7 4.2C8.25695 4.2 8.7911 4.42125 9.18492 4.81508C9.57875 5.2089 9.8 5.74304 9.8 6.3C9.8 6.48565 9.87375 6.6637 10.005 6.79497C10.1363 6.92625 10.3143 7 10.5 7C10.6856 7 10.8637 6.92625 10.995 6.79497C11.1262 6.6637 11.2 6.48565 11.2 6.3C11.2 5.37174 10.8312 4.4815 10.1749 3.82513C9.51849 3.16875 8.62826 2.8 7.7 2.8C7.51435 2.8 7.3363 2.87375 7.20502 3.00503C7.07375 3.1363 7 3.31435 7 3.5C7 3.68565 7.07375 3.8637 7.20502 3.99497C7.3363 4.12625 7.51435 4.2 7.7 4.2Z"
      fill={isActive ? communicationColor.active : communicationColor.inactive}
    />
    <path
      d="M7.7 1.4C8.99956 1.4 10.2459 1.91625 11.1648 2.83518C12.0837 3.7541 12.6 5.00044 12.6 6.3C12.6 6.48565 12.6737 6.6637 12.805 6.79497C12.9363 6.92625 13.1143 7 13.3 7C13.4856 7 13.6637 6.92625 13.795 6.79497C13.9262 6.6637 14 6.48565 14 6.3C14 4.62914 13.3362 3.02671 12.1548 1.84523C10.9733 0.663748 9.37086 0 7.7 0C7.51435 0 7.3363 0.0737498 7.20502 0.205025C7.07375 0.336301 7 0.514348 7 0.7C7 0.885652 7.07375 1.0637 7.20502 1.19497C7.3363 1.32625 7.51435 1.4 7.7 1.4ZM13.825 9.737C13.7865 9.62463 13.72 9.5239 13.6319 9.4443C13.5437 9.3647 13.4367 9.30885 13.321 9.282L9.121 8.323C9.00699 8.29715 8.88833 8.30026 8.77584 8.33206C8.66335 8.36385 8.56061 8.4233 8.477 8.505C8.379 8.596 8.372 8.603 7.917 9.471C6.40724 8.77531 5.19749 7.5606 4.508 6.048C5.397 5.6 5.404 5.6 5.495 5.495C5.5767 5.41139 5.63615 5.30865 5.66794 5.19616C5.69974 5.08367 5.70285 4.965 5.677 4.851L4.718 0.7C4.69115 0.584287 4.6353 0.47731 4.5557 0.389138C4.4761 0.300967 4.37537 0.234502 4.263 0.196C4.09953 0.137613 3.93072 0.0954103 3.759 0.0699999C3.58208 0.0289766 3.40153 0.00552954 3.22 0C2.366 0 1.54698 0.339249 0.943116 0.943116C0.339249 1.54698 0 2.366 0 3.22C0.00370434 6.0779 1.14064 8.81768 3.16148 10.8385C5.18232 12.8594 7.9221 13.9963 10.78 14C11.2029 14 11.6216 13.9167 12.0122 13.7549C12.4029 13.5931 12.7579 13.3559 13.0569 13.0569C13.3559 12.7579 13.5931 12.4029 13.7549 12.0122C13.9167 11.6216 14 11.2029 14 10.78C14.0002 10.6018 13.9862 10.4239 13.958 10.248C13.9286 10.0741 13.8841 9.90315 13.825 9.737Z"
      fill={isActive ? communicationColor.active : communicationColor.inactive}
    />
  </svg>
);

export default VoiceCallIcon;
