import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import useDashboardVytalCharts from 'screens/ProviderDashboard/store/hooks/useDashboardVytalCharts';
import { AlertVytal } from 'types/ApiModels/Dashboard';
import { convertDataIntoChartData, getChartOptions } from './utils';

interface AlertGraphProps {
  vytal: AlertVytal;
  patientId: number;
}
const AlertGraph = ({ vytal, patientId }: AlertGraphProps) => {
  const [] = useDashboardVytalCharts(vytal.type, 10, patientId);

  const {
    state: { vytalsChart },
  } = useDashboardContext();

  const chartValues = useMemo(() => {
    if (vytal) {
      return (
        vytalsChart?.[`${patientId}-${vytal.type}`]?.vytals.map(
          (vytal) =>
            (vytal &&
              (vytal.type === 'blood_pressure'
                ? [parseFloat(vytal.value), parseFloat(vytal.value_2)]
                : parseFloat(vytal.value))) ??
            0
        ) ?? []
      );
    }
    return null;
  }, [vytalsChart, vytal, patientId]);

  const chartData = useMemo(
    () =>
      convertDataIntoChartData(
        chartValues,
        vytal.type === 'blood_pressure' ? 'double-line' : 'normal'
      ),
    [chartValues, vytal]
  );

  return (
    <div className="px-2">
      {chartData.datasets.flatMap((d) => d && d.data).filter((e) => e).length > 0 ? (
        <Line
          style={{
            background:
              'linear-gradient(180deg, transparent 10%,#EAF1F7 100%, rgba(246, 250, 252, 0.59) 100%)',
          }}
          data={chartData}
          options={getChartOptions(
            chartValues,
            vytal.type === 'blood_pressure' ? 'double-line' : 'normal'
          )}
          height="70%"
        />
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          There are no readings from this patient
        </div>
      )}
    </div>
  );
};
export default AlertGraph;
