import Button from 'components/Button';
import SatisfactionBar from 'components/SatisfactionBar';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import UserBubble from 'components/UserBubble';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CareTeamProvider } from 'types/ApiModels/CareTeam';
import styles from './styles.module.css';
import { ReactComponent as CopyIcon } from 'assets/icons/copyIcon.svg';

interface IProviderPopup {
  provider: CareTeamProvider;
  leftPosition: number;
  topPosition?: number;
  showProviderPopup: boolean;
  closeProviderPopup: () => void;
}
const ProviderPopup = ({
  provider,
  leftPosition,
  topPosition,
  closeProviderPopup,
}: IProviderPopup) => {
  const containerRef = useRef<HTMLDivElement>();
  const [containerPosition, setContainerPosition] = useState<CSSProperties>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        closeProviderPopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, closeProviderPopup]);

  useEffect(() => {
    if (!containerRef.current) return;
    let style: CSSProperties = { left: leftPosition - 600 };

    if (topPosition + containerRef.current.getBoundingClientRect().height > window.innerHeight) {
      // window does not fit on viewport
      style = {
        ...style,
        top: window.innerHeight - containerRef.current.getBoundingClientRect().height - 10,
      };
    } else {
      style = {
        ...style,
        top: topPosition + 10,
      };
    }
    setContainerPosition(style);
  }, [containerRef.current?.getBoundingClientRect().height, leftPosition, topPosition]);

  return (
    <div className={styles.providerPopupContainer} style={containerPosition} ref={containerRef}>
      {containerPosition && (
        <>
          <div className="d-flex align-items-center">
            <Col sm={4}>
              <UserBubble user={provider} containerClassNameList={[styles.bubbleContainer]} />
            </Col>
            <Col>
              <Row className={styles.topHeader}>
                <div className={styles.nameContainer}>
                  {provider.first_name} {provider.last_name}
                </div>
              </Row>
              <Row>
                <div className={styles.condition}>{provider.is_active ? 'Active' : 'Inactive'}</div>
              </Row>
              <Row>
                <TagList
                  className={styles.tagListContainer}
                  tagList={
                    provider.specialities?.map<Tag>((s) => ({
                      text: s.name,
                      style: { marginBottom: '5px' },
                    })) ?? []
                  }
                />
              </Row>
            </Col>
          </div>
          <div className={styles.providerPopupBody}>
            <div className="d-flex" style={{ padding: '20px 0px' }}>
              <Col className={styles.label} sm={4}>
                Satisfaction
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
                <SatisfactionBar className="flex-grow-1" value={provider.satisfaction} />
              </Col>
            </div>
            <div className="d-flex align-items-center">
              <Col className={styles.label} sm={4}>
                Title
              </Col>
              <Col>
                <div className={styles.textContainer}>{provider.title}</div>
              </Col>
            </div>
            <div className="d-flex align-items-center">
              <Col className={styles.label} sm={4}>
                Email
              </Col>
              <Col>
                <div className={`d-flex align-items-center ${styles.textContainer}`}>
                  {provider.email}
                  <CopyIcon stroke="#1890FF" />
                </div>
              </Col>
            </div>
            <div className="d-flex align-items-center">
              <Col className={styles.label} sm={4}>
                Phone
              </Col>
              <Col>
                <div className={`d-flex align-items-center ${styles.textContainer}`}>
                  {provider.phone_number}
                  <CopyIcon stroke="#1890FF" />
                </div>
              </Col>
            </div>
            <div className="d-flex align-items-center">
              <Col className={styles.label} sm={4}>
                Phone number 2
              </Col>
              <Col>
                <div className={`d-flex align-items-center ${styles.textContainer}`}>
                  {provider.second_phone_number ? provider.second_phone_number : '-'}
                  <CopyIcon stroke="#1890FF" />
                </div>
              </Col>
            </div>
            <div style={{ paddingTop: '17px', paddingBottom: '20px' }}>
              <Col>
                <Button label="send message" containerClassName="flex-grow-1" className="w-100" />
              </Col>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ProviderPopup;
