import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const StressLevelIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15981 0.0598831C7.23989 0.109526 7.30185 0.186742 7.33558 0.278916C7.36932 0.37109 7.37282 0.472773 7.34553 0.567354L5.87204 5.68756H8.59076C8.67066 5.68753 8.74881 5.71252 8.81558 5.75944C8.88235 5.80637 8.93481 5.87317 8.96648 5.95162C8.99816 6.03006 9.00766 6.1167 8.99381 6.20085C8.97996 6.285 8.94338 6.36298 8.88857 6.42515L2.34335 13.8622C2.28002 13.9342 2.19575 13.9812 2.10427 13.9954C2.01278 14.0097 1.91949 13.9904 1.83959 13.9406C1.75969 13.8909 1.69789 13.8137 1.66428 13.7216C1.63066 13.6295 1.6272 13.528 1.65447 13.4335L3.12796 8.31241H0.409239C0.329343 8.31245 0.251185 8.28746 0.184416 8.24053C0.117646 8.19361 0.0651875 8.1268 0.0335152 8.04836C0.00184296 7.96992 -0.00765622 7.88327 0.00619027 7.79912C0.0200368 7.71497 0.0566227 7.637 0.111432 7.57483L6.65665 0.137754C6.7199 0.065832 6.80405 0.018908 6.8954 0.00461124C6.98675 -0.00968549 7.07994 0.00948587 7.15981 0.0590082V0.0598831Z"
        fill={color}
      />
    </svg>
  );
};

export default StressLevelIcon;
