import Button from 'components/Button';
import React from 'react';
import styles from './styles.module.css';

const EncounterControls = () => {
  return (
    <div className={`dashboard-detail ${styles.main}`}>
      <div className="dashboard-item-header">Encounter Controls</div>
      <div className={styles.body}>
        <Button className={styles.controlButton} label="PROVIDE QUESTIONNAIRE" />
        <Button className={styles.controlButton} label="ASSIGN" />
        <Button className={styles.controlButton} label="ADJUST THRESHOLD" />
        <Button className={styles.controlButton} label="REQUEST APPOINTMENT" />
        <Button variant="confirm" className={styles.controlButton} label="COMPLETED" />
      </div>
    </div>
  );
};

export default EncounterControls;
