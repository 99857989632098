import PatientCase from 'types/PatientCases';
import CareTeam from './CareTeam';
import CollapseThread from './CollapseThread';
import Header from './Header';
import OriginalAlert from './OriginalAlert';
import styles from './styles.module.css';
interface CaseCardProps {
  patientCase: PatientCase;
  showDateBelow?: boolean;
}
const CaseCard = ({ patientCase, showDateBelow }: CaseCardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <Header patient={patientCase.patient} alert={patientCase.alert} />
      </div>
      <hr className={styles.horizontalLine} />
      <div className={styles.body}>
        <OriginalAlert alert={patientCase.alert} />
      </div>
      <hr className={styles.horizontalLine} />
      {patientCase.messages.map((message, index) => (
        <div className={styles.container} key={index}>
          <CollapseThread messages={message.messages} date={message.messages[0].created_at} />
        </div>
      ))}
      <hr className={styles.horizontalLine} />
      <div className={styles.footer}>
        <CareTeam
          careTeam={patientCase.careTeam}
          dateBelow={showDateBelow && patientCase.completed_date}
        />
      </div>
    </div>
  );
};
export default CaseCard;
