import VyTracModal from 'components/Modal';
import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Col, FormCheck, Row } from 'react-bootstrap';
import InputField from 'components/InputField';
import Checkbox from 'components/form/Checkbox';
import Button from 'components/Button';
import GenericButton from 'components/GenericButton';
import DeleteIcon from 'assets/icons/delete.png';
import Select from 'components/form/Select';

interface ActivityTrackerModalProps {
  handleClose: () => void;
  handleChange: (month) => void;
  showModal: boolean;
  data: any;
}

const ActivityTrackerModal: FC<ActivityTrackerModalProps> = ({
  handleClose,
  handleChange,
  showModal,
  data,
}: ActivityTrackerModalProps) => {
  return (
    <VyTracModal
      show={showModal}
      onClose={handleClose}
      title={
        <div className={styles.container}>
          <span>Submit billing request {data.code}</span>
          <div className={styles.addProviderContainer}>
            <Button className={styles.button} onClick={() => {}} label="add new provider" />
          </div>
        </div>
      }
      size="lg"
      bodyClassName={styles.modalBody}
      body={
        <div className="">
          <div className={`d-flex p-3 justify-content-between ${styles.header}`}>
            <span className={styles.headerProvider}>PROVIDER</span>
            <span className={styles.headerTitle}>DATE OF SERVICE</span>
            <span className={styles.headerTitle}>ACTIVITY</span>
            <span className={styles.headerTitle}>TIME SPENT</span>
          </div>
          <div className={`d-flex p-3 justify-content-between ${styles.header}`}>
            <span className={styles.headerTitle}>Thomas Wayne, Physician (80%)</span>
            <span className={styles.labelBlue}> Add time</span>
          </div>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>
          <div className={`d-flex p-3 justify-content-between ${styles.header}`}>
            <span className={styles.headerTitle}>Martha Wayne, NPP (20%)</span>
            <span className={styles.labelBlue}> Add time</span>
          </div>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>
          <div className={`d-flex p-3 justify-content-between ${styles.header}`}>
            <span className={styles.labelBlue}>Select Provider</span>
            <span className={styles.labelBlue}> Add time</span>
          </div>
          <Row className={styles.row}>
            <Col className={styles.headerTitle} lg={6}>
              Activity Tracker
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <div className={styles.content}>
                <InputField placeholder='Select time' onChange={(e) => {}} value={null} className={styles.input} />
              </div>
            </Col>
            <Col className={styles.headerTitle} lg={2}>
              <Select
                options={[]}
                selectClassName={styles.select}
                onChange={(e) => {}}
                placeholder='Select activity'
              />
            </Col>
            <Col className={`d-flex ${styles.headerTitle}`} lg={2}>
              <InputField onChange={(e) => {}} value={null} className={styles.timeSpentInput} />
              <GenericButton
                icon={DeleteIcon}
                alt="block"
                className={`${styles.actions} justify-content-center`}
                onClick={() => {}}
              />
            </Col>
          </Row>

        </div>
      }
      footer={
        <div className="d-flex w-100 px-3 justify-content-between">
          <Button label="cancel" variant="cancel" />
          <Button label="save changes" variant="confirm" onClick={() => {}} />
        </div>
      }
    />
  );
};
export default ActivityTrackerModal;
