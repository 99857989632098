import React from 'react';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightIcon } from 'assets/icons/arrow_right.svg';
import styles from './styles.module.css';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';

interface IValueSelector {
  label: string;
  value: number;
  onChange?: (newValue: number) => void;
}
const ValueSelector = ({ label, value, onChange }: IValueSelector) => {
  return (
    <div className={styles.valueSelector}>
      <div style={{ userSelect: 'none' }}>{label}</div>
      <div className="d-flex align-items-center" style={{ userSelect: 'none' }}>
        <LeftIcon className={styles.iconButton} onClick={() => onChange(value - 1)} />
        {value}
        <RightIcon className={styles.iconButton} onClick={() => onChange(value + 1)} />
      </div>
    </div>
  );
};
interface IRelativeDateSelector {
  value: IRelativeTime;
  onChange: (newValue: IRelativeTime) => void;
  containerClassName?: string;
}

const RelativeDateSelector = ({
  value,
  onChange,
  containerClassName = '',
}: IRelativeDateSelector) => {
  const onDayClick = (newDayValue: number) => {
    // newDayValue needs correction to use 0 - 6 range
    const correctedDayValue = newDayValue - 1;

    if (correctedDayValue === -1 && value.week_number === 1) return;
    if (correctedDayValue === 7) {
      onChange({
        ...value,
        day_of_week: 0,
        week_number: value.week_number + 1,
      });
    } else if (correctedDayValue === -1) {
      onChange({
        ...value,
        day_of_week: 6,
        week_number: value.week_number - 1,
      });
    } else {
      onChange({
        ...value,
        day_of_week: correctedDayValue,
      });
    }
  };

  const onWeekClick = (newWeekValue: number) => {
    if (newWeekValue === -1) return;
    onChange({
      ...value,
      week_number: newWeekValue,
    });
  };
  return (
    <div className={`font-size-big ${styles.main} ${containerClassName}`}>
      <ValueSelector label="Day of week" value={value.day_of_week + 1} onChange={onDayClick} />
      <ValueSelector label="Week" value={value.week_number} onChange={onWeekClick} />
    </div>
  );
};
export default RelativeDateSelector;
