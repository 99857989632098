export enum VytalType {
  WEIGHT = 'weight',
  OXYGEN_LEVEL = 'oxygen_level',
  HEART_RATE = 'bpm',
  TEMPERATURE = 'temperature',
  STEPS = 'steps',
  STAIRS = 'stairs',
  SLEEP = 'sleep',
  BLOOD_PRESSURE = 'blood_pressure',
  BLOOD_SUGAR = 'blood_sugar',
}

export interface Vytal {
  patient: number;
  rpm_action: number;
  device: number;
  name: string;
  type: VytalType;
  /**
   * decimal
   */
  value: string;
  /**
   * decimal
   */
  value_2: string;
}

export interface VytalResponse {
  /**
   * YYYY-MM-DD
   */
  day: string;
  vytal: Vytal;
}
