import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const WeightIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1.5H9.89109C10.2759 2.16281 10.5 2.92992 10.5 3.75C10.5 6.23133 8.48133 8.25 6 8.25C3.51867 8.25 1.5 6.23133 1.5 3.75C1.5 2.92992 1.72406 2.16281 2.10891 1.5H1.5C0.672891 1.5 0 2.17289 0 3V10.5C0 11.3271 0.672891 12 1.5 12H10.5C11.3271 12 12 11.3271 12 10.5V3C12 2.17289 11.3271 1.5 10.5 1.5ZM6 7.5C8.07117 7.5 9.75 5.82117 9.75 3.75C9.75 1.67883 8.07117 0 6 0C3.92883 0 2.25 1.67883 2.25 3.75C2.25 5.82117 3.92883 7.5 6 7.5ZM5.99297 3.93891L6.78 2.10234C6.86203 1.91086 7.08328 1.82297 7.27289 1.90523C7.4632 1.9868 7.55133 2.20734 7.47 2.39742L6.68086 4.23844C6.83742 4.40625 6.9375 4.62773 6.9375 4.875C6.9375 5.39273 6.51773 5.8125 6 5.8125C5.48227 5.8125 5.0625 5.39273 5.0625 4.875C5.0625 4.35984 5.47875 3.94289 5.99297 3.93891Z"
        fill={color}
      />
    </svg>
  );
};

export default WeightIcon;
