import { Dispatch, useCallback, useEffect, useState } from 'react';
import { getVytalChart } from 'services/dashboardService';
import IVytalChart from 'types/Dashboard/VytalChart';
import { iconType } from 'types/Patients/constants';
import { setVytalChart } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useDashboardVytalCharts = (
  type: iconType,
  days: number,
  patientId: number
): [IVytalChart, Dispatch<DashboardAction>] => {
  const {
    state: { vytalsChart },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const [vytalsData, setVytalsData] = useState<IVytalChart>();

  const fetchVytalChartData = useCallback(async () => {
    if (!type || !days || !patientId) return;
    const data = await getVytalChart(type, days, patientId);
    setVytalsData(data);
  }, [type, days, patientId]);

  const vytalChartDataInit = useCallback(async () => {
    await fetchVytalChartData();
    fetchRegistry.current.vytals.list[`${patientId}-${type}`] = true;
  }, [fetchRegistry, fetchVytalChartData, type, patientId]);

  useEffect(() => {
    if (!days || !type || !patientId) return;
    if (!vytalsChart || !fetchRegistry.current.vytals.list[`${patientId}-${type}`]) {
      vytalChartDataInit();
    }
  }, [fetchRegistry, days, type, patientId, vytalsChart, vytalChartDataInit]);

  useEffect(() => {
    if (vytalsData) {
      dispatch(setVytalChart(vytalsData, patientId));
    }
  }, [dispatch, vytalsData, patientId]);

  return [vytalsData, dispatch];
};
export default useDashboardVytalCharts;
