import CollapsibleCard from 'components/CollapsibleCard';
import { useMemo, useState } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import { useDashboardContext } from '../store/DashboardContext';
import useDashboardOutstandingChats from '../store/hooks/useDashboardOutstandingChats';
import EmptyOutstandingChats from './EmptyOutstandingchats';
import Header from './Header';
import OutstandingChatItem from './OutstandingChatItem';
import styles from './styles.module.css';
import { mapOutstandingChatToList } from './utils';

interface OutstandingChatsProps {
  collapsed: boolean;
}
const OutstandingChats = ({ collapsed }: OutstandingChatsProps) => {
  const [date, setDate] = useState<DatePickerValue>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  });

  const {
    state: { totalPatients },
  } = useDashboardContext();
  const [{ book: outstandingChats, currentPage }] = useDashboardOutstandingChats(date);

  const outstandingChatsCurrentPage = useMemo(() => {
    return outstandingChats?.[currentPage];
  }, [currentPage, outstandingChats]);

  const outstandingChatsMappedToList = useMemo(
    () => outstandingChatsCurrentPage?.results?.map(mapOutstandingChatToList) ?? [],
    [outstandingChatsCurrentPage]
  );

  return (
    <CollapsibleCard
      outerCollapse={collapsed}
      className="bg-white d-flex flex-column"
      headerClassName="p-0"
      headers={[
        <Header
          key={0}
          outstandingChatPatients={outstandingChatsMappedToList.length}
          totalPatients={totalPatients}
          date={date}
          setDate={setDate}
        />,
      ]}
    >
      <div className={styles.body} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          {outstandingChatsMappedToList && outstandingChatsMappedToList.length > 0 ? (
            <>
              {outstandingChatsMappedToList?.map((item) => (
                <OutstandingChatItem chat={item} key={item.id} />
              ))}
            </>
          ) : (
            <EmptyOutstandingChats />
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};
export default OutstandingChats;
