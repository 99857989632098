import React, { Component } from 'react';

export default class AlertItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text ? props.text : '- no text -',
      style: props.type === 'hexagon' ? 'alert-item-container-hexagon' : 'alert-item-container',
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.text !== this.props.text) {
      this.setState({ text: this.props.text });
    }
  };
  resolveColor = () => {
    if (this.props.type === 'hexagon') {
      return '#F5485C';
    } else if (this.props.color) {
      return this.props.color;
    } else {
      return '';
    }
  };

  resolveBorder = () => {
    if (this.props.type === 'hexagon') {
      return undefined;
    } else {
      return `1px solid ${this.props.color}`;
    }
  };

  render = () => (
    <div
      className={`${this.state.style} ${this.props.className || ''}`}
      style={{
        ...this.props.style,
        color: this.resolveColor(),
        border: this.resolveBorder(),
      }}
    >
      {this.state.text}
    </div>
  );
}

export class AlertItemImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: props.image ? props.image : undefined,
      color: props.color ? props.color : '#F5485C',
      borderColor: props.color ? props.color : props.type === 'hexagon' ? 'transparent' : '#F5485C',
      style: props.type === 'hexagon' ? 'alert-item-container-hexagon' : 'alert-item-container',
    };
  }

  render() {
    return (
      <div
        className={this.state.style}
        style={{
          color: this.state.color,
          border: this.props.type === 'hexagon' ? '' : '1px solid ' + this.state.borderColor,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        {this.state.image && (
          <img style={{ width: 13, height: 13, marginTop: -2 }} src={this.state.image} alt="" />
        )}
      </div>
    );
  }
}
