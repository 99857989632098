import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { VytalResponse, VytalType } from 'types/ApiModels/Patients/Vytal';
import { IDataset } from 'types/VytalChart/dataPoint';
import { getAxisLabel, getChartType, nullWrap } from 'util/goalChart';
import { Config } from '../../../GoalsThreshold/Content/components/GoalChartCard/components/GoalLineChart/util';
import { generateDataset } from './utils/generateDateSet';

interface IPatientTimelineChart {
  dataPoints: VytalResponse[];
  selectedGoal: VytalType;
  onDataPointClick: (idx: number) => void;
}

const PatientTimelineChart = ({
  dataPoints = [],
  selectedGoal,
  onDataPointClick,
}: IPatientTimelineChart) => {
  //State that manages the labels and datasets
  const [chartData, setChartData] = useState<{ labels: string[]; datasets: IDataset[] }>({
    labels: [],
    datasets: [],
  });

  //State that manages the annotations (thresholds and labels)
  const clickEvent: any = ['click'];
  const [options, setOptions] = useState({
    events: clickEvent,
    responsive: true,
    maintainAspectRatio: false,
    plugins: Config.PLUGINS,
    elements: Config.ELEMENTS,
    scales: Config.SCALES(getAxisLabel(selectedGoal)),
    layout: Config.LAYOUT,
    onClick: (e, array) => onDataPointClick(array[0]?.index),
  });

  //Generate Data Points
  useEffect(() => {
    const datasets: IDataset[] = [generateDataset(dataPoints, getChartType(selectedGoal))];

    const labels = dataPoints.map((dataPoint) => moment(dataPoint.day).format('MM/DD')).reverse();
    setChartData({
      labels: nullWrap(labels),
      datasets,
    });
  }, [dataPoints, selectedGoal]);

  //Generate vertical lines for BP
  useEffect(() => {
    setOptions((prev) => {
      let newOptions = {
        ...prev,
        scales: Config.SCALES(getAxisLabel(selectedGoal)),
        showLine: true,
        plugins: {
          ...prev.plugins,
          annotation: { annotations: [] },
        },
      };

      if (selectedGoal === VytalType.BLOOD_PRESSURE) {
        const newLines = dataPoints
          .map((read, index) =>
            read[0] && read[1]
              ? {
                  type: getChartType(selectedGoal),
                  xMax: index,
                  xMin: index,
                  yMax: read[0],
                  yMin: read[1],
                  annotationID: `DataPoint-${index}`,
                  drawTime: 'beforeDatasetsDraw',
                  borderColor: '#8FAAC3',
                  borderWidth: 3,
                }
              : null
          )
          .filter((line) => line);
        newOptions = {
          ...newOptions,
          showLine: false,
          plugins: {
            ...newOptions.plugins,
            annotation: {
              annotations: [...newLines],
            },
          },
        };
      }
      return newOptions;
    });
  }, [dataPoints, selectedGoal]);

  return <Line options={options} data={chartData} />;
};
export default PatientTimelineChart;
