import separatorIcon from 'assets/icons/menu/separator.png';
import { CategoryScale, Chart, LinearScale, LineElement, PointElement, Title } from 'chart.js';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ToDoList from './ToDoList';
import RedAlerts from './RedAlerts';
import YellowAlerts from './YellowAlerts';
import OutstandingChats from './OutstandingChats';
import Adherence from './Adherence';
import HighestUrgency from './HighestUrgency';
import PatientsEscalated from './PatientsEscalated';
import Schedule from './Schedule';
import DailyAdherence from './DailyAdherence/DailyAdherence';
import StickyNotes from './StickyNotes';
import BillingOverview from './BillingOverview';
import DashboardProvider from './store/DashboardProvider';
import VytracLayout from 'components/VyTracLayout';
import CollapseLine from './CollapseLine';
import { useDashboardContext } from './store/DashboardContext';
import {
  setTrackingActivityId,
  setTrackingType,
  toggleTimeIsTracking,
} from './store/DashboardActionCreators';
import { getTrackingActivities } from 'services/trackingService';
import { useDialogContext } from 'components/VyTracLayout/store';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import ActionsModal from './ActionsModal/ActionsModal';
import useDashboardTracking from './store/hooks/useDashboardTracking';

Chart.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

const ProviderDashboardContent = () => {
  const { dispatch } = useDashboardContext();
  const { dialog: DialogComponent } = useDialogContext();
  const { errorDialog } = useDialog();
  useDashboardTracking();
  const [collapseRedAlerts, setCollapseRedAlerts] = useState(true);
  const [collapseYellowAlerts, setCollapseYellowAlerts] = useState(true);
  const [collapseOutstandingChats, setCollapseOutstandingChats] = useState(true);
  const [collapseAdherence, setCollapseAdherence] = useState(true);
  const [collapseHighestUrgency, setCollapseHighestUrgency] = useState(true);
  const [collapsePatientsEscalated, setCollapsePatientsEscalated] = useState(true);
  const [collapseSchedule, setCollapseSchedule] = useState(true);
  const [collapseDailyAdherence, setCollapseDailyAdherence] = useState(true);
  const [collapseStickyNotes, setCollapseStickyNotes] = useState(true);
  const [collapseBillingOverview, setCollapseBillingOverview] = useState(true);

  const collapsedFirstRow = useMemo(
    () =>
      collapseRedAlerts && collapseYellowAlerts && collapseOutstandingChats && collapseAdherence,
    [collapseRedAlerts, collapseYellowAlerts, collapseOutstandingChats, collapseAdherence]
  );
  const toggleCollapseFirstRow = useCallback(() => {
    if (collapsedFirstRow) {
      setCollapseRedAlerts(false);
      setCollapseYellowAlerts(false);
      setCollapseOutstandingChats(false);
      setCollapseAdherence(false);
    } else {
      setCollapseRedAlerts(true);
      setCollapseYellowAlerts(true);
      setCollapseOutstandingChats(true);
      setCollapseAdherence(true);
    }
  }, [collapsedFirstRow]);

  const collapsedSecondRow = useMemo(() => collapseHighestUrgency, [collapseHighestUrgency]);
  const toggleCollapseSecondRow = useCallback(() => {
    if (collapsedSecondRow) {
      setCollapseHighestUrgency(false);
    } else {
      setCollapseHighestUrgency(true);
    }
  }, [collapsedSecondRow]);

  const collapsedThirdRow = useMemo(
    () => collapsePatientsEscalated && collapseSchedule && collapseDailyAdherence,
    [collapsePatientsEscalated, collapseSchedule, collapseDailyAdherence]
  );
  const toggleCollapseThirdRow = useCallback(() => {
    if (collapsedThirdRow) {
      setCollapsePatientsEscalated(false);
      setCollapseSchedule(false);
      setCollapseDailyAdherence(false);
    } else {
      setCollapsePatientsEscalated(true);
      setCollapseSchedule(true);
      setCollapseDailyAdherence(true);
    }
  }, [collapsedThirdRow]);

  const collapsedFourthRow = useMemo(
    () => collapseStickyNotes && collapseBillingOverview,
    [collapseStickyNotes, collapseBillingOverview]
  );
  const toggleCollapseFourthRow = useCallback(() => {
    if (collapsedFourthRow) {
      setCollapseStickyNotes(false);
      setCollapseBillingOverview(false);
    } else {
      setCollapseStickyNotes(true);
      setCollapseBillingOverview(true);
    }
  }, [collapsedFourthRow]);

  useEffect(() => {
    const setChartingId = async () => {
      const activities = await getTrackingActivities();
      const chartingId = activities.find(
        (activity) => activity.name.toLowerCase() === 'charting'
      )?.id;
      dispatch(setTrackingActivityId(chartingId));

      if (!chartingId) {
        errorDialog(
          'Error',
          "To track time on Dashboard there has to be a 'Charting' activity created"
        );
      } else {
        dispatch(setTrackingType(TrackingType.PATIENT_TODO));
        dispatch(toggleTimeIsTracking(true));
      }
    };
    setChartingId();
  }, []);

  return (
    <div className="position-relative">
      <div className="position-absolute w-100" style={{ zIndex: 200 }}>
        {DialogComponent && <DialogComponent />}
      </div>
      <ToDoList />

      <div className="dashboard-separator">
        <img style={{ width: 10, height: 4, margin: '0 auto' }} src={separatorIcon} alt="" />
      </div>

      <div className="dashboard-details">
        <div className="mb-3 position-relative" style={{ zIndex: 93 }}>
          <CollapseLine isOpen={collapsedFirstRow} toggleOpen={toggleCollapseFirstRow} />
          <Row>
            <Col
              onClick={() => setCollapseRedAlerts((prev) => !prev)}
              className="dashboard-detail-container px-2"
              xl="3"
              style={{ zIndex: 93 }}
            >
              <RedAlerts collapsed={collapseRedAlerts} />
            </Col>

            <Col
              onClick={() => setCollapseYellowAlerts((prev) => !prev)}
              className="dashboard-detail-container"
              xl="3"
              style={{ zIndex: 92 }}
            >
              <YellowAlerts collapsed={collapseYellowAlerts} />
            </Col>

            <Col
              onClick={() => setCollapseOutstandingChats((prev) => !prev)}
              className="dashboard-detail-container"
              xl="3"
              style={{ zIndex: 90 }}
            >
              <OutstandingChats collapsed={collapseOutstandingChats} />
            </Col>

            <Col
              onClick={() => setCollapseAdherence((prev) => !prev)}
              className="dashboard-detail-container"
              style={{ minWidth: '410px', zIndex: 90 }}
              xl="3"
            >
              <Adherence collapsed={collapseAdherence} />
            </Col>
          </Row>
        </div>

        <div className="mb-3 position-relative" style={{ zIndex: 92 }}>
          <CollapseLine isOpen={collapsedSecondRow} toggleOpen={toggleCollapseSecondRow} />
          <Row>
            <Col
              onClick={() => setCollapseHighestUrgency((prev) => !prev)}
              className="dashboard-detail-container mb-0 bigger height-auto"
              xs="12"
            >
              <HighestUrgency collapsed={collapseHighestUrgency} />
            </Col>
          </Row>
        </div>
        {/* <div className="mb-3 position-relative" style={{ zIndex: 91 }}>
          <CollapseLine isOpen={collapsedThirdRow} toggleOpen={toggleCollapseThirdRow} />
          <Row>
            <Col
              onClick={() => setCollapsePatientsEscalated((prev) => !prev)}
              className="dashboard-detail-container"
              xl="3"
            >
              <PatientsEscalated collapsed={collapsePatientsEscalated} />
            </Col>

            <Col
              onClick={() => setCollapseSchedule((prev) => !prev)}
              className="dashboard-detail-container"
              xl="3"
            >
              <Schedule collapsed={collapseSchedule} />
            </Col>

            <Col
              onClick={() => setCollapseDailyAdherence((prev) => !prev)}
              className="dashboard-detail-container"
              xl="6"
            >
              <DailyAdherence collapsed={collapseDailyAdherence} />
            </Col>
          </Row>
        </div> */}
        {/* <div className="mb-3 position-relative" style={{ zIndex: 90 }}>
          <CollapseLine isOpen={collapsedFourthRow} toggleOpen={toggleCollapseFourthRow} />
          <Row>
            <Col
              onClick={() => setCollapseStickyNotes((prev) => !prev)}
              className="dashboard-detail-container"
              xl="3"
            >
              <StickyNotes collapsed={collapseStickyNotes} />
            </Col>

            <Col
              onClick={() => setCollapseBillingOverview((prev) => !prev)}
              className="dashboard-detail-container"
              xl="9"
            >
              <BillingOverview collapsed={collapseBillingOverview} />
            </Col>
          </Row>
        </div> */}
      </div>
    </div>
  );
};

const ProviderDashboard = () => {
  return (
    <VytracLayout selectedRoute="dashboard">
      <DashboardProvider>
        <ProviderDashboardContent />
        <ActionsModal />
      </DashboardProvider>
    </VytracLayout>
  );
};
export default ProviderDashboard;
