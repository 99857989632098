import PatientSatisfactionCardBody from './PatientSatisfactionCardBody';
import PatientSatisfactionHeader from './PatientSatisfactionHeader';

const PatientSatisfaction = () => {
  return (
    <div className="dashboard-detail">
      <PatientSatisfactionHeader />
      <div>
        <PatientSatisfactionCardBody />
      </div>
    </div>
  );
};

export default PatientSatisfaction;
