import { Reducer } from 'react';
import { WebSocketAlertAction, WebSocketAlertActionTypes, webSocketAlertsReducer } from './Alerts';
import { WebSocketState } from './WebSocketContext';

export type AllWebSocketActions = WebSocketAlertAction;

enum AdminActionSubType {
  Alert = 'Alert',
}

const getSubType = (action: AllWebSocketActions) => {
  if (action.type in WebSocketAlertActionTypes) return AdminActionSubType.Alert;
};
const webSocketReducer: Reducer<WebSocketState, AllWebSocketActions> = (
  state: WebSocketState,
  action: AllWebSocketActions
): WebSocketState => {
  const subType = getSubType(action);

  switch (subType) {
    case AdminActionSubType.Alert: {
      const newAlerts = webSocketAlertsReducer(state.alerts, action as WebSocketAlertAction);
      return { ...state, alerts: newAlerts };
    }
  }
};
export default webSocketReducer;
