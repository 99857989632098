import Button from 'components/Button';
import VytracModal from 'components/Modal';
import { useEffect, useMemo, useState } from 'react';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import ModalBody from './Body/Body';
import styles from './styles.module.css';

interface IQuestionnaireModal {
  show: boolean;
  onClose: () => void;
  action: 'add' | 'delete';
  onAdd: (elements, name: string, template_id: number) => void;
  onDelete: (id: number) => void;
}

const QuestionnaireModal = ({
  show,
  onClose,
  action = 'add',
  onAdd,
  onDelete,
}: IQuestionnaireModal) => {
  const {
    templateState: {
      templateCarePlan: { questionnaireList },
    },
  } = useTemplateContext();

  const [selectedTemplate, setSelectedTemplate] = useState<IQuestionnaire>(null);
  const [elements, setElements] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState<string>('');

  useEffect(() => {
    setSelectedTemplate(null);
    setElements([]);
    setNewTemplateName('');
  }, [show]);

  const questionnaireNames = useMemo(() => {
    return questionnaireList.map((questionnaire) => questionnaire.name);
  }, [questionnaireList]);

  const isNameRepeated = useMemo(() => {
    return questionnaireNames.includes(newTemplateName);
  }, [newTemplateName, questionnaireNames]);

  return (
    <VytracModal
      title={action === 'add' ? 'Create template copy' : 'Delete template copy'}
      body={
        <ModalBody
          action={action}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          elements={elements}
          setElements={setElements}
          newTemplateName={newTemplateName}
          setNewTemplateName={setNewTemplateName}
          isNameRepeated={isNameRepeated}
        />
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={onClose} />
          {action === 'add' ? (
            <Button
              label="confirm"
              variant="confirm"
              disabled={newTemplateName === '' || isNameRepeated || !selectedTemplate}
              onClick={() => onAdd(elements, newTemplateName, selectedTemplate.id)}
            />
          ) : (
            <Button
              label="delete"
              variant="delete"
              disabled={!selectedTemplate}
              onClick={() => onDelete(selectedTemplate?.id)}
            />
          )}
        </div>
      }
      onClose={onClose}
      show={show}
      size="xl"
      centered
      dialogClassName={styles['questionnaire-modal']}
    />
  );
};
export default QuestionnaireModal;
