import React, { useCallback, useMemo } from 'react';
import ActivityTimeline from 'components/ActivityTimeline';
import ActivityTimelineContext from 'components/ActivityTimeline/store';
import {
  setAppointmentsModalType,
  setPreivousAppointments,
  setSelectedAppointment,
  setSelectedDate,
  useAppointmentsContext,
} from 'screens/Appointments/store';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import { mapAppointmentsToActivities } from 'components/ActivityTimeline/utils';
import { initialAppointment } from 'screens/Appointments/Content/utils';
import { AppointmentsModalType } from '../../../AppointmentsModal/utils';

const calendarHeight = 'calc(100vh - 220px)';

const AppointmentsTimeline = () => {
  const {
    dispatch,
    appointmentsState: { timePeriodSelected, selectedDate },
  } = useAppointmentsContext();

  const setTimelineDate = useCallback(
    (datePickerValue: DatePickerValue) => {
      const dateValue = convertDatepickToDate(datePickerValue);
      dispatch(setSelectedDate(dateValue));
    },
    [dispatch]
  );

  const timelineActivities = useMemo(() => mapAppointmentsToActivities([initialAppointment]), []);

  const onActivityClick = () => {
    dispatch(setAppointmentsModalType(AppointmentsModalType.EDIT));
    dispatch(setSelectedAppointment(initialAppointment));
    dispatch(setPreivousAppointments());
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ maxHeight: calendarHeight, overflow: 'auto' }}>
        <ActivityTimelineContext.Provider
          value={{
            activities: timelineActivities,
            currentSelectedDate: convertDateToDatePickValue(selectedDate),
            setCurrentSelectedDate: setTimelineDate,
            clickable: true,
            onActivityClick,
            draggable: false,
          }}
        >
          <ActivityTimeline
            currentView={timePeriodSelected}
            style={{ minHeight: calendarHeight }}
          />
        </ActivityTimelineContext.Provider>
      </div>
    </div>
  );
};
export default AppointmentsTimeline;
