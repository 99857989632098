import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import ReactSelect, { MultiValue } from 'react-select';
import { setFormDirty } from 'screens/Administration/store/Forms/formActionCreators';
import { useAdminPopulations } from 'screens/Administration/store/hooks';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { AdminForm } from 'types/Administration/AdminForms/AdminForm';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import styles from './styles.module.css';

const parseToEditor = (body: string) =>
  body
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(body)))
    : EditorState.createEmpty();

interface FormDetailProps {
  form: AdminForm;
}
const FormDetail = ({ form }: FormDetailProps) => {
  const [formName, setFormName] = useState(form.name);
  const [usedFor, setUsedFor] = useState(form.population);
  const [editorState, setEditorState] = useState<EditorState>(parseToEditor(form?.editor_body));
  const [{ list: populations }] = useAdminPopulations();
  const [{ dirtyForms }, dispatch] = useAdminForms();

  const editorContent = useMemo(() => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return { json: JSON.stringify(raw), html: draftToHtml(raw) };
  }, [editorState]);

  useEffect(() => {
    if (!dirtyForms[form.id]) {
      setFormName(form.name);
      setUsedFor(form.population);
      setEditorState(parseToEditor(form?.editor_body));
    }
  }, [form, dirtyForms]);

  const newForm: AdminForm = useMemo(() => {
    const test = {
      ...form,
      name: formName,
      population: usedFor,
      editor_body: editorContent.json,
      editor_html: editorContent.html,
    };
    return test;
  }, [formName, usedFor, editorContent, form]);

  useEffect(() => {
    if (
      form.name !== formName ||
      form.population !== usedFor ||
      form.editor_body !== editorContent.json
    ) {
      dispatch(setFormDirty(newForm));
    }
  }, [formName, usedFor, dispatch, newForm, editorContent, form]);

  const populationsToSelect = useCallback(
    (pops: PopulationWithCount[]) =>
      pops?.map((pop) => ({
        label: pop.name,
        value: pop.id,
      })),
    []
  );

  const handlePopulationChange = useCallback(
    (newValue: MultiValue<{ label: string; value: number }>) => {
      setUsedFor(populations.filter((p) => newValue.some((v) => v.value === p.id)));
    },
    [populations]
  );

  return (
    <div className="px-4">
      <div>
        <div className={`d-flex flex-column`}>
          <div className={`d-flex gap align-items-center px-3 ${styles['subcard']} mt-1`}>
            <label className="m-0 font-size-big" style={{ flexBasis: 75 }}>
              Form name
            </label>
            <input
              className={`${styles['form-name-input']} flex-grow-1 rounded pl-2`}
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
            />
          </div>
          <div className={`d-flex gap align-items-center py-1 px-3 ${styles['subcard']} mt-1`}>
            <label className="m-0 font-size-big text-nowrap" style={{ flexBasis: 80 }}>
              Used for
            </label>
            <ReactSelect
              isMulti
              options={populationsToSelect(populations)}
              value={populationsToSelect(usedFor)}
              onChange={handlePopulationChange}
              styles={{
                ...vytracSelectStyle,
                control: (base, state) => ({
                  ...vytracSelectStyle.control(base, state),
                  ...styles,
                }),
                container: (base) => ({ ...base, width: '100%' }),
              }}
            />
          </div>
          <div className="mt-1">
            <div className="w-100">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                editorClassName={styles['editor']}
                wrapperClassName="overflow-visible"
                toolbarClassName={styles['toolbar']}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'history',
                  ],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormDetail;
