import React from 'react';
import CrossButton from '../CrossButton';
import styles from './styles.module.css';

interface IInputField {
  placeholder?: string;
  onChange?: (value: any) => void;
  label?: string;
  type?: string;
  name?: string;
  value?: string | number;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  errorLabel?: string;
  errorClassName?: string;
  required?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
}
export default function InputField({
  placeholder,
  onChange,
  label,
  type,
  name,
  value,
  className,
  containerClassName,
  labelClassName,
  errorLabel,
  errorClassName,
  required,
  readOnly,
  style,
}: IInputField) {
  const inputFieldRef = React.useRef(null);
  const onLabelClick = (e: React.MouseEvent) => {
    inputFieldRef.current.click(e);
  };
  const removeFile = () => {
    onChange({ target: { files: [''] } });
  };

  return (
    <>
      <div
        className={`${containerClassName ? containerClassName : ''} ${
          styles.vytracInputFieldContainer
        } position-relative`}
      >
        {label && (
          <label
            className={`${labelClassName ? labelClassName : ''} ${styles.customFileUpload} m-0`}
            htmlFor={name}
            onClick={onLabelClick}
          >
            {label}
          </label>
        )}
        <input
          readOnly={readOnly}
          value={value}
          ref={inputFieldRef}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          className={`${className ? className : ''} ${styles.vytracInputField}`}
          type={type ? type : 'text'}
          required={required}
          style={style}
        />
        {type === 'file' && <CrossButton onClick={removeFile} />}
      </div>
      <label className={`${errorClassName} position-absolute`} style={{ top: 75 }}>
        {errorLabel}
      </label>
    </>
  );
}
