import { AdminForm } from 'types/Administration/AdminForms/AdminForm';

export enum FormActionTypes {
  SET_FORMS = 'SET_FORMS',
  SET_CURRENT_FORM = 'SET_CURRENT_FORM',
  DELETE_FORM = 'DELETE_FORM',
  UPDATE_FORM = 'UPDATE_FORM',
  OPEN_ADD_FORM_MODAL = 'OPEN_ADD_FORM_MODAL',
  ADD_NEW_FORM = 'ADD_NEW_FORM',
  OPEN_DELETE_FORM_MODAL = 'OPEN_DELETE_FORM_MODAL',
  SET_FORM_DIRTY = 'SET_FORM_DIRTY',
  RESET_DIRTY_FORMS = 'RESET_DIRTY_FORMS',
  UPDATE_DIRTY_FORMS = 'UPDATE_DIRTY_FORMS',
  CLEAR_DIRTY_FORM = 'CLEAR_DIRTY_FORM',
}

export type FormAction =
  | {
      type: FormActionTypes.SET_FORMS;
      payload: { forms: AdminForm[] };
    }
  | {
      type: FormActionTypes.SET_CURRENT_FORM;
      payload: { form: AdminForm };
    }
  | {
      type: FormActionTypes.UPDATE_FORM;
      payload: {
        form: AtLeast<AdminForm, 'id'>;
        oldId?: number;
      };
    }
  | { type: FormActionTypes.DELETE_FORM; payload: { formId: number } }
  | {
      type: FormActionTypes.OPEN_ADD_FORM_MODAL;
      payload: {
        open: boolean;
      };
    }
  | {
      type: FormActionTypes.ADD_NEW_FORM;
      payload: {
        form: AdminForm;
      };
    }
  | {
      type: FormActionTypes.OPEN_DELETE_FORM_MODAL;
      payload: {
        open: boolean;
      };
    }
  | {
      type: FormActionTypes.SET_FORM_DIRTY;
      payload: {
        form: AdminForm;
      };
    }
  | {
      type: FormActionTypes.RESET_DIRTY_FORMS;
    }
  | {
      type: FormActionTypes.UPDATE_DIRTY_FORMS;
    }
  | {
      type: FormActionTypes.CLEAR_DIRTY_FORM;
      payload: {
        formId: number;
      };
    };
