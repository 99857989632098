import React from 'react';
import styles from './styles.module.css';
import {ReactComponent as ArrowIcon} from '../../../../../../../../assets/icons/arrow_left.svg';
import {useHistory} from 'react-router-dom';
import PatientInfo from './components/PatientInfo';
import CareTeamInfo from './components/CareTeamInfo';
import Insurer from './components/Insurer';
import RegisteredCare from './components/RegisteredCare';
import TimeSpanGoalChart from './components/TimeSpanGoalChart';
import MonthlyGoalChart from './components/MonthlyGoalChart';
const PatientDetails = ({patient}) => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={styles.arrow} onClick={() => history.goBack()}>
        <ArrowIcon height={13} width={9} fill="#8FAAC3" />
      </div>
      <div className={styles.container}>
        <PatientInfo patient={{...patient?.patient, id: patient?.id}} />
        <CareTeamInfo />
        <Insurer />
        <RegisteredCare categories={['RPM', 'CCM', 'RTM']} />
        <TimeSpanGoalChart
          proportion={0.6}
          name={'99453'}
          daysLeft={4}
          totalDays={365}
        />
        <TimeSpanGoalChart
          proportion={0.6}
          name={'99454'}
          daysLeft={4}
          totalDays={365}
        />
        <MonthlyGoalChart proportion={0.8} name={'99490'} />
        <MonthlyGoalChart proportion={0.91} name={'99981'} />
      </div>
    </div>
  );
};

export default PatientDetails;
