import Card from 'components/Card';
import AddressInformation from './AddressInformation';
import ContactInformation from './ContactInformation';
import PersonalInfomationHeader from './PersonalInfomationHeader';

const ProviderPersonalInformation = () => {
  return (
    <Card
      className="w-100 card-bg-border"
      headers={[<PersonalInfomationHeader key={'personal-information-header'} />]}
      divideChildren
    >
      <ContactInformation />
      <AddressInformation />
    </Card>
  );
};
export default ProviderPersonalInformation;
