import { NavigationAction, NavigationActionTypes, navigationReducer } from 'util/commonContext/NavigationContext';
import { AnalyticsAction, AnalyticsTypes } from './AnalyticsActionTypes';

export type AllAnalyticsActions = NavigationAction | AnalyticsAction;

enum AnalyticsActionSubType {
  Navigation = 'Navigation',
  Analytics = 'Analytics',
}

const getSubType = (action: AllAnalyticsActions) => {
  if (action.type in NavigationActionTypes) return AnalyticsActionSubType.Navigation;
  if (action.type in AnalyticsTypes) return AnalyticsActionSubType.Analytics;
};


const AnalyticsReducer = (state, action: AllAnalyticsActions ) => {
  const subType = getSubType(action);
  switch (subType) {
    case AnalyticsActionSubType.Navigation: {
      return {
        ...state,
        analyticsNavigation: navigationReducer(state.templateNavigation, action as NavigationAction),
      };
    }
    
    default:
      return state;
  }
};

export default AnalyticsReducer;
