import GenericTable from 'components/GenericTable';
import PatientProfileRing from 'components/patient/PatientProfileRing/PatientProfileRing';
import AlertItem from 'components/Alertitem';
import styles from './styles.module.css';
import { formatDottedDate } from 'util/dateUtils';
import { IUrgency } from 'types/Dashboard/Urgency';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import NotesIndicator from 'components/NotesIndicator';
import UserBubble from 'components/UserBubble';
import Adherence from 'types/ApiModels/Patients/Adherence';
import {
  getOtherPhysicians,
  getPrimaryPhysician,
} from 'components/PatientPreviewTable/components/Row/PatientRow';
import UserBubbleGroup from 'components/UserBubbleGroup';

interface IPatientTable {
  patientList: IUrgency[];
}

const PatientTable = ({ patientList }: IPatientTable) => {
  const columns = [
    {
      title: 'score',
      key: 'score',
      render: ({ score }: IUrgency) => `${score ?? 0}`,
    },
    {
      title: 'patient',
      key: 'user',
      render: ({ user, avatarStatus }: IUrgency) => (
        <div className="d-flex align-items-center">
          <PatientProfileRing size={36} currentPatient={{ avatar_status: avatarStatus, user }} />
          <span className={styles.patientName}>
            {user.first_name} {user.last_name}
          </span>
        </div>
      ),
    },
    {
      title: 'patient id',
      key: 'user.id',
      render: ({ user }) => `#${user.id}`,
    },
    {
      title: 'date of birth',
      key: 'user.birthDate',
      render: ({ user }: IUrgency) => formatDottedDate(new Date(user.birth_date)),
    },
    {
      title: 'alerts',
      key: 'avatarStatus',
      render: ({ avatarStatus }: IUrgency) => (
        <div className="d-flex">
          {avatarStatus.read_alerts > 0 && (
            <AlertItem text={avatarStatus.read_alerts} color="#F5485C" />
          )}
          {avatarStatus.yellow_alerts > 0 && (
            <AlertItem text={avatarStatus.yellow_alerts} color="#F8CA54" />
          )}
          {avatarStatus.normal_readings > 0 && (
            <AlertItem text={avatarStatus.normal_readings} color="#8FAAC3" />
          )}
        </div>
      ),
    },
    {
      title: 'tags',
      key: 'populations',
      render: ({ populations }: IUrgency) => {
        return (
          <TagList
            tagList={
              populations.map<Tag>((p) => ({
                text: p.name,
              })) ?? []
            }
            maxCount={2}
          />
        );
      },
    },
    {
      title: 'complaint',
      key: 'complaint',
      render: () => 'Test complaint text',
    },
    {
      title: 'notes',
      key: 'notes',
      render: ({ notes }) => <NotesIndicator hasNotes={notes > 0} />,
    },
    {
      title: 'readings',
      key: 'adherence',
      render: (adherence: Adherence) => {
        return <>{`${adherence.adherence_days?.length ?? 0}/30`}</>;
      },
    },
    {
      title: 'primary',
      key: 'careTeam',
      render: ({ careTeam }: IUrgency) => {
        return <UserBubble user={getPrimaryPhysician(careTeam)} />;
      },
    },
    {
      title: 'others',
      key: 'others',
      render: ({ careTeam }: IUrgency) => (
        <UserBubbleGroup users={getOtherPhysicians(careTeam)} maxCount={3} />
      ),
    },
  ];
  return (
    <GenericTable
      tableClassName={styles.main}
      columns={columns}
      data={patientList}
      rowClassName={styles.row}
    />
  );
};

export default PatientTable;
