import {
  IPatientActionRecurrence,
  IPatientCarePlanAction,
  IPatientTimelineAction,
} from 'types/ApiModels/Patients/CarePlan';

const addException = (
  originalAction: IPatientCarePlanAction,
  selectedTimelineAction: IPatientTimelineAction
): IPatientActionRecurrence => {
  // add exception to original action
  let updatedRecurrence: IPatientActionRecurrence;
  const exceptions = originalAction.recurrence.exceptions;
  if (exceptions && !!exceptions[Symbol.iterator]) {
    updatedRecurrence = {
      ...originalAction.recurrence,
      exceptions: [...exceptions, selectedTimelineAction.previousStartDate],
    };
  } else {
    updatedRecurrence = {
      ...originalAction.recurrence,
      exceptions: [selectedTimelineAction.previousStartDate],
    };
  }

  return updatedRecurrence;
};

/**
 * Adds an exception to the original action recurrence when an action from the calendar is edited / deleted
 * @param originalActionIndex
 * @param updatedPatientActions
 * @param selectedTimelineAction
 */
export const addExceptionToRecurrence = (
  originalActionIndex: number,
  updatedPatientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction
): IPatientCarePlanAction => {
  originalActionIndex = updatedPatientActions.findIndex(
    (action) => action.id === selectedTimelineAction.originalActivityId
  );
  const originalAction = updatedPatientActions[originalActionIndex];

  // add exception to recurrence
  const updatedRecurrence = addException(originalAction, selectedTimelineAction);
  const updatedOriginalAction = {
    ...originalAction,
    recurrence: updatedRecurrence,
  };
  updatedPatientActions[originalActionIndex] = updatedOriginalAction;
  return updatedOriginalAction;
};
