import React from 'react';
import TimeSummary from './components/TimeSummary';
import InfomartionSummary from './components/InformationSummary';
import styles from './styles.module.css';

const Body = () => {
  return (
    <>
      <TimeSummary />
      <hr className="my-0" />
      <div className={styles.informationSummary}>
        <InfomartionSummary />
      </div>
    </>
  );
};

export default Body;
