import { Appointment } from 'types/ApiModels/Appointments/Appointment';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';

export enum ActivityType {
  ACTION = 'action',
  APPOINTMENT = 'appointment',
  ADHERENCE_BLOCK = 'adherence_block',
}

export interface Activity {
  type: ActivityType;
  start_date: string;
  end_date: string;
  id?: number;
  action_props?: Omit<IPatientTimelineAction, 'start_date' | 'end_date' | 'id'>;
  appointment_props?: Omit<Appointment, 'appointment_from' | 'appintment_to' | 'id'>;
}

export interface ActivityColumn {
  headerLabel: string;
  items: Activity[];
  date?: Date;
}

export interface ActivityMonthColumn {
  headerLabel: string;
  items: Activity[][];
}

export interface ActivityHourColumns {
  allDayItems: Activity[];
  hourActivities: ActivityColumn[];
}

export interface DateRangeActivity extends Activity {
  isStarting?: boolean;
  isEnding?: boolean;
  renderRow: number;
  chunk: number;
}

export interface ActivityWeekColumns {
  dateRangeActivitiesByDay: DateRangeActivity[][];
  weekArray: Date[];
  activitiesByDay: ActivityColumn[];
}

export interface ActivityMonthColumns {
  dateRangeWeekActivities: Activity[][];
  dateRangeMonthActivities: DateRangeActivity[][];
  activitiesByWeek: ActivityMonthColumn[];
  weekArray: Date[][];
}
