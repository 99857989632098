import BreadCrumbPath from 'types/Shared/BreadCrumbPath';

export enum NavigationActionTypes {
  SET_BREADCRUMB_PATH_LIST = 'SET_BREADCRUMB_PATH_LIST',
  SET_ACTIONS = 'SET_ACTIONS',
}

export type NavigationAction =
  | {
      type: NavigationActionTypes.SET_BREADCRUMB_PATH_LIST;
      payload: {
        breadcrumbPathList: BreadCrumbPath[];
      };
    }
  | {
      type: NavigationActionTypes.SET_ACTIONS;
      payload: {
        actions: () => JSX.Element;
      };
    };
