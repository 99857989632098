import { useHistory } from 'react-router-dom';
import FiltersButton from 'components/FiltersButton';
import GenericTable from 'components/GenericTable';
import { getQuestionnaireTemplates } from 'services/questionnaireService';
import TabEnum from '../../../../../../util/TabEnum';
import TabRouteMap from '../../../../../../util/TabRouteMap';
import styles from './styles.module.css';
import GenericColumn from 'types/Shared/GenericColumn';
import { useFetchList } from 'hooks';

const Questionnaires = () => {
  const history = useHistory();

  const [questionnaireTemplates] = useFetchList(getQuestionnaireTemplates);

  const columns: GenericColumn[] = [
    {
      title: 'Id',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      key: 'name',
      render: ({ name }) => <div className={styles.questionnaireItem}>{name}</div>,
    },
    {
      title: 'Questions',
      key: 'questions',
      textAlign: 'end',
      cellTextAlign: 'end',
    },
  ];

  return (
    <div className="container-with-padding">
      <div
        className="dashboard-detail"
        style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05))' }}
      >
        <div className="dashboard-item-header d-flex justify-content-between align-items-center">
          <div>Questionnaires</div>
          <FiltersButton />
        </div>
        <GenericTable
          onRowClick={(rowInfo) =>
            history.push(`${TabRouteMap[TabEnum.QUESTIONNAIRES]}/${rowInfo.id}`)
          }
          columns={columns}
          data={questionnaireTemplates}
          rowClassName={styles.row}
        />
      </div>
    </div>
  );
};

export default Questionnaires;
