export const teamNotesQuickFiltersKeys = {
  surveys: 'surveys',
  technicalIssues: 'technical-issues',
  medicalIssues: 'medical-issues',
  rpmIssues: 'rpm-issues',
};
const teamNotesQuickFilterList = [
  {
    key: teamNotesQuickFiltersKeys.surveys,
    label: 'SURVEYS',
  },
  {
    key: teamNotesQuickFiltersKeys.technicalIssues,
    label: 'TECHNICAL ISSUES',
  },
  {
    key: teamNotesQuickFiltersKeys.medicalIssues,
    label: 'MEDICAL ISSUES',
  },
  {
    key: teamNotesQuickFiltersKeys.rpmIssues,
    label: 'RPM ISSUES',
  },
];

export default teamNotesQuickFilterList;
