import React from 'react';
import styles from './styles.module.css';
import { ReactComponent as VerticalGrip } from 'assets/icons/drag.svg';
const Item = ({ name, onDragStart, className }) => {
  return (
    <div draggable onDragStart={onDragStart} className={className || styles.item}>
      <div>{name}</div>
      <VerticalGrip fill="white" />
    </div>
  );
};

export default Item;
