import QuickSortButton from './QuickSortButton';
import { QuickFilter } from 'types/Shared/Pagination';
import { FC } from 'react';

interface QuickFiltersProps {
  filters: QuickFilter[];
}

const QuickFilters: FC<QuickFiltersProps> = ({ filters }: QuickFiltersProps) => (
  <div className="d-flex align-items-center">
    <span className="mr-3">Quick filters</span>
    {filters.map((filter, index) => (
      <QuickSortButton
        key={index}
        sort={filter.sortKey}
        label={filter.label}
        onClick={filter.onClick}
        selected={filter.selected}
      />
    ))}
  </div>
);

export default QuickFilters;
