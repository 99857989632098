import { useMemo } from 'react';
import { IWebSocketAdherenceAlertMessage } from 'types/WebSocket/WebSocket';
import { useWebSocketContext } from 'websockets/WebSocketContext';

const useWebSocketAdherenceAlerts = (): { received: IWebSocketAdherenceAlertMessage } => {
  const {
    state: {
      alerts: { adherenceAlert },
    },
  } = useWebSocketContext();

  return { received: adherenceAlert };
};

export default useWebSocketAdherenceAlerts;
