import Button from 'components/Button';
import VyTracModal from 'components/Modal/VytracModal';
import React, { useCallback } from 'react';
import { Appointment } from 'types/ApiModels/Appointments/Appointment';
import { Body } from './components';
import { AppointmentsModalType, getTitle } from './utils';

interface IAppointmentsModal {
  selectedAppointment: Appointment;
  setSelectedAppointment: (appointment: Appointment) => void;
  type: AppointmentsModalType;
  onClose: () => void;
  onCreate: () => void;
  onConfirm: () => void;
  onSave: () => void;
  onRemove: () => void;
}
const AppointmentsModal = ({
  selectedAppointment,
  setSelectedAppointment,
  type,
  onClose,
  onCreate,
  onConfirm,
  onSave,
  onRemove,
}: IAppointmentsModal) => {
  const ModalFooter = useCallback(() => {
    if (!selectedAppointment) return null;

    const { patient_id, provider_id } = selectedAppointment;
    const disableConfirm = !patient_id || !provider_id;
    switch (type) {
      case AppointmentsModalType.CREATE: {
        return (
          <div className="d-flex justify-content-between w-100">
            <Button label="cancel" variant="cancel" onClick={onClose} />
            <Button label="create" variant="confirm" onClick={onCreate} disabled={disableConfirm} />
          </div>
        );
      }

      case AppointmentsModalType.EDIT: {
        return (
          <div className="d-flex justify-content-between w-100">
            <Button label="cancel" variant="cancel" onClick={onClose} />
            <Button label="save" variant="confirm" onClick={onSave} />
          </div>
        );
      }

      default: {
        <div />;
      }
    }
  }, [type, onClose, onConfirm, selectedAppointment]);

  return (
    <VyTracModal
      show={selectedAppointment !== null}
      title={getTitle(type)}
      body={
        <Body
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
        />
      }
      footer={<ModalFooter />}
      onClose={onClose}
      centered
    />
  );
};
export default AppointmentsModal;
