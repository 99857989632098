import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import {
  PatientGoalsThresholdsAction,
  PatientGoalsThresholdsActionTypes,
} from './patientGoalsThresholdsActionTypes';

export interface IPatientGoalsThresholdsState {
  selectedGoal: VytalType;
  thresholds: Record<VytalType, IGoalThreshold>;
  previousThresholds: Record<VytalType, IGoalThreshold>;
  goalsUnsavedChanges: boolean;
  editedGoals: Set<VytalType>;
}

export const goalsThresholdsInitialState: IPatientGoalsThresholdsState = {
  selectedGoal: null,
  thresholds: null,
  previousThresholds: null,
  goalsUnsavedChanges: false,
  editedGoals: new Set<VytalType>(),
};

const patientGoalsThresholdsReducer = (
  state = goalsThresholdsInitialState,
  action: PatientGoalsThresholdsAction
): IPatientGoalsThresholdsState => {
  switch (action.type) {
    case PatientGoalsThresholdsActionTypes.UPDATE_THRESHOLD: {
      const stateCopy: IPatientGoalsThresholdsState = {
        ...state,
        goalsUnsavedChanges: true,
        editedGoals: new Set(state.editedGoals).add(action.payload.goal),
      };
      stateCopy.thresholds[action.payload.goal] = action.payload.threshold;
      return stateCopy;
    }

    case PatientGoalsThresholdsActionTypes.SET_THRESHOLDS: {
      const thresholdMap = {} as Record<VytalType, IGoalThreshold>;
      action.payload.thresholds.forEach((threshold) => {
        thresholdMap[threshold.type] = threshold;
      });
      return { ...state, thresholds: thresholdMap };
    }

    case PatientGoalsThresholdsActionTypes.SET_THRESHOLDS_SELECTED_GOAL: {
      return { ...state, selectedGoal: action.payload.goal };
    }

    case PatientGoalsThresholdsActionTypes.SET_PREVIOUS_THRESHOLDS: {
      return { ...state, previousThresholds: state.thresholds };
    }

    case PatientGoalsThresholdsActionTypes.RESET_EDITED_GOALS: {
      return {
        ...state,
        editedGoals: new Set<VytalType>(),
        goalsUnsavedChanges: false,
        previousThresholds: state.thresholds,
      };
    }
  }
};
export default patientGoalsThresholdsReducer;
