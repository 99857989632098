import AvatarStatus from 'types/ApiModels/Patients/AvatarStatus';

export const convertCountToDegrees = (
  count: number,
  avatarStatus: RecursivePartial<AvatarStatus>
): number => {
  if (!avatarStatus) return 0;
  const { adherence, yellow_alerts, read_alerts, normal_readings } = avatarStatus;
  const totalCount = adherence + yellow_alerts + read_alerts + normal_readings;

  if (totalCount === 0) return 0;

  return (count / totalCount) * 360;
};
