import { PaginationControls } from 'components/Pagination';
import DateSelector from './components/DateSelector';
import styles from './styles.module.css';
const Header = () => {
  return (
    <div className={styles.main}>
      <div className="d-flex">
        <span>Patient billing</span>
        <DateSelector />
      </div>
      <PaginationControls />
    </div>
  );
};

export default Header;
