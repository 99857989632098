import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import { AdminPopulationAction, AdminPopulationActionTypes } from './adminPopulationsActionTypes';

export interface AdminPopulationsState {
  list: PopulationWithCount[];
  updatedIsActivePopulations: { id: number; value: boolean }[];
  deletedPopulations: number[];
  submitChanges: () => void;
  resetChanges: () => void;
}
export const populationsInitialState: AdminPopulationsState = {
  list: null,
  deletedPopulations: [],
  updatedIsActivePopulations: [],
  resetChanges: () => undefined,
  submitChanges: () => undefined,
};

const adminPopulationsReducer = (
  state: AdminPopulationsState,
  action: AdminPopulationAction
): AdminPopulationsState => {
  switch (action.type) {
    case AdminPopulationActionTypes.SET_POPULATIONS: {
      return { ...state, list: action.payload.populations };
    }
    case AdminPopulationActionTypes.APPEND_DELETE_POPULATION: {
      return {
        ...state,
        deletedPopulations: [...state.deletedPopulations, action.payload.populationId],
      };
    }
    case AdminPopulationActionTypes.APPEND_UPDATE_POPULATION: {
      const { id, value } = action.payload.updatedValue;
      const existingIdx = state.updatedIsActivePopulations.findIndex((u) => u.id === id);
      if (existingIdx === -1) {
        return {
          ...state,
          updatedIsActivePopulations: [
            ...state.updatedIsActivePopulations,
            action.payload.updatedValue,
          ],
        };
      }
      const copy = [...state.updatedIsActivePopulations];
      copy[existingIdx].value = value;
      return { ...state, updatedIsActivePopulations: copy };
    }
    case AdminPopulationActionTypes.SET_RESET_CHANGES: {
      return { ...state, resetChanges: action.payload.resetChanges };
    }
    case AdminPopulationActionTypes.SET_SUBMIT_CHANGES: {
      return { ...state, submitChanges: action.payload.submitChanges };
    }
    case AdminPopulationActionTypes.CLEAR_POPULATION_CHANGES: {
      return { ...state, updatedIsActivePopulations: [], deletedPopulations: [] };
    }

    default:
      return state;
  }
};

export default adminPopulationsReducer;
