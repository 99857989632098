import moment from 'moment';
import React from 'react';
import { Tagitem } from '../../../../../../../../../../../../../components/Tagitem';
import UserBubble from '../../../../../../../../../../../../../components/UserBubble';
import styles from './styles.module.css';

export default function VitalMessage({ message, from, className, timeStampClassName }) {
  const tagClassName = message.data?.status === 'yellow' ? styles.yellowTag : styles.normalTag;

  const renderTagAlert = () => {
    const text = `${message.data.value} (above medium alert = ${message.data.alert})`;

    return <Tagitem className={tagClassName} text={text} />;
  };
  return (
    <div className={className}>
      <div>
        <UserBubble user={from} />
      </div>
      <div className="d-flex flex-column">
        <div>
          Current {message.data && renderTagAlert()}
          <span className={styles.atUser}>
            @{from.firstName} {from.lastName}
          </span>{' '}
          is making progress
        </div>
        <span className={timeStampClassName}>{moment(message.createdAt).format('h:mm a')}</span>
      </div>
    </div>
  );
}
