import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import { ErrorMessage } from 'formik';
import styles from './styles.module.css';
const Step2 = () => {
  return (
    <div>
      <div className={styles['question']}>
        <div>Why are you requesting this?</div>
        <TextAreaFormik name="note" placeholder={"I'm requesting this because..."} />
        <ErrorMessage
          name="note"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
      <div className={`${styles['question']} mb-2`}>
        <div>Note for the patient</div>
        <TextAreaFormik name="patient_note" placeholder={'Add a note for the patient...'} />
        <ErrorMessage
          name="patient_note"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
    </div>
  );
};
export default Step2;
