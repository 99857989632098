import BreadCrumbPath from '../../../types/Shared/BreadCrumbPath';
import { NavigationAction, NavigationActionTypes } from './NavigationActionType';

export const setBreadcrumbPathList = (breadcrumbPathList: BreadCrumbPath[]): NavigationAction => ({
  type: NavigationActionTypes.SET_BREADCRUMB_PATH_LIST,
  payload: {
    breadcrumbPathList,
  },
});

export const setActions = (actions: () => JSX.Element): NavigationAction => ({
  type: NavigationActionTypes.SET_ACTIONS,
  payload: {
    actions,
  },
});
