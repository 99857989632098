import messageBubble from 'assets/icons/message_bubble.svg';

interface MessageCountBubbleProps {
  count: number;
  className?: string;
  width?: number | string;
  height?: number | string;
}
const MessageCountBubble = ({
  count,
  className,
  width = 'auto',
  height = 'auto',
}: MessageCountBubbleProps) => {
  return (
    <div className={className}>
      <span>{count}</span>
      &nbsp;
      <span>
        <img src={messageBubble} width={width} height={height} />
      </span>
    </div>
  );
};
export default MessageCountBubble;
