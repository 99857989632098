export enum PaginationActionType {
  FIRST_PAGE = 'FIRST_PAGE',
  PREVIOUS_PAGE = 'PREVIOUS_PAGE',
  NEXT_PAGE = 'NEXT_PAGE',
  LAST_PAGE = 'LAST_PAGE',
  SET_FILTER = 'SET_FILTER',
  SET_MAX_PAGE = 'SET_MAX_PAGE',
  SET_MAX_COUNT = 'SET_MAX_COUNT',
  SET_IS_LOADING = 'SET_IS_LOADING',
}

export type PaginationAction =
  | { type: PaginationActionType.FIRST_PAGE }
  | { type: PaginationActionType.PREVIOUS_PAGE }
  | { type: PaginationActionType.NEXT_PAGE }
  | { type: PaginationActionType.LAST_PAGE }
  | { type: PaginationActionType.SET_FILTER; payload: { sortKey: string } }
  | { type: PaginationActionType.SET_MAX_PAGE; payload: { maxPage: number } }
  | { type: PaginationActionType.SET_MAX_COUNT; payload: { maxCount: number } }
  | { type: PaginationActionType.SET_IS_LOADING; payload: { isLoading: boolean } };
