import styles from './styles.module.css';

const EmailSettingsHeader = () => {
  return (
    <div className={`d-flex w-100 p-3 ${styles.header} font-size-small`}>
      <div className={`pl-2 ${styles['name-th']}`}>name</div>
      <div>actions</div>
    </div>
  );
};
export default EmailSettingsHeader;
