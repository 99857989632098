import Card from 'components/Card';
import GenericTable from 'components/GenericTable';
import { useState } from 'react';
import AssignedPatientsHeader from './AssignedPatientsHeader';
import { assignedPatientsColumns } from './utils';

const ProviderAssignedPatients = () => {
  const [assignedPatients, setAssignedPatients] = useState([]);
  return (
    <Card
      className="w-100 card-bg-border"
      headers={[<AssignedPatientsHeader count={0} key={0} />]}
      divideChildren
    >
      <GenericTable columns={assignedPatientsColumns} data={assignedPatients} />
    </Card>
  );
};
export default ProviderAssignedPatients;
