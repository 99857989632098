import { useActivityTimelineContext } from 'components/ActivityTimeline/store';
import { FC } from 'react';
import { ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import styles from '../../ActivityItems.module.css';

interface DateRangeActivityItemProps {
  activityId: number;
  type: ActivityType;
  isStarting: boolean;
  isEnding: boolean;
  chunk: number;
  renderLabel: boolean;
  label: string;
}

const DateRangeActivityItem: FC<DateRangeActivityItemProps> = ({
  activityId,
  type,
  isStarting,
  isEnding,
  chunk,
  renderLabel,
  label,
}: DateRangeActivityItemProps) => {
  const { clickable, onActivityClick } = useActivityTimelineContext();
  const className = isStarting
    ? `${
        type === ActivityType.ADHERENCE_BLOCK
          ? styles['adherece-block-border-left']
          : styles['range-activity-border-left']
      } rounded-left ml-1`
    : isEnding
    ? `${
        type === ActivityType.ADHERENCE_BLOCK
          ? styles['adherence-block-border-right']
          : styles['range-activity-border-right']
      } rounded-right mr-1`
    : '';

  return (
    <div
      className={`d-flex ${
        type === ActivityType.ADHERENCE_BLOCK
          ? styles['adherence-block-container']
          : styles['range-activity-container']
      } ${className}`}
      onClick={
        clickable && type !== ActivityType.ADHERENCE_BLOCK
          ? (event) => onActivityClick(event, activityId)
          : null
      }
    >
      <div
        className={`position-relative ${styles['range-activity-completed']}`}
        style={{ flexBasis: `${chunk * 100}%` }}
      >
        <div
          className={`font-size-medium h-100 d-flex align-items-center pl-3 ${styles['range-activity-label']}`}
        >
          {renderLabel ? label : ''}
        </div>
      </div>
      {chunk !== 1 && <div />}
    </div>
  );
};
export default DateRangeActivityItem;
