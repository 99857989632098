import { ReactNode } from 'react';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
import Breadcrumbs from '../Breadcrumbs';
import styles from './styles.module.css';

interface BreadcrumbsWithActionsProps {
  breadcrumbs: BreadCrumbPath[];
  children?: ReactNode;
}

const BreadcrumbsWithActions = ({ breadcrumbs, children }: BreadcrumbsWithActionsProps) => (
  <div className={styles.breadCrumbsContainer}>
    <div>
      <Breadcrumbs items={breadcrumbs} />
    </div>
    {children ? <div className={styles.buttonContainer}>{children}</div> : null}
  </div>
);

export default BreadcrumbsWithActions;
