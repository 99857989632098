import moment from 'moment';
import React from 'react';
import BackButton from '../../../../../../../../../components/BackButton';
import Files from './components/Files';
import Log from './components/Log';
import Summary from './components/Summary';
import styles from './styles.module.css';

export default function EncounterDetail({ encounterId, onBack, patient }) {
  const currentProvider = {
    firstName: 'Thomas',
    lastName: 'Wayne',
    profileIcon: null,
  };

  const baseMoment = moment().startOf('day').add(16, 'hours').add(30, 'minutes');
  const fetchEncounter = (encounterId) => {
    return {
      id: encounterId.replace('#', ''),
      reason: 'Your weekly session',
      patientMessages: [
        {
          id: '1',
          type: 'text',
          data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque maximus mauris vel accumsan facilisis. Etiam augue nisi, condimen iaculis pharetra semper, aliquet eu arcu. Praesent nec venenatis ligula.',
          createdAt: moment(baseMoment).toDate(),
          from: `${patient.user.firstName} ${patient.user.lastName}`,
        },
        {
          id: '2',
          type: 'file',
          data: ['Documentation 2.docx', 'Documentation 2.docx'],
          createdAt: moment(baseMoment).add(60, 'seconds').toDate(),
          from: `${patient.user.firstName} ${patient.user.lastName}`,
        },
        {
          id: '3',
          type: 'audio',
          data: 'dummy audio 1',
          createdAt: moment(baseMoment).add(190, 'seconds').toDate(),
          from: `${patient.user.firstName} ${patient.user.lastName}`,
        },
      ],
      providerMessages: [
        {
          id: '4',
          type: 'mention',
          data: {
            message: 'please see this alert',
            destination: 'Amanda Spence',
          },
          createdAt: moment(baseMoment).add(30, 'seconds').toDate(),
          tag: 'Osteoarthritis',
          from: 'Thomas Wayne',
        },
        {
          id: '5',
          type: 'vital',
          data: {
            type: 'BP',
            value: '140/89mm HG',
            alert: '150 mm HG',
            status: 'yellow',
          },
          createdAt: moment(baseMoment).add(90, 'seconds').toDate(),
          from: 'Thomas Wayne',
        },
        {
          id: '6',
          type: 'vital',
          data: {
            type: 'BP',
            value: '150/89mm HG',
            alert: '150 mm HG',
            status: 'normal',
          },
          createdAt: moment(baseMoment).add(120, 'seconds').toDate(),
          from: 'Thomas Wayne',
        },
        {
          id: '7',
          type: 'call',
          data: 'dummy call 1',
          createdAt: moment(baseMoment).add(150, 'seconds').toDate(),
          duration: '0:43',
          from: 'Thomas Wayne',
        },
        {
          id: '8',
          type: 'vital',
          data: null,
          createdAt: moment(baseMoment).add(180, 'seconds').toDate(),
          from: 'Thomas Wayne',
        },
      ],
      startDate: moment().startOf('day').add(15, 'hours').add(25, 'minutes').toDate(),
      endDate: moment().startOf('day').add(16, 'hours').add(25, 'minutes').toDate(),
      duration: {
        voice: '00:36',
        video: '00:36',
        chat: '01:12',
      },
      subjective: 'Headache',
      objective: 'Dehydrated',
      assessments: 'Drink extra water today',
      plan: '1 week',
      patientSatisfaction: 30,
      files: [
        {
          name: 'File name.jpg',
          url: 'dummy',
          image: 'https://pbs.twimg.com/media/DY3Qzo5W4AALlYa.jpg',
        },
        {
          name: 'Really long document name',
          url: 'dummy',
        },
        {
          name: 'Documentation 2.docx',
          url: 'dummy',
        },
        {
          name: 'File name.jpg',
          url: 'dummy',
          image: 'https://pbs.twimg.com/media/DY3Qzo5W4AALlYa.jpg',
        },
      ],
    };
  };

  const encounterInfo = fetchEncounter(encounterId);

  patient.firstName = patient.user.first_name;
  patient.lastName = patient.user.last_name;
  return (
    <div className="d-flex justify-content-between">
      <div className={`d-flex flex-column ${styles.encounterLog}`}>
        <div className="d-flex justify-content-between">
          <BackButton
            onBack={onBack}
            label="Back to all encounters"
            style={{ width: 141 }}
            color="#1890FF"
          />
          <span className={styles.downloadEncounter}>Download encounter log</span>
        </div>
        <Log
          className={styles.innerContainer}
          patientMessages={encounterInfo.patientMessages}
          providerMessages={encounterInfo.providerMessages}
          encounterId={encounterInfo.id}
          encounterReason={encounterInfo.reason}
          patient={patient}
          provider={currentProvider}
        />
      </div>
      <div className={`d-flex flex-column ${styles.summaryFiles}`}>
        <Summary className={styles.innerContainerTop} encounter={encounterInfo} />
        <Files className={styles.innerContainerBottom} files={encounterInfo.files} />
      </div>
    </div>
  );
}
