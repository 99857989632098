import moment from 'moment';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import {
  Activity,
  ActivityMonthColumn,
  ActivityMonthColumns,
  DateRangeActivity,
} from 'types/Patients/PatientTimeline/ActivityTimeline';
import {
  areDatesEqual,
  dateIsBetween,
  diffDateDays,
  getCarePlanPeriodWeeks,
  getDateIndexesFromWeek,
  getMonthWeeks,
  getTimelessDateFromStr,
  getWeekEndDatesBetweenDates,
} from 'util/dateUtils/shared';
import { createRowsRenderer } from '../../utils/activityTimelineViewUtils';

const MONTH_VIEW_AMOUNT_OF_COLUMNS = 5;
const rowsRenderer = createRowsRenderer(MONTH_VIEW_AMOUNT_OF_COLUMNS);

const weekEndDaysForActivityMemo = () => {
  const weekEndDays: Record<number, Date[]> = {};
  //could change this to be weekEndDates and calculate fractions in here even
  const fractionsByWeekEndDays: Record<number, number[]> = {};

  return (activity: Activity) => {
    const { id, start_date, end_date } = activity;
    // goalCompletion
    //const mainFraction = activity.vytal.value / activity.vytal.goal;
    const mainFraction = 0;

    if (!weekEndDays[id] && !fractionsByWeekEndDays[id]) {
      weekEndDays[id] = getWeekEndDatesBetweenDates(
        getTimelessDateFromStr(start_date),
        getTimelessDateFromStr(end_date)
      );
      let remainingFraction = mainFraction * weekEndDays[id].length;
      const fractionAcrossWeeks = weekEndDays[id].map(() => {
        const currentFraction = remainingFraction > 1 ? 1 : remainingFraction;
        remainingFraction = remainingFraction > 1 ? remainingFraction - 1 : 0;
        return currentFraction;
      });
      fractionsByWeekEndDays[id] = fractionAcrossWeeks;
    }

    return {
      weekEndDays: weekEndDays[id],
      fractionsByWeekEndDays: fractionsByWeekEndDays[id],
    };
  };
};

export const getMonthColumns = (
  activities: Activity[],
  currentSelectedDate: Date,
  isCarePlanPeriod = false,
  carePlanFrom: Date
): ActivityMonthColumns => {
  rowsRenderer.clear();
  // For each week we need a list of activities, we don't care about which day each activity happens.
  const dateRangeWeekActivities: Activity[][] = [];

  // For activities that last longer than a week, we show them as a ranged activity on top, similar to what we had in Week view.
  // So this will need to handle render rows.
  // All we want here is a list of activities for each week, to tell which is the render row to be used.
  const dateRangeMonthActivities: DateRangeActivity[][] = [];

  // trying to think this as an activity done in a DAY, within a WEEK.
  // So it kind of make sense to have an array which does array[weekIdx][dayIdx][activityIdx]
  const weekActivities: Activity[][][] = [];

  let weeks: Date[][];
  if (isCarePlanPeriod) weeks = getCarePlanPeriodWeeks(currentSelectedDate, carePlanFrom);
  else weeks = getMonthWeeks(currentSelectedDate, MONTH_VIEW_AMOUNT_OF_COLUMNS);

  const sortedActivities = [...activities].sort((a1, a2) =>
    getTimelessDateFromStr(a1.start_date) < getTimelessDateFromStr(a2.start_date) ? -1 : 1
  );

  const getWeekEndDaysForActivity = weekEndDaysForActivityMemo();

  weeks.forEach((w, weekIdx) => {
    sortedActivities.forEach((a) => {
      const [begin, end] = [new Date(a.start_date), new Date(a.end_date)];
      const [beginIdx, endIdx] = getDateIndexesFromWeek(w, [begin, end]);

      const dateIsFullyWithinWeek = beginIdx !== -1 && endIdx !== -1;
      const spansOverMultipleDays = beginIdx !== endIdx;
      const spansOverTheWholeWeek =
        beginIdx === -1 &&
        endIdx === -1 &&
        dateIsBetween(
          w[0],
          getTimelessDateFromStr(a.start_date),
          getTimelessDateFromStr(a.end_date)
        );
      const spansOverMoreThanOneWeek =
        (!dateIsFullyWithinWeek && (beginIdx !== -1 || endIdx !== -1)) ||
        rowsRenderer.getExisting(a.id) >= 0 ||
        spansOverTheWholeWeek;

      if (spansOverMoreThanOneWeek) {
        const weekEndDaysBetweenDates = getWeekEndDaysForActivity(a);
        const currentWeekIdxInActivity = weekEndDaysBetweenDates.weekEndDays.findIndex((wed) =>
          areDatesEqual(wed, w[w.length - 1])
        );

        const newMonthRangeActivityItem: DateRangeActivity = {
          ...a,
          isStarting:
            // weekIdx === 0 ? beginIdx !== -1 : rowsRenderer.getExisting(a.id) === undefined,
            moment(a.start_date).isBetween(moment(w[0]), moment(w[w.length]), undefined, '[]'),
          isEnding: endIdx !== -1,
          renderRow: rowsRenderer.renderNewOrGetExisting(a.id, weekIdx),
          chunk: weekEndDaysBetweenDates.fractionsByWeekEndDays[currentWeekIdxInActivity] ?? 0,
        };
        dateRangeMonthActivities[weekIdx] = dateRangeMonthActivities[weekIdx]
          ? [...dateRangeMonthActivities[weekIdx], newMonthRangeActivityItem]
          : [newMonthRangeActivityItem];
        endIdx !== -1 && rowsRenderer.popRowByRegistryId(a.id);
        return;
      }
      if (spansOverMultipleDays) {
        //Process week range items
        dateRangeWeekActivities[weekIdx] = dateRangeWeekActivities[weekIdx]
          ? [...dateRangeWeekActivities[weekIdx], a]
          : [a];
      } else if (beginIdx !== -1) {
        //Process week items
        if (!weekActivities[weekIdx]) {
          weekActivities[weekIdx] = [];
        }

        weekActivities[weekIdx][beginIdx] = weekActivities[weekIdx][beginIdx]
          ? [...weekActivities[weekIdx][beginIdx], a]
          : [a];
      }
    });
  });

  const activitiesByWeek = weeks.map<ActivityMonthColumn>((w, idx) => ({
    headerLabel: `Week ${idx + 1}`,
    items: weekActivities[idx] ?? [],
  }));

  dateRangeMonthActivities.forEach((weekActivities) => {
    weekActivities.sort((a, b) => {
      return diffDateDays(new Date(a.start_date), new Date(a.end_date)) >
        diffDateDays(new Date(b.start_date), new Date(b.end_date))
        ? -1
        : 1;
    });
  });

  return {
    dateRangeWeekActivities,
    dateRangeMonthActivities,
    activitiesByWeek,
    weekArray: weeks,
  };
};
