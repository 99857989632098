import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { Appointment } from 'types/ApiModels/Appointments/Appointment';
import { AppointmentsModalType } from '../Content/components/AppointmentsModal/utils';
import { AppointmentsAction, AppointmentsActionTypes } from './AppointmentActionType';

export interface AppointmentsState {
  selectedDate: Date;
  timePeriodSelected: TimePeriodOptions;
  selectedAppointment: Appointment;
  modalType: AppointmentsModalType;
  appointments: Appointment[];
  previousAppointments: Appointment[];
}

export const appointmentsInitialValue: AppointmentsState = {
  selectedDate: new Date(),
  timePeriodSelected: TimePeriodOptions.WEEK,
  selectedAppointment: null,
  modalType: AppointmentsModalType.CREATE,
  appointments: [],
  previousAppointments: [],
};

const appointmentsReducer = (state: AppointmentsState, action: AppointmentsAction) => {
  switch (action.type) {
    case AppointmentsActionTypes.SET_SELECTED_DATE: {
      const stateCopy = { ...state };
      stateCopy.selectedDate = action.payload.selectedDate;
      return stateCopy;
    }

    case AppointmentsActionTypes.SET_PERIOD_SELECTED: {
      const stateCopy = { ...state };
      stateCopy.timePeriodSelected = action.payload.periodSelected;
      return stateCopy;
    }

    case AppointmentsActionTypes.SET_SELECTED_APPOINTMENT: {
      const stateCopy = { ...state };
      stateCopy.selectedAppointment = action.payload.selectedAppointment;
      return stateCopy;
    }

    case AppointmentsActionTypes.SET_MODAL_TYPE: {
      const stateCopy = { ...state };
      stateCopy.modalType = action.payload.modalType;
      return stateCopy;
    }

    case AppointmentsActionTypes.SET_PREVIOUS_APPOINTMENTS: {
      return { ...state, previousAppointments: state.appointments };
    }
  }
};
export default appointmentsReducer;
