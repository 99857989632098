import { IGoalThresholdUmbral } from 'types/ApiModels/Patients/Goal';

/**
 *
 * @param value Y value of the position of the line
 * @param color Color of the line
 * @param name Id of the line
 * @returns Chart.JS object to draw a horizontal line before dataset values
 */
export const generateLineAnnotation = (value: number, color: string, name: string) => ({
  drawTime: 'beforeDatasetsDraw',
  type: ['line'],
  value: value,
  scaleID: 'y',
  borderColor: color,
  borderWidth: 1,
  annotationID: name,
});

/**
 *
 * @param umbral
 * @param color color of the box
 * @param name Id of the box
 * @returns Chart.JS object to draw a color box before dataset values
 */
export const generateBoxAnnotation = (
  umbral: IGoalThresholdUmbral,
  color: string,
  name: string
) => ({
  drawTime: 'beforeDatasetsDraw',
  display: true,
  type: ['box'],
  xMin: -Infinity,
  xMax: Infinity,
  yMin: umbral ? umbral.min_value : 0,
  yMax: umbral ? umbral.max_value : 0,
  backgroundColor: color,
  borderWidth: 0,
  annotationID: name,
});

/**
 *
 * @param umbral
 * @param unit unit string to append to value
 * @param color color of label
 * @param name id of the label
 * @param timeSpan X position of the label
 * @returns Chart.JS object to draw a label after the dataset values
 */
export const generateLabelAnnotation = (
  umbral: IGoalThresholdUmbral,
  unit: string,
  color: string,
  name: string,
  timeSpan
) => ({
  drawTime: 'afterDatasetsDraw',
  type: ['label'],
  height: 19,
  xValue: timeSpan - timeSpan / 30,
  yValue: (umbral?.min_value + umbral?.max_value) / 2,
  backgroundColor: 'rgba(255,255,255,0.8)',
  borderRadius: 80,
  borderColor: 'transparent',
  content: `${umbral?.min_value} - ${umbral?.max_value} ${unit}`,
  padding: {
    right: 20,
    left: 20,
  },
  font: {
    weight: 500,
  },
  enabled: true,
  color: color,
  annotationID: name,
});
