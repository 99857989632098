import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import styles from './styles.module.css';
const Header = () => {
  return (
    <div className="d-flex justify-content-between w-100">
      <div className={styles['title']}>Forms</div>
      <div className="d-flex gap">
        <SearchBar onChange={() => null} />
        <FiltersButton />
      </div>
    </div>
  );
};
export default Header;
