import { createContext, Dispatch, MutableRefObject, useContext } from 'react';
import { AnalyticsAction } from './AnalyticsActionTypes';
import { PatientEnrollement } from 'types/ApiModels/Analytics/AnalyticsPatientEnrollement';
import { PatientEligibility } from 'types/ApiModels/Analytics/AnalyticsPatientEligibility';
import { Patients } from 'types/ApiModels/Analytics/AnalyticsPatients';
import { ComplianceIssues } from 'types/ApiModels/Analytics/AnalyticsComplianceIssues';
import { Satisfaction } from 'types/ApiModels/Analytics/AnalyticsSatisfaction';
import { NewPatients } from 'types/ApiModels/Analytics/AnalyticsNewPatients';
import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import { DroppedPatients } from 'types/ApiModels/Analytics/AnalyticsDropppedPatients';
import { Devices } from 'types/ApiModels/Analytics/AnalyticsDevices';
import { analyticsInitialBreadCrumbPathList } from '../util';
import { AllAnalyticsActions } from './AnalyticsReducer';
import { PatientAccrued } from 'types/ApiModels/Analytics/AnalyticsPatientAccrued';
import { INavigationState } from 'util/commonContext/NavigationContext';

interface AnalyticsFetchRegistry {
  
  patientEnrollement: {
    list: boolean;
  };
  patientEligibility: {
    list: boolean;
  };
  patients: {
    list: boolean;
  };
  complianceIssues: {
    list: boolean;
  };
  satisfaction: {
    list: boolean;
  };
  newPatients: {
    list: boolean;
  };
  alerts: {
    list: boolean;
  };
  droppedPatients: {
    list: boolean;
  };
  devices: {
    list: boolean;
  };
  patientAccrued: {
    list: boolean;
  },
  
}

export const analyticsFetchRegistryInitialValues: AnalyticsFetchRegistry = {
  
  patientEnrollement: {
    list: false,
  },
  patientEligibility: {
    list: false,
  },
  patients: {
    list: false,
  },
  complianceIssues: {
    list: false,
  },
  satisfaction: {
    list: false,
  },
  newPatients: {
    list: false,
  },
  alerts: {
    list: false,
  },
  droppedPatients: {
    list: false,
  },
  devices: {
    list: false,
  },
  patientAccrued: {
    list: false,
  },
};

export interface AnalyticsState {
  patientEnrollement: PatientEnrollement;
  patientEligibility: PatientEligibility;
  patients: Patients;
  complianceIssues: ComplianceIssues;
  satisfaction: Satisfaction;
  newPatients: NewPatients;
  alerts: Alerts[];
  droppedPatients: DroppedPatients;
  devices: Devices[];
  patientAccrued: PatientAccrued;
  analyticsNavigation: INavigationState;
}

export const analyticsStateInitialValue: AnalyticsState = {
  patientEnrollement: null,
  patientEligibility: null,
  patients: null,
  complianceIssues: null,
  satisfaction: null,
  newPatients: null,
  alerts: null,
  droppedPatients: null,
  devices: null,
  patientAccrued: null,
  analyticsNavigation: { breadcrumbPathList: analyticsInitialBreadCrumbPathList, actions: null }
};

export interface AnalyticsContextState {
  state: AnalyticsState;
  dispatch: Dispatch<AllAnalyticsActions>;
  fetchRegistry: MutableRefObject<AnalyticsFetchRegistry>;
}

export const AnalyticsContext = createContext<AnalyticsContextState>({} as AnalyticsContextState);
export const useAnalyticsContext = () => useContext(AnalyticsContext);

export default AnalyticsContext;
