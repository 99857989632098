import { ReactNode, useReducer } from 'react';
import TemplateContext, { templateStateInitialValue } from './templateContext';
import templateReducer from './templateReducer';

interface TemplateCtxProviderProps {
  children: ReactNode;
}

const TemplateCtxProvider = ({ children }: TemplateCtxProviderProps) => {
  const [templateState, dispatch] = useReducer(templateReducer, templateStateInitialValue);

  return (
    <TemplateContext.Provider
      value={{
        dispatch,
        templateState,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};
export default TemplateCtxProvider;
