export enum RolePermissionOptions {
  view = 'View Only',
  manage = 'View & Manage',
  custom = 'Custom',
  none = 'Hidden',
}

interface RolePermissions {
  id?: string | number;
  name: string;
  DASHBOARD: RolePermissionOptions;
  PATIENTS: RolePermissionOptions;
  'PATIENTS:list': RolePermissionOptions | null;
  'PATIENTS:patient_status': RolePermissionOptions | null;
  'PATIENTS:patient_timeline': RolePermissionOptions | null;
  'PATIENTS:team_notes': RolePermissionOptions | null;
  'PATIENTS:care_plan': RolePermissionOptions | null;
  'PATIENTS:patient_reports': RolePermissionOptions | null;
  'PATIENTS:encounters': RolePermissionOptions | null;
  'PATIENTS:goals_threshold': RolePermissionOptions | null;
  'PATIENTS:questionnaires': RolePermissionOptions | null;
  'PATIENTS:patient_management': RolePermissionOptions | null;
  PATIENT_CASES: RolePermissionOptions;
  SCHEDULE: RolePermissionOptions;
  APPOINTMENTS: RolePermissionOptions;
  MESSAGES: RolePermissionOptions;
  TEMPLATES: RolePermissionOptions;
  'TEMPLATES:care_plan_templates': RolePermissionOptions | null;
  'TEMPLATES:questionnaires': RolePermissionOptions | null;
  'TEMPLATES:surveys': RolePermissionOptions | null;
  REVENUE_MANAGEMENT: RolePermissionOptions | null;
  ADMINISTRATION: RolePermissionOptions;
  'ADMINISTRATION:dashboard': RolePermissionOptions | null;
  'ADMINISTRATION:providers': RolePermissionOptions | null;
  'ADMINISTRATION:roles': RolePermissionOptions | null;
  'ADMINISTRATION:patients': RolePermissionOptions | null;
  'ADMINISTRATION:patient_settings': RolePermissionOptions | null;
  'ADMINISTRATION:forms': RolePermissionOptions | null;
  'ADMINISTRATION:email_settings': RolePermissionOptions | null;
  'ADMINISTRATION:account_settings': RolePermissionOptions | null;
  'ADMINISTRATION:reports': RolePermissionOptions | null;
  'ADMINISTRATION:rules_engine': RolePermissionOptions | null;
  'ADMINISTRATION:messages': RolePermissionOptions | null;
  'ADMINISTRATION:care_plan_templates': RolePermissionOptions | null;
  'ADMINISTRATION:audit_tracking': RolePermissionOptions | null;
  ANALYTICS: RolePermissionOptions;
  RESOURCES: RolePermissionOptions;
  SUPPORT: RolePermissionOptions;
  active: boolean;
}

export const emptyRolePermissions: RolePermissions = {
  name: '',
  DASHBOARD: RolePermissionOptions.none,
  PATIENTS: RolePermissionOptions.none,
  'PATIENTS:list': RolePermissionOptions.none,
  'PATIENTS:patient_status': RolePermissionOptions.none,
  'PATIENTS:patient_timeline': RolePermissionOptions.none,
  'PATIENTS:team_notes': RolePermissionOptions.none,
  'PATIENTS:care_plan': RolePermissionOptions.none,
  'PATIENTS:patient_reports': RolePermissionOptions.none,
  'PATIENTS:encounters': RolePermissionOptions.none,
  'PATIENTS:goals_threshold': RolePermissionOptions.none,
  'PATIENTS:questionnaires': RolePermissionOptions.none,
  'PATIENTS:patient_management': RolePermissionOptions.none,
  PATIENT_CASES: RolePermissionOptions.none,
  SCHEDULE: RolePermissionOptions.none,
  APPOINTMENTS: RolePermissionOptions.none,
  MESSAGES: RolePermissionOptions.none,
  TEMPLATES: RolePermissionOptions.none,
  'TEMPLATES:care_plan_templates': RolePermissionOptions.none,
  'TEMPLATES:questionnaires': RolePermissionOptions.none,
  'TEMPLATES:surveys': RolePermissionOptions.none,
  REVENUE_MANAGEMENT: RolePermissionOptions.none,
  ADMINISTRATION: RolePermissionOptions.none,
  'ADMINISTRATION:dashboard': RolePermissionOptions.none,
  'ADMINISTRATION:providers': RolePermissionOptions.none,
  'ADMINISTRATION:roles': RolePermissionOptions.none,
  'ADMINISTRATION:patients': RolePermissionOptions.none,
  'ADMINISTRATION:patient_settings': RolePermissionOptions.none,
  'ADMINISTRATION:forms': RolePermissionOptions.none,
  'ADMINISTRATION:email_settings': RolePermissionOptions.none,
  'ADMINISTRATION:account_settings': RolePermissionOptions.none,
  'ADMINISTRATION:reports': RolePermissionOptions.none,
  'ADMINISTRATION:rules_engine': RolePermissionOptions.none,
  'ADMINISTRATION:messages': RolePermissionOptions.none,
  'ADMINISTRATION:care_plan_templates': RolePermissionOptions.none,
  'ADMINISTRATION:audit_tracking': RolePermissionOptions.none,
  ANALYTICS: RolePermissionOptions.none,
  RESOURCES: RolePermissionOptions.none,
  SUPPORT: RolePermissionOptions.none,
  active: false,
};

export default RolePermissions;
