import { useEffect } from 'react';
import { Tab as BootstrapTab, Tabs as BootstrapTabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAdministrationContext } from 'screens/Administration/store';
import {
  administrationInitialBreadCrumbPathList,
  AdminTabEnum,
  TabRouteMap,
} from 'screens/Administration/util';
import styles from './styles.module.css';
import AdminActionComponentByTab, {
  selfLoadingActionBlockList,
} from './util/AdminActionComponentByTab';
import AdminTabComponentMap from './util/AdminTabComponentMap';
import AdminTabNameMap from './util/AdminTabNameMap';

const Tabs = ({ selectedTabKey }) => {
  // Context
  const { breadcrumbPathList, setBreadcrumbPathList, setActions, actions } =
    useAdministrationContext();
  const history = useHistory();

  // Functions and subroutines
  const updateBreadcrumbPathList = (key) =>
    setBreadcrumbPathList(
      key !== AdminTabEnum.DASHBOARD
        ? [
            ...administrationInitialBreadCrumbPathList,
            { title: AdminTabNameMap[key], url: TabRouteMap[key] },
          ]
        : administrationInitialBreadCrumbPathList
    );

  const handleSelect = (key) => {
    updateBreadcrumbPathList(key);
    setActions(AdminActionComponentByTab[key]);
    history.push(TabRouteMap[key]);
  };

  const keyIsPartOfTabEnum = (key) => Object.values(AdminTabEnum).includes(key);

  // Effects
  useEffect(() => {
    if (selfLoadingActionBlockList.includes(selectedTabKey)) {
      //the tab will load its actions on its own
      return;
    }
    if (keyIsPartOfTabEnum(selectedTabKey) && !actions) {
      setActions(AdminActionComponentByTab[selectedTabKey]);
    }
  }, [selectedTabKey]);

  useEffect(() => {
    /**
     * This effect was made in order
     * to solve a problem with the breadcrumbs
     * when the user goes back to the dashboard Tab
     * with the buttons in the breadcrumbs instead of
     * the tabs themselves.
     */
    if (selectedTabKey === AdminTabEnum.DASHBOARD) {
      setBreadcrumbPathList(administrationInitialBreadCrumbPathList);
      setActions(AdminActionComponentByTab[selectedTabKey]);
    }
  }, [selectedTabKey]);

  useEffect(() => {
    /**
     * This effect was made in
     * order to update the ui
     * in case the user enters a
     * weird administration URL.
     * For example:
     * baseURL =
     *   "http://localhost:3000" |
     *   "https://vytrac-24106-staging.botics.co/" |
     *   "https://vytrac-24106.botics.co"
     *
     * ${baseURL}/administration/something-weird
     */
    if (!keyIsPartOfTabEnum(selectedTabKey)) {
      history.push(TabRouteMap[AdminTabEnum.DASHBOARD]);
    }
  }, [selectedTabKey]);

  useEffect(() => {
    /**
     * This effect was made in order to
     * update the Breadcrumbs when the
     * user enters one of the tabs
     * with the url instead of the
     * interface.
     */
    if (
      selectedTabKey !== AdminTabEnum.DASHBOARD &&
      keyIsPartOfTabEnum(selectedTabKey) &&
      breadcrumbPathList.length === administrationInitialBreadCrumbPathList.length
    ) {
      updateBreadcrumbPathList(selectedTabKey);
    }
  }, []);

  return (
    <BootstrapTabs
      id="patients-tab"
      activeKey={selectedTabKey}
      onSelect={handleSelect}
      className="tabs"
      mountOnEnter
    >
      {Object.keys(AdminTabComponentMap).map((TabKey) => {
        const Component = AdminTabComponentMap[TabKey];

        return (
          <BootstrapTab
            key={TabKey}
            tabClassName={selectedTabKey === TabKey ? 'tab-selected' : 'tab'}
            eventKey={TabKey}
            title={AdminTabNameMap[TabKey]}
          >
            <div className={styles.tabContainer}>
              <Component />
            </div>
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
};

export default Tabs;
