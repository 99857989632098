import { useEffect, useMemo, useReducer } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ControlButton from '../../../../../../../components/ControlButton';
import { getAlertReport, getPopulationsReport } from '../../../../../../../services/patientService';
import patientReportsReducer, {
  SET_ALERTS,
  SET_POPULATIONS,
  SWITCH_CONTROL,
} from './patientReportsReducer';
import { PatientReportsContext } from './utils/Context/PatientReportsContext';
import { controlsEnum } from './utils/patientReportUtils';

import styles from './styles.module.css';
import { sectionByControl } from './utils/sectionByControl';

const PatientReports = () => {
  const [{ alerts, populations, currentControl }, dispatch] = useReducer(patientReportsReducer, {
    alerts: [],
    populations: [],
    currentControl: controlsEnum.alerts,
  });

  const { table, pieChart } = useMemo(
    () => sectionByControl[currentControl] ?? {},
    [currentControl]
  );

  const fetchAlerts = async () => {
    const alertsResponse = await getAlertReport();
    dispatch({
      type: SET_ALERTS,
      payload: alertsResponse,
    });
  };

  const fetchPopulations = async () => {
    const populationsResponse = await getPopulationsReport();
    dispatch({
      type: SET_POPULATIONS,
      payload: populationsResponse,
    });
  };

  useEffect(() => {
    fetchAlerts();
    fetchPopulations();
  }, []);

  return (
    <PatientReportsContext.Provider value={{ alerts, populations }}>
      <Container className="container-with-padding h-100">
        <Row className="h-100">
          <Col lg={8} className="h-100">
            <Row className="mb-4">
              <ControlButton
                selected={currentControl === controlsEnum.alerts}
                onClick={() => dispatch({ type: SWITCH_CONTROL, payload: controlsEnum.alerts })}
                style={{ marginRight: '10px' }}
              >
                alerts
              </ControlButton>
              <ControlButton
                selected={currentControl === controlsEnum.populations}
                onClick={() =>
                  dispatch({
                    type: SWITCH_CONTROL,
                    payload: controlsEnum.populations,
                  })
                }
              >
                populations
              </ControlButton>
            </Row>
            <Row className="w-100">
              <div className={`${styles['patient-reports-card']} w-100 h-100`}>{table}</div>
            </Row>
          </Col>
          <Col>
            <div className={`${styles['patient-reports-card']}`}>{pieChart}</div>
          </Col>
        </Row>
      </Container>
    </PatientReportsContext.Provider>
  );
};
export default PatientReports;
