import {
  ChangeEvent,
  ClipboardEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useRef,
} from 'react';

interface DigitToken {
  value: string[];
  setValue: Dispatch<SetStateAction<string[]>>;
  length: number;
  onChange: () => void;
  className?: string;
}

const DigitToken: FC<DigitToken> = ({
  value,
  setValue,
  length,
  onChange,
  className,
}: DigitToken) => {
  const tokenRef = useRef<(HTMLInputElement | null)[]>(Array.from(Array(length)));

  const handleKeyDown =
    (handleBackspace: () => void, callerIdx: number) => (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        setValue((t) => {
          const tokenCopy = [...t];
          tokenCopy[callerIdx] = '';
          return tokenCopy;
        });
        handleBackspace();
        onChange();
        return;
      }
      if (e.ctrlKey) {
        return;
      }
      if (isNaN(parseInt(e.key))) {
        e.preventDefault();
        return;
      }
      return;
    };

  const handleInputChange = (idx: number) => (event: ChangeEvent<HTMLInputElement>) => {
    setValue((t) => {
      const tokenCopy = [...t];
      tokenCopy[idx] =
        event.target.value.length > 1
          ? parseInt(event.target.value[event.target.value.length - 1]).toString()
          : parseInt(event.target.value).toString();
      return tokenCopy;
    });
    if (idx !== length - 1) {
      tokenRef.current[idx + 1]?.focus();
      tokenRef.current[idx + 1]?.select();
    }
    onChange();
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const data = e.clipboardData.getData('text');
    if (isNaN(parseInt(data))) return;
    const emptyNumbers = Array.from(Array(length)).fill('');
    setValue(emptyNumbers.map((_, idx) => data[idx] ?? ''));
    onChange();
  };

  return (
    <>
      {Array.from(Array(length).keys()).map((i, idx) => (
        <input
          autoComplete="off"
          key={i}
          value={isNaN(parseInt(value[i])) ? '' : value[i]}
          onChange={handleInputChange(idx)}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown(() => {
            if (idx !== 0) {
              tokenRef.current[idx - 1]?.focus();
              tokenRef.current[idx - 1]?.select();
            }
          }, idx)}
          className={`text-field-token ${className ? className : ''}`}
          style={{ marginLeft: 0 }}
          type="text"
          name={`number-${i}`}
          ref={(el) => (tokenRef.current[idx] = el)}
        />
      ))}
    </>
  );
};
export default DigitToken;
