import Select from 'components/form/Select';
import GenericTable from 'components/GenericTable';
import InputField from 'components/InputField';
import React from 'react';
import { FormCheck } from 'react-bootstrap';
import GenericColumn from 'types/Shared/GenericColumn';
import TriggerEventSelect from './components/TriggerEventSelect';
import { NotificationCol } from './utils/NotificationCol';
import styles from './styles.module.css';

const TableBody = () => {
  const columns: GenericColumn[] = [
    {
      title: 'Trigger Event',
      key: NotificationCol.TRIGGER_EVENT.toString(),
      render: () => <TriggerEventSelect className={styles.triggerEventSelect} />,
    },
    {
      title: 'Time',
      key: NotificationCol.TIME.toString(),
      render: () => <Select />,
    },
    {
      title: 'Reminder text',
      key: NotificationCol.REMINDER_TEXT.toString(),
      render: () => <InputField />,
    },
    {
      title: 'Population',
      key: NotificationCol.REMINDER_TEXT.toString(),
      render: () => <InputField />,
    },
    {
      title: 'Reminder Type',
      key: NotificationCol.REMINDER_TEXT.toString(),
      render: () => <InputField />,
    },
    {
      title: 'Action',
      key: NotificationCol.REMINDER_TEXT.toString(),
      render: () => <InputField />,
      style: { width: '50px' },
    },
    {
      title: 'Status',
      key: NotificationCol.REMINDER_TEXT.toString(),
      render: () => <FormCheck type="switch" checked={true} />,
      style: { width: '50px' },
    },
  ];

  const data = [
    {
      [NotificationCol.TRIGGER_EVENT]: null,
      [NotificationCol.TIME]: null,
      [NotificationCol.REMINDER_TEXT]: null,
      [NotificationCol.POPULATION]: null,
      [NotificationCol.REMINDER_TYPE]: null,
      [NotificationCol.ACTION]: null,
      [NotificationCol.STATUS]: null,
    },
  ];
  return (
    <div>
      <GenericTable columns={columns} data={data} />
    </div>
  );
};
export default TableBody;
