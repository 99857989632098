import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { VytalType } from 'types/ApiModels/Patients/Vytal';

export enum PatientGoalsThresholdsActionTypes {
  UPDATE_THRESHOLD = 'UPDATE_THRESHOLD',
  SET_THRESHOLDS = 'SET_THRESHOLDS',
  SET_THRESHOLDS_SELECTED_GOAL = 'SET_THRESHOLDS_SELECTED_GOAL',
  SET_PREVIOUS_THRESHOLDS = 'SET_PREVIOUS_THRESHOLDS',
  RESET_EDITED_GOALS = 'RESET_EDITED_GOALS',
}

export type PatientGoalsThresholdsAction =
  | {
      type: PatientGoalsThresholdsActionTypes.UPDATE_THRESHOLD;
      payload: {
        goal: VytalType;
        threshold: IGoalThreshold;
      };
    }
  | {
      type: PatientGoalsThresholdsActionTypes.SET_THRESHOLDS;
      payload: {
        thresholds: IGoalThreshold[];
      };
    }
  | {
      type: PatientGoalsThresholdsActionTypes.SET_THRESHOLDS_SELECTED_GOAL;
      payload: {
        goal: VytalType;
      };
    }
  | {
      type: PatientGoalsThresholdsActionTypes.SET_PREVIOUS_THRESHOLDS;
    }
  | {
      type: PatientGoalsThresholdsActionTypes.RESET_EDITED_GOALS;
    };
