import { Ref, useEffect, useRef } from 'react';
import sendDashboardTrackingLog from 'screens/ProviderDashboard/utils/tracking/sendTrackingLog';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import {
  addTrackerTime,
  changeSelectedTimer,
  resetTrackingDuration,
  setTrackingType,
  toggleTimeIsTracking,
} from '../DashboardActionCreators';
import { useDashboardContext } from '../DashboardContext';

const useAlertItemTracking = (itemId: number, itemType: TrackingType, showPopup: boolean) => {
  const mountRef = useRef<boolean>(false);
  const {
    dispatch,
    state: {
      trackingDuration,
      trackingActivityId,
      trackingType,
      trackingPatientId,
      timerIsTracking,
    },
  } = useDashboardContext();

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      return;
    }

    if (showPopup) {
      dispatch(changeSelectedTimer(itemId));
      dispatch(setTrackingType(itemType));
    } else {
      dispatch(toggleTimeIsTracking(false));
      sendDashboardTrackingLog(
        trackingDuration,
        trackingActivityId,
        trackingType,
        trackingPatientId
      );
      dispatch(resetTrackingDuration());
    }
  }, [showPopup]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerIsTracking && showPopup) {
        dispatch(addTrackerTime());
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timerIsTracking, trackingPatientId]);
};

export default useAlertItemTracking;
