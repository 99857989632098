import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { Appointment } from 'types/ApiModels/Appointments/Appointment';
import { AppointmentsModalType } from '../Content/components/AppointmentsModal/utils';
import { AppointmentsAction, AppointmentsActionTypes } from './AppointmentActionType';

export const setSelectedDate = (selectedDate: Date): AppointmentsAction => ({
  type: AppointmentsActionTypes.SET_SELECTED_DATE,
  payload: {
    selectedDate,
  },
});

export const setTimePeriodSelected = (periodSelected: TimePeriodOptions): AppointmentsAction => ({
  type: AppointmentsActionTypes.SET_PERIOD_SELECTED,
  payload: {
    periodSelected,
  },
});

export const setSelectedAppointment = (selectedAppointment: Appointment): AppointmentsAction => ({
  type: AppointmentsActionTypes.SET_SELECTED_APPOINTMENT,
  payload: {
    selectedAppointment,
  },
});

export const setAppointmentsModalType = (modalType: AppointmentsModalType): AppointmentsAction => ({
  type: AppointmentsActionTypes.SET_MODAL_TYPE,
  payload: {
    modalType,
  },
});

export const setAppointments = (appointments: Appointment[]): AppointmentsAction => ({
  type: AppointmentsActionTypes.SET_APPOINTMENTS,
  payload: {
    appointments,
  },
});

export const setPreivousAppointments = (): AppointmentsAction => ({
  type: AppointmentsActionTypes.SET_PREVIOUS_APPOINTMENTS,
});
