import React, { useEffect } from 'react';
import TabEnum from '../../../../util/TabEnum';
import TabRouteMap from '../../../../util/TabRouteMap';
import TabNameMap from '../TemplateTabs/util/TabNameMap';
import DetailNameMap from './utils/DetailNameMap';
import DetailComponentMap from './utils/DetailComponentMap';
import DetailActionComponentMap from './utils/DetailActionComponentMap';
import { templatesInitialBreadCrumbPathList } from '../../../../util/templatesUtils';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { setActions, setBreadcrumbPathList } from 'util/commonContext/NavigationContext';

interface ITemplateDetails {
  templateType: string;
  id?: number;
  create?: boolean;
}

const TemplateDetails = ({ templateType, id, create = false }: ITemplateDetails) => {
  const {
    dispatch,
    templateState: {
      templateNavigation: { breadcrumbPathList },
    },
  } = useTemplateContext();

  const updateBreadcrumbPathList = (key: string) =>
    dispatch(
      setBreadcrumbPathList([
        ...templatesInitialBreadCrumbPathList,
        { title: TabNameMap[key], url: TabRouteMap[key] },
        { title: DetailNameMap[key], url: null },
      ])
    );

  const keyIsPartOfTabEnum = (key: string) => Object.values(TabEnum).includes(key);

  useEffect(() => {
    if (
      keyIsPartOfTabEnum(templateType) &&
      breadcrumbPathList.findIndex(({ title }) => title === DetailNameMap[templateType]) !==
        breadcrumbPathList.length - 1
    ) {
      updateBreadcrumbPathList(templateType);
      const ActionComponent = DetailActionComponentMap[templateType];
      dispatch(setActions(ActionComponent && (() => <ActionComponent />)));
    }
  }, []);

  const DetailComponent = DetailComponentMap[templateType];

  return <DetailComponent id={id} create={create} />;
};

export default TemplateDetails;
