import { Field } from 'formik';
import { CSSProperties, FC, useState } from 'react';
import eyeIcon from 'assets/icons/eye_opened.svg';
import eyeClosed from 'assets/icons/eye_closed.svg';

interface PasswordInputProps {
  name: string;
  invalidStyle: CSSProperties;
}

const PasswordInput: FC<PasswordInputProps> = ({ invalidStyle, name }: PasswordInputProps) => {
  const [showPsw, setShowPsw] = useState(false);

  const handleToggleTextVisible = () => {
    setShowPsw((v) => !v);
  };

  return (
    <div>
      <Field name={name}>
        {({ field }) => (
          <input
            {...field}
            style={invalidStyle}
            className="text-field dynamic-font-normal"
            type={showPsw ? 'text' : 'password'}
            name={name}
          />
        )}
      </Field>
      <img
        className="textfield-button"
        alt=""
        onClick={handleToggleTextVisible}
        src={showPsw ? eyeClosed : eyeIcon}
        style={{ transform: 'scale(2)' }}
      />
    </div>
  );
};
export default PasswordInput;
