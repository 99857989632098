import ActivityTimeline from 'components/ActivityTimeline';
import ActivityTimelineContext from 'components/ActivityTimeline/store';
import { datePickerDefault } from 'components/ArrowDownTodayDatePicker';
import PatientGoalsTopBar from 'components/PatientGoalsTopBar';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import PatientTimelineHeader from './components/PatientTimelineHeader';
import styles from './styles.module.css';
import Card from 'components/Card';
import PatientTimelineChart from './components/PatientTimelineChart';
import { IGoalData } from '../GoalsThreshold/Content/components/GoalChartCard/util';
import PatientTimelineActivityPopup from './components/PatientTimelineActivityPopup';
import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { initialAction } from '../PatientCarePlan/components/PatientCarePlanTimeline/util/initialAction';
import { Activity, ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { mapGoalToLabel } from 'components/PatientGoalsTopBar/utils';
import { getVytals } from 'services/vytalsService';
import { PatientFormikContext } from 'screens/Patients/store/PatientFormikContext';
import { useVytalsQuery } from 'screens/Patients/store/hooks/use-vytals-query';
import { getChartType } from 'util/goalChart';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import { setSelectedDate, setSelectedGoal } from 'screens/Patients/store/PatientTimeline';
import MarkEventModal from './components/MarkEventModal';

const calendarHeight = '400px';

const PatientTimeline = () => {
  const {
    dispatch,
    patientState: {
      patientTimeline: { selectedGoal, selectedDate, periodSelected },
    },
  } = usePatientContext();

  const { selectedPatient } = useContext(PatientFormikContext);
  const { data: goalData } = useVytalsQuery(selectedPatient.id, selectedGoal);
  const [showActivityPopup, setShowActivityPopup] = useState<boolean>(false);
  const [activityClickEvent, setActivityClickEvent] = useState<React.MouseEvent>(null);
  const [selectedPointIdx, setSelectedPointIdx] = useState<number>(null);

  // useEffect(() => {
  //   const fetchPatientTimeline = async (patientId: number) => {
  //     try {
  //       // const patientActivities = await getPatientTimelineActivitiesDEPRECATED(patientId);
  //       // dispatch(setPatientActivities(patientActivities));
  //     } catch (exception) {
  //       throw exception;
  //     }
  //   };

  //   //TODO: replace with patient in context?
  //   const patientId = 1;
  //   fetchPatientTimeline(patientId);
  // }, []);

  const onActivityClick = (event: React.MouseEvent) => {
    setActivityClickEvent(event);
    setShowActivityPopup(true);
  };

  const setTimelineDate = useCallback(
    (value: DatePickerValue) => {
      const newDate = convertDatepickToDate(value);
      dispatch(setSelectedDate(newDate));
    },
    [dispatch]
  );

  const testAction: Activity = {
    type: ActivityType.ACTION,
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString(),
    action_props: { ...initialAction, event_type: 'vitals_request', type: 'sleep' },
  };

  return (
    <div className={styles.patientTimelineContainer}>
      <Card>
        <PatientGoalsTopBar
          selectedGoal={selectedGoal}
          setSelectedGoal={(newGoal) => dispatch(setSelectedGoal(newGoal))}
          disableAllOption
        />
        <div className={styles.patientTimelineChartContainer}>
          <PatientTimelineChart
            dataPoints={goalData}
            selectedGoal={selectedGoal}
            onDataPointClick={(idx: number) => setSelectedPointIdx(idx)}
          />
        </div>
      </Card>
      <PatientTimelineHeader />
      <div style={{ position: 'relative' }}>
        <div style={{ maxHeight: calendarHeight, overflow: 'auto' }}>
          <ActivityTimelineContext.Provider
            value={{
              activities: [testAction],
              currentSelectedDate: convertDateToDatePickValue(selectedDate),
              setCurrentSelectedDate: setTimelineDate,
              clickable: true,
              onActivityClick,
              draggable: false,
            }}
          >
            <ActivityTimeline currentView={periodSelected} style={{ minHeight: calendarHeight }} />
          </ActivityTimelineContext.Provider>
        </div>

        {showActivityPopup && (
          <PatientTimelineActivityPopup
            onClickOutside={() => setShowActivityPopup(false)}
            activityClickEvent={activityClickEvent}
          />
        )}
      </div>
      <MarkEventModal
        show={'number' === typeof selectedPointIdx}
        onConfirm={() => null}
        onClose={() => setSelectedPointIdx(null)}
      />
    </div>
  );
};

export default PatientTimeline;
