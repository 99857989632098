import { ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';

export const providerActionTypeToString: Record<ProviderActionType, string> = {
  complete: 'Complete',
  assign: 'Assign',
  vytal_request: 'Vytal Request',
  provide_questionnaire: 'Provide Questionnaire',
  call_patient: 'Call Patient',
  send_automated_chat: 'Send Automated Chat',
  request_appointment: 'Request Appointment',
};
