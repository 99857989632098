import { useAuthContext } from 'auth';
import VytracLayout from 'components/VyTracLayout';
import { useTwilioToken } from 'hooks';
import { TwilioProvider } from 'store/twilio-client';
import ConversationCtxProvider from 'store/twilio-conversations/conversations-ctx-provider';
import MessagesContent from './messages-content';
import NavigationHeader from './navigation-header';
import MessagesProvider from './store/messages-provider';

const Messages = () => {
  const { currentUser } = useAuthContext();
  const token = useTwilioToken(currentUser?.twilio_identity);
  return (
    <VytracLayout selectedRoute="messages">
      <ConversationCtxProvider>
        <MessagesProvider>
          <NavigationHeader />
          <TwilioProvider token={token}>
            <MessagesContent />
          </TwilioProvider>
        </MessagesProvider>
      </ConversationCtxProvider>
    </VytracLayout>
  );
};
export default Messages;
