import { usePatientFormikContext } from 'screens/Patients/store';
import { pushQuestionnaireTemplateToPatient } from 'services/questionnaireService';
import TableElement from './TableElement.js/index.js';

const AvailableQuestionnairesTable = ({ data, setRefetch }) => {
  const { selectedPatient } = usePatientFormikContext();

  const pushQuestionnaire = async (id, patientId) => {
    try {
      await pushQuestionnaireTemplateToPatient(id, patientId);
      setRefetch((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {data.map((q, index) => (
        <TableElement
          key={index}
          name={q.name}
          population={q.population || ''}
          onClick={() => pushQuestionnaire(q.id, selectedPatient.id)}
        />
      ))}
    </div>
  );
};

export default AvailableQuestionnairesTable;
