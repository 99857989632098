import CollapsibleCard from 'components/CollapsibleCard';
import { useMemo } from 'react';
import useDashboardUrgency from '../store/hooks/useDashboardUrgency';
import Header from './Header';
import PatientTable from './PatientTable';
import { mapUrgencyToList } from './utils';
import styles from './styles.module.css';

interface HighestUrgencyProps {
  collapsed: boolean;
}

const HighestUrgency = ({ collapsed }: HighestUrgencyProps) => {
  const [{ book: urgency, currentPage }] = useDashboardUrgency();

  const urgencyCurrentPage = useMemo(() => {
    return urgency?.[currentPage];
  }, [currentPage, urgency]);

  const urgencyMappedToList = useMemo(
    () => urgencyCurrentPage?.results?.map(mapUrgencyToList) ?? [],
    [urgencyCurrentPage]
  );

  return (
    <CollapsibleCard
      outerCollapse={collapsed}
      headers={[
        <Header key="highest-urgency-header" patientListLength={urgencyMappedToList.length} />,
      ]}
      className="bg-white"
      headerClassName="p-0"
    >
      <div className={styles.body} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          <PatientTable patientList={urgencyMappedToList} />
        </div>
      </div>
    </CollapsibleCard>
  );
};
export default HighestUrgency;
