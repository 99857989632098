import React from 'react';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import Modal from '../../Modal';
import { mapActionEventTypeToLabel } from '../utils/eventTypeMapper';
import ActionModalBody from './components/ActionModalBody';
import ActionModalFooter from './components/ActionModalFooter';

export type ActionModalType = 'patient' | 'template';

interface ICarePlanActionModal {
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  show: boolean;
  eventType: CarePlanActionEventType;
  modalData: IPatientCarePlanAction;
  setModalData: (updatedSelectedAction: IPatientTimelineAction) => void;
  modalType?: ActionModalType;
  relativeMinDate?: Date;
  patientId?: number;
}
const CarePlanActionModal = ({
  onClose,
  onConfirm,
  onCancel,
  show,
  eventType = null,
  modalData,
  setModalData,
  modalType = 'patient',
  relativeMinDate,
  patientId = null,
}: ICarePlanActionModal) => {
  return (
    <>
      {modalData && (
        <Modal
          title={mapActionEventTypeToLabel(eventType)}
          body={
            <ActionModalBody
              data={modalData}
              setData={setModalData}
              modalType={modalType}
              relativeMinDate={relativeMinDate}
              patientId={patientId}
            />
          }
          footer={<ActionModalFooter onConfirm={onConfirm} onCancel={onCancel} />}
          onClose={onClose}
          show={show}
          centered
        />
      )}
    </>
  );
};
export default CarePlanActionModal;
