import { createContext, Dispatch, useContext } from 'react';
import { getInitialNavigationState } from 'util/commonContext/NavigationContext/getInitialNavigationState';
import { INavigationState } from 'util/commonContext/NavigationContext/NavigationReducer';
import { templatesInitialBreadCrumbPathList } from '../util/templatesUtils';
import {
  ITemplateCarePlanState,
  templateCarePlanInitialState,
} from './templateCarePlan/templateCarePlanReducer';
import { AllTemplateActions } from './templateReducer';

export interface TemplateState {
  templateNavigation: INavigationState;
  templateCarePlan: ITemplateCarePlanState;
  // questionnaires
  // surveys
}

export const templateStateInitialValue: TemplateState = {
  templateNavigation: getInitialNavigationState(templatesInitialBreadCrumbPathList),
  templateCarePlan: templateCarePlanInitialState,
};

interface TemplateContextState {
  templateState: TemplateState;
  dispatch: Dispatch<AllTemplateActions>;
}

const TemplateContext = createContext<TemplateContextState>({} as TemplateContextState);

export const useTemplateContext = () => useContext(TemplateContext);

export default TemplateContext;
