import { TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { IAccount } from 'types/Administration/Account/Account';
import styles from './styles.module.css';

const LastColumn = () => {
  const formik = useFormikContext<IAccount>();
  const [selectedLogo, setSelectedLogo] = useState('');
  const [selectedPracticeIcon, setSelectedPracticeIcon] = useState('');

  const handleChangePractice = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    formik.setFieldValue('brandPracticeIcon', e.target.value);
    setSelectedPracticeIcon(url);
  };

  const handleChangeScheme = (e) => {
    const objValue = JSON.parse(e.target.value);
    formik.handleChange;
    formik.setFieldValue('brandColorScheme', objValue);
  };

  const handleChangeImage = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setSelectedLogo(url);
  };

  return (
    <div className={styles.container}>
      <h6>Platform branding</h6>
      <hr className={styles.horizontalLine}></hr>
      <div className="mt-3">
        <div className={styles.inputTitle}>Name</div>
        <div className={styles.inputContainer}>
          <TextInputFormik className={styles.input} name="brandName" />
          <ErrorMessage name="brandName">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="mt-3">
        <div className={styles.inputTitle}>Logo</div>
        <div className={styles.imgUploaderContainer}>
          <Field name="brandLogo">
            {({ field }) => (
              <input
                {...field}
                className={styles.imgUploader}
                id="img"
                name="img"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleChangeImage(e), formik.setFieldValue('brandLogo', e.target.value);
                }}
              />
            )}
          </Field>
          {selectedLogo && <img src={selectedLogo} className={styles.logo} alt="img" />}
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <span className={styles.span}>Practice Icon</span>
        <div className={styles.practiceIconContainer}>
          <Field name="brandPracticeIcon">
            {({ field }) => (
              <input
                {...field}
                className={styles.practiceIcon}
                placeholder="change"
                id="img"
                name="img"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleChangePractice(e);
                }}
              />
            )}
          </Field>
          <span className={styles.changeSpan}>change</span>
          {selectedPracticeIcon && (
            <img src={selectedPracticeIcon} className={styles.practiceIconImg} alt="img" />
          )}
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <span className={styles.span}>Color Scheme</span>
        <Field
          name="colorScheme"
          render={({ field }) => (
            <>
              <div className="d-flex justify-content-end">
                <div className={styles.containerRadio}>
                  <input
                    {...field}
                    id="black_teal"
                    value="1"
                    defaultChecked
                    checked={field.value == 'black_teal' ? true : false}
                    onChange={(e) => {
                      handleChangeScheme(e);
                    }}
                    name="black_teal"
                    type="radio"
                  />
                  <span className={styles.checkmarkGreen}></span>
                </div>
                <div className={styles.containerRadio}>
                  <input
                    {...field}
                    id="orange_green"
                    value="2"
                    name="orange_green"
                    checked={field.value == 'orange_green' ? true : false}
                    onChange={(e) => {
                      handleChangeScheme(e);
                    }}
                    type="radio"
                  />
                  <span className={styles.checkmarkOrange}></span>
                </div>
                <div className={styles.containerRadio}>
                  <input
                    {...field}
                    id="yellow_blue"
                    value="3"
                    name="yellow_blue"
                    checked={field.value == 'yellow_blue' ? true : false}
                    onChange={(e) => {
                      handleChangeScheme(e);
                    }}
                    type="radio"
                  />
                  <span className={styles.checkmarkYellow}></span>
                </div>
              </div>
            </>
          )}
        />
      </div>
      <div className="mt-3">
        <div className={styles.inputTitle}>Rewards Store Feed</div>
        <div className={styles.inputContainer}>
          <TextInputFormik className={styles.input} name="brandRewardsStoreFeed" />
          <ErrorMessage name="brandRewardsStoreFeed">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="mt-3">
        <div className={styles.inputTitle}>Rewards Redeem Link</div>
        <div className={styles.inputContainer}>
          <TextInputFormik className={styles.input} name="brandRewardsRedeemLink" />
          <ErrorMessage name="brandRewardsRedeemLink">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
    </div>
  );
};

export default LastColumn;
