import AlertItemSvg from 'components/AlertItemSvg';
import PatientProfileRing from 'components/patient/PatientProfileRing/PatientProfileRing';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { Alert } from 'types/PatientCases';
import { get12hFormatObject, timeAgo } from 'util/dateUtils';
import { colorBySeverity } from 'util/iconUtils';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import { getUserDisplayName } from 'util/userUtils';
import styles from './styles.module.css';
interface HeaderProps {
  patient: PatientGetSingle;
  alert: Alert;
}
const Header = ({ patient, alert }: HeaderProps) => {
  const hours = get12hFormatObject(new Date(alert.created_at).getHours());
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <div className="mr-2">
          <PatientProfileRing currentPatient={patient} size={35} />
        </div>
        <div className={styles.text}>{getUserDisplayName(patient.user)}</div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mr-1">
          <AlertItemSvg
            SvgComponent={rpmActionTypeToIcon[alert.icon_type]}
            color={colorBySeverity[alert.type]}
          />
        </div>
        <div>
          <div className="text-capitalize">{alert.event_type}</div>
          <div className="d-flex">
            <div className="text-danger">{timeAgo(new Date(alert.created_at))}</div>&nbsp;•&nbsp;
            <div>
              {hours.hour12Format}:{new Date(alert.created_at).getMinutes()} {hours.ampm}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
