import React, { useReducer, useRef } from 'react';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import {
  DashboardContext,
  dashboardFetchRegistryInitialValues,
  DashboardState,
} from './DashboardContext';
import dashboardReducer from './DashboardReducer';

const initialValue: DashboardState = {
  patientIdTimeMap: new Map<number, number>(),
  timerIsTracking: false,
  trackingPatientId: null,
  trackingActivityId: null,
  trackingDuration: 0,
  trackingType: TrackingType.PATIENT_TODO,
  toDoItems: null,
  toDoItemsSingle: null,
  redAlerts: null,
  selectedRedAlert: null,
  yellowAlerts: null,
  outstandingChats: null,
  adherence: null,
  adherenceGraphic: null,
  urgency: null,
  escalated: null,
  schedule: null,
  stickynotes: null,
  selectedYellowAlert: null,
  selectedAdherence: null,
  vytalsChart: null,
  totalPatients: 0,
  showActionModal: false,
  actionModalType: null,
  openedAlertId: null,
  questionnaireTemplates: null,
  patientQuestionnaires: {},
  currentToDo: null,
  currentProviderCareTeam: null,
};

const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dashboardReducer, initialValue);
  const fetchRegistry = useRef(dashboardFetchRegistryInitialValues);

  return (
    <DashboardContext.Provider value={{ state, dispatch, fetchRegistry }}>
      {children}
    </DashboardContext.Provider>
  );
};
export default DashboardProvider;
