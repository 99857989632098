import React, { HTMLProps } from 'react';
import activityItemStyles from './../ActivityItems.module.css';
import { AppointmentType } from 'types/ApiModels/Appointments/Appointment';
import { getComsIcon } from 'components/ActivityTimeline/utils';

export const appointmentContentProps = (): HTMLProps<HTMLDivElement> => ({
  className: `d-flex flex-column rounded-left px-2 py-2 font-size-medium ${activityItemStyles['activity-container']}`,
});

interface IAppointmentContent {
  dateAMPM: string;
  label: string;
  type: AppointmentType;
}
const AppointmentContent = ({ dateAMPM, label, type }: IAppointmentContent) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className={activityItemStyles['hour-label']}>{dateAMPM}</div>
        {getComsIcon(type)}
      </div>
      <div className={activityItemStyles['main-label']}>{label}</div>
    </>
  );
};
export default AppointmentContent;
