import { CareTeam, CareTeamCreateUpdateRequest } from 'types/ApiModels/CareTeam';
import { PaginatedResponse } from 'types/ApiModels/General';
import { deleteFromBook, findInBook } from 'util/bookUtils';
import { CareTeamAction, CareTeamActionTypes } from './careTeamActionTypes';

export interface CareTeamState {
  careTeamsBook: Record<number, PaginatedResponse<CareTeam>>;
  careTeamsBookFromTable: Record<number, PaginatedResponse<CareTeam>>;
  resetCareTeamsForm: () => void;
  submitCareTeamsForm: () => void;
  deletedCareTeams: number[];
  updatedCareTeams: CareTeamCreateUpdateRequest[];
  createdCareTeams: CareTeamCreateUpdateRequest[];
  showAddCareTeamModal: boolean;
  currentCareTeamId: number;
  currentBookPage: number;
  createCareTeam: () => void;
}

export const careTeamsInitialState: CareTeamState = {
  careTeamsBook: null,
  resetCareTeamsForm: () => undefined,
  submitCareTeamsForm: () => undefined,
  createCareTeam: () => undefined,
  deletedCareTeams: [],
  updatedCareTeams: [],
  createdCareTeams: [],
  currentCareTeamId: null,
  showAddCareTeamModal: false,
  careTeamsBookFromTable: {},
  currentBookPage: 1,
};

const careTeamReducer = (state: CareTeamState, action: CareTeamAction): CareTeamState => {
  switch (action.type) {
    case CareTeamActionTypes.SET_CARE_TEAMS_BOOK: {
      return { ...state, careTeamsBook: action.payload.careTeamBook };
    }
    case CareTeamActionTypes.SET_CURRENT_CARE_TEAM: {
      return { ...state, currentCareTeamId: action.payload.careTeamId };
    }
    case CareTeamActionTypes.UPDATE_CARE_TEAM_DELETE: {
      //update book
      const { careTeamId, page } = action.payload;
      const careTeamsBookFromTable = deleteFromBook(state.careTeamsBookFromTable, page, careTeamId);
      //update deleted
      const deletedCareTeams = [...state.deletedCareTeams, careTeamId];
      return { ...state, careTeamsBookFromTable, deletedCareTeams };
    }
    case CareTeamActionTypes.SET_CARE_TEAMS_BOOK_AND_MATCH_TABLE: {
      const { careTeamsBook } = action.payload;
      if (!careTeamsBook) return state;
      const bookKeys = Object.keys(careTeamsBook);
      if (bookKeys.length !== Object.keys(state.careTeamsBookFromTable).length) {
        const lastPageAdded = bookKeys.reduce((res, key) => {
          const keyAsNumber = parseInt(key);
          const safeKeyAsNumber = isNaN(keyAsNumber) ? 0 : keyAsNumber;
          return res > safeKeyAsNumber ? res : safeKeyAsNumber;
        }, 0);

        return {
          ...state,
          careTeamsBook,
          careTeamsBookFromTable: {
            ...state.careTeamsBookFromTable,
            [lastPageAdded]: careTeamsBook[lastPageAdded],
          },
        };
      }
      return { ...state, careTeamsBook };
    }
    case CareTeamActionTypes.RESET_CARE_TEAM_FORM: {
      return {
        ...state,
        careTeamsBookFromTable: JSON.parse(JSON.stringify(state.careTeamsBook)),
        deletedCareTeams: [],
        updatedCareTeams: [],
        createdCareTeams: [],
      };
    }
    case CareTeamActionTypes.SET_CARE_TEAMS_SUBMIT_FORM: {
      return {
        ...state,
        submitCareTeamsForm: action.payload.submitForm,
      };
    }
    case CareTeamActionTypes.RESET_DELETED_CARE_TEAMS: {
      return { ...state, deletedCareTeams: [] };
    }
    case CareTeamActionTypes.RESET_UPDATED_CARE_TEAMS: {
      return { ...state, updatedCareTeams: [] };
    }
    case CareTeamActionTypes.SET_CREATE_CARE_TEAM: {
      return { ...state, createCareTeam: action.payload.createCareTeam };
    }
    case CareTeamActionTypes.APPEND_CREATED_CARE_TEAM: {
      const newCareTeamEntry: CareTeam = {
        ...action.payload.careTeam,
        patients_count: 0,
        providers_count: action.payload.careTeam.tiers.reduce((res, t) => {
          res += t.providers.length;
          return res;
        }, 0),
      };
      //TODO: improve this with variables
      return {
        ...state,
        careTeamsBookFromTable: {
          ...state.careTeamsBookFromTable,
          [state.currentBookPage]: {
            ...state.careTeamsBookFromTable[state.currentBookPage],
            results: [
              ...state.careTeamsBookFromTable[state.currentBookPage].results,
              newCareTeamEntry,
            ],
          },
        },
        createdCareTeams: [...state.createdCareTeams, action.payload.careTeam],
      };
    }
    case CareTeamActionTypes.REMOVE_CREATED_CARE_TEAM: {
      const [page] = findInBook(state.careTeamsBookFromTable, action.payload.localId);
      const bookCopy = { ...state.careTeamsBookFromTable };
      const pageCopy = { ...bookCopy[page] };
      pageCopy.results = pageCopy.results.filter((r) => r.id !== action.payload.localId);
      bookCopy[page] = pageCopy;

      return { ...state, careTeamsBookFromTable: bookCopy };
    }
    case CareTeamActionTypes.APPEND_UPDATED_CARE_TEAM: {
      const [page, result, idx] = findInBook(
        state.careTeamsBookFromTable,
        action.payload.updatedCareTeam.id
      );
      const newCareTeamEntry: CareTeam = {
        ...action.payload.updatedCareTeam,
        patients_count: result.patients_count,
        providers_count: action.payload.updatedCareTeam.tiers.reduce((res, t) => {
          res += t.providers.length;
          return res;
        }, 0),
      };
      const bookCopy = { ...state.careTeamsBookFromTable };
      const pageCopy = { ...bookCopy[page] };
      const resultsCopy = [...pageCopy.results];
      resultsCopy[idx] = newCareTeamEntry;
      pageCopy.results = resultsCopy;
      bookCopy[page] = pageCopy;

      //check if we have an entry already, if so we edit it rather than append it
      const updatedCopy = [...state.updatedCareTeams];
      const updatedFoundIdx = updatedCopy.findIndex(
        (u) => u.id === action.payload.updatedCareTeam.id
      );
      if (updatedFoundIdx !== -1) {
        updatedCopy[updatedFoundIdx] = action.payload.updatedCareTeam;
      } else {
        updatedCopy.push(action.payload.updatedCareTeam);
      }

      return {
        ...state,
        careTeamsBookFromTable: bookCopy,
        updatedCareTeams: updatedCopy,
      };
    }
    case CareTeamActionTypes.RESET_UPDATE_CREATED_CARE_TEAMS: {
      //update all created so that they have their new ids
      const { updatedCareTeams } = action.payload;
      const toUpdateIds = state.createdCareTeams.map((cct) => cct.id);
      const bookCopy = { ...state.careTeamsBookFromTable };
      const pageCopy = { ...bookCopy[state.currentBookPage] };
      const resultsCopy = [...pageCopy.results];
      toUpdateIds.forEach((id, idx) => {
        const foundIdx = resultsCopy.findIndex((r) => r.id === id);
        resultsCopy[foundIdx] = updatedCareTeams[idx];
      });
      pageCopy.results = resultsCopy;
      bookCopy[state.currentBookPage] = pageCopy;

      return { ...state, careTeamsBookFromTable: bookCopy, createdCareTeams: [] };
    }
    //--AppendCase
    default:
      return state;
  }
};

export default careTeamReducer;
