import React, { CSSProperties } from 'react';
import { SexLabelId } from 'types/ApiModels/Users/Sex';
import DefaultImage from 'components/DefaultImage';
import styles from './styles.module.css';

interface IProfileImage {
  src?: string;
  sex?: SexLabelId;
  style?: CSSProperties;
  className?: string;
}
const ProfileImage = ({ src, sex, style, className }: IProfileImage) => (
  <div className={styles.container}>
    {src ? (
      <img style={style} src={src} alt="" className={className} />
    ) : (
      <DefaultImage sex={sex} style={style} />
    )}
  </div>
);

export default ProfileImage;
