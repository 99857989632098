import ArrowDownTodayDatePicker from 'components/ArrowDownTodayDatePicker';
import CompareBarGraph from 'components/CompareBarGraph/CompareBarGraph';
import DateRangePicker from 'components/DateRangePicker';
import FiltersButton from 'components/FiltersButton';
import Tags from 'components/Tags';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styles from '../styles.module.css';

const PatientEligibility = () => {
  const [date, setDate] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  });
  const [dataToModalCompare, setDataToModalCompare] = useState({ labels: [], datasets: [] });
  const [showModal, setShowModal] = useState<boolean>(false);

  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const labels = ['99453', '99454', '99458', '99457'];

  const getRandomInt = (minRange, maxRange) => {
    const min = Math.ceil(minRange);
    const max = Math.floor(maxRange);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const data = {
    labels,
    datasets: [
      {
        data: labels.map(() => getRandomInt(0, 10000)),
        borderColor: '#CFD6E2',
        backgroundColor: '#CFD6E2',
      },
    ],
  };

  const fetchDataforCompare = async (mounths: string[]) => {};

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>Patient Eligibility per CPT Code</div>
        <div className={styles.controls}>
          <DateRangePicker
            currentDateRange={date}
            handleDateRangePickerChange={(range) => setDate(range)}
            calendarClassName={styles.calendar}
            wrapperClassName={styles.datePickerWrapper}
            inputClassName={styles.datePicker}
          />
          <i onClick={() => setShowModal(!showModal)} className={styles.expandArrow}></i>
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      <div className={styles.body}>
        <div className="mt-2">
          <Tags tagList={['Category', 'Category']} />
        </div>
        <Bar options={options} data={data} />
      </div>
      {/* <CompareBarGraph
        title="Compare Patient Eligibility"
        handleChange={(months) => {
          fetchDataforCompare(months);
        }}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        options={options}
        data={dataToModalCompare}
      ></CompareBarGraph> */}
    </div>
  );
};

export default PatientEligibility;
