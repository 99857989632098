import { FC } from 'react';

interface ProvidersCellProps {
  count: number;
  handleProviderCountClick: () => void;
}

const ProvidersCell: FC<ProvidersCellProps> = ({
  count,
  handleProviderCountClick,
}: ProvidersCellProps) => {
  return (
    <button className="link" onClick={handleProviderCountClick}>
      {count}
    </button>
  );
};
export default ProvidersCell;
