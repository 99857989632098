import TabEnum from '../../../../../util/TabEnum';
import Questionnaires from '../components/Questionnaires';
import CarePlanTemplates from '../components/CarePlan';
import Surveys from '../components/Surveys';

const TabComponentMap = {
  [TabEnum.CAREPLAN_TEMPLATES]: CarePlanTemplates,
  [TabEnum.QUESTIONNAIRES]: Questionnaires,
  //[TabEnum.SURVEYS]: Surveys,
};

export default TabComponentMap;
