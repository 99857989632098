import { FC } from 'react';
import { CarePlanActionType } from 'types/ApiModels/CarePlan/ActionType';
import SVGIconProps from 'types/Shared/SVGIconProps';
import BloodPressureIcon from '../BloodPressureIcon';
import BloodSugarIcon from '../BloodSugarIcon';
import HeartIcon from '../HeartIcon';
import HospitalizationIcon from '../HospitalizationIcon';
import MedicationIcon from '../MedicationIcon';
import OxygenLevelIcon from '../OxygenLevelIcon';
import QuestionnaireIcon from '../QuestionnaireIcon';
import SleepIcon from '../SleepIcon';
import StairsIcon from '../StairsIcon';
import StepsIcon from '../StepsIcon';
import StressLevelIcon from '../StressLevelIcon';
import TemperatureIcon from '../TemperatureIcon';
import WeightIcon from '../WeightIcon';
import { AlertTypeOptions } from './AlertOptions';

export const mapAlertTypeToIcon: (alertType: AlertTypeOptions) => FC<SVGIconProps> | null = (
  alertType
) => {
  switch (alertType) {
    case AlertTypeOptions.BLOOD_PRESSURE:
      return BloodPressureIcon;
    case AlertTypeOptions.BLOOD_SUGAR:
      return BloodSugarIcon;
    case AlertTypeOptions.HEART_RATE:
      return HeartIcon;
    case AlertTypeOptions.HOSPITALIZATION:
      return HospitalizationIcon;
    case AlertTypeOptions.MEDICATION:
      return MedicationIcon;
    case AlertTypeOptions.OXYGEN_LEVEL:
      return OxygenLevelIcon;
    case AlertTypeOptions.QUESTIONNAIRE:
      return QuestionnaireIcon;
    case AlertTypeOptions.SLEEP:
      return SleepIcon;
    case AlertTypeOptions.STEPS:
      return StepsIcon;
    case AlertTypeOptions.STRESS_LEVEL:
      return StressLevelIcon;
    case AlertTypeOptions.TEMPERATURE:
      return TemperatureIcon;
    case AlertTypeOptions.WEIGHT:
      return WeightIcon;

    default:
      null;
  }
};

export const mapCarePlanActionTypeToIcon: Record<CarePlanActionType, FC<SVGIconProps>> = {
  blood_pressure: BloodPressureIcon,
  blood_sugar: BloodSugarIcon,
  bpm: HeartIcon,
  medication: MedicationIcon,
  oxygen_level: OxygenLevelIcon,
  questionnaire: QuestionnaireIcon,
  sleep: SleepIcon,
  stairs: StairsIcon,
  steps: StepsIcon,
  stress_level: StressLevelIcon,
  temperature: TemperatureIcon,
  weight: WeightIcon,

  upload_file: null,
  select_from_patient_documents: null,
  check_up: null,
};
