import {
  PatientSignup,
  ProviderSignup,
  RoleSignup,
} from 'types/ApiModels/Administration/Dashboard';
import { DashboardAction, DashboardActionTypes } from './dashboardActionTypes';

export interface AdminDashboardState {
  providerSignups: ProviderSignup[];
  rolesAdded: RoleSignup[];
  patientSignups: PatientSignup[];
  //TODO:
  providerActivityPoints: any[];
  patientActivityPoints: any[];
}

export const dashboardInitialState = {
  providerSignups: null,
  rolesAdded: null,
  patientSignups: null,
  providerActivityPoints: [],
  patientActivityPoints: [],
};

const adminDashboardReducer = (
  state: AdminDashboardState,
  action: DashboardAction
): AdminDashboardState => {
  switch (action.type) {
    case DashboardActionTypes.SET_PROVIDER_SIGNUPS: {
      return { ...state, providerSignups: action.payload.providerSignups };
    }
    case DashboardActionTypes.SET_PATIENT_SIGNUPS: {
      return { ...state, patientSignups: action.payload.patientSignups };
    }
    case DashboardActionTypes.SET_ROLES_ADDED: {
      return { ...state, rolesAdded: action.payload.rolesAdded };
    }
    case DashboardActionTypes.SET_PATIENT_ACTIVITY_POINTS: {
      console.error('TODO');
      return state;
    }
    case DashboardActionTypes.SET_PROVIDER_ACTIVITY_POINTS: {
      console.error('TODO');
      return state;
    }
    default:
      return state;
  }
};

export default adminDashboardReducer;
