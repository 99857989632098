import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';

export enum ActionEventTypeLabel {
  SHEDULE_CALL = 'Schedule Call',
  PUSH_FILE = 'Push File',
  VITALS_REQUEST = 'Vitals Request',
  MEDICATIONS = 'Medication',
  QUESTIONNAIRE = 'Questionnaire',
  GOAL = 'Goal',
}

export const mapLabelToActionEventType: (label: ActionEventTypeLabel) => CarePlanActionEventType = (
  label
) => {
  switch (label) {
    case ActionEventTypeLabel.VITALS_REQUEST:
      return 'vitals_request';
    case ActionEventTypeLabel.SHEDULE_CALL:
      return 'schedule_call';
    case ActionEventTypeLabel.QUESTIONNAIRE:
      return 'questionnaire';
    case ActionEventTypeLabel.GOAL:
      return 'goal';
    case ActionEventTypeLabel.PUSH_FILE:
      return 'push_file';
    case ActionEventTypeLabel.MEDICATIONS:
      return 'medication';
    default:
      return null;
  }
};

export const mapActionEventTypeToLabel: (eventType: CarePlanActionEventType) => string = (
  eventType
) => {
  switch (eventType) {
    case 'vitals_request':
      return ActionEventTypeLabel.VITALS_REQUEST;
    case 'schedule_call':
      return ActionEventTypeLabel.SHEDULE_CALL;
    case 'questionnaire':
      return ActionEventTypeLabel.QUESTIONNAIRE;
    case 'goal':
      return ActionEventTypeLabel.GOAL;
    case 'push_file':
      return ActionEventTypeLabel.PUSH_FILE;
    case 'medication':
      return ActionEventTypeLabel.MEDICATIONS;
    default:
      return 'Add Care Plan Action';
  }
};
