import AddButton from 'components/AddButton';
import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import styles from './styles.module.css';
import {ReactComponent as FolderIcon} from '../../../../../../assets/icons/folder.svg';
import { useState } from 'react';
import InputField from 'components/InputField';
import VyTracModal from 'components/Modal/VytracModal';
import Button from 'components/Button';
import { IResourceCategory } from 'types/Resources/Resources';

interface IFirstColumn {
  categories : IResourceCategory[]
  onCreateCategory: (category: IResourceCategory) => void
}

const FirstColumn = ({categories, onCreateCategory}: IFirstColumn)  => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState<string>('');

  const handleClose = () => {
    setCategoryName('')
    setShowModal(false);
  };

  const handleCreateCategory = async ()  => {
    const newCategory: IResourceCategory = { name: categoryName}
    setShowModal(false);
    onCreateCategory(newCategory);
  };
  
  return (
    <div>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.title}>Categories</div>
          <div className={styles.controls}>
            <AddButton onClick={() => setShowModal(true)} />
            <FiltersButton />
          </div>
        </div>
        <hr className={styles.horizontalLine} />
        <div className={styles.body}>
          <div className="mx-3">
            <SearchBar onChange={() => {}} placeholder="Search Category" />
          </div>
          <div>
            { categories?.map((item, index) => (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.item}>
                    <FolderIcon className='mr-2' fill="#8FAAC3" />
                    {item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <VyTracModal
      show={showModal}
      title="Add new Category"
      onClose={handleClose}
      body={
        <div className="d-flex flex-column gap">
          <div className={styles.rowContainer}>
            <div className={styles.title}> Name</div>
            <div className={styles.content}>
              <InputField
                onChange={(e) => setCategoryName(e.target.value)}
                value={categoryName}
                className={styles.input}
              />
            </div>
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={handleClose} />
          <Button label="save" onClick={handleCreateCategory} />
        </div>
      }
      ></VyTracModal>
    </div>
  );
};

export default FirstColumn;
