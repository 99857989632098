import Button from 'components/Button';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { IModalBody } from '../../ActionModalBody';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';

const ProvideQuestionnaireBody = ({ onCancel }: IModalBody) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const { handleSubmit, errors, values, setFieldValue } = useFormikContext();

  const Step = useCallback(() => {
    switch (currentStep) {
      case 0:
        return <Step1 currentTab={currentTab} setCurrentTab={setCurrentTab} />;
      case 1:
        return <Step2 />;
    }
  }, [currentStep, currentTab]);

  const disableNext = useMemo(() => {
    const errorsStep1 = _.omit(errors, ['note', 'patient_note']);
    return !_.isEmpty(errorsStep1) || _.isEmpty(values) || values['questionnaire_id'] === null;
  }, [errors, values]);

  const handleNext = useCallback(() => {
    if (currentStep === 1) {
      handleSubmit();
    } else if (!disableNext) {
      setCurrentStep(currentStep + 1);
      setFieldValue('tab', currentTab);
    }
  }, [currentStep, handleSubmit, disableNext, setFieldValue, currentTab]);

  const handleBack = useCallback(() => {
    if (currentStep === 0) {
      onCancel();
    } else {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep, onCancel]);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <Step />
      <div className="d-flex justify-content-between w-100 pt-0">
        <Button
          label={currentStep === 0 ? 'Cancel' : 'Back'}
          onClick={handleBack}
          variant="cancel"
          type="button"
        />
        <Button
          label={currentStep === 1 ? 'Confirm' : 'Next'}
          onClick={handleNext}
          variant="confirm"
          type="button"
          disabled={disableNext}
        />
      </div>
    </div>
  );
};
export default ProvideQuestionnaireBody;
