import FiltersButton from 'components/FiltersButton';

const CarePlanBreakdownHeader = () => (
  <div className="d-flex justify-content-between dashboard-item-header" style={{ height: '50px' }}>
    <span className="d-flex align-items-center">Care plan breakdown</span>
    <div className="d-flex align-items-center">
      <FiltersButton />
    </div>
  </div>
);

export default CarePlanBreakdownHeader;
