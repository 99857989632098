import GenericColumn from 'types/Shared/GenericColumn';

export const assignedPatientsColumns: GenericColumn[] = [
  {
    title: 'score',
    key: 'score',
  },
  {
    title: 'patient',
    key: 'patient',
  },
  {
    title: 'patientId',
    key: 'patientId',
  },
  {
    title: 'dateOfBirth',
    key: 'dateOfBirth',
  },
  {
    title: 'alerts',
    key: 'alerts',
  },
  {
    title: 'tags',
    key: 'tags',
  },
  {
    title: 'complaint',
    key: 'complaint',
  },
];
