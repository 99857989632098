import React from 'react';

import sampleMp3 from './file_example_MP3_700KB.mp3';

import UserBubble from 'components/UserBubble';
import moment from 'moment';
import AudioWaves from './components/AudioWaves';
export default function AudioMessage({ message, className, from, timeStampClassName }) {
  return (
    <div className={className} style={{ flexGrow: 1 }}>
      <div>
        <UserBubble user={from} />
      </div>
      <div className="d-flex align-items-center flex-grow-1">
        <AudioWaves audio={sampleMp3} />
        <span className={timeStampClassName}>{moment(message.createdAt).format('h:mm a')}</span>
      </div>
    </div>
  );
}
