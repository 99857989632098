import { AnalyticsTabEnum } from 'screens/Analytics/util';
import ComplianceAndSatisfactionTab from '../ComplianceAndSatisfactionTab';
import EligibilityAndAlertsTab from '../EligibilityAndAlertsTab';
import PatientStatusTab from '../PatientStatusTab';

const AnalyticsTabComponentMap = {
  [AnalyticsTabEnum.ELIGIBILITY_AND_ALERTS]: EligibilityAndAlertsTab,
  [AnalyticsTabEnum.COMPLIANCE_AND_SATISFACTION]: ComplianceAndSatisfactionTab,
  [AnalyticsTabEnum.PATIENT_STATUS]: PatientStatusTab,
};

export default AnalyticsTabComponentMap;
