import React, { FC } from 'react';
import styles from './styles.module.css';
import Body from './Body';

interface EncounterSummaryProps {
  encounter: any; //TODO: change to actual encounter
}

const EncounterSummary: FC<EncounterSummaryProps> = ({ encounter }: EncounterSummaryProps) => {
  return (
    <div className="dashboard-detail w-100 h-100">
      <div className="dashboard-item-header">Encounter summary</div>
      <div className={styles.body}>
        <Body />
      </div>
    </div>
  );
};

export default EncounterSummary;
