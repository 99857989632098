import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { getAccessToken } from 'services/tokenService';
import { getCurrentUserInfo, setPortalScheme } from 'services/userService';
import { useAuthContext } from './store';

interface AuthGuardProps {
  children: JSX.Element;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { currentUser, setCurrentUser } = useAuthContext();

  const fetchSetUserInfo = useCallback(async () => {
    const userInfo = await getCurrentUserInfo();
    setCurrentUser(userInfo);
  }, [setCurrentUser]);

  //Fetch user info only once per app render
  useEffect(() => {
    const localToken = getAccessToken().token;
    if (!currentUser) {
      const currentToken = localToken;
      currentToken && fetchSetUserInfo();
    }
  }, [currentUser, fetchSetUserInfo]);

  if (!getAccessToken().token) return <Redirect to="/" />;

  return <>{children}</>;
};

export default AuthGuard;
