import moment from 'moment';
import React from 'react';
import styles from './styles.module.css';

import Section from './components/Section';
import SatisfactionBar from '../../../../../../../../../../../components/SatisfactionBar';
import Duration from './components/Duration';

export default function Summary({ className, encounter }) {
  const {
    startDate,
    endDate,
    duration,
    subjective,
    objective,
    assessments,
    plan,
    patientSatisfaction,
  } = encounter;

  return (
    <div className={className}>
      <div className="d-flex flex-column shadow-sm rounded">
        <div className={styles.header}>Encounter Summary (SOAP)</div>
        <div className={styles.content}>
          <div className={styles.titleRow}>
            <div className={styles.title}>Start date</div>
            <div className={styles.value}>
              {moment(startDate).format('DD.MM.YYYY   •   H:mm a')}
            </div>
          </div>
          <div className={styles.titleRow}>
            <div className={styles.title}>End date</div>
            <div className={styles.value}>{moment(endDate).format('DD.MM.YYYY   •   H:mm a')}</div>
          </div>
          <div className={styles.titleRow} style={{ marginBottom: 20 }}>
            <div className={styles.title}>Duration</div>
            <Duration
              voiceDuration={duration.voice}
              textDuration={duration.chat}
              videoDuration={duration.video}
            />
          </div>
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={styles.section}>
          <Section title="Subjective" value={subjective} />
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={styles.section}>
          <Section title="Objective" value={objective} />
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={styles.section}>
          <Section title="Assessments " value={assessments} />
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={styles.section}>
          <Section title="Plan " value={plan} />
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={styles.section}>
          <Section title="Encounter Satisfaction">
            <SatisfactionBar value={patientSatisfaction} />
          </Section>
        </div>
      </div>
    </div>
  );
}
