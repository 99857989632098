import React from 'react';
import styles from './styles.module.css';
import Body from './components/Body';

const EncounterSummary = () => {
  return (
    <div className="dashboard-detail h-100">
      <div className="dashboard-item-header">Encounter summary</div>
      <div className={styles.body}>
        <Body />
      </div>
    </div>
  );
};

export default EncounterSummary;
