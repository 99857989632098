import { ReactComponent as CopyIcon } from 'assets/icons/copyIcon.svg';
import { negativeLocalIdCreator } from 'util/utils';

import Button from 'components/Button';
import InputField from 'components/InputField';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { MultiValue } from 'react-select';
import { useAdminPopulations } from 'screens/Administration/store/hooks';
import { AdminForm } from 'types/Administration/AdminForms/AdminForm';
import styles from './styles.module.css';
import { Overlay } from 'react-bootstrap';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { setFormDirty } from 'screens/Administration/store/Forms';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const rawEditorContent = convertToRaw(EditorState.createEmpty().getCurrentContent());
const blankEditorBody = JSON.stringify(rawEditorContent);
const blankEditorHtml = draftToHtml(rawEditorContent);

interface AddFormBodyProps {
  onCancel: () => void;
  onConfirm: (data: AdminForm) => void;
  currentForm?: AdminForm;
}
const AddFormBody = ({ onCancel, onConfirm, currentForm }: AddFormBodyProps) => {
  const [_, dispatch] = useAdminForms();

  const [newForm, setNewForm] = useState<AdminForm>(
    currentForm || {
      id: negativeLocalIdCreator()(),
      name: '',
      type: null,
      approved: '',
      url: null,
      status: true,
      editor_body: blankEditorBody,
      editor_html: blankEditorHtml,
      owner: null,
      population: [],
    }
  );

  useEffect(() => {
    if (
      currentForm &&
      (newForm.name !== currentForm?.name || newForm.population !== currentForm?.population)
    ) {
      dispatch(setFormDirty(newForm));
    }
  }, [newForm, currentForm, dispatch]);

  const [formSource, setFormSource] = useState<string>(currentForm?.url ? 'external' : 'internal');
  const [{ list: populations }] = useAdminPopulations();

  const selectPopulations = useMemo(
    () => populations?.map((pop) => ({ label: pop.name, value: pop.id })),
    [populations]
  );

  const handleFormNameChange = useCallback((e) => {
    setNewForm((prev) => ({ ...prev, name: e.target.value }));
  }, []);

  const handleFormTypeChange = useCallback((e) => {
    setNewForm((prev) => ({ ...prev, type: e.value }));
  }, []);
  const handleFormApprovedChange = useCallback((e) => {
    setNewForm((prev) => ({ ...prev, approved: e.value }));
  }, []);
  const handleFormUrlChange = useCallback((e) => {
    setNewForm((prev) => ({ ...prev, url: e.target.value }));
  }, []);
  const handleFormPopulationChange = useCallback(
    (newValue: MultiValue<{ label: string; value: number }>) => {
      setNewForm((prev) => ({
        ...prev,
        population: populations.filter((pop) => newValue.some((n) => n.value === pop.id)),
      }));
    },
    [populations]
  );

  const copyRef = useRef(null);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const copyToClipboard = useCallback((url) => {
    navigator.clipboard.writeText(url);
    setShowCopyTooltip(true);
    setTimeout(() => {
      setShowCopyTooltip(false);
    }, 2000);
  }, []);

  const sourceOptions = useMemo(
    () => [
      { label: 'External link', value: 'external' },
      { label: 'Internal', value: 'internal' },
    ],
    []
  );

  const formTypeOptions = useMemo(
    () => [
      { label: 'Consent form', value: 'consent_form' },
      { label: 'Terms and conditions', value: 'terms_conditions' },
      { label: 'HIPPA', value: 'hippa' },
      { label: 'Privacy policy', value: 'privacy_policy' },
    ],
    []
  );

  const approvedWithOptions = useMemo(
    () => [
      { label: 'None', value: 'none' },
      { label: 'Yes/No Checkbox', value: 'checkbox' },
    ],
    []
  );

  return (
    <div>
      <div>
        <div className={styles['field']}>
          <label htmlFor="form-name">Form name</label>
          <InputField name="form-name" value={newForm.name} onChange={handleFormNameChange} />
        </div>
        <div className={styles['field']}>
          <label htmlFor="form-populations">Population</label>
          <ReactSelect
            options={selectPopulations}
            value={newForm.population.map((pop) => ({ label: pop.name, value: pop.id }))}
            onChange={handleFormPopulationChange}
            isMulti
            name="form-populations"
            styles={{
              control: (base, state) => ({
                ...vytracSelectStyle.control(base, state),
              }),
              valueContainer: (base) => ({ ...base, fontSize: 13 }),
              container: (base) => ({ ...base, width: '100%' }),
              input: (base) => ({ ...base, height: 35 }),
            }}
          />
        </div>
        <div className={styles['field']}>
          <label htmlFor="form-source">Source</label>
          <ReactSelect
            isDisabled={!!currentForm}
            options={sourceOptions}
            onChange={(e) => setFormSource(e.value)}
            name="form-source"
            value={sourceOptions.find((opt) => opt.value === formSource)}
            styles={{
              ...vytracSelectStyle,
              control: (base, state) => ({
                ...vytracSelectStyle.control(base, state),
              }),
              valueContainer: (base) => ({ ...base, fontSize: 13 }),
              container: (base) => ({ ...base, width: '100%' }),
              input: (base) => ({ ...base, height: 35 }),
            }}
          />
        </div>
        <div className={styles['field']}>
          <label htmlFor="form-type">Type</label>
          <ReactSelect
            isDisabled={!!currentForm}
            name="form-type"
            options={formTypeOptions}
            value={formTypeOptions.find((opt) => opt.value === newForm.type)}
            onChange={handleFormTypeChange}
            styles={{
              control: (base, state) => ({
                ...vytracSelectStyle.control(base, state),
              }),
              valueContainer: (base) => ({ ...base, fontSize: 13 }),
              container: (base) => ({ ...base, width: '100%' }),
              input: (base) => ({ ...base, height: 35 }),
            }}
          />
        </div>
        <div className={styles['field']}>
          <label htmlFor="form-approved">Approved with</label>
          <ReactSelect
            isDisabled={!!currentForm}
            name="form-approved"
            value={approvedWithOptions.find((opt) => opt.value === newForm.approved)}
            onChange={handleFormApprovedChange}
            options={approvedWithOptions}
            styles={{
              control: (base, state) => ({
                ...vytracSelectStyle.control(base, state),
              }),
              valueContainer: (base) => ({ ...base, fontSize: 13 }),
              container: (base) => ({ ...base, width: '100%' }),
              input: (base) => ({ ...base, height: 35 }),
            }}
          />
        </div>
        {formSource === 'external' && (
          <div className={styles['field']}>
            <label htmlFor="form-url">Form link</label>
            <InputField
              style={{
                color: !!currentForm && 'var(--bright-blue)',
                backgroundColor: !!currentForm && 'white',
              }}
              readOnly={!!currentForm}
              name="form-url"
              value={newForm.url}
              onChange={handleFormUrlChange}
            />
            {!!currentForm && (
              <>
                <CopyIcon
                  className="position-absolute cursor-pointer"
                  style={{ right: '5%' }}
                  stroke="#8FAAC3"
                  ref={copyRef}
                  onClick={() => copyToClipboard(newForm.url)}
                />
                <Overlay target={copyRef.current} show={showCopyTooltip} placement="bottom-end">
                  {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        backgroundColor: '#8FAAC3',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        zIndex: 2000,
                        ...props.style,
                      }}
                    >
                      URL copied to clipboard
                    </div>
                  )}
                </Overlay>
              </>
            )}
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between w-100">
        <Button label="Cancel" variant="cancel" onClick={onCancel} />
        <Button label={'Confirm'} variant="confirm" onClick={() => onConfirm(newForm)} />
      </div>
    </div>
  );
};
export default AddFormBody;
