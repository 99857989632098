import { AdminForm } from 'types/Administration/AdminForms/AdminForm';
import { FormAction, FormActionTypes } from './formActionTypes';

export const setAdminForms = (forms: AdminForm[]): FormAction => ({
  type: FormActionTypes.SET_FORMS,
  payload: { forms },
});

export const setCurrentForm = (form: AdminForm): FormAction => ({
  type: FormActionTypes.SET_CURRENT_FORM,
  payload: { form },
});

export const updateCurrentForm = (form: AtLeast<AdminForm, 'id'>, oldId?: number): FormAction => {
  return {
    type: FormActionTypes.UPDATE_FORM,
    payload: { form, oldId },
  };
};

export const addNewForm = (form: AdminForm): FormAction => ({
  type: FormActionTypes.ADD_NEW_FORM,
  payload: { form },
});

export const deleteForm = (formId: number): FormAction => ({
  type: FormActionTypes.DELETE_FORM,
  payload: { formId },
});

export const setOpenAddFormModal = (open: boolean): FormAction => ({
  type: FormActionTypes.OPEN_ADD_FORM_MODAL,
  payload: { open },
});

export const setOpenDeleteFormModal = (open: boolean): FormAction => ({
  type: FormActionTypes.OPEN_DELETE_FORM_MODAL,
  payload: { open },
});

export const setFormDirty = (form: AdminForm): FormAction => ({
  type: FormActionTypes.SET_FORM_DIRTY,
  payload: { form },
});

export const resetDirtyForms = (): FormAction => ({
  type: FormActionTypes.RESET_DIRTY_FORMS,
});

export const updateDirtyForms = (): FormAction => ({
  type: FormActionTypes.UPDATE_DIRTY_FORMS,
});
export const clearDirtyForm = (formId: number): FormAction => ({
  type: FormActionTypes.CLEAR_DIRTY_FORM,
  payload: { formId },
});
