import BreadcrumbsWithActions from 'components/BreadcrumbsWithActions';
import { useDialogContext } from 'components/VyTracLayout/store';
import { FC } from 'react';
import Navbar from 'screens/Common/Navbar';

interface PatientTopBarProps {
  breadcrumbPathList;
  actions;
}

const PatientTopBar: FC<PatientTopBarProps> = ({
  breadcrumbPathList,
  actions: Actions,
}: PatientTopBarProps) => {
  const { dialog: DialogComponent } = useDialogContext();
  return (
    <>
      <Navbar showCareTeamIcon={true} />
      <BreadcrumbsWithActions breadcrumbs={breadcrumbPathList}>
        {Actions && <Actions />}
      </BreadcrumbsWithActions>
      {DialogComponent && <DialogComponent />}
    </>
  );
};
export default PatientTopBar;
