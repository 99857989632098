import PatientTimeline from '../components/PatientTimeline';
import CarePlan from '../components/PatientCarePlan';
import Encounters from '../components/Encounters';
import PatientManagement from '../components/PatientManagement';
import tabEnum from './tabEnum';
import Questionnaires from '../components/Questionnaires';
import GoalsThreshold from '../components/GoalsThreshold';
import PatientReports from '../components/PatientReports';
import TeamNotes from '../components/team-notes';
import PatientStatus from '../components/patient-status';
import PatientMessages from '../components/PatientMessages';

const tabComponentMap = {
  [tabEnum.PATIENT_STATUS]: PatientStatus,
  [tabEnum.PATIENT_TIMELINE]: PatientTimeline,
  [tabEnum.TEAM_NOTES]: TeamNotes,
  [tabEnum.CARE_PLAN]: CarePlan,
  [tabEnum.PATIENT_MESSAGES]: PatientMessages,
  [tabEnum.PATIENT_REPORTS]: PatientReports,
  [tabEnum.ENCOUNTERS]: Encounters,
  [tabEnum.GOALS_THRESHOLD]: GoalsThreshold,
  [tabEnum.QUESTIONNAIRES]: Questionnaires,
  [tabEnum.PATIENT_MANAGEMENT]: PatientManagement,
};

export default tabComponentMap;
