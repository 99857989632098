import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const StepsIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 15"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02448 2.81261C8.80657 2.81261 9.44056 2.183 9.44056 1.4063C9.44056 0.629614 8.80657 0 8.02448 0C7.24238 0 6.60839 0.629614 6.60839 1.4063C6.60839 2.183 7.24238 2.81261 8.02448 2.81261ZM3.35405 9.30124L2.91743 10.3126H0.944056C0.42276 10.3126 0 10.7324 0 11.2501C0 11.7678 0.42276 12.1877 0.944056 12.1877H3.22897C3.79687 12.1877 4.30814 11.8525 4.53029 11.3354L4.78961 10.7342L4.47482 10.5496C3.96385 10.2499 3.588 9.80634 3.35405 9.30124ZM11.3287 6.56246H10.0297L9.26089 5.00234C8.89212 4.25378 8.21506 3.70649 7.43828 3.5099L5.34129 2.89054C4.50639 2.69132 3.63698 2.87443 2.95637 3.39271L1.78604 4.28366C1.37213 4.59862 1.29365 5.18692 1.61139 5.59797C1.92912 6.00902 2.52122 6.08608 2.93483 5.77142L4.10576 4.88046C4.33204 4.7079 4.62027 4.64608 4.85127 4.70057L5.28494 4.82861L4.17981 7.38896C3.8075 8.25267 4.14116 9.26433 4.9557 9.74188L7.46276 11.2118L6.65235 13.7821C6.49658 14.276 6.7736 14.8019 7.271 14.9566C7.36511 14.9859 7.46011 15 7.55363 15C7.95515 15 8.32746 14.7431 8.45402 14.3423L9.38746 11.3814C9.56181 10.7729 9.3022 10.1192 8.74904 9.78788L6.94235 8.72905L7.86605 6.4356L8.46405 7.64942C8.70006 8.12815 9.19923 8.43724 9.73587 8.43724H11.3287C11.85 8.43724 12.2727 8.0174 12.2727 7.49971C12.2727 6.98201 11.85 6.56246 11.3287 6.56246Z"
        fill={color}
      />
    </svg>
  );
};

export default StepsIcon;
