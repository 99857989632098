import { Role } from 'types/ApiModels/Administration';

export enum AdminUsersActionTypes {
  SET_ROLES = 'SET_ROLES',
  UPSERT_ROLE = 'UPSERT_ROLE',
  UPSERT_ROLE_BULK = 'UPSERT_ROLE_BULK',
}

export type AdminUsersAction =
  | { type: AdminUsersActionTypes.UPSERT_ROLE; payload: { role: Role } }
  | { type: AdminUsersActionTypes.UPSERT_ROLE_BULK; payload: { roles: Role[] } }
  | { type: AdminUsersActionTypes.SET_ROLES; payload: { roles: Role[] } };
