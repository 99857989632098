import React, { useMemo } from 'react';
import styles from './styles.module.css';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as VerticalGrip } from 'assets/icons/drag.svg';
import { mapCardTypeToEventTypeLabel, mapLabelToActionCardType } from '../../../../util';

interface IActionItem {
  name: string;
  id: number;
  type: string;
}
const ActionItem = ({ name, id, type }: IActionItem) => {
  const eventTypeLabel = useMemo(() => {
    const actionCardType = mapLabelToActionCardType(type);
    return mapCardTypeToEventTypeLabel(actionCardType);
  }, [type]);

  return (
    <Draggable draggableId={`actionsCardItem-${eventTypeLabel}-${id}`} index={-1}>
      {(provided) => (
        <div
          className={styles.item}
          id={name}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className={styles.label}>{name}</div>
          <VerticalGrip className="mr-3" fill="#8FAAC3" />
        </div>
      )}
    </Draggable>
  );
};

export default ActionItem;
