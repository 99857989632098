import Card from 'components/Card';
import { PatientProfileRingAlt } from 'components/patient/PatientProfileRing';
import { Tagitem } from 'components/Tagitem';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import { formatDottedDate } from 'util/dateUtils';
import styles from './styles.module.css';

const PatientInformation = () => {
  const {
    state: { selectedPatient: patient },
  } = useMessagesContext();

  const [first_name, last_name] = patient?.full_name?.split(' ') ?? [];
  const information = [
    formatDottedDate(new Date(patient?.birth_date)),
    `#${patient?.id}`,
    patient?.email,
    patient?.phone_number,
  ];
  return (
    <Card className="bg-white w-100" headers={['Patient Information']} bodyClassName="p-0">
      {patient && (
        <div>
          <div className={styles['photo-name-container']}>
            <PatientProfileRingAlt
              currentPatient={{
                avatar_status: patient.status,
                imageUrl: patient.photo_thumbnail,
                sex: patient.sex,
              }}
            />
            <div className={styles['name-container']}>
              <div>{patient?.full_name}</div>
              <Link to={`patients/${patient.id}`}> Visit patient profile </Link>
            </div>
          </div>
          <Row>
            <Row
              className={`${styles['patient-information']} d-flex gap-sm flex-wrap container justify-content-evenly`}
            >
              {information.filter(Boolean).map((i, idx) => {
                const element = <span>{i}</span>;
                if (idx !== 0)
                  return (
                    <>
                      <span>•</span>
                      {element}
                    </>
                  );
                return element;
              })}
            </Row>
            <Row className={styles['info']}>
              {patient?.populations?.map((population) => (
                <Tagitem className="mb-1" key={population.id} text={population.name} />
              ))}
            </Row>
          </Row>
        </div>
      )}
    </Card>
  );
};
export default PatientInformation;
