export const fetchMockAppointments = () => {
  return [
    {
      from: 6,
      to: 7,
      subject: 'Meeting with John Constantine',
      note: 'Lorem ipsum dolor sit amet',
      type: 'video',
    },
    {
      from: 7,
      to: 8,
      subject: 'Meeting with John Constantine',
      note: 'Lorem ipsum dolor sit amet',
      type: 'voice',
    },
    {
      from: 14,
      to: 15,
      subject: 'Meeting with John Constantine',
      note: 'Lorem ipsum dolor sit amet',
      type: 'voice',
    },
  ];
};
