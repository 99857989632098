import { vytracAxios } from 'ajax';
import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import { ComplianceIssues } from 'types/ApiModels/Analytics/AnalyticsComplianceIssues';
import { Devices } from 'types/ApiModels/Analytics/AnalyticsDevices';
import { DroppedPatients } from 'types/ApiModels/Analytics/AnalyticsDropppedPatients';
import { NewPatients } from 'types/ApiModels/Analytics/AnalyticsNewPatients';
import { PatientAccrued } from 'types/ApiModels/Analytics/AnalyticsPatientAccrued';
import { Patients } from 'types/ApiModels/Analytics/AnalyticsPatients';
import { Satisfaction } from 'types/ApiModels/Analytics/AnalyticsSatisfaction';

export const getAnalyticsAlerts = async (dateFrom: string, dateTo: string) => {
  try {
    const res = await vytracAxios.get<Alerts[]>(`analytics/alerts/?date_from=${dateFrom}&date_to=${dateTo}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAnalyticsAlertsCompare = async ( months: number[]) => {
  try {
    const res = await vytracAxios.get<Alerts[]>(`analytics/alerts_months/?months=${months}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAlertsComparation = async (dateFrom: string, month: string) => {
    try {
      const res = await vytracAxios.get(`analytics/alerts/?date_from=${dateFrom}&months=${month}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsComplianceIssues = async (dateFrom: string, dateTo: string) => {
    try {
      const res = await vytracAxios.get<ComplianceIssues>(`analytics/issues_management/totals/?date_from=${dateFrom}&date_to=${dateTo}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsComplianceIssuesCompare = async (months: number[]) => {
    try {
      const res = await vytracAxios.get(`analytics/issues_management/compliance_months/?months=${months}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsSatisfaction = async (dateFrom: string, dateTo: string) => {
    try {
      const res = await vytracAxios.get<Satisfaction>(`analytics/success_metrics/patient_satisfaction/?date_from=${dateFrom}&date_to=${dateTo}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsNewPatients = async (dateFrom: string, dateTo: string) => {
    try {
      const res = await vytracAxios.get<NewPatients[]>(`analytics/general/patients_new/?date_from=${dateFrom}&date_to=${dateTo}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsNewPatientsCompare = async (months: number[]) => {
    try {
      const res = await vytracAxios.get(`analytics/general/patients_new_months/?months=${months}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsPatients = async () => {
    try {
      const res = await vytracAxios.get<Patients>(`analytics/general/patients_active/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };
  export const getAnalyticsPatientsCompare = async (months: number[]) => {
    try {
      const res = await vytracAxios.get(`analytics/general/patients_active_months/?months=${months}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsDroppedPatients = async () => {
    try {
      const res = await vytracAxios.get<DroppedPatients>(`analytics/general/patients_dropped/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsDroppedPatientsCompare = async (months: number[]) => {
    try {
      const res = await vytracAxios.get(`analytics/general/patients_dropped_months/?months=${months}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsAccruedPatients = async (dateFrom: string, dateTo: string) => {
    try {
      const res = await vytracAxios.get<PatientAccrued>(`analytics/general/patients_accrued/?date_from=${dateFrom}&date_to=${dateTo}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsAccruedPatientsCompare = async (months: number[]) => {
    try {
      const res = await vytracAxios.get(`analytics/general/patients_accrued_months/?months=${months}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getAnalyticsDevices = async () => {
    try {
      const res = await vytracAxios.get(`analytics/devices/count_model/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  export const getAnalyticsDevicesCompare = async (months: number[]) => {
    try {
      const res = await vytracAxios.get(`analytics/devices/count_model_months/?months=${months}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };
