import { RoundTagitem } from 'components/Tagitem';
import { FC } from 'react';
import styles from './styles.module.css';

interface BubbleProps {
  text: string;
}

const Bubble: FC<BubbleProps> = ({ text }: BubbleProps) => {
  return (
    <RoundTagitem
      text={text}
      containerClassNameList={[
        styles['regular-bubble-container'],
        styles['regular-bubble-container-margin'],
      ]}
    />
  );
};
export default Bubble;
