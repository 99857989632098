import { useActivityTimelineContext } from 'components/ActivityTimeline/store';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ActivityColumn, ActivityWeekColumns } from 'types/Patients/PatientTimeline';
import { addDays, convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import ActivityTimelineColumnHeader from '../ActivityColumnHeader';
import styles from '../ActivityItems.module.css';
import DateRangeActivityItems from '../DateRangeActivityItems';
import DayActivityItems from './DayActivityItems';
import { getWeekColumns } from './utils/weekViewUtils';

interface IColumnContent {
  abd: ActivityColumn;
  idx: number;
}

const WeekView = () => {
  const { activities, currentSelectedDate, setCurrentSelectedDate, draggable } =
    useActivityTimelineContext();

  const stringDateRef = useRef<string>(convertDatepickToDate(currentSelectedDate).toISOString());

  useEffect(() => {
    stringDateRef.current = convertDatepickToDate(currentSelectedDate).toISOString();
  }, [currentSelectedDate]);

  const weekColumns: ActivityWeekColumns = useMemo(
    () => getWeekColumns(activities, convertDatepickToDate(currentSelectedDate)),
    [activities, currentSelectedDate]
  );

  const handleNext = useCallback(() => {
    const date = convertDatepickToDate(currentSelectedDate);
    const newDate = addDays(date, 7);
    setCurrentSelectedDate(convertDateToDatePickValue(newDate));
  }, [currentSelectedDate, setCurrentSelectedDate]);

  const handlePrevious = useCallback(() => {
    const date = convertDatepickToDate(currentSelectedDate);
    const newDate = addDays(date, -7);
    setCurrentSelectedDate(convertDateToDatePickValue(newDate));
  }, [currentSelectedDate, setCurrentSelectedDate]);

  const ColumnContent = ({ abd, idx }: IColumnContent) => {
    return (
      <div style={{ padding: '40px 0px 10px 0px' }}>
        <DateRangeActivityItems
          isFirst={idx === 0}
          items={weekColumns.dateRangeActivitiesByDay[idx]}
        />

        <DayActivityItems items={abd.items} />
      </div>
    );
  };

  return (
    <>
      {weekColumns.activitiesByDay.map((abd, idx, arr) => (
        <div
          className={`d-flex flex-column ${styles['activity-column']}`}
          key={`header-${abd.headerLabel}`}
        >
          <ActivityTimelineColumnHeader
            arrowLeft={idx === 0}
            arrowRight={idx === arr.length - 1}
            label={abd.headerLabel}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activitiesCount={abd.items?.length}
            columnCount={arr.length}
            isCurrent={stringDateRef.current === abd.date.toISOString()}
          />
          {draggable ? (
            <Droppable droppableId={`weekView__${idx}__${stringDateRef.current}`}>
              {(provided, snapshot) => (
                <div
                  className={`d-flex flex-column flex-grow-1 mt-1 gap-sm`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={snapshot.isDraggingOver ? { backgroundColor: '#F7F7FA' } : {}}
                >
                  <ColumnContent abd={abd} idx={idx} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <div className={`d-flex flex-column flex-grow-1 mt-1 gap-sm`}>
              <ColumnContent abd={abd} idx={idx} />
            </div>
          )}
        </div>
      ))}
    </>
  );
};
export default WeekView;
