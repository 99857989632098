import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getAnalyticsSatisfaction } from 'services/analyticsService';
import { Satisfaction } from 'types/ApiModels/Analytics/AnalyticsSatisfaction';
import DatePickerValue from 'types/Shared/DatePicker';
import { setSatisfaction } from '../AnalyticsActionCreators';
import { AnalyticsAction } from '../AnalyticsActionTypes';
import { useAnalyticsContext } from '../AnalyticsContext';

const useSatisfaction = (
dateFrom: DatePickerValue,
dateTo: DatePickerValue
): [Satisfaction, Dispatch<AnalyticsAction>, () => void] => {
  const {
    state: { satisfaction },
    dispatch,
    fetchRegistry,
  } = useAnalyticsContext();

  const [satisfactionState, setSatisfactionState] = useState<Satisfaction>(null);

  const previousDateFrom = useRef(dateFrom);
  const previousDateTo = useRef(dateTo);
  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    previousDateFrom.current = { year: null, month: null, day: null };
    previousDateTo.current = { year: null, month: null, day: null };
    fetchRegistry.current.satisfaction.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchSatisfaction = useCallback(async () => {
    if (!dateFrom || !dateTo) return;
    const data = await getAnalyticsSatisfaction(
      `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`,
      `${dateTo.year}-${dateTo.month}-${dateTo.day}`
    );
    setSatisfactionState(data);
  }, [dateFrom, dateTo]);

  const satisfactionInit = useCallback(async () => {
    fetchRegistry.current.satisfaction.list = true;
    await fetchSatisfaction();
    fetchRegistry.current.satisfaction.list = false;
  }, [fetchRegistry, fetchSatisfaction]);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    if (
      previousDateFrom.current.year != dateFrom.year ||
      previousDateFrom.current.month != dateFrom.month ||
      previousDateFrom.current.day != dateFrom.day ||
      previousDateTo.current.year != dateTo.year ||
      previousDateTo.current.month != dateTo.month ||
      previousDateTo.current.day != dateTo.day ||
      (!satisfaction && !fetchRegistry.current.satisfaction.list)
    ) {
        satisfactionInit();
      previousDateFrom.current = { ...dateFrom };
      previousDateTo.current = { ...dateTo };
    }
  }, [fetchRegistry, satisfaction, satisfactionInit, dateFrom, dateTo,  _refetch.current]);

  useEffect(() => {
    dispatch(setSatisfaction(satisfactionState));
  }, [dispatch, satisfactionState]);

  return [satisfactionState, dispatch, refetch];
};

export default useSatisfaction;
