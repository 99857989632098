import React from 'react';
import UserBubble from '../../../../../../../../../../../../../components/UserBubble';
import RatingStarsView from './RatingStarsView';
import styles from './styles.module.css';

export default function Survey({ from, className, survey, to }) {
  return (
    <div className={className}>
      <div>
        <UserBubble user={from} />
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.surveyText}>
          A survey has been pushed to {to.firstName} {to.lastName}
        </div>
        <div className="d-flex align-items-center ">
          <RatingStarsView value={survey.value} />
        </div>
      </div>
    </div>
  );
}
