import { TextInputFormik } from 'components/FormikComponents';
import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import { ErrorMessage, useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { getNameInitials, getUserFullName } from 'util/userUtils';
import { CareTeamCreateEditFormikFields } from '../../types';
import { mapCareTeamProviderToUserBubble } from '../../utils';

const CareTeamNameInput: FC = ({}) => {
  const { values } = useFormikContext<CareTeamCreateEditFormikFields>();

  const currentInitials = useMemo(() => {
    const teamLeadInitials = values.tiers?.[0]?.providers?.[0]
      ? [
          getNameInitials(
            getUserFullName(mapCareTeamProviderToUserBubble(values.tiers?.[0]?.providers?.[0]))
          ),
        ]
      : [];
    const membersInitials =
      values.tiers.length > 1
        ? values.tiers.slice(1).flatMap((t) => {
            return t.providers
              .slice(0, 3)
              .map((p) => getNameInitials(getUserFullName(mapCareTeamProviderToUserBubble(p))));
          }, [])
        : [];

    return [...teamLeadInitials, ...membersInitials];
  }, [values.tiers]);
  return (
    <div className="d-flex gap">
      <UserBubbleGroupCollapsed initials={currentInitials} />
      <div className="d-flex flex-grow-1 flex-column gap-sm">
        <TextInputFormik
          name="name"
          className="flex-grow-1 font-size-medium"
          placeholder="Team name..."
        />
        <ErrorMessage name="name">
          {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );
};
export default CareTeamNameInput;
