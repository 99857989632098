import Button from 'components/Button';
import VyTracModal from 'components/Modal';
import SearchBar, { SearchBarAlt } from 'components/SearchBar';
import { FC, useState } from 'react';
import { Role } from 'types/ApiModels/Administration';
import styles from '../BulletedModals.module.css';

interface AccessRolesModalProps {
  roles: Role[];
  assignedRolesIds: number[];
  handleClose: () => void;
  handleConfirm: (rids: number[]) => void;
  show: boolean;
}

const AccessRolesModal: FC<AccessRolesModalProps> = ({
  roles,
  assignedRolesIds,
  handleClose,
  handleConfirm,
  show,
}: AccessRolesModalProps) => {
  const [currentlySelected, setCurrentlySelected] = useState(assignedRolesIds);

  const handleRoleSelect = (roleId: number) => {
    setCurrentlySelected((rids) => {
      const existingIdx = rids.findIndex((id) => id === roleId);
      return existingIdx === -1 ? [...rids, roleId] : rids.filter((id) => id !== roleId);
    });
  };

  const [search, setSearch] = useState('');

  return (
    <VyTracModal
      show={show}
      title="Assign a role"
      onClose={handleClose}
      body={
        <div className="d-flex flex-column gap">
          <SearchBarAlt onChange={(e) => setSearch(e.target.value)} value={search} />
          <div>
            {roles?.flatMap((r) => {
              if (!r.name.toLowerCase().includes(search.toLowerCase())) return [];

              const isSelected = currentlySelected?.some((arId) => r.id === arId);
              return [
                <div
                  key={r.id}
                  className={`d-flex gap justify-content-between ${styles['container']} cursor-pointer`}
                  onClick={() => handleRoleSelect(r.id)}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className={`${styles['custom-input']} ${isSelected ? styles.selected : ''}`}
                    ></div>
                    <input type="checkbox" hidden value={r.id} checked={isSelected} />
                  </div>
                  <div className="flex-grow-1 ">{r.name}</div>
                </div>,
              ];
            })}
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={handleClose} />
          <Button label="confirm" onClick={() => handleConfirm(currentlySelected)} />
        </div>
      }
    />
  );
};
export default AccessRolesModal;
