import ArrowDownTodayDatePicker from 'components/ArrowDownTodayDatePicker';
import Card from 'components/Card';
import FiltersButton from 'components/FiltersButton';
import { useRef, useState } from 'react';
import { singlePatient } from 'screens/Common/mocks';
import ActivityPointsActions from 'types/ActivityPoints';
import DatePickerValue from 'types/Shared/DatePicker';
import { PointsTable } from './points-table';

const Header = ({ points }: { points: number }) => {
  const [selectedDate, setSelectedDate] = useState<DatePickerValue>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  });
  return (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex align-items-center">
        <div className="mr-2">Your activity points ({points})</div>
        <ArrowDownTodayDatePicker value={selectedDate} onChange={(date) => setSelectedDate(date)} />
      </div>
      <FiltersButton onFiltersClick={() => null} />
    </div>
  );
};

const YourActivityPoints = () => {
  const mockedData = useRef<ActivityPointsActions>({
    action: 'Lorem ipsum dolor sit amet',
    patient: singlePatient,
    patientId: '1',
    reward: 18,
    multiplier: 0.15,
  });
  return (
    <Card
      headers={[<Header key={0} points={375} />]}
      className="w-100 bg-white"
      bodyClassName="p-0"
    >
      <PointsTable actions={[mockedData.current, mockedData.current]} />
    </Card>
  );
};
export default YourActivityPoints;
