import { useMemo } from 'react';
import Adherence from 'types/ApiModels/Patients/Adherence';
import { convertAdherenceToBooleanArray } from 'util/adherenceUtils';

interface AdherenceGridProps {
  adherence: Adherence;
}
const AdherenceGrid = ({ adherence }: AdherenceGridProps) => {
  const adherenceGridBool = useMemo(() => convertAdherenceToBooleanArray(adherence), [adherence]);

  return (
    <div className="d-flex">
      <div>
        <div className="adherence-grid">
          {adherenceGridBool &&
            adherenceGridBool.map((item, idx) => (
              <div
                className={`${item ? 'adherence-line-blue' : 'adherence-line-gray'}`}
                key={`patient-adherence-line-${idx}`}
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default AdherenceGrid;
