import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { PaginatedResponse } from 'types/ApiModels/General';
import { debouncePromiseValue } from 'util/utils';

type SearchRequest<T> = (search: string) => Promise<PaginatedResponse<T>>;

/**
 * Hook a searchbar with this to add remote search functionality
 * @param searchRequest The request to do when prompting a search. Should be memoized
 * @param mapperFunction The mapper function to get the search results with the desired shape. Should be memoized
 */
export const useDebouncedSearch = <T, TReturn = T>(
  searchRequest: SearchRequest<T>,
  mapperFunction?: (source: T) => TReturn
) => {
  const [search, setSearch] = useState('');
  const [bareSearchResult, setBareSearchResult] = useState<T[]>([]);
  const [searchResult, setSearchResult] = useState<TReturn[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const fetchSearch = useCallback(
    async (search: string) => {
      try {
        if (!search) {
          setSearchResult([]);
          return;
        }
        const result = await searchRequest(search);
        flushSync(() => {
          //sadly I needed to do this for typescript to believe me
          const unknownResult: unknown = result.results;
          setSearchResult(
            mapperFunction
              ? (unknownResult as T[]).map(mapperFunction)
              : (unknownResult as TReturn[])
          );
          setBareSearchResult(result.results);
        });
      } catch (error) {
        throw error;
      } finally {
        setIsSearching(false);
      }
    },
    [mapperFunction, searchRequest]
  );

  const debouncedSearch = useMemo(() => debouncePromiseValue(fetchSearch, 500), [fetchSearch]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      //as soon as we type we should see that we're searching (even if we didn't do the request yet)
      setIsSearching(true);
      setSearch(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch]
  );

  return useMemo(
    () => ({
      search,
      handleSearch,
      mappedSearchResult: searchResult,
      bareSearchResult,
      isSearching,
    }),
    [search, handleSearch, searchResult, bareSearchResult, isSearching]
  );
};
