import React from 'react';
import styles from './styles.module.css';
import documentImage from 'assets/icons/file.png';

export default function File({ name }) {
  return (
    <div className={styles.fileContainer}>
      <div className={styles.imageContainer}>
        <img src={documentImage} alt="document" className={styles.image} />
      </div>
      <a href="">{name}</a>
    </div>
  );
}
