import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import * as DragDataPlugin from 'chartjs-plugin-dragdata';
import moment from 'moment';
import React, { useState, useEffect, useMemo, MutableRefObject } from 'react';
import { Line } from 'react-chartjs-2';
import { UmbralType, IGoalThreshold, IGoalThresholdUmbral } from 'types/ApiModels/Patients/Goal';
import { VytalResponse } from 'types/ApiModels/Patients/Vytal';
import { IDataset } from 'types/VytalChart/dataPoint';
import { getAxisLabel, getChartType, getChartUnits, isDoubleThreshold } from 'util/goalChart';
import { debounce } from 'util/utils';
import useGoalChart from './useGoalChart';
import { Config } from './util';
import {
  generateBoxAnnotation,
  generateLabelAnnotation,
  generateLineAnnotation,
} from './util/chartAnnotations';
import { chartColors } from './util/chartColors';
import { generateDatasets } from './util/generateDataSet';

ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IGoalLineChart {
  dataPoints: VytalResponse[];
  goalThreshold: IGoalThreshold;
  timeSpan: number;
  chartRef: MutableRefObject<ChartJS<'line'>>;
}

const GoalLineChart = ({ dataPoints = [], timeSpan, goalThreshold, chartRef }: IGoalLineChart) => {
  const { onDragThreshold } = useGoalChart(goalThreshold);
  //State that manages the labels and datasets
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  //State that manages the annotations (thresholds and labels)
  const [options, setOptions] = useState({
    events: [],
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      ...Config.PLUGINS,
      dragData: {
        round: 1,
        onDrag: debounce(
          (_, datasetIndex: number, __, value: number) => onDragThreshold(datasetIndex, value),
          50
        ),
      },
    },
    elements: Config.ELEMENTS,
    scales: Config.SCALES(getAxisLabel(goalThreshold.type)),
    layout: Config.LAYOUT,
  });

  const usedDatapoints = useMemo(() => dataPoints.slice(0, timeSpan), [dataPoints, timeSpan]);

  const umbralRegistry: Record<UmbralType, IGoalThresholdUmbral> = useMemo(() => {
    const registry = {} as Record<UmbralType, IGoalThresholdUmbral>;
    goalThreshold.threshold_umbrals.forEach((umbral) => (registry[umbral.type] = umbral));
    return registry;
  }, [goalThreshold.threshold_umbrals]);

  //Generate Data Points, labels and thresholds handles
  useEffect(() => {
    const datasets: IDataset[] = generateDatasets(goalThreshold, usedDatapoints);
    const labels = usedDatapoints
      .map((dataPoint) => moment(dataPoint.day).format('MM/DD'))
      .reverse();
    setChartData({
      labels: ['', ...labels, ''],
      datasets,
    });
    // }, [dataPoints, goal]);
  }, [usedDatapoints, goalThreshold]);

  //Generate Thresholds lines and vertical lines for BP
  useEffect(() => {
    setOptions((prev) => {
      let newOptions: any = {
        ...prev,
        scales: Config.SCALES(getAxisLabel(goalThreshold.type)),
        showLine: true,
        plugins: {
          ...prev.plugins,
          annotation: {
            annotations: [
              generateBoxAnnotation(
                umbralRegistry[UmbralType.RED_ZONE_LOWER],
                chartColors.red.background,
                'redLowerBox'
              ),
              generateBoxAnnotation(
                umbralRegistry[UmbralType.YELLOW_ZONE_LOWER],
                chartColors.yellow.background,
                'yellowLowerBox'
              ),
              generateBoxAnnotation(
                umbralRegistry[UmbralType.NORMAL_ZONE],
                chartColors.normal.background,
                'normalBox'
              ),
              generateLineAnnotation(
                umbralRegistry[UmbralType.RED_ZONE_LOWER]?.max_value,
                '#F2A1AD',
                'redTopLowerLine'
              ),
              generateLineAnnotation(
                umbralRegistry[UmbralType.RED_ZONE_LOWER]?.min_value,
                '#F2A1AD',
                'redBottomLowerLine'
              ),
              generateLabelAnnotation(
                umbralRegistry[UmbralType.RED_ZONE_LOWER],
                getChartUnits(goalThreshold.type),
                chartColors.red.label,
                'redLowerLabel',
                timeSpan
              ),
              generateLabelAnnotation(
                umbralRegistry[UmbralType.YELLOW_ZONE_LOWER],
                getChartUnits(goalThreshold.type),
                chartColors.yellow.label,
                'yellowLowerLabel',
                timeSpan
              ),
              generateLineAnnotation(
                umbralRegistry[UmbralType.YELLOW_ZONE_LOWER].max_value,
                '#F8CA54',
                'yellowLowerLine'
              ),
              generateLabelAnnotation(
                umbralRegistry[UmbralType.NORMAL_ZONE],
                getChartUnits(goalThreshold.type),
                chartColors.normal.label,
                'NormalLabel',
                timeSpan
              ),
            ],
          },
        },
      };
      if (isDoubleThreshold(goalThreshold.type)) {
        newOptions = {
          ...newOptions,
          plugins: {
            ...newOptions.plugins,
            annotation: {
              annotations: [
                ...newOptions.plugins.annotation.annotations,
                generateBoxAnnotation(
                  umbralRegistry[UmbralType.RED_ZONE_UPPER],
                  chartColors.red.background,
                  'redUpperBox'
                ),
                generateBoxAnnotation(
                  umbralRegistry[UmbralType.YELLOW_ZONE_UPPER],
                  chartColors.yellow.background,
                  'yellowUpperBox'
                ),
                generateLineAnnotation(
                  umbralRegistry[UmbralType.RED_ZONE_UPPER]?.max_value,
                  '#F2A1AD',
                  'redTopUpperLine'
                ),
                generateLineAnnotation(
                  umbralRegistry[UmbralType.RED_ZONE_UPPER]?.min_value,
                  '#F2A1AD',
                  'redBottomUpperLine'
                ),
                generateLabelAnnotation(
                  umbralRegistry[UmbralType.RED_ZONE_UPPER],
                  getChartUnits(goalThreshold.type),
                  chartColors.red.label,
                  'redUpperLabel',
                  timeSpan
                ),
                generateLineAnnotation(
                  umbralRegistry[UmbralType.YELLOW_ZONE_UPPER]?.min_value,
                  '#F8CA54',
                  'yellowUpperLine'
                ),
                generateLabelAnnotation(
                  umbralRegistry[UmbralType.YELLOW_ZONE_UPPER],
                  getChartUnits(goalThreshold.type),
                  chartColors.yellow.label,
                  'yellowUpperLabel',
                  timeSpan
                ),
              ],
            },
          },
        };
      }
      if (getChartType(goalThreshold.type) === 'double-line') {
        const newLines = dataPoints
          .map((dataPoint, index) =>
            dataPoint
              ? {
                  type: ['line'],
                  xMax: index,
                  xMin: index,
                  yMax: dataPoint.vytal?.value ?? 0,
                  yMin: dataPoint.vytal?.value_2 ?? 0,
                  annotationID: `DataPoint-${index}`,
                  drawTime: 'beforeDatasetsDraw',
                  borderColor: '#8FAAC3',
                  borderWidth: 3,
                }
              : null
          )
          .filter((line) => line);
        newOptions = {
          ...newOptions,
          showLine: false,
          plugins: {
            ...newOptions.plugins,
            annotation: {
              annotations: [...newOptions.plugins.annotation.annotations, ...newLines],
            },
          },
        };
      }
      return newOptions;
    });
  }, [umbralRegistry, goalThreshold, timeSpan]);

  return <Line options={options} data={chartData} plugins={[DragDataPlugin]} ref={chartRef} />;
};

export default GoalLineChart;
