import AppointmentServices from '../AppointmentServices';
import ChartingTemplates from '../ChartingTemplates';
import NotesKeywords from '../notes-keywords';
import AdminPopulation from '../AdminPopulation';
import Activities from '../activities';

enum adminPatientSettingsEnum {
  population = 'population',
  activities = 'activities',
  notesKeywords = 'notesKeywords',
  appointmentServices = 'appointmentServices',
  chartingTemplates = 'chartingTemplates',
}

export const adminPatientSettingsControls = [
  {
    enumValue: adminPatientSettingsEnum.population,
    route: 'population',
    label: 'population',
    component: AdminPopulation,
  },
  {
    enumValue: adminPatientSettingsEnum.activities,
    route: 'activities',
    label: 'activities',
    component: Activities,
  },
  {
    enumValue: adminPatientSettingsEnum.notesKeywords,
    route: 'notes-keywords',
    label: 'notes keywords',
    component: NotesKeywords,
  },
  {
    enumValue: adminPatientSettingsEnum.appointmentServices,
    route: 'appointment-services',
    label: 'appointment services',
    component: AppointmentServices,
  },
  {
    enumValue: adminPatientSettingsEnum.chartingTemplates,
    route: 'charting-templates',
    label: 'charting templates',
    component: ChartingTemplates,
  },
];
