import Button from 'components/Button';
import CareTeamTierSelect from 'components/CareTeamTierSelect';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import ProviderTierItem from 'components/ProviderTierItem';
import { ErrorMessage, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import useCurrentProviderCareTeam from 'screens/ProviderDashboard/store/hooks/useCurrentProviderCareTeam';
import { IModalBody } from '../ActionModalBody';
import styles from './styles.module.css';

const AssignBody = ({ onCancel }: IModalBody) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);

  useEffect(() => {
    setFieldValue('assign_to', selectedProviders);
  }, [selectedProviders, setFieldValue, setFieldTouched]);

  const [currentCareTeam] = useCurrentProviderCareTeam();

  const aboveTiers = useMemo(() => {
    if (currentCareTeam) {
      const currentTierNumber = currentCareTeam.current_tier.number;
      return currentCareTeam.tiers.filter((tier) => tier.number >= currentTierNumber);
    }
    return [];
  }, [currentCareTeam]);

  return (
    <div>
      <div className={styles['question']}>
        <div>To whom do you want to assign this patient?</div>
        {currentCareTeam && (
          <CareTeamTierSelect
            tiers={aboveTiers}
            careTeamName={currentCareTeam.name}
            ProviderItem={ProviderTierItem}
            onProviderSelect={(provider, checked) => {
              if (checked) {
                setSelectedProviders([...selectedProviders, provider.id]);
              } else {
                setSelectedProviders(selectedProviders.filter((id) => id !== provider.id));
              }
            }}
            selectedProviders={selectedProviders}
          />
        )}
        <ErrorMessage
          name="assign_to"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
      <div className={styles['question']}>
        <div>Why are you escalating this patient? Why to this/these provider/s?</div>
        <TextAreaFormik name="note" placeholder={"I'm escalating this patient because..."} />
        <ErrorMessage
          name="note"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
      <div className="mt-3 d-flex justify-content-between w-100">
        <Button label="Cancel" onClick={onCancel} variant="cancel" />
        <Button label="Confirm" type="submit" variant="confirm" />
      </div>
    </div>
  );
};
export default AssignBody;
