import { User } from 'types/ApiModels/Users';

export type FullNameableUser = Pick<User, 'first_name' | 'last_name'>;
export type NameableUser = Partial<Pick<User, 'username'>> & FullNameableUser;

export const getUserFullName = (user: FullNameableUser) => {
  return `${user.first_name} ${user.last_name}`;
};

export const getUserDisplayName = (user: NameableUser) => {
  return user?.first_name && user?.last_name
    ? `${user.first_name} ${user.last_name}`
    : user.username;
};

export const getNameInitials = (name: string) =>
  name
    ? name
        .split(' ')
        .map((n) => n.charAt(0))
        .join('')
    : '??';

export const getUserInitials = (user: NameableUser) => getNameInitials(getUserDisplayName(user));
