import vytracBubble from 'assets/images/vytrac_user_bubble.png';
import SearchBar from 'components/SearchBar';
import AudioMessage from './components/AudioMessage';
import CallMessage from './components/CallMessage';
import FileMessage from './components/FileMessage';
import MentionMessage from './components/MentionMessage';
import Survey from './components/Survey';
import { TextMessage } from 'components/Messages';
import VitalMessage from './components/VitalMessage';
import styles from './styles.module.css';

export default function Log({
  encounterId,
  encounterReason,
  patientMessages,
  providerMessages,
  className,
  patient,
  provider,
}) {
  //Dummy survey value
  const survey = {
    value: 3,
  };
  //Dummy Vytrac User
  const vytracApp = {
    firstName: 'Vytrac',
    lastName: 'App',
    profileIcon: vytracBubble,
  };

  //TODO: check if we ever need these two, or we can just wipe them.
  // const [searchValue, setSearchValue] = React.useState('');

  //Search submit
  // const searchSubmit = (e) => {
  //   e.preventDefault();
  // };

  //Renders A message based on the type of message
  //UPDATE: The message types are not determined yet
  const renderMessageByType = (message, user, isProvider) => {
    const messageClass = isProvider ? styles.providerMessage : styles.patientMessage;

    const timeStampClass = isProvider ? styles.timeStampLeft : styles.timeStampRight;

    switch (message.type) {
      case 'text':
        return (
          <TextMessage
            message={message}
            from={user}
            className={messageClass}
            timeStampClassName={timeStampClass}
          />
        );
      case 'file':
        return (
          <FileMessage
            message={message}
            from={user}
            className={messageClass}
            timeStampClassName={timeStampClass}
          />
        );
      case 'audio':
        return (
          <AudioMessage
            message={message}
            from={user}
            className={messageClass}
            timeStampClassName={timeStampClass}
          />
        );
      case 'mention':
        return (
          <MentionMessage
            message={message}
            from={user}
            className={messageClass}
            timeStampClassName={timeStampClass}
          />
        );
      case 'vital':
        return (
          <VitalMessage
            message={message}
            from={user}
            className={messageClass}
            timeStampClassName={timeStampClass}
          />
        );
      case 'call':
        return (
          <CallMessage
            message={message}
            from={user}
            // isProvider={isProvider}
            className={messageClass}
            timeStampClassName={timeStampClass}
          />
        );
      default:
        return null;
    }
  };

  //Renders a message based on the user, and if the user is the provider or patient
  const renderMessage = (message, patient) => {
    const isProvider = message.from !== `${patient.firstName} ${patient.lastName}`;
    return (
      <div className={`d-flex pt-3 ${isProvider ? 'justify-content-end' : ''}`}>
        {renderMessageByType(message, isProvider ? provider : patient, isProvider)}
      </div>
    );
  };

  //Sorts messages by creation date
  const sortedMessages = patientMessages.concat(providerMessages).sort((a, b) => {
    return a.createdAt - b.createdAt;
  });

  return (
    <div className={className}>
      <div className="d-flex flex-column shadow-sm rounded">
        <div className="d-flex justify-content-between align-items-center" style={{ height: 50 }}>
          <div className={styles.encounterId}>#{encounterId}</div>
          <div className={styles.searchBox}>
            <SearchBar placeholder="Quick search" onChange={() => null} />
          </div>
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={`h-100 d-flex flex-column ${styles.messagesContainer}`}>
          {sortedMessages.map((message) => renderMessage(message, patient))}
          <div className={styles.encounterEnded}>
            <hr className={styles.horizontalLine} />
            <div className={styles.encounterEndedText}>Encounter ended</div>
            <hr className={styles.horizontalLine} />
          </div>
          <div>
            <Survey
              from={vytracApp}
              survey={survey}
              className={styles.patientMessage}
              to={patient}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
