import TabEnum from '../../../../../util/TabEnum';
import QuestionnaireDetails from '../components/QuestionnaireDetails';
import CarePlanDetails from '../components/CarePlanDetails';
import SurveyDetails from '../components/SurveyDetails';

const DetailComponentMap = {
  [TabEnum.CAREPLAN_TEMPLATES]: CarePlanDetails,
  [TabEnum.QUESTIONNAIRES]: QuestionnaireDetails,
  // [TabEnum.SURVEYS]: SurveyDetails,
};

export default DetailComponentMap;
