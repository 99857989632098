import UserBubbleGroup, { UserBubbleGroupUser } from 'components/UserBubbleGroup';
import React from 'react';
import MentionItemCustom from 'types/Shared/MentionItemCustom';
import attatchment from '../../assets/icons/attatchment.png';
import { MessageInputPowered } from './components/MessageInput';
import styles from './styles.module.css';

interface SendMessageBoxPoweredProps {
  noPadding?: boolean;
  handleSendMessage: (message: string, mentions: MentionItemCustom[]) => void;
  participants?: UserBubbleGroupUser[];
  mentionables?: { display: string; id: string }[];
  keywords?: { display: string; id: string }[];
}

const MAX_BUBBLES = 2;

/**
 * Alternate component that uses react-mentions as input so that we can add hashtags and mentions
 * @returns
 */
const SendMessageBoxPowered = ({
  noPadding = false,
  handleSendMessage,
  participants,
  mentionables = [],
  keywords = [],
}: SendMessageBoxPoweredProps) => {
  return (
    <div
      className={styles.main}
      style={noPadding ? { padding: '0px' } : {}}
      onClick={(e) => e.stopPropagation()}
    >
      <button className={styles.attatchment}>
        <img src={attatchment} alt="" />
      </button>
      {participants?.length ? (
        <div className={styles.messageBubbles}>
          <UserBubbleGroup users={participants} maxCount={MAX_BUBBLES} />
        </div>
      ) : null}
      <div className={styles.messageInput}>
        <MessageInputPowered
          handleSendMessage={handleSendMessage}
          mentionables={mentionables}
          keywords={keywords}
        />
      </div>
    </div>
  );
};

export default SendMessageBoxPowered;
