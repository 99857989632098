import { AlertImportanceOptions, AlertTypeOptions } from 'components/AlertIcons/utils/AlertOptions';
import { mapAlertTypeToColor } from 'components/AlertIcons/utils/mapAlertImportanceToColor';
import { mapAlertTypeToIcon } from 'components/AlertIcons/utils/mapAlertTypeToIcon';
import AlertItemSvg from 'components/AlertItemSvg';
import { IComment } from 'components/CardComment/CardComment';
import { mockEvent } from 'screens/ProviderDashboard/utils';
import styles from '../../styles.module.css';
import CommentIcon from '../comment-icon';

interface IEventCardHeader {
  event: mockEvent;
  comments: IComment[];
}

const EventCardHeader = ({ event, comments }: IEventCardHeader) => {
  const unreadCommentsCounter = comments.filter(
    (comment: IComment) => comment.unreadComment
  ).length;

  AlertImportanceOptions.CRITICAL;

  return (
    <div className="d-flex justify-content-between w-100" style={{ padding: '10px 20px' }}>
      <div className="d-flex gap align-items-center">
        <div style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>6:33 pm</div>
        <AlertItemSvg
          SvgComponent={mapAlertTypeToIcon(event.type as AlertTypeOptions)}
          color={mapAlertTypeToColor(event.importance)}
          type={event.importance === AlertImportanceOptions.CRITICAL ? 'hexagon' : null}
        />
        <div className={styles.textContainer}>
          <div className={styles.title}>{event.title}</div>
          <div className={styles.text}>{event.text}</div>
        </div>
      </div>
      <div className="flex-grow-1"></div>
      {comments.length > 0 && (
        <div className="d-flex gap align-items-center" style={{ fontSize: '12px' }}>
          {unreadCommentsCounter ? (
            <div style={{ fontWeight: 'bold' }}>8:35 pm</div>
          ) : (
            <div style={{ color: '#8FAAC3' }}>{comments.length}</div>
          )}
          <CommentIcon unreadComments={unreadCommentsCounter > 0} />
        </div>
      )}
    </div>
  );
};
export default EventCardHeader;
