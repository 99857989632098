import React from 'react';
import Modal from '../../../Modal';
import RecurrenceConfirmationModalBody from './components/RecurrenceConfirmationModalBody';

interface IRecurrenceConfirmationModal {
  onAllEvents: () => void;
  onOnlyThisEvent: () => void;
  onClose: () => void;
  show: boolean;
}
const RecurrenceConfirmationModal = ({
  onAllEvents,
  onOnlyThisEvent,
  onClose,
  show,
}: IRecurrenceConfirmationModal) => {
  return (
    <Modal
      title={'Warning'}
      body={
        <RecurrenceConfirmationModalBody
          onAllEvents={onAllEvents}
          onOnlyThisEvent={onOnlyThisEvent}
        />
      }
      centered
      onClose={onClose}
      show={show}
    />
  );
};
export default RecurrenceConfirmationModal;
