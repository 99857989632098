import Button from 'components/Button';
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { setActions, switchLastBreadcrumb } from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import BroadcastActions from './broadcast-actions';
import BroadcastMessageCard from './broadcast-messages-card';

const Broadcast = () => {
  const { dispatch } = useMessagesContext();

  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(switchLastBreadcrumb({ title: 'Broadcast messages', url: '/messages/broadcast' }));
    dispatch(setActions(BroadcastActions));
  }, [dispatch]);

  return (
    <>
      <Col className="pb-3">
        {/* This component children render its options on the bottom side*/}
        <BroadcastMessageCard>
          <Switch>
            <Route path={path} exact>
              <div className="d-flex justify-content-between p-3">
                <Button label="Save message as template" variant="cancel" />
                <div className="d-flex gap">
                  <Button label="Save changes" variant="confirm" />
                  <Button label="Send now" />
                </div>
              </div>
            </Route>

            <Route path={`${path}/templates`} exact>
              <div className="d-flex justify-content-between p-3">
                <div className="d-flex gap">
                  <Button label="Save message as template" variant="cancel" />
                  <Button label="Create from template" variant="cancel" />
                </div>
                <Button label="Save changes" variant="confirm" />
              </div>
            </Route>
          </Switch>
        </BroadcastMessageCard>
      </Col>
    </>
  );
};
export default Broadcast;
