import { RoundTagitem } from 'components/Tagitem';
import { FC } from 'react';
import styles from './styles.module.css';

interface UserBubbleGroupCollapsedProps {
  initials: string[];
  clickable?: boolean;
  onClick?: () => void;
}

const getProcessedInitials = (initials: string[]) => {
  if (initials.length < 4) return initials;

  return initials.slice(0, 4);
};

const UserBubbleGroupCollapsed: FC<UserBubbleGroupCollapsedProps> = ({
  initials,
  clickable = false,
  onClick = null,
}: UserBubbleGroupCollapsedProps) => {
  if (initials.length === 1) return <RoundTagitem text={initials[0]} includeMargin={false} />;

  return (
    <div
      className={`d-flex flex-wrap justify-content-center align-items-stretch ${styles['bubble-container']}`}
      style={clickable ? { cursor: 'pointer' } : {}}
      onClick={onClick}
    >
      {getProcessedInitials(initials).map((i: string, idx: number) => (
        <div
          className={`text-uppercase position-relative ${styles['four-elements-container']}`}
          key={idx}
        >
          <div className="">{i}</div>
        </div>
      ))}
    </div>
  );
};
export default UserBubbleGroupCollapsed;
