import PatientDetail from 'components/patient/PatientDetail';
import PatientProfileRing from 'components/patient/PatientProfileRing';
import Stopwatch from 'components/Stopwatch';
import { useCallback, useEffect, useMemo } from 'react';
import {
  addTrackerTime,
  changeSelectedTimer,
  setTrackingType,
  toggleTimeIsTracking,
} from 'screens/ProviderDashboard/store/DashboardActionCreators';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import { IDashboardToDo, IDashboardToDoSingle } from 'types/ApiModels/Dashboard/DashboardToDo';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import PatientEvent from './PatientEvent';

interface PatientToDoProps {
  patientToDo: IDashboardToDo | IDashboardToDoSingle;
  loadingToDos: boolean;
}

const PatientToDo = ({ patientToDo, loadingToDos }: PatientToDoProps) => {
  const {
    dispatch,
    state: { trackingPatientId, timerIsTracking, patientIdTimeMap, trackingActivityId },
  } = useDashboardContext();

  const stopwatchClick = useCallback(() => {
    if (trackingPatientId !== patientToDo.id) {
      dispatch(changeSelectedTimer(patientToDo.id));
    }

    dispatch(setTrackingType(TrackingType.PATIENT_TODO));
    dispatch(toggleTimeIsTracking(!timerIsTracking));
  }, [timerIsTracking, patientToDo?.id, trackingPatientId, dispatch]);

  const patient: RecursivePartial<PatientGetSingle> = useMemo(
    () =>
      patientToDo && {
        id: patientToDo.id,
        user: {
          first_name: patientToDo.first_name,
          last_name: patientToDo.last_name,
          email: patientToDo.email,
          phone_number: patientToDo.phone_number,
          sex: patientToDo.sex,
        },
        is_active: true,
        has_mobile_app: patientToDo.has_mobile_app,
      },
    [patientToDo]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerIsTracking && trackingPatientId === patientToDo?.id) {
        dispatch(addTrackerTime());
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timerIsTracking, trackingPatientId]);

  return (
    <div className="dashboard-patient-scroller scroller-x">
      <div
        className="h-100 d-flex align-items-center px-3"
        style={{ borderRight: '1px solid #efeff0' }}
      >
        <Stopwatch
          isTracking={timerIsTracking && trackingPatientId === patientToDo?.id}
          toggleIsTracking={() => stopwatchClick()}
          disabled={!trackingPatientId || !trackingActivityId}
          elapsedTimeInSeconds={patientIdTimeMap.get(patientToDo?.id)}
          displayTime
        />
      </div>
      {!loadingToDos && !patientToDo ? (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center font-size-big">
          Great job. There are no pending To Dos
        </div>
      ) : (
        <>
          <div className="h-100 d-flex align-items-center pl-3">
            <PatientProfileRing
              currentPatient={{ ...patient, avatar_status: patientToDo?.avatar_status }}
            />
          </div>
          <div className="dashboard-patient-detail-profile pt-0">
            <PatientDetail currentPatient={patient} showActive={false} />
          </div>

          {(patientToDo as IDashboardToDoSingle)?.alerts?.map((item, index) => (
            <PatientEvent key={index} event={item} patientId={patient.id} />
          ))}
        </>
      )}
    </div>
  );
};
export default PatientToDo;
