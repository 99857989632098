import Notes from 'components/Notes';
import { useFetchList } from 'hooks';
import React, { useMemo } from 'react';
import { getAllQuestionnaries } from 'services/questionnaireService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { ActionModalType } from '../../CarePlanActionModal';
import EventType from './components/EventType';
import RecurrentOptions from './components/RecurrentOptions';
import './styles.css';

interface IActionModalBody {
  data: IPatientCarePlanAction;
  setData: (updatedSelectedAction: IPatientTimelineAction) => void;
  modalType: ActionModalType;
  relativeMinDate: Date;
  patientId: number;
}
const ActionModalBody = ({
  data,
  setData,
  modalType,
  relativeMinDate,
  patientId,
}: IActionModalBody) => {
  const [allQuestionnaires] = useFetchList(getAllQuestionnaries);

  const questionnaireList = useMemo(() => {
    if (modalType === 'patient') {
      return allQuestionnaires.filter((questionnaire) => (questionnaire.patient_id = patientId));
    } else {
      return allQuestionnaires.filter((questionnaire) => questionnaire.template_id);
    }
  }, [modalType, allQuestionnaires, patientId]);

  return (
    <div className="font-size-big">
      <div className="body-element-container-first">
        <EventType
          setData={setData}
          data={data}
          questionnaireList={questionnaireList}
          modalType={modalType}
          relativeMinDate={relativeMinDate}
        />
      </div>
      {data.event_type !== 'goal' && (
        <div className="body-element-container-middle">
          <RecurrentOptions data={data} setData={setData} modalType={modalType} />
        </div>
      )}
      <div className="body-element-container-last">
        <Notes
          data={data}
          setData={setData}
          questionnaireList={questionnaireList}
          modalType={modalType}
        />
      </div>
    </div>
  );
};
export default ActionModalBody;
