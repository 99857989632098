import { ErrorMessage } from 'formik';
import { FC } from 'react';
import styles from './styles.module.css';

interface ProviderTierListValidationErrorProps {
  name: string;
}

const ProviderTierListValidationError: FC<ProviderTierListValidationErrorProps> = ({
  name,
}: ProviderTierListValidationErrorProps) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <div className={`${styles['error-background']} rounded w-100`}>
          <div className={`${styles['error-text']} font-size-medium px-3 py-2`}>{msg}</div>
        </div>
      )}
    </ErrorMessage>
  );
};
export default ProviderTierListValidationError;
