import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import { PaginatedResponse } from 'types/ApiModels/General';
import Patient from 'types/ApiModels/Patients/Patient';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PatientAction, PatientActionTypes } from './patientsActionTypes';

export interface AdminPatientsState {
  patientsBook: Record<number, PaginatedResponse<PatientGet>>;
  showInvitePatient: boolean;
  selectedPatient: PatientGetSingle;
  submitSinglePatientForm: () => void;
  resetSinglePatientForm: (state?: { values?: PatientManagementFormikValues }) => void;
  archivePatient: () => void;
  singlePatientFormDirty: boolean;
}

export const patientsInitialState: AdminPatientsState = {
  patientsBook: null,
  selectedPatient: null,
  showInvitePatient: false,
  archivePatient: () => undefined,
  resetSinglePatientForm: () => undefined,
  singlePatientFormDirty: false,
  submitSinglePatientForm: () => undefined,
};

const adminPatientsReducer = (
  state: AdminPatientsState,
  action: PatientAction
): AdminPatientsState => {
  switch (action.type) {
    case PatientActionTypes.TOGGLE_SHOW_INVITE_PATIENT: {
      return { ...state, showInvitePatient: !state.showInvitePatient };
    }
    case PatientActionTypes.SET_PATIENTS_BOOK: {
      return { ...state, patientsBook: action.payload.patientsBook };
    }
    case PatientActionTypes.SET_SELECTED_PATIENT: {
      return { ...state, selectedPatient: action.payload.patient };
    }
    case PatientActionTypes.SET_SINGLE_PATIENT_ARCHIVE: {
      return { ...state, archivePatient: action.payload.archivePatient };
    }
    case PatientActionTypes.SET_SINGLE_PATIENT_FORM_DIRTY: {
      return { ...state, singlePatientFormDirty: action.payload.dirty };
    }
    case PatientActionTypes.SET_SINGLE_PATIENT_SUBMIT_FORM: {
      return { ...state, submitSinglePatientForm: action.payload.submitForm };
    }
    case PatientActionTypes.SET_SINGLE_PATIENT_RESET_FORM: {
      return { ...state, resetSinglePatientForm: action.payload.resetForm };
    }
    default:
      return state;
  }
};

export default adminPatientsReducer;
