import { useCallback, useEffect, useRef, useState } from 'react';
import { getYellowAlerts } from 'services/dashboardService';
import DashboardYellowAlert from 'types/ApiModels/Dashboard/DashboardYellowAlert';
import DatePickerValue from 'types/Shared/DatePicker';
import { setYellowAlerts } from '../DashboardActionCreators';
import { useDashboardContext } from '../DashboardContext';

const useDashboardYellowAlerts = (
  dateFrom: DatePickerValue,
  dateTo: DatePickerValue
): [DashboardYellowAlert[], () => void] => {
  const {
    state: { yellowAlerts },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const [yellowAlertsState, setYellowAlertsState] = useState<DashboardYellowAlert[]>([]);

  const previousDateFrom = useRef(dateFrom);
  const previousDateTo = useRef(dateTo);
  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    previousDateFrom.current = { year: null, month: null, day: null };
    previousDateTo.current = { year: null, month: null, day: null };
    fetchRegistry.current.yellowAlerts.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchYellowAlerts = useCallback(async () => {
    if (!dateFrom || !dateTo) return;
    const data = await getYellowAlerts(
      `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`,
      `${dateTo.year}-${dateTo.month}-${dateTo.day}`
    );
    setYellowAlertsState(data);
  }, [dateFrom, dateTo]);

  const yellowAlertsInit = useCallback(async () => {
    fetchRegistry.current.yellowAlerts.list = true;
    await fetchYellowAlerts();
    fetchRegistry.current.yellowAlerts.list = false;
  }, [fetchRegistry, fetchYellowAlerts]);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    if (
      previousDateFrom.current.year != dateFrom.year ||
      previousDateFrom.current.month != dateFrom.month ||
      previousDateFrom.current.day != dateFrom.day ||
      previousDateTo.current.year != dateTo.year ||
      previousDateTo.current.month != dateTo.month ||
      previousDateTo.current.day != dateTo.day ||
      (!yellowAlerts && !fetchRegistry.current.yellowAlerts.list)
    ) {
      yellowAlertsInit();
      previousDateFrom.current = { ...dateFrom };
      previousDateTo.current = { ...dateTo };
    }
  }, [fetchRegistry, yellowAlerts, yellowAlertsInit, dateFrom, dateTo]);

  useEffect(() => {
    dispatch(setYellowAlerts(yellowAlertsState));
  }, [dispatch, yellowAlertsState]);

  return [yellowAlertsState, refetch];
};

export default useDashboardYellowAlerts;
