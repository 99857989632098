import React, { CSSProperties } from 'react';
import Button from 'components/Button';

interface ITemplateActions {
  onSave?: any;
  style?: CSSProperties;
  disabled?: boolean;
}
const TemplateActions = ({ onSave, style, disabled }: ITemplateActions) => {
  return (
    <div>
      <Button
        style={style}
        disabled={disabled}
        onClick={() => onSave()}
        label="create from this template"
      />
    </div>
  );
};

export default TemplateActions;
