import { useAuthContext } from 'auth';
import { ControlButtonLink } from 'components/ControlButton';
import { useDebouncedSearch } from 'hooks';
import { ChangeEventHandler, Fragment, useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { searchEncounter } from 'services/encounterService';
import ConversationType from 'types/Shared/conversation-type';
import { useMessagesCenter, useTwilioConversations } from '../hooks';
import { ChatData, MockBroadcast } from '../mocks/types';
import useBroadcasts from '../store/hooks/use-broadcasts';
import useBroadcastTemplates from '../store/hooks/use-broadcasts-templates';
import { setMetadataByParticipantSID } from '../store/messages-action-creators';
import { useMessagesContext } from '../store/messages-context';
import { getConversationAttributes } from '../utils';
import Broadcast from './broadcast';
import BroadcastList from './broadcast-list';
import ListCard from './list-card';
import MessagesList from './messages-list';
import PatientMessages from './patient-messages';
import { mapToChatDataOld } from './utils';

const filterConversationFunction = (conversation) => {
  const attributes = getConversationAttributes(conversation.attributes);
  return (
    !!attributes?.conversation_type &&
    attributes?.conversation_type !== ConversationType.CareTeamMainThread &&
    attributes?.conversation_type !== ConversationType.CareTeamSubThread
  );
};

const MessagesContent = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { state, dispatch } = useMessagesContext();
  const {
    conversations,
    loadMoreConversations,
    metadataCache,
    identityByParticipantSID,
    areConversationsLoading,
    isTwilioLoading,
  } = useMessagesCenter(filterConversationFunction);
  const { currentUser } = useAuthContext();

  const searchEncounterCb = useCallback((search: string) => searchEncounter({ search }), []);

  const {
    search: conversationSearchQuery,
    handleSearch: handleConversationsSearch,
    mappedSearchResult: encounterSearchResult,
    isSearching,
  } = useDebouncedSearch(searchEncounterCb);

  const stableConversationSearchResult = useMemo(() => {
    return encounterSearchResult?.map((csr) => csr.twilio_conversation_sid) ?? [];
  }, [encounterSearchResult]);

  const {
    conversations: conversationsSearchResult,
    metadataCache: conversationSearchMetadataCache,
    areConversationsLoading: areSearchConversationsLoading,
  } = useTwilioConversations(stableConversationSearchResult, metadataCache);

  useEffect(() => {
    if (!metadataCache || !identityByParticipantSID) return;
    const getMetadataByParticipantSID = (participantSID: string) =>
      metadataCache[identityByParticipantSID[participantSID]];
    dispatch(setMetadataByParticipantSID(getMetadataByParticipantSID));
  }, [metadataCache, identityByParticipantSID, dispatch]);

  const searchResultChats = useMemo(() => {
    return conversationsSearchResult.map<ChatData>(
      mapToChatDataOld(conversationSearchMetadataCache, currentUser?.twilio_identity)
    );
  }, [conversationSearchMetadataCache, conversationsSearchResult, currentUser?.twilio_identity]);

  const chats = useMemo(() => {
    if (!conversations && !Object.keys(metadataCache)?.length) return [];
    //[participantsid]=>[identity]
    //[identity]=>[metadata]
    return conversations?.map(mapToChatDataOld(metadataCache, currentUser?.twilio_identity)) ?? [];
  }, [conversations, currentUser?.twilio_identity, metadataCache]);

  //broadcasts n templates
  const [{ broadcasts: recordBroadcasts }] = useBroadcasts();
  const [{ broadcastTemplates: recordBroadcastTemplates }] = useBroadcastTemplates();
  const broadcasts: MockBroadcast[] = useMemo(() => {
    return Object.keys(recordBroadcasts || {}).map((key) => recordBroadcasts[key]);
  }, [recordBroadcasts]);
  const broadcastsTemplates: MockBroadcast[] = useMemo(() => {
    return Object.keys(recordBroadcastTemplates || {}).map((key) => recordBroadcastTemplates[key]);
  }, [recordBroadcastTemplates]);
  //end broadcasts n templates

  //todo: refactor... this is horrendous...
  const cardItems: {
    title: string;
    counter: number;
    searchPlaceholder: string;
    searchValue?: string;
    handleSearch?: ChangeEventHandler<HTMLInputElement>;
  } = useMemo(() => {
    if (pathname === '/messages') {
      return {
        title: 'Messages',
        counter: conversations.reduce((res, c) => res + (c?.unreadCount ?? 0), 0),
        searchPlaceholder: 'Search conversations',
        searchValue: conversationSearchQuery,
        handleSearch: handleConversationsSearch,
      };
    } else if (pathname === '/messages/broadcast') {
      return {
        title: 'Broadcasts',
        counter: broadcasts.length,
        searchPlaceholder: 'Search past broadcasts',
      };
    } else if (pathname === '/messages/broadcast/templates') {
      return {
        title: 'Templates',
        counter: broadcastsTemplates.length,
        searchPlaceholder: 'Search broadcast templates',
      };
    }
  }, [
    broadcasts.length,
    broadcastsTemplates.length,
    conversationSearchQuery,
    conversations,
    handleConversationsSearch,
    pathname,
  ]);

  const broadcastList = useMemo(() => {
    if (pathname === '/messages/broadcast') {
      return broadcasts;
    } else if (pathname === '/messages/broadcast/templates') {
      return broadcastsTemplates;
    }
    return [];
  }, [pathname, broadcasts, broadcastsTemplates]);

  const openedBroadcastId = useMemo(() => {
    if (pathname === '/messages/broadcast') {
      return state?.openedBroadcast?.id;
    } else if (pathname === '/messages/broadcast/templates') {
      return state?.openedBroadcastTemplate?.id;
    }
    return null;
  }, [pathname, state.openedBroadcast, state.openedBroadcastTemplate]);

  return (
    <Fragment>
      <Row className="pt-3">
        <Col md="3">
          <Row className="gap mb-3">
            <ControlButtonLink to={`/messages`} exact>
              Patient messages
            </ControlButtonLink>
            <ControlButtonLink to={`/messages/broadcast`}>Broadcast messages</ControlButtonLink>
          </Row>
          <Row>
            <ListCard
              title={cardItems.title}
              counter={cardItems.counter}
              searchPlaceholder={cardItems.searchPlaceholder}
              searchValue={cardItems?.searchValue}
              handleSearch={cardItems?.handleSearch}
            >
              <Switch>
                <Route path={`${path}/broadcast`}>
                  <BroadcastList broadcasts={broadcastList} openedBroadcastId={openedBroadcastId} />
                </Route>
                <Route path={`${path}`} exact>
                  <MessagesList
                    chats={conversationSearchQuery ? searchResultChats : chats}
                    areChatsLoading={
                      areConversationsLoading ||
                      isTwilioLoading ||
                      isSearching ||
                      areSearchConversationsLoading
                    }
                  />
                </Route>
              </Switch>
            </ListCard>
          </Row>
        </Col>
        <Switch>
          <Route path={`${path}/broadcast`} component={Broadcast} />
          <Route path={`${path}`} exact component={PatientMessages} />
        </Switch>
      </Row>
    </Fragment>
  );
};
export default MessagesContent;
