import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getAnalyticsAlerts } from 'services/analyticsService';
import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import DatePickerValue from 'types/Shared/DatePicker';
import { setAlerts } from '../AnalyticsActionCreators';
import { AnalyticsAction } from '../AnalyticsActionTypes';
import { useAnalyticsContext } from '../AnalyticsContext';

const useAlerts = (
dateFrom: DatePickerValue,
dateTo: DatePickerValue
): [Alerts[], Dispatch<AnalyticsAction>, () => void] => {
  const {
    state: { alerts },
    dispatch,
    fetchRegistry,
  } = useAnalyticsContext();

  const [alertsState, setAlertsState] = useState<Alerts[]>([]);

  const previousDateFrom = useRef(dateFrom);
  const previousDateTo = useRef(dateTo);
  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    previousDateFrom.current = { year: null, month: null, day: null };
    previousDateTo.current = { year: null, month: null, day: null };
    fetchRegistry.current.alerts.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchAlerts = useCallback(async () => {
    if (!dateFrom || !dateTo) return;
    const data = await getAnalyticsAlerts(
      `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`,
      `${dateTo.year}-${dateTo.month}-${dateTo.day}`
    );
    setAlertsState(data);
  }, [dateFrom, dateTo]);

  const alertsInit = useCallback(async () => {
    fetchRegistry.current.alerts.list = true;
    await fetchAlerts();
    fetchRegistry.current.alerts.list = false;
  }, [fetchRegistry, fetchAlerts]);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    if (
      previousDateFrom.current.year != dateFrom.year ||
      previousDateFrom.current.month != dateFrom.month ||
      previousDateFrom.current.day != dateFrom.day ||
      previousDateTo.current.year != dateTo.year ||
      previousDateTo.current.month != dateTo.month ||
      previousDateTo.current.day != dateTo.day ||
      (!alerts && !fetchRegistry.current.alerts.list)
    ) {
        alertsInit();
      previousDateFrom.current = { ...dateFrom };
      previousDateTo.current = { ...dateTo };
    }
  }, [fetchRegistry, alerts, alertsInit, dateFrom, dateTo,  _refetch.current]);

  useEffect(() => {
    dispatch(setAlerts(alertsState));
  }, [dispatch, alertsState]);

  return [alertsState, dispatch, refetch];
};

export default useAlerts;
