import { OptionsInfo } from './providerProfileReducer';

export enum ProviderProfileActionTypes {
  SET_PROVIDER_PROFILE_FORM_RESET = 'SET_PROVIDER_PROFILE_FORM_RESET',
  SET_PROVIDER_PROFILE_FORM_DIRTY = 'SET_PROVIDER_PROFILE_FORM_DIRTY',
  SET_PROVIDER_PROFILE_SUBMIT_FORM = 'SET_PROVIDER_PROFILE_SUBMIT_FORM',
  SET_PROVIDER_PROFILE_ACTION = 'SET_PROVIDER_PROFILE_ACTION',
  SET_OPTIONS_INFO = 'SET_OPTIONS_INFO',
}

export type ProviderProfileAction =
  | {
      type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_ACTION;
      payload: { action: () => JSX.Element };
    }
  | {
      type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_SUBMIT_FORM;
      payload: { submitForm: () => void };
    }
  | {
      type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_FORM_DIRTY;
      payload: { dirty: boolean };
    }
  | {
      type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_FORM_RESET;
      payload: { resetForm: () => void };
    }
  | {
      type: ProviderProfileActionTypes.SET_OPTIONS_INFO;
      payload: { optionsInfo: OptionsInfo };
    };
