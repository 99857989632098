import { RepetitionType } from 'types/ApiModels/CarePlan/RepetitionType';

export enum RepetitionTypeLabel {
  EVERY_DAY = 'Every Day',
  EVERY_WEEK = 'Every Week',
  EVERY_MONTH = 'Every Month',
  // TIME_PERIOD = 'Time Period',
  START_OF_ADHERENCE_BLOCK = 'Start Of Adherence Block',
  BEFORE_START_OF_ADHERENCE_BLOCK = 'Before Start Of Adherence Block',
}

export const mapLabelToRepetitionType: (label: RepetitionTypeLabel) => RepetitionType = (label) => {
  switch (label) {
    case RepetitionTypeLabel.EVERY_DAY:
      return 'every_day';
    case RepetitionTypeLabel.EVERY_WEEK:
      return 'every_week';
    case RepetitionTypeLabel.EVERY_MONTH:
      return 'every_month';
    // case RepetitionTypeLabel.TIME_PERIOD:
    // return 'time_period';
    case RepetitionTypeLabel.START_OF_ADHERENCE_BLOCK:
      return 'start_of_adherence_block';
    case RepetitionTypeLabel.BEFORE_START_OF_ADHERENCE_BLOCK:
      return 'before_start_of_adherence_block';
    default:
      return null;
  }
};

export const mapRepetitionTypeToLabel: (repetitionType: RepetitionType) => string = (
  recurrenceType
) => {
  switch (recurrenceType) {
    case 'every_day':
      return RepetitionTypeLabel.EVERY_DAY;
    case 'every_week':
      return RepetitionTypeLabel.EVERY_WEEK;
    case 'every_month':
      return RepetitionTypeLabel.EVERY_MONTH;
    // case 'time_period':
    // return RepetitionTypeLabel.TIME_PERIOD;
    case 'start_of_adherence_block':
      return RepetitionTypeLabel.START_OF_ADHERENCE_BLOCK;
    case 'before_start_of_adherence_block':
      return RepetitionTypeLabel.BEFORE_START_OF_ADHERENCE_BLOCK;
    default:
      return null;
  }
};
