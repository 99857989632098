import Button from 'components/Button';
import { useCallback } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import { useAdminPatients } from 'screens/Administration/store/hooks';
import { toggleShowInvitePatient } from 'screens/Administration/store/Patients';
import { setPatientSelected } from 'screens/Administration/store/Patients/patientsActionCreators';
import { PatientFormikContext } from 'screens/Patients/store/PatientFormikContext';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import AdminPatientDetails from './AdminPatientDetails';
import AdminPatientsMain from './AdminPatientsMain';

export const AdminPatientsActions = () => {
  const { dispatch } = useAdministrationContextV2();
  return (
    <div className={`d-flex gap`}>
      <Button label="export patients list" variant="dull" onClick={() => null} />
      <Button
        label="add new patient"
        onClick={() => {
          dispatch(toggleShowInvitePatient());
        }}
      />
    </div>
  );
};

const AdminPatients = () => {
  const { path } = useRouteMatch();
  const [{ selectedPatient }, , dispatch] = useAdminPatients();

  const dispatchSelectedPatient = useCallback(
    (p: PatientGetSingle) => {
      dispatch(setPatientSelected(p));
    },
    [dispatch]
  );

  return (
    <PatientFormikContext.Provider
      value={{
        selectedPatient,
        setSelectedPatient: dispatchSelectedPatient,
      }}
    >
      <div className="pt-4 px-4">
        <Route exact path={path}>
          <AdminPatientsMain />
        </Route>
        <Route path={`${path}/:patientId`}>
          <AdminPatientDetails />
        </Route>
      </div>
    </PatientFormikContext.Provider>
  );
};

export default AdminPatients;
