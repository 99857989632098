import React, { CSSProperties, useEffect } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import styles from './styles.module.css';
import moment from 'moment';
import HourFooter from './components/HourFooter';
import CalendarPicker from '../CalendarPicker';
import DatePickerValue from 'types/Shared/DatePicker';

interface IDatePicker {
  placeHolder?: string;
  value: DatePickerValue;
  onChange: (newDate: DatePickerValue) => void;
  minimumDate?: DatePickerValue;
  disabled?: boolean;
  format?: string;
  style?: CSSProperties;
  inputClassName?: string;
  containerClassName?: string;
  hasFooter?: boolean;
  closeOnSelect?: boolean;
}

export const DatePicker = ({
  placeHolder = '',
  value,
  onChange,
  minimumDate = null,
  disabled = false,
  format = 'ddd, MMM DD h:mm A',
  style = {},
  inputClassName = '',
  containerClassName = '',
  hasFooter = true,
  closeOnSelect = false,
}: IDatePicker) => {
  //States
  const [inputVal, setInputVal] = React.useState('');
  const [displayCalendar, setDisplayCalendar] = React.useState(false);
  const [amPm, setAmPm] = React.useState<'AM' | 'PM'>(null);

  const calendarRef = React.useRef(null);

  useEffect(() => {
    if (!value) return;

    const parsedHours = amPm === 'AM' ? value.hours % 12 : (value.hours % 12) + 12;
    const newVal = moment(`${value.year}/${value.month}/${value.day}`)
      .startOf('day')
      .add(parsedHours, 'hours')
      .add(value.minutes, 'minutes');

    if (minimumDate) {
      const minVal = moment(`${minimumDate.year}/${minimumDate.month}/${minimumDate.day}`)
        .startOf('day')
        .add(minimumDate.hours, 'hours')
        .add(minimumDate.minutes, 'minutes');

      if (newVal.isBefore(minVal)) return;
    }
    setInputVal(newVal.format(format));
  }, [value, amPm, format, minimumDate]);

  useEffect(() => {
    if (!value) return;
    if (!amPm) {
      // set initial value
      value.hours <= 12 ? setAmPm('AM') : setAmPm('PM');
      return;
    }

    if (amPm === 'AM') {
      onChange({ ...value, hours: value.hours % 12 });
    } else {
      onChange({ ...value, hours: (value.hours % 12) + 12 });
    }
  }, [amPm]);

  //Event listener to click outside of calendar
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setDisplayCalendar(false);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  const onHoursChange = (hourValue: number) => {
    onChange({ ...value, hours: amPm === 'AM' ? hourValue : hourValue + 12 });
  };

  const onMinutesChange = (minutesValue: number) => {
    onChange({ ...value, minutes: minutesValue });
  };

  return (
    <div
      className={`${
        containerClassName ? containerClassName : 'd-flex flex-row justify-content-between'
      }`}
      style={style}
      onClick={() => !displayCalendar && !disabled && setDisplayCalendar(true)}
    >
      <input
        type="text"
        className={`${inputClassName ? inputClassName : ''} ${styles.dayPickerInput}`}
        placeholder={placeHolder}
        value={inputVal}
        readOnly
        disabled={disabled}
      />
      <label className={styles.calendarIcon}></label>
      <div
        style={{
          position: 'absolute',
          zIndex: 99,
          left: '15%',
          display: displayCalendar ? 'block' : 'none',
        }}
        ref={calendarRef}
      >
        <CalendarPicker
          value={value && { day: value.day, month: value.month, year: value.year }}
          onChange={(newValue) => {
            onChange({ ...newValue, hours: value?.hours, minutes: value?.minutes });
            closeOnSelect && setDisplayCalendar(false);
          }}
          renderFooter={() =>
            hasFooter && (
              <HourFooter
                label={'Start time'}
                hours={value.hours}
                onHoursChange={onHoursChange}
                minutes={value.minutes}
                onMinutesChange={onMinutesChange}
                amPm={amPm}
                setAmPm={setAmPm}
              />
            )
          }
          minimumDate={minimumDate}
        />
      </div>
    </div>
  );
};
export default DatePicker;
