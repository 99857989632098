import { Col, Row} from 'react-bootstrap';
import TotalPerYear from './Components/TotalPerYear';
import PatientAdmitted from './Components/PatientsAdmitted';
import PatientDropped from './Components/PatientsDropped';
import Patients from './Components/Patients';
import NewPatients from './Components/NewPatients';
import PatientDevices from './Components/PatientDevices';
import TotalPerMonth from './Components/TotalPerMonth';
import PatientsAccrued from './Components/PatientsAccrued';

const PatientStatusTab = () => {
  return (
    <div>
      <Row>
        {/* <Col className="my-3" lg={3}>
          <TotalPerMonth></TotalPerMonth>
        </Col>
        <Col className="my-3" lg={3}>
          <TotalPerYear></TotalPerYear>
        </Col>
        <Col className="my-3" lg={3}>
          <PatientAdmitted></PatientAdmitted>
        </Col>
        <Col className="my-3" lg={3}>
          <PatientDropped></PatientDropped>
        </Col> */}
        <Col className="my-3" lg={6}>
          <Patients></Patients>
        </Col>
        <Col className="my-3" lg={4}>
          <NewPatients></NewPatients>
        </Col>
        <Col className="my-3" lg={6}>
         <PatientDevices></PatientDevices>
        </Col>
        <Col className="my-3" lg={4}>
          <PatientsAccrued></PatientsAccrued>
        </Col>
      </Row>
    </div>
  );
};

export default PatientStatusTab;
