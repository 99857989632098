import { vytracAxios } from 'ajax';
import { VytalResponse, VytalType } from 'types/ApiModels/Patients/Vytal';

export const getVytals = async (patientId: number, goal: VytalType, days: number) => {
  try {
    const response = await vytracAxios.get<VytalResponse[]>(
      `vytals/?patient=${patientId}&days=${days}&type=${goal}`
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};
