import { PaginatedResponse } from 'types/ApiModels/General';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { ProviderAction as ProviderAction, ProviderActionTypes } from './providersActionTypes';

export const setProvidersBook = (
  providersBook: Record<number, PaginatedResponse<Provider>>
): ProviderAction => ({
  type: ProviderActionTypes.SET_PROVIDERS_BOOK,
  payload: { providersBook },
});

/**
 * Adds new pages if there were new fetched to the table but leaves unmodified existing values in the table. This way we preserve changes
 * @param providersBook The most recent providers book value
 */
export const setProvidersBookAndMatchTable = (providersBook): ProviderAction => ({
  type: ProviderActionTypes.SET_PROVIDERS_BOOK_AND_MATCH_TABLE,
  payload: { providersBook },
});

export const appendProvidersBookPage = (providersPage: PaginatedResponse<Provider>) => ({
  type: ProviderActionTypes.APPEND_PROVIDERS_BOOK_PAGE,
  payload: { providersPage },
});

export const toggleProviderActive = (providerId: number): ProviderAction => ({
  type: ProviderActionTypes.TOGGLE_PROVIDER_ACTIVE,
  payload: { providerId },
});

export const setResetProviderForm = (resetForm: () => void): ProviderAction => ({
  type: ProviderActionTypes.SET_PROVIDERS_RESET_FORM,
  payload: { resetForm },
});

export const setSubmitProviderForm = (submitForm): ProviderAction => ({
  type: ProviderActionTypes.SET_PROVIDERS_SUBMIT_FORM,
  payload: { submitForm },
});

export const resetUpdatedProviders = (): ProviderAction => ({
  type: ProviderActionTypes.RESET_UPDATED_PROVIDERS,
});

export const resetDeletedProviders = (): ProviderAction => ({
  type: ProviderActionTypes.RESET_DELETED_PROVIDERS,
});

export const toggleShowInviteProvider = (): ProviderAction => ({
  type: ProviderActionTypes.TOGGLE_SHOW_INVITE_PROVIDER,
});

export const setSelectedProvider = (provider?: Provider): ProviderAction => ({
  type: ProviderActionTypes.SET_SELECTED_PROVIDER,
  payload: { provider },
});

export const setSubmitSingleProviderForm = (submitSingleProvider): ProviderAction => ({
  type: ProviderActionTypes.SET_SUBMIT_SINGLE_PROVIDER_FORM,
  payload: { submitSingleProvider },
});

export const setResetSingleProviderForm = (
  resetSingleProviderForm: () => void
): ProviderAction => ({
  type: ProviderActionTypes.SET_RESET_SINGLE_PROVIDER_FORM,
  payload: { resetSingleProviderForm },
});

export const setSingleProviderFormDirty = (singleProviderFormDirty: boolean): ProviderAction => ({
  type: ProviderActionTypes.SET_SINGLE_PROVIDER_FORM_DIRTY,
  payload: { singleProviderFormDirty },
});

export const setRemoveSingleProvider = (removeSingleProvider: () => void): ProviderAction => ({
  type: ProviderActionTypes.SET_SINGLE_PROVIDER_REMOVE,
  payload: { removeSingleProvider },
});

export const removeProviderFromBook = (providerId: number): ProviderAction => ({
  type: ProviderActionTypes.REMOVE_PROVIDER_FROM_BOOK,
  payload: { providerId },
});

export const setProvidersTableBook = (
  tableBook: Record<number, PaginatedResponse<Provider>>
): ProviderAction => ({
  type: ProviderActionTypes.SET_PROVIDERS_TABLE_BOOK,
  payload: { tableBook },
});

export const matchProviderBooks = (): ProviderAction => ({
  type: ProviderActionTypes.MATCH_PROVIDERS_TABLE_BOOK_WITH_BOOK,
});

export const updateProviderTablePage = (page: number): ProviderAction => ({
  type: ProviderActionTypes.UPDATE_PROVIDER_TABLE_PAGE,
  payload: { page },
});

export const updateProviderTableIsActive = (providerId: number, page: number): ProviderAction => ({
  type: ProviderActionTypes.UPDATE_PROVIDER_IS_ACTIVE,
  payload: { providerId, page },
});

export const updateProviderTableDelete = (providerId: number, page: number): ProviderAction => ({
  type: ProviderActionTypes.UPDATE_PROVIDER_DELETE,
  payload: { page, providerId },
});

export const updateCurrentBookPage = (page: number): ProviderAction => ({
  type: ProviderActionTypes.UPDATE_CURRENT_BOOK_PAGE,
  payload: { page },
});
