import React from 'react';
import { usePagination } from '../Context';
import {
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPreviousPage,
} from '../store/action-creators';
import styles from './styles.module.css';

interface IPaginationControls {
  disablePagination?: boolean;
}
const PaginationControls = ({ disablePagination = false }: IPaginationControls) => {
  const { paginationState, paginationDispatch } = usePagination();

  const { currentPageIndex, maxPage } = paginationState;

  // Functions and subroutines
  const handleFirstButtonClick = () => paginationDispatch(goToFirstPage());

  const handlePreviousButtonClick = () => paginationDispatch(goToPreviousPage());

  const handleNextButtonClick = () => paginationDispatch(goToNextPage());

  const handleLastButtonClick = () => paginationDispatch(goToLastPage());

  return (
    <div className={styles.main}>
      <button
        disabled={currentPageIndex === 0 || disablePagination}
        onClick={handleFirstButtonClick}
      >
        first
      </button>
      <button
        disabled={currentPageIndex === 0 || disablePagination}
        onClick={handlePreviousButtonClick}
      >
        previous
      </button>
      <span>
        {currentPageIndex + 1} of {maxPage + 1} pages
      </span>
      <button
        disabled={currentPageIndex === maxPage || disablePagination}
        onClick={handleNextButtonClick}
      >
        next
      </button>
      <button
        disabled={currentPageIndex === maxPage || disablePagination}
        onClick={handleLastButtonClick}
      >
        last
      </button>
    </div>
  );
};

export { default as PaginationControlsAlt } from './PaginationControlsAlt';
export default PaginationControls;
