import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import VyTracLayout from 'components/VyTracLayout';
import Content from './components/Content';
import Breadcrumbs from '../../components/Breadcrumbs';
import ButtonGroup from './components/ButtonGroup';
import styles from './styles.module.css';
import VyTracModal from '../../components/Modal';
import Button from '../../components/Button';
import {
  createSchedule,
  createScheduleActiveProps,
  deleteSchedule,
  getActiveScheduleProps,
  updateSchedule,
} from '../../services/scheduleService';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ActiveScheduleContext, {
  useActiveScheduleContext,
} from './components/Content/components/FirstColumn/components/ActiveSchedule/store/context/ActiveScheduleContext';
import activeScheduleReducer, {
  activeScheduleInitialState,
  IActiveScheduleState,
} from './components/Content/components/FirstColumn/components/ActiveSchedule/store/reducers/ActiveScheduleReducer';
import { useAuthContext } from 'auth';
import { setActiveScheduleProps } from './components/Content/components/FirstColumn/components/ActiveSchedule/store/actions/actionCreators';
import { convertDateToDateTime } from 'util/dateUtils';
import { setBreadcrumTitle } from 'util/themeUtils';

class ISchedule {
  id = '';
  name = '';
  weekToggles?: any = [false, false, false, false, false, false, false];
  dayShift?: boolean = false;
  secondShift?: boolean = false;
  thirdShift?: boolean = false;
  selectedHours?: [] = [];
  new?: boolean = false;
}

export interface IActiveScheduleProps {
  userId: string;
  scheduleId: string;
  name: string;
  activeUntil: boolean;
  endDate?: Date;
  vacationMode: boolean;
  startDateVacation?: Date;
  endDateVacation?: Date;
  vacationMessage?: string;
  outOfOffice: boolean;
  outOfOfficeDate?: Date;
  outOfOfficeMessage: string;
}

const Schedule = () => {
  const breadcrumbs = [
    {
      title: `${setBreadcrumTitle()} Dashboard`,
      url: '/dashboard',
    },
    {
      title: 'Schedule',
      url: '/schedule',
    },
  ];

  const initialState = useRef(null);

  const initialStateActiveSchedule = useRef(null);

  const { currentUser } = useAuthContext();

  const [activeScheduleState, dispatch] = useReducer(
    activeScheduleReducer,
    activeScheduleInitialState
  );

  const [unSavedChanges, setUnSavedChanges] = useState(false);
  const [isRemovable, setIsRemovable] = useState(true);
  const [activeSchedule, setActiveSchedule] = useState<ISchedule>({
    id: '',
    name: '',
  });
  const [newSchedule, setNewSchedule] = useState<ISchedule>({
    id: '',
    name: '',
    weekToggles: [false, false, false, false, false, false, false],
    dayShift: false,
    secondShift: false,
    thirdShift: false,
    selectedHours: [],
    new: false,
  });
  const [refetch, setRefetch] = useState(false);
  const deleteCancel = useRef(null);

  const sendRequestActiveSchedule = async () => {
    if (currentUser?.id) {
      const request = await getActiveScheduleProps(currentUser.id);
      const activeScheduleProps: IActiveScheduleState = {
        scheduleId: request.scheduleId,
        name: request.name,
        outOfOffice: request.outOfOffice,
        outOfOfficeDate: convertDateToDateTime(request.outOfOfficeDate),
        outOfOfficeMessage: request.outOfOfficeMessage,
        activeUntil: request.activeUntil,
        activeUntilDate: convertDateToDateTime(request.activeUntilDate),
        vacationMode: request.vacationMode,
        startDateVacation: convertDateToDateTime(request.startDateVacation),
        endDateVacation: convertDateToDateTime(request.endDateVacation),
        vacationMessage: request.vacationMessage,
      };
      initialStateActiveSchedule.current = activeScheduleProps;
      dispatch(setActiveScheduleProps(activeScheduleProps));
    }
  };

  useEffect(() => {
    //TODO: replace with actual condition
    if (activeSchedule?.id && activeSchedule?.id === '1') {
      setIsRemovable(false);
    } else {
      setIsRemovable(true);
    }
    initialState.current = activeSchedule;
  }, [activeSchedule]);

  useEffect(() => {
    // Get user and send Request for Active Schedule
    currentUser?.id && sendRequestActiveSchedule();
  }, [currentUser]);

  const compareActiveShedules = (
    initialSchedule: IActiveScheduleState,
    schedule: IActiveScheduleState
  ) => {
    if (!initialSchedule || !schedule) return false;
    if (initialSchedule.activeUntil !== schedule.activeUntil) return false;
    if (initialSchedule.endDateVacation !== schedule.endDateVacation) return false;
    if (initialSchedule.vacationMode !== schedule.vacationMode) return false;
    if (initialSchedule.startDateVacation !== schedule.startDateVacation) return false;
    if (initialSchedule.endDateVacation !== schedule.endDateVacation) return false;
    if (initialSchedule.vacationMessage !== schedule.vacationMessage) return false;
    if (initialSchedule.outOfOffice !== schedule.outOfOffice) return false;
    if (initialSchedule.outOfOfficeDate !== schedule.outOfOfficeDate) return false;
    if (initialSchedule.outOfOfficeMessage !== schedule.outOfOfficeMessage) return false;

    return true;
  };

  // active save button on change some activeSchedule Prop
  useEffect(() => {
    setUnSavedChanges(
      !compareActiveShedules(initialStateActiveSchedule.current, activeScheduleState)
    );
  }, [activeScheduleState]);

  const validationSchema = Yup.object().shape({
    scheduleName: Yup.string().required('Schedule name is required'),
  });

  const onCancel = () => {
    setNewSchedule(initialState.current);
    setUnSavedChanges(false);
    setShowCancelDeleteModal(false);
  };

  const onSave = async () => {
    let newData = new ISchedule();
    if (!newSchedule.name) {
      return;
    }
    try {
      if (!newSchedule.new) {
        newData = await updateSchedule(newSchedule.id, newSchedule);
      } else {
        newData = await createSchedule(newSchedule);
      }
      const newActiveScheduleProps: IActiveScheduleProps = {
        userId: currentUser.id.toString(),
        scheduleId: newData.id,
        name: newSchedule.name,
        activeUntil: activeScheduleState.activeUntil,
        endDate: activeScheduleState.activeUntilDate,
        vacationMode: activeScheduleState.vacationMode,
        startDateVacation: activeScheduleState.startDateVacation,
        endDateVacation: activeScheduleState.endDateVacation,
        vacationMessage: activeScheduleState.vacationMessage,
        outOfOffice: activeScheduleState.outOfOffice,
        outOfOfficeDate: activeScheduleState.outOfOfficeDate,
        outOfOfficeMessage: activeScheduleState.outOfOfficeMessage,
      };
      const newActiveSchedule = await createScheduleActiveProps(newActiveScheduleProps);

      setRefetch(!refetch);
      initialState.current = newData;
      initialStateActiveSchedule.current = newActiveSchedule;
      setActiveSchedule(newData);
      setNewSchedule(newData);
      setUnSavedChanges(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async () => {
    const newData = new ISchedule();
    try {
      await deleteSchedule(newSchedule.id);
      setActiveSchedule(newData);
      setUnSavedChanges(false);
      setRefetch(!refetch);
      setShowCancelDeleteModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onCreateNew = () => {
    const newSchedule = new ISchedule();
    newSchedule.new = true;
    setNewSchedule(newSchedule);
    setIsRemovable(false);
    setUnSavedChanges(true);
  };

  const [showCancelDeleteModal, setShowCancelDeleteModal] = useState(false);
  const deleteCancelBody = (type) => (
    <div className={styles.modalBody}>
      {type === 'delete'
        ? 'Are you sure you want to delete this schedule?'
        : 'You have unsaved changes. Are you sure you want to discard them?'}
    </div>
  );

  const deleteCancelFooter = (type) => (
    <div className={styles.modalFooter}>
      <Button
        label="cancel"
        variant="cancel"
        className={styles.button}
        onClick={() => setShowCancelDeleteModal(false)}
      />
      <Button
        label={type === 'cancel' ? 'discard' : 'delete'}
        variant="delete"
        className={styles.button}
        onClick={() => (type === 'cancel' ? onCancel() : onDelete())}
      />
    </div>
  );

  //TODO FIX VALIDATION
  return (
    <ActiveScheduleContext.Provider value={{ activeScheduleState, dispatch }}>
      <VyTracLayout selectedRoute="schedule">
        <Row>
          <Formik
            initialValues={newSchedule}
            enableReinitialize
            onSubmit={onSave}
            validationSchema={validationSchema}
          >
            {({ setFieldValue }) => (
              <Form className="w-100">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-grow-1">
                    <Breadcrumbs items={breadcrumbs} />
                  </div>
                  <ButtonGroup
                    hasUnsavedChanges={unSavedChanges}
                    isRemovable={isRemovable}
                    onSave={() => {
                      onSave();
                    }}
                    onCancel={() => {
                      setShowCancelDeleteModal(true);
                      deleteCancel.current = 'cancel';
                    }}
                    onDelete={() => {
                      setShowCancelDeleteModal(true);
                      deleteCancel.current = 'delete';
                    }}
                    onCreateNew={() => {
                      setFieldValue('scheduleName', '');
                      onCreateNew();
                    }}
                    isNew={newSchedule.new}
                  />
                </div>
                <hr className={styles.horizontalLine} />
                <div className="py-4">
                  <Content
                    setUnSavedChanges={setUnSavedChanges}
                    setIsRemovable={setIsRemovable}
                    activeSchedule={activeSchedule}
                    setActiveSchedule={setActiveSchedule}
                    initialState={initialState.current}
                    newSchedule={newSchedule}
                    refetch={refetch}
                    setNewSchedule={setNewSchedule}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Row>
        <VyTracModal
          title={deleteCancel.current === 'cancel' ? 'Discard changes' : 'Delete Schedule'}
          show={showCancelDeleteModal}
          onClose={() => setShowCancelDeleteModal(false)}
          body={deleteCancelBody(deleteCancel.current)}
          footer={deleteCancelFooter(deleteCancel.current)}
        />
      </VyTracLayout>
    </ActiveScheduleContext.Provider>
  );
};

export default Schedule;
