import { TimePeriodOptions } from 'components/TimePeriodSelect';
import PatientTimelineResponse from 'types/ApiModels/Patients/PatientTimeline';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import { PatientTimelineAction, PatientTimelineActionTypes } from './patientTimelineActionTypes';

export interface IPatientTimelineState {
  selectedGoal: VytalType;
  patientActivities: PatientTimelineResponse[];
  selectedDate: Date;
  periodSelected: TimePeriodOptions;
}

export const patientTimelineInitialState: IPatientTimelineState = {
  selectedGoal: VytalType.WEIGHT,
  patientActivities: [],
  selectedDate: new Date(),
  periodSelected: TimePeriodOptions.WEEK,
};

const patientTimelineReducer = (
  state = patientTimelineInitialState,
  action: PatientTimelineAction
) => {
  switch (action.type) {
    case PatientTimelineActionTypes.SET_SELECTED_GOAL: {
      return { ...state, selectedGoal: action.payload.goal };
    }

    case PatientTimelineActionTypes.SET_PATIENT_ACTIVITIES: {
      return { ...state, patientActivities: action.payload.patientActivities };
    }

    case PatientTimelineActionTypes.SET_TIMLEINE_SELECTED_DATE: {
      return { ...state, selectedDate: action.payload.selectedDate };
    }

    case PatientTimelineActionTypes.SET_TIMELINE_PERIOD_SELECTED: {
      return { ...state, periodSelected: action.payload.periodSelected };
    }

    default:
      return state;
  }
};

export default patientTimelineReducer;
