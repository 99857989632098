import Card from 'components/Card';
import { PaginationControls } from 'components/Pagination';
import AuditTable from './AuditTable';
import Header from './Header';
import styles from './styles.module.css';
export const AuditTrackingActions = () => <></>;

const AuditTracking = () => (
  <div className="p-4">
    <Card
      className="bg-white"
      bodyClassName="p-0"
      headers={[
        <Header
          key="audit-tracking-header"
          auditNumber={123}
          onChange={(timePeriod) => console.log(timePeriod)}
        />,
      ]}
    >
      <AuditTable />
      <div className={styles.footer}>
        <PaginationControls />
      </div>
    </Card>
  </div>
);

export default AuditTracking;
