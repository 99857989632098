import { useMemo, useState } from 'react';

interface EditDeleteHandler {
  queueEdit: (id: number) => void;
  queueDelete: (id: number) => void;
}
export interface SurgeryFamilyAllergyHandlers {
  familyHistory: EditDeleteHandler;
  surgery: EditDeleteHandler;
  allergy: EditDeleteHandler;
}

interface SurgeryFamilyAllergyHandlersHook {
  surgeriesToDelete: number[];
  surgeriesToUpdate: number[];
  familyHistoryToDelete: number[];
  familyHistoryToUpdate: number[];
  allergiesToDelete: number[];
  allergiesToUpdate: number[];
  handlers: SurgeryFamilyAllergyHandlers;
}

const useSurgeryFamilyAllergyHandlers = (): SurgeryFamilyAllergyHandlersHook => {
  const [surgeriesToDelete, setSurgeriesToDelete] = useState([]);
  const [surgeriesToUpdate, setSurgeriesToUpdate] = useState([]);
  const [familyHistoryToDelete, setFamilyHistoryToDelete] = useState([]);
  const [familyHistoryToUpdate, setFamilyHistoryToUpdate] = useState([]);
  const [allergiesToDelete, setAllergiesToDelete] = useState([]);
  const [allergiesToUpdate, setAllergiesToUpdate] = useState([]);

  const queueDelete = (setDeleted) => (id: number) => {
    setDeleted((std) => [...std, id]);
  };

  const queueEdit = (setEdited) => (id: number) => {
    setEdited((prev) => {
      return prev.includes(id) ? prev : [...prev, id];
    });
  };

  const handlers = useMemo(
    () => ({
      familyHistory: {
        queueEdit: queueEdit(setFamilyHistoryToUpdate),
        queueDelete: queueDelete(setFamilyHistoryToDelete),
      },
      surgery: {
        queueEdit: queueEdit(setSurgeriesToUpdate),
        queueDelete: queueDelete(setSurgeriesToDelete),
      },
      allergy: {
        queueEdit: queueEdit(setAllergiesToUpdate),
        queueDelete: queueDelete(setAllergiesToDelete),
      },
    }),
    []
  );

  return {
    surgeriesToDelete,
    surgeriesToUpdate,
    familyHistoryToDelete,
    familyHistoryToUpdate,
    allergiesToDelete,
    allergiesToUpdate,
    handlers,
  };
};

export default useSurgeryFamilyAllergyHandlers;
