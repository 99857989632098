import ActivityTimeline from 'components/ActivityTimeline';
import ActivityTimelineContext from 'components/ActivityTimeline/store';
import { mapActionsToActivities } from 'components/ActivityTimeline/utils';
import React, { useEffect, useMemo } from 'react';
import {
  setCarePlanModalEventType,
  setPreviousPatientActions,
  setSelectedAction,
  setSelectedDate,
  setTimelineActions,
} from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import DatePickerValue from 'types/Shared/DatePicker';
import { generateTimelineActions } from 'util/calendarUtils/actionRecurrence/absolute/generateRecurrentActions';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import { templateCarePlanStartDate } from '../../../util';

const calendarHeight = '540px';

const TemplateTimeline = () => {
  const {
    dispatch,
    templateState: {
      templateCarePlan: { selectedDate, periodSelected, patientActions, timelineActions },
    },
  } = useTemplateContext();

  useEffect(() => {
    if (!patientActions) return;
    const blockStartDate = templateCarePlanStartDate;
    const newTimelineActions = generateTimelineActions(
      patientActions,
      selectedDate,
      blockStartDate
    );

    dispatch(setTimelineActions(newTimelineActions));
  }, [patientActions, selectedDate, dispatch]);

  const setTimelineDate = (datePickerValue: DatePickerValue) => {
    const dateValue = convertDatepickToDate(datePickerValue);
    dispatch(setSelectedDate(dateValue));
  };

  const onActivityClick = (_, activityId: number) => {
    const clickedIdx = timelineActions.findIndex((a) => a.id === activityId);
    const clickedAction = { ...timelineActions[clickedIdx] };
    clickedAction.previousStartDate = clickedAction.start_date;

    const updatedTimelineActions = [...timelineActions];
    updatedTimelineActions[clickedIdx] = clickedAction;
    dispatch(setTimelineActions(updatedTimelineActions));
    dispatch(setSelectedAction(clickedAction));

    const eventType = clickedAction.event_type;
    dispatch(setCarePlanModalEventType(eventType));
    dispatch(setPreviousPatientActions());
  };

  const timelineActivities = useMemo(
    () => mapActionsToActivities(timelineActions),
    [timelineActions]
  );

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ maxHeight: calendarHeight, overflow: 'auto' }}>
        <ActivityTimelineContext.Provider
          value={{
            activities: timelineActivities,
            currentSelectedDate: convertDateToDatePickValue(selectedDate),
            setCurrentSelectedDate: setTimelineDate,
            clickable: true,
            onActivityClick,
            draggable: true,
          }}
        >
          <ActivityTimeline currentView={periodSelected} style={{ minHeight: calendarHeight }} />
        </ActivityTimelineContext.Provider>
      </div>
    </div>
  );
};
export default TemplateTimeline;
