import DateRangePicker from 'components/DateRangePicker';
import GaugeIndicator from 'components/indicators/GaugeIndicator';
import { Dispatch, SetStateAction } from 'react';
import { DatePickerRangeOption } from 'types/Shared/DatePicker';
import styles from './styles.module.css';

// type DatePickerRange

interface HeaderProps {
  date: DatePickerRangeOption;
  setDate: Dispatch<SetStateAction<DatePickerRangeOption>>;
  yellowAlertPatients: number;
  totalPatients: number;
}
const Header = ({ date, setDate, yellowAlertPatients, totalPatients }: HeaderProps) => {
  return (
    <div className="dashboard-item-header d-flex">
      <span style={{ textTransform: 'capitalize' }}>Consecutive Yellow Alerts</span>
      <div className="ml-3" onClick={(e) => e.stopPropagation()}>
        <DateRangePicker
          currentDateRange={date}
          handleDateRangePickerChange={(range) => setDate(range)}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.datePickerWrapper}
          inputClassName={styles.datePicker}
        />
      </div>

      <div style={{ flex: 1, paddingRight: 40 }}>
        <div style={{ float: 'right' }}>
          <GaugeIndicator
            key={yellowAlertPatients}
            color="#F8CA54"
            percentage={(yellowAlertPatients / totalPatients) * 100}
            text={`${yellowAlertPatients}`}
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
