import React from 'react';
import Button from '../../../../../../../../../../components/Button';

const CreateActions = ({ onSave, onCancel, style, disabled }) => {
  return (
    <div className="d-flex">
      <Button
        style={style}
        disabled={disabled}
        onClick={() => onCancel()}
        label="cancel"
        variant="cancel"
        className="mr-3"
      />
      <Button
        style={style}
        disabled={disabled}
        onClick={() => onSave()}
        label="Save questionnaire"
        variant="confirm"
      />
    </div>
  );
};
export default CreateActions;
