import VyTracModal from 'components/Modal';
import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { FormCheck } from 'react-bootstrap';
import InputField from 'components/InputField';
import Checkbox from 'components/form/Checkbox';
import Button from 'components/Button';

interface ChargesConfigModalProps {
  handleClose: () => void;
  handleChange: (month) => void;
  showModal: boolean;
}

const ChargesConfigModal: FC<ChargesConfigModalProps> = ({
  handleClose,
  handleChange,
  showModal,
}: ChargesConfigModalProps) => {
  return (
    <VyTracModal
      show={showModal}
      onClose={handleClose}
      title={
        <div className={styles.container}>
          <span>Medicare insurer code charges</span>
          <div className={styles.swithContainer}>
            <FormCheck type="switch" checked={true} />
          </div>
        </div>
      }
      size="lg"
      bodyClassName={styles.modalBody}
      body={
        <div className="d-flex flex-column gap">
          <div className={styles.rowContainer}>
            <div className={styles.title}>Name</div>
            <div className={styles.content}>
              <InputField onChange={(e) => {}} value={null} className={styles.input} />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Charge codes page</div>
            <div className={styles.content}>
              <InputField onChange={(e) => {}} value={null} className={styles.input} />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Default pricing</div>
            <div className={styles.content}>
              <Checkbox value={null} />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Code category</div>
            <div className={styles.content}>
              <Button
                className={styles.button}
                color="#282556"
                label='RPM'
                onClick={() => {}}
                selected={true}
              />
              <Button
                className={styles.button}
                color="#282556"
                label='RTM'
                onClick={() => {}}
                selected={false}
              />
              <Button
                className={styles.button}
                color="#282556"
                label='CCM'
                onClick={() => {}}
                selected={false}
              />

            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.content}>
              <Button className={styles.button} onClick={() => {}} label="base cost" />
              <Button className={styles.button} onClick={() => {}} label="Insurer modifiers" />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>99453</div>
            <div className={styles.content}>
              <InputField onChange={(e) => {}} value={null} className={styles.input} />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>99454</div>
            <div className={styles.content}>
              <InputField onChange={(e) => {}} value={null} className={styles.input} />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>99457</div>
            <div className={styles.content}>
              <InputField onChange={(e) => {}} value={null} className={styles.input} />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>99458</div>
            <div className={styles.content}>
              <InputField onChange={(e) => {}} value={null} className={styles.input} />
            </div>
          </div>
          <div className={styles.content}>
            <span className={styles.addCode}>ADD CODE</span>
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 px-3 justify-content-between">
          <Button label="cancel" variant="cancel" />
          <Button label="save changes" variant="confirm" onClick={() => {}} />
        </div>
      }
    />
  );
};
export default ChargesConfigModal;
