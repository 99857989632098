import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';

interface AdministrationContextState {
  breadcrumbPathList: BreadCrumbPath[];
  setBreadcrumbPathList: Dispatch<SetStateAction<BreadCrumbPath[]>>;
  /** Action on right top corner of screen, they are added in the same component as breadcrumbs */
  actions: any;
  setActions: Dispatch<SetStateAction<any>>;
  state: any;
  setState: Dispatch<any>;
}

const AdministrationContext = createContext<AdministrationContextState>(
  {} as AdministrationContextState
);

export const useAdministrationContext = () => useContext(AdministrationContext);

export default AdministrationContext;
