import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { ITimesHours } from 'types/Shared/ITimesHours';

const useMultipleTimes = (
  data: IPatientCarePlanAction,
  setData: (updatedSelectedAction: IPatientTimelineAction) => void
): {
  onAddTime: () => void;
  onDeleteTime: (index: number) => void;
  onTimeChange: (index: number, time: ITimesHours) => void;
} => {
  const onAddTime = () => {
    setData({
      ...data,
      recurrence: {
        ...data.recurrence,
        multiple_times_hours: [...data.recurrence.multiple_times_hours, { hour: 0, minute: 0 }],
      },
    });
  };

  const onDeleteTime = (index: number) => {
    const updatedTimeArray = [...data.recurrence.multiple_times_hours];
    updatedTimeArray.splice(index, 1);
    setData({
      ...data,
      recurrence: {
        ...data.recurrence,
        multiple_times_hours: updatedTimeArray,
      },
    });
  };

  const onTimeChange = (index: number, time: ITimesHours) => {
    const updatedMultipleTimesHours = [...data.recurrence.multiple_times_hours];
    updatedMultipleTimesHours[index] = time;
    setData({
      ...data,
      recurrence: { ...data.recurrence, multiple_times_hours: updatedMultipleTimesHours },
    });
  };

  return { onAddTime, onDeleteTime, onTimeChange };
};
export default useMultipleTimes;
