import { ReactComponent as DefaultMale } from 'assets/icons/default_male_profile.svg';
import { ReactComponent as DefaultFemale } from 'assets/icons/default_female_profile.svg';
import { ReactComponent as DefaultUndefined } from 'assets/icons/default_undefined_profile.svg';
import { SexLabelId } from 'types/ApiModels/Users/Sex';

interface IDefaultImage extends React.SVGProps<SVGSVGElement> {
  sex: SexLabelId;
}
const DefaultImage = (props: IDefaultImage) => {
  if (props.sex === 'M') return <DefaultMale {...props} />;
  else if (props.sex === 'F') return <DefaultFemale {...props} />;
  else return <DefaultUndefined {...props} />;
};
export default DefaultImage;
