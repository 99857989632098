import arrowIcon from 'assets/icons/arrow_right.svg';
import deleteIcon from 'assets/icons/delete.png';
import editIcon from 'assets/icons/edit.png';
import GenericButton from 'components/GenericButton';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { ChangeEventHandler, FC, useMemo, useState } from 'react';
import { Accordion, Card, Form as BootstrapForm } from 'react-bootstrap';
import Select from 'react-select';
import { deleteEmailSettingAction } from 'screens/Administration/store/EmailSettings';
import { updateEmailSettingAction } from 'screens/Administration/store/EmailSettings/emailSettingActionCreators';
import { useAdminEmailSettings } from 'screens/Administration/store/hooks';
import { deleteEmailSetting } from 'services/administrationService';
import { EmailSetting, EmailSettingType, EmailTypeTuple } from 'types/ApiModels/Administration';
import { EmailTypeOption } from '../types';
import styles from './styles.module.css';

interface EmailSettingCardHeaderProps {
  emailSetting: EmailSetting;
  getEmailSettingEditorData: () => {
    editorContentJson: string;
    editorContentHtml: string;
    subject: string;
  };
  types: EmailTypeTuple[];
  handleTypeChange: (v: EmailTypeOption) => void;
  currentType: EmailTypeOption;
  handleCollapse: () => void;
  collapsed: boolean;
  customName: string;
  handleCustomNameChange: ChangeEventHandler<HTMLInputElement>;
}

const EmailSettingItemHeader: FC<EmailSettingCardHeaderProps> = ({
  emailSetting,
  types,
  handleTypeChange,
  currentType,
  collapsed,
  handleCollapse,
  customName,
  handleCustomNameChange,
}: EmailSettingCardHeaderProps) => {
  const [, dispatch] = useAdminEmailSettings();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { confirmationDialog, errorDialog } = useDialog();

  const typesOptions = useMemo(
    () =>
      types.map<{ value: EmailSettingType; label: string }>((t) => {
        const [value, label] = t;
        return { value, label };
      }),
    [types]
  );

  const handleCommitDelete = async () => {
    //must check if this is just something creeated and not saved
    if (emailSetting.id > 0) {
      const [, error] = await deleteEmailSetting(emailSetting.id);
      if (error) {
        errorDialog(
          'Error',
          `There was an issue when trying to delete setting ${Object.values(error).join(', ')}`
        );
        return;
      }
      confirmationDialog('Success', 'Email setting deleted successfully');
      dispatch(deleteEmailSettingAction(emailSetting.id));
    } else {
      dispatch(deleteEmailSettingAction(emailSetting.id));
    }
  };

  const handleDeleteIconClick = () => {
    if (emailSetting.id > 0) setShowConfirmDelete(true);
    else {
      handleCommitDelete();
    }
  };

  const handleUpdateIsActive = () => {
    dispatch(updateEmailSettingAction({ id: emailSetting.id, active: !emailSetting.active }));
  };

  return (
    <Card.Header className={`${styles['toggle-header']} px-3 overflow-visible`}>
      <div className="w-100 d-flex gap overflow-visible">
        <div className={`${styles['input-toggle-container']} d-flex gap overflow-visible`}>
          <Accordion.Toggle
            eventKey={emailSetting.id.toString()}
            className={`${styles['accordion-toggle']} d-flex align-items-center cursor-pointer ${
              styles['toggle-rotate']
            } ${collapsed ? styles['toggle-rotate-0'] : styles['toggle-rotate-90']}`}
            onClick={handleCollapse}
          >
            <img src={arrowIcon} alt="edit setting" />
          </Accordion.Toggle>
          <Select
            options={[...typesOptions, { value: EmailSettingType.custom, label: 'Custom' }]}
            styles={vytracSelectStyle}
            className={styles['select-min-width']}
            onChange={(v) => handleTypeChange(v)}
            value={currentType}
          />
          {currentType?.value === EmailSettingType.custom ? (
            <input
              type="text"
              className={`${styles['title-input']} vytrac-input p-1 rounded`}
              value={customName}
              onChange={handleCustomNameChange}
            />
          ) : null}
        </div>
        <div className="d-flex gap align-items-center">
          <Accordion.Toggle
            eventKey={emailSetting.id.toString()}
            className={`${styles['accordion-toggle']} d-flex align-items-center cursor-pointer`}
            onClick={handleCollapse}
          >
            <img src={editIcon} height={16} alt={'edit setting'} />
          </Accordion.Toggle>
          <GenericButton
            icon={deleteIcon}
            onClick={handleDeleteIconClick}
            alt="delete setting"
            className="d-flex"
          />
          <div className="cursor-pointer" onClick={handleUpdateIsActive}>
            <BootstrapForm.Check type="switch" checked={emailSetting.active} readOnly />
          </div>
        </div>
        <SaveChangesConfirmationModal
          show={showConfirmDelete}
          variant="danger"
          onConfirm={handleCommitDelete}
          onCancel={() => setShowConfirmDelete(false)}
          message={`Are you sure you want to remove the email setting: ${emailSetting.name}?`}
        />
      </div>
    </Card.Header>
  );
};
export default EmailSettingItemHeader;
