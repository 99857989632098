import { Dispatch, useCallback, useEffect, useMemo } from 'react';
import { DashboardUrgency } from 'types/ApiModels/Dashboard/DashboardUrgency';
import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { setUrgency } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useDashboardUrgency = (): [
  PaginatedRequestHook<DashboardUrgency>,
  Dispatch<DashboardAction>
] => {
  const {
    state: { urgency },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const paginationRequest = usePaginatedRequest('providers/dashboard/urgency/', 10, urgency, false);

  const init = useMemo(() => {
    return paginationRequest.init;
  }, [paginationRequest.init]);

  const urgencyInit = useCallback(async () => {
    fetchRegistry.current.urgency.list = true;
    await init();
    fetchRegistry.current.urgency.list = false;
  }, [fetchRegistry, init]);

  useEffect(() => {
    if (!urgency && !fetchRegistry.current.urgency.list) {
      urgencyInit();
    }
  }, [fetchRegistry, urgency, urgencyInit]);

  useEffect(() => {
    dispatch(setUrgency(paginationRequest.book));
  }, [dispatch, paginationRequest.book]);

  return [paginationRequest, dispatch];
};

export default useDashboardUrgency;
