import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { PatientTrackingAction, PatientTrackingActionTypes } from './patientTrackingActionTypes';

export interface IPatientTrackingState {
  selectedActivity: TrackingActivityTemplate;
}

export const patientTrackingInitialState: IPatientTrackingState = {
  selectedActivity: null,
};

const patientTrackingReducer = (
  state: IPatientTrackingState,
  action: PatientTrackingAction
): IPatientTrackingState => {
  switch (action.type) {
    case PatientTrackingActionTypes.SET_SELECTED_ACTIVITY: {
      return { ...state, selectedActivity: action.payload.selectedActivity };
    }
  }
};

export default patientTrackingReducer;
