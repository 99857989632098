import {useContext} from 'react';
import {createContext} from 'react';

export const initialPatientReportsCtxValue = {
  alerts: [],
  populations: [],
};

export const PatientReportsContext = createContext(initialPatientReportsCtxValue);

export const usePatientReportsContext = () => {
  const patientReportsContext = useContext(PatientReportsContext);

  return patientReportsContext;
};
