import { Dispatch, useEffect } from 'react';
import { getAdminForms } from 'services/administrationService';
import { useAppQuery } from 'store/use-app-query';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import { useAdministrationContextV2 } from '..';
import { AllAdminActions } from '../administrationReducer';

import { FormState, setAdminForms } from '../Forms';

export const useAdminForms = (): [state: FormState, dispatch: Dispatch<AllAdminActions>] => {
  const {
    administrationStateV2: { forms },
    dispatch,
    fetchRegistry,
  } = useAdministrationContextV2();

  const { data: populations } = useAppQuery<PopulationWithCount[]>('populations');

  useEffect(() => {
    if (forms.list || fetchRegistry.current.forms.list || !populations) return;
    (async () => {
      fetchRegistry.current.forms.list = true;
      const forms = await getAdminForms();
      const parsedForms = forms.map((f) => ({
        ...f,
        population: populations.filter((p) => f.population.some((id) => id === p.id)),
      }));
      dispatch(setAdminForms(parsedForms));
      fetchRegistry.current.forms.list = false;
    })();
  }, [dispatch, forms.list, fetchRegistry, populations]);

  return [forms, dispatch];
};
