//TODO: replace hardcoded values everywhere
const colors = {
  highRiskAlerts: '#F5485C',
  mediumRiskAlerts: '#F8CA54',
  lowRiskAlerts: '#8FAAC3',
  brightBlue: '#1890FF',
  regularBlack: '#393E48',
  greenConfirmationBg: '#f7fcf2',
  greenConfirmationText: '#98a185',
  lightGray: '#f8f9fb',
  white: '#FFFFFF',
  grayProgressBar: '#CFD6E2',
};

export default colors;
