import EmptyList from 'components/EmptyList';
import React from 'react';
import styles from './styles.module.css';
import { ReactComponent as PatientIcon } from 'assets/icons/adherence_empty.svg';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';

interface QuestionnaireTemplatesListProps {
  setTemplate: (template: IQuestionnaire) => void;
  selectedTemplate: IQuestionnaire;
  templates: IQuestionnaire[];
}
const QuestionnaireTemplatesList = ({
  setTemplate,
  selectedTemplate,
  templates,
}: QuestionnaireTemplatesListProps) => {
  return (
    <>
      {templates?.length > 0 ? (
        <>
          <div className={styles['list-container']}>
            {templates.map((template) => (
              <div
                key={template.id}
                className={`${styles.item} ${
                  template.id === selectedTemplate?.id ? styles.active : ''
                }`}
                onClick={() => setTemplate(template)}
              >
                {template.name}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <EmptyList
            Icon={PatientIcon}
            title="There are no questionnaire templates"
            fill="#E6F5FA"
          />
        </div>
      )}
    </>
  );
};
export default QuestionnaireTemplatesList;
