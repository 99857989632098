import { Dispatch, useCallback, useEffect, useMemo, useRef } from 'react';
import DashboardOutstandingChats from 'types/ApiModels/Dashboard/DashboardOutstandingChats';
import DatePickerValue from 'types/Shared/DatePicker';
import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { setOutstandingChats } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useDashboardOutstandingChats = (
  date: DatePickerValue
): [PaginatedRequestHook<DashboardOutstandingChats>, Dispatch<DashboardAction>] => {
  const {
    state: { outstandingChats },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const previousDate = useRef(date);

  const paginationRequest = usePaginatedRequest(
    'providers/dashboard/outstandingchats/',
    10,
    outstandingChats,
    false,
    { date_from: `${date.year}-${date.month}-${date.day}` }
  );

  const init = useMemo(() => {
    return paginationRequest.init;
  }, [paginationRequest.init]);

  const outstandingChatsInit = useCallback(async () => {
    fetchRegistry.current.outstandingChats.list = true;
    await init();
    fetchRegistry.current.outstandingChats.list = false;
  }, [fetchRegistry, init]);

  useEffect(() => {
    if (
      previousDate.current.year != date.year ||
      previousDate.current.month != date.month ||
      previousDate.current.day != date.day ||
      (!outstandingChats && !fetchRegistry.current.outstandingChats.list)
    ) {
      outstandingChatsInit();
      previousDate.current = { ...date };
    }
  }, [fetchRegistry, outstandingChats, outstandingChatsInit, date]);

  useEffect(() => {
    dispatch(setOutstandingChats(paginationRequest.book));
  }, [dispatch, paginationRequest.book]);

  return [paginationRequest, dispatch];
};

export default useDashboardOutstandingChats;
