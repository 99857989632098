export const emptyResource = {
  id: null,
  name: '',
  type: null,
  categories: null,
  categoriesId: [],
  location: null,
  url: null,
  cover: null,
  content: null,
  createdBy: null,
  dateCreated: null,
};
