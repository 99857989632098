import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import {
  PatientGoalsThresholdsAction,
  PatientGoalsThresholdsActionTypes,
} from './patientGoalsThresholdsActionTypes';

export const updateThreshold = (
  goal: VytalType,
  threshold: IGoalThreshold
): PatientGoalsThresholdsAction => ({
  type: PatientGoalsThresholdsActionTypes.UPDATE_THRESHOLD,
  payload: {
    goal,
    threshold,
  },
});

export const setThresholds = (thresholds: IGoalThreshold[]): PatientGoalsThresholdsAction => ({
  type: PatientGoalsThresholdsActionTypes.SET_THRESHOLDS,
  payload: {
    thresholds,
  },
});

export const setSelectedGoal = (goal: VytalType): PatientGoalsThresholdsAction => ({
  type: PatientGoalsThresholdsActionTypes.SET_THRESHOLDS_SELECTED_GOAL,
  payload: {
    goal,
  },
});

export const setPreviousThresholds = (): PatientGoalsThresholdsAction => ({
  type: PatientGoalsThresholdsActionTypes.SET_PREVIOUS_THRESHOLDS,
});

export const resetEditedGoals = (): PatientGoalsThresholdsAction => ({
  type: PatientGoalsThresholdsActionTypes.RESET_EDITED_GOALS,
});
