import React from 'react';
import BreadcrumbsWithActions from '../../../components/BreadcrumbsWithActions';
import { useTemplateContext } from '../store/templateContext';

const NavigationHeader = () => {
  const {
    templateState: {
      templateNavigation: { breadcrumbPathList, actions: Actions },
    },
  } = useTemplateContext();

  return (
    <BreadcrumbsWithActions breadcrumbs={breadcrumbPathList}>
      {Actions && <Actions />}
    </BreadcrumbsWithActions>
  );
};

export default NavigationHeader;
