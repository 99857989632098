import { AlertType } from '../Dashboard';

export enum AlertTypeEnum {
  VYTAL = 'vital',
  QUESTIONNAIRE = 'questionnaire',
  ADHERENCE = 'adherence',
}

export interface IAPIAlertWebSocketMessage {
  patient_id: number;
  vytal_id: number;
  questionnaire_id: number;
  alert_type: AlertType;
  type: 'yellow' | 'red' | 'critical';
}

export enum WebSocketMessageType {
  ALERT = 'alert',
  CHAT = 'chat',
}
interface IAPIWebSocketMessage {
  type: WebSocketMessageType;
  content: IAPIAlertWebSocketMessage | string; //TODO: add chat type
}
export default IAPIWebSocketMessage;
