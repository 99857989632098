import PlatformRulesBody from './PlatformRulesBody';
import PlatformRulesHeader from './PlatformRulesHeader';
import styles from './styles.module.css';

const PlatformRules = () => {
  const handleSearch = (search) => {};
  const handleFilter = (filter) => {};
  return (
    <div className="dashboard-detail h-auto">
      <div className={`dashboard-item-header ${styles.header}`}>
        <PlatformRulesHeader onSearch={handleSearch} onFilter={handleFilter} />
      </div>
      <div>
        <PlatformRulesBody />
      </div>
    </div>
  );
};

export default PlatformRules;
