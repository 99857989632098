import React, { HTMLProps } from 'react';
import activityItemStyles from './../ActivityItems.module.css';

interface IActionContentProps {
  isCarePlan: boolean;
  isActive: boolean;
}
export const actionContentProps = ({
  isCarePlan,
  isActive,
}: IActionContentProps): HTMLProps<HTMLDivElement> => ({
  className: `d-flex flex-column rounded-left px-2 py-2 font-size-small ${
    activityItemStyles['activity-container']
  } ${activityItemStyles['action-container']} ${
    !isCarePlan && activityItemStyles['is-not-care-plan']
  } ${!isActive && activityItemStyles['is-not-active']}`,
});

interface IActionContent {
  dateAMPM: string;
  label: string;
  isActive: boolean;
  questionnaireId?: number;
  handleQuestionnaireClick?: () => void;
}
const ActionContent = ({
  dateAMPM,
  label,
  questionnaireId,
  handleQuestionnaireClick,
  isActive,
}: IActionContent) => {
  return (
    <>
      <div className={activityItemStyles['hour-label']}>{dateAMPM}</div>
      <div className={activityItemStyles['main-label']}>{label}</div>
      {questionnaireId && (
        <span className="link" style={{ marginTop: '5px' }} onClick={(e) => e.stopPropagation()}>
          View questionnaire
        </span>
      )}
      {!isActive && (
        <div style={{ marginTop: '5px' }}>
          <i>Disabled</i>
        </div>
      )}
    </>
  );
};
export default ActionContent;
