import React from 'react';
import ActiveSchedule from './components/ActiveSchedule';
import Calendar from './components/Calendar';
const FirstColumn = ({ activeSchedule, setActiveSchedule, refetch }) => {
  return (
    <div>
      <Calendar />
      <ActiveSchedule
        refetch={refetch}
        activeSchedule={activeSchedule}
        setActiveSchedule={setActiveSchedule}
      />
    </div>
  );
};

export default FirstColumn;
