import Card from '../../../../../../../../../components/Card';
import GenericTable from '../../../../../../../../../components/GenericTable';
import PatientTableToolbar from '../../../../../../../../../components/PatientTableToolbar';
import style from '../../styles.module.css';
import { usePatientReportsContext } from '../../utils/Context/PatientReportsContext';
import { alertsColumns, mapAlertDataToTable } from '../../utils/patientReportUtils';

const AlertsReport = () => {
  const { alerts } = usePatientReportsContext();

  return (
    <>
      <Card
        headers={[<PatientTableToolbar title={`Reports history (${alerts?.length || ''})`} />]}
        bodyClassName={style['patient-reports-table-container']}
      >
        <GenericTable columns={alertsColumns} data={mapAlertDataToTable(alerts)} />
      </Card>
    </>
  );
};
export default AlertsReport;
