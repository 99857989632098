import Button from 'components/Button';
import React from 'react';

interface IRecurrenceEditionModalFooter {
  onCancel: () => void;
  onDelete?: () => void;
  onSave?: () => void;
}
const RecurrenceEditionModalFooter = ({
  onCancel,
  onDelete,
  onSave,
}: IRecurrenceEditionModalFooter) => {
  return (
    <div className="d-flex justify-content-around" style={{ width: '100%' }}>
      <div className="d-flex justify-content-start flex-grow-1">
        <Button
          label={'cancel'}
          variant="cancel"
          style={{ marginRight: '10px', width: '80px' }}
          onClick={onCancel}
        />
        <Button label={'delete'} variant="delete" style={{ width: '80px' }} onClick={onDelete} />
      </div>

      <Button label={'save'} variant="confirm" style={{ width: '80px' }} onClick={onSave} />
    </div>
  );
};
export default RecurrenceEditionModalFooter;
