export enum AppointmentType {
  CALL = 'call',
  VIDEO = 'video',
}

export interface Appointment {
  id?: number;
  deleted?: boolean;
  appointment_from?: string;
  appointment_to?: string;
  appointment_type?: AppointmentType;
  detail?: string;
  is_completed?: boolean;
  is_confirmed?: boolean;
  patient_id?: number;
  provider_id?: number;
  team_note?: string;
  schedule_note?: string;
  provider_schedule_note?: string;
  patient_note?: string;
  type?: string;
}
