export enum PatientQuickFilter {
  ALERTS_COUNT = '-alerts_count',
  DATE_CREATED = 'date_created',
  TAGS_COUNT = '-tags_count',
  TIME_SPENT = '-time_spent',
  UPCOMING_APPOINTMENTS = 'upcoming_appointments',
  PRIMARY_IN_TEAM = '-primary_in_team',
}

export enum QuickFilterLabel {
  ALERTS_COUNT = 'Most alerts',
  DATE_CREATED = 'Oldest first',
  TAGS_COUNT = 'Most tags',
  TIME_SPENT = 'Time spent',
  UPCOMING_APPOINTMENTS = 'Upcoming appointments',
  PRIMARY_IN_TEAM = 'Primary in team',
}

export const mapQuickFilterToLabel = (filter: keyof PatientQuickFilter): QuickFilterLabel => {
  if (filter in Object.keys(QuickFilterLabel)) {
    return QuickFilterLabel[filter];
  }
  return null;
};
