import Bubble from 'components/Bubble';
import { Tagitem } from 'components/Tagitem';
import React from 'react';
import styles from './styles.module.css';
const BillingPreview = () => {
  return (
    <div className={styles.main}>
      <div className={styles.title}>billing</div>
      <hr className="my-0" />
      <div className={styles.bottom}></div>
      <Tagitem text="123456" className={styles.billingTag} />
    </div>
  );
};
export default BillingPreview;
