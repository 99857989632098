import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const StairsIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 0.916667C6.66667 0.41 7.07733 0 7.58333 0H10.0833C10.59 0 11 0.410667 11 0.916667V4.66667H10V1H7.66667V3.41667C7.66667 3.92333 7.256 4.33333 6.75 4.33333H4.33333V6.75C4.33333 7.25667 3.92267 7.66667 3.41667 7.66667H1V10H4.66667V11H0.916667C0.41 11 0 10.5893 0 10.0833V7.58333C0 7.07667 0.410667 6.66667 0.916667 6.66667H3.33333V4.25C3.33333 3.74333 3.744 3.33333 4.25 3.33333H6.66667V0.916667ZM8.66667 6.25C8.66667 5.74333 9.07733 5.33333 9.58333 5.33333H12.0833C12.59 5.33333 13 5.744 13 6.25V10.8333C13 11.408 12.7717 11.9591 12.3654 12.3654C11.9591 12.7717 11.408 13 10.8333 13H6.25C5.74333 13 5.33333 12.5893 5.33333 12.0833V9.58333C5.33333 9.07667 5.744 8.66667 6.25 8.66667H8.66667V6.25Z"
        fill={color}
      />
    </svg>
  );
};

export default StairsIcon;
