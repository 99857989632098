import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const HeartIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54032 1.01307C4.93247 0.366782 4.10697 0.00239304 3.24531 1.17446e-05C2.38364 -0.00236955 1.55636 0.357452 0.945322 1.00037C0.337783 1.64698 -0.00223916 2.52244 1.10984e-05 3.43428C0.00226136 4.34612 0.346599 5.21969 0.957322 5.86294L5.66432 10.8451C5.75809 10.9443 5.88524 11 6.01782 11C6.1504 11 6.27756 10.9443 6.37132 10.8451L11.0543 5.89257C11.6616 5.24599 12.0014 4.37076 11.9991 3.45918C11.9969 2.54761 11.6527 1.67426 11.0423 1.03106C10.7411 0.710348 10.3831 0.455603 9.9888 0.281383C9.59449 0.107163 9.1716 0.0168827 8.74431 0.0157034C8.31701 0.0145241 7.89369 0.102468 7.49853 0.27451C7.10337 0.446551 6.74412 0.699316 6.44132 1.01836L5.99432 1.49244L5.54032 1.01307Z"
        fill={color}
      />
    </svg>
  );
};

export default HeartIcon;
