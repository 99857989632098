import ListItem from 'components/ListItem';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import { FC } from 'react';
import { CareTeamProvider } from 'types/ApiModels/CareTeam';
import DeleteIcon from 'assets/icons/delete.png';
import GenericButton from 'components/GenericButton';
import Checkbox from 'components/form/Checkbox';

interface ProviderTierItemProps {
  provider: CareTeamProvider;
  handleDeleteProvider?: () => void;
  handleSelectProvider?: (provider: CareTeamProvider, checked: boolean) => void;
  enableDelete?: boolean;
  enabledSelect?: boolean;
  selected?: boolean;
  onNameClick?: (provider: CareTeamProvider, topPosition: number) => void;
}

const ProviderTierItem: FC<ProviderTierItemProps> = ({
  provider,
  handleDeleteProvider,
  handleSelectProvider,
  enableDelete = true,
  enabledSelect = false,
  selected = false,
  onNameClick,
}: ProviderTierItemProps) => {
  return (
    <div className={`d-flex justify-content-between rounded flex-grow-1`} key={provider.id}>
      <ListItem
        user={{
          first_name: provider.first_name,
          last_name: provider.last_name,
          username: null,
          imageUrl: provider.photo_thumbnail,
        }}
        onNameClick={(topPosition: number) => onNameClick?.(provider, topPosition)}
      />
      <div className="d-flex align-items-center">
        <div>
          <TagList
            tagList={provider.specialities?.map<Tag>((s) => ({ text: s.name })) ?? []}
            maxCount={2}
          />
        </div>
        {enableDelete && (
          <div className="pr-3">
            <GenericButton
              className="d-flex align-items-center"
              icon={DeleteIcon}
              alt="delete"
              onClick={handleDeleteProvider}
            />
          </div>
        )}
        {enabledSelect && (
          <div className="pl-3">
            <Checkbox
              value={selected}
              onClick={(e) => handleSelectProvider(provider, e.target.checked)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ProviderTierItem;
