import AlertItemSvg from 'components/AlertItemSvg';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import { activityStatusToColor } from '../../utils/dayViewUtils';
import activityItemStyles from '../../../ActivityItems.module.css';
import { CarePlanActionType } from 'types/ApiModels/CarePlan/ActionType';
import { HTMLProps } from 'react';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';

interface IActionContentProps {
  isCarePlan: boolean;
  isActive: boolean;
}
export const actionContentProps = ({
  isCarePlan,
  isActive,
}: IActionContentProps): HTMLProps<HTMLDivElement> => ({
  className: `d-flex rounded-left align-items-center gap px-2 py-2 overflow-hidden font-size-medium ${
    activityItemStyles['activity-container']
  } ${activityItemStyles['action-container']} ${
    !isCarePlan && activityItemStyles['is-not-care-plan']
  } ${!isActive && activityItemStyles['is-not-active']}`,
});

interface IActionContent {
  type: CarePlanActionType;
  eventType: CarePlanActionEventType;
  mainLabel: string;
  secondaryLabel?: string;
  questionnaireId?: number;
  isActive: boolean;
}
const ActionContent = ({
  type,
  eventType,
  mainLabel,
  secondaryLabel,
  questionnaireId,
  isActive,
}: IActionContent) => {
  const Icon =
    eventType === 'vitals_request' ? rpmActionTypeToIcon[type] : rpmActionTypeToIcon[eventType];
  return (
    <>
      {Icon && (
        <div className={`d-flex align-items-center`}>
          <AlertItemSvg
            SvgComponent={Icon}
            color={activityStatusToColor['regular']}
            height={20}
            width={20}
            lg
          />
        </div>
      )}

      <div className={`d-flex flex-column overflow-hidden`}>
        <p
          className={`${activityItemStyles['main-label']} ${activityItemStyles['day-item-label']}`}
        >
          {mainLabel}
        </p>
        {questionnaireId && (
          <span className="link" style={{ marginTop: '5px' }} onClick={(e) => e.stopPropagation()}>
            View questionnaire
          </span>
        )}
        {secondaryLabel ? (
          <p className={`${activityItemStyles['secondary-label']}`}>{secondaryLabel}</p>
        ) : null}
        {!isActive && (
          <div style={{ marginTop: '5px' }}>
            <i>Disabled</i>
          </div>
        )}
      </div>
    </>
  );
};
export default ActionContent;
