import { ChartData } from 'chart.js';
import CompareBarGraph from 'components/CompareBarGraph/CompareBarGraph';
import FiltersButton from 'components/FiltersButton';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import usePatients from 'screens/Analytics/store/hooks/usePatients';
import { getAnalyticsPatientsCompare } from 'services/analyticsService';
import styles from '../styles.module.css';

const Patients = () => {
  const [patients, __, refetch] = usePatients();
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [dataToModalCompare, setDataToModalCompare] = useState({ labels: [], datasets: [] });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (patients) setPatients();
  }, [patients]);

  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const setPatients = () => {
    const data = {
      labels: Object.keys(patients),
      datasets: [
        {
          data: [patients.active, patients.enrolled],
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setData(data);
  };
  const setComparePatients = (monthsInfo) => {
    const labels = ['active','enrolled'];
    const data = {
      labels: monthsInfo.map((x) => {
        return `${x.model} Month:${x.month}`;
      }),
      datasets: [
        {
          data: monthsInfo?.map((a) => a.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setDataToModalCompare(data);
  };

  const fetchDataforCompare = async (filters: string[]) => {
    const months = filters.map((month) => Number.parseInt(month));
    const monthsInfo = await getAnalyticsPatientsCompare(months);
    setComparePatients(monthsInfo);
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>Patients - Active</div>
        <div className={styles.controls}>
          <i onClick={() => setShowModal(!showModal)} className={styles.expandArrow}></i>
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />

      {patients && (
        <>
          <h6 className="d-flex ml-4 mt-3">{`${patients.active + patients.enrolled} patients`}</h6>
          <div className={styles.bodyPatient}>
            <Bar options={options} data={data} />
          </div>
        </>
      )}
      <CompareBarGraph
        title="Compare Patients"
        handleChange={(months) => {
          fetchDataforCompare(months);
        }}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        options={options}
        data={dataToModalCompare}
      ></CompareBarGraph>
    </div>
  );
};

export default Patients;
