import React, { useEffect, useState } from 'react';
import HourFooter from 'components/DatePicker/components/HourFooter';
import { ReactComponent as TrashIcon } from 'assets/icons/trashcan.svg';
import styles from './styles.module.css';
import { ITimesHours } from 'types/Shared/ITimesHours';

interface ITimeRow {
  time: ITimesHours;
  onDelete: () => void;
  disableDelete: boolean;
  onChange: (newTime: ITimesHours) => void;
}

const TimeRow = ({ time, onDelete, disableDelete, onChange }: ITimeRow) => {
  const [amPm, setAmPm] = useState<'AM' | 'PM'>(null);

  useEffect(() => {
    // set initial value
    if (!amPm) {
      if (time.hour <= 12) setAmPm('AM');
      else setAmPm('PM');
      return;
    }

    if (amPm === 'AM') {
      onChange({ ...time, hour: time.hour % 12 });
    } else {
      onChange({ ...time, hour: (time.hour % 12) + 12 });
    }
  }, [amPm]);

  const onHoursChange = (updatedHours: number) => {
    onChange({ ...time, hour: amPm === 'AM' ? updatedHours : updatedHours + 12 });
  };

  const onMinutesChange = (updatedMinutes: number) => {
    onChange({ ...time, minute: updatedMinutes });
  };

  return (
    <div className="d-flex align-items-center">
      <HourFooter
        label={'Enter time'}
        hours={time.hour}
        onHoursChange={onHoursChange}
        minutes={time.minute}
        onMinutesChange={onMinutesChange}
        amPm={amPm}
        setAmPm={setAmPm}
        containerStyle={{ boxShadow: 'none', paddingRight: '20px', paddingLeft: '20px' }}
      />
      <button className={styles.trashButton} onClick={onDelete} disabled={disableDelete}>
        <TrashIcon />
      </button>
    </div>
  );
};
export default TimeRow;
