import React from 'react';
import VytracBaseNode from '../../../../../../../../../../../components/VytracBaseNode';
import styles from './styles.module.css';

const QuestionNode = ({ data, xPos, yPos, type }) => {
  const [show, setShow] = React.useState(false);

  return (
    <VytracBaseNode
      className={styles.innerBox}
      data={data}
      showMenu={show}
      setShowMenu={setShow}
      type={type}
    >
      <button
        type="button"
        className={`${styles.ripple} ${styles.button}`}
        onClick={data.removeElement}
      >
        Remove question
      </button>
      <button
        type="button"
        className={`${styles.ripple} ${styles.button}`}
        onClick={() => {
          data.addAnswer({ x: xPos, y: yPos });
          setShow(false);
        }}
      >
        Add an answer
      </button>
    </VytracBaseNode>
  );
};
export default QuestionNode;
