import cloudArrowDown from 'assets/icons/cloudArrowDown.png';
import addCircleButton from 'assets/icons/addCircleButton.png';
import './styles.css';
import FiltersButton from 'components/FiltersButton';

const Header = () => (
  <div className="patients-documents-header-container">
    <div className="font-weight-md">Patient documents</div>
    <div className="btns-container">
      <button>
        <img src={cloudArrowDown} alt="" />
      </button>
      <button>
        <img src={addCircleButton} alt="" />
      </button>
      <FiltersButton />
    </div>
  </div>
);

export default Header;
