import { ReactNode } from 'react';
import styles from './styles.module.css';
interface EmptyListProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  fill?: string;
  stroke?: string;
  className?: string;
  children?: ReactNode;
}
const EmptyList = ({ Icon, title, fill, stroke, className, children }: EmptyListProps) => {
  return (
    <div
      className={`d-flex align-items-center flex-column h-100 justify-content-center ${
        className || ''
      }`}
    >
      {children ? (
        children
      ) : (
        <>
          <Icon fill={fill} stroke={stroke} className={styles.emptyIcon} />
          <div className={styles.emptyTitle}>{title}</div>
        </>
      )}
    </div>
  );
};
export default EmptyList;
