import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { ProviderProfileAction } from './providerProfileActions';
import providerProfileReducer, {
  providerProfileInitialState,
  ProviderProfileState,
} from './providerProfileReducer';

interface ProviderProfileContextState {
  providerProfileState: ProviderProfileState;
  dispatch: Dispatch<ProviderProfileAction>;
}

export const ProviderProfileContext = createContext<ProviderProfileContextState>({
  providerProfileState: providerProfileInitialState,
  dispatch: () => undefined,
});

interface ProviderProfileCtxProviderProps {
  children: React.ReactNode;
}
export const ProviderProfileCtxProvider = ({ children }: ProviderProfileCtxProviderProps) => {
  const [providerProfileState, dispatch] = useReducer(
    providerProfileReducer,
    providerProfileInitialState
  );

  return (
    <ProviderProfileContext.Provider value={{ providerProfileState, dispatch }}>
      {children}
    </ProviderProfileContext.Provider>
  );
};

export const useProviderProfileContext = () => {
  return useContext(ProviderProfileContext);
};
