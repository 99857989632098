import Specialty from 'types/ApiModels/Providers/Specialty';
import { SpecialtyAction, SpecialtyActionTypes } from './specialtyActionTypes';

export const setSpecialties = (specialties: Specialty[]): SpecialtyAction => ({
  type: SpecialtyActionTypes.SET_SPECIALTIES,
  payload: { specialties },
});

export const toggleSpecialtyShowEdit = (): SpecialtyAction => ({
  type: SpecialtyActionTypes.TOGGLE_SHOW_EDIT_SPECIALTY,
});

export const toggleShowProvidersBySpecialty = (): SpecialtyAction => ({
  type: SpecialtyActionTypes.TOGGLE_SHOW_PROVIDERS_BY_SPECIALTY,
});

export const toggleShowSpecialtyAdd = (): SpecialtyAction => ({
  type: SpecialtyActionTypes.TOGGLE_SHOW_ADD_SPECIALTY,
});

export const setCurrentSpecialtyId = (specialtyId): SpecialtyAction => ({
  type: SpecialtyActionTypes.SET_CURRENT_SPECIALTYID,
  payload: { specialtyId },
});

export const appendUpdatedSpecialty = (specialty: Specialty): SpecialtyAction => ({
  type: SpecialtyActionTypes.APPEND_UPDATED_SPECIALTY,
  payload: { specialty },
});

export const appendDeletedSpecialty = (specialtyId: number): SpecialtyAction => ({
  type: SpecialtyActionTypes.APPEND_DELETED_SPECIALTY,
  payload: { specialtyId },
});

export const appendAddedSpecialty = (specialty: Specialty): SpecialtyAction => ({
  type: SpecialtyActionTypes.APPEND_ADDED_SPECIALTY,
  payload: { specialty },
});

export const setResetSpecialtyForm = (resetForm: () => void): SpecialtyAction => ({
  type: SpecialtyActionTypes.SET_RESET_SPECIALTIES_FORM,
  payload: { resetForm },
});

export const clearAllSpecialtyChanges = (): SpecialtyAction => ({
  type: SpecialtyActionTypes.CLEAR_ALL_CHANGES,
});

export const setSubmitSpecialtiesForm = (submitForm: () => void): SpecialtyAction => ({
  type: SpecialtyActionTypes.SET_SUBMIT_SPECIALTY_FORM,
  payload: { submitForm },
});
