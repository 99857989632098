import React from 'react';
import Others from '../../../../../../../../../../components/Others';
import styles from './styles.module.css';

const careTeam = ['TW', 'KN', 'AS', 'LT'];

const CareTeamInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Care Team</div>
      <div className={styles.careTeam}>
        <Others medicalGroup={careTeam} />
      </div>
    </div>
  );
};

export default CareTeamInfo;
