import { AdminTabEnum } from 'screens/Administration/util';

const AdminTabNameMap = {
  [AdminTabEnum.DASHBOARD]: 'dashboard',
  [AdminTabEnum.PROVIDERS]: 'providers',
  [AdminTabEnum.ROLES]: 'roles',
  [AdminTabEnum.PATIENTS]: 'patients',
  [AdminTabEnum.PATIENT_SETTINGS]: 'patient settings',
  [AdminTabEnum.NOTIFICATIONS]: 'notifications',
  [AdminTabEnum.FORMS]: 'forms',
  [AdminTabEnum.EMAIL_SETTINGS]: 'email settings',
  [AdminTabEnum.ACCOUNT_SETTINGS]: 'account settings',
  [AdminTabEnum.REPORTS]: 'reports',
  [AdminTabEnum.RULES_ENGINE]: 'rules engine',
  [AdminTabEnum.AUDIT_TRACKING]: 'audit tracking',
  [AdminTabEnum.HEALTH_SCORING]: 'health scoring',
  [AdminTabEnum.ACTIVITY_POINTS]: 'activity points',
};

export default AdminTabNameMap;
