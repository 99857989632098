import CompareBarGraph from 'components/CompareBarGraph/CompareBarGraph';
import DateRangePicker from 'components/DateRangePicker';
import FiltersButton from 'components/FiltersButton';
import Tags from 'components/Tags';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import useAlerts from 'screens/Analytics/store/hooks/useAlerts';
import { getAnalyticsAlertsCompare } from 'services/analyticsService';
import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import styles from '../styles.module.css';

const PatientAlerts = () => {
  const [date, setDate] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  });

  const [alerts, __, refetch] = useAlerts(date.from, date.to);
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [dataToModalCompare, setDataToModalCompare] = useState({ labels: [], datasets: [] });

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (alerts) setAlertsChart();
  }, [alerts]);

  const setAlertsChart = () => {
    const data = {
      labels: alerts?.map((a) => a.type),
      datasets: [
        {
          data: alerts?.map((a) => a.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setData(data);
  };

  const setAlertsCompareChart = (monthsInfo : Alerts[]) => {
    const data = {
      labels: monthsInfo.map(x => { return `${x.type} Month:${x.created_at__month}`}),
      datasets: [
        {
          data: monthsInfo?.map((a) => a.count),
          borderColor: '#CFD6E2',
          backgroundColor: '#CFD6E2',
        },
      ],
    };
    setDataToModalCompare(data);
  };

  const options = {
    indexAxis: 'y' as const,

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const fetchDataforCompare = async (filters: string[]) => {
    const months = filters.map(month => Number.parseInt(month));
    const monthsInfo = await getAnalyticsAlertsCompare(months);
    setAlertsCompareChart(monthsInfo);

  };

  return (
    <div>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.title}>Patient Alerts</div>

          <div className={styles.controls}>
            <DateRangePicker
              currentDateRange={date}
              handleDateRangePickerChange={(range) => setDate(range)}
              calendarClassName={styles.calendar}
              wrapperClassName={styles.datePickerWrapper}
              inputClassName={styles.datePicker}
            />
            <i onClick={() => setShowModal(!showModal)} className={styles.expandArrow}></i>
            <FiltersButton />
          </div>
        </div>
        <hr className={styles.horizontalLine} />
        <div className={styles.body}>
          <Bar options={options} data={data} />
          <div className="mt-2">
            <Tags tagList={['Osteoporosis', 'Alergy']} />
          </div>
        </div>
      </div>
      <CompareBarGraph
        title="Compare Patient Alerts"
        handleChange={(months) => {
          fetchDataforCompare(months);
        }}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        options={options}
        data={dataToModalCompare}
      ></CompareBarGraph>
    </div>
  );
};

export default PatientAlerts;
