import { ProfileControlItem, profileControls } from 'types/Profile';
import { setBreadcrumTitle } from 'util/themeUtils';

export const providerBreadCrumbsList = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'My account',
    url: '/profile',
  },
];

export const profileControlsList: ProfileControlItem[] = [
  {
    label: 'Account information',
    enumValue: profileControls.myAccount,
    route: 'account',
  },
  {
    label: 'Activity points',
    enumValue: profileControls.activityPoints,
    route: 'points',
  },
];
