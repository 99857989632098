import NotificationsPopup from 'components/NotificationsPopup';
import { useAuthContext } from 'auth';
import PatientProfilePopup from 'components/PatientProfilePopup';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { debounce } from 'util/utils';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import pointsIcon from '../../assets/icons/menu/points.png';
import profileIcon from '../../assets/images/dashboard/profile.png';
import PatientSearchBar from './PatientSearchBar';
import { ReactComponent as ActivityPointsIcon } from 'assets/icons/activity-points-gray-sm.svg';
import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import { usePatientFormikContext } from 'screens/Patients/store';
import getCareTeamInitials from 'util/getTeamInitials';

interface INavBar {
  showPointsIcon?: boolean;
  showCareTeamIcon?: boolean;
}
const Navbar = ({ showPointsIcon = false, showCareTeamIcon = false }: INavBar) => {
  const [showProfileActions, setShowProfileActions] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { currentUser } = useAuthContext();
  const { selectedPatient } = usePatientFormikContext();

  const teamInitials: string[] = useMemo(() => {
    if (!selectedPatient?.care_team) return [];
    return getCareTeamInitials(selectedPatient.care_team);
  }, [selectedPatient]);

  const showCareTeamIconDiv = () => {
    if (selectedPatient && showCareTeamIcon) return true;
    else return false;
  };

  //Control the hiding delay of the profile popup so we don't aggressively close the popup at an accidental millimetric mouse-out
  const debouncedShowProfileActions = (f) => debounce(f, 500);

  const handleMouseLeave = (f) => {
    debouncedShowProfileActions(f)(false);
  };
  const handleMouseEnter = (f) => {
    debouncedShowProfileActions(f)(true);
  };
  const displayName = currentUser?.first_name
    ? `${currentUser.first_name} ${currentUser.last_name}`
    : currentUser?.username;

  return (
    <>
      <div className="dashboard-header sticky-top">
        <Row style={{ width: '100%', height: 50 }}>
          <div
            style={{
              padding: '0 12px 0 12px',
              borderBottom: '1px solid #EFEFF0',
              backgroundColor: 'white',
            }}
            className="dashboard-patient-search"
          >
            <PatientSearchBar />
          </div>
          <div
            className="dashboard-notifications"
            style={{
              borderLeft: '2px solid #EFEFF0',
              borderBottom: '2px solid #EFEFF0',
              padding: '10px 16px',
              cursor: 'pointer',
              backgroundColor: showNotifications ? 'var(--dark-blue)' : '#F8F9FB',
              color: showNotifications ? 'white' : '',
              alignItems: 'center',
              width: 83,
            }}
            onClick={() => {
              setShowNotifications((prev) => !prev);
              setShowProfileActions(false);
            }}
          >
            196
            {showNotifications ? (
              <NotificationsPopup
                onMouseEnter={() => handleMouseEnter(setShowNotifications)}
                onMouseLeave={() => handleMouseLeave(setShowNotifications)}
              />
            ) : null}
            <BellIcon fill={showNotifications ? '#fff' : '#8FAAC4'} className="ml-2" />
          </div>
          {showCareTeamIconDiv() && (
            <div
              className="dashboard-notifications"
              style={{
                borderLeft: '2px solid #EFEFF0',
                borderBottom: '2px solid #EFEFF0',
                padding: '10px 16px',
                cursor: 'pointer',
                backgroundColor: '#F8F9FB',
                alignItems: 'center',
                width: 64,
              }}
            >
              <UserBubbleGroupCollapsed
                initials={teamInitials}
                clickable={false}
                onClick={() => {}}
              />
            </div>
          )}

          <div
            className="dashboard-profile position-relative"
            style={{
              backgroundColor: showProfileActions ? 'var(--dark-blue)' : '#F8F9FB',
              color: showProfileActions ? 'white' : '',
            }}
            onClick={() => {
              setShowProfileActions((spa) => !spa);
              setShowNotifications(false);
            }}
          >
            <img
              className="dashboard-menu-icon"
              style={{
                marginLeft: 0,
                marginRight: 15,
                marginTop: 0,
                width: 24,
                height: 24,
              }}
              src={profileIcon}
              alt=""
            />
            <div className="d-flex flex-column">
              <span>{displayName}</span>
              <div className="d-flex align-items-center">
                <ActivityPointsIcon style={{ marginRight: '6px' }} />
                <span>375 activity points</span>
              </div>
            </div>

            {showProfileActions && (
              <PatientProfilePopup
                onMouseEnter={() => handleMouseEnter(setShowProfileActions)}
                onMouseLeave={() => handleMouseLeave(setShowProfileActions)}
              />
            )}
          </div>
          {showPointsIcon && (
            <Col
              style={{
                borderLeft: '2px solid #EFEFF0',
                borderBottom: '2px solid #EFEFF0',
                padding: 11,
                cursor: 'pointer',
                maxWidth: 50,
                maxHeight: 50,
                backgroundColor: '#F8F9FB',
              }}
            >
              <img style={{ width: 25, height: 25 }} src={pointsIcon} alt="" />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Navbar;
