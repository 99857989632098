import { CarePlanActionType } from 'types/ApiModels/CarePlan/ActionType';

export enum ActionTypeLabel {
  OXYGEN_LEVEL = 'Oxygen Level',
  BPM = 'BPM',
  BLOOD_PRESSURE = 'Blood Pressure',
  WEIGHT = 'Weight',
  TEMPERATURE = 'Temperature',
  STEPS = 'Steps',
  STAIRS = 'Stairs',
  SLEEP = 'Sleep',
  BLOOD_SUGAR = 'Blood Sugar',
  STRESS_LEVEL = 'Stress Level',
  CHECK_UP = 'Check Up',
  QUESTIONNAIRE = 'Questionnaire',
  UPLOAD_FILE = 'Upload File',
  SELECT_FROM_PATIENT_DOCUMENT = 'Select From Patient Document',
  MEDICATION = 'Medication',
}

export const mapLabelToActionType: (label: ActionTypeLabel) => CarePlanActionType = (label) => {
  switch (label) {
    case ActionTypeLabel.OXYGEN_LEVEL:
      return 'oxygen_level';
    case ActionTypeLabel.BPM:
      return 'bpm';
    case ActionTypeLabel.BLOOD_PRESSURE:
      return 'blood_pressure';
    case ActionTypeLabel.WEIGHT:
      return 'weight';
    case ActionTypeLabel.TEMPERATURE:
      return 'temperature';
    case ActionTypeLabel.STEPS:
      return 'steps';
    case ActionTypeLabel.STAIRS:
      return 'stairs';
    case ActionTypeLabel.SLEEP:
      return 'sleep';
    case ActionTypeLabel.BLOOD_SUGAR:
      return 'blood_sugar';
    case ActionTypeLabel.STRESS_LEVEL:
      return 'stress_level';
    case ActionTypeLabel.CHECK_UP:
      return 'check_up';
    case ActionTypeLabel.QUESTIONNAIRE:
      return 'questionnaire';
    case ActionTypeLabel.UPLOAD_FILE:
      return 'upload_file';
    case ActionTypeLabel.SELECT_FROM_PATIENT_DOCUMENT:
      return 'select_from_patient_documents';
    case ActionTypeLabel.MEDICATION:
      return 'medication';
    default:
      return null;
  }
};

export const mapActionTypeToLabel: (eventType: CarePlanActionType) => string = (eventType) => {
  switch (eventType) {
    case 'oxygen_level':
      return ActionTypeLabel.OXYGEN_LEVEL;
    case 'bpm':
      return ActionTypeLabel.BPM;
    case 'blood_pressure':
      return ActionTypeLabel.BLOOD_PRESSURE;
    case 'weight':
      return ActionTypeLabel.WEIGHT;
    case 'temperature':
      return ActionTypeLabel.TEMPERATURE;
    case 'steps':
      return ActionTypeLabel.STEPS;
    case 'stairs':
      return ActionTypeLabel.STAIRS;
    case 'sleep':
      return ActionTypeLabel.SLEEP;
    case 'blood_sugar':
      return ActionTypeLabel.BLOOD_SUGAR;
    case 'stress_level':
      return ActionTypeLabel.STRESS_LEVEL;
    case 'check_up':
      return ActionTypeLabel.CHECK_UP;
    case 'questionnaire':
      return ActionTypeLabel.QUESTIONNAIRE;
    case 'upload_file':
      return ActionTypeLabel.UPLOAD_FILE;
    case 'select_from_patient_documents':
      return ActionTypeLabel.SELECT_FROM_PATIENT_DOCUMENT;
    case 'medication':
      return ActionTypeLabel.MEDICATION;
    default:
      return 'Add Care Plan Action';
  }
};
