import ArrowDownTodayDatePicker from 'components/ArrowDownTodayDatePicker';
import FiltersButton from 'components/FiltersButton';
import TimePeriodSelect, { TimePeriodOptions } from 'components/TimePeriodSelect';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { setPeriodSelected, setSelectedDate } from 'screens/Patients/store/PatientTimeline';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import styles from './styles.module.css';

const PatientTimelineHeader = () => {
  const handleFiltersClick = () => {
    //TODO: handle filters
    return;
  };

  const {
    dispatch,
    patientState: {
      patientTimeline: { periodSelected, selectedDate },
    },
  } = usePatientContext();

  const handleDatePickChange = (value: DatePickerValue) => {
    const newDateValue = convertDatepickToDate(value);
    dispatch(setSelectedDate(newDateValue));
  };

  const handleTimePeriodChange = (newTimePeriod: TimePeriodOptions) => {
    dispatch(setPeriodSelected(newTimePeriod));
  };

  return (
    <div className={`dashboard-item-header ${styles.main}`}>
      <div className="d-flex gap-sm align-items-center">
        <div className="d-flex gap">
          <span className="font-size-big">Patient activity</span>
          <TimePeriodSelect
            selectedTimePeriod={periodSelected}
            handleTimePeriodClick={handleTimePeriodChange}
          />
        </div>
        <ArrowDownTodayDatePicker
          value={convertDateToDatePickValue(selectedDate)}
          onChange={handleDatePickChange}
        />
      </div>

      <FiltersButton onFiltersClick={handleFiltersClick} />
    </div>
  );
};

export default PatientTimelineHeader;
