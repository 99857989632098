import React from 'react';
import styles from './styles.module.css';
import { mapTimePeriodToLabel, TimePeriodOptions } from './utils';

interface ITimePeriodSelect {
  selectedTimePeriod: TimePeriodOptions;
  handleTimePeriodClick: (timePeriod: TimePeriodOptions) => void;
  timeOptions?: TimePeriodOptions[];
  disabledOptions?: TimePeriodOptions[];
}

const TimePeriodSelect = ({
  selectedTimePeriod,
  handleTimePeriodClick,
  timeOptions = [TimePeriodOptions.DAY, TimePeriodOptions.WEEK, TimePeriodOptions.MONTH],
  disabledOptions = null,
}: ITimePeriodSelect) => {
  return (
    <div className="d-flex gap ">
      {timeOptions.map((timeOption: TimePeriodOptions, idx: number) => {
        if (disabledOptions?.includes(timeOption)) {
          return (
            <div key={idx} className={styles.period} style={{ color: 'gray' }}>
              {mapTimePeriodToLabel(timeOption)}
            </div>
          );
        } else {
          return (
            <p
              key={idx}
              className={`${styles.period} ${
                timeOption === selectedTimePeriod ? styles.selected : ''
              }`}
              onClick={() => handleTimePeriodClick(timeOption)}
              style={{ whiteSpace: 'nowrap' }}
            >
              {mapTimePeriodToLabel(timeOption)}
            </p>
          );
        }
      })}
    </div>
  );
};
export default TimePeriodSelect;
