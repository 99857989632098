import React, { ChangeEvent, CSSProperties } from 'react';
import styles from './styles.module.css';

interface ISelect {
  options?: any;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  style?: CSSProperties;
  placeholder?: string;
  containerClassName?: string;
  selectClassName?: string;
  currentValue?: string;
  name?: string;
  onBlur?: () => void;
  selectProps?: React.HTMLProps<HTMLSelectElement>;
}

export interface IOption {
  key?: string;
  label?: any;
  value?: any;
}

export default function Select({
  options = [],
  onChange,
  style = null,
  placeholder = '',
  containerClassName = '',
  selectClassName = '',
  currentValue = '',
  name = '',
  onBlur = () => null,
  selectProps = {},
}: ISelect) {
  return (
    <div className={`${containerClassName || ''} ${styles.selectContainer}`}>
      <select
        className={`${selectClassName || ''} ${styles.vytracSelect}`}
        aria-label="Default select example"
        style={style}
        onChange={onChange}
        value={currentValue}
        // defaultValue={placeholder ? '' : options[0]?.option}
        name={name}
        onBlur={onBlur}
        {...selectProps}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options?.map((option: IOption, index: number) => (
          <option
            key={index}
            id={index.toString()}
            value={option.value !== undefined ? option.value : option}
          >
            {option.label !== undefined ? option.label : option}
          </option>
        ))}
      </select>
    </div>
  );
}
