import PatientManagementSelect from 'components/PatientManagementSelect';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import SelectValueLabel from 'types/Shared/SelectValueLabel';
import { PatientManagementFormikKeys, PatientManagementFormikValues } from '../types';
import HealthScoreSelect from './HealthScoreSelect';
import styles from './styles.module.css';

const formikFields = [
  {
    label: 'yellow alert',
    name: `${PatientManagementFormikKeys.HEALTH_SCORING}.missed_calls_yellow_alert`,
  },
  {
    label: 'Red alert',
    name: `${PatientManagementFormikKeys.HEALTH_SCORING}.missed_calls_red_alert`,
  },
];

const healthScoreOptions = [
  ...Object.keys([...Array(10)]).map((hs) => {
    const value = parseInt(hs) + 1;
    return { value, label: `${value} missed call${value > 1 ? 's' : ''}` };
  }),
];

const renderValueLabelOption = (opt: SelectValueLabel) => (
  <option value={opt.value} key={opt.value} className="text-capitalize">
    {opt.label}
  </option>
);

const HealthScoreTableRow = ({
  formikFields,
  onSelectChange,
}: {
  formikFields: { label: string; name: string }[];
  onSelectChange: (name: string) => (values) => void;
}) => {
  return (
    <>
      {formikFields.map((ff) => {
        // return <HealthScoreSelect onChange={onSelectChange} name={ff.name} key={ff.name} />;
        return (
          <td className="pr-2" key={ff.name}>
            <PatientManagementSelect
              value={ff.name}
              error={undefined}
              name={ff.name}
              onChange={onSelectChange}
              options={healthScoreOptions}
              renderOption={renderValueLabelOption}
            />
          </td>
        );
      })}
    </>
  );
};

const HealthScoringCard: FC = () => {
  const { setFieldValue, setFieldTouched } = useFormikContext<PatientManagementFormikValues>();
  const handleSelectChange = (name: string) => {
    return (value) => {
      setFieldTouched(name, true);
      setFieldValue(name, value.value, true);
    };
  };
  return (
    <div className={`vytrac-card ${styles.body}`}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className="d-flex gap">
              <div className="text-uppercase font-weight-md font-size-big">Health scoring</div>
            </th>
            {['Yellow alert', 'Red alert', ''].map((t, idx) => (
              <th className="font-size-big" key={idx}>
                {t}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="gap">
            <td>Health scoring for calls</td>
            <HealthScoreTableRow formikFields={formikFields} onSelectChange={handleSelectChange} />
            <td className="w-100" />
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default HealthScoringCard;
