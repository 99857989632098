import { useMemo } from 'react';
import { IWebSocketVytalAlertMessage } from 'types/WebSocket/WebSocket';
import { useWebSocketContext } from 'websockets/WebSocketContext';

const useWebSocketYellowAlerts = (): {
  received: IWebSocketVytalAlertMessage;
} => {
  const {
    state: {
      alerts: { yellowAlert },
    },
  } = useWebSocketContext();
  return { received: yellowAlert };
};

export default useWebSocketYellowAlerts;
