import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface IInvalidStartDateModal {
  show: boolean;
  onConfirm: () => void;
}

const InvalidStartDateModal = ({ show, onConfirm }: IInvalidStartDateModal) => {
  return (
    <VytracModal
      show={show}
      title="Invalid Start Date"
      body={
        <div className="font-size-big">
          The start date of the action occurs after the end of the care plan. If you wish to set
          this start date, increase the duration of the care plan
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-end">
          <Button variant="confirm" label="Confirm" onClick={onConfirm} />
        </div>
      }
      onClose={onConfirm}
      centered
    />
  );
};
export default InvalidStartDateModal;
