import { PaginatedResponse } from 'types/ApiModels/General';
import Patient from 'types/ApiModels/Patients/Patient';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';

export enum PatientActionTypes {
  TOGGLE_SHOW_INVITE_PATIENT = 'TOGGLE_SHOW_INVITE_PATIENT',
  SET_PATIENTS_BOOK = 'SET_PATIENTS_BOOK',
  SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT',
  SET_SINGLE_PATIENT_SUBMIT_FORM = 'SET_SINGLE_PATIENT_SUBMIT_FORM',
  SET_SINGLE_PATIENT_RESET_FORM = 'SET_SINGLE_PATIENT_RESET_FORM',
  SET_SINGLE_PATIENT_ARCHIVE = 'SET_SINGLE_PATIENT_ARCHIVE',
  SET_SINGLE_PATIENT_FORM_DIRTY = 'SET_SINGLE_PATIENT_FORM_DIRTY',
}

export type PatientAction =
  | { type: PatientActionTypes.TOGGLE_SHOW_INVITE_PATIENT }
  | {
      type: PatientActionTypes.SET_PATIENTS_BOOK;
      payload: { patientsBook: Record<number, PaginatedResponse<PatientGet>> };
    }
  | { type: PatientActionTypes.SET_SELECTED_PATIENT; payload: { patient: PatientGetSingle } }
  | { type: PatientActionTypes.SET_SINGLE_PATIENT_RESET_FORM; payload: { resetForm: () => void } }
  | { type: PatientActionTypes.SET_SINGLE_PATIENT_ARCHIVE; payload: { archivePatient: () => void } }
  | { type: PatientActionTypes.SET_SINGLE_PATIENT_SUBMIT_FORM; payload: { submitForm: () => void } }
  | { type: PatientActionTypes.SET_SINGLE_PATIENT_FORM_DIRTY; payload: { dirty: boolean } };
