import CarePlanActionModal from 'components/RPM/ActionModal';
import RpmActionButton from 'components/RPM/RpmActionButton';
import { ActionEventTypeLabel } from 'components/RPM/utils/eventTypeMapper';
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { initialAction } from 'screens/Patients/Content/components/PatientTabs/components/PatientCarePlan/components/PatientCarePlanTimeline/util/initialAction';
import { ITemplateParams } from 'screens/Templates/components/Content/Content';
import {
  setCarePlanModalEventType,
  setPatientActions,
  setPreviousPatientActions,
  setSelectedAction,
} from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { createTemplateAction } from 'services/templatesService';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { transformToPatientAction } from 'util/calendarUtils/transformers/transformToPatientAction';
import { transformToTemplateAction } from 'util/calendarUtils/transformers/transformToTemplateAction';
import { templateCarePlanStartDate } from '../../../../../util';
import DisabledCalendarModal from '../../../../DisabledCalendarModal';
import NotAnAdminModal from '../../../../NotAnAdminModal';
import InvalidStartDateModal from './InvalidStartDateModal';
import { isActionAfterDuration } from './utils';
import './styles.css';

const CreateAction = ({ onCancel }: { onCancel: () => void }) => {
  const params = useParams<ITemplateParams>();
  const {
    dispatch,
    templateState: {
      templateCarePlan: {
        carePlanModalEventType,
        selectedAction,
        patientActions,
        selectedDate,
        carePlanState,
        userIsStaff,
      },
    },
  } = useTemplateContext();

  const [showNotAnAdminModal, setShowNotAnAdminModal] = useState<boolean>(false);
  const [showDisabledCalendarModal, setShowDisabledCalendarModal] = useState<boolean>(false);
  const [showInvalidStartDateModal, setShowInvalidStartDateModal] = useState<boolean>(false);

  const onAddCustomAction = () => {
    if (isNaN(Number(params.id))) {
      setShowDisabledCalendarModal(true);
      return;
    }

    if (carePlanState.creator_is_admin && !userIsStaff) {
      setShowNotAnAdminModal(true);
      return;
    }

    dispatch(setPreviousPatientActions());
    dispatch(
      setSelectedAction({
        ...initialAction,
        start_date: selectedDate.toISOString(),
        end_date: selectedDate.toISOString(),
      } as IPatientTimelineAction)
    );
    dispatch(setCarePlanModalEventType(null));
  };

  const onCreateActionConfirm = async () => {
    if (
      isActionAfterDuration(
        selectedAction,
        carePlanState.duration_type,
        carePlanState.duration_quantity
      )
    ) {
      setShowInvalidStartDateModal(true);
      return;
    }

    const selectedTemplateAction = transformToTemplateAction(
      selectedAction,
      templateCarePlanStartDate
    );

    const newTemplateAction = await createTemplateAction(Number(params.id), selectedTemplateAction);
    const newPatientAction = transformToPatientAction(newTemplateAction, templateCarePlanStartDate);

    let updatedPatientActions = [...patientActions];
    const actionIdx = updatedPatientActions.findIndex((action) => action.id === selectedAction.id);
    if (actionIdx === -1) {
      updatedPatientActions = [...updatedPatientActions, newPatientAction];
    } else {
      updatedPatientActions[actionIdx] = { ...newPatientAction };
    }
    dispatch(setPatientActions(updatedPatientActions));
    dispatch(setSelectedAction(null));
  };

  return (
    <div className="m-3">
      <div className="font-size-big mb-3">Commonly used care plan actions</div>
      <Droppable droppableId="calendar-control">
        {(provided) => (
          <div
            className="d-flex flex-wrap mb-4 justify-content-between"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {Object.values(ActionEventTypeLabel).map((carePlanAction, index) => (
              <RpmActionButton
                text={carePlanAction}
                id={carePlanAction}
                key={carePlanAction}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <button id="add-rpm-action-btn" onClick={onAddCustomAction}>
        add custom action
      </button>
      <CarePlanActionModal
        eventType={carePlanModalEventType}
        show={typeof selectedAction?.id !== 'number'}
        onCancel={onCancel}
        onClose={onCancel}
        onConfirm={onCreateActionConfirm}
        modalData={selectedAction}
        setModalData={(updatedSelectedAction) => dispatch(setSelectedAction(updatedSelectedAction))}
        modalType="template"
        relativeMinDate={templateCarePlanStartDate}
      />
      <NotAnAdminModal show={showNotAnAdminModal} onConfirm={() => setShowNotAnAdminModal(false)} />
      <DisabledCalendarModal
        show={showDisabledCalendarModal}
        onConfirm={() => setShowDisabledCalendarModal(false)}
      />
      <InvalidStartDateModal
        show={showInvalidStartDateModal}
        onConfirm={() => setShowInvalidStartDateModal(false)}
      />
    </div>
  );
};
export default CreateAction;
