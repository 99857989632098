import DateRangePicker from 'components/DateRangePicker';
import FiltersButton from 'components/FiltersButton';
import SatisfactionBar from 'components/SatisfactionBar';
import { useEffect, useState } from 'react';
import useSatisfaction from 'screens/Analytics/store/hooks/useSatisfaction';
import styles from '../styles.module.css';

const PatientSatisfaction = () => {
  const [date, setDate] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  });
  const [porcentage, setPorcentage] = useState<number>();

  const [satisfaction, __, refetch] = useSatisfaction(date.from, date.to);

  useEffect(() => {
    if (satisfaction) setSatisfactionPorcentage();
  }, [satisfaction]);

  const setSatisfactionPorcentage = () => {
    const data = (satisfaction.bad / (satisfaction.bad + satisfaction.good)) * 100; 
   setPorcentage(data)
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>Patient Satisfaction</div>
        <div className={styles.controls}>
          <DateRangePicker
            currentDateRange={date}
            handleDateRangePickerChange={(range) => setDate(range)}
            calendarClassName={styles.calendar}
            wrapperClassName={styles.datePickerWrapper}
            inputClassName={styles.datePicker}
          />
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      <div className="d-flex justify-content-between px-4 my-2">
        <span className={styles.spanValue}>{`${satisfaction?.bad}`}</span>
        <span className={styles.spanValue}>{`${satisfaction?.good}`}</span>
      </div>
      <div className="d-flex justify-content-between px-4">
        <span className={styles.span}>Bad</span>
        <span className={styles.span}>Good</span>
      </div>
      <div className="d-flex justify-content-center">
        <SatisfactionBar className={styles.satisfactionBar} value={porcentage} />
      </div>
    </div>
  );
};

export default PatientSatisfaction;
