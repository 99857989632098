import { IWebSocketVytalAlertMessage } from 'types/WebSocket/WebSocket';
import { useWebSocketContext } from 'websockets/WebSocketContext';

const useWebSocketRedAlerts = (): { received: IWebSocketVytalAlertMessage } => {
  const {
    state: {
      alerts: { redAlert },
    },
  } = useWebSocketContext();
  return { received: redAlert };
};

export default useWebSocketRedAlerts;
