import ChatGearIcon from 'assets/icons/chat_gear.svg';
import UserBubble, { UserBubbleUser } from 'components/UserBubble';
import { FC, useMemo } from 'react';
//mocks
import { ChatData } from 'screens/messages/mocks/types';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import { timeAgoWithHours } from 'util/dateUtils';
import styles from './styles.module.css';

interface ChatElementProps {
  chat: ChatData;
  onClick?: () => void;
  isCurrent: boolean;
}

const ChatElement: FC<ChatElementProps> = ({ chat, onClick, isCurrent }: ChatElementProps) => {
  const {
    state: { getMetadataByParticipantSID },
  } = useMessagesContext();

  const lastActive = useMemo(() => {
    return chat.last_active_time ? timeAgoWithHours(new Date(chat.last_active_time)) : '';
  }, [chat]);

  const hasGear = useMemo(() => chat.attributes?.subject_type === 'technical', [chat]);

  const urgent = useMemo(() => chat.urgent, [chat]);

  const newMessages = useMemo(() => chat.unread_messages > 0, [chat]);

  const lastMessageParticipantMetadata = getMetadataByParticipantSID
    ? getMetadataByParticipantSID(chat.last_message_participant_sid)
    : null;

  const conversationDescription = lastActive ? (
    chat.attributes?.subject ? (
      <>
        <span>{lastActive}</span>
        <span>•</span>
        <span>{lastMessageParticipantMetadata?.full_name}:</span>
        <span>{chat.last_message}</span>
      </>
    ) : (
      <>
        <span>{lastActive}</span>
        <span>•</span>
        {<span>{lastMessageParticipantMetadata?.full_name}:</span>}
        <span>{chat.last_message}</span>
      </>
    )
  ) : null;

  const [first_name, last_name] = chat.patientMetadata?.full_name?.split(' ') ?? [];
  const patientUserBubble: UserBubbleUser = {
    first_name,
    last_name,
    imageUrl: chat.patientMetadata?.photo_thumbnail,
  };
  return (
    <div
      className={`${styles['chat-item']} ${isCurrent ? styles['chat-item-current'] : ''}`}
      onClick={onClick}
    >
      <div className="position-relative">
        <UserBubble containerClassNameList={['ml-0']} user={patientUserBubble} />

        {(urgent || newMessages) && (
          <div
            className={styles['notification-right']}
            data-content={urgent || !newMessages ? '' : chat.unread_messages}
            style={{ backgroundColor: urgent ? '#F5485C' : '#785992' }}
          ></div>
        )}
        {hasGear && (
          <div
            className={styles['notification-left']}
            style={{
              backgroundImage: `url(${ChatGearIcon})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          ></div>
        )}
      </div>
      <div
        className={`${styles['chat-info']} d-flex flex-column gap-sm`}
        style={{ fontWeight: chat.unread_messages > 0 ? 'bold' : undefined }}
      >
        <div className={styles['conversation-title']}>{chat.patientMetadata?.full_name}</div>
        <div className="d-flex flex-column">
          {chat.attributes?.subject ? (
            <p className="m-0 p-0 font-size-medium">Subject: {chat.attributes.subject}</p>
          ) : null}
          <div className={`d-flex gap-sm font-size-medium ${styles['patient-last-message']}`}>
            {conversationDescription}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatElement;
