import { useEffect, useRef } from 'react';
import sendDashboardTrackingLog from 'screens/ProviderDashboard/utils/tracking/sendTrackingLog';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import { resetTrackingDuration } from '../DashboardActionCreators';
import { useDashboardContext } from '../DashboardContext';

const useDashboardTracking = (): void => {
  const {
    dispatch,
    state: {
      trackingDuration,
      trackingPatientId,
      timerIsTracking,
      trackingActivityId,
      trackingType,
    },
  } = useDashboardContext();

  const prevPatientId = useRef<number>();
  const prevTrackingType = useRef<TrackingType>();

  /** send tracking log on client change */
  useEffect(() => {
    if (trackingDuration) {
      sendDashboardTrackingLog(
        trackingDuration,
        trackingActivityId,
        prevTrackingType.current,
        prevPatientId.current
      );
      dispatch(resetTrackingDuration());
    }
    prevPatientId.current = trackingPatientId;
    prevTrackingType.current = trackingType;
  }, [trackingPatientId]);

  /** send tracking log on tracking duration */
  useEffect(() => {
    if (trackingDuration < 60000) return;

    sendDashboardTrackingLog(trackingDuration, trackingActivityId, trackingType, trackingPatientId);
    dispatch(resetTrackingDuration());
  }, [trackingDuration]);

  /** send tracking log on timer stop */
  useEffect(() => {
    if (!trackingDuration || timerIsTracking) return;

    sendDashboardTrackingLog(trackingDuration, trackingActivityId, trackingType, trackingPatientId);
    dispatch(resetTrackingDuration());
  }, [timerIsTracking]);
};

export default useDashboardTracking;
