import React, { useState } from 'react';
import VyTracLayout from '../../components/VyTracLayout';
import BreadCrumbsWithActions from '../../components/BreadcrumbsWithActions';
import Context from './util/Context';
import Content from './components/Content';
import { billingInitialBreadcrumbPathList } from './util/billingUtils';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';

export interface BillingState {
  patientList: [],
  filteredPatientList: [],
  searchValue: '',
  patientPageList: [[]],
  breadcrumbPathList: BreadCrumbPath[],
  actions: () => JSX.Element | null;
  selectedPatient: null,
}

const Billing = () => {
  const [state, setState] = useState<BillingState>({
    patientList: [],
    filteredPatientList: [],
    searchValue: '',
    patientPageList: [[]],
    breadcrumbPathList: billingInitialBreadcrumbPathList,
    actions: null,
    selectedPatient: null,
  });

  const context = {
    state,
    setState,
  };

  const Actions = state.actions;

  return (
    <VyTracLayout selectedRoute="Revenue Management">
      <Context.Provider value={context}>
        <BreadCrumbsWithActions breadcrumbs={state.breadcrumbPathList}>
          {Actions && <Actions />}
        </BreadCrumbsWithActions>
        <Content />
      </Context.Provider>
    </VyTracLayout>
  );
};

export default Billing;
