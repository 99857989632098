import { Role } from 'types/ApiModels/Administration';
import { AdminUsersAction, AdminUsersActionTypes } from './adminUsersActionTypes';

export const setAdminRoles = (roles: Role[]): AdminUsersAction => ({
  type: AdminUsersActionTypes.SET_ROLES,
  payload: { roles },
});

export const upsertAdminRole = (role: Role): AdminUsersAction => ({
  type: AdminUsersActionTypes.UPSERT_ROLE,
  payload: { role },
});

export const upsertAdminRoleBulk = (roles: Role[]): AdminUsersAction => ({
  type: AdminUsersActionTypes.UPSERT_ROLE_BULK,
  payload: { roles },
});
