import CareTeamAsyncSelect, { CareTeamSelectOption } from 'components/CareTeamAsyncSelect';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { InviteProviderFormikValues } from '../InviteProviderForm';

//? not sure whether it makes sense to make this a prop
const fieldName = 'careTeamId';

interface CareTeamProviderInviteFieldProps {
  onChange: (values) => void;
  value: CareTeamSelectOption;
}

const CareTeamProviderInviteField: FC<CareTeamProviderInviteFieldProps> = ({
  onChange,
  value,
}: CareTeamProviderInviteFieldProps) => {
  const { errors, touched, setFieldValue } = useFormikContext<InviteProviderFormikValues>();

  const handleChange = (selectOption) => {
    setFieldValue(fieldName, selectOption.value, true);
    onChange(selectOption);
  };

  return (
    <div className="flex-grow-1">
      <Field name={fieldName}>
        {() => (
          <CareTeamAsyncSelect onChange={handleChange} value={value} className="font-size-medium" />
        )}
      </Field>
      {errors[fieldName] && touched[fieldName] && typeof errors[fieldName] === 'string' ? (
        <ErrorMessage name={fieldName}>
          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
        </ErrorMessage>
      ) : null}
    </div>
  );
};
export default CareTeamProviderInviteField;
