import moment from 'moment';
import {
  createTemplateAction,
  deleteTemplateAction,
  updateTemplateAction,
} from 'services/templatesService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { addExceptionToRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/addExceptionToRecurrence';
import { generateFutureRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/generateFutureRecurrence';
import { transformToTemplateAction } from 'util/calendarUtils/transformers';

export const deleteOnlyThisEvent = async (
  templateId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction,
  blockStartDate: Date
): Promise<IPatientCarePlanAction[]> => {
  let updatedPatientActions = [...patientActions];
  let originalActionIndex: number;
  let originalAction: IPatientCarePlanAction;

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    originalActionIndex = updatedPatientActions.findIndex(
      (action) => action.id === selectedTimelineAction.id
    );
    originalAction = { ...updatedPatientActions[originalActionIndex] };

    // generate recurrent action starting from next action
    const nextRecurrentAction = generateFutureRecurrence(moment(blockStartDate), originalAction);
    if (nextRecurrentAction) {
      const nextTemplateRecurrentAction = transformToTemplateAction(
        nextRecurrentAction,
        blockStartDate
      );
      const newTemplateAction = await createTemplateAction(templateId, nextTemplateRecurrentAction);
      updatedPatientActions = [
        ...updatedPatientActions,
        {
          ...nextRecurrentAction,
          id: newTemplateAction.id,
        },
      ];
    }

    // delete original action
    updatedPatientActions.splice(originalActionIndex, 1);
    await deleteTemplateAction(templateId, originalAction.id);
  } else {
    // means action is recurrent generated
    const updatedOriginalAction = addExceptionToRecurrence(
      originalActionIndex,
      updatedPatientActions,
      selectedTimelineAction
    );
    const updatedTemplateAction = transformToTemplateAction(updatedOriginalAction, blockStartDate);
    await updateTemplateAction(templateId, updatedTemplateAction);
  }

  return updatedPatientActions;
};
