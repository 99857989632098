import Card from 'components/Card';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import { FC, useMemo } from 'react';
import { mapCareTeamProviderToUserBubble } from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/AdminCareTeam/utils';
import { CareTeam } from 'types/ApiModels/CareTeam';
import { getNameInitials, getUserFullName } from 'util/userUtils';
import styles from './styles.module.css';

interface CareTeamCardProps {
  careTeam: CareTeam;
}

const CareTeamCard: FC<CareTeamCardProps> = ({ careTeam }: CareTeamCardProps) => {
  const currentInitials = useMemo(() => {
    if (!careTeam) return [];
    const teamLeadInitials = careTeam.tiers?.[0]?.providers?.[0]
      ? [
          getNameInitials(
            getUserFullName(mapCareTeamProviderToUserBubble(careTeam.tiers?.[0]?.providers?.[0]))
          ),
        ]
      : [];
    const membersInitials =
      careTeam.tiers.length > 1
        ? careTeam.tiers.slice(1).flatMap((t) => {
            return t.providers
              .slice(0, 3)
              .map((p) => getNameInitials(getUserFullName(mapCareTeamProviderToUserBubble(p))));
          }, [])
        : [];

    return [...teamLeadInitials, ...membersInitials];
  }, [careTeam]);
  return (
    <div className="dashboard-detail">
      <div className="dashboard-item-header font-weight-md">Care Team</div>
      {currentInitials.length ? (
        <div className="d-flex gap align-items-center" style={{ padding: '20px 20px 0px 20px' }}>
          <UserBubbleGroupCollapsed initials={currentInitials} />
          <div>{careTeam?.name}</div>
        </div>
      ) : (
        <div style={{ padding: '20px 20px 0px 20px' }}>
          <TextSurroundedWithLines
            className={`text-gray-low-risk ${styles.noCareTeam}`}
            text="No care teams assigned to this patient yet"
          />
        </div>
      )}
    </div>
  );
};
export default CareTeamCard;
