import tabEnum from './tabEnum';

const tabSubtitleMap = {
  [tabEnum.ALL_CODES]: 'All Codes',
  [tabEnum.READY_TO_SUBMIT]: 'Ready to submit codes',
  [tabEnum.SUBMITTED]: 'Submitted codes',
  [tabEnum.PAID]: 'Paid codes',
};

export default tabSubtitleMap;
