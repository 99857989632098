import { vytracAxios } from 'ajax';
import { PaginatedResponse } from 'types/ApiModels/General';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';

const mapQuestionnaireToApi = (elements, name: string, template_id: number = null) => {
  const questions = [];
  const answers = [];
  const edges = [];
  elements.forEach((element) => {
    switch (element.type) {
      case 'question':
        questions.push({
          id: element.id.split('_')[1],
          question: element.data.question.value,
          position_x: element.position.x,
          position_y: element.position.y,
        });
        break;
      case 'answer':
        answers.push({
          id: element.id.split('_')[1],
          value: element.data.answer.value,
          position_x: element.position.x,
          position_y: element.position.y,
          critical: !!element.data.critical,
          question_id: element.data.questionId.split('_')[1],
        });
        break;
      default:
        edges.push({
          id: element.id,
          source: element.source.split('_')[1],
          target: element.target.split('_')[1],
        });
        break;
    }
  });

  let questionnaire: IQuestionnaire = {
    name,
  };
  if (template_id) {
    questionnaire = { ...questionnaire, template_id };
  }

  return {
    questionnaire,
    questions,
    answers,
    edges,
  };
};
const mapApiToQuestionnaire = (questionnaire) => {
  const mapPosition = (elem) => {
    const newAns = {
      ...elem,
      position: { x: elem.position_x, y: elem.position_y },
    };
    delete newAns.position_x;
    delete newAns.position_y;
    return newAns;
  };

  return {
    name: questionnaire.questionnaire.name,
    answers: questionnaire.answers.map(mapPosition),
    questions: questionnaire.questions.map(mapPosition),
    edges: questionnaire.edges,
  };
};

const mapApiRepliesToPatientQuestionnaire = (patientQuestionnaire, replies = []) => {
  const questionOrder = [];
  const answersMap = {};
  replies.forEach((reply, index) => {
    const key = reply.question_id;
    if (!answersMap[key]) {
      answersMap[key] = [];
    }
    const edge = patientQuestionnaire.edges.find((edge) => reply.answer_id === edge.source);
    if (edge) {
      questionOrder.push(edge.target);
    } else {
      questionOrder.push(key);
    }
  });
  patientQuestionnaire.answers.forEach((answer) => {
    if (answersMap[answer.question_id]) {
      answersMap[answer.question_id].push({
        id: answer.id,
        answer: answer.value,
        critical: answer.critical,
        selected: replies.some(
          (reply) => reply.question_id === answer.question_id && reply.answer_id === answer.id
        ),
      });
    }
  });
  const questions = patientQuestionnaire.questions
    .filter((question) => answersMap[question.id])
    .map((question) => ({
      id: question.id,
      question: question.question,
    }))
    .sort(
      (q1, q2) =>
        questionOrder.findIndex((e) => e === q1.id) - questionOrder.findIndex((e) => e === q2.id)
    );
  const answers = [];
  questions.forEach((question) => {
    const answer = answersMap[question.id];
    if (answer) {
      answers.push(answer);
    }
  });
  const criticalCount = answers
    .flatMap((ans) => ans)
    .filter((answer) => answer.critical && answer.selected).length;

  return {
    id: patientQuestionnaire.questionnaire.id,
    questionnaireName: patientQuestionnaire.questionnaire.name,
    date: '2021-12-10T13:40:37.834142Z',
    critical: criticalCount,
    questionLen: questions.length,
    questions,
    answers,
  };
};

export async function getAllQuestionnaries() {
  try {
    const response = await vytracAxios.get<PaginatedResponse<IQuestionnaire>>(
      'questionnaire/?size=1000'
    );
    return response.data.results;
  } catch (exception) {
    throw exception;
  }
}

export const getQuestionnaireTemplates = async () => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<IQuestionnaire>>(
      'questionnaire/?size=1000'
    );
    return response.data.results.filter((questionnaire) => !questionnaire.template_id);
  } catch (exception) {
    throw exception;
  }
};

export async function getQuestionnaire(id) {
  try {
    const response = await vytracAxios.get(`questionnaire/${id}/`);
    const parsedData = mapApiToQuestionnaire(response.data);
    return parsedData;
  } catch (exception) {
    throw exception;
  }
}

export async function createQuestionnaire(elements, name: string, template_id: number = null) {
  try {
    const response = await vytracAxios.post(
      'questionnaire/',
      mapQuestionnaireToApi(elements, name, template_id)
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
}

export async function updateQuestionnaire(id, { elements, name }) {
  try {
    const response = await vytracAxios.put(
      `questionnaire/${id}/`,
      mapQuestionnaireToApi(elements, name)
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
}
export async function deleteQuestionnaire(id: number) {
  try {
    const response = await vytracAxios.delete(`questionnaire/${id}/`);
    return response.data;
  } catch (exception) {
    throw exception;
  }
}

export async function getPatientQuestionnaires(patientId: number) {
  try {
    const response = await vytracAxios.get<IPatientQuestionnaire[]>(
      `patient/patient_questionnaire/${patientId}/`
    );

    return response.data;
  } catch (exception) {
    throw exception;
  }
}

export async function pushQuestionnaireTemplateToPatient(templateId, patientId) {
  try {
    const response = await vytracAxios.post(`/patient/patient_questionnaire_template/`, {
      questionnaire_id: templateId,
      patient_id: patientId,
    });
    return response.data;
  } catch (exception) {
    throw exception;
  }
}

export async function pushNewQuestionnaireToPatient(elements, name, patientId) {
  try {
    const data = mapQuestionnaireToApi(elements, name);
    const response = await vytracAxios.post(`/patient/patient_questionnaire/`, {
      ...data,
      questionnaire: {
        ...data.questionnaire,
        patient_id: patientId,
      },
    });
    return response.data;
  } catch (exception) {
    throw exception;
  }
}

export async function getQuestionnaireReplies(questionnaireId) {
  try {
    const replies = await vytracAxios.get(
      `/patient/patient_questionnaire_reply/${questionnaireId}/`
    );
    const questionnaireData = await vytracAxios.get(`questionnaire/${questionnaireId}/`);

    return mapApiRepliesToPatientQuestionnaire(questionnaireData.data, replies.data);
  } catch (exception) {
    throw exception;
  }
}

export async function getAvailableQuestionnairesOfPatient(patientId: number) {
  // try {
  //   const questionnaireTemplates = await getAllQuestionnaries();
  //   const patientQuestionnaires = await getPatientQuestionnaires(patientId);
  //   const availableQuestionnaires = patientQuestionnaires
  //     .filter((questionnaire: IPatientQuestionnaire) =>
  //       questionnaireTemplates.every((template) => template.id !== questionnaire.id)
  //     )
  //     .map((questionnaire: IPatientQuestionnaire) => ({
  //       name: questionnaire.name,
  //       population: questionnaire.usedFor,
  //       id: questionnaire.key,
  //     }));
  //   return availableQuestionnaires.concat(questionnaireTemplates);
  // } catch (exception) {
  //   throw exception;
  // }
  return [];
}
