import {
  BloodPressureIcon,
  BloodSugarIcon,
  HeartIcon,
  MedicationIcon,
  OxygenLevelIcon,
  QuestionnaireIcon,
  SleepIcon,
  StairsIcon,
  StepsIcon,
  StressLevelIcon,
  TemperatureIcon,
  WeightIcon,
} from 'components/AlertIcons';
import { FC } from 'react';
import { iconType } from 'types/Patients/constants';
import SVGIconProps from 'types/Shared/SVGIconProps';

export const rpmActionTypeToIcon: Record<iconType, FC<SVGIconProps> | null> = {
  oxygen_level: OxygenLevelIcon,
  bpm: HeartIcon,
  blood_pressure: BloodPressureIcon,
  weight: WeightIcon,
  temperature: TemperatureIcon,
  steps: StepsIcon,
  stairs: StairsIcon,
  sleep: SleepIcon,
  blood_sugar: BloodSugarIcon,
  stress_level: StressLevelIcon,
  check_up: null,
  questionnaire: QuestionnaireIcon,
  upload_file: null,
  select_from_patient_documents: null,
  medication: MedicationIcon,
};
