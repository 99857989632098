import React, { useCallback, useEffect } from 'react';
import { Tabs as BootstrapTabs, Tab as BootstrapTab } from 'react-bootstrap';
import TabEnum from '../../../../util/TabEnum';
import TabRouteMap from '../../../../util/TabRouteMap';
import TabActionComponentMap from './util/TabActionComponentMap';
import TabComponentMap from './util/TabComponentMap';
import TabNameMap from './util/TabNameMap';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import { templatesInitialBreadCrumbPathList } from 'screens/Templates/util/templatesUtils';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { setActions, setBreadcrumbPathList } from 'util/commonContext/NavigationContext';

interface ITemplateTabs {
  selectedTabKey: string;
}

const keyIsPartOfTabEnum = (key: string) => Object.values(TabEnum).includes(key);

const TemplateTabs = ({ selectedTabKey }: ITemplateTabs) => {
  const history = useHistory();

  const {
    dispatch,
    templateState: {
      templateNavigation: { breadcrumbPathList, actions },
    },
  } = useTemplateContext();

  // Functions and subroutines
  const updateBreadcrumbPathList = useCallback(() => {
    dispatch(
      setBreadcrumbPathList([
        ...templatesInitialBreadCrumbPathList,
        { title: TabNameMap[selectedTabKey], url: TabRouteMap[selectedTabKey] },
      ])
    );
  }, [dispatch, selectedTabKey]);

  const handleSelect = useCallback(
    (key) => {
      setActions(TabActionComponentMap[key]);
      history.push(TabRouteMap[key]);
    },
    [history]
  );

  // Effects
  useEffect(() => {
    const titleIndex = breadcrumbPathList.findIndex(
      ({ title }) => title === TabNameMap[selectedTabKey]
    );
    if (keyIsPartOfTabEnum(selectedTabKey) && titleIndex !== breadcrumbPathList.length - 1) {
      updateBreadcrumbPathList();
      dispatch(setActions(TabActionComponentMap[selectedTabKey]));
    }
  }, [selectedTabKey]);

  useEffect(() => {
    /**
     * This effect was made in order to
     * update the Breadcrumbs when the
     * user enters one of the tabs
     * with the url instead of the
     * interface.
     */
    if (
      keyIsPartOfTabEnum(selectedTabKey) &&
      breadcrumbPathList.length === templatesInitialBreadCrumbPathList.length
    ) {
      updateBreadcrumbPathList();
    }
  }, []);

  return (
    <BootstrapTabs
      id="patients-tab"
      activeKey={selectedTabKey}
      onSelect={handleSelect}
      className="tabs"
    >
      {Object.keys(TabComponentMap).map((TabKey) => {
        const Component = TabComponentMap[TabKey];

        return (
          <BootstrapTab
            key={TabKey}
            tabClassName={selectedTabKey === TabKey ? 'tab-selected' : 'tab'}
            eventKey={TabKey}
            title={TabNameMap[TabKey]}
          >
            <div className={styles.tabContainer}>
              <Component />
            </div>
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
};

export default TemplateTabs;
