import DashboardOutstandingChats from 'types/ApiModels/Dashboard/DashboardOutstandingChats';

export const mapOutstandingChatToList = (
  outstandingChat: DashboardOutstandingChats
): DashboardOutstandingChats => {
  return {
    id: outstandingChat.id,
    content: outstandingChat.content,
    message_type: outstandingChat.message_type,
    start_date: outstandingChat.start_date,
    encounter_id: outstandingChat.encounter_id,
    user_id: outstandingChat.user_id,
    first_name: outstandingChat.first_name,
    middle_name: outstandingChat.middle_name,
    last_name: outstandingChat.last_name,
    photo_thumbnail: outstandingChat.photo_thumbnail,
  };
};
