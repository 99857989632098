import Card from '../../../../../../../../../components/Card';
import GenericTable from '../../../../../../../../../components/GenericTable';
import PatientTableToolbar from '../../../../../../../../../components/PatientTableToolbar';
import style from '../../styles.module.css';
import { usePatientReportsContext } from '../../utils/Context/PatientReportsContext';
import { mapPopulationsDataToTable, populationsColumns } from '../../utils/patientReportUtils';

const PopulationsReport = () => {
  const { populations } = usePatientReportsContext();

  return (
    <>
      <Card
        headers={[
          <PatientTableToolbar
            title={`Chronic conditions history (${populations?.length || ''})`}
          />,
        ]}
        bodyClassName={style['patient-reports-table-container']}
        className="mh-100"
      >
        <GenericTable columns={populationsColumns} data={mapPopulationsDataToTable(populations)} />
      </Card>
    </>
  );
};
export default PopulationsReport;
