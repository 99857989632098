import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import PatientTimelineResponse from 'types/ApiModels/Patients/PatientTimeline';
import { PatientTimelineAction, PatientTimelineActionTypes } from './patientTimelineActionTypes';

export const setSelectedGoal = (goal: VytalType): PatientTimelineAction => ({
  type: PatientTimelineActionTypes.SET_SELECTED_GOAL,
  payload: {
    goal,
  },
});

export const setPatientActivities = (
  patientActivities: PatientTimelineResponse[]
): PatientTimelineAction => ({
  type: PatientTimelineActionTypes.SET_PATIENT_ACTIVITIES,
  payload: {
    patientActivities,
  },
});

export const setSelectedDate = (selectedDate: Date): PatientTimelineAction => ({
  type: PatientTimelineActionTypes.SET_TIMLEINE_SELECTED_DATE,
  payload: {
    selectedDate,
  },
});

export const setPeriodSelected = (periodSelected: TimePeriodOptions): PatientTimelineAction => ({
  type: PatientTimelineActionTypes.SET_TIMELINE_PERIOD_SELECTED,
  payload: {
    periodSelected,
  },
});
