import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { ITemplateCarePlan, ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';

export enum TemplateCarePlanActionTypes {
  RESET_STATE = 'RESET_STATE',
  SET_CAREPLAN_STATE = 'SET_CAREPLAN_STATE',
  SET_PREVIOUS_PATIENT_ACTIONS = 'SET_PREVIOUS_PATIENT_ACTIONS',
  SET_SELECTED_DATE = 'SET_SELECTED_DATE',
  SET_PATIENT_ACTIONS = 'SET_PATIENT_ACTIONS',
  SET_TIMELINE_ACTIONS = 'SET_TIMELINE_ACTIONS',
  SET_SELECTED_ACTION = 'SET_SELECTED_ACTION',
  SET_PERIOD_SELECTED = 'SET_PERIOD_SELECTED',
  SET_SHOW_CAREPLAN_MODAL = 'SET_SHOW_CAREPLAN_MODAL',
  SET_CAREPLAN_MODAL_EVENT_TYPE = 'SET_CAREPLAN_MODAL_EVENT_TYPE',
  SET_HAS_UNSAVED_CHANGES = 'SET_HAS_UNSAVED_CHANGES',
  SET_USER_IS_STAFF = 'SET_USER_IS_STAFF',
  SET_QUESTIONNAIRE_LIST = 'SET_QUESTIONNAIRE_LIST',
}

export type TemplateCarePlanAction =
  | {
      type: TemplateCarePlanActionTypes.RESET_STATE;
    }
  | {
      type: TemplateCarePlanActionTypes.SET_CAREPLAN_STATE;
      payload: {
        carePlanState: ITemplateCarePlan;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_PREVIOUS_PATIENT_ACTIONS;
    }
  | {
      type: TemplateCarePlanActionTypes.SET_SELECTED_DATE;
      payload: {
        selectedDate: Date;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_PATIENT_ACTIONS;
      payload: {
        patientActions: IPatientCarePlanAction[];
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_TIMELINE_ACTIONS;
      payload: {
        timelineActions: ITemplateCarePlanAction[];
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_SELECTED_ACTION;
      payload: {
        selectedAction: ITemplateCarePlanAction;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_PERIOD_SELECTED;
      payload: {
        timePeriod: TimePeriodOptions;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_SHOW_CAREPLAN_MODAL;
      payload: {
        showCarePlanModal: boolean;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_CAREPLAN_MODAL_EVENT_TYPE;
      payload: {
        carePlanModalEventType: CarePlanActionEventType;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_HAS_UNSAVED_CHANGES;
      payload: {
        hasUnsavedChanges: boolean;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_USER_IS_STAFF;
      payload: {
        userIsStaff: boolean;
      };
    }
  | {
      type: TemplateCarePlanActionTypes.SET_QUESTIONNAIRE_LIST;
      payload: {
        questionnaireList: IQuestionnaire[];
      };
    };
