import AdminTabEnum from './AdminTabEnum';

const dashboardRoute = '/administration';

const TabRouteMap = {
  [AdminTabEnum.DASHBOARD]: dashboardRoute,
  [AdminTabEnum.PROVIDERS]: `${dashboardRoute}/providers/all`,
  [AdminTabEnum.ROLES]: `${dashboardRoute}/roles`,
  [AdminTabEnum.PATIENTS]: `${dashboardRoute}/patients`,
  [AdminTabEnum.PATIENT_SETTINGS]: `${dashboardRoute}/patient-settings`,
  [AdminTabEnum.NOTIFICATIONS]: `${dashboardRoute}/notifications`,
  [AdminTabEnum.FORMS]: `${dashboardRoute}/forms`,
  [AdminTabEnum.EMAIL_SETTINGS]: `${dashboardRoute}/email-settings`,
  [AdminTabEnum.ACCOUNT_SETTINGS]: `${dashboardRoute}/account-settings`,
  [AdminTabEnum.REPORTS]: `${dashboardRoute}/reports`,
  [AdminTabEnum.RULES_ENGINE]: `${dashboardRoute}/rules-engine`,
  [AdminTabEnum.AUDIT_TRACKING]: `${dashboardRoute}/audit-tracking`,
  [AdminTabEnum.HEALTH_SCORING]: `${dashboardRoute}/health-scoring`,
  [AdminTabEnum.ACTIVITY_POINTS]: `${dashboardRoute}/activity-points`,
};

export default TabRouteMap;
