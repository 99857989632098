import { PatientQuickFilter } from 'screens/Patients/Content/components/PatientListWithFilters/QuickFilters';
import PatientGet from 'types/ApiModels/Patients/PatientGet';

export enum PatientListActionTypes {
  SET_COMPLETE = 'SET_COMPLETE',
  SET_FILTER = 'SET_FILTER',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  SET_PAGE = 'SET_PAGE',
}

export type PatientListAction =
  | {
      type: PatientListActionTypes.SET_COMPLETE;
      payload: {
        completeList: PatientGet[];
      };
    }
  | {
      type: PatientListActionTypes.SET_FILTER;
      payload: {
        filter: PatientQuickFilter;
      };
    }
  | {
      type: PatientListActionTypes.SET_SEARCH_VALUE;
      payload: {
        searchValue: string;
      };
    }
  | {
      type: PatientListActionTypes.SET_PAGE;
      payload: {
        pageList: PatientGet[][];
      };
    };
