export const SWITCH_CONTROL = 'SWITCH_CONTROL';
export const SET_ALERTS = 'SET_ALERTS';
export const SET_POPULATIONS = 'SET_POPULATIONS';

const patientReportsReducer = (state, action) => {
  switch (action.type) {
    case SET_ALERTS: {
      return {...state, alerts: action.payload};
    }
    case SET_POPULATIONS: {
      return {...state, populations: action.payload};
    }
    case SWITCH_CONTROL: {
      return {...state, currentControl: action.payload};
    }
    default:
      return state;
  }
};

export default patientReportsReducer;
