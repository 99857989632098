import Card from 'components/Card';
import EmptyList from 'components/EmptyList';
import { FC } from 'react';
import { ReactComponent as EmptyNotesIcon } from 'assets/icons/empty_notes.svg'

import styles from './styles.module.css'
interface StickyNotesProps {
  notes?: string[];
}

const StickyNotes: FC<StickyNotesProps> = ({ notes }: StickyNotesProps) => {
  return (
    <Card className="bg-white w-100" headers={['Sticky notes']}>
      {notes && (
        <div>
          {notes.length > 0 ? notes.map(file => (<div key={file}>{file}</div>)) :
            <>
              <EmptyList className={styles['empty-notes']} Icon={EmptyNotesIcon} title="No notes found" />
              <div className={styles['add-note']}>Add note</div>
            </>
          }
        </div>
      )}
    </Card>
  );
};
export default StickyNotes;
