import Button from 'components/Button';
import PropTypes from 'prop-types';
import { useState } from 'react';
import colors from 'styles/colors';
import styles from './styles.module.css';

const GenericQuickFilters = ({ filters, onFilterClick }) => {
  const [currentFilters, setCurrentFilters] = useState([]);

  const handleFilterClick = (filter) => {
    setCurrentFilters((fs) => {
      const keyIndex = fs.indexOf(filter.key);
      if (keyIndex === -1) return [...fs, filter.key];
      const fsCopy = [...fs];
      fsCopy.splice(keyIndex, 1);
      return fsCopy;
    });
    onFilterClick?.(filter);
  };

  return (
    <div className="d-flex align-items-center">
      <span className="mr-3">Quick filters</span>
      {filters.map((f) => (
        <Button
          key={`${f.key}`}
          className={styles.button}
          color={colors.lowRiskAlerts}
          label={f.label}
          onClick={() => handleFilterClick(f)}
          selected={currentFilters.includes(f.key)}
        />
      ))}
    </div>
  );
};

GenericQuickFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  /**Will receive the filter object corresponding to the filter that was clicked */
  onFiltersClick: PropTypes.func,
};

export default GenericQuickFilters;
