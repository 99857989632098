import { iconType } from 'types/Patients/constants';

export const rpmActionTypeToString: Record<iconType, string> = {
  oxygen_level: 'Oxygen Level',
  bpm: 'BPM',
  blood_pressure: 'Blood Pressure',
  weight: 'Weight',
  temperature: 'Temperature',
  steps: 'Steps',
  stairs: 'Stairs',
  sleep: 'Sleep',
  blood_sugar: 'Blood Sugar',
  stress_level: 'Stress Level',
  check_up: 'Check Up',
  questionnaire: 'Questionnaire',
  upload_file: 'Upload File',
  select_from_patient_documents: 'Select From Patient Documents',
  medication: 'Medication',
};
