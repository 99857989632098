import tabEnum from './tabEnum';

const filterFunctionMap = {
  [tabEnum.ALL_CODES]: (originalCodeList = []) => {
    return originalCodeList;
  },
  [tabEnum.READY_TO_SUBMIT]: (originalCodeList = []) => {
    return originalCodeList.filter((code) => code.status === 'Eligible');
  },
  [tabEnum.SUBMITTED]: (originalCodeList = []) => {
    return originalCodeList.filter((code) => code.status === 'submitted');
  },
  [tabEnum.PAID]: (originalCodeList = []) => {
    return originalCodeList.filter((code) => code.status === 'paid');
  },
};

export default filterFunctionMap;
