import CollapsibleCard from 'components/CollapsibleCard';
import { useEffect, useMemo, useRef } from 'react';
import useWebSocketAdherenceAlerts from 'websockets/hooks/useWebSocketAdherenceAlerts';
import { useWebSocketContext } from 'websockets/WebSocketContext';
import { useDashboardContext } from '../store/DashboardContext';
import useDashboardAdherence from '../store/hooks/useDashboardAdherence';
import AdherenceItem from './AdherenceItem';
import EmptyAdherence from './EmptyAdherence';
import Header from './Header';
import styles from './styles.module.css';

interface AdherenceProps {
  collapsed: boolean;
}
const Adherence = ({ collapsed }: AdherenceProps) => {
  const {
    state: { totalPatients },
  } = useDashboardContext();
  const [{ book: adherence, currentPage, refetch }] = useDashboardAdherence();

  const { dispatch } = useWebSocketContext();
  const { received } = useWebSocketAdherenceAlerts();
  const lastReceived = useRef(null);
  useEffect(() => {
    if (received && received !== lastReceived.current) {
      lastReceived.current = received;
      refetch();
    }
  }, [received, dispatch, refetch]);

  const adherenceCurrentPage = useMemo(() => {
    return adherence?.[currentPage];
  }, [currentPage, adherence]);

  const adherenceMappedToList = useMemo(
    () => adherenceCurrentPage?.results ?? [],
    [adherenceCurrentPage]
  );
  return (
    <CollapsibleCard
      outerCollapse={collapsed}
      className="bg-white d-flex flex-column"
      headerClassName="p-0"
      headers={[
        <Header
          key={0}
          patientAdherence={adherenceMappedToList.length}
          totalPatients={totalPatients}
        />,
      ]}
    >
      <div className={styles.body} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          {adherenceMappedToList && adherenceMappedToList.length > 0 ? (
            <>
              {adherenceMappedToList?.map((item) => (
                <AdherenceItem adherenceItem={item} key={item.id} />
              ))}
            </>
          ) : (
            <EmptyAdherence />
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};

export default Adherence;
