import Breadcrumbs from 'components/Breadcrumbs';
import VyTracLayout from 'components/VyTracLayout';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { patientForPatientCase } from 'screens/Common/mocks';
import PatientCase from 'types/PatientCases';
import { setBreadcrumTitle } from 'util/themeUtils';
import CaseCard from './CaseCard';
import CasesContainer from './CasesContainer';
import styles from './styles.module.css';

const PatientCases = () => {
  const [daysAgo, setDaysAgo] = useState(new Date());
  const [openCases, setOpenCases] = useState<PatientCase[]>([]);
  const [completed, setCompleted] = useState<PatientCase[]>([]);
  useEffect(() => {
    const d = new Date();
    d.setDate(d.getDate() - 5);
    setDaysAgo(d);
  }, []);
  const [requireFollowUp, setRequireFollowUp] = useState<PatientCase[]>([
    {
      patient: patientForPatientCase,
      alert: {
        name: 'COPD Questionnaire, Questionaire (not answered)',
        event_type: 'questionnaire',
        icon_type: 'oxygen_level',
        created_at: daysAgo.toISOString(),
        originalStatus: 'Questionnaire (10 answers of 24 critical)',
        originalAlertProvider: 'K S',
        type: 'critical',
      },
      messages: [
        {
          messages: [
            {
              from: 'Thomas Wayne',
              created_at: new Date().toISOString(),
              text: 'Hello, I am a doctor. I am here to help you.',
            },
          ],
        },
      ],
      careTeam: ['Thomas Wayne', 'K N', 'Amanda Spence', 'L T'],
    },
  ]);

  //TODO delete this on integration
  useEffect(() => {
    const mockData: PatientCase[] = [
      {
        patient: patientForPatientCase,
        alert: {
          name: 'COPD Questionnaire, Questionaire (not answered)',
          event_type: 'questionnaire',
          icon_type: 'oxygen_level',
          created_at: daysAgo.toISOString(),
          originalStatus: 'Questionnaire (10 answers of 24 critical)',
          originalAlertProvider: 'K S',
          type: 'critical',
        },
        messages: [
          {
            messages: [
              {
                from: 'Thomas Wayne',
                created_at: new Date().toISOString(),
                text: 'Assign this to me.',
                subThread: {
                  messages: [
                    {
                      from: 'K S',
                      created_at: new Date().toISOString(),
                      text: "I'll assign this to you.",
                    },
                  ],
                },
              },
            ],
          },
          {
            messages: [
              {
                from: 'Thomas Wayne',
                created_at: daysAgo.toISOString(),
                text: 'COPD Questionnaire, Questionaire (not answered)',
              },
            ],
          },
        ],
        careTeam: ['Thomas Wayne', 'K N', 'Amanda Spence', 'L T'],
      },
    ];
    setRequireFollowUp(
      mockData.flatMap((patientCase) => {
        return [patientCase, patientCase];
      })
    );
    const d1 = new Date();
    d1.setDate(d1.getDate() - 2);
    setOpenCases(
      mockData.flatMap((patientCase) => {
        return [
          {
            ...patientCase,
            completed_date: d1.toISOString(),
            alert: { ...patientCase.alert, originalStatus: '' },
          },
        ];
      })
    );
    setCompleted(
      mockData.flatMap((patientCase) => {
        return [
          {
            ...patientCase,
            completed_date: d1.toISOString(),
            alert: { ...patientCase.alert, originalStatus: '' },
          },
          {
            ...patientCase,
            completed_date: d1.toISOString(),
            alert: { ...patientCase.alert, originalStatus: '' },
          },
          {
            ...patientCase,
            completed_date: d1.toISOString(),
            alert: { ...patientCase.alert, originalStatus: '' },
          },
        ];
      })
    );
  }, [daysAgo]);

  return (
    <VyTracLayout selectedRoute="patient cases">
      <Breadcrumbs
        items={[
          { title: `${setBreadcrumTitle()} Dashboard`, url: '/' },
          { title: 'Patient cases', url: '' },
        ]}
      />
      <div className={styles.container}>
        <Row className="py-4 px-2 h-100">
          <Col className="h-100">
            <CasesContainer
              title={`Require Follow up (${requireFollowUp.length})`}
              className={styles.caseContainer}
            >
              {requireFollowUp.map((caseItem, index) => (
                <CaseCard key={index} patientCase={caseItem} />
              ))}
            </CasesContainer>
          </Col>
          <Col className="h-100">
            <CasesContainer
              title={`Open cases (${openCases.length})`}
              className={styles.caseContainer}
            >
              {openCases.map((caseItem, index) => (
                <CaseCard key={index} patientCase={caseItem} showDateBelow />
              ))}
            </CasesContainer>
          </Col>
          <Col className="h-100">
            <CasesContainer
              title={`Completed (${completed.length})`}
              className={styles.caseContainer}
            >
              {completed.map((caseItem, index) => (
                <CaseCard key={index} patientCase={caseItem} showDateBelow />
              ))}
            </CasesContainer>
          </Col>
        </Row>
      </div>
    </VyTracLayout>
  );
};

export default PatientCases;
