import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';
type CircleAddProps = SVGIconProps & { secondaryColor: string };

const CircledAdd: FC<CircleAddProps> = ({ color, height, width, secondaryColor }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={color} />
      <path
        d="M8.498 7.502l.004-3.035a.502.502 0 00-1.004 0l.004 3.035-3.034-.004a.502.502 0 100 1.003l3.034-.003-.003 3.034a.5.5 0 00.694.464.5.5 0 00.309-.464l-.004-3.034 3.035.004a.5.5 0 00.463-.694.5.5 0 00-.463-.31l-3.035.004z"
        fill={secondaryColor}
      />
    </svg>
  );
};
export default CircledAdd;
