import moment from 'moment';
import React from 'react';
import {FormCheck} from 'react-bootstrap';
import styles from './styles.module.css';

const DateLabel = ({date, isToday, toggle, onToggle}) => {
  return (
    <div className={styles.dateLabel}>
      <div style={isToday ? {fontWeight: '700', color: '#393E48'} : {}}>
        {moment(date).format('ddd')}
      </div>
      <div onClick={onToggle}>
        <FormCheck type="switch" checked={toggle} />
      </div>
    </div>
  );
};

export default DateLabel;
