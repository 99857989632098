import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down.svg';
import styles from './styles.module.css';
import { useDebouncedSearch } from 'hooks';
import { PaginatedResponse } from 'types/ApiModels/General';

interface resultsOption {
  id: number;
  name: string;
}

interface IUserSelect {
  value?: {
    id?: number;
    profileImg: ReactNode;
    name: string;
  };
  onChange: (id: number) => void;
  onSearch: (searchValue: string) => Promise<PaginatedResponse<unknown>>;
  searchResultsMapper: (source: unknown) => resultsOption;
  valuePlaceholder?: string;
  inputPlaceholder?: string;
  containerClassName?: string;
  labelClassName?: string;
}
const UserSelect = ({
  value,
  onChange,
  onSearch,
  searchResultsMapper,
  valuePlaceholder,
  inputPlaceholder,
  containerClassName,
  labelClassName,
}: IUserSelect) => {
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);

  const { search, handleSearch, mappedSearchResult } = useDebouncedSearch(
    onSearch,
    searchResultsMapper
  );

  const optionsRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setDisplayOptions(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  const onOptionClick = (id: number) => {
    onChange(id);
    setDisplayOptions(false);
  };

  return (
    <div className="position-relative" style={{ userSelect: 'none' }}>
      <div
        className={`d-flex align-items-center justify-content-between ${containerClassName}`}
        onClick={() => setDisplayOptions(true)}
      >
        <div className="d-flex align-items-center">
          {value ? (
            <>
              {value?.profileImg}
              <span className={labelClassName}>{value?.name}</span>
            </>
          ) : (
            valuePlaceholder
          )}
        </div>
        <ArrowDownIcon fill="#CCE0F9" />
      </div>
      <div
        className={styles.optionsContainer}
        ref={optionsRef}
        style={{ display: displayOptions ? 'block' : 'none' }}
      >
        <input
          className={styles.optionsInput}
          value={search}
          placeholder={inputPlaceholder}
          onChange={handleSearch}
        />
        <div className={styles.optionList}>
          {mappedSearchResult.map((option: resultsOption) => (
            <div
              key={option.id}
              className={styles.optionRow}
              onClick={() => onOptionClick(option.id)}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default UserSelect;
