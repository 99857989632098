import BreadCrumbPath from '../../../types/Shared/BreadCrumbPath';
import { NavigationAction, NavigationActionTypes } from './NavigationActionType';

export interface INavigationState {
  breadcrumbPathList: BreadCrumbPath[];
  actions: () => JSX.Element | null;
}

const navigationReducer = (state: INavigationState, action: NavigationAction) => {
  switch (action.type) {
    case NavigationActionTypes.SET_BREADCRUMB_PATH_LIST: {
      const stateCopy = { ...state };
      stateCopy.breadcrumbPathList = action.payload.breadcrumbPathList;
      return stateCopy;
    }
    case NavigationActionTypes.SET_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.actions = action.payload.actions;
      return stateCopy;
    }
    default:
      return state;
  }
};
export default navigationReducer;
