import React, { CSSProperties, Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { Handle, Position, useStoreState } from 'react-flow-renderer';
import { ReactComponent as BurgerMenu } from '../../assets/icons/burger_graph_menu.svg';
import styles from './styles.module.css';
import { CSSTransition } from 'react-transition-group';
import './overrideStyles.css';

const setInputRestrictions = (e: React.KeyboardEvent<HTMLSpanElement>) => {
  if (e.key === 'Enter') e.preventDefault();
  if ((e.target as HTMLElement).innerText.length >= 254 && e.key.length === 1) e.preventDefault();
};

interface IVytracBaseNode {
  className?: string;
  data: any;
  showMenu: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  type: 'question' | 'answer';
  style?: CSSProperties;
  menuStyle?: CSSProperties;
}
const VytracBaseNode: FC<IVytracBaseNode> = ({
  className = '',
  data,
  showMenu,
  setShowMenu,
  type,
  style,
  menuStyle,
  children,
}) => {
  const menu = useRef(null);
  const defaultValue = useRef(data[type].value);
  const spanRef = useRef<HTMLSpanElement>();
  const edges = useStoreState((state) => state.edges);
  const isValidConnection = (connection) => {
    if (type === 'answer')
      return (
        connection.target !== data.questionId &&
        !edges.find((edge) => edge.source === connection.source)
      );
    else {
      return !edges.find((edge) => edge.source === connection.source);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menu.current && !menu.current.contains(event.target)) {
        event.stopPropagation();
        setShowMenu(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.addEventListener('click', handleClickOutside);
    };
  }, [menu]);

  return (
    <div className="d-flex">
      <Handle
        isValidConnection={isValidConnection}
        type={type === 'question' ? 'target' : 'source'}
        position={type === 'question' ? Position.Left : Position.Right}
        style={type === 'question' ? { left: -6 } : { right: -6 }}
      />
      <div className={styles.externalBox}>
        <div
          className={className}
          style={{ ...style, minHeight: 26, height: spanRef?.current?.offsetHeight }}
        >
          {/* <span
            onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
            contentEditable={data.editable}
            suppressContentEditableWarning={true}
            onInput={(e) => {
              console.log(e.target.innerText.length);
              console.log(e.target.innerText.length < 20);
              if (e.target.innerText.length < 20) {
                data.onChange(e.target.innerText);
              }
            }}
          >
            {defaultValue.current}
          </span> */}
          {/* <TextareaAutosize onChange={(e) => data.onChange(e.target.value)}>
            {defaultValue.current}
          </TextareaAutosize> */}
          <span
            ref={spanRef}
            contentEditable
            suppressContentEditableWarning
            style={{ maxWidth: '300px' }}
            onKeyDown={setInputRestrictions}
            onInput={(e) => data.onChange((e.target as HTMLElement).innerText)}
          >
            {defaultValue.current}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <BurgerMenu
            className={showMenu ? styles.burgerMenuActive : styles.burgerMenu}
            onClick={
              data.editable
                ? (e) => {
                    e.stopPropagation();
                    setShowMenu(!showMenu);
                  }
                : () => null
            }
          />
        </div>
      </div>
      <CSSTransition in={showMenu} timeout={300} classNames="transition" unmountOnExit>
        <div className={styles.menu} ref={menu} style={menuStyle}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

export default VytracBaseNode;
