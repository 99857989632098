import Card from 'components/Card';
import { Collapse } from 'react-bootstrap';

interface CollapsibleCardProps {
  children: React.ReactElement;
  headers?: string[] | JSX.Element[];
  className?: string;
  headerClassName?: string;
  outerCollapse: boolean;
}

const CollapsibleCard = ({
  children,
  headers,
  className,
  headerClassName,
  outerCollapse,
}: CollapsibleCardProps) => {
  return (
    <Card
      bodyClassName={'p-0'}
      headerClassName={headerClassName ?? ''}
      className={className ?? ''}
      headers={headers ?? []}
    >
      <Collapse in={outerCollapse}>{children}</Collapse>
    </Card>
  );
};
export default CollapsibleCard;
