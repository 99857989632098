import GridTableWithDropdown from 'components/GridTableWIthDropdown';
import { Tagitem } from 'components/Tagitem';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trashcan.svg';
import { ReactComponent as PrinterIcon } from 'assets/icons/print.svg';
import GenericColumn from 'types/Shared/GenericColumn';
import styles from './styles.module.css';
import { FormCheck } from 'react-bootstrap';
import FormDetail from './FormDetail';
import { useCallback, useEffect, useState } from 'react';
import {
  setCurrentForm,
  setOpenAddFormModal,
  setFormDirty,
} from 'screens/Administration/store/Forms';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { AdminForm } from 'types/Administration/AdminForms/AdminForm';
import { formTypeToString } from './utils';
import { setOpenDeleteFormModal } from 'screens/Administration/store/Forms/formActionCreators';
const FormsTable = () => {
  const [{ list: adminForms }, dispatch] = useAdminForms();
  const [checks, setChecks] = useState<{ [key: number]: boolean }>({});
  useEffect(() => {
    if (adminForms) {
      const newChecks = {};
      adminForms.forEach((form) => {
        newChecks[form.id] = form.status;
      });
      setChecks(newChecks);
    }
  }, [adminForms]);
  const handleDelete = useCallback(
    (form: AdminForm) => {
      dispatch(setCurrentForm(form));
      dispatch(setOpenDeleteFormModal(true));
    },
    [dispatch]
  );

  const handleStatusCheck = useCallback(
    (form: AdminForm, status: boolean) => {
      setChecks((prevChecks) => ({ ...prevChecks, [form.id]: status }));
      dispatch(setFormDirty({ ...form, status: status }));
    },
    [dispatch]
  );

  const Actions = ({ form }: { form: AdminForm }) => (
    <div className="d-flex gap" onClick={(e) => e.stopPropagation()}>
      <ShareIcon stroke="#8FAAC3" fill="#8FAAC3" className="cursor-pointer" />
      <EditIcon
        stroke="#8FAAC3"
        className="cursor-pointer"
        onClick={() => {
          dispatch(setCurrentForm(form));
          dispatch(setOpenAddFormModal(true));
        }}
      />
      <DeleteIcon stroke="#8FAAC3" className="cursor-pointer" onClick={() => handleDelete(form)} />
      <PrinterIcon stroke="#8FAAC3" fill="#8FAAC3" className="cursor-pointer" />
    </div>
  );

  const handleOpen = useCallback((rowData) => dispatch(setCurrentForm(rowData)), [dispatch]);
  const handleClose = useCallback(() => dispatch(setCurrentForm(null)), [dispatch]);

  const columns: GenericColumn[] = [
    {
      title: 'ID',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Used for',
      key: 'population',
      render: ({ population }) => (
        <div className="d-flex flex-wrap gap">
          {population.map((tag) => (
            <Tagitem key={tag.name} text={tag.name} />
          ))}
        </div>
      ),
      width: '10%',
    },
    {
      title: 'Type',
      key: 'type',
      width: '10%',
      render: ({ type }) => formTypeToString[type] || 'Unknown',
    },
    {
      title: 'Format',
      key: 'format',
      width: '9%',
      render: ({ url }) => (
        <>
          {url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              External
            </a>
          ) : (
            <span>Internal</span>
          )}
        </>
      ),
    },
    {
      title: 'Patients',
      key: 'patients',
      width: '8%',
      render: ({ population }: AdminForm) =>
        `${population.reduce((acc, curr) => acc + curr.patients_count, 0)}`,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (rowData) => <Actions form={rowData} />,
    },
    {
      title: 'status',
      key: 'status',
      render: (rowData) => (
        <div onClick={(e) => e.stopPropagation()}>
          <FormCheck
            type="switch"
            checked={checks[rowData.id] ?? false}
            id={rowData.id}
            onChange={(e) => handleStatusCheck(rowData, e.target.checked)}
          />
        </div>
      ),
    },
  ];

  return (
    <GridTableWithDropdown
      data={adminForms ?? []}
      columns={columns}
      dropdownChildren={(rowData) => <FormDetail form={rowData} />}
      dropdownContainerClassName={styles['dropdown-container']}
      openOnRowClick
      onOpen={handleOpen}
      onClose={handleClose}
      showDropDown={(rowdata) => !rowdata.url}
    />
  );
};
export default FormsTable;
