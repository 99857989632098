import { useAuthContext } from 'auth';
import VytracSpinner from 'components/vytrac-spinner';
import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../../screens/Common/Navbar';
import SideNavigationMenu from '../../screens/Common/SideNavigationMenu';
import { DialogsContextProvider } from './store';
import styles from './styles.module.css';
interface IVytracLayout {
  selectedRoute: string;
}
const VyTracLayout: FC<IVytracLayout> = ({ selectedRoute, children }) => {
  const { currentUser } = useAuthContext();
  if (!currentUser) return <></>;
  return (
    <Container className="px-0">
      <DialogsContextProvider>
        <div className="d-flex">
          <SideNavigationMenu selectedRoute={selectedRoute} />
          <div className={styles.content}>
            <Navbar showPointsIcon={selectedRoute === 'dashboard'} />
            <div className={styles['children-container']}>{children}</div>
          </div>
        </div>
      </DialogsContextProvider>
    </Container>
  );
};

export default VyTracLayout;
