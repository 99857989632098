import React from 'react';
import NoteCheckbox from '../NoteCheckbox';
import TextArea from '../TextArea';

interface INormalNote {
  onNoteChange: (note: string) => void;
  label: string;
  onChangeCheckbox: () => void;
  checked: boolean;
  boxShadow?: string;
  currentValue: string;
  textAreaClassName?: string;
}

const NormalNote = ({
  onNoteChange,
  label,
  onChangeCheckbox,
  checked,
  boxShadow,
  currentValue,
  textAreaClassName = '',
}: INormalNote) => {
  return (
    <NoteCheckbox
      label={label}
      onChangeCheckbox={onChangeCheckbox}
      checked={checked}
      boxShadow={boxShadow}
    >
      <div>
        <TextArea
          value={currentValue}
          className={textAreaClassName}
          onTextAreaChange={(e) => onNoteChange(e.target.value)}
          placeholder="Add a note..."
        />
      </div>
    </NoteCheckbox>
  );
};
export default NormalNote;
