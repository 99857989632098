import React, { ChangeEvent } from 'react';
import styles from './styles.module.css';

interface ITextArea {
  onTextAreaChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  value: string;
  className?: string;
}
const TextArea = ({ onTextAreaChange, placeholder, value, className = '' }: ITextArea) => {
  //States
  const [rows, setRows] = React.useState(5);

  //Functions and subroutines
  const autoAdjustRows = (event) => {
    const textareaLineHeight = 13;
    const { minRows, maxRows } = { minRows: 5, maxRows: 10 };

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  return (
    <div className={styles.rowContainer}>
      <textarea
        className={className}
        value={value}
        placeholder={placeholder}
        rows={rows}
        onChange={(e) => {
          autoAdjustRows(e);
          onTextAreaChange(e);
        }}
      />
    </div>
  );
};
export default TextArea;
