import { useEffect, useReducer } from 'react';
import Card from 'components/Card';
import {
  schedulesInitialState,
  schedulesReducer,
  setSchedules,
  toggleScheduleCompleted,
} from '../store/schedules';
import SchedulesContext from '../store/schedules/schedules-context';
import Schedule from './schedule';
import ScheduleCardHeader from './schedule-card-header';
import styles from './styles.module.css';

const TeamNotesSchedule = ({ schedules: schedulesProp }) => {
  const [schedulesState, dispatch] = useReducer(schedulesReducer, schedulesInitialState);
  const toggleCompleted = (id) => {
    dispatch(toggleScheduleCompleted(id));
  };

  useEffect(() => {
    dispatch(setSchedules(schedulesProp));
  }, [schedulesProp]);

  return (
    <SchedulesContext.Provider
      value={{
        ...schedulesState,
        dispatch,
      }}
    >
      <Card
        className={'w-100 card-bg-border d-flex flex-column flex-grow-1 '}
        headers={[<ScheduleCardHeader />]}
        bodyClassName={`d-flex flex-column overflow-hidden ${styles['schedules-container']}`}
      >
        <div className={`d-flex flex-column gap-sm overflow-auto `}>
          {schedulesState.filteredSchedules?.map((s) => (
            <Schedule
              key={s.id}
              completed={s.completed}
              message={s.message}
              handleCompletedChange={() => toggleCompleted(s.id)}
              scheduleDate={new Date(s.date)}
              title={s.title}
            />
          ))}
        </div>
      </Card>
    </SchedulesContext.Provider>
  );
};
export default TeamNotesSchedule;
