import moment, { Moment } from 'moment';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';

export const getRecurrenceEndDate = (
  action: IPatientCarePlanAction,
  currentCalendarDate: Date,
  blockStartDate: Date
): Moment => {
  const blockStartMoment = moment(blockStartDate);
  const nextBlockStart = blockStartMoment.clone().add(30, 'days');
  switch (action.recurrence.end_date_type) {
    case 'fixed_date': {
      return moment(action.recurrence.end_date);
    }
    case 'duration': {
      if (action.recurrence.repeats_type === 'start_of_adherence_block') {
        return nextBlockStart.add(action.recurrence.end_date_duration_days, 'days');
      } else if (action.recurrence.repeats_type === 'before_start_of_adherence_block') {
        nextBlockStart.add(-action.recurrence.repeats_param, 'days');
        return nextBlockStart.add(action.recurrence.end_date_duration_days, 'days');
      } else {
        return moment(action.start_date).add(action.recurrence.end_date_duration_days, 'days');
      }
    }
    case 'end_of_adherence_block': {
      return nextBlockStart;
    }
    case 'none': {
      const dateValue = currentCalendarDate;
      return moment(dateValue).add(40, 'days'); // just in case
    }
  }
};
