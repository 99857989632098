import { FC } from 'react';
import { FormCheck } from 'react-bootstrap';
import styles from './styles.module.css';

interface SwitchInputProps {
  label: string;
  onChange: (value) => void;
  value: boolean;
}

const SwitchInput: FC<SwitchInputProps> = ({ label, onChange, value }: SwitchInputProps) => {
  return (
    <div className={`d-flex justify-content-between p-2 ${styles['switch-input-container']}`}>
      <div>{label}</div>
      <div className="cursor-pointer" onClick={onChange}>
        <FormCheck type="switch" checked={value} />
      </div>
    </div>
  );
};
export default SwitchInput;
