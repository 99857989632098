import UserBubbleGroup, { UserBubbleGroupUser } from 'components/UserBubbleGroup';
import React from 'react';
import attatchment from '../../assets/icons/attatchment.png';
import MessageInput from './components/MessageInput/message-input';
import styles from './styles.module.css';

interface ISendMessageBox {
  noPadding?: boolean;
  handleSendMessage: (message: string) => void;
  participants?: UserBubbleGroupUser[];
}

const MAX_BUBBLES = 2;
const SendMessageBox = ({
  noPadding = false,
  handleSendMessage,
  participants,
}: ISendMessageBox) => {
  return (
    <div
      className={styles.main}
      style={noPadding ? { padding: '0px' } : {}}
      onClick={(e) => e.stopPropagation()}
    >
      <button className={styles.attatchment}>
        <img src={attatchment} alt="" />
      </button>
      {participants?.length ? (
        <div className={styles.messageBubbles}>
          <UserBubbleGroup users={participants} maxCount={MAX_BUBBLES} />
        </div>
      ) : null}
      <div className={styles.messageInput}>
        <MessageInput handleSendMessage={handleSendMessage} />
      </div>
    </div>
  );
};

export default SendMessageBox;
