import GenericColumn from 'types/Shared/GenericColumn';
import CarePlanEditElement from '../CarePlanEditElement';
import PopulationActionElement from '../PopulationActionElement';
import PopulationNameElement from '../PopulationNameElement';
import PopulationStatusElement from '../PopulationStatusElement';

export const populationColumns = (
  styles,
  handleDeletePopulation,
  handleUpdateIsActivePopulation
): GenericColumn[] => [
  {
    title: 'id',
    key: 'id',
    hidden: true,
  },
  {
    title: 'name',
    key: 'name',
    render: PopulationNameElement,
    headerClasses: 'flex-grow-1',
    tdClassName: 'flex-grow-1',
  },
  {
    title: 'patients',
    key: 'patientsCount',
    headerClasses: styles['flex-basis-10'],
    tdClassName: styles['flex-basis-10'],
  },
  {
    title: 'care plan',
    key: 'carePlan',
    render: CarePlanEditElement,
    headerClasses: styles['flex-basis-10'],
    tdClassName: styles['flex-basis-10'],
  },
  {
    title: 'action',
    key: 'name',
    render: ({ id }) =>
      PopulationActionElement({ id, handleDeletePopulation: handleDeletePopulation }),
    headerClasses: styles['flex-basis-5'],
    tdClassName: styles['flex-basis-5'],
  },
  {
    title: 'status',
    key: 'status',
    render: ({ id, status }) =>
      PopulationStatusElement({ id, handleUpdateIsActivePopulation, status }),
    headerClasses: styles['flex-basis-5'],
    tdClassName: styles['flex-basis-5'],
  },
];
