import { BloodPressureIcon, OxygenLevelIcon } from 'components/AlertIcons';
import colors from 'styles/colors';

//TODO: might want to unify these with patientTimelineUtils
export const colorBySeverity = {
  critical: colors.highRiskAlerts,
  medium: colors.mediumRiskAlerts,
  low: colors.lowRiskAlerts,
};
export const IconComponentByType = {
  respiratory: OxygenLevelIcon,
  cardiac: BloodPressureIcon,
};
