import React, { useState } from 'react';
import colors from 'styles/colors';
import styles from './styles.module.css';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  variant?: 'cancel' | 'confirm' | 'delete' | 'dull' | '';
  containerClassName?: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: (e: any) => void;
}
export default function Button({
  label,
  variant = '',
  onClick = null,
  color = null,
  style = null,
  className = '',
  containerClassName = '',
  disabled = false,
  selected = false,
  ...props
}: IButton) {
  const [hover, setHover] = useState(false);

  let buttonColor = color;
  if (!color) {
    if (variant === 'cancel') {
      buttonColor = '#8FAAC3';
    } else if (variant === 'confirm') {
      buttonColor = '#86B463';
    } else if (variant === 'delete') {
      buttonColor = '#F5485C';
    } else if (variant === 'dull') {
      buttonColor = colors.lowRiskAlerts;
    }
  }

  return (
    <div className="d-flex">
      <div className={`${containerClassName || ''}`}>
        <button
          onClick={onClick}
          onPointerEnter={() => (disabled ? null : setHover(true))}
          onPointerLeave={() => setHover(false)}
          className={`${className || ''} ${styles.actionBtn} `}
          style={{
            ...style,
            border: `1px solid ${buttonColor}`,
            color: hover || selected ? 'white' : buttonColor,
            backgroundColor: hover || selected ? buttonColor : 'transparent',
            opacity: disabled ? 0.3 : 1,
            cursor: disabled ? 'default' : 'pointer',
          }}
          disabled={disabled}
          {...props}
        >
          {label}
        </button>
      </div>
    </div>
  );
}
