export const SET_ACTIVE_UNTIL = 'SET_ACTIVE_UNTIL';
export const SET_ACTIVE_UNTIL_DATE = 'SET_ACTIVE_UNTIL_DATE';
export const SET_VACATION_MODE = 'SET_VACATION_MODE';
export const SET_START_DATE_VACATION = 'SET_START_DATE_VACATION';
export const SET_END_DATE_VACATION = 'SET_END_DATE_VACATION';
export const SET_VACATION_MESSAGE = 'SET_VACATION_MESSAGE';
export const SET_OUT_OF_OFFICE = 'SET_OUT_OF_OFFICE';
export const SET_OUT_OF_OFFICE_DATE = 'SET_OUT_OF_OFFICE_DATE';
export const SET_OUT_OF_OFFICE_MESSAGE = 'SET_OUT_OF_OFFICE_MESSAGE';
export const SET_ACTIVE_SCHEDULE_PROPS = 'SET_ACTIVE_SCHEDULE_PROPS';


