import moment from 'moment';
import { DragUpdate } from 'react-beautiful-dnd';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { initialAction } from '../../screens/Patients/Content/components/PatientTabs/components/PatientCarePlan/components/PatientCarePlanTimeline/util/initialAction';

type viewType = 'dayView' | 'weekView' | 'monthView';

interface IColPosition {
  view: viewType;
  position: number;
  startDate: string;
  dayHour?: number;
}

const getHourFromString = (hourString: string) => {
  const hour = hourString.split(' ');
  if (hour[0] === '12' && hour[1] == 'am') return 0;

  let value = Number(hour[0]);
  if (hour[1] === 'pm') value += 12;
  return value;
};

const mapStringToColPosition: (stringValue: string) => IColPosition = (stringValue) => {
  const stringArray = stringValue.split('__');
  let colPosition: IColPosition = {
    view: stringArray[0] as viewType,
    position: Number(stringArray[1]),
    startDate: stringArray[2],
  };

  if (colPosition.view === 'dayView') {
    colPosition = {
      ...colPosition,
      dayHour: getHourFromString(stringArray[3]),
    };
  }
  return colPosition;
};

export const createActivity = (eventType: CarePlanActionEventType, destinationId: string) => {
  const destinationPosition = mapStringToColPosition(destinationId);
  const newStartDate = moment(destinationPosition.startDate);

  let destinationDate = moment(destinationPosition.startDate);
  if (destinationPosition.view === 'monthView') {
    const week_number = destinationPosition.position;
    destinationDate = destinationDate.date(1 + week_number * 7);
  } else if (destinationPosition.view === 'weekView') {
    const currentCol = newStartDate.day();
    const dayDiff = destinationPosition.position - currentCol;
    destinationDate = destinationDate.add(dayDiff, 'day');
  } else if (destinationPosition.view === 'dayView') {
    destinationDate.hour(destinationPosition.dayHour);
  }

  let newAction: IPatientCarePlanAction = {
    ...initialAction,
    start_date: destinationDate.toISOString(),
    end_date: destinationDate.toISOString(),
    end_date_type: 'fixed_date',
    is_part_of_rpm_plan: true,
    event_type: eventType,
  };

  if (eventType === 'goal') {
    newAction = {
      ...newAction,
      end_date: destinationDate.clone().add(1, 'days').toISOString(),
      goal_name: 'New Goal',
    };
  }

  return newAction;
};

export const transferActivity = (
  selectedAction: IPatientTimelineAction,
  sourceId: string,
  destinationId: string
) => {
  const sourcePosition = mapStringToColPosition(sourceId);
  const destinationPosition = mapStringToColPosition(destinationId);

  const colDiff = destinationPosition.position - sourcePosition.position;

  const transferredAction = { ...selectedAction };
  transferredAction.previousStartDate = transferredAction.start_date;
  const updatedDate = moment(transferredAction.start_date);
  if (sourcePosition.view === 'monthView') {
    updatedDate.add(colDiff * 7, 'day');
  } else if (sourcePosition.view === 'weekView') {
    updatedDate.add(colDiff, 'day');
  } else if (sourcePosition.view === 'dayView') {
    updatedDate.add(colDiff, 'hour');
  }

  transferredAction.start_date = updatedDate.toISOString();
  transferredAction.end_date = updatedDate.toISOString();

  return transferredAction;
};

const updateActionsOnDrag = (
  actions: IPatientCarePlanAction[],
  dragUpdate: DragUpdate,
  eventType: CarePlanActionEventType
) => {
  if (dragUpdate.draggableId.startsWith('actionButton')) {
    // action button drag
    const newAction = createActivity(eventType, dragUpdate.destination.droppableId);
    return [...actions, newAction];
  } else {
    // activity drag
    const actionId = Number(dragUpdate.draggableId);
    const selectedAction = actions.find(
      (a: IPatientCarePlanAction) => a.id === actionId
    ) as IPatientTimelineAction;

    transferActivity(
      selectedAction,
      dragUpdate.source.droppableId,
      dragUpdate.destination.droppableId
    );
    return actions;
  }
};
export default updateActionsOnDrag;
