import React from 'react';
import {useEffect} from 'react';
import styles from './styles.module.css';

const Status = ({answers = []}) => {
  const [answerCount, setAnswerCount] = React.useState(0);
  const [selectedAnswers, setSelectedAnswers] = React.useState([]);
  useEffect(() => {
    setAnswerCount(answers.length);
    setSelectedAnswers(
      answers.flatMap((ansGroup) => ansGroup.filter((ans) => ans.selected)),
    );
  }, [answers]);

  return (
    <div className={styles.container}>
      <div className={styles.line}>
        <hr
          className={styles.horizontalLine}
          style={{width: (answerCount + 1) * 20 + answerCount * 13}}
        />
        {selectedAnswers.map((ans, index) => (
          <div
            key={index}
            className={styles.answer}
            style={{borderColor: ans.critical ? '#F5485C' : '#EFEFF0'}}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Status;
