import Modal from 'components/Modal/VytracModal';
import { ActionModalType } from 'components/RPM/ActionModal/CarePlanActionModal';
import React from 'react';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import RecurrenceEditionModalBody from './components/RecurrenceEditionModalBody';
import RecurrenceEditionModalFooter from './components/RecurrenceEditionModalFooter';

interface IRecurrenceEditionModal {
  onCancel: () => void;
  onDelete: () => void;
  onSave: () => void;
  onClose: () => void;
  modalData: IPatientCarePlanAction;
  setModalData: (updatedSelectedAction: IPatientTimelineAction) => void;
  show: boolean;
  modalType?: ActionModalType;
  relativeMinDate?: Date;
  patientId?: number;
}

const RecurrenceEditionModal = ({
  onCancel,
  onDelete,
  onSave,
  onClose,
  modalData,
  setModalData,
  show,
  modalType = 'patient',
  relativeMinDate,
  patientId = null,
}: IRecurrenceEditionModal) => {
  return (
    <Modal
      title={'Edit Event'}
      body={
        <RecurrenceEditionModalBody
          data={modalData}
          setData={setModalData}
          modalType={modalType}
          relativeMinDate={relativeMinDate}
          patientId={patientId}
        />
      }
      footer={
        <RecurrenceEditionModalFooter onCancel={onCancel} onSave={onSave} onDelete={onDelete} />
      }
      centered
      onClose={onClose}
      show={show}
    />
  );
};
export default RecurrenceEditionModal;
