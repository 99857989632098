import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
interface TableHeaderProps {
  title: string;
}
const TableHeader = ({ title }: TableHeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex align-items-center">{title}</div>
      <div className="d-flex">
        <SearchBar onChange={() => {}} className="mr-2" />
        <FiltersButton onFiltersClick={() => {}} />
      </div>
    </div>
  );
};
export default TableHeader;
