import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import arrowLeftDark from '../../assets/icons/arrow_left_dark.png';
import styles from './styles.module.css';

interface IBackButton {
  label?: string;
  style?: CSSProperties;
  className?: string;
  color?: string;
  onBack?: () => void;
}

const BackButton = ({ label, style, className, color, onBack }: IBackButton) => {
  const history = useHistory();
  return (
    <div
      className={`d-flex justify-content-between ${styles.container} ${className || ''}`}
      style={style}
      onClick={onBack || (() => history.goBack())}
    >
      <div className="d-flex align-items-center">
        <img src={arrowLeftDark} alt="arrow_left_dark" className={styles.arrow} />
      </div>
      <div className="d-flex align-items-center justify-content-center h-100" style={{ color }}>
        <span>{label}</span>
      </div>
    </div>
  );
};

export default BackButton;
