import { FC } from 'react';
import { PatientSignup } from 'types/ApiModels/Administration/Dashboard';
import PatientSignupCard from './PatientSignupCard';

interface PatientSignupsProps {
  patientSignups: PatientSignup[];
}

const PatientSignups: FC<PatientSignupsProps> = ({ patientSignups }: PatientSignupsProps) => {
  return (
    <div className="d-flex flex-column gap-sm">
      {patientSignups.map((ps, idx) => (
        <PatientSignupCard patientSignup={ps} key={idx} />
      ))}
    </div>
  );
};
export default PatientSignups;
