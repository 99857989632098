import BreadcrumbsWithActions from 'components/BreadcrumbsWithActions';
import { ControlButtonLink } from 'components/ControlButton';
import { Route, useRouteMatch } from 'react-router-dom';
import ProfileActivityPoints from '../profile-activity-points';
import ProfileMyAccount from '../profile-my-account';
import { useProviderProfileContext } from '../store/ProviderProfileContextProvider';
import { profileControlsList, providerBreadCrumbsList } from '../utils';

const ProviderProfileInner = () => {
  const { path, url } = useRouteMatch();
  const {
    providerProfileState: {
      actions: { currentActions: Actions },
    },
  } = useProviderProfileContext();

  return (
    <>
      <BreadcrumbsWithActions breadcrumbs={providerBreadCrumbsList}>
        {Actions ? <Actions /> : null}
      </BreadcrumbsWithActions>
      <div className="container-with-padding d-flex gap flex-column">
        <div>
          {profileControlsList.map((pci, index) => (
            <ControlButtonLink
              key={pci.enumValue}
              className={index === 0 ? 'mr-2' : ' '}
              to={`${url}/${pci.route}`}
            >
              {pci.label}
            </ControlButtonLink>
          ))}
        </div>
        <Route path={`${path}/account`}>
          <ProfileMyAccount />
        </Route>
        <Route path={`${path}/points`}>
          <ProfileActivityPoints />
        </Route>
      </div>
    </>
  );
};
export default ProviderProfileInner;
