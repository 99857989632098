import ReadyToSubmitTable from '../components/ReadyToSubmitTable';
import tabEnum from './tabEnum';

const tabComponentMap = {
  [tabEnum.ALL_CODES]: ReadyToSubmitTable,
  [tabEnum.READY_TO_SUBMIT]: ReadyToSubmitTable,
  [tabEnum.SUBMITTED]: ReadyToSubmitTable,
  [tabEnum.PAID]: ReadyToSubmitTable,
};

export default tabComponentMap;
