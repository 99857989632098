import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const PatientSatisfactionHeader = () => {
  const { patientId } = useParams<{ patientId: string }>();

  return (
    //TODO: The link does not work from admin, maybe we have to improve the route definition over on patients section
    <div className={`dashboard-item-header ${styles.main}`}>
      <span className="font-weight-md">Patient satisfaction</span>
      <Link to={`/patients/${patientId}/encounters`} className="font-size-medium">
        Encounters
      </Link>
    </div>
  );
};

export default PatientSatisfactionHeader;
