import { AxiosError, AxiosResponse } from 'axios';
import ResponseError from 'types/Shared/response-error';

export function compareStringsCaseInsensitive(first, second) {
  return first.toLowerCase() === second.toLowerCase();
}

export const resolveClassName = (classNameList: string[], initialValue: string) => {
  let finalClassName = initialValue;
  if (classNameList) {
    classNameList.forEach((className) => (finalClassName += ` ${className}`));
  }

  return finalClassName;
};

export const debounce = (fn, timeout) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => fn(...args), timeout);
  };
};

/**
 * Debounce a promise callback.
 * @param fn A callback that returns a promise value
 * @param timeout the time to wait until the function is called
 * @returns a function reference that closures over a timeoutId. Returns a promise when called, if called again while its promise is unresolved it will reset the timeout, avoiding multiple calls during the timeout time
 */
export const debouncePromiseValue = <T = unknown>(fn, timeout) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const promise = new Promise<T>((resolve) => {
      timeoutId = setTimeout(() => {
        resolve(fn(...args));
      }, timeout);
    });
    return promise;
  };
};

export const throttle = (fn, throttleTimeout) => {
  let isDisabled = false;
  return function (...args) {
    if (isDisabled) {
      return;
    }
    fn(...args);
    isDisabled = true;
    setTimeout(() => {
      isDisabled = false;
    }, throttleTimeout);
  };
};

export const localIdCreator = () => {
  let current = 1;
  return () => {
    return current++;
  };
};

/**
 * Used for creating ids on new elements that have not yet been committed to backend and don't have a real id
 * @returns
 */
export const negativeLocalIdCreator = () => {
  let current = -1;
  return () => {
    return current--;
  };
};

export const parsePhoneNumber = (n: string): [parsed: string, isValidNumber: boolean] => {
  if (isNaN(parseInt(n))) {
    //if it doesn't have dash don't even format since the string is wrong
    if (!n.includes('-')) return ['', false];
    const dashedNumbers = n.split('-');
    if (dashedNumbers.length !== 3) return ['', false];
    const validLengths = [3, 3, 4];
    const numbersAreValid = dashedNumbers.reduce((res, current, idx) => {
      const isNumber = !isNaN(parseInt(current));
      const hasRightLength = current.length === validLengths[idx];
      return res && isNumber && hasRightLength;
    }, true);
    return numbersAreValid ? [n, true] : ['', false];
  } else if (n.length !== 10) {
    //no format to do, number is incomplete or too long
    return [n, true];
  } else {
    const numbers = [n.slice(0, 3), n.slice(3, 6), n.slice(6)];
    return [numbers.join('-'), true];
  }
};

/**
 *  Parses nonfield errors, field errors should be addressed by developers, validation should match both FE and BE
 * @param errors array of error.response.data
 * @returns
 */
export const getErrorMessage = (errors: any[]) => {
  const truthy = errors.filter(Boolean);
  if (truthy.length) {
    const errorMessages = truthy.reduce((message, error) => {
      const nonFieldError = error?.non_field_errors?.length
        ? error?.non_field_errors.join(', ')
        : '';
      return message + nonFieldError;
    }, '');
    return errorMessages;
  }
  return '';
};

export const objectToUrlParams = (obj: Record<string, string>) => {
  const result = Object.entries(obj).reduce((res, [key, value], idx) => {
    if (idx === 0) res += '?';
    else res += '&';
    res += `${key}=${encodeURIComponent(value)}`;
    return res;
  }, '');

  return result;
};

export const requestWrapper = async <T>(
  req: Promise<AxiosResponse<T>>
): Promise<[res: T, error: ResponseError]> => {
  try {
    const res = await req;
    return [res.data, null];
  } catch (error) {
    const errorAsAxios: AxiosError = error;
    return [
      null,
      {
        data: errorAsAxios.response.data,
        status: errorAsAxios.response.status,
        message: errorAsAxios.message,
      },
    ];
  }
};

export const validateContext = (context) => {
  if (!context) {
    throw new Error(
      'Context is null, you need a Context provider up in the tree to be able to use this hook.'
    );
  }
};
