import { ActivityColumn, ActivityWeekColumns } from 'types/Patients/PatientTimeline';
import { Activity, DateRangeActivity } from 'types/Patients/PatientTimeline/ActivityTimeline';
import {
  areDatesEqual,
  dateIsBetween,
  diffDateDays,
  getDateIndexesFromWeek,
  getDateRange,
  getTimelessDateFromStr,
  memoizedGetWeekArrayFromDate,
} from 'util/dateUtils/shared';
import { createRowsRenderer } from '../../utils/activityTimelineViewUtils';

const getWeekArrayFromDate = memoizedGetWeekArrayFromDate();
const WEEK_VIEW_AMOUNT_OF_COLUMNS = 7;

const getActivityFractions = (activity: Activity, dateStart: Date, dateEnd: Date) => {
  const allDaysRange = getDateRange(new Date(activity.start_date), new Date(activity.end_date));
  // goalCompletion
  //let remainingFraction = (activity.vytal.value / activity.vytal.goal) * allDaysRange.length;
  let remainingFraction = 0 * allDaysRange.length;
  const fractions = allDaysRange.map(() => {
    const currentFraction = remainingFraction > 1 ? 1 : remainingFraction;
    remainingFraction = remainingFraction > 1 ? remainingFraction - 1 : 0;
    return currentFraction;
  });
  const fractionBeginIdx = allDaysRange.findIndex(
    (d) => d.toDateString() === dateStart.toDateString()
  );
  const fractionEndIdx = allDaysRange.findIndex((d) => d.toDateString() === dateEnd.toDateString());
  return fractions.slice(fractionBeginIdx, fractionEndIdx + 1);
};

const rowsRenderer = createRowsRenderer(WEEK_VIEW_AMOUNT_OF_COLUMNS);

interface AddActivityToWeekDay {
  activity: Activity;
  dayWeekIdx: number;
  dateRangeActivitiesByDay: DateRangeActivity[][];
  chunk: number;
  isStarting?: boolean;
  isEnding?: boolean;
}

const addActivityToWeekday = ({
  activity,
  dayWeekIdx,
  dateRangeActivitiesByDay,
  chunk = 0,
  isStarting = false,
  isEnding = false,
}: AddActivityToWeekDay) => {
  const newDateRangeActivityItem: DateRangeActivity = {
    ...activity,
    isStarting,
    isEnding,
    renderRow: rowsRenderer.renderNewOrGetExisting(activity.id, dayWeekIdx),
    chunk: chunk,
  };
  return dateRangeActivitiesByDay[dayWeekIdx]
    ? [...dateRangeActivitiesByDay[dayWeekIdx], newDateRangeActivityItem]
    : [newDateRangeActivityItem];
};

interface AddWeekRangeActivitiesWithMutation {
  dateRange: Date[];
  currentDate: Date;
  dateRangeActivitiesByDay: DateRangeActivity[][];
  activity: Activity;
  dayWeekIdx: number;
}

const addWeekRangeActivitiesWithMutation = ({
  dateRange,
  currentDate,
  dateRangeActivitiesByDay,
  activity,
  dayWeekIdx,
}: AddWeekRangeActivitiesWithMutation) => {
  const fractionsSlice = getActivityFractions(
    activity,
    dateRange[0],
    dateRange[dateRange.length - 1]
  );

  dateRange.forEach((dr, dateRangeIdx, dateRangeArr) => {
    if (currentDate.getDate() === dr.getDate()) {
      dateRangeActivitiesByDay[dayWeekIdx] = addActivityToWeekday({
        activity,
        dayWeekIdx,
        dateRangeActivitiesByDay,
        isStarting: areDatesEqual(new Date(activity.start_date), currentDate),
        isEnding: areDatesEqual(new Date(activity.end_date), currentDate),
        chunk: fractionsSlice[dateRangeIdx],
      });
      if (dateRangeIdx === dateRangeArr.length - 1) {
        rowsRenderer.popRowByRegistryId(activity.id);
      }
    }
  });
};

export const getWeekColumns = (activities: Activity[], date: Date): ActivityWeekColumns => {
  rowsRenderer.clear();

  const dateRangeActivitiesByDay: DateRangeActivity[][] = [];
  const itemsByDay: Activity[][] = [];

  const currentWeek = getWeekArrayFromDate(date);
  const activitiesCopy = [...activities];
  activitiesCopy.sort((a, b) => {
    return new Date(a.start_date) < new Date(b.start_date) ? -1 : 1;
  });

  activitiesCopy.forEach((a) => {
    const [begin, end] = [getTimelessDateFromStr(a.start_date), getTimelessDateFromStr(a.end_date)];
    const [beginIdx, endIdx] = getDateIndexesFromWeek(currentWeek, [begin, end]);
    const isWithinWeek = beginIdx !== -1 || endIdx !== -1;
    const spansOverMultipleDays = beginIdx !== endIdx;
    const containsWholeWeek = !isWithinWeek && dateIsBetween(currentWeek[0], begin, end);

    if (!isWithinWeek && !containsWholeWeek) {
      return;
    }
    if (beginIdx !== -1 && !(spansOverMultipleDays || containsWholeWeek)) {
      //Process day items
      itemsByDay[beginIdx] = !itemsByDay[beginIdx] ? [a] : [...itemsByDay[beginIdx], a];
      return;
    }

    //Process range items
    currentWeek.forEach((d, dayWeekIdx) => {
      if (containsWholeWeek) {
        dateRangeActivitiesByDay[dayWeekIdx] = addActivityToWeekday({
          activity: a,
          dayWeekIdx,
          dateRangeActivitiesByDay,
          chunk: 0,
        });
      } else {
        const dateRange = getDateRange(
          beginIdx !== -1 ? begin : currentWeek[0],
          endIdx !== -1 ? end : currentWeek[currentWeek.length - 1]
        );
        addWeekRangeActivitiesWithMutation({
          dateRange,
          currentDate: d,
          dateRangeActivitiesByDay,
          activity: a,
          dayWeekIdx,
        });
      }
    });
  });

  dateRangeActivitiesByDay.forEach((dayActivities) => {
    dayActivities.sort((a, b) =>
      diffDateDays(new Date(a.start_date), new Date(a.end_date)) <
      diffDateDays(new Date(b.start_date), new Date(b.end_date))
        ? -1
        : 1
    );
  });

  const activitiesByDay = currentWeek.map<ActivityColumn>((day, idx) => ({
    headerLabel: day.toLocaleDateString('en-US', { weekday: 'short' }),
    items: itemsByDay[idx] ?? [],
    date: day,
  }));

  return {
    dateRangeActivitiesByDay,
    activitiesByDay,
    weekArray: currentWeek,
  };
};
