import AvatarStatus from 'types/ApiModels/Patients/AvatarStatus';

export const convertCountToPercenteage = (
  count: number,
  avatarStatus: RecursivePartial<AvatarStatus>,
  spacing: number
): number => {
  if (!avatarStatus) return 0;
  const { adherence, yellow_alerts, read_alerts, normal_readings } = avatarStatus;
  const totalCount = adherence + yellow_alerts + read_alerts + normal_readings;
  const defaultValue = spacing / 100;
  if (totalCount === 0 || count / totalCount < defaultValue) return defaultValue;

  return (count / totalCount) * 100 - spacing;
};
