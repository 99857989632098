import React, { useReducer } from 'react';
import { FormCheck } from 'react-bootstrap';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import DatePicker from 'components/DatePicker';
import InputField from 'components/InputField';
import {
  setEndDateVacation,
  setStartDateVacation,
  setVacationMessage,
  setVacationMode,
} from '../../store/actions/actionCreators';
import { useActiveScheduleContext } from '../../store/context/ActiveScheduleContext';
import styles from './styles.module.css';

const VacationMode = () => {
  const { activeScheduleState, dispatch } = useActiveScheduleContext();

  const onChangeVacationMode = (vacationMode: boolean) => {
    dispatch(setVacationMode(vacationMode));
  };

  const onChangeStartDateVacation = (startDateVacation: Date) => {
    dispatch(setStartDateVacation(startDateVacation));
  };

  const onChangeEndDateVacation = (endDateVacation: Date) => {
    dispatch(setEndDateVacation(endDateVacation));
  };

  const onChangeVacationMessage = (vacationMessage: string) => {
    dispatch(setVacationMessage(vacationMessage));
  };

  return (
    <div className={`d-flex flex-column ${styles.vacationContainer}`}>
      <div className={styles.container}>
        <div className={styles.text}>Vacation mode</div>
        <div
          className="d-flex align-items-center"
          onClick={() => onChangeVacationMode(!activeScheduleState.vacationMode)}
        >
          <FormCheck type="switch" checked={activeScheduleState.vacationMode} />
        </div>
      </div>
      {activeScheduleState.vacationMode && (
        <div className="d-flex flex-column">
          <div className={styles.rowContainer}>
            <div className={styles.title}>Start Date</div>
            <div className={styles.content}>
              <DatePicker
                placeHolder="Select start date..."
                value={
                  activeScheduleState.startDateVacation &&
                  convertDateToDatePickValue(activeScheduleState.startDateVacation)
                }
                onChange={(value) => onChangeStartDateVacation(convertDatepickToDate(value))}
                containerClassName={styles.datePickerContainer}
                inputClassName={styles.datePickerInput}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>End Date</div>
            <div className={styles.content}>
              <DatePicker
                placeHolder="Select end date..."
                value={
                  activeScheduleState.endDateVacation &&
                  convertDateToDatePickValue(activeScheduleState.endDateVacation)
                }
                onChange={(value) => onChangeEndDateVacation(convertDatepickToDate(value))}
                containerClassName={styles.datePickerContainer}
                inputClassName={styles.datePickerInput}
              />
            </div>
          </div>
          <div className={styles.outgoingMessageContainer}>
            <div className={styles.outgoingMessageTitle}>Automated Outgoing Mesage</div>
            <div className={styles.outgoingMessage}>
              <InputField
                className={styles.outgoingMessageInput}
                placeholder="Add outgoing message..."
                value={activeScheduleState.vacationMessage}
                onChange={(value) => onChangeVacationMessage(value.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VacationMode;
