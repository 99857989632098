import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ITimesHours } from 'types/Shared/ITimesHours';
import { convertTimesHoursToString } from 'util/dateUtils/timesHours';
import styles from './styles.module.css';
import TimeRow from './TimeRow';

interface IMultipleTimeSelector {
  times: ITimesHours[];
  onAddTime: () => void;
  onDeleteTime: (index: number) => void;
  onTimeChange: (index: number, time: ITimesHours) => void;
}
const MultipleTimeSelector = ({
  times,
  onAddTime,
  onDeleteTime,
  onTimeChange,
}: IMultipleTimeSelector) => {
  const [displayTimeSelector, setDisplayTimeSelector] = useState<boolean>(false);
  const timeSelectorRef = useRef<HTMLDivElement>(null);

  //Event listener to click outside of timeSelector
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (timeSelectorRef.current && !timeSelectorRef.current.contains(event.target)) {
        setDisplayTimeSelector(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [timeSelectorRef]);

  const inputValue = useMemo(() => {
    if (times.length === 1) {
      return convertTimesHoursToString(times[0]);
    } else {
      let currentValue = '';
      times.forEach((time: ITimesHours, index: number) => {
        if (index === 0) currentValue = currentValue.concat(convertTimesHoursToString(time));
        else currentValue = currentValue.concat(', ' + convertTimesHoursToString(time));
      });
      return currentValue;
    }
  }, [times]);

  return (
    <div className="w-100 h-100">
      <input
        className={`w-100 ${styles.input}`}
        type="text"
        placeholder="Select time"
        readOnly
        value={inputValue}
        onClick={() => setDisplayTimeSelector(true)}
      />
      <div
        ref={timeSelectorRef}
        className={styles.timeSelectorContainer}
        style={{ display: displayTimeSelector ? 'block' : 'none' }}
      >
        {times.map((time: ITimesHours, index: number) => (
          <TimeRow
            key={index}
            time={time}
            onChange={(newTime: ITimesHours) => onTimeChange(index, newTime)}
            onDelete={() => onDeleteTime(index)}
            disableDelete={times.length === 1}
          />
        ))}
        <button className={styles.addButton} onClick={() => onAddTime()}>
          Add another time
        </button>
      </div>
    </div>
  );
};
export default MultipleTimeSelector;
