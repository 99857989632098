import { ELEMENTS_PER_PAGE } from '../Context';

export const mapListToPagedList = (filteredList: any[]) => {
  const newPageList = [];
  if (filteredList && filteredList.length > 0) {
    const COMPLETE_PAGES_COUNT = Math.floor(filteredList.length / ELEMENTS_PER_PAGE);
    const INCOMPLETE_LAST_PAGE = filteredList.length % ELEMENTS_PER_PAGE;

    for (let i = 0; i < COMPLETE_PAGES_COUNT; i++) {
      const firstIndex = i * ELEMENTS_PER_PAGE;
      const lastIndex = firstIndex + ELEMENTS_PER_PAGE;

      newPageList.push(filteredList.slice(firstIndex, lastIndex));
    }

    if (INCOMPLETE_LAST_PAGE > 0) {
      newPageList.push(filteredList.slice(-1 * INCOMPLETE_LAST_PAGE));
    }
  }
  return newPageList;
};
