import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const BloodSugarIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7H1V4H4V7ZM8 4H5V7H8V4ZM6 0H3V3H6V0ZM16 6C12.25 6 10.28 7.25 9 8H0C0 10.21 1.79 12 4 12H5C6 12 6.92 11.63 7.62 11C9.06 9.78 10.68 8.74 12.55 8.36C13.61 8.15 14.78 8 16 8V6Z"
        fill={color}
      />
    </svg>
  );
};

export default BloodSugarIcon;
