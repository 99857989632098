import { Dispatch, useCallback, useEffect, useState } from 'react';
import { getCurrentProviderCareTeam } from 'services/administrationService';
import { CurrentProviderCareTeam } from 'types/ApiModels/CareTeam';
import { setCurrentProviderCareTeam } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useCurrentProviderCareTeam = (): [CurrentProviderCareTeam, Dispatch<DashboardAction>] => {
  const { dispatch, fetchRegistry } = useDashboardContext();

  const [currentCareTeam, setCurrentCareTeam] = useState<CurrentProviderCareTeam>();

  const fetchCurrentProviderCareTeam = useCallback(async () => {
    const data = await getCurrentProviderCareTeam();
    setCurrentCareTeam(data);
  }, []);

  const currentCareTeamInit = useCallback(async () => {
    await fetchCurrentProviderCareTeam();
    fetchRegistry.current.currentProviderCareTeam = true;
  }, [fetchRegistry, fetchCurrentProviderCareTeam]);

  useEffect(() => {
    if (!currentCareTeam || !fetchRegistry.current.currentProviderCareTeam) {
      currentCareTeamInit();
    }
  }, [fetchRegistry, currentCareTeam, currentCareTeamInit]);

  useEffect(() => {
    if (currentCareTeam) {
      dispatch(setCurrentProviderCareTeam(currentCareTeam));
    }
  }, [dispatch, currentCareTeam]);

  return [currentCareTeam, dispatch];
};

export default useCurrentProviderCareTeam;
