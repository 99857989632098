import { IProviderAction, ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';

export const initialState: Record<ProviderActionType, Partial<IProviderAction>> = {
  vytal_request: {
    note: '',
    patient_note: '',
  },
  send_automated_chat: {
    note: '',
  },
  request_appointment: {
    schedule_note: '',
  },
  provide_questionnaire: {
    questionnaire_id: null,
    note: '',
    patient_note: '',
  },
  complete: {
    note: '',
  },
  call_patient: {},
  assign: {
    assign_to: [],
    note: '',
  },
};
