import { AdminTabEnum } from 'screens/Administration/util';
import AccountSettings from '../AdminAccountSettings/AccountSettings';
import ActivityPoints from '../AdminActivityPoints/ActivityPoints';
import AuditTracking from '../AdminAuditTracking/AuditTracking';
import { AdminDashboard } from '../AdminDashboard';
import EmailSettings from '../AdminEmailSettings/EmailSettings';
import Forms from '../AdminForms/Forms';
import HealthScoring from '../AdminHealthScoring/HealthScoring';
import Notifications from '../AdminNotifications/Notifications';
import AdminPatients from '../AdminPatients/AdminPatients';
import AdminPatientSettings from '../AdminPatientSettings/AdminPatientSettings';
import AdminProviders from '../AdminProviders/AdminProviders';
import Reports from '../AdminReports/Reports';
import Roles from '../AdminRoles/Roles';
import { AdminRulesEngine } from '../AdminRulesEngine';

const AdminTabComponentMap = {
  [AdminTabEnum.DASHBOARD]: AdminDashboard,
  [AdminTabEnum.PROVIDERS]: AdminProviders,
  [AdminTabEnum.ROLES]: Roles,
  [AdminTabEnum.PATIENTS]: AdminPatients,
  [AdminTabEnum.PATIENT_SETTINGS]: AdminPatientSettings,
  [AdminTabEnum.NOTIFICATIONS]: Notifications,
  [AdminTabEnum.FORMS]: Forms,
  [AdminTabEnum.EMAIL_SETTINGS]: EmailSettings,
  [AdminTabEnum.ACCOUNT_SETTINGS]: AccountSettings,
  [AdminTabEnum.REPORTS]: Reports,
  [AdminTabEnum.RULES_ENGINE]: AdminRulesEngine,
  [AdminTabEnum.AUDIT_TRACKING]: AuditTracking,
  [AdminTabEnum.HEALTH_SCORING]: HealthScoring,
  [AdminTabEnum.ACTIVITY_POINTS]: ActivityPoints,
};

export default AdminTabComponentMap;
