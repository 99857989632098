import { AlertImportanceOptions } from 'components/AlertIcons/utils/AlertOptions';
import React from 'react';
import styles from './styles.module.css';
import { mapAlertTypeToColor } from './utils/map-alert-to-color';

const QuestionnaireDetails = () => {
  return (
    <div className={styles.questionnaireContainer}>
      {/*       <div className={`${styles.lineContainer}`}>
        <hr className="m-0" />
      </div> */}

      <div
        className={`d-flex align-items-center justify-content-between ${styles.answersContainer}`}
      >
        {Array.from(Array(5)).map((_, index) => (
          <div
            key={index}
            style={{ borderColor: mapAlertTypeToColor(AlertImportanceOptions.NORMAL) }}
          />
        ))}
        {Array.from(Array(5)).map((_, index) => (
          <div
            key={index}
            style={{ borderColor: mapAlertTypeToColor(AlertImportanceOptions.CRITICAL) }}
          />
        ))}
        {Array.from(Array(5)).map((_, index) => (
          <div
            key={index}
            style={{ borderColor: mapAlertTypeToColor(AlertImportanceOptions.YELLOW) }}
          />
        ))}
      </div>

      <button className={styles.viewAnswersButton}>View answers</button>
    </div>
  );
};
export default QuestionnaireDetails;
