import React from 'react';
import NoteCheckbox from '../NoteCheckbox';
import Select from '../../../form/Select';
import styles from './styles.module.css';
import { mapPushTypeToLabel, PushTypeLabel } from 'components/RPM/utils/pushTypeMapper';
import InputField from 'components/InputField';
import { PushType } from 'types/ApiModels/CarePlan/PushType';

interface IPush {
  boxShadow: string;
  label: string;
  selectLabel: string;
  checked: boolean;
  onCheckChange: () => void;
  options: string[];
  selectedOption: string;
  onOptionChange: (newOption: string) => void;
  when: PushType;
  onWhenChange: (value: PushTypeLabel) => void;
  howLong: number;
  onHowLongChange: (howLong: number) => void;
}

const whenOptions = [...Object.values(PushTypeLabel)];

export default function Push({
  boxShadow,
  label,
  selectLabel,
  checked,
  onCheckChange,
  options,
  selectedOption,
  onOptionChange,
  when,
  onWhenChange,
  howLong,
  onHowLongChange,
}: IPush) {
  return (
    <NoteCheckbox
      label={`Push ${label}`}
      onChangeCheckbox={onCheckChange}
      checked={checked}
      boxShadow={boxShadow}
    >
      <div>
        <div className={styles.rowContainer}>
          <div className={styles.centerText}>{label}</div>
          <div className={styles.select}>
            <Select
              options={options}
              placeholder={selectLabel}
              currentValue={selectedOption}
              onChange={(e) => onOptionChange(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.rowContainer}>
          <div className={styles.centerText}>When to push</div>
          <div className={styles.select}>
            <Select
              options={whenOptions}
              currentValue={mapPushTypeToLabel(when)}
              placeholder="Select when to push"
              onChange={(e) => onWhenChange(e.target.value as PushTypeLabel)}
            />
          </div>
        </div>
        <div className={styles.rowContainer}>
          <div className={styles.centerText}>Select time</div>
          <div className={styles.select} style={{ position: 'relative' }}>
            <input
              className={styles.input}
              type="number"
              value={howLong}
              min={1}
              onChange={(e) => onHowLongChange(Number(e.target.value))}
            />
            <span style={{ position: 'absolute', right: 10, top: 8 }}>min</span>
          </div>
        </div>
      </div>
    </NoteCheckbox>
  );
}
