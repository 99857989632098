import Button from 'components/Button';
import VyTracModal from 'components/Modal';
import { SearchBarAlt } from 'components/SearchBar';
import { FC, useState } from 'react';
import { Language } from 'types/ApiModels/Users';
import styles from '../BulletedModals.module.css';

interface LanguagesModalProps {
  languages: Language[];
  assignedLanguagesIds: number[];
  handleClose: () => void;
  handleConfirm: (lids: number[]) => void;
  show: boolean;
}

const LanguagesModal: FC<LanguagesModalProps> = ({
  languages,
  assignedLanguagesIds,
  handleClose,
  handleConfirm,
  show,
}: LanguagesModalProps) => {
  const [currentlySelected, setCurrentlySelected] = useState(assignedLanguagesIds);

  const handleLanguageSelect = (languageId: number) => {
    setCurrentlySelected((lids) => {
      const existingIdx = lids.findIndex((id) => id === languageId);
      return existingIdx === -1 ? [...lids, languageId] : lids.filter((id) => id !== languageId);
    });
  };

  const [search, setSearch] = useState('');

  return (
    <VyTracModal
      show={show}
      title="Assign languages"
      onClose={handleClose}
      body={
        <div className="d-flex flex-column gap">
          <SearchBarAlt onChange={(e) => setSearch(e.target.value)} value={search} />
          <div>
            {languages.flatMap((l) => {
              if (!l.name.toLowerCase().includes(search.toLowerCase())) return [];

              const isSelected = currentlySelected?.some((arId) => l.id === arId);
              return [
                <div
                  key={l.id}
                  className={`d-flex gap justify-content-between ${styles['container']} cursor-pointer`}
                  onClick={() => handleLanguageSelect(l.id)}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className={`${styles['custom-input']} ${isSelected ? styles.selected : ''}`}
                    ></div>
                    <input type="checkbox" hidden value={l.id} checked={isSelected} readOnly />
                  </div>
                  <div className="flex-grow-1 ">{l.name}</div>
                </div>,
              ];
            })}
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={handleClose} />
          <Button label="confirm" onClick={() => handleConfirm(currentlySelected)} />
        </div>
      }
    />
  );
};
export default LanguagesModal;
