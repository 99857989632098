import { convertDatepickRangeToDates } from 'util/dateUtils';
import { SET_DATE_RANGE, SET_SCHEDULES, TOGGLE_SCHEDULE_COMPLETE } from './schedules-action-types';

export const schedulesInitialState = {
  schedules: [],
  currentDateRange: null,
  filteredSchedules: [],
};

const updateFiltered = (schedules, dateRange) =>
  schedules.filter((s) => {
    const scheduleDate = new Date(s.date);
    const [start, end] = convertDatepickRangeToDates(dateRange);
    return scheduleDate >= start && scheduleDate <= end;
  });

const schedulesReducer = (state = schedulesInitialState, action) => {
  switch (action.type) {
    case SET_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
        filteredSchedules: updateFiltered(action.payload, state.currentDateRange),
      };
    case TOGGLE_SCHEDULE_COMPLETE: {
      const schedulesCopy = [...state.schedules];
      const scheduleIdx = state.schedules.findIndex((s) => s.id === action.payload);
      schedulesCopy[scheduleIdx].completed = !schedulesCopy[scheduleIdx].completed;

      return { ...state, schedules: schedulesCopy };
    }
    case SET_DATE_RANGE: {
      const { payload: range } = action;
      const filtered = updateFiltered(state.schedules, range);
      return {
        ...state,
        currentDateRange: action.payload,
        filteredSchedules: filtered,
      };
    }
    default:
      return state;
  }
};

export default schedulesReducer;
