import DashboardAdherence from 'types/ApiModels/Dashboard/DashboardAdherence';
import { DashboardEscalated } from 'types/ApiModels/Dashboard/DashboardEscalated';
import DashboardOutstandingChats from 'types/ApiModels/Dashboard/DashboardOutstandingChats';
import DashboardRedAlert from 'types/ApiModels/Dashboard/DashboardRedAlert';
import DashboardRedAlertDetail from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';
import DashboardSchedule from 'types/ApiModels/Dashboard/DashboardSchedule';
import { IDashboardToDo, IDashboardToDoSingle } from 'types/ApiModels/Dashboard/DashboardToDo';
import { DashboardStickynotes } from 'types/ApiModels/Dashboard/DashboardStickynotes';
import { DashboardUrgency } from 'types/ApiModels/Dashboard/DashboardUrgency';
import DashboardYellowAlert from 'types/ApiModels/Dashboard/DashboardYellowAlert';
import DashboardYellowAlertDetail from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { PaginatedResponse } from 'types/ApiModels/General';
import IVytalChart from 'types/Dashboard/VytalChart';
import { IAdherenceGraphic } from 'types/Dashboard/AdherenceGraphic';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import { ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { CurrentProviderCareTeam } from 'types/ApiModels/CareTeam';

export enum DashboardTypes {
  ADD_TRACKER_TIME = 'ADD_TRACKER_TIME',
  TOGGLE_IS_TRACKING = 'TOGGLE_IS_TRACKING',
  CHANGE_SELECTED_TIMER = 'CHANGE_SELECTED_TIMER',
  SET_TRACKING_ACTIVITY_ID = 'SET_TRACKING_ACTIVITY_ID',
  RESET_TRACKING_DURATION = 'RESET_TRCKING_DURATION',
  SET_TRACKING_TYPE = 'SET_TRACKING_TYPE',
  SET_TODO_ITEMS = 'SET_TODO_ITEMS',
  SET_TODO_ITEMS_SINGLE = 'SET_TODO_ITEMS_SINGLE',
  SET_RED_ALERTS = 'SET_RED_ALERTS',
  SET_YELLOW_ALERTS = 'SET_YELLOW_ALERTS',
  SET_OUTSTANDING_CHATS = 'SET_OUTSTANDING_CHATS',
  SET_ADHERENCE = 'SET_ADHERENCE',
  SET_URGENCY = 'SET_URGENCY',
  SET_ESCALATED = 'SET_ESCALATED',
  SET_SCHEDULE = 'SET_SCHEDULE',
  SET_STICKYNOTES = 'SET_STICKYNOTES',
  SET_ADHERENCE_GRAPHIC = 'SET_ADHERENCE_GRAPHIC',
  SET_SELECTED_RED_ALERT = 'SET_SELECTED_RED_ALERT',
  SET_SELECTED_YELLOW_ALERT = 'SET_SELECTED_YELLOW_ALERT',
  SET_SELECTED_ADHERENCE = 'SET_SELECTED_ADHERENCE',
  SET_VYTAL_CHART = 'SET_VYTAL_CHART',
  SET_TOTAL_PATIENTS = 'SET_TOTAL_PATIENTS',
  ADD_SINGLE_TODO = 'ADD_SINGLE_TODO',
  SET_SHOW_ACTION_MODAL = 'SET_SHOW_ACTION_MODAL',
  SET_QUESTIONNAIRE_TEMPLATES = 'SET_QUESTIONNAIRE_TEMPLATES',
  SET_PATIENT_QUESTIONNAIRES = 'SET_PATIENT_QUESTIONNAIRES',
  SET_CURRENT_TODO = 'SET_CURRENT_TODO',
  SET_CURRENT_PROVIDER_CARE_TEAM = 'SET_CURRENT_PROVIDER_CARE_TEAM',
}

export type DashboardAction =
  | {
      type: DashboardTypes.ADD_TRACKER_TIME;
    }
  | {
      type: DashboardTypes.TOGGLE_IS_TRACKING;
      payload: { isTracking: boolean };
    }
  | {
      type: DashboardTypes.CHANGE_SELECTED_TIMER;
      payload: { patientId: number };
    }
  | {
      type: DashboardTypes.SET_TRACKING_ACTIVITY_ID;
      payload: { activityId: number };
    }
  | {
      type: DashboardTypes.RESET_TRACKING_DURATION;
    }
  | {
      type: DashboardTypes.SET_TRACKING_TYPE;
      payload: { trackingType: TrackingType };
    }
  | {
      type: DashboardTypes.SET_TODO_ITEMS;
      payload: { toDoItems: IDashboardToDo[] };
    }
  | {
      type: DashboardTypes.SET_TODO_ITEMS_SINGLE;
      payload: { toDoItemsSingle: Record<number, IDashboardToDoSingle> };
    }
  | {
      type: DashboardTypes.SET_RED_ALERTS;
      payload: { redAlerts: Record<number, PaginatedResponse<DashboardRedAlert>> };
    }
  | {
      type: DashboardTypes.SET_YELLOW_ALERTS;
      payload: { yellowAlerts: DashboardYellowAlert[] };
    }
  | {
      type: DashboardTypes.SET_OUTSTANDING_CHATS;
      payload: { outstandingChats: Record<number, PaginatedResponse<DashboardOutstandingChats>> };
    }
  | {
      type: DashboardTypes.SET_ADHERENCE;
      payload: { adherence: Record<number, PaginatedResponse<DashboardAdherence>> };
    }
  | {
      type: DashboardTypes.SET_ADHERENCE_GRAPHIC;
      payload: { adherenceGraphic: IAdherenceGraphic[] };
    }
  | {
      type: DashboardTypes.SET_URGENCY;
      payload: { urgency: Record<number, PaginatedResponse<DashboardUrgency>> };
    }
  | {
      type: DashboardTypes.SET_ESCALATED;
      payload: { escalated: Record<number, PaginatedResponse<DashboardEscalated>> };
    }
  | {
      type: DashboardTypes.SET_SCHEDULE;
      payload: { schedule: Record<number, PaginatedResponse<DashboardSchedule>> };
    }
  | {
      type: DashboardTypes.SET_STICKYNOTES;
      payload: { stickynotes: Record<number, PaginatedResponse<DashboardStickynotes>> };
    }
  | {
      type: DashboardTypes.SET_SELECTED_RED_ALERT;
      payload: { selectedRedAlert: DashboardRedAlertDetail | null };
    }
  | {
      type: DashboardTypes.SET_SELECTED_YELLOW_ALERT;
      payload: { selectedYellowAlert: DashboardYellowAlertDetail | null };
    }
  | {
      type: DashboardTypes.SET_SELECTED_ADHERENCE;
      payload: { selectedAdherence: DashboardAdherence | null };
    }
  | {
      type: DashboardTypes.SET_VYTAL_CHART;
      payload: { vytalChart: IVytalChart; patientId: number };
    }
  | {
      type: DashboardTypes.SET_TOTAL_PATIENTS;
      payload: { totalPatients: number };
    }
  | {
      type: DashboardTypes.ADD_SINGLE_TODO;
      payload: { toDoSingle: IDashboardToDoSingle };
    }
  | {
      type: DashboardTypes.SET_SHOW_ACTION_MODAL;
      payload: { showActionModal: boolean; actionType: ProviderActionType; openedAlertId: number };
    }
  | {
      type: DashboardTypes.SET_QUESTIONNAIRE_TEMPLATES;
      payload: { questionnaireTemplates: Record<number, PaginatedResponse<IQuestionnaire>> };
    }
  | {
      type: DashboardTypes.SET_PATIENT_QUESTIONNAIRES;
      payload: { patientId: number; patientQuestionnaires: IPatientQuestionnaire[] };
    }
  | {
      type: DashboardTypes.SET_CURRENT_TODO;
      payload: { currentTodo: IDashboardToDoSingle };
    }
  | {
      type: DashboardTypes.SET_CURRENT_PROVIDER_CARE_TEAM;
      payload: { currentProviderCareTeam: CurrentProviderCareTeam };
    };
