import { TrackingActivityTemplate } from 'types/ApiModels/Administration';

export enum PatientTrackingActionTypes {
  SET_SELECTED_ACTIVITY = 'SET_SELECTED_ACTIVITY',
}

export type PatientTrackingAction = {
  type: PatientTrackingActionTypes.SET_SELECTED_ACTIVITY;
  payload: {
    selectedActivity: TrackingActivityTemplate;
  };
};
