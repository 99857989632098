import React from 'react';
import styles from './styles.module.css';

import {FormCheck} from 'react-bootstrap';
import {uniqueId} from 'lodash';
import VytracBaseNode from '../../../../../../../../../../components/VytracBaseNode';

const AnswerNode = ({data, type}) => {
  const [show, setShow] = React.useState(false);

  return (
    <VytracBaseNode
      style={data.critical ? {backgroundColor: '#F5485C'} : {}}
      className={styles.innerBox}
      data={data}
      showMenu={show}
      setShowMenu={setShow}
      menuStyle={{right: -175}}
      type={type}
    >
      <div>
        <FormCheck
          id={uniqueId('critical-response')}
          type="switch"
          label="Critical response"
          className={styles.switch}
          checked={data.critical}
          onChange={(e) => data.setCritical(e.target.checked)}
        />
      </div>
      <button
        type="button"
        className={`${styles.ripple} ${styles.button}`}
        onClick={data.removeElement}
      >
        Remove answer
      </button>
    </VytracBaseNode>
  );
};
export default AnswerNode;
