import { AdminTabEnum } from 'screens/Administration/util';
import { AccountSettingsActions } from '../AdminAccountSettings/AccountSettings';
import { ActivityPointsActions } from '../AdminActivityPoints/ActivityPoints';
import { AuditTrackingActions } from '../AdminAuditTracking/AuditTracking';
import { FormsActions } from '../AdminForms';
import { HealthScoringActions } from '../AdminHealthScoring/HealthScoring';
import { NotificationsActions } from '../AdminNotifications/Notifications';
import { ReportsActions } from '../AdminReports/Reports';
import { RolesActions } from '../AdminRoles/Roles';
import { RulesEngineActions } from '../AdminRulesEngine';

const AdminActionComponentByTab = {
  [AdminTabEnum.ROLES]: RolesActions,
  [AdminTabEnum.NOTIFICATIONS]: NotificationsActions,
  [AdminTabEnum.FORMS]: FormsActions,
  [AdminTabEnum.ACCOUNT_SETTINGS]: AccountSettingsActions,
  [AdminTabEnum.REPORTS]: ReportsActions,
  [AdminTabEnum.RULES_ENGINE]: RulesEngineActions,
  [AdminTabEnum.AUDIT_TRACKING]: AuditTrackingActions,
  [AdminTabEnum.HEALTH_SCORING]: HealthScoringActions,
  [AdminTabEnum.ACTIVITY_POINTS]: ActivityPointsActions,
};

export const selfLoadingActionBlockList = [
  AdminTabEnum.DASHBOARD,
  AdminTabEnum.PROVIDERS,
  AdminTabEnum.PATIENTS,
  AdminTabEnum.EMAIL_SETTINGS,
  AdminTabEnum.PATIENT_SETTINGS,
];

export default AdminActionComponentByTab;
