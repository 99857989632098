import { FC } from 'react';
import { communicationColor, ICommunicationIcon } from './utils';

const VideoCallIcon: FC<ICommunicationIcon> = ({
  width,
  height,
  isActive,
  ...rest
}: ICommunicationIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 21 14`}
    fill={isActive ? communicationColor.active : communicationColor.inactive}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M20.475 1.1C20.16 0.9 19.74 0.9 19.425 1.1L14.805 4.1V2C14.805 0.9 13.86 0 12.705 0H2.1C0.945 0 0 0.9 0 2V12C0 13.1 0.945 14 2.1 14H12.6C13.755 14 14.7 13.1 14.7 12V9.9L19.32 12.9C19.53 13 19.74 13.1 19.95 13.1C20.16 13.1 20.265 13.1 20.475 13C20.79 12.8 21 12.5 21 12.1V2C21 1.6 20.79 1.3 20.475 1.1ZM12.6 12H2.1V2H12.6V12ZM18.9 10.1L14.7 7.4V6.5L18.9 3.8V10.1Z"
      fill={isActive ? communicationColor.active : communicationColor.inactive}
    />
  </svg>
);

export default VideoCallIcon;
