import Select from 'components/form/Select';
import React from 'react';

interface ITriggerEventSelect {
  className: string;
}
const TriggerEventSelect = ({ className }: ITriggerEventSelect) => {
  const triggerEventOptions = [
    'Before a mesaurement',
    'After a measurement',
    'Before a call',
    'After a call',
    'Once a day',
    'Once a week',
    'Once a month',
  ];

  return (
    <Select
      options={triggerEventOptions}
      placeholder={'Select trigger event'}
      containerClassName={className}
    />
  );
};
export default TriggerEventSelect;
