import { ConversationMetadata } from 'types/ApiModels/conversations';
import { PaginatedResponse } from 'types/ApiModels/General';
import { PatientEncounter } from 'types/ApiModels/Patients/encounter';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
//mocks
import { MockBroadcast } from '../mocks/types';

export enum MessagesTypes {
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  SWITCH_LAST_BREADCRUMB = 'SWITCH_LAST_BREADCRUMB',
  SET_ACTIONS = 'SET_ACTIONS',
  TOGGLE_NEW_CONVERSATION_MODAL = 'TOGGLE_NEW_CONVERSATION_MODAL',
  SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT',
  CLEAR_SELECTED_PATIENT = 'CLEAR_SELECTED_PATIENT',
  SET_PATIENT_LIST = 'SET_PATIENT_LIST',
  SET_SELECTED_CHAT = 'SET_SELECTED_CHAT',
  SET_BROADCASTS = 'SET_BROADCASTS',
  SET_BROADCAST_TEMPLATES = 'SET_BROADCAST_TEMPLATES',
  ADD_TO_SELECTED_BROADCAST = 'SET_SELECTED_BROADCAST',
  REMOVE_FROM_SELECTED_BROADCAST = 'REMOVED_FROM_SELECTED_BROADCAST',
  SET_OPENED_BROADCAST = 'SET_OPENED_BROADCAST',
  SET_OPENED_BROADCAST_TEMPLATE = 'SET_OPENED_BROADCAST_TEMPLATE',

  //new
  SET_METADATA_MAPPER = 'SET_METADATA_MAPPER',
}

export type MessagesAction =
  | { type: MessagesTypes.SET_BREADCRUMBS; payload: BreadCrumbPath[] }
  | { type: MessagesTypes.SWITCH_LAST_BREADCRUMB; payload: BreadCrumbPath }
  | { type: MessagesTypes.SET_ACTIONS; payload: React.FC<any> }
  | { type: MessagesTypes.TOGGLE_NEW_CONVERSATION_MODAL }
  | {
      type: MessagesTypes.SET_SELECTED_PATIENT;
      payload: { selectedPatient: PatientEncounter };
    }
  | {
      type: MessagesTypes.CLEAR_SELECTED_PATIENT;
    }
  | { type: MessagesTypes.SET_PATIENT_LIST; payload: Record<number, PaginatedResponse<PatientGet>> }
  | { type: MessagesTypes.SET_SELECTED_CHAT; payload: { conversationSID: string } }
  | { type: MessagesTypes.SET_BROADCASTS; payload: MockBroadcast[] }
  | { type: MessagesTypes.SET_BROADCAST_TEMPLATES; payload: MockBroadcast[] }
  | { type: MessagesTypes.ADD_TO_SELECTED_BROADCAST; payload: number }
  | { type: MessagesTypes.REMOVE_FROM_SELECTED_BROADCAST; payload: number }
  | { type: MessagesTypes.SET_OPENED_BROADCAST; payload: MockBroadcast }
  | { type: MessagesTypes.SET_OPENED_BROADCAST_TEMPLATE; payload: MockBroadcast }
  | {
      type: MessagesTypes.SET_METADATA_MAPPER;
      payload: { getMetadataByParticipantSID: (participantSID: string) => ConversationMetadata };
    };
