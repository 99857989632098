import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import GenericTable from '../../../../../../../../components/GenericTable';
import { getSurveyList } from '../../../../../../../../services/surveyService';
import { withRouter } from 'react-router-dom';
import TabRouteMap from '../../../../../../util/TabRouteMap';
import TabEnum from '../../../../../../util/TabEnum';
import GenericColumn from 'types/Shared/GenericColumn';
import ControlButton from 'components/ControlButton';

type surveyType = 'providers' | 'patients';

const Surveys = ({ history }) => {
  const [surveys, setSurveys] = React.useState([]);

  const [surveySelected, setSurveySelected] = useState<surveyType>('providers');

  useEffect(() => {
    const fetchSurveys = async () => {
      const surveyData = await getSurveyList(surveySelected);
      setSurveys(surveyData);
    };
    fetchSurveys();
    return () => {
      setSurveys([]);
    };
  }, [surveySelected]);

  const columns: GenericColumn[] = [
    {
      title: 'ID',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      key: 'name',
      render: (rowInfo) => (
        <div
          className={styles.surveyName}
          onClick={() => history.push(`${TabRouteMap[TabEnum.SURVEYS]}/${rowInfo.id}`)}
        >
          {rowInfo.name}
        </div>
      ),
    },
    {
      title: 'Questions',
      key: 'questions',
      textAlign: 'end',
      cellTextAlign: 'end',
    },
  ];

  return (
    <div className="container-with-padding">
      <div className={styles.buttonContainer}>
        <ControlButton
          selected={surveySelected === 'providers'}
          onClick={() => setSurveySelected('providers')}
          style={{ marginRight: '10px' }}
        >
          providers
        </ControlButton>
        <ControlButton
          selected={surveySelected === 'patients'}
          onClick={() => setSurveySelected('patients')}
        >
          patients
        </ControlButton>
      </div>
      <div
        className="dashboard-detail"
        style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05))' }}
      >
        <div className="dashboard-item-header">Surverys</div>
        <GenericTable
          columns={columns}
          data={surveys}
          //headerRowClassName={styles.th}
          //rowClassName={styles.td}
        />
      </div>
    </div>
  );
};
export default withRouter(Surveys);
