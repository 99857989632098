const mockedSchedules = [
  {
    id: 'f3d67054-f596-4bd2-b46f-408e13d7a837',
    title:
      'blandit non interdum in ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus',
    message:
      'Fusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem.',
    completed: true,
    date: '2022-02-04T11:18:02Z',
  },
  {
    id: 'b34f5332-4c62-4e27-a01d-4a75361f9684',
    title:
      'erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin eu',
    message:
      'Aenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh.',
    completed: false,
    date: '2022-01-28T05:16:01Z',
  },
  {
    id: 'b9ddc72d-2fab-4d98-b36e-0aa3584e01c5',
    title:
      'curae duis faucibus accumsan odio curabitur convallis duis consequat dui',
    message:
      'Duis consequat dui nec nisi volutpat eleifend. Donec ut dolor. Morbi vel lectus in quam fringilla rhoncus.',
    completed: true,
    date: '2022-01-24T07:59:52Z',
  },
  {
    id: '38adb576-15ea-485e-bb2f-2709e33dbd57',
    title:
      'non mi integer ac neque duis bibendum morbi non quam nec dui luctus',
    message:
      'Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat. Vestibulum sed magna at nunc commodo placerat.',
    completed: true,
    date: '2022-02-11T14:46:15Z',
  },
  {
    id: '975f4234-7a8e-4f8a-8e3e-7815839a0eb3',
    title:
      'elit proin interdum mauris non ligula pellentesque ultrices phasellus id sapien in sapien iaculis congue vivamus metus arcu adipiscing',
    message:
      'Curabitur at ipsum ac tellus semper interdum. Mauris ullamcorper purus sit amet nulla. Quisque arcu libero, rutrum ac, lobortis vel, dapibus at, diam.',
    completed: true,
    date: '2022-02-06T19:57:07Z',
  },
  {
    id: 'a29c828c-ba7c-47bb-b4b0-918b3cca359d',
    title:
      'justo sollicitudin ut suscipit a feugiat et eros vestibulum ac est lacinia nisi venenatis',
    message:
      'Phasellus sit amet erat. Nulla tempus. Vivamus in felis eu sapien cursus vestibulum.',
    completed: false,
    date: '2022-02-27T01:52:35Z',
  },
  {
    id: 'e8d95973-7137-4bc6-a103-08edc5cdea12',
    title:
      'vitae consectetuer eget rutrum at lorem integer tincidunt ante vel ipsum praesent blandit lacinia',
    message:
      'Quisque porta volutpat erat. Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla. Nunc purus.',
    completed: true,
    date: '2022-01-22T05:08:42Z',
  },
  {
    id: '5ece6bac-62fa-4f51-8aa0-74bc857e442f',
    title:
      'fusce lacus purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam',
    message:
      'Nullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris.',
    completed: true,
    date: '2022-01-24T07:03:05Z',
  },
  {
    id: 'd613ba2f-704d-4ed0-9345-646838e80bfc',
    title:
      'in hac habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla ut erat id mauris',
    message:
      'Praesent blandit. Nam nulla. Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede.',
    completed: false,
    date: '2022-02-17T17:49:40Z',
  },
  {
    id: '32cea417-f90f-4d7d-9096-5efc91c6c182',
    title:
      'quisque erat eros viverra eget congue eget semper rutrum nulla nunc purus phasellus in felis donec semper',
    message:
      'Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti.',
    completed: false,
    date: '2022-02-11T21:46:00Z',
  },
];

export default mockedSchedules;
