import React from 'react';
import PatientDocumentItem from './PatientDocumentItem';
import './styles.css';

const Body = ({ patient }) => {
  // Functions and subroutines
  const mapPatientToDocumentList = (patient) => [
    { name: 'Disclosure agreement 1.docx', date: '04.11.2021', checked: true },
    { name: 'Disclosure agreement 2.docx', date: '04.11.2021', checked: true },
    { name: 'Disclosure agreement 3.docx', date: '04.11.2021', checked: false },
    { name: 'Disclosure agreement 4.docx', date: '04.11.2021', checked: false },
  ];

  // Variables and constants
  const documentList = mapPatientToDocumentList(patient);

  return (
    <div className="patient-documents-body-container">
      {documentList.map((document, idx) => (
        <PatientDocumentItem
          document={document}
          checked={document.checked}
          //TODO: add a better key
          key={`patient-document-item-${document.name}-${idx}`}
        />
      ))}
    </div>
  );
};

export default Body;
