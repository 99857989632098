import { FC } from "react";
import { Row } from "react-bootstrap";
import EncounterControls from "./EncounterControls";
import EncounterSummary from "./EncounterSummary";

interface EncounterContainerProps {
  encounter: any; //TODO: change to actual encounter
}

const EncounterContainer: FC<EncounterContainerProps> = ({ encounter }: EncounterContainerProps) => {
  return <>
    <Row className="mb-3">
      <EncounterControls encounter={encounter} />
    </Row>
    <Row className="flex-grow-1 w-100">
      <EncounterSummary encounter={encounter} />
    </Row>
  </>
}
export default EncounterContainer;
