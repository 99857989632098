import { DismissableDialogAlt } from 'components/DismissableDialog';
import { DismissableDialogVariant } from 'components/DismissableDialog/types';
import { useCallback, useMemo } from 'react';
import { useDialogContext } from '../DialogsContext';

const useDialog = () => {
  const { setDialog } = useDialogContext();

  const dialogCreator = useCallback(
    (variant: DismissableDialogVariant) => (label: string, text: string) =>
      setDialog(() => () => DismissableDialogAlt({ label, text, variant })),
    [setDialog]
  );

  const memoizedReturn = useMemo(
    () => ({
      clearDialog: () => {
        setDialog(null);
      },
      confirmationDialog: dialogCreator('confirmation'),
      errorDialog: dialogCreator('error'),
      notificationDialog: dialogCreator('notification'),
      scrollTop: () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    }),
    [dialogCreator, setDialog]
  );

  return memoizedReturn;
};

export default useDialog;
