export enum ThresholdFormikKeys {
  UPPER_RED_ZONE_TOP = 'UPPER_RED_ZONE_TOP',
  UPPER_RED_ZONE_BOTTOM = 'UPPER_RED_ZONE_BOTTOM',
  UPPER_YELLOW_ZONE_BOTTOM = 'UPPER_YELLOW_ZONE_BOTTOM',
  LOWER_YELLOW_ZONE_TOP = 'LOWER_YELLOW_ZONE_TOP',
  LOWER_RED_ZONE_TOP = 'LOWER_RED_ZONE_TOP',
  LOWER_RED_ZONE_BOTTOM = 'LOWER_RED_ZONE_BOTTOM',
  DOUBLE_THRESHOLD = 'DOUBLE_THRESHOLD',
}

export interface ThresholdFormikValues {
  [ThresholdFormikKeys.UPPER_RED_ZONE_TOP]: number;
  [ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM]: number;
  [ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM]: number;
  [ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP]: number;
  [ThresholdFormikKeys.LOWER_RED_ZONE_TOP]: number;
  [ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM]: number;
  [ThresholdFormikKeys.DOUBLE_THRESHOLD]: boolean;
}
