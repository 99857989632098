import DragIconRed from 'assets/icons/goals/dragRed.png';
import DragIconYellow from 'assets/icons/goals/dragYellow.png';

const DragRed = new Image(14, 14);
DragRed.src = DragIconRed;
const DragYellow = new Image(14, 14);
DragYellow.src = DragIconYellow;

export const DragImages = {
  RED: DragRed,
  YELLOW: DragYellow,
};

export const Config = {
  ELEMENTS: {
    line: {
      borderColor: '#8FAAC3',
      spanGaps: true,
    },
    point: {
      radius: 6,
      borderWidth: 2,
      borderColor: '#8FAAC3',
      backgroundColor: 'white',
    },
  },
  SCALES: (units = '') => ({
    x: {
      grid: { display: false },
      ticks: {
        color: '#8FAAC3',
      },
    },
    y: {
      grid: { display: false },
      grace: 1,
      ticks: {
        color: '#8FAAC3',
        callback: (value: string) => `${value}${units}`,
      },
    },
  }),
  LAYOUT: {
    padding: {
      left: 0,
    },
  },
  PLUGINS: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};
