import {
  ActionEventTypeLabel,
  mapActionEventTypeToLabel,
  mapLabelToActionEventType,
} from 'components/RPM/utils/eventTypeMapper';
import {
  ActionTypeLabel,
  mapActionTypeToLabel,
  mapLabelToActionType,
} from 'components/RPM/utils/typeMapper';
import React, { useEffect, useState } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import DatePicker from '../../../../../../DatePicker';
import Select from '../../../../../../form/Select';
import InputField from '../../../../../../InputField';
import EndDateInput from '../EndDateInput';
import { IModalSection } from '../utils/IModalSection';
import styles from './styles.module.css';
import { EndDateTypeLabel, mapLabelToEndDateType } from 'components/RPM/utils/endDateTypeMapper';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import moment from 'moment';
import RelativeDateSelector from 'components/RelativeDateSelector';
import { getAbsoluteDate, getRelativeDate } from 'util/calendarUtils/transformers';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';
import { MedicationMethodType } from 'types/ApiModels/CarePlan/MedicationMethodType';

const eventOptions = [...Object.values(ActionEventTypeLabel)];
const vitalsOptions = [...Object.values(ActionTypeLabel)];
const medicationOptions = ['Tylenol', 'Ibuprofen', 'Morphine', 'Medication Name'];

const EventType = ({
  setData,
  data,
  questionnaireList,
  modalType,
  relativeMinDate,
}: IModalSection) => {
  const scheduleCallOptions = ['Check-up'];
  const goalTrackingFeatures = ['Questionnaire'];
  const pushFileOptions = ['Upload file', 'Select from patient documents'];

  const [disableStartDate, setDisableStartDate] = useState<boolean>(false);

  useEffect(() => {
    if (
      data.recurrence?.repeats_type === 'start_of_adherence_block' ||
      data.recurrence?.repeats_type === 'before_start_of_adherence_block'
    ) {
      setDisableStartDate(true);
    } else {
      setDisableStartDate(false);
    }
  }, [data.recurrence?.repeats_type]);

  const onEventTypeChange = (e) => {
    const eventType = mapLabelToActionEventType(e.target.value as ActionEventTypeLabel);
    let updatedData: IPatientCarePlanAction = { ...data, event_type: eventType };

    if (eventType === 'goal') {
      updatedData = { ...updatedData, recurrence: null };
    }

    setData(updatedData);
  };

  const onQuestionnaireChange = (questionnaireName: string) => {
    const selectedQuestionnaire = questionnaireList.find(
      (questionnaire) => questionnaire.name === questionnaireName
    );
    setData({ ...data, questionnaire: selectedQuestionnaire.id });
  };

  const onAbsoluteStartDateChange = (date: DatePickerValue) => {
    const newStartDate = moment(convertDatepickToDate(date));
    if (data.event_type === 'goal') {
      const prevStartDate = moment(data.start_date);
      const prevEndDate = moment(data.end_date);
      const dayDiff = newStartDate.diff(prevStartDate, 'days');
      const endDate = prevEndDate.clone().add(dayDiff, 'days');
      setData({ ...data, start_date: newStartDate.toISOString(), end_date: endDate.toISOString() });
    } else {
      setData({ ...data, start_date: newStartDate.toISOString() });
    }
  };

  const onRelativeStartDateChange = (newRelativeTime: IRelativeTime) => {
    const newAbsoluteDate = getAbsoluteDate(newRelativeTime, relativeMinDate);
    setData({
      ...data,
      start_date: newAbsoluteDate.toISOString(),
    });
  };

  return (
    <div className="d-flex flex-column">
      <div className={styles.rowContainer}>
        <div>Event Type</div>
        <div className={styles.select}>
          <Select
            currentValue={data.event_type ? mapActionEventTypeToLabel(data.event_type) : ''}
            placeholder={'Select Event Type'}
            options={eventOptions}
            onChange={onEventTypeChange}
          />
        </div>
      </div>
      {data.event_type === 'vitals_request' && (
        <div className={styles.rowContainer}>
          <div>Type</div>
          <div className={styles.select}>
            <Select
              currentValue={mapActionTypeToLabel(data.type)}
              placeholder="Select Type"
              options={vitalsOptions}
              onChange={(e) =>
                setData({
                  ...data,
                  type: mapLabelToActionType(e.target.value as ActionTypeLabel),
                })
              }
            />
          </div>
        </div>
      )}
      {data.event_type === 'schedule_call' && (
        <div className={styles.rowContainer}>
          <div>Type</div>
          <div className={styles.select}>
            <Select options={scheduleCallOptions} onChange={() => null} />
          </div>
        </div>
      )}
      {data.event_type === 'questionnaire' && (
        <div className={styles.rowContainer}>
          <div>Questionnaire</div>
          <div className={styles.select}>
            <Select
              options={questionnaireList.map((questionnaire) => questionnaire.name)}
              currentValue={
                questionnaireList.find((questionnaire) => questionnaire.id === data.questionnaire)
                  ?.name
              }
              placeholder={'Select questionnaire'}
              onChange={(e) => onQuestionnaireChange(e.target.value)}
            />
          </div>
        </div>
      )}
      {data.event_type === 'goal' && (
        <>
          <div className={styles.rowContainer}>
            <div>Goal name</div>
            <div className={styles.select}>
              <InputField
                placeholder="Enter goal name..."
                onChange={(e) => setData({ ...data, goal_name: e.target.value })}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div>Tracking feature</div>
            <div className={styles.select}>
              <Select
                options={goalTrackingFeatures}
                onChange={(e) => onQuestionnaireChange(e.target.value)}
              />
            </div>
          </div>
        </>
      )}
      {data.event_type === 'push_file' && (
        <>
          <div className={styles.rowContainer}>
            <div>Type</div>
            <div className={styles.select}>
              <Select
                options={pushFileOptions}
                onChange={() => null}
                currentValue={pushFileOptions[0]}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div>File</div>

            {/* TODO: push file type? */}
            {/* {eventTypeData.pushFileType === 'Upload file' && (
              <div className={styles.select}>
                <InputField
                  name="pushFileInput"
                  label="Select new file..."
                  type="file"
                  placeholder="Enter file name..."
                  onChange={(e) => onPushFileChange(e.target.files[0])}
                  value={eventTypeData.pushFile?.name}
                />
              </div>
            )}
            {eventTypeData.pushFileType === 'Select from patient documents' && (
              <div className={styles.select}>
                <Select
                  options={[]}
                  placeholder="Select a file..."
                  onChange={(e) => onPushFileChange(e.target.value)}
                />
              </div>
            )} */}
          </div>
        </>
      )}
      {data.event_type === 'medication' && (
        <div className={styles.rowContainer}>
          <div>Name</div>
          <div className={styles.select}>
            <Select
              placeholder="Select medication..."
              options={medicationOptions}
              onChange={(e) => setData({ ...data, medication_name: e.target.value })}
            />
          </div>
        </div>
      )}
      <div className={styles.rowContainer} style={{ opacity: disableStartDate && '0.3' }}>
        <div style={{ transform: 'translateY' }}>Occurs on</div>
        <div style={{ width: 220 }}>
          {modalType === 'template' ? (
            <RelativeDateSelector
              value={getRelativeDate(data.start_date, relativeMinDate)}
              onChange={onRelativeStartDateChange}
              containerClassName="px-2"
            />
          ) : (
            <DatePicker
              placeHolder="Select start date"
              value={convertDateToDatePickValue(new Date(data.start_date), true)}
              onChange={onAbsoluteStartDateChange}
              disabled={disableStartDate}
              style={{ maxHeight: 19.5 }}
            />
          )}
        </div>
      </div>
      {data.event_type === 'goal' && (
        <EndDateInput
          startDate={data?.start_date}
          endDate={data?.end_date}
          endDateType={data?.end_date_type}
          endDateDurationDays={data?.end_date_duration}
          onEndDateTypeChange={(value: EndDateTypeLabel) =>
            setData({ ...data, end_date_type: mapLabelToEndDateType(value) })
          }
          onDurationDaysChange={(days: number) =>
            days > 0 && setData({ ...data, end_date_duration: days })
          }
          onFixedDateChange={(value: DatePickerValue) =>
            setData({ ...data, end_date: convertDatepickToDate(value).toISOString() })
          }
          modalType={modalType}
        />
      )}
      {data.event_type === 'medication' && (
        <>
          <div className={styles.rowContainer}>
            <div>Quantity</div>
            <div className={styles.select}>
              <InputField
                placeholder="Enter quantity..."
                onChange={(e) => setData({ ...data, medication_name: e.target.value })}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div>Method</div>
            <div className={styles.select}>
              <Select
                options={['orally', 'drops'] as MedicationMethodType[]}
                onChange={(e) =>
                  setData({ ...data, medication_method: e.target.value as MedicationMethodType })
                }
                style={{ textTransform: 'capitalize' }}
                currentValue={data.medication_method}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default EventType;
