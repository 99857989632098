import React, { useEffect, useRef, useState } from 'react';
import CalendarActions from './components/CalendarActions';
import Calendar from './components/Calendar';
import styles from './styles.module.css';
import BackButton from 'components/BackButton';
import { getCarePlanTemplate } from 'services/templatesService';
import { DragDropContext, DragUpdate } from 'react-beautiful-dnd';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import {
  resetState,
  setCarePlanModalEventType,
  setCarePlanState,
  setHasUnsavedChanges,
  setPatientActions,
  setPreviousPatientActions,
  setSelectedAction,
  setTimelineActions,
  setUserIsStaff,
} from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { createActivity, transferActivity, getDraggedEventType } from 'util/dragUtils';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { transformToPatientAction } from 'util/calendarUtils/transformers/transformToPatientAction';
import { addPropToAction, templateCarePlanStartDate } from './util';
import ConfirmCancelModal from '../confirmCancelModal';
import TabRouteMap from 'screens/Templates/util/TabRouteMap';
import TabEnum from 'screens/Templates/util/TabEnum';
import { useHistory } from 'react-router-dom';
import DisabledCalendarModal from './components/DisabledCalendarModal';
import NotAnAdminModal from './components/NotAnAdminModal';
import { getCurrentUserInfo } from 'services/userService';

interface ICarePlanDetails {
  id: number;
  create: boolean;
}
const CarePlanDetails = ({ id, create = false }: ICarePlanDetails) => {
  const history = useHistory();
  const {
    dispatch,
    templateState: {
      templateCarePlan: {
        carePlanState,
        timelineActions,
        patientActions,
        hasUnsavedChanges,
        userIsStaff,
      },
    },
  } = useTemplateContext();

  const [showDisabledCalendarModal, setShowDisabledCalendarModal] = useState<boolean>(false);
  const [showNotAnAdminModal, setShowNotAnAdminModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getCurrentUserInfo();
      dispatch(setUserIsStaff(userInfo.is_staff));
    };
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (create) {
      dispatch(resetState());
      return;
    }

    const fetchTemplate = async () => {
      const data = await getCarePlanTemplate(id);
      dispatch(setCarePlanState(data));
    };
    if (id) fetchTemplate();
  }, [dispatch, id]);

  useEffect(() => {
    if (!carePlanState?.actions) return;
    const updatedPatientActions = [];
    carePlanState.actions.forEach((action) =>
      updatedPatientActions.push(transformToPatientAction(action, templateCarePlanStartDate))
    );
    dispatch(setPatientActions(updatedPatientActions));
  }, [carePlanState.actions]);

  const onCancel = () => {
    dispatch(setHasUnsavedChanges(false));
    history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}`);
  };

  const onDragEnd = (dragUpdate: DragUpdate) => {
    if (create) {
      setShowDisabledCalendarModal(true);
      return;
    }

    if (carePlanState.creator_is_admin && !userIsStaff) {
      setShowNotAnAdminModal(true);
      return;
    }

    if (dragUpdate.destination.droppableId === 'calendar-control') {
      // item was dropped on calendar controls
      return;
    }

    if (dragUpdate.destination.droppableId.includes('actionsCard')) {
      // item was dropped on calendar actions card
      return;
    }

    // set previous state if user hits cancel on modal
    dispatch(setPreviousPatientActions());

    const modalEventType = getDraggedEventType(dragUpdate.draggableId, patientActions);
    dispatch(setCarePlanModalEventType(modalEventType));

    if (dragUpdate.draggableId.startsWith('actionButton')) {
      // action button drag
      const newAction = createActivity(modalEventType, dragUpdate.destination.droppableId);
      dispatch(setSelectedAction(newAction as IPatientTimelineAction));
      dispatch(setPatientActions([...patientActions, newAction]));
    } else if (dragUpdate.draggableId.startsWith('actionsCardItem')) {
      // Actions card item drag
      let newAction = createActivity(modalEventType, dragUpdate.destination.droppableId);
      newAction = addPropToAction(newAction, modalEventType, dragUpdate);
      dispatch(setSelectedAction(newAction as IPatientTimelineAction));
      dispatch(setPatientActions([...patientActions, newAction]));
    } else {
      // activity drag
      const actionId = Number(dragUpdate.draggableId);
      const updatedTimelineActions = [...timelineActions];
      const selectedActionIndex = updatedTimelineActions.findIndex(
        (a: IPatientTimelineAction) => a.id === actionId
      );

      const transferredAction = transferActivity(
        updatedTimelineActions[selectedActionIndex],
        dragUpdate.source.droppableId,
        dragUpdate.destination.droppableId
      );
      updatedTimelineActions[selectedActionIndex] = transferredAction;
      dispatch(setTimelineActions(updatedTimelineActions));
      dispatch(setSelectedAction(transferredAction));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <BackButton
          label="Back to all rpm templates"
          color="#1890FF"
          className={styles.backContainer}
          onBack={() =>
            hasUnsavedChanges
              ? setShowCancelModal(true)
              : history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}`)
          }
        />
        <div className="px-4">
          <Calendar />
          <CalendarActions />
        </div>
        <ConfirmCancelModal
          show={showCancelModal}
          onCancel={() => setShowCancelModal(false)}
          onDiscard={onCancel}
        />
        <DisabledCalendarModal
          show={showDisabledCalendarModal}
          onConfirm={() => setShowDisabledCalendarModal(false)}
        />
        <NotAnAdminModal
          show={showNotAnAdminModal}
          onConfirm={() => setShowNotAnAdminModal(false)}
        />
      </div>
    </DragDropContext>
  );
};

export default CarePlanDetails;
