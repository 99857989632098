import styles from './styles.module.css';

const CardWithCounter = ({ label, count }) => {
  return (
    <div className="d-flex justify-content-between bg-light-gray p-3">
      <div>{label}</div>
      <div className={styles['counter']}>{count}</div>
    </div>
  );
};
export default CardWithCounter;
