import React, { ReactNode, useReducer } from 'react';
import { setBreadcrumTitle } from 'util/themeUtils';
import MessagesContext, { MessagesState } from './messages-context';
import messagesReducer from './messages-reducer';

const initialValue: MessagesState = {
  selectedPatient: null,
  breadcrumbs: [
    { title: `${setBreadcrumTitle()} Dashboard`, url: '/dashboard' },
    { title: 'Patient Messages', url: '/messages' },
  ],
  Actions: null,
  newConversationModal: false,
  patientList: null,
  selectedConversationSID: null,
  broadcasts: null,
  selectedBroadcast: [],
  openedBroadcast: null,
  broadcastTemplates: null,
  openedBroadcastTemplate: null,
  getMetadataByParticipantSID: null,
};

const MessagesProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(messagesReducer, initialValue);
  return (
    <MessagesContext.Provider value={{ state, dispatch }}>{children}</MessagesContext.Provider>
  );
};
export default MessagesProvider;
