import { FC, MouseEvent, useState } from 'react';
import { timeAgoWithHours } from 'util/dateUtils';
import styles from './styles.module.css';
import patient from 'assets/images/dashboard/patient.png';
import ListItem from 'components/ListItem';
interface NotificationsPopupProps {
  onMouseLeave: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseEnter: (e: MouseEvent<HTMLDivElement>) => void;
}
const todayMinus2hours = new Date(Date.now() - 1 * 60 * 60 * 1000).toISOString();
const NotificationsPopup: FC<NotificationsPopupProps> = ({
  onMouseEnter,
  onMouseLeave,
}: NotificationsPopupProps) => {
  timeAgoWithHours;
  const [notifications, setNotifications] = useState([
    {
      from: { first_name: 'Doctor', last_name: 'Who', imageUrl: patient },
      created_at: todayMinus2hours,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam imperdiet lectus elit, at sodales metus tempor sit amet.',
      new: true,
    },
    {
      from: { first_name: 'John', last_name: 'Constantine', imageUrl: patient },
      created_at: todayMinus2hours,
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      new: true,
    },
    {
      from: { first_name: 'John', last_name: 'Constantine', imageUrl: patient },
      created_at: todayMinus2hours,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam imperdiet lectus elit, at sodales metus tempor sit amet.',
      new: false,
    },
  ]);

  return (
    <div
      className={`${styles['notifications-popup']} rounded position-absolute d-flex flex-column`}
      tabIndex={0}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <div className={styles['header']}>Notifications</div>
      <div className={styles['notifications-container']}>
        {notifications.map((notification, index) => (
          <div key={index} style={{ borderLeft: notification.new ? '2px solid #42DAB4' : 'none' }}>
            <div className="d-flex justify-content-between">
              <ListItem
                user={notification.from}
                nameStyle={notification.new ? { fontWeight: 600, fontSize: 13 } : { fontSize: 13 }}
              />
              <div style={notification.new ? { fontWeight: 600 } : {}}>
                {timeAgoWithHours(new Date(notification.created_at))}
              </div>
            </div>
            <div className={styles['message']} style={notification.new ? { fontWeight: 600 } : {}}>
              {notification.message}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default NotificationsPopup;
