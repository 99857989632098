import { TextMessage } from 'components/Messages';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { RedAlert } from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';
import styles from './styles.module.css';

interface ComplaintAndNotesProps {
  alerts: RedAlert[];
}

const ComplaintAndNotes = ({ alerts }: ComplaintAndNotesProps) => {
  const [currentAlert, setCurrentAlert] = useState<RedAlert | null>(null);

  useEffect(() => {
    if (alerts.length > 0) {
      setCurrentAlert(alerts[0]);
    }
  }, [alerts]);

  const [activeTab, setActiveTab] = useState('notes');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles['tabs']}>
      <Tabs
        mountOnEnter
        unmountOnExit
        transition={false}
        activeKey={activeTab}
        onSelect={handleTabChange}
      >
        <Tab
          eventKey="notes"
          title="notes"
          tabClassName={`${activeTab === 'notes' ? styles['tab-active'] : styles['tab']}`}
        >
          <div className={styles['tab-content']}>
            <div className={styles['messageContainer']}>
              {currentAlert?.notes.length > 0 ? (
                currentAlert.notes.map((note, index) => (
                  <TextMessage
                    key={index}
                    from={note.user_from}
                    message={{
                      data: note.message,
                      createdAt: note.creation_date,
                    }}
                    messageClassName={styles.message}
                    className="d-flex"
                    timeStampClassName={styles.messageTime}
                    bubbleClassName={styles.bubble}
                    dataClassName={styles.data}
                  />
                ))
              ) : (
                <div className="text-center">No new team notes to display</div>
              )}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};
export default ComplaintAndNotes;
