import { VytalType } from 'types/ApiModels/Patients/Vytal';
import { ChartType } from 'types/VytalChart/chartType';

/**
 *
 * @param goal
 * @returns the Y axis label of the chart associated to that goal
 */
export const getAxisLabel = (goal: VytalType): string => {
  switch (goal) {
    case VytalType.OXYGEN_LEVEL:
      return '%';
    case VytalType.TEMPERATURE:
      return 'F';
    case VytalType.SLEEP:
      return 'h';
    default:
      return '';
  }
};

/**
 *
 * @param goalType
 * @returns true if the chart of that goal has double threshold
 */
export const isDoubleThreshold = (goalType: VytalType): boolean => {
  if (goalType === VytalType.OXYGEN_LEVEL) return false;
  return true;
};

/**
 *
 * @param goal
 * @returns 'Line' or 'Double Line' according to the chart type
 */
export const getChartType = (goal: VytalType): ChartType => {
  if (goal === VytalType.BLOOD_PRESSURE) return 'double-line';
  return 'line';
};

/**
 *
 * @param goal
 * @returns the units of the chart associated to that goal
 */
export const getChartUnits = (goal: VytalType): string => {
  switch (goal) {
    case VytalType.WEIGHT:
      return 'LBS';
    case VytalType.OXYGEN_LEVEL:
      return '%';
    case VytalType.HEART_RATE:
      return 'bpm';
    case VytalType.TEMPERATURE:
      return 'F';
    case VytalType.SLEEP:
      return 'hours';
    default:
      return '';
  }
};

/**
 *
 * @param array values to be wraped
 * @returns the same array with null before and after values
 */
export const nullWrap = <T>(array: T[]): T[] => {
  return [null, ...array, null];
};
