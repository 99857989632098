import TodoAlert, { CompletedAlertInfo } from 'components/TodoAlert';
import { useCallback, useRef, useState } from 'react';
import { IDashboardToDoAlert } from 'types/ApiModels/Dashboard/DashboardToDo';
import { formatVytracDateNoYear } from 'util/dateUtils';
import PatientEventDetails from './PatientEventDetails';
import styles from './styles.module.css';
interface PatientEventProps {
  event: IDashboardToDoAlert;
  patientId: number;
}

const PatientEvent = ({ event, patientId }: PatientEventProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const myRef = useRef<HTMLDivElement>(null);
  const rect = myRef.current?.getBoundingClientRect();

  const toggleDetails = useCallback(() => setShowDetails((prev) => !prev), [setShowDetails]);

  if (!event?.vytal) return null;
  return (
    <div className={styles['container']} onClick={toggleDetails} ref={myRef}>
      <div className={styles.alertContainer}>
        <TodoAlert event={event} />
      </div>
      <div className={styles['lowerAlertInfo']}>
        {event.is_completed ? (
          <CompletedAlertInfo />
        ) : (
          <>
            {formatVytracDateNoYear(new Date(event.created_at))}{' '}
            {event.goal_umbral_type &&
              event.goal_umbral_value &&
              ` • ${event.goal_umbral_type} = ${event.goal_umbral_value}`}
          </>
        )}
      </div>

      {showDetails && (
        <PatientEventDetails
          patientId={patientId}
          outRef={myRef}
          left={
            rect.right > window.innerWidth - 300
              ? rect.left - rect.width - (rect.right - (window.innerWidth - 300))
              : rect.left < 300
              ? rect.left - 200
              : rect.left - rect.width
          }
          event={event}
          onClick={toggleDetails}
        />
      )}
    </div>
  );
};
export default PatientEvent;
