import Button from 'components/Button';
import { ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';
import { setShowActionModal } from './store/DashboardActionCreators';
import { useDashboardContext } from './store/DashboardContext';

export interface PatientActionsProps {
  alertId: number;
  adjustThreshold?: boolean;
  callPatient?: boolean;
  complete?: boolean;
  assign?: boolean;
  contactCareChampion?: boolean;
  alertCareChampion?: boolean;
  confirmAppointment?: boolean;
  callCareChampionBack?: boolean;
  alertTechnicalSupport?: boolean;
  adherenceCall?: boolean;
  requestTelehealthAppointment?: boolean;
  vytalsRequest?: boolean;
  provideQuestionnaire?: boolean;
  sendAutomatedChat?: boolean;
}

const PatientActions = ({
  alertId,
  adjustThreshold,
  callPatient,
  complete,
  assign,
  contactCareChampion,
  alertCareChampion,
  confirmAppointment,
  callCareChampionBack,
  adherenceCall,
  alertTechnicalSupport,
  requestTelehealthAppointment,
  vytalsRequest,
  provideQuestionnaire,
  sendAutomatedChat,
}: PatientActionsProps) => {
  const { dispatch } = useDashboardContext();

  const handleAction = (actionType: ProviderActionType) => {
    dispatch(setShowActionModal(true, actionType, alertId));
  };

  return (
    <div>
      <div className="d-flex w-100 gap flex-wrap">
        {vytalsRequest && (
          <Button label="Vital Request" onClick={() => handleAction('vytal_request')} />
        )}
        {adjustThreshold && <Button label="ADJUST THRESHOLD" onClick={() => null} />}
        {provideQuestionnaire && (
          <Button
            label="PROVIDE QUESTIONNAIRE"
            onClick={() => handleAction('provide_questionnaire')}
          />
        )}
        {callPatient && (
          <Button label="CALL PATIENT" onClick={() => handleAction('call_patient')} />
        )}
        {sendAutomatedChat && (
          <Button label="SEND AUTOMATED CHAT" onClick={() => handleAction('send_automated_chat')} />
        )}
        {contactCareChampion && <Button label="contact care champion" />}
        {alertCareChampion && <Button label="alert care champion" />}
        {confirmAppointment && <Button label="confirm appointment" />}
        {confirmAppointment && <Button label="confirm appointment" />}
        {callCareChampionBack && <Button label="call care champion back" />}
        {alertTechnicalSupport && <Button label="alert technical support" />}
        {adherenceCall && <Button label="adherence call" />}
        {assign && <Button label="ASSIGN" onClick={() => handleAction('assign')} />}
        {requestTelehealthAppointment && (
          <Button
            label="REQUEST TELEHEALTH APPOINTMENT"
            onClick={() => handleAction('request_appointment')}
          />
        )}
        {complete && (
          <Button variant="confirm" label="COMPLETED" onClick={() => handleAction('complete')} />
        )}
      </div>
    </div>
  );
};
export default PatientActions;
