import { useEffect, useMemo, useRef, useState } from 'react';
import ChatGearIcon from 'assets/icons/chat_gear.svg';
import { MockPatient } from 'screens/ProviderDashboard/utils';
import styles from './styles.module.css';
import { MessageInput } from 'components/SendMessageBox/components/MessageInput';
import DashboardOutstandingChats from 'types/ApiModels/Dashboard/DashboardOutstandingChats';
import UserBubble from 'components/UserBubble';
interface OutstandingChatItemProps {
  chat: DashboardOutstandingChats;
}
const OutstandingChatItem = ({ chat }: OutstandingChatItemProps) => {
  const chatRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      event.stopPropagation();
      if (chatRef && chatRef.current && !chatRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
      chatRef.current = null;
    };
  }, [chatRef]);

  //TODO: All of this must be integrated with the chat system
  const [read, setRead] = useState(false);
  const criticalChat = useMemo(() => Math.random() < 0.5, []);
  const hasNewMessages = useMemo(() => (read ? false : Math.random() < 0.5), [read]);
  const numberOfMessages = useMemo(() => Math.floor(Math.random() * 10), []);
  const hasGear = useMemo(() => Math.random() < 0.5, []);

  useEffect(() => {
    if (open && chatRef.current) {
      chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, [open]);
  return (
    <div
      style={{
        cursor: open ? '' : 'pointer',
        backgroundColor: open ? 'white' : '#F8F9FB',
        filter: open ? 'drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6))' : '',
      }}
      className={styles['chat-container']}
      ref={chatRef}
    >
      <div
        onClick={() => {
          setRead(true);
          setOpen((prev) => !prev);
        }}
        className={`dashboard-profile-patient ${styles['chat-item']}`}
      >
        <div className="position-relative">
          <UserBubble
            imgClassNameList={['dashboard-profile-icon']}
            user={{
              first_name: chat.first_name,
              last_name: chat.last_name,
              imageUrl: chat.photo_thumbnail,
            }}
          />
          {(hasNewMessages || criticalChat) && (
            <div
              className={styles['notification-right']}
              data-content={criticalChat && !hasNewMessages ? '' : numberOfMessages}
              style={{ backgroundColor: criticalChat && !hasNewMessages ? '#F5485C' : '#785992' }}
            ></div>
          )}
          {hasGear && (
            <div
              className={styles['notification-left']}
              style={{
                backgroundImage: `url(${ChatGearIcon})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            ></div>
          )}
        </div>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 10,
          }}
        >
          <div className="d-flex justify-content-between">
            <div style={{ lineHeight: '16px' }}>
              {chat.first_name} {chat.last_name}
            </div>
            {open && <div className={styles['open-messenger']}>Open in messenger</div>}
          </div>
          <div
            className="one-lined"
            style={{
              fontSize: 12,
              overflow: 'hide',
              lineHeight: '14px',
              fontWeight: hasNewMessages ? '600' : '400',
              whiteSpace: open ? 'unset' : 'nowrap',
            }}
          >
            10h ago • Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam imperdiet lectus
            elit, at sodales me
          </div>
        </div>
      </div>
      {open && (
        <div className={styles['input-container']}>
          <MessageInput className={styles['input']} />
        </div>
      )}
    </div>
  );
};
export default OutstandingChatItem;
