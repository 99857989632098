import { PushType } from 'types/ApiModels/CarePlan/PushType';

export enum PushTypeLabel {
  BEFORE = 'Before Event',
  AFTER = 'After Event',
}

export const mapLabelToPushType: (label: PushTypeLabel) => PushType = (label) => {
  if (label === PushTypeLabel.BEFORE) {
    return 'before_event';
  } else return 'after_event';
};

export const mapPushTypeToLabel: (type: PushType) => PushTypeLabel = (type) => {
  if (type === 'before_event') {
    return PushTypeLabel.BEFORE;
  } else return PushTypeLabel.AFTER;
};
