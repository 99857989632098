import React from 'react';
import Card from 'components/Card';
import Item from './components/Item';
import styles from './style.module.css';
const Content = ({ items }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'copy';
  };
  return (
    <Card
      className={styles.container}
      headers={['Content']}
      bodyClassName={styles.body}
      headerClassName={styles.header}
    >
      {items?.map((item, index) => (
        <Item
          key={index}
          name={item.name}
          onDragStart={(e) => onDragStart(e, item.nodeType)}
          className={item.className}
        />
      ))}
    </Card>
  );
};

export default Content;
