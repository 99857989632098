import BreadcrumbsWithActions from 'components/BreadcrumbsWithActions';
import { useDialogContext } from 'components/VyTracLayout/store';
import { useAnalyticsContext } from '../store/AnalyticsContext';

const NavigationHeader = () => {
  const { state: {analyticsNavigation: {breadcrumbPathList}}} = useAnalyticsContext();
  const { dialog: DialogComponent } = useDialogContext();

  return (
    <>
      <BreadcrumbsWithActions breadcrumbs={breadcrumbPathList}>
      </BreadcrumbsWithActions>
      {DialogComponent ? <DialogComponent /> : null}
    </>
  );
};

export default NavigationHeader;
