import Breadcrumbs from 'components/Breadcrumbs';
import VyTracLayout from 'components/VyTracLayout';
import { setBreadcrumTitle } from 'util/themeUtils';

const Support = () => {
  const breadcrumbs = [
    {
      title: `${setBreadcrumTitle()} Dashboard`,
      url: '/dashboard',
    },
    {
      title: 'Support',
      url: '/support',
    },
  ];

  //TODO FIX VALIDATION
  return (
    <VyTracLayout selectedRoute="support">
      <div className="d-flex flex-grow-1">
        <Breadcrumbs items={breadcrumbs} />
      </div>
    </VyTracLayout>
  );
};

export default Support;
