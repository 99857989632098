import AdminTabEnum from './AnalyticsTabEnum';

const analyticsRoute = '/analytics';

const TabRouteMap = {
  [AdminTabEnum.ELIGIBILITY_AND_ALERTS]: analyticsRoute,
  [AdminTabEnum.PATIENT_STATUS]: `${analyticsRoute}/patientStatus`,
  [AdminTabEnum.COMPLIANCE_AND_SATISFACTION]: `${analyticsRoute}/complianceAndSatisfaction`,
};

export default TabRouteMap;
