import React, { useEffect, useState } from 'react';
import Select from '../../../../../../../../components/form/Select';
import { getScheduleList } from '../../../../../../../../services/scheduleService';
import ActiveUntil from './components/ActiveUntil';
import VacationMode from './components/VacationMode';
import styles from './styles.module.css';

const ActiveSchedule = ({ activeSchedule, setActiveSchedule, refetch }) => {
  //States ActiveUntil
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const fetchSchedules = async () => {
      const data = await getScheduleList();
      setSchedules(data);
      if (!activeSchedule || !activeSchedule.name) {
        setActiveSchedule(data[0]);
      }
    };
    fetchSchedules();
    return () => setSchedules([]);
  }, [refetch]);

  return (
    <div>
      <div className={`${styles.activeScheduleContainer} mb-2`}>
        <div className={styles.container}>
          <div className={styles.title}>Active schedule</div>
        </div>
        <hr className={styles.horizontalLine} />
        <div className={styles.content}>
          <Select
            options={schedules.map((schedule) => schedule.name)}
            selectClassName={styles.select}
            currentValue={activeSchedule && activeSchedule.name}
            onChange={(e) =>
              setActiveSchedule(schedules.find((schedule) => schedule.name === e.target.value))
            }
          />
        </div>
        <hr className={styles.horizontalLine} />
      </div>
      <div>
        <ActiveUntil schedules={schedules.map((schedule) => schedule.name)} />
        <VacationMode />
      </div>
    </div>
  );
};

export default ActiveSchedule;
