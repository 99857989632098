import * as Yup from 'yup';
import { ThresholdFormikKeys } from './thresholdFormikUtils';

const thresholdMessage = 'Top threshold must be greater than bottom threshold';

const goalDetailAlertsValidationSchema = Yup.object().shape({
  [ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM]: Yup.number()
    .required('Red threshold is required')
    .moreThan(0, 'Threshold must be greater than 0')
    .lessThan(Yup.ref(ThresholdFormikKeys.LOWER_RED_ZONE_TOP), thresholdMessage),
  [ThresholdFormikKeys.LOWER_RED_ZONE_TOP]: Yup.number()
    .required('Red threshold is required')
    .moreThan(Yup.ref(ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM), thresholdMessage)
    .lessThan(Yup.ref(ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP), thresholdMessage),
  [ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP]: Yup.number()
    .required('Yellow threshold is required')
    .moreThan(Yup.ref(ThresholdFormikKeys.LOWER_RED_ZONE_TOP), thresholdMessage)
    .lessThan(Yup.ref(ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM), thresholdMessage),
  [ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM]: Yup.number().when(
    ThresholdFormikKeys.DOUBLE_THRESHOLD,
    {
      is: (doubleThreshold: boolean) => doubleThreshold === true,
      then: Yup.number()
        .required('Yellow threshold is required')
        .moreThan(Yup.ref(ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP), thresholdMessage)
        .lessThan(Yup.ref(ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM), thresholdMessage),
    }
  ),
  [ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM]: Yup.number().when(
    ThresholdFormikKeys.DOUBLE_THRESHOLD,
    {
      is: (doubleThreshold: boolean) => doubleThreshold === true,
      then: Yup.number()
        .required('Red threshold is required')
        .moreThan(Yup.ref(ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM), thresholdMessage)
        .lessThan(Yup.ref(ThresholdFormikKeys.UPPER_RED_ZONE_TOP), thresholdMessage),
    }
  ),
  [ThresholdFormikKeys.UPPER_RED_ZONE_TOP]: Yup.number().when(
    ThresholdFormikKeys.DOUBLE_THRESHOLD,
    {
      is: (doubleThreshold: boolean) => doubleThreshold === true,
      then: Yup.number()
        .required('Red threshold is required')
        .moreThan(Yup.ref(ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM), thresholdMessage),
    }
  ),
  [ThresholdFormikKeys.DOUBLE_THRESHOLD]: Yup.boolean(),
});
export default goalDetailAlertsValidationSchema;
