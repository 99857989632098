import Button from 'components/Button';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  createCategory,
  createResource,
  deleteResource,
  getResourceCategoryList,
  updateResource,
} from 'services/resourcesService';
import { IResourceCategory, IResourceResult } from 'types/Resources/Resources';
import { emptyResource } from '../utils/EmptyResource';
import FirstColumn from './component/FirstColumn';
import Header from './component/Header';
import LastColumn from './component/LastColumn';
import ResourcesModal from './component/ResourcesModal';
import styles from './styles.module.css';

const Content = () => {
  const [newResource, setNewResource] = useState<IResourceResult>(emptyResource);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [refetch, setRefetch] = useState(false);
  const [categories, setCategories] = useState<IResourceCategory[]>(null);

  const setLocationList = () => {
    const options = ['Video', 'Link', 'Article'].map((val) => ({ label: val, value: val }));
    return options;
  };

  const setResourceTypeList = () => {
    const options = ['Internal', 'External'].map((val) => ({ label: val, value: val }));
    return options;
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const data = await getResourceCategoryList();
      setCategories(data);
    };
    fetchCategories();
    return () => setCategories(null);
  }, [refetch]);

  const handleCreateCategory = async (newCategory: IResourceCategory) => {
    await createCategory(newCategory);
    setRefetch(!refetch);
  };

  const saveResource = async (newResource) => {
    try {
      if (newResource.id) {
        await updateResource(newResource);
      } else {
        await createResource(newResource);
      }
      setRefetch(!refetch);
      setShowModal(false);
      setNewResource(emptyResource);
    } catch (e) {
      throw e;
    }
  };

  const onDelete = async (id: string) => {
    try {
      await deleteResource(id);
      setRefetch(!refetch);
    } catch (e) {
      throw e;
    }
  };

  const onEdit = (resource: IResourceResult) => {
    setNewResource(resource);
    setShowModal(true);
  };

  const onHandleClose = () => {
    setNewResource(emptyResource);
    setShowModal(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.resourcesActions}>
        <Button
          label="Add a Resource"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>
      <div className="h-100 d-flex">
        <Row>
          <Header></Header>
        </Row>
      </div>
      <div>
        <Row>
          <Col className="" lg={3}>
            <FirstColumn
              categories={categories}
              onCreateCategory={(category) => handleCreateCategory(category)}
            />
          </Col>
          <Col className="px-3" lg={9}>
            <LastColumn
              refetch={refetch}
              handleDelete={(id) => {
                onDelete(id);
              }}
              handleEdit={(resource) => {
                onEdit(resource);
              }}
            />
          </Col>
        </Row>
      </div>
      <div>
        <ResourcesModal
          newResource={newResource}
          setNewResource={setNewResource}
          categories={categories}
          locationList={setLocationList()}
          resourceTypeList={setResourceTypeList()}
          handleClose={() => {
            onHandleClose();
          }}
          handleConfirm={(newCategory: IResourceCategory) => {
            saveResource(newCategory);
          }}
          showModal={showModal}
        />
      </div>
    </div>
  );
};

export default Content;
