import { mapActionTypeToLabel } from 'components/RPM/utils/typeMapper';
import { Appointment } from 'types/ApiModels/Appointments/Appointment';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';

export const getActionLabel = (item: IPatientCarePlanAction): string => {
  switch (item.event_type) {
    case 'vitals_request': {
      return mapActionTypeToLabel(item.type);
    }

    case 'goal': {
      return item.goal_name;
    }

    case 'medication': {
      return 'Medication';
    }

    case 'questionnaire': {
      // TODO: display questionnaire name
      return 'Questionnaire';
    }

    case 'schedule_call': {
      return 'Shedule Call';
    }

    default: {
      return 'Missing type';
    }
  }
};

export const getAppointmentLabel = (item: Appointment): string => {
  return 'Missing Appointment label';
};
