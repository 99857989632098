import FiltersButton from 'components/FiltersButton';
import { SearchBarAlt } from 'components/SearchBar';
import { ChangeEventHandler, FC } from 'react';

interface SearchFilterHeaderProps {
  handleSearch: ChangeEventHandler<HTMLInputElement>;
  search: string;
  onFiltersClick: () => void;
  title: string;
  searchPlaceholder?: string;
}

const SearchFilterHeader: FC<SearchFilterHeaderProps> = ({
  title,
  handleSearch,
  onFiltersClick,
  search,
  searchPlaceholder,
}: SearchFilterHeaderProps) => {
  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div>{title}</div>
      <div className="d-flex gap">
        <SearchBarAlt onChange={handleSearch} value={search} placeholder={searchPlaceholder} />
        <FiltersButton onFiltersClick={onFiltersClick} />
      </div>
    </div>
  );
};
export default SearchFilterHeader;
