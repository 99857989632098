import Button from 'components/Button';
import ControlButton from 'components/ControlButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  setActions,
  setSelectedChat,
  switchLastBreadcrumb,
  toggleNewConversationModal,
} from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import Chat from './chat';
import NewDiscussionModal from './new-discussion-modal';
import PatientData from './patient-data';
import PatientEncounter from './patient-encounter';
import styles from './styles.module.css';

const NewConversationAction = () => {
  const { dispatch } = useMessagesContext();

  const handleClick = useCallback(() => dispatch(toggleNewConversationModal()), [dispatch]);

  return <Button label="start new discussion" onClick={handleClick} />;
};

const PatientMessages = () => {
  const chatControlsOptions = useMemo(
    () => [
      {
        title: 'Patient information',
        key: 'patient-information',
      },
      {
        title: 'Encounter controls',
        key: 'encounter-controls',
      },
    ],
    []
  );

  const [selectedChatOptions, setSelectedChatOptions] = useState('patient-information');

  const {
    dispatch,
    state: { selectedPatient, getMetadataByParticipantSID },
  } = useMessagesContext();

  const selected = useCallback((key: string) => selectedChatOptions === key, [selectedChatOptions]);

  useEffect(() => {
    dispatch(switchLastBreadcrumb({ title: 'Patient message center', url: '/messages' }));
    dispatch(setActions(NewConversationAction));
  }, [dispatch]);

  return (
    <>
      <NewDiscussionModal
        setSelectedChat={(sid: string) => {
          dispatch(setSelectedChat(sid));
        }}
      />
      <Col md="6" className="pb-3">
        <Chat
          getMetadataByParticipantSidExternal={getMetadataByParticipantSID}
          containerClass={styles['message-container']}
        />
      </Col>
      <Col md="3">
        {selectedPatient && (
          <Row className="mb-3 gap">
            {chatControlsOptions.map(({ title, key }) => (
              <ControlButton
                key={key}
                selected={selected(key)}
                onClick={() => setSelectedChatOptions(key)}
              >
                {title}
              </ControlButton>
            ))}
          </Row>
        )}
        {selectedChatOptions === 'patient-information' ? <PatientData /> : <PatientEncounter />}
      </Col>
    </>
  );
};
export default PatientMessages;
