import { PaginationAction, PaginationActionType } from './types';

export const goToFirstPage = (): PaginationAction => ({ type: PaginationActionType.FIRST_PAGE });

export const goToPreviousPage = (): PaginationAction => ({
  type: PaginationActionType.PREVIOUS_PAGE,
});

export const goToNextPage = (): PaginationAction => ({ type: PaginationActionType.NEXT_PAGE });

export const goToLastPage = (): PaginationAction => ({ type: PaginationActionType.LAST_PAGE });

export const setPaginationFilter = (sortKey: string): PaginationAction => ({
  type: PaginationActionType.SET_FILTER,
  payload: {
    sortKey,
  },
});

export const setPaginationMaxPage = (maxPage: number): PaginationAction => ({
  type: PaginationActionType.SET_MAX_PAGE,
  payload: { maxPage },
});

export const setPaginationMaxCount = (maxCount: number): PaginationAction => ({
  type: PaginationActionType.SET_MAX_COUNT,
  payload: { maxCount },
});

export const setIsLoading = (isLoading: boolean): PaginationAction => ({
  type: PaginationActionType.SET_IS_LOADING,
  payload: { isLoading },
});
