import {
  SET_ACTIVE_SCHEDULE_PROPS,
  SET_ACTIVE_UNTIL,
  SET_ACTIVE_UNTIL_DATE,
  SET_END_DATE_VACATION,
  SET_OUT_OF_OFFICE,
  SET_OUT_OF_OFFICE_DATE,
  SET_OUT_OF_OFFICE_MESSAGE,
  SET_START_DATE_VACATION,
  SET_VACATION_MESSAGE,
  SET_VACATION_MODE,
} from '../actions/actionTypes';

export interface IActiveScheduleState {
  scheduleId?: string,
  name?: string,
  outOfOffice: boolean,
  outOfOfficeDate?: Date,
  outOfOfficeMessage: string,
  activeUntil: boolean,
  activeUntilDate?: Date
  vacationMode: boolean,
  startDateVacation?: Date,
  endDateVacation?: Date,
  vacationMessage?: string
}

export const activeScheduleInitialState: IActiveScheduleState = {
  outOfOffice: false,
  outOfOfficeDate: new Date(),
  outOfOfficeMessage: "",
  activeUntil: false,
  activeUntilDate: null,
  vacationMode: false,
  startDateVacation: null,
  endDateVacation: null,
  vacationMessage: ""
};

const activeScheduleReducer = (state = activeScheduleInitialState, action: any) => {
  switch (action.type) {
    case SET_OUT_OF_OFFICE: {
      const stateCopy = { ...state };
      stateCopy.outOfOffice = action.payload.outOfOffice;
      return stateCopy;
    }
    case SET_OUT_OF_OFFICE_DATE: {
      const stateCopy = { ...state };
      stateCopy.outOfOfficeDate = action.payload.outOfOfficeDate;
      return stateCopy;
    }
    case SET_OUT_OF_OFFICE_MESSAGE: {
      const stateCopy = { ...state };
      stateCopy.outOfOfficeMessage = action.payload.outOfOfficeMessage;
      return stateCopy;
    }
    case SET_ACTIVE_UNTIL: {
      const stateCopy = { ...state };
      stateCopy.activeUntil = action.payload.activeUntil;
      return stateCopy;
    }
    case SET_ACTIVE_UNTIL_DATE: {
      const stateCopy = { ...state };
      stateCopy.activeUntilDate = action.payload.activeUntilDate;
      return stateCopy;
    }
    case SET_VACATION_MODE: {
      const stateCopy = { ...state };
      stateCopy.vacationMode = action.payload.vacationMode;
      return stateCopy;
    }
    case SET_START_DATE_VACATION: {
      const stateCopy = { ...state };
      stateCopy.startDateVacation = action.payload.startDateVacation;
      return stateCopy;
    }
    case SET_END_DATE_VACATION: {
      const stateCopy = { ...state };
      stateCopy.endDateVacation = action.payload.endDateVacation;
      return stateCopy;
    }
    case SET_VACATION_MESSAGE: {
      const stateCopy = { ...state };
      stateCopy.vacationMessage = action.payload.vacationMessage;
      return stateCopy;
    }
    case SET_ACTIVE_SCHEDULE_PROPS: {
      return action.payload;
      ;
    }
    
    default:
      return state;
  }
};

export default activeScheduleReducer;
