import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const SleepIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.94886 0C5.91706 0.000106587 5.88532 0.00298952 5.85402 0.00861551C4.12874 0.31367 2.57803 1.24208 1.50047 2.61509C0.422903 3.9881 -0.105335 5.70864 0.0174562 7.44544C0.140248 9.18225 0.905388 10.8125 2.16556 12.0224C3.42573 13.2322 5.09184 13.9361 6.84311 13.9986C6.93199 14.0018 7.02087 13.9986 7.10867 13.9986C8.24622 13.9993 9.36722 13.7279 10.3769 13.2072C11.3865 12.6866 12.2552 11.932 12.9093 11.0074C12.9623 10.9277 12.9933 10.8355 12.999 10.7402C13.0048 10.6448 12.9852 10.5497 12.9421 10.4642C12.8991 10.3788 12.8342 10.3061 12.754 10.2536C12.6737 10.201 12.5809 10.1703 12.485 10.1647C11.4023 10.0703 10.3559 9.72976 9.42676 9.1695C8.49762 8.60923 7.7106 7.8442 7.1265 6.93353C6.54241 6.02286 6.1769 4.99095 6.05822 3.91755C5.93954 2.84415 6.07087 1.75802 6.44205 0.743087C6.47363 0.661848 6.48521 0.574289 6.47583 0.487703C6.46645 0.401117 6.43639 0.318011 6.38813 0.245309C6.33988 0.172607 6.27484 0.112414 6.19843 0.0697432C6.12202 0.0270728 6.03646 0.00316107 5.94886 0Z"
        fill={color}
      />
    </svg>
  );
};

export default SleepIcon;
