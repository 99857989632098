import React from 'react';
import Body from './components/Body';
import Header from './components/Header';
import styles from './styles.module.css';
export default function Table({onIdClick}) {
  return (
    <div className={`dashboard-detail ${styles.main}`}>
      <Header encounterCount={4} />
      <div>
        <Body onIdClick={onIdClick} />
      </div>
    </div>
  );
}
