import { PaginatedResponse } from 'types/ApiModels/General';
import { Provider } from 'types/ApiModels/Providers/Provider';

export enum ProviderActionTypes {
  TOGGLE_PROVIDER_ACTIVE = 'TOGGLE_PROVIDER_ACTIVE',
  SET_PROVIDERS_RESET_FORM = 'SET_PROVIDERS_RESET_FORM',
  SET_PROVIDERS_SUBMIT_FORM = 'SET_PROVIDERS_SUBMIT_FORM',
  RESET_UPDATED_PROVIDERS = 'RESET_UPDATED_PROVIDERS',
  RESET_DELETED_PROVIDERS = 'RESET_DELETED_PROVIDERS',
  SET_PROVIDERS_BOOK = 'SET_PROVIDERS_BOOK',
  APPEND_PROVIDERS_BOOK_PAGE = 'APPEND_PROVIDERS_BOOK_PAGE',
  REMOVE_PROVIDER_FROM_BOOK = 'REMOVE_PROVIDER_FROM_BOOK',
  TOGGLE_SHOW_INVITE_PROVIDER = 'TOGGLE_SHOW_INVITE_PROVIDER',
  SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER',
  SET_SUBMIT_SINGLE_PROVIDER_FORM = 'SET_SUBMIT_SINGLE_PROVIDER_FORM',
  SET_RESET_SINGLE_PROVIDER_FORM = 'SET_RESET_SINGLE_PROVIDER_FORM',
  SET_SINGLE_PROVIDER_FORM_DIRTY = 'SET_SINGLE_PROVIDER_FORM_DIRTY',
  SET_SINGLE_PROVIDER_REMOVE = 'SET_SINGLE_PROVIDER_REMOVE',
  SET_PROVIDERS_TABLE_BOOK = 'SET_PROVIDERS_TABLE_BOOK',
  MATCH_PROVIDERS_TABLE_BOOK_WITH_BOOK = 'MATCH_PROVIDERS_TABLE_BOOK_WITH_BOOK',
  UPDATE_PROVIDER_TABLE_PAGE = 'UPDATE_PROVIDER_TABLE_PAGE',
  UPDATE_PROVIDER_IS_ACTIVE = 'UPDATE_PROVIDER_IS_ACTIVE',
  UPDATE_PROVIDER_DELETE = 'UPDATE_PROVIDER_DELETE',
  UPDATE_CURRENT_BOOK_PAGE = 'UPDATE_CURRENT_BOOK_PAGE',
  SET_PROVIDERS_BOOK_AND_MATCH_TABLE = 'SET_PROVIDERS_BOOK_AND_MATCH_TABLE',
}

export type ProviderAction =
  | {
      type: ProviderActionTypes.SET_PROVIDERS_BOOK;
      payload: { providersBook: Record<number, PaginatedResponse<Provider>> };
    }
  | {
      type: ProviderActionTypes.APPEND_PROVIDERS_BOOK_PAGE;
      payload: { providersPage: PaginatedResponse<Provider> };
    }
  | { type: ProviderActionTypes.SET_PROVIDERS_SUBMIT_FORM; payload: { submitForm: () => void } }
  | { type: ProviderActionTypes.RESET_UPDATED_PROVIDERS }
  | { type: ProviderActionTypes.RESET_DELETED_PROVIDERS }
  | { type: ProviderActionTypes.TOGGLE_PROVIDER_ACTIVE; payload: { providerId: number } }
  | {
      type: ProviderActionTypes.SET_PROVIDERS_RESET_FORM;
      payload: {
        resetForm: () => void;
      };
    }
  | { type: ProviderActionTypes.TOGGLE_SHOW_INVITE_PROVIDER }
  | { type: ProviderActionTypes.SET_SELECTED_PROVIDER; payload: { provider: Provider } }
  | {
      type: ProviderActionTypes.SET_SUBMIT_SINGLE_PROVIDER_FORM;
      payload: { submitSingleProvider: () => void };
    }
  | {
      type: ProviderActionTypes.SET_RESET_SINGLE_PROVIDER_FORM;
      payload: { resetSingleProviderForm: () => void };
    }
  | {
      type: ProviderActionTypes.SET_SINGLE_PROVIDER_FORM_DIRTY;
      payload: { singleProviderFormDirty: boolean };
    }
  | {
      type: ProviderActionTypes.SET_SINGLE_PROVIDER_REMOVE;
      payload: { removeSingleProvider: () => void };
    }
  | { type: ProviderActionTypes.REMOVE_PROVIDER_FROM_BOOK; payload: { providerId: number } }
  | {
      type: ProviderActionTypes.SET_PROVIDERS_TABLE_BOOK;
      payload: { tableBook: Record<number, PaginatedResponse<Provider>> };
    }
  | { type: ProviderActionTypes.MATCH_PROVIDERS_TABLE_BOOK_WITH_BOOK }
  | { type: ProviderActionTypes.UPDATE_PROVIDER_TABLE_PAGE; payload: { page: number } }
  | {
      type: ProviderActionTypes.UPDATE_PROVIDER_IS_ACTIVE;
      payload: { providerId: number; page: number };
    }
  | {
      type: ProviderActionTypes.UPDATE_PROVIDER_DELETE;
      payload: { providerId: number; page: number };
    }
  | { type: ProviderActionTypes.UPDATE_CURRENT_BOOK_PAGE; payload: { page: number } }
  | {
      type: ProviderActionTypes.SET_PROVIDERS_BOOK_AND_MATCH_TABLE;
      payload: { providersBook: Record<number, PaginatedResponse<Provider>> };
    };
