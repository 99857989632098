import React from 'react';
import ListItem from '../../../../../../../../../../components/patient/ListItem';
import styles from './styles.module.css';
const Item = ({ data, onClick }) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <ListItem patient={data.patient} className={styles.item} />
    </div>
  );
};

export default Item;
