import { billingInitialBreadcrumbPathList } from '../../../util/billingUtils';
import { routeEnum } from './routeEnum';

export const breadcrumbsPathMap = (patientName, patientId) => ({
  [routeEnum.PATIENT_LIST]: [...billingInitialBreadcrumbPathList],
  [routeEnum.PATIENT_BILLING]: [
    ...billingInitialBreadcrumbPathList,
    { title: patientName, path: `/revenue-management/${patientId}` },
  ],
  [routeEnum.CHARGES_CONFIG]: [
    ...billingInitialBreadcrumbPathList,
    {
      title: 'Charges configuration',
      path: `/revenue-management/charges-configuration`,
    },
  ],
  [routeEnum.CODES_CONFIG]: [
    ...billingInitialBreadcrumbPathList,
    {
      title: 'Codes configuration',
      path: `/revenue-management/codes-configuration`,
    },
  ],
});
