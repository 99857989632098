import { Dispatch, useEffect } from 'react';
//mocks
import { mockBroadcastData } from 'screens/messages/mocks/data';
import { MessagesState, useMessagesContext } from '../messages-context';
import { MessagesAction } from '../messages-action-types';
import { setBroadcasts } from '../messages-action-creators';

const useBroadcasts = (): [
  state: MessagesState,
  // paginatedRequest: PaginatedRequestHook<MockChatData>,
  dispatch: Dispatch<MessagesAction>
] => {
  const { state, dispatch } = useMessagesContext();

  // const paginatedRequest = usePaginatedRequest('patient/', 10, state.chats);

  useEffect(() => {
    dispatch(setBroadcasts(mockBroadcastData));
  }, [dispatch /*paginatedRequest.book*/]);

  return [state, /*paginatedRequest,*/ dispatch];
};

export default useBroadcasts;
