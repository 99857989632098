import tabEnum from './tabEnum';

const tabNameMap = {
  [tabEnum.ALL_CODES]: 'All Codes',
  [tabEnum.READY_TO_SUBMIT]: 'Ready to Submit',
  [tabEnum.SUBMITTED]: 'Submitted',
  [tabEnum.PAID]: 'Paid',
};

export default tabNameMap;
