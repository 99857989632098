import AdherenceGrid from 'components/AdherenceGrid';
import ProfileImage from 'components/ProfileImage';
import Stopwatch from 'components/Stopwatch';
import { useCallback, useMemo, useState } from 'react';
import {
  setSelectedAdherence,
  setTrackingType,
  toggleTimeIsTracking,
} from 'screens/ProviderDashboard/store/DashboardActionCreators';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import useAlertItemTracking from 'screens/ProviderDashboard/store/hooks/useAlertItemTracking';
import DashboardAdherence from 'types/ApiModels/Dashboard/DashboardAdherence';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import { diffStrDateDays } from 'util/dateUtils';
import AdherencePopup from './AdherencePopup';
import styles from './styles.module.css';

interface AdherenceItemProps {
  adherenceItem: DashboardAdherence;
}
const AdherenceItem = ({ adherenceItem }: AdherenceItemProps) => {
  const {
    dispatch,
    state: { timerIsTracking, trackingPatientId, patientIdTimeMap, trackingActivityId },
  } = useDashboardContext();

  const [showPopup, setShowPopup] = useState(false);

  useAlertItemTracking(adherenceItem.id, TrackingType.ADHERENCE, showPopup);

  const completedDays = useMemo(
    () => adherenceItem.adherence.adherence_days.length,
    [adherenceItem]
  );
  const totalDays = useMemo(
    () => diffStrDateDays(new Date().toISOString(), adherenceItem.adherence.care_plan_from) || 0,
    [adherenceItem]
  );

  const handleClosePopUp = useCallback(() => {
    setShowPopup(false);
    dispatch(setSelectedAdherence(null));
  }, [setShowPopup, dispatch]);

  const stopwatchClick = useCallback(() => {
    dispatch(setTrackingType(TrackingType.ADHERENCE));
    dispatch(toggleTimeIsTracking(!timerIsTracking));
  }, [dispatch, timerIsTracking]);

  const handleTogglePopup = useCallback(() => {
    if (showPopup) {
      handleClosePopUp();
    } else {
      setShowPopup(true);
      dispatch(setSelectedAdherence(adherenceItem));
    }
  }, [showPopup, handleClosePopUp, adherenceItem, dispatch]);

  return (
    <div>
      <div
        onClick={handleTogglePopup}
        className={`dashboard-profile-patient ${styles.itemContainer}`}
      >
        <div className="d-flex align-items-center">
          {showPopup ? (
            <Stopwatch
              isTracking={timerIsTracking && adherenceItem.id === trackingPatientId}
              toggleIsTracking={stopwatchClick}
              disabled={!trackingActivityId}
              style={{ marginRight: 18 }}
              size={40}
              buttonSize={15}
              background={
                <ProfileImage
                  src={adherenceItem.photo_thumbnail}
                  sex={adherenceItem.sex}
                  style={{ width: 35, height: 35 }}
                />
              }
              elapsedTimeInSeconds={patientIdTimeMap.get(adherenceItem.id)}
            />
          ) : (
            <ProfileImage
              src={adherenceItem.photo_thumbnail}
              sex={adherenceItem.sex}
              style={{ width: 35, height: 35, marginLeft: 3, marginRight: 20 }}
            />
          )}

          <div>
            {adherenceItem.first_name} {adherenceItem.last_name}
            <br />
            <span
              className="one-lined"
              style={{
                fontSize: 12,
                overflow: 'hide',
                color: completedDays < totalDays / 2 ? '#F5485C' : '',
              }}
            >
              Completed {completedDays} days of {totalDays}
            </span>
          </div>
        </div>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 10,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <AdherenceGrid adherence={adherenceItem.adherence} />
        </div>
      </div>
      {showPopup && <AdherencePopup onClickOutSide={handleClosePopUp} />}
    </div>
  );
};
export default AdherenceItem;
