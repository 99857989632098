import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import { FormikState } from 'formik';

export enum PatientManagementActionTypes {
  SET_SUBMIT_PATIENT_FORM = 'SET_SUBMIT_PATIENT_FORM',
  SET_RESET_PATIENT_FORM = 'SET_RESET_PATIENT_FORM',
  SET_FORM_DIRTY = 'SET_FORM_DIRTY',
  TEST_ACTION = 'TEST_ACTION',
  //--AppendType
}

export type PatientManagementAction =
  | {
      type: PatientManagementActionTypes.SET_SUBMIT_PATIENT_FORM;
      payload: {
        submitForm: () => void;
      };
    }
  | {
      type: PatientManagementActionTypes.SET_RESET_PATIENT_FORM;
      payload: {
        resetForm: (nextState: Partial<FormikState<PatientManagementFormikValues>>) => void;
      };
    }
  | {
      type: PatientManagementActionTypes.SET_FORM_DIRTY;
      payload: {
        dirty: boolean;
      };
    }
  | {
      type: PatientManagementActionTypes.TEST_ACTION;
      payload: {
        test: boolean;
      };
    } /**--AppendAction */;
