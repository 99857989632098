import React, { CSSProperties, ReactNode } from 'react';
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from 'react-circular-input';
import { ReactComponent as PlayButton } from 'assets/icons/play_stopwatch.svg';
import { ReactComponent as StopButton } from 'assets/icons/stop_stopwatch.svg';
import styles from './styles.module.css';
import resolveCompletionRatioWithinRange from './utils/resolveCompletionRatioWitinRange';
import formatElapsedTime from './utils/formatElapsedTime';

interface IStopwatch {
  color?: string;
  style?: CSSProperties;
  size?: number;
  buttonSize?: number;
  background?: ReactNode;
  disabled?: boolean;
  isTracking: boolean;
  toggleIsTracking?: () => void;
  elapsedTimeInSeconds?: number;
  displayTime?: boolean;
}

const Stopwatch = ({
  color = '#1890FF',
  style,
  size = 67,
  buttonSize = 20,
  background,
  disabled = false,
  isTracking,
  toggleIsTracking,
  elapsedTimeInSeconds,
  displayTime = false,
}: IStopwatch) => {
  const buttonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;
    toggleIsTracking();
  };
  return (
    <div className={styles.main} style={{ ...style, width: size, height: size }}>
      <CircularInput
        value={resolveCompletionRatioWithinRange(elapsedTimeInSeconds)}
        style={{ height: size, width: size, zIndex: 50, position: 'absolute' }}
      >
        <CircularTrack strokeWidth={3.35} />
        <CircularProgress strokeWidth={3.35} stroke={color} />
        <CircularThumb r={13} fill={color} stroke="#fff" strokeWidth="5px" />
      </CircularInput>
      {background && (
        <div
          className={styles.backgroundContainer}
          style={{
            height: size,
            width: size,
            top: 0,
            left: 0,
          }}
        >
          {background}
        </div>
      )}

      <div
        className={styles.playContainer}
        style={{
          height: buttonSize,
          width: buttonSize,
          top: size / 2 - buttonSize / 2,
          left: size / 2 - buttonSize / 2,
        }}
      >
        {!isTracking ? (
          <PlayButton
            style={{ opacity: disabled && '0.3' }}
            color={color}
            height={buttonSize}
            width={buttonSize}
            onClick={buttonClick}
          />
        ) : (
          <StopButton
            style={{ opacity: disabled && '0.3' }}
            color={color}
            height={buttonSize}
            width={buttonSize}
            onClick={buttonClick}
          />
        )}
      </div>
      {displayTime && (
        <div
          className={`font-size-small ${styles.counter}`}
          style={{ top: size / 2 + buttonSize / 2 }}
        >
          {formatElapsedTime(elapsedTimeInSeconds)}
        </div>
      )}
    </div>
  );
};

export default Stopwatch;
