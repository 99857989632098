import FiltersButton from 'components/FiltersButton';
import GenericButton from 'components/GenericButton';
import GenericTable from 'components/GenericTable';
import SearchBar from 'components/SearchBar';
import React, { useContext, useEffect, useState } from 'react';
import GenericColumn from 'types/Shared/GenericColumn';
import Context from '../../../../util/Context';
import { breadcrumbsPathMap } from '../../util/breadcrumbsPathMap';
import { routeEnum } from '../../util/routeEnum';
import EditIcon from 'assets/icons/edit.png';
import DeleteIcon from 'assets/icons/delete.png';
import styles from './styles.module.css';
import Tags from 'components/Tags';
import { FormCheck } from 'react-bootstrap';
import Button from 'components/Button';
import CategoriesTable from 'components/CategoriesTable';
import CodesConfigModal from './CodesConfigModal/CodesConfigModal';

const CodesConfig = () => {
  const Actions = () => (
    <div className="d-flex">
      <Button
        className={styles.button}
        onClick={() => {
          setShowModal(true);
        }}
        label="Add new Code"
      />
    </div>
  );

  const { state, setState } = useContext(Context);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      breadcrumbPathList: breadcrumbsPathMap()[routeEnum.CODES_CONFIG],
      actions: Actions,
    }));
  }, []);

  const searchCodes = () => {};
  const onHandleEdit = () => {};
  const handleDelete = () => {};

  const categories = ['RPM', 'CCM', 'RTM'];

  const data = [
    [
      {
        code: 99453,
        activities: ['All'],
        populations: ['All'],
        provider_title: ['All'],
        timeframe: ['Every 30 days'],
        time_required: ['20 minutes'],
        special_rules: ['Patient enroled', 'RPM'],
        active: true,
      },
      {
        code: 99454,
        activities: ['All'],
        populations: ['All'],
        provider_title: ['All', 'NPP'],
        timeframe: ['Every 30 days'],
        time_required: ['20 minutes'],
        special_rules: ['Patient enroled'],
        active: true,
      },
      {
        code: 99455,
        activities: ['All'],
        populations: ['All'],
        provider_title: ['All'],
        timeframe: ['Every 30 days'],
        time_required: ['20 minutes'],
        special_rules: ['Patient enroled', 'RPM', 'Patient age'],
        active: true,
      },
    ],
  ];

  const columns = [
    {
      title: 'Code',
      key: 'code',
      style: {
        width: '25%',
      },
      render: ({ code }) => <div className={styles.codeName}>{code}</div>,
    },
    {
      title: 'Activities',
      key: 'activities',
      style: {
        paddingRight: '4.1vw',
      },
      render: ({ activities }) => <Tags tagList={activities} />,
    },
    {
      title: 'Population',
      key: 'populations',
      style: {
        paddingRight: '4.1vw',
      },
      render: ({ populations }) => <Tags tagList={populations} />,
    },
    {
      title: 'Provider title',
      key: 'provider_title',
      style: {
        paddingRight: '4.1vw',
      },
      render: ({ provider_title }) => <Tags tagList={provider_title} />,
    },
    {
      title: 'Timeframe',
      key: 'timeframe',
      style: {
        paddingRight: '4.1vw',
      },
      render: ({ timeframe }) => <Tags tagList={timeframe} />,
    },
    {
      title: 'Time required',
      key: 'time_required',
      style: {
        paddingRight: '4.1vw',
      },
      render: ({ time_required }) => <Tags tagList={time_required} />,
    },
    {
      title: 'Special rules',
      key: 'special_rules',
      tdClassName: 'w-25',
      render: ({ special_rules }) => <Tags tagList={special_rules} />,
    },
    {
      title: 'Action',
      key: 'action',
      style: {
        paddingRight: '2.1vw',
      },
      render: ({ action, ...rowInfo }) => (
        <div className="d-flex">
          <GenericButton
            icon={EditIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
            onClick={() => onHandleEdit()}
          />
          <GenericButton
            icon={DeleteIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
            onClick={() => handleDelete()}
          />
        </div>
      ),
    },
    {
      title: 'Active',
      key: 'active',
      render: ({ active, ...rowInfo }) => (
        <div className="d-flex align-items-center" onClick={() => {}}>
          <FormCheck type="switch" checked={active} />
        </div>
      ),
    },
  ];

  return (
    <div
      className="dashboard-detail"
      style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05))' }}
    >
      <div className="dashboard-item-header d-flex justify-content-between align-items-center">
        <div>Activity codes</div>
        <div className="d-flex">
          <SearchBar
            placeholder="Quick search"
            className={styles.search}
            onChange={() => {
              searchCodes();
            }}
          />
          <FiltersButton />
        </div>
      </div>
      <CategoriesTable data={data} columns={columns} categories={categories} />
      <CodesConfigModal
        handleChange={(months) => {}}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
      ></CodesConfigModal>
    </div>
  );
};

export default CodesConfig;
