import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import styles from './styles.module.css';

interface PlatformRulesHeaderProps {
  onSearch: (search) => void;
  onFilter: (filter) => void;
}
const PlatformRulesHeader = ({ onSearch, onFilter }: PlatformRulesHeaderProps) => {
  return (
    <div className={styles.main}>
      <span className={styles.title}>Platform rules</span>
      <div className={styles.filtersContainer}>
        <SearchBar onChange={onSearch} />
        <FiltersButton onFiltersClick={onFilter} />
      </div>
    </div>
  );
};

export default PlatformRulesHeader;
