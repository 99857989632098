import React from 'react';
import CategoriesTable from '../../../../../../../../../components/CategoriesTable';

const ReadyToSubmitTable = ({data}) => {
  const categories = ['RPM', 'CCM', 'RTM'];

  const columns = [
    {
      title: 'Code',
      key: 'code',
      render: (row) => <div style={{color: '#1890FF'}}>{row.code}</div>,
      style: {
        width: '25%',
      },
    },
    {
      title: 'Value',
      key: 'value',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Time Spent',
      key: 'timeSpent',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Due Bill Date',
      key: 'dueBillDate',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Last Bill Date',
      key: 'lastBillDate',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Date of Service',
      key: 'dateOfService',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Providers',
      key: 'providers',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Required Time',
      key: 'requiredTime',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Status',
      key: 'status',
      style: {
        paddingRight: '4.1vw',
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row) => (
        <div style={{color: '#1890FF'}}>
          {row.status === 'Eligible' ? 'Submit' : ''}
        </div>
      ),
      style: {
        paddingRight: '0',
      },
    },
  ];

  return (
    <CategoriesTable data={data} columns={columns} categories={categories} />
  );
};

export default ReadyToSubmitTable;
