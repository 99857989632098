import { usePagination } from 'components/Pagination';
import { useContext } from 'react';
import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import Context from '../../util/Context';
import ChargesConfig from './components/ChargesConfig/ChargesConfig';
import CodesConfig from './components/CodesConfig/CodesConfig';
import PatientBilling from './components/PatientBilling';
import PatientListWithFilters from './components/PatientListWithFilters';

const Content = () => {
  const { state } = useContext(Context);
  const { path } = useRouteMatch();
  const { paginationState: paginationState } = usePagination();
  const findPatientById = (id) =>
    state.patientList.find((currentPatient) => currentPatient.id === id);

  return (
    <Switch>
      <Route exact path={path}>
        <PatientListWithFilters />
      </Route>
      <Route exact path={`${path}/charges-configuration`} component={ChargesConfig} />
      <Route exact path={`${path}/codes-configuration`} component={CodesConfig} />
      <Route exact path={`${path}/:patientId`}>
        <PatientBilling findPatientById={findPatientById} />
      </Route>
    </Switch>
  );
};

export default withRouter(Content);
