import EncounterContainer from 'components/EncountersContainer';
import { useMessagesContext } from 'screens/messages/store/messages-context';

const PatientEncounter = () => {
  //change to encounter
  const {
    state: { selectedPatient },
  } = useMessagesContext();

  return (
    <>
      <EncounterContainer encounter={selectedPatient} />
    </>
  );
};
export default PatientEncounter;
