import Button from 'components/Button';
import CardComment from 'components/CardComment';
import SendMessageBox from 'components/SendMessageBox';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import GraphAlert from 'screens/ProviderDashboard/RedAlerts/RedAlertItem/RedAlertPopup/GraphAlert';
import styles from './styles.module.css';

interface IPatientTimelineActivityPopup {
  onClickOutside: () => void;
  activityClickEvent: React.MouseEvent;
  // TODO: add event information
  // TODO: add patient information
}
const PatientTimelineActivityPopup = ({
  onClickOutside,
  activityClickEvent,
}: IPatientTimelineActivityPopup) => {
  const containerRef = useRef<HTMLDivElement>();
  const [containerPosition, setContainerPosition] = useState<CSSProperties>(null);

  useEffect(() => {
    if (containerRef.current === null) return;

    const handleOutsideClick = (event) => {
      if (!containerRef.current.contains(event.target)) onClickOutside();
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [containerRef, onClickOutside]);

  // update popup position based on click event
  useEffect(() => {
    const updateContainerPosition: () => CSSProperties = () => {
      const position = {
        bottom: `calc(100vh - ${activityClickEvent?.clientY}px)`,
      };

      if (
        activityClickEvent.clientX + containerRef.current?.getBoundingClientRect().width >
        window.innerWidth
      ) {
        // prevent popup from being outside view
        return {
          ...position,
          right: `calc(100vw - ${activityClickEvent?.clientX}px)`,
        };
      } else {
        return {
          ...position,
          left: `calc(${activityClickEvent?.clientX}px - 200px)`,
        };
      }
    };
    setContainerPosition(updateContainerPosition());
  }, [activityClickEvent]);

  const handleSendMessage = () => undefined;

  return (
    <div className={styles.popupContainer} ref={containerRef} style={containerPosition}>
      {containerPosition && (
        <>
          {/* <GraphAlert patient={patient} showTopIcons={false} /> */}
          <div className={styles.actionContainer}>
            <Button label="ADJUST THRESHOLD" />
          </div>
          <div className={styles.commentContainer}>
            <CardComment
              cardComment={{
                time: '12:30 am',
                name: 'Thomas Wayne',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum',
              }}
              showActions={true}
            />
            <CardComment
              cardComment={{
                time: '12:30 am',
                name: 'Thomas Wayne',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum',
              }}
            />
          </div>
          <div className={styles.sendMessageContainer}>
            <SendMessageBox handleSendMessage={handleSendMessage} />
          </div>
        </>
      )}
    </div>
  );
};
export default PatientTimelineActivityPopup;
