import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Controls from 'components/VytracSurveyBuilder/components/Controls';
import GraphBuilder from 'components/GraphBuilder';
import VytracSurveyBuilder from 'components/VytracSurveyBuilder';
import {
  createQuestionnaire,
  deleteQuestionnaire,
  getQuestionnaire,
  updateQuestionnaire,
} from 'services/questionnaireService';
import TabEnum from '../../../../../../util/TabEnum';
import TabRouteMap from '../../../../../../util/TabRouteMap';
import AnswerNode from './components/AnswerNode';
import QuestionNode from './components/QuestionNode';
import styles from './styles.module.css';
import ConfirmCancelModal from '../confirmCancelModal';
import ConfirmDeleteModal from '../../../confirmDeleteModal';

const QuestionnaireDetails = ({ id, create = false }) => {
  const history = useHistory();

  const [elements, setElements] = useState([]);
  const [initialValues, setInitialValues] = useState({
    answers: [],
    questions: [],
    edges: [],
  });
  const [questionnaire, setQuestionnaire] = useState({
    id: id,
    name: '',
    usedFor: null,
  });
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [disableCreate, setDisableCreate] = useState<boolean>(false);
  const initialElements = useRef(null);
  const firstRender = useRef(true);

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      if (id) {
        const data = await getQuestionnaire(id);
        setInitialValues(data);
        setQuestionnaire((q) => ({ ...q, name: data.name }));
      }
    };
    fetchQuestionnaire();
    return () => setInitialValues(null);
  }, [id]);

  const saveQuestionnaire = async (data, name) => {
    try {
      if (!id) {
        setDisableCreate(true);
        const questionnaire = await createQuestionnaire(data, name);
        history.replace(`${TabRouteMap[TabEnum.QUESTIONNAIRES]}/${questionnaire.questionnaire.id}`);
        setDisableCreate(false);
      } else {
        setHasUnsavedChanges(false);
        await updateQuestionnaire(id, {
          elements: data,
          name,
        });
        initialElements.current = { elements: data, name };
      }
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (elements && elements.length && firstRender.current) {
      initialElements.current = {
        elements: elements,
        name: questionnaire.name,
      };
      firstRender.current = false;
    } else if (elements && elements.length) {
      setHasUnsavedChanges(() =>
        checkUnsavedChanges(initialElements.current, {
          elements: elements,
          name: questionnaire.name,
        })
      );
    }
  }, [elements, questionnaire]);

  const checkUnsavedChanges = (prevState, actualState) => {
    if (prevState.name !== actualState.name) {
      return true;
    }
    if (prevState.elements.length !== actualState.elements.length) {
      return true;
    }
    for (let i = 0; i < prevState.elements.length; i++) {
      if (!isEqual(prevState.elements[i], actualState.elements[i])) return true;
    }
    return false;
  };

  const onConfirmCancel = () => {
    setElements(initialElements.current.elements);
    setQuestionnaire((q) => ({ ...q, name: initialElements.current.name }));
    setShowConfirmCancelModal(false);
  };

  const onConfirmDelete = async () => {
    try {
      await deleteQuestionnaire(id);
      history.replace(`${TabRouteMap[TabEnum.QUESTIONNAIRES]}`);
    } catch (e) {
      console.error(e);
    }
  };

  const QuestionnaireBuilderActions = () => {
    return (
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.button}
          label="cancel"
          variant="cancel"
          onClick={() => {
            setShowConfirmCancelModal(true);
          }}
          style={{
            opacity: hasUnsavedChanges ? 1 : 0.3,
            cursor: hasUnsavedChanges ? 'pointer' : 'default',
          }}
          disabled={!hasUnsavedChanges}
        />
        <Button
          className={styles.button}
          label={id ? 'save changes' : 'create template'}
          variant="confirm"
          onClick={() => saveQuestionnaire(elements, questionnaire.name)}
          style={{
            opacity: hasUnsavedChanges ? 1 : 0.3,
            cursor: hasUnsavedChanges ? 'pointer' : 'default',
          }}
          disabled={!hasUnsavedChanges || disableCreate}
        />
        {id && (
          <Button
            className={styles.button}
            label="delete template"
            variant="delete"
            onClick={() => {
              setShowConfirmDeleteModal(true);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="h-100 w-100">
      <BackButton
        label="Back to all questionnaires"
        color="#1890FF"
        className={styles.backContainer}
        onBack={() => history.replace(`${TabRouteMap[TabEnum.QUESTIONNAIRES]}`)}
      />

      <div className={styles.controls}>
        <Controls
          name={questionnaire.name}
          usedFor={questionnaire.usedFor}
          setName={(newVal: string) => setQuestionnaire({ ...questionnaire, name: newVal })}
          items={[
            {
              name: 'Question',
              nodeType: 'question',
            },
          ]}
          header="Questionnaire Options"
          label="Questionnaire name"
        />
        <GraphBuilder
          actions={() => <QuestionnaireBuilderActions />}
          title="Questionnaire Builder"
          elements={elements}
          setElements={setElements}
          containerClassName={styles.graphContainer}
        >
          <VytracSurveyBuilder
            elements={elements}
            setElements={setElements}
            questionNodeType={QuestionNode}
            answerNodeType={AnswerNode}
            initialValues={initialValues}
          />
        </GraphBuilder>
      </div>
      <ConfirmCancelModal
        show={showConfirmCancelModal}
        onCancel={() => setShowConfirmCancelModal(false)}
        onDiscard={onConfirmCancel}
      />
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        name={questionnaire.name}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onDelete={onConfirmDelete}
      />
    </div>
  );
};

export default QuestionnaireDetails;
