import { AppointmentsModalType } from './AppointmentsModalType';

export const getTitle = (type: AppointmentsModalType): string => {
  switch (type) {
    case AppointmentsModalType.CREATE: {
      return 'Schedule an appointment';
    }

    case AppointmentsModalType.CONFIRM: {
      return 'Confirm an appointment';
    }

    case AppointmentsModalType.EDIT: {
      return 'Edit appointment';
    }

    case AppointmentsModalType.DELETE: {
      return 'Are you sure you want to remove this appointment?';
    }
  }
};
