import { createContext, Dispatch, useContext } from 'react';
import { AppointmentsAction } from './AppointmentActionType';
import { AppointmentsState } from './appointmentsReducer';

interface AppointmentsContextState {
  appointmentsState: AppointmentsState;
  dispatch: Dispatch<AppointmentsAction>;
}

const AppointmentsContext = createContext<AppointmentsContextState>({} as AppointmentsContextState);

export const useAppointmentsContext = () => useContext(AppointmentsContext);

export default AppointmentsContext;
