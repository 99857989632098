import DatePickerValue from 'types/Shared/DatePicker';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.css';
import RedAlertItem from './RedAlertItem';
import Header from './Header';
import EmptyRedAlerts from './EmptyRedAlerts';
import useDashboardRedAlerts from '../store/hooks/useDashboardRedAlerts';
import CollapsibleCard from 'components/CollapsibleCard';
import useWebSocketRedAlerts from 'websockets/hooks/useWebSocketRedAlerts';
import { useWebSocketContext } from 'websockets/WebSocketContext';

interface RedAlertsProps {
  collapsed: boolean;
}

const RedAlerts = ({ collapsed }: RedAlertsProps) => {
  const [date, setDate] = useState<DatePickerValue>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  });

  const [{ book: redAlerts, currentPage, extra, refetch }] = useDashboardRedAlerts(date);

  const redAlertsCurrentPage = useMemo(() => {
    return redAlerts?.[currentPage];
  }, [currentPage, redAlerts]);

  const redAlertsMappedToList = useMemo(
    () => redAlertsCurrentPage?.results ?? [],
    [redAlertsCurrentPage]
  );
  const { dispatch } = useWebSocketContext();
  const { received } = useWebSocketRedAlerts();
  const lastReceived = useRef(null);
  useEffect(() => {
    if (received && received !== lastReceived.current) {
      lastReceived.current = received;
      refetch();
    }
  }, [received, dispatch, refetch]);

  const redAlertPatientsCount = useMemo(() => redAlerts?.[1]?.count ?? 0, [redAlerts]);

  return (
    <CollapsibleCard
      outerCollapse={collapsed}
      headers={[
        <Header
          key={0}
          date={date}
          setDate={setDate}
          redAlertPatients={redAlertPatientsCount}
          totalPatients={extra?.patients_total ?? 0}
        />,
      ]}
      className="bg-white mh-100 d-flex flex-column"
      headerClassName="p-0"
    >
      <div className={styles.body} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          {redAlertsMappedToList && redAlertsMappedToList.length > 0 ? (
            <>
              {redAlertsMappedToList?.map((item) => (
                <RedAlertItem dateFrom={date} redAlertSummary={item} key={item.id} />
              ))}
            </>
          ) : (
            <EmptyRedAlerts />
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};
export default RedAlerts;
