import Button from 'components/Button';
import styles from './styles.module.css';

export const AddRow = ({ name, onClick = () => null }: { name: string; onClick: () => void }) => {
  return (
    <div className={styles['add']}>
      <div className="cursor-pointer" onClick={onClick}>
        + Add {name}
      </div>
    </div>
  );
};

interface StepFooterProps {
  onCancel: () => void;
  cancelLabel: string;
  confirmLabel: string;
}
export const StepFooter = ({ onCancel, cancelLabel, confirmLabel }: StepFooterProps) => {
  return (
    <div className="d-flex justify-content-between w-100 p-3">
      <Button label={cancelLabel} variant="dull" onClick={onCancel} />
      <Button label={confirmLabel} type="submit" variant="confirm" />
    </div>
  );
};
