import { ArcElement, Chart, Tooltip } from 'chart.js';
import { useMemo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import Card from '../../../../../../../../../../components/Card';
import PatientTableToolbar from '../../../../../../../../../../components/PatientTableToolbar';
import TagList from '../../../../../../../../../../components/TagList';
import {
  mapToPieChartData,
  triggerMouseEventOnPie,
} from '../../../../../../../../../../util/pieChartUtils';
import styles from '../../../styles.module.css';
import { usePatientReportsContext } from '../../../utils/Context/PatientReportsContext';
import {
  borderColors,
  piechartColors,
  renderTextWithSubscript,
  sortByOccurrence,
} from '../../../utils/patientReportUtils';

Chart.register(ArcElement, Tooltip);
Chart.overrides['doughnut'].plugins.legend = {
  display: false,
};
Chart.defaults.elements.arc = {
  ...Chart.defaults.elements.arc,
  borderWidth: 2,
};

const AlertsReportPieChart = () => {
  const { alerts } = usePatientReportsContext();
  const pieRef = useRef();

  const pieData = useMemo(() => {
    const data = mapToPieChartData(
      'Alerts breakdown',
      //TODO: may need mapping once integrated (or change the keys param)
      [...alerts].sort(sortByOccurrence),
      { label: 'reason', data: 'occurrences' },
      piechartColors,
      borderColors
    );
    return data;
  }, [alerts]);

  return (
    <Card headers={[<PatientTableToolbar title={'Alerts breakdown'} />]} bodyClassName="px-0">
      <Row>
        <Col lg={5}>
          <Doughnut
            data={pieData}
            plugins={{
              legend: {
                display: false,
              },
            }}
            ref={pieRef}
          />
        </Col>
        <Col lg={7}>
          {/* //TODO: replace with corresponding mapping */}
          <TagList
            tagList={[...alerts].sort(sortByOccurrence).map((a, idx) => ({
              text: renderTextWithSubscript(a.category),
              className: styles.pill,
              onHover: () => {
                triggerMouseEventOnPie(pieRef.current, 'mousemove', idx);
              },
              onMouseLeave: () => triggerMouseEventOnPie(pieRef.current, 'mouseout', idx),
              style: {
                backgroundColor: piechartColors[idx],
              },
            }))}
            className={`d-inline-flex flex-wrap ${styles['pills-container']}`}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AlertsReportPieChart;
