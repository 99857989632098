import Card from 'components/Card';
import { Row } from 'react-bootstrap';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import PatientFiles from './patient-files';
import PatientInformation from './patient-information';
import StickyNotes from './sticky-notes';

const PatientData = () => {
  const {
    state: { selectedPatient },
  } = useMessagesContext();
  if (!selectedPatient) {
    return (
      <Card
        headers={['Encounter information']}
        className="card-bg-border"
        bodyClassName="text-center font-italic"
      >
        Select a conversation to see details about the encounter
      </Card>
    );
  }
  return (
    <>
      <Row className="mb-3">
        <PatientInformation />
      </Row>
      <Row className="mb-3">
        <PatientFiles files={selectedPatient && []} />
      </Row>
      <Row>
        <StickyNotes notes={selectedPatient && []} />
      </Row>
    </>
  );
};
export default PatientData;
