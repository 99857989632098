import UserBubble from 'components/UserBubble';
import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { AdminPatientRow } from '../AdminPatientsMain/AdminPatientsTable/utils/adminPatientsTableUtils';

//TODO: later will be imported
const defaultImage = '';

const AdminPatientElement: FC<Partial<AdminPatientRow>> = ({
  patient,
  username,
}: Partial<AdminPatientRow>) => {
  const { url } = useRouteMatch();

  const { firstName, lastName, profileImage } = patient;
  const displayName = firstName && lastName ? `${firstName} ${lastName}` : username;
  return (
    <div className="d-flex gap-sm align-items-center">
      <UserBubble
        user={{ first_name: firstName, last_name: lastName, imageUrl: profileImage, username }}
        containerClassNameList={['ml-0']}
        imgClassNameList={['ml-0']}
      />
      <Link to={`${url}/${patient.id}`}>{displayName}</Link>
    </div>
  );
};
export default AdminPatientElement;
