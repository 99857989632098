import { Tagitem } from 'components/Tagitem';
import { AlertPopulation } from 'types/ApiModels/Dashboard';
import { formatDottedDate } from 'util/dateUtils';
import styles from './styles.module.css';
interface PopulationsProps {
  user_id: number;
  birth_date: string;
  email: string;
  phone_number: string;
  populations: AlertPopulation[];
}
const Populations = ({
  birth_date,
  phone_number,
  email,
  populations,
  user_id: id,
}: PopulationsProps) => {
  return (
    <div>
      <div className={styles['user-info']}>
        <span>{formatDottedDate(new Date(birth_date))}</span>•<span>{id ?? 'no-id'}</span>•
        <span>{email}</span>•<span>{phone_number}</span>
      </div>
      <div className={styles['user-populations']}>
        {populations?.map((population) => (
          <Tagitem text={population.name} key={population.id} />
        ))}
      </div>
    </div>
  );
};
export default Populations;
