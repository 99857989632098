import { deleteTemplateAction, updateTemplateAction } from 'services/templatesService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { updatePreviousActionsRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/updatePreviousActionsRecurrence';
import { transformToTemplateAction } from 'util/calendarUtils/transformers';

/**
 * deletes the action along with the recurrent actions
 * @param updatedPatientActions
 * @param originalActionId
 * @param templateId
 */
const deleteOriginalAction = async (
  updatedPatientActions: IPatientCarePlanAction[],
  originalActionId: number,
  templateId: number
) => {
  const selectedActionIndex = updatedPatientActions.findIndex((a) => a.id === originalActionId);

  await deleteTemplateAction(templateId, originalActionId);
  updatedPatientActions.splice(selectedActionIndex, 1);
};

/**
 * deletes all the actions starting from the recurrent action clicked. The actions before that are not deleted.
 * If the selected action occurs the same day as the original one everything is deleted
 * @param updatedOriginalAction
 * @param blockStartDate
 * @param templateId
 * @param updatedPatientActions
 * @param selectedTimelineAction
 */
const deleteRecurrentAction = async (
  updatedOriginalAction: IPatientCarePlanAction,
  blockStartDate: Date,
  templateId: number,
  updatedPatientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction
) => {
  if (updatedOriginalAction.recurrence.end_date_duration_days > 0) {
    const updatedTemplateOriginalAction = transformToTemplateAction(
      updatedOriginalAction,
      blockStartDate
    );
    await updateTemplateAction(templateId, updatedTemplateOriginalAction);
  } else {
    await deleteOriginalAction(
      updatedPatientActions,
      selectedTimelineAction.originalActivityId,
      templateId
    );
  }
};

export const deleteAllEvents = async (
  templateId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction,
  blockStartDate: Date
): Promise<IPatientCarePlanAction[]> => {
  const updatedPatientActions = [...patientActions];

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    await deleteOriginalAction(updatedPatientActions, selectedTimelineAction.id, templateId);
  } else {
    // update recurrence of previous actions
    const updatedOriginalAction = updatePreviousActionsRecurrence(
      selectedTimelineAction,
      updatedPatientActions,
      'template'
    );

    await deleteRecurrentAction(
      updatedOriginalAction,
      blockStartDate,
      templateId,
      updatedPatientActions,
      selectedTimelineAction
    );
  }
  return updatedPatientActions;
};
