import DayActivityItem from 'components/ActivityTimeline/ActivityTimelineView/DayActivityItem';
import { printBeginDate } from 'components/ActivityTimeline/utils';
import { mapActionTypeToLabel } from 'components/RPM/utils/typeMapper';

import { FC, Fragment } from 'react';
import { Activity, ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import ActivityItemStyles from '../../ActivityItems.module.css';
import ActionContent, { actionContentProps } from '../../DayActivityItem/ActionContent';
import AppointmentContent, {
  appointmentContentProps,
} from '../../DayActivityItem/AppointmenContent';
import { getActionLabel, getAppointmentLabel } from '../../utils/getItemLabel';
import styles from './styles.module.css';

interface WeekActivityItemsProps {
  items: Activity[][];
  rangeItems: Activity[];
  weekArray: Date[];
}

const WeekActivityItems: FC<WeekActivityItemsProps> = ({
  items,
  rangeItems,
  weekArray,
}: WeekActivityItemsProps) => {
  return (
    <div className="d-flex week-items-container gap-sm h-100">
      <div className="d-flex flex-column flex-grow-1">
        {weekArray.map((d, idx) => {
          return (
            <Fragment key={`week-items-${d.toDateString()}`}>
              {items?.[idx]?.length ? (
                <div className={`d-flex align-items-center justify-content-center font-size-small`}>
                  <div className={`flex-grow-1 ${styles['week-day-separator']} rounded mx-2`}></div>
                  <div className={`text-uppercase ${styles['week-day-separator-label']}`}>
                    {d.toLocaleDateString('en-US', { weekday: 'short' })}
                  </div>
                  <div
                    className={` flex-grow-1 ${styles['week-day-separator']} rounded mx-2`}
                  ></div>
                </div>
              ) : null}
              <div className="d-flex flex-column gap-sm mt-2 px-1">
                {items?.[idx]?.map((i) => {
                  return (
                    <DayActivityItem
                      key={`day-activity-item-${i.id}`}
                      activityItemId={i.id}
                      itemContainerProps={
                        i.type === ActivityType.ACTION
                          ? actionContentProps({
                              isCarePlan: i.action_props.is_part_of_rpm_plan,
                              isActive: i.action_props.is_active,
                            })
                          : appointmentContentProps()
                      }
                    >
                      {i.type === ActivityType.ACTION && (
                        <ActionContent
                          dateAMPM={printBeginDate(i.start_date)}
                          label={getActionLabel(i.action_props)}
                          isActive={i.action_props.is_active}
                        />
                      )}
                      {i.type === ActivityType.APPOINTMENT && (
                        <AppointmentContent
                          dateAMPM={printBeginDate(i.start_date)}
                          label={getAppointmentLabel(i.appointment_props)}
                          type={i.appointment_props.appointment_type}
                        />
                      )}
                    </DayActivityItem>
                  );
                })}
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className="d-flex h-100 gap-sm py-3 pr-2">
        {rangeItems?.map((ri) => {
          return (
            <div
              className={` d-flex ${styles['week-range-item']} rounded cursor-pointer`}
              key={`week-range-item-${ri.id}`}
              title={`${ri.action_props.goal_name}`}
            >
              <div className="start-offset"></div>
              <div
                className={`d-flex flex-column ${ActivityItemStyles['range-activity-container-bg']}`}
                style={{ flexBasis: `100%` }}
              >
                <div
                  className={`${ActivityItemStyles['range-activity-completed']} rounded`}
                  // goalCompletion
                  //style={{ flexBasis: `${(ri.vytal.value / ri.vytal.goal) * 100}%` }}
                  style={{ flexBasis: `${0 * 100}%` }}
                ></div>
                <div className=""></div>
              </div>
              <div className="end-offset"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default WeekActivityItems;
