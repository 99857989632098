export enum profileControls {
  myAccount = 'myAccount',
  activityPoints = 'activityPoints',
}

export interface ProfileControlItem {
  label: string;
  enumValue: profileControls;
  route: string;
}
