interface IColor {
  label: string;
  background: string;
}

interface IChartColors {
  red: IColor;
  yellow: IColor;
  normal: IColor;
}

export const chartColors: IChartColors = {
  red: {
    label: '#F2A1AD',
    background: '#FFFAFA',
    // background: 'red',
  },
  yellow: {
    label: '#F8CA54',
    background: '#FFFCF6',
    // background: 'yellow',
  },
  normal: {
    label: '#86B463',
    background: '#EFFAE7',
    // background: 'green',
  },
};
