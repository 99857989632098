import VyTracLayout from '../../components/VyTracLayout';
import AdministrationContent from './AdministrationContent';
import NavigationHeader from './NavigationHeader';
import AdministrationCtxProvider from './store/AdministrationCtxProvider';

const Administration = () => {
  return (
    <VyTracLayout selectedRoute="administration">
      <AdministrationCtxProvider>
        <NavigationHeader />
        <AdministrationContent />
      </AdministrationCtxProvider>
    </VyTracLayout>
  );
};

export default Administration;
