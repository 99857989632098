import { vytracAxios } from 'ajax';
import { Appointment } from 'types/ApiModels/Appointments/Appointment';

export const getAppointments = async (dateFrom: string, dateTo: string = null) => {
  try {
    const response = await vytracAxios.get<Appointment[]>(`appointments/`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSingleAppoointment = async (id: number) => {
  try {
    const response = await vytracAxios.get(`appointments/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const createAppointment = async (appointment: Appointment) => {
  try {
    const response = await vytracAxios.post<Appointment>(`appointments/`, appointment);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const deleteAppointment = async (id: number) => {
  try {
    const response = await vytracAxios.delete(`appointments/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};
