import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.css';

//mocks
import { MockBroadcast } from 'screens/messages/mocks/types';
import Checkbox from 'components/form/Checkbox';

import { timeAgo } from 'util/dateUtils';
import { useLocation } from 'react-router-dom';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import {
  addToSelectedBroadcast,
  removeFromSelectedBroadcast,
  setOpenedBroadcast,
  setOpenedBroadcastTemplate,
} from 'screens/messages/store/messages-action-creators';

interface BroadcastItemProps {
  broadcast: MockBroadcast;
  selectable?: boolean;
  opened?: boolean;
}

const BroadcastItem: FC<BroadcastItemProps> = ({
  broadcast,
  selectable,
  opened,
}: BroadcastItemProps) => {
  const { pathname } = useLocation();

  const [checkboxValue, setCheckboxValue] = useState<boolean | null>(null);

  const { dispatch } = useMessagesContext();

  useEffect(() => {
    if (checkboxValue) {
      dispatch(addToSelectedBroadcast(broadcast.id));
    } else if (checkboxValue !== null) {
      dispatch(removeFromSelectedBroadcast(broadcast.id));
    }
  }, [dispatch, checkboxValue, broadcast]);

  const broadcastName = useMemo(() => {
    return broadcast.title;
  }, [broadcast]);

  const lastSent = useMemo(() => {
    return timeAgo(new Date(broadcast.time.year, broadcast.time.month, broadcast.time.day));
  }, [broadcast]);

  const broadcastText = useMemo(() => {
    return broadcast.text;
  }, [broadcast]);

  const itemStyle: React.CSSProperties = useMemo(() => {
    let styles = {};
    if (selectable && checkboxValue) {
      styles = {
        ...styles,
        fontWeight: '600',
      };
    }
    if (opened) {
      styles = {
        ...styles,
        backgroundColor: 'var(--gray-tags)',
      };
    }
    return styles;
  }, [checkboxValue, selectable, opened]);

  const handleItemClick = useCallback(() => {
    if (pathname === '/messages/broadcast') {
      dispatch(setOpenedBroadcast(broadcast));
    } else if (pathname === '/messages/broadcast/templates') {
      dispatch(setOpenedBroadcastTemplate(broadcast));
    }
  }, [broadcast, dispatch, pathname]);

  const handleSelectClick = useCallback(() => {
    setCheckboxValue((prev) => !prev);
  }, [setCheckboxValue]);

  return (
    <div className={styles['broadcast-item']} style={itemStyle} onClick={handleItemClick}>
      {selectable && (
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox onChange={handleSelectClick} value={Boolean(checkboxValue)} />
        </div>
      )}
      <div className={styles['text-container']}>
        <div className={styles['broadcast-title']}>{broadcastName}</div>
        <div className={styles['broadcast-text-container']}>
          <span className={styles['broadcast-text']}>
            {lastSent}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{broadcastText}
          </span>
        </div>
      </div>
    </div>
  );
};
export default BroadcastItem;
