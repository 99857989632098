import React, { useEffect } from 'react';
import VyTracModal from '../../../../../../components/Modal';
import Body from './components/Body';
import Header from './components/Header';
import styles from './styles.module.css';

const LastColumn = ({
  activeSchedule,
  setUnSavedChanges,
  initialState,
  newSchedule,
  setNewSchedule,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalBody, setModalBody] = React.useState(null);

  //? if you're using formik it's better to manage the state there rather than on a separate state. this is regarding all onX handlers
  const onNameChange = (value) => {
    return setNewSchedule({ ...newSchedule, name: value });
  };

  const onShiftChange = (value, shift) => {
    return setNewSchedule({ ...newSchedule, [shift]: value });
  };

  const onSelectionChange = (newSelection) => {
    //? this patches it but not really sure is a good approach. For some reason when you click outside of scheduler it triggers the onChange on the component
    if (newSelection.length === newSchedule.selectedHours.length) return;
    return setNewSchedule({ ...newSchedule, selectedHours: newSelection });
  };

  const onWeekTogglesChange = (newWeekToggles) => {
    return setNewSchedule({ ...newSchedule, weekToggles: newWeekToggles });
  };

  useEffect(() => {
    setNewSchedule({ ...newSchedule, ...activeSchedule });
    //? should not lie to useEffect here, you have three dependencies: newSchedule, activeSchedule, setNewSchedule
  }, [activeSchedule]);

  //? if using formik you should get rid of this function since formik will do the heavy lifting by comparing initialvalues with your current value
  const compareSchedules = (sched1, sched2) => {
    if (!sched1 || !sched2) return false;
    if (sched1.name !== sched2.name) return false;
    if (sched1.dayShift !== sched2.dayShift) return false;
    if (sched1.secondShift !== sched2.secondShift) return false;
    if (sched1.thirdShift !== sched2.thirdShift) return false;
    if (sched1.weekToggles.some((item, index) => item !== sched2.weekToggles[index])) return false;
    if (sched1.selectedHours.length !== sched2.selectedHours.length) return false;
    const set1 = new Set(sched1.selectedHours.map((date) => date.toISOString()));
    const set2 = new Set(sched2.selectedHours.map((date) => date.toISOString()));
    if (
      !(
        sched1.selectedHours.every((item) => set2.has(item.toISOString())) &&
        sched2.selectedHours.every((item) => set1.has(item.toISOString()))
      )
    )
      return false;
    // let day1, hour1, day2, hour2;
    // for (let i = 0; i < sched1.selectedHours.length; i++) {
    //   day1 = moment(sched1.selectedHours[i]).day();
    //   hour1 = moment(sched1.selectedHours[i]).hour();
    //   day2 = moment(sched2.selectedHours[i]).day();
    //   hour2 = moment(sched2.selectedHours[i]).hour();
    //   if (day1 !== day2 || hour1 !== hour2) return false;
    // }
    return true;
  };

  useEffect(() => {
    setUnSavedChanges(!compareSchedules(initialState, newSchedule));
  }, [initialState, newSchedule, setUnSavedChanges]);

  return (
    <>
      <div className={styles.container}>
        <Header
          setCurrentSchedule={onNameChange}
          dayShift={newSchedule.dayShift}
          setDayShift={(value) => onShiftChange(value, 'dayShift')}
          secondShift={newSchedule.secondShift}
          setSecondShift={(value) => onShiftChange(value, 'secondShift')}
          thirdShift={newSchedule.thirdShift}
          setThirdShift={(value) => onShiftChange(value, 'thirdShift')}
          setModalBody={setModalBody}
          setShowModal={setShowModal}
        />
        <Body
          selection={newSchedule.selectedHours}
          setSelection={onSelectionChange}
          dayShift={newSchedule.dayShift}
          secondShift={newSchedule.secondShift}
          thirdShift={newSchedule.thirdShift}
          setModalBody={setModalBody}
          setShowModal={setShowModal}
          weekToggles={newSchedule.weekToggles}
          setWeekToggles={onWeekTogglesChange}
        />
      </div>
      <VyTracModal
        title="Warning"
        body={modalBody}
        show={showModal}
        centered
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default LastColumn;
