import React from 'react';
import {RatingView} from 'react-simple-star-rating';
import styles from './styles.module.css';

export default function RatingStarsView({value}) {
  return (
    <RatingView
      ratingValue={value}
      size={12}
      fillColor="#8FAAC3"
      emptyColor="#fff"
      className={styles.starSpan}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 10 11"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.30915 7.4129L1.7904 10.5661C1.74961 10.8132 1.93459 11.0155 2.13741 10.9977L2.03731 10.6069L2.03727 10.6067L2.01565 10.5224L2.33294 10.3484M2.30915 7.4129L2.33294 10.3484M2.30915 7.4129L0.107283 5.17564L2.30915 7.4129ZM2.33294 10.3484L4.75877 9.0184L4.99909 8.88664L5.23943 9.01836L7.6673 10.3489L7.19764 7.49407L7.1564 7.24335L7.3346 7.06223L9.36823 4.99533L6.57984 4.5727L6.31667 4.53281L6.20281 4.29221L5.00008 1.75055L3.79735 4.29221L3.6835 4.53281L3.42033 4.5727L0.631433 4.9954L2.66551 7.06218L2.84377 7.2433L2.80252 7.49407L2.33294 10.3484ZM9.53574 4.82508C9.53586 4.82496 9.53598 4.82484 9.53609 4.82471L9.53585 4.82497L9.53574 4.82508Z"
          stroke="#8FAAC4"
        />
      </svg>
    </RatingView>
  );
}
