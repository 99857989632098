import React from 'react';
import styles from './styles.module.css';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
const Header = ({ title, date, criticalCount, totalCount, onClick }) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>
          <div>
            <span>{date}</span> •{' '}
            <span>
              {criticalCount} out of {totalCount} answers are critical
            </span>
          </div>
        </div>
      </div>
      <Cross fill="#8FAAC4" className={styles.cross} onClick={onClick} />
    </div>
  );
};
export default Header;
