import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import { ComplianceIssues } from 'types/ApiModels/Analytics/AnalyticsComplianceIssues';
import { Devices } from 'types/ApiModels/Analytics/AnalyticsDevices';
import { DroppedPatients } from 'types/ApiModels/Analytics/AnalyticsDropppedPatients';
import { NewPatients } from 'types/ApiModels/Analytics/AnalyticsNewPatients';
import { PatientAccrued } from 'types/ApiModels/Analytics/AnalyticsPatientAccrued';
import { PatientEligibility } from 'types/ApiModels/Analytics/AnalyticsPatientEligibility';
import { PatientEnrollement } from 'types/ApiModels/Analytics/AnalyticsPatientEnrollement';
import { Patients } from 'types/ApiModels/Analytics/AnalyticsPatients';
import { Satisfaction } from 'types/ApiModels/Analytics/AnalyticsSatisfaction';


export enum AnalyticsTypes {
  SET_PATIENT_ENROLLEMENT = 'SET_PATIENT_ENROLLEMENT',
  SET_PATIENT_ELIGIBILITY = 'SET_PATIENT_ELIGIBILITY',
  SET_BILLING = 'SET_BILLING',
  SET_PATIENT_ALERTS = 'SET_PATIENT_ALERTS',
  SET_COMPLIANCE_ISSUES = 'SET_COMPLIANCE_ISSUES',
  SET_PATIENT_SATISFACTION = 'SET_PATIENT_SATISFACTION',
  SET_TOTAL_PER_MONTH = 'SET_TOTAL_PER_MONTH',
  SET_TOTAL_PER_YEAR = 'SET_TOTAL_PER_YEAR',
  SET_PATIENT_ADMITTED = 'SET_PATIENT_ADMITTED',
  SET_PATIENTS_DROPPED = 'SET_PATIENTS_DROPPED',
  SET_PATIENTS = 'SET_PATIENTS',
  SET_NEW_PATIENTS = 'SET_NEW_PATIENTS',
  SET_PATIENTS_ACCRUED = 'SET_PATIENTS_ACCRUED',
  SET_DEVICES = 'SET_DEVICES'
}

export type AnalyticsAction =
  | {
      type: AnalyticsTypes.SET_PATIENT_ENROLLEMENT;
      payload: { patientEnrollement: PatientEnrollement };
    }
  | {
      type: AnalyticsTypes.SET_PATIENT_ELIGIBILITY;
      payload: { patientEligibility: PatientEligibility };
    }
  | {
    type: AnalyticsTypes.SET_PATIENTS;
    payload: { patients: Patients };
  }
  | {
    type: AnalyticsTypes.SET_COMPLIANCE_ISSUES;
    payload: { complianceIssues: ComplianceIssues };
  }
  | {
    type: AnalyticsTypes.SET_PATIENT_SATISFACTION;
    payload: { satisfaction: Satisfaction };
  }
  | {
    type: AnalyticsTypes.SET_NEW_PATIENTS;
    payload: { newPatients: NewPatients[] };
  }
  | {
    type: AnalyticsTypes.SET_PATIENT_ALERTS;
    payload: { alerts: Alerts[] };
  }
  | {
    type: AnalyticsTypes.SET_PATIENTS_DROPPED;
    payload: { droppedPatients: DroppedPatients };
  }
  | {
    type: AnalyticsTypes.SET_DEVICES;
    payload: { devices: Devices[] };
  }
  | {
    type: AnalyticsTypes.SET_PATIENTS_ACCRUED;
    payload: { patientAccrued: PatientAccrued };
  }

