import { ReactComponent as WeightIcon } from 'assets/icons/goals/weight.svg';
import { ReactComponent as OxygenIcon } from 'assets/icons/goals/oxygen.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/goals/heart.svg';
import { ReactComponent as TemperatureIcon } from 'assets/icons/goals/temperature.svg';
import { ReactComponent as MoonIcon } from 'assets/icons/goals/moon.svg';
import { ReactComponent as BPIcon } from 'assets/icons/goals/BP.svg';
import { VytalType } from 'types/ApiModels/Patients/Vytal';

const VytalTypeIconMapper = {
  [VytalType.WEIGHT]: WeightIcon,
  [VytalType.OXYGEN_LEVEL]: OxygenIcon,
  [VytalType.HEART_RATE]: HeartIcon,
  [VytalType.TEMPERATURE]: TemperatureIcon,
  [VytalType.STEPS]: HeartIcon,
  [VytalType.SLEEP]: MoonIcon,
  [VytalType.BLOOD_PRESSURE]: BPIcon,
  [VytalType.BLOOD_SUGAR]: BPIcon,
};

export default VytalTypeIconMapper;
