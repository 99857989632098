import { emailRegex, phoneNumberRegex, usernameRegex } from 'util/regexs';
import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Required';
const EMAIL_INVALID_MESSAGE = 'Email has an invalid format';

const emailSchema = yup.string().email(EMAIL_INVALID_MESSAGE).required(REQUIRED_MESSAGE);

export const ForgotPasswordValidationSchema = yup.object().shape({
  email: emailSchema,
});

export const ResetPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(8, 'Must contain at least 8 characters')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g,
      'Password must contain at least one special character'
    ),
  confirmPassword: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const LoginValidationSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().required(REQUIRED_MESSAGE),
  timezone: yup.string().required(REQUIRED_MESSAGE),
});
