import moment from 'moment';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { durationUnit } from 'types/ApiModels/Templates/CarePlan';
import { templateCarePlanStartDate } from '../../../../../../util';

export const isActionAfterDuration = (
  selectedAction: IPatientCarePlanAction,
  durationType: durationUnit,
  durationQuantity: number
): boolean => {
  if (!durationType) return false;

  const dayQty = moment(selectedAction.start_date).diff(templateCarePlanStartDate, 'days');
  switch (durationType) {
    case 'days': {
      return dayQty >= durationQuantity;
    }
    case 'weeks': {
      return dayQty >= durationQuantity * 7;
    }
    case 'months': {
      return dayQty >= durationQuantity * 30;
    }
  }
};
