import { PatientsSearch } from 'types/ApiModels/Patients/PatientsSearch';
import { ProvidersSearch } from 'types/ApiModels/Providers/Provider';

export const mapPatientSearchToResult = (value: PatientsSearch) => ({
  id: value.id,
  name: `${value.first_name} ${value.last_name}`,
});

export const mapProviderSearchToResult = (value: ProvidersSearch) => ({
  id: value.id,
  name: `${value.first_name} ${value.last_name}`,
});
