import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getAnalyticsDevices } from 'services/analyticsService';
import { Devices } from 'types/ApiModels/Analytics/AnalyticsDevices';
import { setDevices } from '../AnalyticsActionCreators';
import { AnalyticsAction } from '../AnalyticsActionTypes';
import { useAnalyticsContext } from '../AnalyticsContext';

const useDevices = (
): [Devices[], Dispatch<AnalyticsAction>, () => void] => {
  const {
    state: { devices },
    dispatch,
    fetchRegistry,
  } = useAnalyticsContext();

  const [devicesState, setDevicesState] = useState<Devices[]>(null);

  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    fetchRegistry.current.devices.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchDevices = useCallback(async () => {
    if(!devicesState){
      const data = await getAnalyticsDevices();
      setDevicesState(data);
    }
  }, [devices]);

  const devicesInit = useCallback(async () => {
    fetchRegistry.current.devices.list = true;
    await fetchDevices();
    fetchRegistry.current.devices.list = false;
  }, [fetchRegistry, fetchDevices]);

  useEffect(() => {
    devicesInit();
  }, [fetchRegistry, devices, devicesInit, _refetch.current]);

  useEffect(() => {
    dispatch(setDevices(devicesState));
  }, [dispatch, devicesState]);

  return [devicesState, dispatch, refetch];
};

export default useDevices;
