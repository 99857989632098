import { AddCircleButton, DeleteCircleButton } from 'components/CircleButton';
import FiltersButton from 'components/FiltersButton';
import { Droppable } from 'react-beautiful-dnd';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { IResourceResult } from 'types/Resources/Resources';
import ActionItem from '../ActionItem';
import styles from './styles.module.css';

type dataType = IQuestionnaire | IResourceResult;
interface IActionsCard {
  calendarActionType: string;
  data: dataType[];
  addElement: () => void;
  showDelete?: boolean;
  deleteElement?: () => void;
}
const ActionsCard = ({
  calendarActionType,
  data,
  addElement,
  showDelete = false,
  deleteElement,
}: IActionsCard) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>{calendarActionType}</div>
        <div className={styles.controls}>
          <AddCircleButton onClick={addElement} />
          {showDelete && <DeleteCircleButton onClick={deleteElement} />}
          <FiltersButton />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      <div className={styles.body}>
        <Droppable droppableId={`actionsCard-${calendarActionType}`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data.map((item: dataType, index: number) => (
                <ActionItem key={index} name={item.name} id={item.id} type={calendarActionType} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default ActionsCard;
