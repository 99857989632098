import React from 'react';
import Button from '../../../Button';
import styles from './styles.module.css';

interface IQuickSortButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sort: string;
  label: string;
  onClick: () => void;
  selected: boolean;
}
const QuickSortButton = ({ sort, label, onClick, selected, ...props }: IQuickSortButton) => {
  return (
    <Button
      {...props}
      className={styles.button}
      color="#8FAAC3"
      label={label}
      onClick={onClick}
      selected={selected}
    />
  );
};

export default QuickSortButton;
