enum PatientManagementFormikKeys {
  IDENTIFICATION = 'IDENTIFICATION',
  POPULATION = 'POPULATION',
  CARE_CHAMPIONS = 'CARE_CHAMPIONS',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  EMERGENCY_CONTACTS = 'EMERGENCY_CONTACTS',
  SURGERIES = 'SURGERIES',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  ALLERGIES = 'ALLERGIES',
  HEALTH_SCORING = 'HEALTH_SCORING',
}

export default PatientManagementFormikKeys;
