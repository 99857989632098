export const FIRST_SHIFT = {
  id: 1,
  start: 8,
  end: 18,
};

export const SECOND_SHIFT = {
  id: 2,
  start: 18,
  end: 2,
};

export const THIRD_SHIFT = {
  id: 3,
  start: 2,
  end: 8,
};
