import React from 'react';
import GenericTable from '../../../../../../../../../../../components/GenericTable';
import styles from './styles.module.css';
import { RoundTagitem } from '../../../../../../../../../../../components/Tagitem';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useContext } from 'react';
import QuestionnaireContext from '../../../../util/Context';
const PatientQuestionnairesTable = ({ data }) => {
  const { setQuestionnaireState } = useContext(QuestionnaireContext);
  const history = useHistory();
  const { url } = useRouteMatch();
  const columns = [
    {
      title: 'Name',
      key: 'name',
      style: { width: '35%' },
      render: (item) => (
        <div
          className={styles.name}
          onClick={() => {
            history.push(`${url}/${item.id}`);
            setQuestionnaireState((prev) => ({
              ...prev,
              selectedQuestionnaire: item,
            }));
          }}
        >
          {item.name}
        </div>
      ),
    },
    { title: 'Questions', key: 'questions', style: { width: '11%' } },
    { title: 'Completed', key: 'completed' },
    { title: 'Last sent', key: 'lastSent' },
    { title: 'Last result', key: 'lastResult' },
  ];

  return <GenericTable columns={columns} tableClassName={styles.table} data={data} />;
};

export default PatientQuestionnairesTable;
