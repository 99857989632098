import React from 'react';
import sliders from '../../assets/icons/sliders.png';
import styles from './styles.module.css';

interface FiltersButtonProps {
  className?: string;
  onFiltersClick?: (filters: any) => void;
}

const FiltersButton = ({ className = '', onFiltersClick }: FiltersButtonProps) => (
  <button className={`${styles['filter-btn']} ${className}`} onClick={onFiltersClick}>
    <img src={sliders} alt="sliders" />
    <span>Filters</span>
    <div className={styles['filter-count']}>0</div>
  </button>
);

export default FiltersButton;
