import RelativeDateSelector from 'components/RelativeDateSelector';
import TimePeriodSelect, { TimePeriodOptions } from 'components/TimePeriodSelect';
import React from 'react';
import {
  setPeriodSelected,
  setSelectedAction,
  setSelectedDate,
} from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import CreateAction from './components/CreateAction';
import TemplateData from './components/TemplateData';
import styles from './styles.module.css';
import useCalendarModification from './util/useCalendarModification';
import { getRelativeDate, getAbsoluteDate } from 'util/calendarUtils/transformers';
import { templateCarePlanStartDate } from '../../../util';
import RecurrenceEditionModal from 'components/RPM/RecurrenceModals/RecurrenceEditionModal';
import RecurrenceConfirmationModal from 'components/RPM/RecurrenceModals/RecurrenceConfirmationModal';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';

const timePeriodOptions = [TimePeriodOptions.DAY, TimePeriodOptions.WEEK];

const CalendarControl = () => {
  const {
    dispatch,
    templateState: {
      templateCarePlan: { periodSelected, selectedDate, selectedAction },
    },
  } = useTemplateContext();

  const {
    showRecurrenceEditionModal,
    showDeletionConfirmationModal,
    showEditionConfirmationModal,
    onCancel,
    onUpdateAllEvents,
    onUpdateOnlyThisEvent,
    onRecurrenceEditionSave,
    onDeleteAllEvents,
    onDeleteOnlyThisEvent,
    onRecurrenceDelete,
  } = useCalendarModification();

  const onRelativeTimeChange = (newRelativeTime: IRelativeTime) => {
    const newAbsoluteDate = getAbsoluteDate(newRelativeTime, templateCarePlanStartDate);
    dispatch(setSelectedDate(newAbsoluteDate));
  };

  return (
    <div className={styles.calendarControl}>
      <div className="container-with-padding bg-light-gray font-size-medium">
        <TimePeriodSelect
          selectedTimePeriod={periodSelected}
          handleTimePeriodClick={(newTimePeriod) => dispatch(setPeriodSelected(newTimePeriod))}
          timeOptions={timePeriodOptions}
        />
      </div>
      <div className={styles.dateSelectorContainer}>
        <RelativeDateSelector
          value={getRelativeDate(selectedDate.toISOString(), templateCarePlanStartDate)}
          onChange={onRelativeTimeChange}
        />
      </div>
      <TemplateData />
      <CreateAction onCancel={onCancel} />
      <RecurrenceEditionModal
        modalData={selectedAction}
        setModalData={(updatedSelectedAction) => dispatch(setSelectedAction(updatedSelectedAction))}
        onCancel={onCancel}
        onClose={onCancel}
        onDelete={onRecurrenceDelete}
        onSave={onRecurrenceEditionSave}
        show={showRecurrenceEditionModal}
        modalType="template"
        relativeMinDate={templateCarePlanStartDate}
      />
      <RecurrenceConfirmationModal
        show={showEditionConfirmationModal}
        onClose={onCancel}
        onAllEvents={onUpdateAllEvents}
        onOnlyThisEvent={onUpdateOnlyThisEvent}
      />
      <RecurrenceConfirmationModal
        show={showDeletionConfirmationModal}
        onClose={onCancel}
        onAllEvents={onDeleteAllEvents}
        onOnlyThisEvent={onDeleteOnlyThisEvent}
      />
    </div>
  );
};

export default CalendarControl;
