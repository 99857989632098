import React from 'react';
import styles from './styles.module.css';
const Details = ({questions = [], answers = []}) => {
  return (
    <div className={styles.container}>
      {questions.map((question, index) => (
        <div key={index} className={styles.question}>
          {index + 1}. {question.question}
          <div className={styles.answerContainer}>
            {answers[index].map((answer, ansIndex) => (
              <div
                //TODO: revisit whether this is a good key or leave todo to improve
                key={`${index}_${ansIndex}`}
                style={{
                  fontWeight: answer.selected ? '700' : '400',
                  color: answer.selected && answer.critical ? 'red' : 'auto',
                }}>
                {answer.answer}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Details;
