import tabEnum from './tabEnum';

const tabNameMap = {
  [tabEnum.PATIENT_STATUS]: 'patient status',
  [tabEnum.PATIENT_TIMELINE]: 'patient timeline',
  [tabEnum.TEAM_NOTES]: 'team notes',
  [tabEnum.CARE_PLAN]: 'care plan',
  [tabEnum.PATIENT_MESSAGES]: 'patient messages',
  [tabEnum.PATIENT_REPORTS]: 'patient reports',
  [tabEnum.ENCOUNTERS]: 'encounters',
  [tabEnum.GOALS_THRESHOLD]: 'goals threshold',
  [tabEnum.QUESTIONNAIRES]: 'questionnaires',
  [tabEnum.PATIENT_MANAGEMENT]: 'patient management',
};

export default tabNameMap;
