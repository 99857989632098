import React from 'react';
import styles from './styles.module.css';

interface ICircleButton {
  sign?: string;
  onClick: () => void;
}
const CircleButton = ({ sign, onClick }: ICircleButton) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.sign}>{sign}</div>
    </div>
  );
};

export const AddCircleButton = ({ onClick }: ICircleButton) => {
  return <CircleButton sign="+" onClick={onClick} />;
};

export const DeleteCircleButton = ({ onClick }: ICircleButton) => {
  return <CircleButton sign="-" onClick={onClick} />;
};
