import { Children, CSSProperties, FC, Fragment, ReactElement, ReactNode, useMemo } from 'react';
import styles from './styles.module.css';

interface CardProps {
  children: ReactNode;
  className?: string;
  bodyClassName?: string;
  headerClassName?: string;
  headers?: string[] | JSX.Element[];
  style?: CSSProperties;
  divideChildren?: boolean;
  onClick?: () => void;
  bodyWrapper?: FC<any>;
}

const Card: FC<CardProps> = ({
  children,
  bodyClassName,
  headerClassName,
  headers,
  className,
  style,
  divideChildren = false,
  onClick = null,
  bodyWrapper,
}: CardProps) => {
  const BodyWrapperComponent = useMemo(() => {
    if (bodyWrapper) {
      return bodyWrapper;
    }
    const emptyComponent = ({ children }) => <>{children}</>;
    return emptyComponent;
  }, [bodyWrapper]);
  return (
    <div className={`shadow-sm ${className || ''}`} style={style} onClick={onClick}>
      {headers?.length > 0 &&
        headers.map((h, idx) => (
          <Fragment key={idx}>
            <div className={`${headerClassName || ''} ${styles.header}`}>{h}</div>
            <hr className={styles.horizontalLine} />
          </Fragment>
        ))}
      <BodyWrapperComponent>
        {divideChildren ? (
          Children.map(children, (c, idx) => (
            <>
              <div className={`${bodyClassName || ''} ${styles.body}`}>{c}</div>
              {idx !== Children.count(children) - 1 ? (
                <hr className={styles.horizontalLine} />
              ) : null}
            </>
          ))
        ) : (
          <div className={`${bodyClassName || ''} ${styles.body}`}>{children}</div>
        )}
      </BodyWrapperComponent>
    </div>
  );
};

export default Card;
