import { RoundTagitem } from '../Tagitem';
import styles from './styles.module.css';

export interface UserBubbleUser {
  first_name?: string;
  last_name?: string;
  imageUrl?: string;
  username?: string;
}
interface IUserBubble {
  user: UserBubbleUser;
  containerClassNameList?: string[];
  imgClassNameList?: string[];
  includeMargin?: boolean;
}

const UserBubble = ({
  user,
  containerClassNameList = [],
  imgClassNameList = [],
  includeMargin = true,
}: IUserBubble) => {
  const { first_name, last_name, imageUrl } = user;

  const initials =
    first_name != null && last_name != null ? first_name?.charAt(0) + last_name?.charAt(0) : '??';

  const containerClasses = includeMargin
    ? [styles.userBubbleContainer, styles.userBubbleContainerMargin, ...containerClassNameList]
    : [styles.userBubbleContainer, ...containerClassNameList];
  const imgClassNames = includeMargin
    ? [styles.userBubbleImage, styles.userBubbleContainerMargin, ...imgClassNameList]
    : [styles.userBubbleImage, ...imgClassNameList];

  return (
    <RoundTagitem
      text={initials}
      containerClassNameList={containerClasses}
      imgClassNameList={imgClassNames}
      img={imageUrl}
      includeMargin={includeMargin}
    />
  );
};

export default UserBubble;
