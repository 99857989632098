import { useCallback, useEffect } from 'react';
import { Tab as BootstrapTab, Tabs as BootstrapTabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAnalyticsContext } from 'screens/Analytics/store/AnalyticsContext';
import { analyticsInitialBreadCrumbPathList, AnalyticsTabEnum, TabRouteMap } from 'screens/Analytics/util';
import { setBreadcrumbPathList } from 'util/commonContext/NavigationContext';
import styles from './styles.module.css';
import AnalyticsTabComponentMap from './util/AnalyticsTabComponentMap';
import AnalyticsTabNameMap from './util/AnalyticsTabNameMap';
interface IAnalyticsTabs {
  selectedTabKey: string;
}
const keyIsPartOfTabEnum = (key) => Object.values(AnalyticsTabEnum).includes(key);

const Tabs = ({ selectedTabKey }: IAnalyticsTabs) => {
  const history = useHistory();

  const {
    dispatch,
    state: {
      analyticsNavigation: { breadcrumbPathList, actions },
    },
  } = useAnalyticsContext();

  // Functions and subroutines
  const updateBreadcrumbPathList = useCallback(
    (key) => {
      dispatch(
        setBreadcrumbPathList([
          ...analyticsInitialBreadCrumbPathList,
          { title: AnalyticsTabComponentMap[key], url: TabRouteMap[key] },
        ])
      );
    },

    [dispatch]
  );

  useEffect(() => {
    const titleIndex = breadcrumbPathList.findIndex(
      ({ title }) => title === AnalyticsTabComponentMap[selectedTabKey]
    );
    if (keyIsPartOfTabEnum(selectedTabKey) && titleIndex !== breadcrumbPathList.length - 1) {
      updateBreadcrumbPathList(selectedTabKey);
    }
  }, [selectedTabKey]);

  useEffect(() => {
    /**
     * This effect was made in order to
     * update the Breadcrumbs when the
     * user enters one of the tabs
     * with the url instead of the
     * interface.
     */
    if (
      keyIsPartOfTabEnum(selectedTabKey) &&
      breadcrumbPathList.length === analyticsInitialBreadCrumbPathList.length
    ) {
      updateBreadcrumbPathList(selectedTabKey);
    }
  }, []);

  const handleSelect = useCallback(
    (key) => {
      updateBreadcrumbPathList(key);
      history.push(TabRouteMap[key]);
    },
    [history, updateBreadcrumbPathList]
  );


  return (
    <BootstrapTabs
      id="patients-tab"
      activeKey={selectedTabKey}
      onSelect={handleSelect}
      className="tabs"
      mountOnEnter
    >
      {Object.keys(AnalyticsTabComponentMap).map((TabKey) => {
        const Component = AnalyticsTabComponentMap[TabKey];

        return (
          <BootstrapTab
            key={TabKey}
            tabClassName={selectedTabKey === TabKey ? 'tab-selected' : 'tab'}
            eventKey={TabKey}
            title={AnalyticsTabNameMap[TabKey]}
          >
            <div className={styles.tabContainer}>
              <Component />
            </div>
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
};

export default Tabs;
