import Bubble from 'components/Bubble';
import UserBubble from 'components/UserBubble';
import { FC } from 'react';
import { User } from 'types/ApiModels/Users';
import styles from './styles.module.css';

export type UserBubbleGroupUser = Partial<
  Pick<User, 'first_name' | 'last_name' | 'imageUrl' | 'username' | 'id'>
>;

interface UserBubbleGroupProps {
  users: UserBubbleGroupUser[];
  maxCount?: number;
}

const UserBubbleGroup: FC<UserBubbleGroupProps> = ({ users, maxCount }: UserBubbleGroupProps) => {
  const slice = maxCount ? users.slice(0, maxCount) : users;
  return (
    <div className={`d-flex ${styles['bubble-group-container']}`}>
      {slice.map((u, idx) => {
        return (
          <UserBubble
            user={u}
            key={u.first_name && u.last_name ? `${u.first_name}-${u.last_name}` : idx}
          />
        );
      })}
      {maxCount && users.length - maxCount > 0 ? (
        <Bubble text={`+ ${users.length - maxCount}`} />
      ) : null}
    </div>
  );
};
export default UserBubbleGroup;
