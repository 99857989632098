import { TextMessage } from 'components/Messages';
import { Collapse } from 'react-bootstrap';
import { Message } from 'types/Shared/Message';
import { formatDottedDateNoYear, timeAgo } from 'util/dateUtils';
import styles from './styles.module.css';
import { useCallback, useEffect, useState } from 'react';
import MessageCountBubble from './MessageCountBubble';
interface CollapseThreadProps {
  messages: Message[];
  date: string;
}
const CollapseThread = ({ messages, date }: CollapseThreadProps) => {
  const [showThread, setShowThread] = useState(false);
  const [showSubThread, setShowSubThread] = useState([]);
  useEffect(() => {
    messages.forEach(() => {
      setShowSubThread((prev) => [...prev, false]);
    });
  }, [messages, setShowSubThread]);

  const showSubThreadHandler = (index: number) => {
    setShowSubThread((prev) => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
  const MessageIcon = useCallback(
    () => (
      <MessageCountBubble
        count={messages.length}
        className={styles.messageCount}
        width={14}
        height={13}
      />
    ),
    [messages]
  );
  return (
    <div className={styles.container}>
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => setShowThread((prev) => !prev)}
      >
        <div className={styles.time}>
          <span>{formatDottedDateNoYear(new Date(date))}</span>&nbsp;•&nbsp;
          <span>{timeAgo(new Date(date))}</span>
        </div>
        <div className={styles.horizontalLineContainer}>
          <hr className={styles.horizontalLine} />
        </div>
        <MessageCountBubble count={messages.length} className={styles.messageCount} />
      </div>
      <Collapse in={showThread}>
        <div className={styles.messageContainer}>
          {messages.map((message, index) => (
            <div key={index}>
              <div
                onClick={message.subThread ? () => showSubThreadHandler(index) : () => null}
                className={`${(message.subThread && 'cursor-pointer') || ''}`}
              >
                <TextMessage
                  from={{
                    firstName: message.from.split(' ')[0].charAt(0),
                    lastName: message.from.split(' ')[1].charAt(0),
                  }}
                  message={{
                    data: message.text,
                    createdAt: message.created_at,
                  }}
                  messageClassName={styles.message}
                  className="d-flex"
                  timeStampClassName={styles.messageTime}
                  bubbleClassName={styles.bubble}
                  dataClassName={styles.data}
                  TimeItems={message.subThread ? MessageIcon : null}
                />
              </div>
              {message.subThread && (
                <Collapse in={showSubThread[index]}>
                  <div>
                    {message.subThread.messages.map((subMessage, index) => (
                      <TextMessage
                        key={index}
                        from={{
                          firstName: subMessage.from.split(' ')[0].charAt(0),
                          lastName: subMessage.from.split(' ')[1].charAt(0),
                        }}
                        message={{
                          data: subMessage.text,
                          createdAt: subMessage.created_at,
                        }}
                        messageClassName={styles.message}
                        className="d-flex mt-3 ml-2"
                        timeStampClassName={styles.messageTime}
                        bubbleClassName={styles.bubble}
                        dataClassName={styles.data}
                      />
                    ))}
                  </div>
                </Collapse>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};
export default CollapseThread;
