import {
  ActionEventTypeLabel,
  mapLabelToActionEventType,
} from 'components/RPM/utils/eventTypeMapper';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';

const getDraggedEventType: (
  draggableId: string,
  carePlanActivities: IPatientCarePlanAction[]
) => CarePlanActionEventType = (draggableId, carePlanActivities) => {
  if (draggableId.startsWith('actionButton') || draggableId.startsWith('actionsCardItem')) {
    const label = draggableId.split('-')[1];
    return mapLabelToActionEventType(label as ActionEventTypeLabel);
  } else {
    const draggedActionId = Number(draggableId);
    const action = carePlanActivities.find((a: IPatientCarePlanAction) => a.id === draggedActionId);
    return action?.event_type;
  }
};
export default getDraggedEventType;
