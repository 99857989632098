import DeleteIcon from 'assets/icons/delete.png';
import { TextInputFormik } from 'components/FormikComponents';
import GenericButton from 'components/GenericButton';
import ArrowDown from 'components/Icons/ArrowDown';
import DragDrop from 'components/Icons/DragDrop';
import { ErrorMessage, getIn, useFormikContext } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Collapse } from 'react-bootstrap';
import colors from 'styles/colors';
import CareTeamTier from 'types/ApiModels/CareTeam/CareTeamTier';
import { CareTeamCreateEditFormikFields } from '../../types';
import ProviderTierList from '../ProviderTierList';
import styles from '../styles.module.css';

interface CareTeamTierComponentProps {
  fieldName: string;
  draggableHandleProps: DraggableProvidedDragHandleProps;
  isEditing: boolean;
}

const CareTeamTierComponent: FC<CareTeamTierComponentProps> = ({
  fieldName,
  draggableHandleProps,
  isEditing,
}: CareTeamTierComponentProps) => {
  const [isOpen, setIsOpen] = useState(!isEditing);
  const { setFormikState, values, errors, touched } =
    useFormikContext<CareTeamCreateEditFormikFields>();

  const currentTier: CareTeamTier = useMemo(() => {
    return getIn(values, fieldName);
  }, [fieldName, values]);

  const handleDeleteTier = () => {
    setFormikState((fs) => {
      const newValue = fs.values.tiers
        .filter((t) => t.number !== currentTier.number)
        .map<CareTeamTier>((t, idx) => ({ ...t, number: idx + 1 }));
      return {
        ...fs,
        values: {
          ...fs.values,
          tiers: newValue,
        },
      };
    });
  };

  useEffect(() => {
    if (getIn(errors, `${fieldName}.providers`) && getIn(touched, `${fieldName}.providers`)) {
      setIsOpen(true);
    }
  }, [errors, touched, fieldName]);
  return (
    <>
      <div className={`${styles['flex-basis-priority']} text-center `}>
        <div
          className={`m-1 ${styles['item-container']} font-size-big font-weight-md d-flex gap align-items-center justify-content-center`}
          {...draggableHandleProps}
        >
          <DragDrop color={colors.lowRiskAlerts} height="10.25" width="6.25" />
          <div>{currentTier?.number}</div>
        </div>
      </div>
      <div className={`d-flex flex-column gap-sm flex-grow-1`}>
        <div className="m-1 bg-transparent">
          <div className={`d-flex flex-column gap w-100 ${styles['collapsable-header']}`}>
            <div className="d-flex align-items-center">
              <div
                className={`${styles['flex-basis-drag-drop']}`}
                onClick={() => {
                  setIsOpen((ppo) => !ppo);
                }}
              >
                <div
                  className={`${styles['arrow-container']} rounded d-flex justify-content-center align-items-center cursor-pointer`}
                >
                  <ArrowDown
                    width="9"
                    height="5"
                    color={colors.lowRiskAlerts}
                    style={{
                      transform: isOpen ? 'rotate(180deg)' : 'initial',
                      transition: 'transform 0.2s',
                    }}
                  />
                </div>
              </div>
              <div
                className={`flex-grow-1 font-size-big font-weight-md d-flex justify-content-between gap`}
              >
                <TextInputFormik
                  name={`${fieldName}.name`}
                  className="vytrac-white-input flex-grow-1"
                  placeholder="Tier name..."
                />
                <GenericButton
                  className="d-flex align-items-center"
                  icon={DeleteIcon}
                  alt="delete"
                  onClick={handleDeleteTier}
                />
              </div>
            </div>
            <ErrorMessage name={`${fieldName}.name`}>
              {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <Collapse in={isOpen}>
          <div className="bg-trasparent">
            <ProviderTierList fieldName={`${fieldName}`} />
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default CareTeamTierComponent;
