import { IPatientActionRecurrence } from 'types/ApiModels/Patients/CarePlan';

export const RecurrenceInitialState: IPatientActionRecurrence = {
  type: 'repeats',
  repeats_type: 'every_day',
  multiple_times_a_day: false,
  end_date_type: 'duration',
  end_date_duration_days: 1,
  end_date: new Date().toISOString(),
};
