import moment from 'moment';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';

export const getRelativeDate = (absoluteDate: string, blockStartDate: Date): IRelativeTime => {
  const absoluteMoment = moment(absoluteDate);
  const time = absoluteMoment.format('HH:mm').concat(':00');
  const day_of_week = absoluteMoment.day();

  const blockStartMoment = moment(blockStartDate);
  const dayDifference = absoluteMoment
    .startOf('day')
    .diff(blockStartMoment.startOf('week'), 'days');
  const week_number = Math.floor(dayDifference / 7) + 1;

  return {
    day_of_week,
    week_number,
    time,
  };
};
