import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import { AdminPopulationAction, AdminPopulationActionTypes } from './adminPopulationsActionTypes';

export const setAdminPopulations = (populations: PopulationWithCount[]): AdminPopulationAction => ({
  type: AdminPopulationActionTypes.SET_POPULATIONS,
  payload: { populations },
});

export const appendUpdatePopulation = (updatedValue: {
  id: number;
  value: boolean;
}): AdminPopulationAction => ({
  type: AdminPopulationActionTypes.APPEND_UPDATE_POPULATION,
  payload: { updatedValue },
});

export const appendDeletePopulation = (populationId: number): AdminPopulationAction => ({
  type: AdminPopulationActionTypes.APPEND_DELETE_POPULATION,
  payload: { populationId },
});

export const setResetPopulationChanges = (resetChanges: () => void): AdminPopulationAction => ({
  type: AdminPopulationActionTypes.SET_RESET_CHANGES,
  payload: { resetChanges },
});

export const setSubmitPopulationChanges = (submitChanges: () => void): AdminPopulationAction => ({
  type: AdminPopulationActionTypes.SET_SUBMIT_CHANGES,
  payload: { submitChanges },
});

export const clearPopulationChanges = (): AdminPopulationAction => ({
  type: AdminPopulationActionTypes.CLEAR_POPULATION_CHANGES,
});
