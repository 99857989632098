import React, { ReactNode, useEffect, useRef, useState } from 'react';

import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  ReactFlowProps,
  removeElements,
  useStoreActions,
  useStoreState,
} from 'react-flow-renderer';
import './overrideStyles.css';

interface VytracSurveyBuilderProps extends ReactFlowProps {
  questionNodeType: ReactNode;
  answerNodeType: ReactNode;
  elements: any[];
  setElements: React.Dispatch<React.SetStateAction<any[]>>;
  editable?: boolean;
  initialValues: { answers: any[]; questions: any[]; edges: any[] };
}

const VytracSurveyBuilder = ({
  questionNodeType,
  answerNodeType,
  elements = [],
  setElements = () => {},
  initialValues = { answers: [], questions: [], edges: [] },
  editable = true,
  ...props
}: VytracSurveyBuilderProps) => {
  const nodeTypes = useRef({
    question: questionNodeType,
    answer: answerNodeType,
  });

  const reactFlowWrapper = useRef(null);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const nodes = useStoreState((state) => state.nodes);

  const setSelected = useStoreActions((actions) => actions.setSelectedElements);
  //Function to select both answers and question by clicking either of them
  const selectQuestionAndAnswers = (event, element) => {
    if (element.type === 'question') {
      const answerNodes = nodes.filter(
        (node) => node.type === 'answer' && node.data.questionId === element.id
      );
      setSelected([...answerNodes, element]);
    } else if (element.type === 'answer') {
      const questionNode = nodes.find(
        (node) => node.type === 'question' && node.id === element.data.questionId
      );
      const answerNodes = nodes.filter(
        (node) => node.type === 'answer' && node.data.questionId === questionNode.id
      );
      setSelected([...answerNodes, questionNode]);
    }
  };

  //Function to set the reactFlow instance
  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);

  //Function to add question node on drop of drag and drop
  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    if (type && type === 'question') {
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      createQuestionNode(position);
    }
  };

  const onDragOver = (event) => {
    if (!updatingEdge.current) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    }
  };

  //Ref to element to prevent unwanted behavior on elements not being updated correctly
  const elementsRef = React.useRef(null);

  elementsRef.current = elements;

  //Id to be used for new elements
  const idCounter = React.useRef(0);

  //Node Height to calculate pos of new nodes
  const NODE_HEIGHT = 39;

  //Map to set z-index of nodes
  const zIndex = React.useRef({});

  //Updates position after dragging a node
  const onDrag = (element) => {
    const nodesToUpdate = nodes.filter(
      (n) => n.id === element.id || (n.type === 'answer' && n.data.questionId === element.id)
    );
    setElements((els) =>
      els.map((el) => {
        const nodeElement = nodesToUpdate.find((n) => n.id === el.id);
        if (nodeElement) {
          return { ...el, position: nodeElement.__rf.position };
        } else return el;
      })
    );
  };

  //Function to create a new edge
  const onConnect = (params) => {
    setElements((els) =>
      addEdge(
        {
          ...params,
          id: `e-${params.source}-${params.target}`,
          style: { strokeWidth: 3, stroke: '#CFD6E2' },
        },
        els
      )
    );
  };

  //Function to get new Id
  const getId = () => {
    const newId = idCounter.current;
    idCounter.current += 1;
    return `${newId}`;
  };

  //Function called when user presses backspace key
  const onElementsRemove = (elementsToRemove) => {
    return setElements((els) => removeElements(elementsToRemove, els));
  };

  //Removes question and all it's answers
  const removeQuestion = (questionId) => {
    setElements((els) =>
      removeElements(
        els.filter(
          (el) =>
            el.id === questionId || (el.type === 'answer' && el.data.questionId === questionId)
        ),
        els
      )
    );
  };

  //Adds a new Question node
  const createQuestionNode = (position, value = '', dbId = null) => {
    const id = dbId ? `question_${dbId}` : `question_${getId()}`;
    const nodeData = { id, value: value };
    zIndex.current[id] = 0;
    const newNode = {
      id,
      type: 'question',
      position,
      style: {
        zIndex: 0,
      },
      data: {
        editable: editable,
        question: nodeData,
        answers: 0,
        removeElement: () => removeQuestion(id),
        onChange: (value) =>
          setElements((els) =>
            els.map((el) =>
              el.id === id
                ? {
                    ...el,
                    data: {
                      ...el.data,
                      question: { ...el.data.question, value },
                    },
                  }
                : el
            )
          ),
        addAnswer: (position) => addAnswer(position, id),
      },
    };
    setElements((es) => es.concat(newNode));
  };

  //Util to calculate the position of the next node
  const calculateAnswerPosition = (numberOfAnswers, actualPosition) => {
    const x = actualPosition[0];
    const y = actualPosition[1];
    const newY = y + numberOfAnswers * (NODE_HEIGHT + 10);
    return [x, newY];
  };

  //Add answer to question
  const addAnswer = (questionPosition, questionId) => {
    const answersCount = elementsRef.current.find((e) => e.id === questionId).data.answers;
    const ansPos = calculateAnswerPosition(answersCount + 1, [
      questionPosition.x,
      questionPosition.y,
    ]);
    setElements((els) =>
      els.map((el) =>
        el.id === questionId ? { ...el, data: { ...el.data, answers: el.data.answers + 1 } } : el
      )
    );
    createAnswerNode({ x: ansPos[0], y: ansPos[1] }, questionId);
  };

  //Re-renders position of answers when an answer is removed
  const removeAnswer = (answerId) => {
    const questionId = elementsRef.current.find((e) => e.id === answerId).data.questionId;
    const question = elementsRef.current.find((e) => e.id === questionId);
    let answersCount = 1;
    setElements((els) =>
      removeElements(
        els.filter((e) => e.id === answerId),
        els
      ).map((el) => {
        if (el.type === 'answer' && el.data.questionId === question.id) {
          const newPos = calculateAnswerPosition(answersCount++, [
            question.position.x,
            question.position.y,
          ]);
          return {
            ...el,
            position: { x: newPos[0], y: newPos[1] },
          };
        } else if (el.id === question.id) {
          return {
            ...el,
            data: {
              ...el.data,
              answers: el.data.answers - 1,
            },
          };
        }
        return el;
      })
    );
  };
  //Adds answer node to graph below the question
  const createAnswerNode = (position, questionId, value = '', critical = false, dbId = null) => {
    const id = dbId ? `answer_${dbId}` : `answer_${getId()}`;
    const nodeData = { id, value: value };
    zIndex.current[questionId] = zIndex.current[questionId] - 1;
    const z = zIndex.current[questionId];
    const newNode = {
      id,
      type: 'answer',
      position,
      style: {
        zIndex: z,
      },
      data: {
        editable: editable,
        answer: nodeData,
        critical: critical,
        questionId: questionId,
        setCritical: (value) =>
          setElements((els) =>
            els.map((el) => (el.id === id ? { ...el, data: { ...el.data, critical: value } } : el))
          ),
        removeElement: () => removeAnswer(id),
        onChange: (value) =>
          setElements((els) =>
            els.map((el) =>
              el.id === id
                ? {
                    ...el,
                    data: {
                      ...el.data,
                      answer: { ...el.data.answer, value },
                    },
                  }
                : el
            )
          ),
      },
    };
    setElements((es) => es.concat(newNode));
  };

  //Quick hack to fix the issue where the flow got stuck when the user updated an edge
  const updatingEdge = useRef(false);

  const onEdgeUpdateStart = (edge) => {
    setElements((els) =>
      removeElements(
        [edge],
        els.map((el) => ({ ...el, data: { ...el.data, editable: false } }))
      )
    );
    updatingEdge.current = true;
  };

  const onEdgeUpdate = (edge, connection) => {
    setElements((els) =>
      addEdge(
        {
          ...connection,
          id: `e-${connection.source}-${connection.target}`,
          style: { strokeWidth: 3, stroke: '#CFD6E2' },
        },
        els.map((el) => ({ ...el, data: { ...el.data, editable: editable } }))
      )
    );
  };

  useEffect(() => {
    if (initialValues && initialValues.questions && initialValues.answers && initialValues.edges) {
      initialValues.questions.forEach((question) =>
        createQuestionNode(question.position, question.question, question.id)
      );
      initialValues.answers.forEach((answer) => {
        createAnswerNode(
          answer.position,
          `question_${answer.question_id}`,
          answer.value,
          answer.critical,
          answer.id
        );
        setElements((els) =>
          els.map((el) =>
            el.id === `question_${answer.question_id}`
              ? { ...el, data: { ...el.data, answers: el.data.answers + 1 } }
              : el
          )
        );
      });
      initialValues.edges.forEach((edge) => {
        onConnect({
          ...edge,
          source: `answer_${edge.source}`,
          target: `question_${edge.target}`,
          sourceHandle: null,
          targetHandle: null,
        });
      });
    }
    return () => setElements([]);
  }, [initialValues]);

  useEffect(() => {
    setElements((els) => els.map((el) => ({ ...el, data: { ...el.data, editable: editable } })));
  }, [editable]);

  return (
    <div className="w-100 h-100" ref={reactFlowWrapper}>
      <ReactFlow
        elements={elements}
        onConnect={onConnect}
        onElementsRemove={onElementsRemove}
        onLoad={onLoad}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodeTypes={nodeTypes.current}
        onElementClick={selectQuestionAndAnswers}
        onNodeDrag={selectQuestionAndAnswers}
        onNodeDragStop={(_, element) => onDrag(element)}
        onEdgeUpdate={editable ? onEdgeUpdate : null}
        onEdgeUpdateStart={editable ? (_, edge) => onEdgeUpdateStart(edge) : null}
        onEdgeUpdateEnd={
          editable
            ? () => {
                updatingEdge.current = false;
              }
            : null
        }
        connectionLineStyle={{ stroke: '#CFD6E2', strokeWidth: 3 }}
        nodesDraggable={editable}
        nodesConnectable={editable}
        {...props}
      >
        <Background variant={BackgroundVariant.Dots} />
      </ReactFlow>
    </div>
  );
};

export default VytracSurveyBuilder;
