import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const Warning: FC<SVGIconProps> = ({ color, height, width }: SVGIconProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 11.398a.939.939 0 00.94-.94V6.112a.939.939 0 10-1.879 0v4.333a.939.939 0 00.94.954zM9.964 14.612a1.083 1.083 0 100-2.167 1.083 1.083 0 000 2.167z"
        fill={color}
      />
      <path
        d="M18.906 14.446L11.872 1.489a2.166 2.166 0 00-3.806 0L1.024 14.446a2.166 2.166 0 001.907 3.22H17a2.167 2.167 0 001.906-3.199v-.021zm-1.285 1.4a.723.723 0 01-.621.355H2.93a.723.723 0 01-.635-1.07L9.329 2.176a.722.722 0 011.272 0l7.034 12.957a.722.722 0 01-.014.715z"
        fill={color}
      />
    </svg>
  );
};
export default Warning;
