import Button from 'components/Button';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import SearchBar from 'components/SearchBar';
import { ErrorMessage, useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { rpmActionTypeToString } from 'util/rpmActionTypeToString';
import { IModalBody } from '../ActionModalBody';
import styles from './styles.module.css';

const VytalRequestBody = ({ onCancel }: IModalBody) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [selectedVital, setSelectedVital] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const handleVitalSelect = useCallback(
    (vitalId) => {
      setFieldTouched('vital', true);
      setSelectedVital(vitalId);
      setFieldValue('vital', vitalId);
    },
    [setFieldValue, setFieldTouched]
  );

  const vitals = useMemo(() => {
    const vitalsList = Object.keys(rpmActionTypeToString).map((k) => ({
      key: k,
      name: rpmActionTypeToString[k],
    }));
    if (searchValue) {
      return vitalsList.filter((vital) =>
        vital.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return vitalsList;
  }, [searchValue]);
  return (
    <div>
      <SearchBar onChange={(e) => setSearchValue(e.target.value)} />
      <div className={styles['list']}>
        {vitals.length > 0 ? (
          vitals.map((vital) => {
            const isSelected = selectedVital === vital.key;
            return (
              <div
                key={vital.key}
                className={`d-flex gap justify-content-between ${styles['container']} cursor-pointer`}
                onClick={() => handleVitalSelect(vital.key)}
              >
                <div className="d-flex align-items-center">
                  <div
                    className={`${styles['custom-input']} ${isSelected ? styles.selected : ''}`}
                  ></div>
                  <input type="checkbox" hidden value={vital.key} checked={isSelected} readOnly />
                </div>
                <input type="checkbox" hidden value={vital.key} checked={isSelected} readOnly />
                <div className="flex-grow-1 ">{vital.name}</div>
              </div>
            );
          })
        ) : (
          <div className={styles['not-found-message']}>No vitals match your search</div>
        )}
      </div>
      <ErrorMessage
        name="vytal_type"
        render={(msg) => <div className={styles['error-message']}>{msg}</div>}
      />
      <div className={styles['question']}>
        <div>Why are you requesting this?</div>
        <TextAreaFormik name="note" placeholder={"I'm requesting this because..."} />
        <ErrorMessage
          name="note"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
      <div className={`${styles['question']} mb-2`}>
        <div>Note for the patient</div>
        <TextAreaFormik name="patient_note" placeholder={'Add a note for the patient...'} />
        <ErrorMessage
          name="patient_note"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
      <div className="d-flex justify-content-between w-100">
        <Button label="Cancel" onClick={onCancel} variant="cancel" />
        <Button label="Confirm" type="submit" variant="confirm" />
      </div>
    </div>
  );
};
export default VytalRequestBody;
