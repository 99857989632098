import { Reducer } from 'react';
import { DashboardAction, DashboardTypes } from './DashboardActionTypes';
import { DashboardState } from './DashboardContext';

const dashboardReducer: Reducer<DashboardState, DashboardAction> = (state, action) => {
  switch (action.type) {
    case DashboardTypes.ADD_TRACKER_TIME:
      const patientIdTimeMap = state.patientIdTimeMap;
      if (patientIdTimeMap.size && patientIdTimeMap.get(state.trackingPatientId)) {
        patientIdTimeMap.set(
          state.trackingPatientId,
          patientIdTimeMap.get(state.trackingPatientId) + 1
        );
      } else {
        patientIdTimeMap.set(state.trackingPatientId, 1);
      }
      return {
        ...state,
        trackingDuration: state.trackingDuration + 1000,
      };
    case DashboardTypes.TOGGLE_IS_TRACKING:
      return {
        ...state,
        timerIsTracking: action.payload.isTracking,
      };
    case DashboardTypes.CHANGE_SELECTED_TIMER:
      return {
        ...state,
        trackingPatientId: action.payload.patientId,
      };
    case DashboardTypes.SET_TRACKING_ACTIVITY_ID:
      return {
        ...state,
        trackingActivityId: action.payload.activityId,
      };
    case DashboardTypes.RESET_TRACKING_DURATION:
      return {
        ...state,
        trackingDuration: 0,
      };
    case DashboardTypes.SET_TRACKING_TYPE:
      return {
        ...state,
        trackingType: action.payload.trackingType,
      };
    case DashboardTypes.SET_TODO_ITEMS:
      return {
        ...state,
        toDoItems: action.payload.toDoItems,
      };
    case DashboardTypes.SET_TODO_ITEMS_SINGLE:
      return {
        ...state,
        toDoItemsSingle: action.payload.toDoItemsSingle,
      };
    case DashboardTypes.SET_RED_ALERTS:
      return {
        ...state,
        redAlerts: action.payload.redAlerts,
      };
    case DashboardTypes.SET_YELLOW_ALERTS:
      return {
        ...state,
        yellowAlerts: action.payload.yellowAlerts,
      };
    case DashboardTypes.SET_OUTSTANDING_CHATS:
      return {
        ...state,
        outstandingChats: action.payload.outstandingChats,
      };
    case DashboardTypes.SET_ADHERENCE:
      return {
        ...state,
        adherence: action.payload.adherence,
      };
    case DashboardTypes.SET_ADHERENCE_GRAPHIC:
      return {
        ...state,
        adherenceGraphic: action.payload.adherenceGraphic,
      };

    case DashboardTypes.SET_URGENCY:
      return {
        ...state,
        urgency: action.payload.urgency,
      };
    case DashboardTypes.SET_ESCALATED:
      return {
        ...state,
        escalated: action.payload.escalated,
      };
    case DashboardTypes.SET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload.schedule,
      };

    case DashboardTypes.SET_STICKYNOTES:
      return {
        ...state,
        stickynotes: action.payload.stickynotes,
      };
    case DashboardTypes.SET_SELECTED_RED_ALERT:
      return {
        ...state,
        selectedRedAlert: action.payload.selectedRedAlert,
      };
    case DashboardTypes.SET_SELECTED_YELLOW_ALERT:
      return {
        ...state,
        selectedYellowAlert: action.payload.selectedYellowAlert,
      };
    case DashboardTypes.SET_SELECTED_ADHERENCE:
      return {
        ...state,
        selectedAdherence: action.payload.selectedAdherence,
      };
    case DashboardTypes.SET_VYTAL_CHART:
      const newVytalsChart = { ...state.vytalsChart };
      newVytalsChart[`${action.payload.patientId}-${action.payload.vytalChart.type}`] =
        action.payload.vytalChart;
      return {
        ...state,
        vytalsChart: newVytalsChart,
      };
    case DashboardTypes.SET_TOTAL_PATIENTS:
      return {
        ...state,
        totalPatients: action.payload.totalPatients,
      };
    case DashboardTypes.ADD_SINGLE_TODO:
      const toDoSingle = action.payload.toDoSingle;
      const updatedToDoItemsSingle = { ...state.toDoItemsSingle };
      updatedToDoItemsSingle[toDoSingle.id] = toDoSingle;
      return { ...state, toDoItemsSingle: updatedToDoItemsSingle };

    case DashboardTypes.SET_SHOW_ACTION_MODAL:
      return {
        ...state,
        showActionModal: action.payload.showActionModal,
        actionModalType: action.payload.actionType,
        openedAlertId: action.payload.openedAlertId,
      };
    case DashboardTypes.SET_CURRENT_TODO:
      return {
        ...state,
        currentToDo: action.payload.currentTodo,
      };

    case DashboardTypes.SET_QUESTIONNAIRE_TEMPLATES:
      return {
        ...state,
        questionnaireTemplates: action.payload.questionnaireTemplates,
      };

    case DashboardTypes.SET_PATIENT_QUESTIONNAIRES:
      const newPatientQuestionnaires = { ...state.patientQuestionnaires };
      newPatientQuestionnaires[action.payload.patientId] = action.payload.patientQuestionnaires;

      return {
        ...state,
        patientQuestionnaires: newPatientQuestionnaires,
      };
    case DashboardTypes.SET_CURRENT_PROVIDER_CARE_TEAM:
      return {
        ...state,
        currentProviderCareTeam: action.payload.currentProviderCareTeam,
      };
  }
};

export default dashboardReducer;
