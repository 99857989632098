import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const TemperatureIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77273 0V6.69025C0.738636 7.4305 0 8.561 0 9.91667C0 12.1654 1.85841 14 4.13636 14C6.41432 14 8.27273 12.1654 8.27273 9.91667C8.27273 8.561 7.53409 7.4305 6.5 6.69025V5.83333H7.68182V4.66667H6.5V3.5H7.68182V2.33333H6.5V1.16667H7.68182V0H1.77273ZM10.9318 0C9.79136 0 8.86364 0.915833 8.86364 2.04167C8.86364 3.1675 9.79136 4.08333 10.9318 4.08333C12.0723 4.08333 13 3.1675 13 2.04167C13 0.915833 12.0723 0 10.9318 0ZM2.95455 1.16667H5.31818V7.23683L5.61364 7.40133C6.49764 7.90708 7.09091 8.83633 7.09091 9.91667C7.09091 11.5342 5.77495 12.8333 4.13636 12.8333C2.49777 12.8333 1.18182 11.5342 1.18182 9.91667C1.18182 8.83633 1.77509 7.90708 2.65909 7.40075L2.95455 7.23742V1.16667ZM10.9318 1.16667C11.4205 1.16667 11.8182 1.55925 11.8182 2.04167C11.8182 2.52408 11.4205 2.91667 10.9318 2.91667C10.4431 2.91667 10.0455 2.52408 10.0455 2.04167C10.0455 1.55925 10.4431 1.16667 10.9318 1.16667ZM3.54545 2.33333V8.27633C2.86 8.51783 2.36364 9.15892 2.36364 9.91783C2.37557 10.3741 2.56758 10.8078 2.89873 11.1264C3.22988 11.4449 3.67398 11.6232 4.13636 11.6232C4.59875 11.6232 5.04285 11.4449 5.374 11.1264C5.70515 10.8078 5.89715 10.3741 5.90909 9.91783C5.90909 9.15892 5.41273 8.51783 4.72727 8.27633V2.33333H3.54545Z"
        fill={color}
      />
    </svg>
  );
};

export default TemperatureIcon;
