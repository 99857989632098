import moment from 'moment';
import React from 'react';
import { Tagitem } from '../../../../../../../../../../../../../components/Tagitem';
import UserBubble from '../../../../../../../../../../../../../components/UserBubble';
import styles from './styles.module.css';

export default function MentionMessage({ message, from, className, timeStampClassName }) {
  return (
    <div className={className}>
      <div>
        <UserBubble user={from} />
      </div>
      <div className="d-flex flex-column">
        <div>
          <span className={styles.atDestination}>@{message.data.destination}</span>{' '}
          {message.data.message} <Tagitem className={styles.tagItem} text={message.tag} />
        </div>
        <span className={timeStampClassName}>{moment(message.createdAt).format('h:mm a')}</span>
      </div>
    </div>
  );
}
