import ListItem from 'components/ListItem';
import { useCallback, useState } from 'react';
import {
  setSelectedRedAlert,
  setTrackingType,
  toggleTimeIsTracking,
} from 'screens/ProviderDashboard/store/DashboardActionCreators';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import useAlertItemTracking from 'screens/ProviderDashboard/store/hooks/useAlertItemTracking';
import { getSingleRedAlert } from 'services/dashboardService';
import DashboardRedAlert from 'types/ApiModels/Dashboard/DashboardRedAlert';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import DatePickerValue from 'types/Shared/DatePicker';
import RedAlertPopup from './RedAlertPopup';
import styles from './styles.module.css';

interface RedAlertItemProps {
  redAlertSummary: DashboardRedAlert;
  dateFrom: DatePickerValue;
}
const RedAlertItem = ({ redAlertSummary, dateFrom }: RedAlertItemProps) => {
  const {
    dispatch,
    state: { timerIsTracking, trackingPatientId, patientIdTimeMap, trackingActivityId },
    fetchRegistry,
  } = useDashboardContext();

  const [showPopup, setShowPopup] = useState(false);

  useAlertItemTracking(redAlertSummary.id, TrackingType.ALERT, showPopup);

  const fetchSinglePatient = useCallback(
    async (redAlertId: number) => {
      let res;
      const dateFromString = `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`;
      if (fetchRegistry.current.redAlertDetail.list[`${redAlertId}-${dateFromString}`]) {
        res = fetchRegistry.current.redAlertDetail.list[`${redAlertId}-${dateFromString}`];
      } else {
        res = await getSingleRedAlert(redAlertId, dateFromString);
        fetchRegistry.current.redAlertDetail.list[`${redAlertId}-${dateFromString}`] = res;
      }
      dispatch(setSelectedRedAlert(res));
    },
    [dispatch, fetchRegistry, dateFrom]
  );

  const handleClosePopUp = useCallback(() => {
    setShowPopup(false);
    dispatch(setSelectedRedAlert(null));
  }, [setShowPopup, dispatch]);

  const handleTogglePopup = useCallback(() => {
    if (showPopup) {
      handleClosePopUp();
    } else {
      setShowPopup(true);
      fetchSinglePatient(redAlertSummary.id);
    }
  }, [showPopup, handleClosePopUp, fetchSinglePatient, redAlertSummary.id]);

  const stopwatchClick = useCallback(() => {
    dispatch(setTrackingType(TrackingType.ALERT));
    dispatch(toggleTimeIsTracking(!timerIsTracking));
  }, [dispatch, timerIsTracking]);

  return (
    <div>
      <div
        onClick={handleTogglePopup}
        className={`dashboard-profile-patient ${styles.itemContainer}`}
      >
        <ListItem
          user={{
            first_name: redAlertSummary.first_name,
            last_name: redAlertSummary.last_name,
            imageUrl: redAlertSummary.photo_thumbnail,
            sex: redAlertSummary.sex,
          }}
          patientId={redAlertSummary.id}
          showStopwach={showPopup}
          tracking={{
            timerIsTracking,
            patientId: trackingPatientId,
            elapsedTime: patientIdTimeMap.get(trackingPatientId),
            activityId: trackingActivityId,
            stopwatchClick,
          }}
        />
        <div>
          <span style={{ float: 'right', color: '#8FAAC3', whiteSpace: 'nowrap' }}>
            {redAlertSummary.alerts_count} alerts
          </span>
        </div>
      </div>
      {showPopup && <RedAlertPopup onClickOutSide={handleClosePopUp} />}
    </div>
  );
};

export default RedAlertItem;
