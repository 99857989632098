import DeleteIcon from 'assets/icons/delete.png';
import FormikArrayTable from 'components/FormikArrayTable';
import GenericButton from 'components/GenericButton';
import VytracModal from 'components/Modal';
import { Field, FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useAdministrationContext } from 'screens/Administration/store';
import { setTimeout } from 'timers/promises';
import RolePermissions, { RolePermissionOptions } from 'types/Roles/RolePermissions';
import GenericColumn from 'types/Shared/GenericColumn';
import CustomPermissionsModal from './CustomPermissionsModal';
import { sectionsByType } from './CustomPermissionsModal/util';
import ModalFooter from './ModalFooter';
import RolesSelect from './RolesSelect';
import styles from './styles.module.css';
const TableBody = ({ formikRef }) => {
  const { state, setState } = useAdministrationContext();
  const [open, setOpen] = useState(false);
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [modalType, setModalType] = useState('templates');
  const {
    handleChange,
    handleBlur,
    dirty,
    handleSubmit,
    handleReset,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<{ roles: RolePermissions[] }>();

  const arrayHelpersRef = useRef<FieldArrayRenderProps>(null);
  const [previousState, setPreviousState] = useState<RolePermissions>();
  const [index, setIndex] = useState<number>();
  const cancelChange = (type) => {
    setFieldValue(`roles[${index}][${type.toUpperCase()}]`, previousState[`${type.toUpperCase()}`]);
    Object.keys(sectionsByType[type]).forEach((section) => {
      setFieldValue(`roles[${index}][${section}]`, previousState[section]);
    });

    setOpenCustomModal(false);
  };
  const confirmChange = (type, index, newValues) => {
    Object.keys(newValues).forEach((key) => {
      setFieldValue(`roles[${index}].${key}`, newValues[key]);
    });
    setOpenCustomModal(false);
  };
  const columns: GenericColumn[] = [
    {
      title: 'ID',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      key: 'name',
      render: ({ name }) => (
        <Field
          className={`${styles.field} ${
            getIn(errors, name) && getIn(touched, name) ? styles.error : ''
          }`}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter Role name"
          style={{ backgroundColor: 'var(--light-gray)', maxWidth: '100%', paddingRight: 10 }}
        />
      ),
    },
    {
      title: 'Dashboard',
      key: 'DASHBOARD',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Patients',
      key: 'PATIENTS',
      render: ({ name, index }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={(e) => handleChange(e)}
              value={name}
              onBlur={handleBlur}
              selectProps={{
                ...field,
                onChange: (e) => {
                  setPreviousState(values.roles[index]);
                  field.onChange(e);
                },
                onClick: (e) => {
                  if (e.target.value === RolePermissionOptions.custom) {
                    if (values.roles[index]['PATIENTS'] === RolePermissionOptions.custom) {
                      setPreviousState(values.roles[index]);
                    }

                    setOpenCustomModal(true);
                    setModalType('patients');
                    setIndex(index);
                  } else {
                    Object.keys(sectionsByType['patients']).forEach((key) => {
                      values.roles[index][key] = RolePermissionOptions.none;
                    });
                  }
                },
              }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
              custom
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Patient Cases',
      key: 'PATIENT_CASES',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Schedule',
      key: 'SCHEDULE',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Appointments',
      key: 'APPOINTMENTS',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Messages',
      key: 'MESSAGES',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Templates',
      key: 'TEMPLATES',
      render: ({ name, index }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{
                ...field,
                onChange: (e) => {
                  setPreviousState(values.roles[index]);
                  field.onChange(e);
                },
                onClick: (e) => {
                  if (e.target.value === RolePermissionOptions.custom) {
                    if (values.roles[index]['TEMPLATES'] === RolePermissionOptions.custom) {
                      setPreviousState(values.roles[index]);
                    }
                    setOpenCustomModal(true);
                    setModalType('templates');
                    setIndex(index);
                  } else {
                    Object.keys(sectionsByType['templates']).forEach((key) => {
                      values.roles[index][key] = RolePermissionOptions.none;
                    });
                  }
                },
              }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
              custom
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Revenue Management',
      key: 'REVENUE_MANAGEMENT',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Administration',
      key: 'ADMINISTRATION',
      render: ({ name, index }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{
                ...field,
                onChange: (e) => {
                  setPreviousState(values.roles[index]);
                  field.onChange(e);
                },
                onClick: (e) => {
                  if (e.target.value === RolePermissionOptions.custom) {
                    if (values.roles[index]['ADMINISTRATION'] === RolePermissionOptions.custom) {
                      setPreviousState(values.roles[index]);
                    }
                    setOpenCustomModal(true);
                    setModalType('administration');
                    setIndex(index);
                  } else {
                    Object.keys(sectionsByType['administration']).forEach((key) => {
                      values.roles[index][key] = RolePermissionOptions.none;
                    });
                  }
                },
              }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
              custom
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Analytics',
      key: 'ANALYTICS',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Resources',
      key: 'RESOURCES',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={handleChange}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Support',
      key: 'SUPPORT',
      render: ({ name }) => (
        <Field name={name}>
          {({ field }) => (
            <RolesSelect
              onSelect={() => null}
              value={name}
              onBlur={handleBlur}
              selectProps={{ ...field }}
              selectClassName={`${styles.field} ${
                getIn(errors, name) && getIn(touched, name) ? styles.error : ''
              }`}
            />
          )}
        </Field>
      ),
    },
    {
      title: 'Status',
      key: 'active',
      textAlign: 'end',
      style: {
        backgroundColor: '#fff',
        paddingLeft: 0,
        marginRight: 0,
        paddingRight: '1rem',
        justifyContent: 'end',
      },
      headerClasses: 'justify-content-end',
      headerStyle: { paddingRight: 20 },
      render: ({ name, index, remove, readOnly }) => (
        <div className="d-flex bg-white">
          {!values.roles[index].id && (
            <GenericButton
              icon={DeleteIcon}
              alt="delete"
              className="mr-2"
              onClick={() => remove(index)}
            />
          )}
          <FormCheck
            type="switch"
            onChange={handleChange}
            checked={values.roles[index].active}
            name={name}
            id={name}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setState({
      ...state,
      roles: {
        ...state.roles,
        hasUnsavedChanges: dirty,
        submitForm: handleSubmit,
        resetForm: () => setOpen(true),
        addRow: () =>
          arrayHelpersRef.current?.push({
            name: '',
            DASHBOARD: '',
            PATIENTS: '',
            PATIENTS_patient_status: RolePermissionOptions.none,
            PATIENTS_patient_timeline: RolePermissionOptions.none,
            PATIENTS_team_notes: RolePermissionOptions.none,
            PATIENTS_care_plan: RolePermissionOptions.none,
            PATIENTS_patient_reports: RolePermissionOptions.none,
            PATIENTS_encounters: RolePermissionOptions.none,
            PATIENTS_goals_threshold: RolePermissionOptions.none,
            PATIENTS_questionnaires: RolePermissionOptions.none,
            PATIENTS_patient_management: RolePermissionOptions.none,
            PATIENT_CASES: '',
            SCHEDULE: '',
            APPOINTMENTS: '',
            MESSAGES: '',
            TEMPLATES: '',
            TEMPLATES_care_plan_templates: RolePermissionOptions.none,
            TEMPLATES_questionnaires: RolePermissionOptions.none,
            TEMPLATES_surveys: RolePermissionOptions.none,
            REVENUE_MANAGEMENT: '',
            ADMINISTRATION: '',
            ADMINISTRATION_dashboard: RolePermissionOptions.none,
            ADMINISTRATION_providers: RolePermissionOptions.none,
            ADMINISTRATION_roles: RolePermissionOptions.none,
            ADMINISTRATION_patients: RolePermissionOptions.none,
            ADMINISTRATION_patient_settings: RolePermissionOptions.none,
            ADMINISTRATION_forms: RolePermissionOptions.none,
            ADMINISTRATION_email_settings: RolePermissionOptions.none,
            ADMINISTRATION_account_settings: RolePermissionOptions.none,
            ADMINISTRATION_reports: RolePermissionOptions.none,
            ADMINISTRATION_rules_engine: RolePermissionOptions.none,
            ADMINISTRATION_messages: RolePermissionOptions.none,
            ADMINISTRATION_care_plan_templates: RolePermissionOptions.none,
            ADMINISTRATION_audit_tracking: RolePermissionOptions.none,
            ANALYTICS: '',
            RESOURCES: '',
            SUPPORT: '',
            active: true,
          }),
      },
    });
  }, [dirty, arrayHelpersRef]);

  return (
    <div>
      <FormikArrayTable name="roles" headers={columns} arrayHelpersRef={arrayHelpersRef} />
      <VytracModal
        title="Warning"
        body="Are you sure you want to clear all changes?"
        footer={
          <ModalFooter
            onCancel={() => setOpen(false)}
            onConfirm={() => {
              handleReset();
              setOpen(false);
            }}
          />
        }
        show={open}
        onClose={() => setOpen(false)}
        centered
      />
      <VytracModal
        body={
          <CustomPermissionsModal
            formRef={formikRef}
            type={modalType}
            onCancel={cancelChange}
            onConfirm={confirmChange}
            index={index}
          />
        }
        show={openCustomModal}
        backdrop={true}
        onClose={() => setOpenCustomModal(false)}
        centered
        size="sm"
      />
    </div>
  );
};
export default TableBody;
