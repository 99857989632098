import { ProviderActionType } from 'types/ApiModels/Providers/ProviderAction';
import * as Yup from 'yup';

export const validationSchema: Record<ProviderActionType, any> = {
  vytal_request: Yup.object().shape({
    vytal_type: Yup.string().required('Required'),
    note: Yup.string().required('Required'),
    patient_note: Yup.string().required('Required'),
  }),
  send_automated_chat: Yup.object().shape({
    note: Yup.number().required('Required'),
  }),
  request_appointment: Yup.object().shape({
    reasonMessage: Yup.string().required('Required'),
  }),
  provide_questionnaire: Yup.object()
    .shape({
      questionnaire_id: Yup.number().nullable(),
      note: Yup.string().required('Required'),
      patient_note: Yup.string().required('Required'),
    })
    .test('questionnaire', 'Questionnaire must have at least one question', (value) =>
      Object.keys(value).some(
        (key) =>
          value[key] !== null &&
          Object.keys(value)
            .filter((k) => k !== key)
            .every((k) => value[k] === null)
      )
    ),
  complete: Yup.object().shape({
    note: Yup.string().required('Required'),
  }),
  call_patient: {},
  assign: Yup.object().shape({
    assign_to: Yup.array().min(1, 'Please select a provider').required('Required'),
    note: Yup.string().required('Required'),
  }),
};
