import Select from 'components/form/Select';
import InputField from 'components/InputField';
import React, { useState } from 'react';
import {
  setCarePlanState,
  setHasUnsavedChanges,
} from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import NotAnAdminModal from '../../../../NotAnAdminModal';
import styles from './styles.module.css';
import { DurationLabel, mapDurationLabelToUnit, mapDurationUnitToLabel } from './utils';

const durationUnitOptions = Object.values(DurationLabel);

const TemplateData = () => {
  const {
    dispatch,
    templateState: {
      templateCarePlan: { carePlanState, userIsStaff },
    },
  } = useTemplateContext();

  const [showNotAnAdminModal, setShowNotAnAdminModal] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (carePlanState.creator_is_admin && !userIsStaff) {
      setShowNotAnAdminModal(true);
      return;
    }

    dispatch(setCarePlanState({ ...carePlanState, name: e.target.value }));
    dispatch(setHasUnsavedChanges(true));
  };

  const onDurationQuatity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (carePlanState.creator_is_admin && !userIsStaff) {
      setShowNotAnAdminModal(true);
      return;
    }
    dispatch(setCarePlanState({ ...carePlanState, duration_quantity: Number(e.target.value) }));
    dispatch(setHasUnsavedChanges(true));
  };

  const onDurationUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (carePlanState.creator_is_admin && !userIsStaff) {
      setShowNotAnAdminModal(true);
      return;
    }
    const unit = mapDurationLabelToUnit(e.target.value as DurationLabel);
    dispatch(setCarePlanState({ ...carePlanState, duration_type: unit }));
    dispatch(setHasUnsavedChanges(true));
  };

  return (
    <div className={styles.main}>
      <InputField
        value={carePlanState.name ?? ''}
        placeholder="Insert name..."
        onChange={onNameChange}
        label="Name"
        labelClassName={styles.fieldLabel}
        className={styles.fieldInput}
        containerClassName={styles.fieldContainer}
      />
      <div className="d-flex">
        <div className={styles.fieldLabel}>Duration</div>
        <InputField
          value={carePlanState.duration_type && carePlanState.duration_quantity}
          type="number"
          className={styles.fieldInput}
          onChange={onDurationQuatity}
          readOnly={carePlanState.duration_type === null}
        />
        <Select
          selectClassName={styles.durationSelect}
          options={durationUnitOptions}
          placeholder="Select Unit"
          currentValue={mapDurationUnitToLabel(carePlanState.duration_type)}
          onChange={onDurationUnitChange}
        />
      </div>

      <NotAnAdminModal show={showNotAnAdminModal} onConfirm={() => setShowNotAnAdminModal(false)} />
    </div>
  );
};
export default TemplateData;
