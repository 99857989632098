import moment from 'moment';
import React from 'react';
import UserBubble from '../UserBubble';

/**
 * @deprecated use TextMessageAlt instead
 */
export default function TextMessage({
  message,
  from,
  className = '',
  timeStampClassName = '',
  messageClassName = '',
  dataClassName = '',
  bubbleClassName = '',
  TimeItems = () => <></>,
}) {
  return (
    <div className={className}>
      <div className={bubbleClassName}>
        <UserBubble user={from} />
      </div>
      <div className={`d-flex ${messageClassName || 'flex-column'}`}>
        <span className={dataClassName}>{message.text || message.data}</span>
        {TimeItems && (
          <span>
            <TimeItems />
          </span>
        )}
        <span className={timeStampClassName}>{moment(message.createdAt).format('h:mm a')}</span>{' '}
      </div>
    </div>
  );
}
