import { Activity, ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import { getActionLabel, getAppointmentLabel } from '../../utils/getItemLabel';
import InstantActivityItem from './InstantActivityItem';
import ActionContent, { actionContentProps } from './InstantActivityItem/ActionContent';
import AppointmentContent, {
  appointmentContentProps,
} from './InstantActivityItem/AppointmentContent';

interface InstantActivityItemsProps {
  items: Activity[];
}
const InstantActivityItems = ({ items }: InstantActivityItemsProps) => {
  return (
    <div className={`d-flex flex-column gap-sm mt-2 px-1 overflow-hidden`}>
      {items &&
        items.map((item) => (
          <InstantActivityItem
            key={`instant-activity-item-${item.id}`}
            activityItemId={item.id}
            itemContainerProps={
              item.type === ActivityType.ACTION
                ? actionContentProps({
                    isCarePlan: item.action_props.is_part_of_rpm_plan,
                    isActive: item.action_props.is_active,
                  })
                : appointmentContentProps()
            }
          >
            {item.type === ActivityType.ACTION && (
              <ActionContent
                type={item.action_props.type}
                eventType={item.action_props.event_type}
                mainLabel={getActionLabel(item.action_props)}
                questionnaireId={item.action_props.questionnaire}
                isActive={item.action_props.is_active}
              />
            )}
            {item.type === ActivityType.APPOINTMENT && (
              <AppointmentContent
                type={item.appointment_props.appointment_type}
                mainLabel={getAppointmentLabel(item.appointment_props)}
              />
            )}
          </InstantActivityItem>
        ))}
    </div>
  );
};
export default InstantActivityItems;
