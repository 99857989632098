import React, { createContext, useContext } from 'react';
import { Activity } from 'types/Patients/PatientTimeline/ActivityTimeline';
import DatePickerValue from 'types/Shared/DatePicker';

interface IActivityTimelineState {
  activities: Activity[];
  currentSelectedDate: DatePickerValue;
  setCurrentSelectedDate: (newValue: DatePickerValue) => void;
  clickable: boolean;
  onActivityClick: (e: React.MouseEvent, id: number) => void;
  draggable: boolean;
  carePlanFrom?: Date;
}

const ActivityTimelineContext = createContext<IActivityTimelineState>({
  activities: [],
  currentSelectedDate: null,
  setCurrentSelectedDate: () => null,
  clickable: false,
  onActivityClick: () => null,
  draggable: false,
  carePlanFrom: null,
});

export const useActivityTimelineContext = () => {
  return useContext(ActivityTimelineContext);
};

export default ActivityTimelineContext;
