import { PaginatedResponse } from 'types/ApiModels/General';

type Identifiable = {
  id: number;
};

export const findInBook = <T extends Identifiable>(
  book: Record<number, PaginatedResponse<T>>,
  id: number
): [page: number, result: T, idx: number] => {
  let page,
    result,
    idx = null;
  if (!book) return [page, result, idx];
  for (const [key, value] of Object.entries(book)) {
    const index = value.results.findIndex((p) => p.id === id);
    if (index !== -1) {
      page = key;
      result = value.results[index];
      idx = index;
      break;
    }
  }
  return [page, result, idx];
};

export const deleteFromBook = <T extends Identifiable>(
  book: Record<number, PaginatedResponse<T>>,
  page,
  id: number
) => {
  const bookCopy = { ...book };
  const pageCopy = { ...bookCopy[page] };
  pageCopy.results = pageCopy.results.filter((p) => p.id !== id);
  bookCopy[page] = pageCopy;
  return bookCopy;
};

export const updateInBook = <T extends Identifiable>(
  book: Record<number, PaginatedResponse<T>>,
  page: number,
  id: number,
  updatedValue: T
) => {
  const bookCopy = { ...book };
  const pageCopy = { ...bookCopy[page] };
  const recordIndex = pageCopy.results.findIndex((r) => r.id === id);
  const resultsCopy = [...pageCopy.results];
  resultsCopy[recordIndex] = updatedValue;
  pageCopy.results = resultsCopy;
  bookCopy[page] = pageCopy;

  return bookCopy;
};
