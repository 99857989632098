// const tabEnum = {
//   PATIENT_STATUS: 'patient-status',
//   PATIENT_TIMELINE: 'patient-timeline',
//   TEAM_NOTES: 'team-notes',
//   CARE_PLAN: 'care_plan',
//   PATIENT_MESSAGES: 'patient-messages',
//   PATIENT_REPORTS: 'patient-reports',
//   ENCOUNTERS: 'encounters',
//   GOALS_THRESHOLD: 'goals-threshold',
//   QUESTIONNAIRES: 'questionnaires',
//   PATIENT_MANAGEMENT: 'patient-management',
// };

enum tabEnum {
  PATIENT_STATUS = 'patient-status',
  PATIENT_TIMELINE = 'patient-timeline',
  TEAM_NOTES = 'team-notes',
  CARE_PLAN = 'care_plan',
  PATIENT_MESSAGES = 'patient-messages',
  PATIENT_REPORTS = 'patient-reports',
  ENCOUNTERS = 'encounters',
  GOALS_THRESHOLD = 'goals-threshold',
  QUESTIONNAIRES = 'questionnaires',
  PATIENT_MANAGEMENT = 'patient-management',
}

export const tabOptions: Record<tabEnum, { isCareTeamRestricted?: boolean }> = {
  'goals-threshold': {},
  'patient-management': {},
  'patient-messages': { isCareTeamRestricted: true },
  'patient-reports': {},
  'patient-status': {},
  'patient-timeline': {},
  'team-notes': { isCareTeamRestricted: true },
  care_plan: {},
  encounters: { isCareTeamRestricted: true },
  questionnaires: {},
};

export default tabEnum;
