import React from 'react';
import styles from './styles.module.css';

export default function Section({ title, value = null, children = null }) {
  return (
    <div className={styles.section}>
      <div className={styles.title}>{title}</div>
      {children ? children : <div className={styles.value}>{value}</div>}
    </div>
  );
}
