export enum loginViews {
  login = 'login',
  token = 'token',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
}

export interface LoginFormValues {
  email: string;
  password: string;
  timezone: string;
  rememberMe: boolean;
}
