import {
  CareChampionsFormikKeys,
  EmergencyContactsFormikKeys,
  IdentificationFormikKeys,
  PatientManagementFormikKeys,
  PersonalInformationFormikKeys,
} from 'components/PatientManagementForm/types';
import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { phoneNumberRegex, zipCodeRegex } from 'util/regexs';
import * as Yup from 'yup';
import 'yup-phone';

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const emailSchema = Yup.string().matches(emailRegex, { message: 'Invalid email' });

const phoneNumberSchema = Yup.string().matches(
  phoneNumberRegex,
  'Phone number must be in the format XXX-XXX-XXXX'
);

//TODO: since there are some endpoints that are not yet ready we'll not require everything just yet.
const PatientManagementValidationSchema = Yup.object().shape({
  [PatientManagementFormikKeys.IDENTIFICATION]: Yup.object().shape({
    [IdentificationFormikKeys.FIRST_NAME]: Yup.string().required(REQUIRED_MESSAGE),
    [IdentificationFormikKeys.LAST_NAME]: Yup.string().required(REQUIRED_MESSAGE),
    [IdentificationFormikKeys.MIDDLE_NAME]: Yup.string(),
    [IdentificationFormikKeys.BIRTHDATE]: Yup.date().required(REQUIRED_MESSAGE),
    [IdentificationFormikKeys.SEX]: Yup.string().required(REQUIRED_MESSAGE),
  }),
  [PatientManagementFormikKeys.POPULATION]: Yup.array().of(Yup.number()),
  [PatientManagementFormikKeys.CARE_CHAMPIONS]: Yup.array().of(
    Yup.object().shape({
      [CareChampionsFormikKeys.RELATIONSHIP]: Yup.string().nullable().required(REQUIRED_MESSAGE),
      [CareChampionsFormikKeys.FULL_NAME]: Yup.string().nullable().required(REQUIRED_MESSAGE),
      [CareChampionsFormikKeys.PERSONAL_PHONE_NUMBER]: phoneNumberSchema
        .nullable()
        .required(REQUIRED_MESSAGE),
      [CareChampionsFormikKeys.SECONDARY_PHONE_NUMBER]: phoneNumberSchema,
      [CareChampionsFormikKeys.EMAIL]: emailSchema,
    })
  ),
  [PatientManagementFormikKeys.PERSONAL_INFORMATION]: Yup.object().shape({
    [PersonalInformationFormikKeys.CONTACT_INFORMATION]: Yup.object().shape({
      email: emailSchema.required(REQUIRED_MESSAGE),
      primaryPhoneNumber: phoneNumberSchema.required(REQUIRED_MESSAGE),
      secondaryPhoneNumber: phoneNumberSchema,
    }),
    [PersonalInformationFormikKeys.ADDRESS]: Yup.array().of(
      Yup.object().shape({
        address: Yup.string().required(REQUIRED_MESSAGE),
        apt: Yup.string(),
        city: Yup.string().nullable().required(REQUIRED_MESSAGE),
        state: Yup.string().required(REQUIRED_MESSAGE),
        zipCode: Yup.string().matches(zipCodeRegex, 'Invalid zip code').required(REQUIRED_MESSAGE),
      })
    ),
    [PersonalInformationFormikKeys.INSURANCE]: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        insuranceId: Yup.string().nullable().required(REQUIRED_MESSAGE),
        subscriberNumber: Yup.string().required(REQUIRED_MESSAGE),
        groupNumber: Yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    [PersonalInformationFormikKeys.DEMOGRAPHIC_INFO]: Yup.object().shape({
      gender: Yup.string(),
      maritalStatus: Yup.string(),
      race: Yup.string(),
      ethnicity: Yup.string(),
      language: Yup.array().of(Yup.number()).required(REQUIRED_MESSAGE).min(1, 'Pick at least one'),
      religion: Yup.string(),
    }),
  }),
  [PatientManagementFormikKeys.EMERGENCY_CONTACTS]: Yup.array().of(
    Yup.object().shape({
      [EmergencyContactsFormikKeys.FIRST_NAME]: Yup.string().required(REQUIRED_MESSAGE),
      [EmergencyContactsFormikKeys.LAST_NAME]: Yup.string().required(REQUIRED_MESSAGE),
      [EmergencyContactsFormikKeys.RELATIONSHIP]: Yup.string(),
      [EmergencyContactsFormikKeys.CONTACT_EMAIL]: emailSchema,
      [EmergencyContactsFormikKeys.CONTACT_PHONE]: phoneNumberSchema.required(REQUIRED_MESSAGE),
    })
  ),
  [PatientManagementFormikKeys.HEALTH_SCORING]: Yup.object().shape({
    missed_calls_red_alert: Yup.number().nullable(),
    missed_calls_yellow_alert: Yup.number().nullable(),
  }),
});

export default PatientManagementValidationSchema;
