import { UmbralType, IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { VytalResponse } from 'types/ApiModels/Patients/Vytal';
import { dataPointType } from 'types/VytalChart/dataPoint';
import { getChartType, isDoubleThreshold, nullWrap } from 'util/goalChart';
import { DragImages } from '.';

export const generateDatasets = (goalThreshold: IGoalThreshold, dataPoints: VytalResponse[]) => {
  const getUmbral = (type: UmbralType) =>
    goalThreshold.threshold_umbrals.find((umbral) => umbral.type === type);

  const chartType = getChartType(goalThreshold.type);

  let newDataset: {
    label: string;
    fill: boolean;
    data: dataPointType[];
    pointStyle?: HTMLImageElement;
    dragData?: boolean;
    backgroundColor?: string;
    pointBorderColor?: string[];
  }[] = [
    {
      label: UmbralType.YELLOW_ZONE_LOWER,
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      data: [getUmbral(UmbralType.YELLOW_ZONE_LOWER)?.max_value],
      pointStyle: DragImages.YELLOW,
    },
    {
      label: UmbralType.RED_ZONE_LOWER,
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      data: [getUmbral(UmbralType.RED_ZONE_LOWER)?.max_value],
      pointStyle: DragImages.RED,
    },
    {
      label: UmbralType.CRITICAL_ZONE_LOWER,
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      data: [getUmbral(UmbralType.RED_ZONE_LOWER)?.min_value],
      pointStyle: DragImages.RED,
    },
  ];
  if (isDoubleThreshold(goalThreshold.type)) {
    newDataset = [
      ...newDataset,
      {
        label: UmbralType.CRITICAL_ZONE_UPPER,
        fill: true,
        backgroundColor: 'rgb(255, 99, 132)',
        data: [getUmbral(UmbralType.RED_ZONE_UPPER)?.max_value],
        pointStyle: DragImages.RED,
      },
      {
        label: UmbralType.RED_ZONE_UPPER,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        data: [getUmbral(UmbralType.RED_ZONE_UPPER)?.min_value],
        pointStyle: DragImages.RED,
      },
      {
        label: UmbralType.YELLOW_ZONE_UPPER,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        data: [getUmbral(UmbralType.YELLOW_ZONE_UPPER)?.min_value],
        pointStyle: DragImages.YELLOW,
      },
    ];
  }

  if (chartType === 'line') {
    newDataset = [
      ...newDataset,
      {
        label: 'DataPoints',
        fill: false,
        data: nullWrap(dataPoints.map((dataPoint) => Number(dataPoint?.vytal?.value)).reverse()),
        dragData: false,
      },
    ];
  } else if (chartType === 'double-line') {
    newDataset = [
      ...newDataset,
      {
        label: 'TopDataPoints',
        fill: false,
        data: nullWrap(dataPoints.map((dataPoint) => Number(dataPoint?.vytal?.value)).reverse()),
        dragData: false,
      },
      {
        label: 'BottomDataPoints',
        fill: false,
        data: nullWrap(dataPoints.map((dataPoint) => Number(dataPoint?.vytal?.value_2)).reverse()),
        dragData: false,
      },
    ];
  }
  return newDataset;
};
