import { setBreadcrumTitle } from 'util/themeUtils';

export const initialBreadcrumbPathList = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'Appointments',
    url: '/appointments',
  },
];
