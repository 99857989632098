import { FC, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { uniqueId } from 'react-bootstrap-typeahead/types/utils';
import GenericColumn from 'types/Shared/GenericColumn';
import Checkbox from '../form/Checkbox';
import styles from './styles.module.css';

interface GenericTableProps {
  columns: GenericColumn[];
  data: any[];
  tableClassName?: string;
  onRowClick?: (row) => void;
  rowClassName?: string;
  specificRowClassName?: string;
  specificRows?: number[];
  selectable?: boolean;
  selected?: any[];
  headerRowClassName?: string;
  fallbackMessage?: string;
}

const GenericTable: FC<GenericTableProps> = ({
  tableClassName,
  columns = [],
  data = [],
  onRowClick = () => null,
  rowClassName,
  specificRowClassName,
  specificRows = [],
  selectable = false,
  selected = [],
  headerRowClassName,
  fallbackMessage = 'No data available',
}: GenericTableProps) => (
  <Table className={`${styles.genericTable} ${tableClassName || ''}`} borderless size="sm">
    <thead>
      <tr className={headerRowClassName}>
        {selectable && (
          <th className={styles.select}>
            <Checkbox
              value={selected.length === data.length}
              onChange={() => (selected = [...data])}
            />
          </th>
        )}
        {columns.map((column, index) => (
          <Fragment key={index}>
            {!column.hidden && (
              <th
                className={`font-size-small ${column.headerClasses || ''}`}
                style={{ ...column.headerStyle, textAlign: column.textAlign }}
              >
                {column.title}
              </th>
            )}
          </Fragment>
        ))}
      </tr>
    </thead>
    <tbody>
      {data && data.length > 0 ? (
        data.map((row, i) => (
          <tr
            key={`data_${i}`}
            className={`${rowClassName} ${specificRows.includes(i) && specificRowClassName}`}
            onClick={() => onRowClick(row)}
          >
            {selectable && (
              <td className={styles.select}>
                <Checkbox
                  value={selected.length === data.length}
                  onChange={() => (selected = [...selected, row])}
                />
              </td>
            )}
            {columns.map((column) => {
              return (
                <Fragment key={uniqueId(`data_${i}_${column.key}`)}>
                  {!column.hidden && (
                    <td
                      className={`font-size-medium ${column.tdClassName}`}
                      style={{
                        ...column.style,
                        textAlign: column.cellTextAlign,
                      }}
                    >
                      {column.render ? column.render({ ...row, idx: i }) : row[column.key]}
                    </td>
                  )}
                </Fragment>
              );
            })}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={columns.length + (selectable ? 1 : 0)}>
            <div className="text-center">
              <span className="font-size-medium">{fallbackMessage}</span>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default GenericTable;
