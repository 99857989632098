import BreadcrumbsWithActions from 'components/BreadcrumbsWithActions';
import { useDialogContext } from 'components/VyTracLayout/store';
import { useAdministrationContext } from '../store';

const NavigationHeader = () => {
  const { breadcrumbPathList, actions: Actions } = useAdministrationContext();
  const { dialog: DialogComponent } = useDialogContext();

  return (
    <>
      <BreadcrumbsWithActions breadcrumbs={breadcrumbPathList}>
        {Actions && <Actions />}
      </BreadcrumbsWithActions>
      {DialogComponent ? <DialogComponent /> : null}
    </>
  );
};

export default NavigationHeader;
