import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
import { setBreadcrumTitle } from 'util/themeUtils';
import AdminTabEnum from './AdminTabEnum';
import TabRouteMap from './TabRouteMap';

export const administrationInitialBreadCrumbPathList: BreadCrumbPath[] = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'administration',
    url: TabRouteMap[AdminTabEnum.DASHBOARD],
  },
];
