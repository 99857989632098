import Header from './Header';
import Body from './Body';

const PatientDocuments = ({ patient }) => (
  <div className="dashboard-detail">
    <div className="dashboard-item-header">
      <Header />
    </div>
    <div>
      <Body patient={patient} />
    </div>
  </div>
);

export default PatientDocuments;
