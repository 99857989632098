import { navigationReducer } from 'util/commonContext/NavigationContext';
import { NavigationAction, NavigationActionTypes } from 'util/commonContext/NavigationContext';
import {
  TemplateCarePlanAction,
  TemplateCarePlanActionTypes,
} from './templateCarePlan/templateCarePlanActionType';
import templateCarePlanReducer from './templateCarePlan/templateCarePlanReducer';
import { TemplateState } from './templateContext';

export type AllTemplateActions = NavigationAction | TemplateCarePlanAction;

enum TemplateActionSubType {
  Navigation = 'Navigation',
  TemplateCarePlan = 'TemplateCarePlan',
}

const getSubType = (action: AllTemplateActions) => {
  if (action.type in NavigationActionTypes) return TemplateActionSubType.Navigation;
  if (action.type in TemplateCarePlanActionTypes) return TemplateActionSubType.TemplateCarePlan;
};

const templateReducer = (state: TemplateState, action: AllTemplateActions): TemplateState => {
  const subType = getSubType(action);
  switch (subType) {
    case TemplateActionSubType.Navigation: {
      return {
        ...state,
        templateNavigation: navigationReducer(state.templateNavigation, action as NavigationAction),
      };
    }
    case TemplateActionSubType.TemplateCarePlan: {
      return {
        ...state,
        templateCarePlan: templateCarePlanReducer(
          state.templateCarePlan,
          action as TemplateCarePlanAction
        ),
      };
    }
    default:
      return state;
  }
};

export default templateReducer;
