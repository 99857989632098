import React from 'react';

const TabButton = ({type, label, className, setTabType}) => {
  return (
    <button onClick={() => setTabType(type)} className={className}>
      {label}
    </button>
  );
};

export default TabButton;
