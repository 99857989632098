import ArrowDownTodayDatePicker from 'components/ArrowDownTodayDatePicker';
import PatientProfileRing from 'components/indicators/GaugeIndicator';
import { Dispatch, SetStateAction } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import styles from './styles.module.css';
interface HeaderProps {
  date: DatePickerValue;
  setDate: Dispatch<SetStateAction<DatePickerValue>>;
  outstandingChatPatients: number;
  totalPatients: number;
}
const Header = ({ date, setDate, outstandingChatPatients, totalPatients }: HeaderProps) => {
  return (
    <div className="dashboard-item-header" style={{ textTransform: 'capitalize' }}>
      Outstanding Chats
      <div
        className="date-selector"
        style={{ marginLeft: 15, marginTop: -2 }}
        onClick={(e) => e.stopPropagation()}
      >
        <ArrowDownTodayDatePicker
          value={date}
          onChange={(date) => setDate(date)}
          inputClassName={styles.datePicker}
          overrideWrapperClassName={styles.datePickerWrapper}
        />
      </div>
      <div style={{ flex: 1, paddingRight: 40 }}>
        <div style={{ float: 'right' }}>
          <PatientProfileRing
            key={outstandingChatPatients}
            color="#785992"
            percentage={(outstandingChatPatients / totalPatients) * 100}
            text={`${outstandingChatPatients}`}
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
