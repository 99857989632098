import { useEffect, useMemo, useRef, useState } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import { addSingleToDo, setCurrentToDo, setToDoItems } from '../DashboardActionCreators';
import { useDashboardContext } from '../DashboardContext';
import { getPatientToDoList, getSinglePatientToDo } from 'services/dashboardService';
import { IDashboardToDo } from 'types/ApiModels/Dashboard/DashboardToDo';
import { PaginatedResponse } from 'types/ApiModels/General';

const useDashboardToDo = (date: DatePickerValue, currentToDoIndex: number) => {
  const {
    state: { toDoItems, toDoItemsSingle },
    dispatch,
  } = useDashboardContext();
  const [paginatedToDoList, setPaginatedToDoList] = useState<
    Partial<PaginatedResponse<IDashboardToDo>>
  >({
    count: 0,
    extra: {
      total_critical_alerts: 0,
      total_red_alerts: 0,
      total_yellow_alerts: 0,
      total_normal_readings: 0,
    },
  });

  const [loadingToDos, setLoadingToDos] = useState<boolean>(false);
  const previousDate = useRef<DatePickerValue>(date);

  const selectedSingleTodo = useMemo(
    () => (toDoItemsSingle ? toDoItemsSingle[toDoItems[currentToDoIndex].id] : null),
    [currentToDoIndex, toDoItems, toDoItemsSingle]
  );

  // Fetch ToDo list
  useEffect(() => {
    if (
      previousDate.current.year != date.year ||
      previousDate.current.month != date.month ||
      previousDate.current.day != date.day ||
      !toDoItems
    ) {
      const fetchToDoList = async () => {
        setLoadingToDos(true);
        try {
          const response = await getPatientToDoList(`${date.year}-${date.month}-${date.day}`);
          setPaginatedToDoList(response);
          dispatch(setToDoItems(response.results));
          previousDate.current = { ...date };
        } catch (exception) {
          throw exception;
        }
        setLoadingToDos(false);
      };

      fetchToDoList();
    }
  }, [toDoItems, date, dispatch]);

  // Fetch single ToDo
  useEffect(() => {
    if (!toDoItems?.length) return;

    const fetchSinglePatientToDo = async (patientId: number) => {
      setLoadingToDos(true);
      try {
        const patientToDo = await getSinglePatientToDo(
          patientId,
          `${date.year}-${date.month}-${date.day}`
        );
        dispatch(addSingleToDo(patientToDo));
        dispatch(setCurrentToDo(patientToDo));
      } catch (exception) {
        throw exception;
      }
      setLoadingToDos(false);
    };

    if (
      selectedSingleTodo &&
      selectedSingleTodo.alerts.length === toDoItems[currentToDoIndex].alerts_count
    ) {
      dispatch(setCurrentToDo(toDoItemsSingle[toDoItems[currentToDoIndex].id]));
    } else {
      fetchSinglePatientToDo(toDoItems[currentToDoIndex].id);
    }
  }, [currentToDoIndex, toDoItems, toDoItemsSingle, dispatch, date]);

  return { loadingToDos, paginatedToDoList };
};

export default useDashboardToDo;
