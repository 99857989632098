import VytracSpinner from 'components/vytrac-spinner';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import ActionButtons from 'screens/ProviderDashboard/ActionButtons';
import PatientActions from 'screens/ProviderDashboard/PatientActions';
import Populations from 'screens/ProviderDashboard/Populations';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import styles from './styles.module.css';
interface AdherencePopupProps {
  onClickOutSide?: () => void;
}
const AdherencePopup = ({ onClickOutSide }: AdherencePopupProps) => {
  const ref = useRef(null);
  const {
    state: { selectedAdherence: adherence },
  } = useDashboardContext();
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      event.stopPropagation();
      if (ref && ref.current && !ref.current.contains(event.target)) {
        onClickOutSide?.();
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
      ref.current = null;
    };
  }, [ref, onClickOutSide]);
  return (
    <>
      {adherence ? (
        <div className={styles['popup']} ref={ref}>
          <Populations
            birth_date={'2022-01-01'}
            phone_number={adherence.phone_number}
            email={adherence.email}
            populations={adherence.populations}
            user_id={adherence.id}
          />
          {/* <ActionButtons /> */}
          <div className={`${styles['container']}`}>
            <PatientActions
              alertId={-1 /* TODO: CHANGE TO adherence.alert_id when Endpoint is updated*/}
              sendAutomatedChat
              adherenceCall
              alertCareChampion
              requestTelehealthAppointment
              assign
              complete
            />
          </div>
          {/* <LastAction
        type={patient.lastAction}
        status={patient.lastActionStatus}
        actions={patient.lastActionActions}
      /> */}
        </div>
      ) : (
        <div className={`${styles['popup']} justify-content-center d-flex`} ref={ref}>
          <VytracSpinner />
        </div>
      )}
    </>
  );
};
export default AdherencePopup;
