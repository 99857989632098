import tabEnum from './tabEnum';

export const patientListRoute = 'patients';

export const baseTabRouteResolver = (patientId: string, route: string) =>
  `/${patientListRoute}/${patientId}/${route}`;

const tabRouteResolverMap = {
  [tabEnum.ENCOUNTERS]: (patientId: string) => baseTabRouteResolver(patientId, tabEnum.ENCOUNTERS),
  [tabEnum.GOALS_THRESHOLD]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.GOALS_THRESHOLD),
  [tabEnum.PATIENT_MANAGEMENT]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.PATIENT_MANAGEMENT),
  [tabEnum.PATIENT_MESSAGES]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.PATIENT_MESSAGES),
  [tabEnum.PATIENT_REPORTS]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.PATIENT_REPORTS),
  [tabEnum.PATIENT_STATUS]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.PATIENT_STATUS),
  [tabEnum.PATIENT_TIMELINE]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.PATIENT_TIMELINE),
  [tabEnum.QUESTIONNAIRES]: (patientId: string) =>
    baseTabRouteResolver(patientId, tabEnum.QUESTIONNAIRES),
  [tabEnum.CARE_PLAN]: (patientId: string) => baseTabRouteResolver(patientId, tabEnum.CARE_PLAN),
  [tabEnum.TEAM_NOTES]: (patientId: string) => baseTabRouteResolver(patientId, tabEnum.TEAM_NOTES),
};

export default tabRouteResolverMap;
