import { Switch, useRouteMatch, Route, useParams } from 'react-router-dom';
import { AdminTabEnum } from '../util';
import Tabs from './AdminTabs';

const Content = () => {
  const match = useRouteMatch();

  const DynamicTabs = () => {
    const { tab } = useParams<{ tab: string }>();

    return <Tabs selectedTabKey={tab} />;
  };

  return (
    <Switch>
      <Route path={match.path} exact>
        <Tabs selectedTabKey={AdminTabEnum.DASHBOARD} />
      </Route>
      <Route path={`${match.path}/:tab`}>
        <DynamicTabs />
      </Route>
    </Switch>
  );
};

export default Content;
