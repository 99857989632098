import { AdministrationAction, AdministrationTypes } from './administrationActionTypes';

// export const showPatientInviteModal = (): AdministrationActions => ({
//   type: AdministrationTypes.SHOW_PATIENT_INVITE_MODAL,
// });

// export const hidePatientInviteModal = (): AdministrationActions => ({
//   type: AdministrationTypes.HIDE_PATIENT_INVITE_MODAL,
// });

export const clearFormErrors = (): AdministrationAction => ({
  type: AdministrationTypes.CLEAR_FORM_ERROR,
});

export const setFormErrors = (error: string): AdministrationAction => ({
  type: AdministrationTypes.SET_FORM_ERROR,
  payload: { error },
});
