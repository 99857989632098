import React, { CSSProperties } from 'react';

export const communicationColor = {
  active: '#1890FF',
  inactive: '#CFD6E2',
};

export interface ICommunicationIcon extends React.SVGProps<SVGSVGElement> {
  width: string;
  height: string;
  isActive?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}
