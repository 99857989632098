import { DragUpdate } from 'react-beautiful-dnd';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';

/**
 * Adds prop to action depending on item dragged
 * @param action Patient care plan action created
 * @param modalEventType
 * @param dragUpdate dragged item info
 * @returns same action with prop added
 */
export const addPropToAction = (
  action: IPatientCarePlanAction,
  modalEventType: CarePlanActionEventType,
  dragUpdate: DragUpdate
): IPatientCarePlanAction => {
  if (modalEventType === 'questionnaire') {
    return { ...action, questionnaire: Number(dragUpdate.draggableId.split('-')[2]) };
  }
};
