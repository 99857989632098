import { DateRangeActivity } from 'types/Patients/PatientTimeline/ActivityTimeline';
import FillerRangeActivityItem from '../../FillerRangeActivityItem';

export function getFillerArray(idx: number, dai: DateRangeActivity, arr: DateRangeActivity[]) {
  const filler: JSX.Element[] = [];
  let diff = 0;

  const addFillerToArray = () => {
    for (let i = 1; i < diff; i++) {
      filler.push(<FillerRangeActivityItem key={`filler-range-${dai.id}-${idx}-col-${i}`} />);
    }
  };

  let previousIdx = idx - 1;
  let currentRenderRow = dai.renderRow;

  if (idx === 0 && dai.renderRow > 0) {
    diff = dai.renderRow + 1;
    addFillerToArray();
  }

  while (previousIdx >= 0 && currentRenderRow - 1 != arr[previousIdx].renderRow) {
    diff = currentRenderRow - arr[previousIdx].renderRow;
    addFillerToArray();
    currentRenderRow = arr[previousIdx].renderRow;
    previousIdx--;
  }
  return filler;
}
