import { AlertPopulation } from 'types/ApiModels/Dashboard';
import { DashboardUrgency } from 'types/ApiModels/Dashboard/DashboardUrgency';
import { IAlertPopulation, IUrgency } from 'types/Dashboard/Urgency';

export const mapToPopulations = (population: AlertPopulation): IAlertPopulation => ({
  id: population.id,
  name: population.name,
  isActive: population.is_active,
});

export const mapUrgencyToList = (apiUrgency: DashboardUrgency): IUrgency => {
  return {
    id: apiUrgency.id,
    score: apiUrgency.score,
    alertsSummary: apiUrgency.alerts_summary,
    adherence: apiUrgency.adherence,
    avatarStatus: apiUrgency.avatar_status,
    notes: apiUrgency.notes,
    satisfaction: apiUrgency.satisfaction,
    careTeam: apiUrgency.care_team,
    user: apiUrgency.user,
    populations: apiUrgency.populations.map(mapToPopulations),
    isActive: apiUrgency.is_active,
    preferredContactMethod: apiUrgency.preferred_contact_method,
  };
};
