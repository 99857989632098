import { Appointment, AppointmentType } from 'types/ApiModels/Appointments/Appointment';

export const initialAppointment: Appointment = {
  deleted: false,
  appointment_from: new Date().toISOString(),
  appointment_to: new Date().toISOString(),
  appointment_type: AppointmentType.VIDEO,
  detail: null,
  is_completed: false,
  patient_id: null,
  provider_id: null,
  type: 'checkup',
};
