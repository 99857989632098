import { Dispatch, useEffect } from 'react';
//mocks
import { mockBroadcastTemplateData } from 'screens/messages/mocks/data';
import { setBroadcastTemplates } from '../messages-action-creators';
import { MessagesAction } from '../messages-action-types';
import { MessagesState, useMessagesContext } from '../messages-context';

const useBroadcastTemplates = (): [
  state: MessagesState,
  // paginatedRequest: PaginatedRequestHook<MockChatData>,
  dispatch: Dispatch<MessagesAction>
] => {
  const { state, dispatch } = useMessagesContext();

  // const paginatedRequest = usePaginatedRequest('patient/', 10, state.chats);

  useEffect(() => {
    dispatch(setBroadcastTemplates(mockBroadcastTemplateData));
  }, [dispatch /*paginatedRequest.book*/]);

  return [state, /*paginatedRequest,*/ dispatch];
};

export default useBroadcastTemplates;
