import { YellowAlert } from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { iconType } from 'types/Patients/constants';
import { ConsecutiveYellowAlerts } from '../../YellowAlertPopup';
import AlertEvent from './AlertEvent';
import styles from './styles.module.css';
interface AlertsHoverProps {
  show: boolean;
  alerts: YellowAlert[];
}
const AlertsHover = ({ show, alerts }: AlertsHoverProps) => {
  return (
    <div style={{ display: !show && 'none' }} className={styles['popup']}>
      {alerts.slice(0, 3).map((alert, index) => (
        <AlertEvent key={index} alert={alert} />
      ))}
    </div>
  );
};
export default AlertsHover;
