import { Col, Row } from "react-bootstrap";
import ImgDoctor1 from "assets/images/resources/resource_doctors.png"
import ImgDoctor2 from "assets/images/resources/resource_doctors_2.png"
import ImgDoctor3 from "assets/images/resources/resource_doctors_3.png"
import ImgDoctor4 from "assets/images/resources/resource_doctors_4.png"
import Item from "./components/Item";

const images = [ImgDoctor1,ImgDoctor2,ImgDoctor3,ImgDoctor4]

const Header = () => {
  return (
    <div>
      <Row>
        <Col className="" lg={3}>
          <Item img={`${ImgDoctor1}`}></Item>
        </Col>
        <Col className="" lg={3}>
          <Item img={ImgDoctor2}></Item>
        </Col>
        <Col className="" lg={3}>
          <Item img={ImgDoctor3}></Item>
        </Col>
        <Col className="" lg={3}>
          <Item img={ImgDoctor4}></Item>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
