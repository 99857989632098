import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateQuestionnaire from './components/CreateQuestionnaire';
import PatientQuestionnaires from './components/PatientQuestionnaires';
import QuestionnaireDetails from './components/QuestionnaireDetails';
import QuestionnaireContext from './util/Context';
import routeEnum from './util/routeEnum';
import routeMapEnum from './util/routeMapEnum';

const Questionnaires = () => {
  const { path } = useRouteMatch();

  const [questionnaireState, setQuestionnaireState] = React.useState({
    questionnaireList: [],
    availableQuestionnaires: [],
    templateQuestionnaires: [],
    selectedQuestionnaire: null,
    createNew: true,
  });
  const context = {
    questionnaireState,
    setQuestionnaireState,
  };

  console.log(questionnaireState.questionnaireList);

  return (
    <QuestionnaireContext.Provider value={context}>
      <Switch>
        <Route path={'/patients/:patientId/:tab'} exact>
          <PatientQuestionnaires />
        </Route>
        <Route path={'/patients/:patientId/:tab/create'} exact>
          <CreateQuestionnaire />
        </Route>
        <Route path={'/patients/:patientId/:tab/:id'} exact>
          <QuestionnaireDetails />
        </Route>
      </Switch>
    </QuestionnaireContext.Provider>
  );
};

export default Questionnaires;
