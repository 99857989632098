import PaginationState from 'types/Shared/Pagination';

const initialPaginationState: PaginationState<string> = {
  currentPageIndex: 0,
  sortKey: '',
  maxPage: 0,
  maxCount: 0,
  isLoading: false,
};

export default initialPaginationState;
