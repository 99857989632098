import {createContext, useContext} from 'react';

const initialSchedulesContext = {
  schedules: [],
  currentDateRange: null,
  dispatch: () => {},
};

const SchedulesContext = createContext(initialSchedulesContext);

export default SchedulesContext;

export const useSchedulesContext = () => {
  return useContext(SchedulesContext);
};
