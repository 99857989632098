import { FC } from 'react';
import { ProviderSignup } from 'types/ApiModels/Administration/Dashboard';
import ProviderSignupCard from './ProviderSignupCard';

interface ProviderSignupsProps {
  providerSignups: ProviderSignup[];
}

const ProviderSignups: FC<ProviderSignupsProps> = ({ providerSignups }: ProviderSignupsProps) => {
  return (
    <div className="d-flex flex-column gap-sm">
      {providerSignups.map((ps, idx) => (
        <ProviderSignupCard providerSignup={ps} key={idx} />
      ))}
    </div>
  );
};
export default ProviderSignups;
