import React from 'react';
import Header from './components/Header';
import AppointmentsTimeline from './components/AppointmentsTimeline';
import styles from '../../styles.module.css';

const Calendar = () => {
  return (
    <div className={`dashboard-detail ${styles.shadow}`}>
      <div className="dashboard-item-header py-0">
        <Header />
      </div>
      <AppointmentsTimeline />
    </div>
  );
};

export default Calendar;
