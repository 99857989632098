import Button from 'components/Button';
import { string } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

export interface IComment {
  time: string;
  name: string;
  text: string;
  unreadComment?: boolean;
}

interface ICardComment {
  cardComment: IComment;
  showActions?: boolean;
}

const CardComment = ({ cardComment, showActions = false }: ICardComment) => {
  return (
    <div style={{ borderBottom: '1px solid #EFEFF0' }}>
      <div
        className={`d-flex align-items-top justify-content-between ${styles.commentContainer}`}
        style={cardComment.unreadComment ? { fontWeight: 'bold' } : {}}
      >
        <div className={styles.commentInfo}>{cardComment.time}</div>
        <div className={styles.commentInfo}>{cardComment.name}</div>
        <div className="flex-grow-1">{cardComment.text}</div>
      </div>
      {showActions && (
        <div className={`d-flex gap ${styles.actionContainer}`}>
          <Button label="call patient" className={styles.actionButton} />
          <Button label="Escalate" className={styles.actionButton} />
          <Button label="completed" variant="confirm" className={styles.actionButton} />
        </div>
      )}
    </div>
  );
};
export default CardComment;
