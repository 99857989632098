import moment, { Moment } from 'moment';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { getNextRecurrentDate } from './getNextRecurrentDate';
import { getRecurrenceEndDate } from './getRecurrenceEndDate';
import { updateDurationDays } from './updateDurationDays';

/**
 * Creates an new action with recurrence starting from the next repetition.
 * Returns null if the recurrence ends before the next action
 * @param blockStartMoment
 * @param originalAction
 * @returns New first action of the recurrence
 */
export const generateFutureRecurrence = (
  blockStartMoment: Moment,
  originalAction: IPatientCarePlanAction
): IPatientCarePlanAction => {
  const recurrenceEndDate = getRecurrenceEndDate(originalAction, null, blockStartMoment.toDate());

  const newActionStartMoment = getNextRecurrentDate(
    moment(originalAction.start_date),
    originalAction,
    blockStartMoment
  );

  if (moment(recurrenceEndDate).isSameOrBefore(newActionStartMoment)) {
    return null;
  }

  const newAction: IPatientCarePlanAction = {
    ...originalAction,
    start_date: newActionStartMoment.toISOString(),
    id: null,
  };

  if (originalAction.recurrence.end_date_type === 'duration') {
    const updatedRecurrence = updateDurationDays(newActionStartMoment, originalAction);
    return { ...newAction, recurrence: updatedRecurrence };
  }
};
