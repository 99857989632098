import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { useDashboardContext } from '../DashboardContext';
import { Dispatch, useCallback, useEffect } from 'react';
import { setQuestionnaireTemplates } from '../DashboardActionCreators';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { DashboardAction } from '../DashboardActionTypes';
const useDashboardQuestionnaireTemplates = (): [
  PaginatedRequestHook<IQuestionnaire>,
  Dispatch<DashboardAction>
] => {
  const {
    state: { questionnaireTemplates },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const paginationRequest = usePaginatedRequest(
    'questionnaire/',
    100,
    questionnaireTemplates,
    false
  );

  const questionnaireTemplatesInit = useCallback(async () => {
    fetchRegistry.current.questionnaireTemplates.list = true;
    await paginationRequest.init();
    fetchRegistry.current.questionnaireTemplates.list = false;
  }, [fetchRegistry, paginationRequest]);

  useEffect(() => {
    if (!questionnaireTemplates && !fetchRegistry.current.questionnaireTemplates.list) {
      questionnaireTemplatesInit();
    }
  }, [fetchRegistry, questionnaireTemplates, questionnaireTemplatesInit]);

  useEffect(() => {
    dispatch(setQuestionnaireTemplates(paginationRequest.book));
  }, [dispatch, paginationRequest.book, paginationRequest.extra]);

  return [paginationRequest, dispatch];
};
export default useDashboardQuestionnaireTemplates;
