import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import { FC } from 'react';
import { getNameInitials } from 'util/userUtils';

interface CareTeamNameCellProps {
  name: string;
  teamLeadName: string;
  membersNames: string[];
}

const CareTeamNameCell: FC<CareTeamNameCellProps> = ({
  name,
  teamLeadName,
  membersNames,
}: CareTeamNameCellProps) => {
  const initials = [
    getNameInitials(teamLeadName),
    ...membersNames.slice(0, 3).map(getNameInitials),
  ];
  return (
    <div className="w-100">
      <div className="d-flex gap align-items-center">
        <UserBubbleGroupCollapsed initials={initials} />
        <div>{name}</div>
      </div>
    </div>
  );
};
export default CareTeamNameCell;
