import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const DragDrop: FC<SVGIconProps> = ({ color, height, width }: SVGIconProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.125 2.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zm4 0a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM6.25 5.125a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zM1.125 6.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM6.25 9.125a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zM1.125 10.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
        fill={color}
      />
    </svg>
  );
};
export default DragDrop;
