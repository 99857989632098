import Breadcrumbs from 'components/Breadcrumbs';
import VyTracLayout from 'components/VyTracLayout';
import { setBreadcrumTitle } from 'util/themeUtils';
import Content from './components/Content/content';
import styles from './styles.module.css';

const Resources = () => {
  const breadcrumbs = [
    {
      title: `${setBreadcrumTitle()} Dashboard`,
      url: '/dashboard',
    },
    {
      title: 'Resources',
      url: '/resources',
    },
  ];
  return (
    <VyTracLayout selectedRoute="resources">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-grow-1">
          <Breadcrumbs items={breadcrumbs} />
        </div>
      </div>
      <hr className={styles.horizontalLine} />
      <div className="py-4">
        <Content/>
      </div>
    </VyTracLayout>
  );
};

export default Resources;
