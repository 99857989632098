import {
  IWebSocketAdherenceAlertMessage,
  IWebSocketQuestionnaireAlertMessage,
  IWebSocketVytalAlertMessage,
} from 'types/WebSocket/WebSocket';

export enum WebSocketAlertActionTypes {
  RECEIVE_RED_ALERT = 'RECEIVE_RED_ALERT',
  RECEIVE_YELLOW_ALERT = 'RECEIVE_YELLOW_ALERT',
  RECEIVE_CRITICAL_ALERT = 'RECEIVE_CRITICAL_ALERT',
  RECEIVE_ADHERENCE_ALERT = 'RECEIVE_ADHERENCE_ALERT',
  RECEIVE_QUESTIONNAIRE_ALERT = 'RECEIVE_QUESTIONNAIRE_ALERT',
  EMPTY_RED_ALERT = 'EMPTY_RED_ALERT',
  EMPTY_YELLOW_ALERT = 'EMPTY_YELLOW_ALERT',
  EMPTY_CRITICAL_ALERT = 'EMPTY_CRITICAL_ALERT',
  EMPTY_ADHERENCE_ALERT = 'EMPTY_ADHERENCE_ALERT',
  EMPTY_QUESTIONNAIRE_ALERT = 'EMPTY_QUESTIONNAIRE_ALERT',
}

export type WebSocketAlertAction =
  | {
      type: WebSocketAlertActionTypes.RECEIVE_RED_ALERT;
      payload: { alert: IWebSocketVytalAlertMessage };
    }
  | {
      type: WebSocketAlertActionTypes.RECEIVE_YELLOW_ALERT;
      payload: { alert: IWebSocketVytalAlertMessage };
    }
  | {
      type: WebSocketAlertActionTypes.RECEIVE_CRITICAL_ALERT;
      payload: { alert: IWebSocketVytalAlertMessage };
    }
  | {
      type: WebSocketAlertActionTypes.RECEIVE_ADHERENCE_ALERT;
      payload: { alert: IWebSocketAdherenceAlertMessage };
    }
  | {
      type: WebSocketAlertActionTypes.RECEIVE_QUESTIONNAIRE_ALERT;
      payload: { alert: IWebSocketQuestionnaireAlertMessage };
    };
