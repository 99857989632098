import FiltersButton from 'components/FiltersButton';
import styles from './styles.module.css';
const Header = ({ title, onFiltersClick, className = '' }) => {
  return (
    <div className={`d-flex justify-content-between align-items-center ${className || ''}`}>
      <div className={styles.title}>{title}</div>
      <FiltersButton className="rounded bg-white" onFiltersClick={onFiltersClick} />
    </div>
  );
};
export default Header;
