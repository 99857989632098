import { useFormikContext } from 'formik';
import { FC } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { CareTeamCreateEditFormikFields } from '../../types';
import CareTeamTierComponent from '../CareTeamTierComponent';

interface CareTeamTiersDnDProps {
  isEditing: boolean;
}
const CareTeamTiersDnD: FC<CareTeamTiersDnDProps> = ({ isEditing }: CareTeamTiersDnDProps) => {
  const { values, setFieldValue } = useFormikContext<CareTeamCreateEditFormikFields>();

  const handleDragEnd = (result: DropResult) => {
    const sourceIdx = result.source.index;
    const destIdx = result.destination.index;

    //tiers can only be reordered from number 2 onwards. (so it would from idx 1 onwards)
    const MODIFIABLE_OFFSET = 1;

    if (sourceIdx === destIdx) return;

    const isOutOfBoundary = (idx: number) =>
      sourceIdx > destIdx ? idx < destIdx || idx > sourceIdx : idx > destIdx || idx < sourceIdx;
    const offsetOnReorder = sourceIdx > destIdx ? 1 : -1;

    const newTiers = values.tiers.map((t, idx) => {
      if (idx === 0) return t;
      return {
        ...t,
        number: isOutOfBoundary(idx)
          ? t.number
          : idx === sourceIdx
          ? destIdx + MODIFIABLE_OFFSET
          : t.number + offsetOnReorder,
      };
    });

    newTiers.sort((a, b) => (a.number > b.number ? 1 : -1));
    setFieldValue('tiers', newTiers);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="care-team-tiers">
        {(droppableProvided) => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
            {values.tiers.map((t, idx) => {
              if (idx === 0) return null;
              return (
                <Draggable key={t.id} draggableId={t.id.toString()} index={idx}>
                  {(draggableProvided) => (
                    <div
                      className={`d-flex align-items-start`}
                      key={`tier-${t.number}`}
                      {...draggableProvided.draggableProps}
                      ref={draggableProvided.innerRef}
                    >
                      <CareTeamTierComponent
                        draggableHandleProps={draggableProvided.dragHandleProps}
                        fieldName={`tiers[${idx}]`}
                        isEditing={isEditing}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default CareTeamTiersDnD;
