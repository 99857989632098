import { ActionEventTypeLabel } from 'components/RPM/utils/eventTypeMapper';

export const templateCarePlanStartDate: Date = new Date(2022, 4, 1);

export enum ActionCardType {
  QUESTIONNAIRE = 'Questionnaire',
  MEDICATION = 'Medication',
  RESOURCES = 'Resources',
}

export const mapLabelToActionCardType = (label: string): ActionCardType => {
  switch (label) {
    case 'Questionnaire': {
      return ActionCardType.QUESTIONNAIRE;
    }

    case 'Medications': {
      return ActionCardType.MEDICATION;
    }

    case 'Resources': {
      return ActionCardType.RESOURCES;
    }

    default: {
      return null;
    }
  }
};

export const mapCardTypeToEventTypeLabel = (value: ActionCardType): ActionEventTypeLabel => {
  switch (value) {
    case ActionCardType.QUESTIONNAIRE: {
      return ActionEventTypeLabel.QUESTIONNAIRE;
    }

    case ActionCardType.MEDICATION: {
      return ActionEventTypeLabel.MEDICATIONS;
    }

    default: {
      console.log('resources not defined');
      return null;
    }
  }
};

export { addPropToAction } from './addPropToAction';
