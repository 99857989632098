import styles from './styles.module.css';
import { ReactComponent as Arrow } from 'assets/icons/arrow_group.svg';
import { ReactElement, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useFormikContext } from 'formik';
interface DropdownMenuProps {
  name: string;
  children: ReactElement<any, string>;
  open?: boolean;
  nameClassName?: string;
  containerClassName?: string;
  openedClassName?: string;
  nameStyle?: React.CSSProperties;
}
const DropdownMenu = ({
  name,
  children,
  open: externOpen,
  nameClassName,
  containerClassName,
  openedClassName,
  nameStyle,
}: DropdownMenuProps) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (externOpen) {
      setOpen(true);
    }
  }, [externOpen]);
  return (
    <div>
      <div
        className={`${styles['container']} ${containerClassName || ''} ${
          (open && openedClassName) || ''
        }`}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div style={nameStyle} className={nameClassName}>
          {name}
        </div>
        <Arrow style={{ transform: !open && 'rotate(-180deg)' }} className={styles['arrow']} />
      </div>
      <Collapse in={open}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};
export default DropdownMenu;
