import ListItem from 'components/ListItem';
import VytracModal from 'components/Modal';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdminCareTeams } from 'screens/Administration/store/hooks';
import { getCareTeamPatients } from 'services/administrationService';
import { CareTeamPatient } from 'types/ApiModels/CareTeam';
import styles from '../styles.module.css';

interface CareTeamPatientModalProps {
  onClose: () => void;
  show: boolean;
}

const CareTeamPatientModal = ({ onClose, show }: CareTeamPatientModalProps) => {
  const [patientsInCareTeam, setPatientsInCareTeam] = useState<CareTeamPatient[]>();

  const [{ currentCareTeamId }] = useAdminCareTeams();

  const fetchPatientsByCareTeam = async (careTeamId?: number) => {
    try {
      const patients = await getCareTeamPatients(careTeamId);
      setPatientsInCareTeam(patients.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!currentCareTeamId) return;
    fetchPatientsByCareTeam(currentCareTeamId);
  }, [currentCareTeamId]);

  const history = useHistory();

  return (
    <VytracModal
      backdrop={false}
      title={`Patients assigned to this team`}
      headerClassName="font-size-big font-weight-md m-0"
      body={
        <div className={`d-flex flex-column gap ${styles['modal-content-container']}`}>
          {patientsInCareTeam?.length ? (
            patientsInCareTeam?.map((ps) => {
              const [first_name, last_name] = ps.full_name.split(' ');
              return (
                <div
                  className={`d-flex justify-content-between rounded ${styles['modal-content-item']}`}
                  key={ps.id}
                >
                  <ListItem
                    user={{
                      first_name: first_name ?? '?',
                      last_name: last_name ?? '?',
                      username: '',
                      imageUrl: ps.photo_thumbnail,
                    }}
                    onClick={() => history.push(`/administration/patients/${ps.id}`)}
                  />
                  <div className={` font-size-medium ${styles['modal-content-alert-count']}`}>
                    {ps.alerts_count} alerts
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center font-italic">No patients assigned on this team</div>
          )}
        </div>
      }
      footer={undefined}
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default CareTeamPatientModal;
