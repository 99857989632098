import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import { FormikState } from 'formik';
import {
  PatientManagementAction,
  PatientManagementActionTypes,
} from './patientManagementActionTypes';

export const setSubmitPatientForm = (submitForm: () => void): PatientManagementAction => ({
  type: PatientManagementActionTypes.SET_SUBMIT_PATIENT_FORM,
  payload: {
    submitForm,
  },
});

export const setResetPatientForm = (
  resetForm: (nextState: Partial<FormikState<PatientManagementFormikValues>>) => void
): PatientManagementAction => ({
  type: PatientManagementActionTypes.SET_RESET_PATIENT_FORM,
  payload: {
    resetForm,
  },
});

export const setFormDirty = (dirty: boolean): PatientManagementAction => ({
  type: PatientManagementActionTypes.SET_FORM_DIRTY,
  payload: {
    dirty,
  },
});

//--AppendCreator
