import React, { FC, MutableRefObject, useEffect } from 'react';
import Card from 'components/Card';
import GoalLineChart from './components/GoalLineChart';
import Header from './components/Header';
import styles from './styles.module.css';
import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { useVytalsQuery } from 'screens/Patients/store/hooks/use-vytals-query';
import { usePatientFormikContext } from 'screens/Patients/store';
import { Chart } from 'chart.js';

interface IGoalCard {
  goalThreshold: IGoalThreshold;
  className?: string;
  chartRef?: MutableRefObject<Chart<'line'>>;
}

const GoalCard: FC<IGoalCard> = ({ goalThreshold, className, chartRef, children }) => {
  const { selectedPatient } = usePatientFormikContext();
  const { data: dataPoints } = useVytalsQuery(selectedPatient.id, goalThreshold?.type);

  //TimeSpan to display on the chart
  const [timeSpan, setTimeSpan] = React.useState(10);

  const [, updateState] = React.useState<unknown>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    forceUpdate();
  }, [goalThreshold, forceUpdate]);

  if (!goalThreshold) return null;

  return (
    <Card
      headers={[
        <Header
          key={0}
          timeSpan={timeSpan}
          setTimeSpan={setTimeSpan}
          vytalType={goalThreshold.type}
        />,
      ]}
      className={`${styles.card} ${className || ''}`}
    >
      <div className={styles.chart} style={{ opacity: !goalThreshold.is_enabled && 0.5 }}>
        <GoalLineChart
          dataPoints={dataPoints}
          goalThreshold={goalThreshold}
          timeSpan={timeSpan}
          chartRef={chartRef}
        />
      </div>
      {children}
    </Card>
  );
};

export default GoalCard;
