import { EndDateTypeLabel, mapLabelToEndDateType } from 'components/RPM/utils/endDateTypeMapper';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate } from 'util/dateUtils';

const useEndDate = (
  data: IPatientCarePlanAction,
  setData: (updatedSelectedAction: IPatientTimelineAction) => void
): {
  onEndDateTypeChange: (value: EndDateTypeLabel) => void;
  onFixedDateChange: (value: DatePickerValue) => void;
  onDurationDaysChange: (days: number) => void;
} => {
  const onEndDateTypeChange = (value: EndDateTypeLabel) => {
    const updatedRecurrence = { ...data.recurrence, end_date_type: mapLabelToEndDateType(value) };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  const onFixedDateChange = (value: DatePickerValue) => {
    const stringDate = convertDatepickToDate(value).toISOString();
    const updatedRecurrence = { ...data.recurrence, end_date: stringDate };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  const onDurationDaysChange = (days: number) => {
    if (days < 0) return;

    const updatedRecurrence = { ...data.recurrence, end_date_duration_days: days };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  return {
    onEndDateTypeChange,
    onFixedDateChange,
    onDurationDaysChange,
  };
};
export default useEndDate;
