import React from 'react';

interface ICommentIcon {
  unreadComments: boolean;
}

const CommentIcon = ({ unreadComments }: ICommentIcon) => {
  const color = unreadComments ? '#393E48' : '#8FAAC3';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
      <path
        d="M7.95292 5.12814C7.59198 5.12814 7.31229 5.40783 7.31229 5.75314C7.31229 6.09845 7.59198 6.37814 7.95292 6.37814C8.2826 6.37814 8.56229 6.09845 8.56229 5.75314C8.56229 5.40783 8.2826 5.12814 7.95292 5.12814ZM3.57792 5.12814C3.21698 5.12814 2.93729 5.40783 2.93729 5.75314C2.93729 6.09845 3.21698 6.37814 3.57792 6.37814C3.9076 6.37814 4.18729 6.09845 4.18729 5.75314C4.18729 5.40783 3.9076 5.12814 3.57792 5.12814Z"
        fill={color}
      />
      <path
        d="M12.9685 3.94064C12.217 2.90939 11.167 2.22033 10.0154 1.90939V1.91095C9.74823 1.61408 9.44667 1.34064 9.10917 1.09689C6.55135 -0.762485 2.96073 -0.195297 1.09354 2.36252C-0.411146 4.44064 -0.347083 7.23595 1.18729 9.22189L1.19979 11.2938C1.19979 11.3438 1.2076 11.3938 1.22323 11.4406C1.30604 11.7047 1.58729 11.85 1.84979 11.7672L3.82792 11.1438C4.35135 11.3297 4.89198 11.436 5.42948 11.4656L5.42167 11.4719C6.81385 12.486 8.63885 12.7906 10.3123 12.2375L12.2982 12.8844C12.3482 12.9 12.3998 12.9094 12.4529 12.9094C12.7295 12.9094 12.9529 12.686 12.9529 12.4094V10.3156C14.3295 8.44689 14.3654 5.86408 12.9685 3.94064ZM4.04667 10.0344L3.85917 9.95627L2.31229 10.4406L2.29667 8.81564L2.17167 8.67502C0.849791 7.06252 0.762292 4.73908 1.99979 3.03439C3.50604 0.968765 6.39354 0.512515 8.45292 2.00314C10.5185 3.5047 10.9764 6.38752 9.48417 8.44064C8.2326 10.1578 5.99198 10.7922 4.04667 10.0344ZM11.9373 9.76877L11.8123 9.92502L11.8279 11.55L10.2967 11.0344L10.1092 11.1125C9.23417 11.4375 8.30135 11.4641 7.43729 11.2219L7.43417 11.2203C8.58885 10.8656 9.63573 10.1469 10.3904 9.11252C11.5842 7.4672 11.7779 5.40002 11.0842 3.63752L11.0935 3.64377C11.4529 3.90158 11.7826 4.22345 12.0623 4.61252C13.1967 6.16877 13.1326 8.28752 11.9373 9.76877Z"
        fill={color}
      />
      <path
        d="M5.76542 5.12814C5.40448 5.12814 5.12479 5.40783 5.12479 5.75314C5.12479 6.09845 5.40448 6.37814 5.76542 6.37814C6.0951 6.37814 6.37479 6.09845 6.37479 5.75314C6.37479 5.40783 6.0951 5.12814 5.76542 5.12814Z"
        fill={color}
      />
    </svg>
  );
};
export default CommentIcon;
