import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAdministrationContext } from 'screens/Administration/store';
import ActivitiesActions from './activities-actions';
import TimeTrackingActivityCard from './time-tracking-activity-card';
import TimeTrackingActivityTemplateCard from './time-tracking-activity-template-card';

const Activities = () => {
  const { setActions } = useAdministrationContext();

  useEffect(() => {
    setActions(ActivitiesActions);
  }, []);

  return (
    <div>
      <Row>
        <Col md={8}>
          <TimeTrackingActivityCard />
        </Col>
        <Col>
          <TimeTrackingActivityTemplateCard />
        </Col>
      </Row>
    </div>
  );
};
export default Activities;
