import CollapsibleCard from 'components/CollapsibleCard';
import { useEffect, useRef, useState } from 'react';
import { addDays } from 'util/dateUtils';
import useWebSocketYellowAlerts from 'websockets/hooks/useWebSocketsYellowAlerts';
import { useWebSocketContext } from 'websockets/WebSocketContext';
import { useDashboardContext } from '../store/DashboardContext';
import useDashboardYellowAlerts from '../store/hooks/useDashboardYellowAlerts';
import EmptyYellowAlerts from './EmptyYellowAlerts';
import Header from './Header';
import styles from './styles.module.css';
import YellowAlertItem from './YellowAlertItem';
interface YellowAlertsProps {
  collapsed: boolean;
}

const getInitialDateRange = () => {
  const today = new Date();
  const yesterday = addDays(today, -1);
  return {
    from: {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate(),
    },
    to: {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    },
  };
};
const YellowAlerts = ({ collapsed }: YellowAlertsProps) => {
  const [date, setDate] = useState(getInitialDateRange());

  const { state } = useDashboardContext();

  const [yellowAlerts, refetch] = useDashboardYellowAlerts(date.from, date.to);

  const { dispatch } = useWebSocketContext();
  const { received } = useWebSocketYellowAlerts();
  const lastReceived = useRef(null);
  useEffect(() => {
    if (received && received !== lastReceived.current) {
      lastReceived.current = received;
      refetch();
    }
  }, [received, dispatch, refetch]);

  return (
    <CollapsibleCard
      outerCollapse={collapsed}
      headers={[
        <Header
          date={date}
          setDate={setDate}
          key={0}
          yellowAlertPatients={yellowAlerts?.length ?? 0}
          totalPatients={state.totalPatients}
        />,
      ]}
      className="bg-white mh-100 d-flex flex-column"
      headerClassName="p-0"
    >
      <div className={styles.body} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          {yellowAlerts && yellowAlerts.length > 0 ? (
            <>
              {yellowAlerts?.map((item) => (
                <YellowAlertItem dateFrom={date.from} dateTo={date.to} alert={item} key={item.id} />
              ))}
            </>
          ) : (
            <EmptyYellowAlerts />
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};
export default YellowAlerts;
