import React from 'react';
import { AlertPopulation } from 'types/ApiModels/Dashboard';
import { IPopulation } from 'types/Dashboard/Population';
import { Tagitem } from '../Tagitem';
import styles from './styles.module.css';

interface GenericPatientPreviewProps {
  populations: AlertPopulation[];
  children: React.ReactNode;
}
const GenericPatientPreview = ({ populations = [], children }: GenericPatientPreviewProps) => (
  <>
    {children}
    <div className={styles.tagsContainer}>
      {populations.map((population) => (
        <Tagitem text={population.name} key={`tag-item-${population.id}`} />
      ))}
    </div>
  </>
);

export default GenericPatientPreview;
