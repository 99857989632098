import Button from 'components/Button';
import FiltersButton from 'components/FiltersButton';
import VyTracModal from 'components/Modal';
import { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styles from './styles.module.css';
import { ReactComponent as DownArrow } from 'assets/icons/arrow_down.svg';

interface GraphModalProps {
  handleClose: () => void;
  handleChange: (month) => void;
  showModal: boolean;
  options: any;
  data: any;
  title: string;
}

const CompareBarGraph: FC<GraphModalProps> = ({
  options,
  data,
  handleClose,
  handleChange,
  showModal,
  title,
}: GraphModalProps) => {
  const [compareMonths, setCompareMonths] = useState<boolean>(false);
  const [showCompareMonth, setShowCompareMonth] = useState<boolean>(false);
  const [selectedMainMonth, setSelectedMainMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  );
  const [monthToCompare, setMonthToCompare] = useState([selectedMainMonth]);

  const monthOptions = [
    { label: 'January', value: '1' },
    { label: 'Febrary', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'Dicember', value: '12' },
  ];

  const addMonthToCompare = (selectedMonth) => {
    let months = [...monthToCompare];
    if (months.includes(selectedMonth)) {
      months = months.filter((m) => m !== selectedMonth);
    } else {
      months.push(selectedMonth);
    }
    setMonthToCompare(months);
  };

  const setToogleCompareMonths = () => {
    setCompareMonths((state) => !state);
  };

  const cancelCompareMonth = () => {
    setMonthToCompare([selectedMainMonth]);
    setShowCompareMonth(!showCompareMonth);
  };

  useEffect(() => {
    handleChange(monthToCompare);
  }, [monthToCompare]);

  return (
    <VyTracModal
      show={showModal}
      onClose={handleClose}
      title={
        <div className="d-flex flex-column">
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div>
              <select
                className={styles.select}
                onChange={(e) => {
                  setSelectedMainMonth(e.target.value);
                  setMonthToCompare([e.target.value]);
                }}
              >
                {monthOptions.map((month) => {
                  return (
                    <option
                      value={month.value}
                      selected={month.value === selectedMainMonth.toString()}
                    >
                      {month.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.controls}>
              <span className={styles.export}>Export</span>
              <FiltersButton />
            </div>
          </div>
        </div>
      }
      size="lg"
      bodyClassName={styles.modalBody}
      body={
        <div className={styles.body}>
          <div className="d-flex px-4 py-2 h">
            <input
              checked={compareMonths}
              className={styles.checkbox}
              onChange={setToogleCompareMonths}
              type="checkbox"
            />
            <label className={styles.labelCompare}>Compare different timeframe</label>
            <div className={styles.monthCompareContainer} hidden={!compareMonths}>
              {monthOptions
                .filter((m) => monthToCompare.includes(m.value))
                .map((month) => {
                  return (
                    <span
                      className={styles.monthCompare}
                      onClick={() => setShowCompareMonth(!showCompareMonth)}
                    >
                      {month.label}
                    </span>
                  );
                })}
              <DownArrow
                fill="#CFD6E2"
                className={styles.arrow}
              />
            </div>
          </div>

          <div>
            <Bar options={options} data={data} />
          </div>
          <VyTracModal
            show={showCompareMonth}
            onClose={() => {
              setShowCompareMonth(false);
            }}
            title="Compare Months"
            size="sm"
            bodyClassName={styles.modalBody}
            body={
              <div className={styles.body}>
                {monthOptions.map((month) => {
                  return (
                    <div>
                      <input
                        className={styles.checkbox}
                        checked={monthToCompare.includes(month.value)}
                        disabled={month.value === selectedMainMonth}
                        value={month.value}
                        onChange={() => {
                          addMonthToCompare(month.value);
                        }}
                        type="checkbox"
                      />
                      <label className={styles.labelCompare}>{month.label}</label>
                    </div>
                  );
                })}
              </div>
            }
            footer={
              <div className="d-flex w-100 justify-content-between">
                <Button label="cancel" variant="cancel" onClick={() => cancelCompareMonth()} />
                <Button label="save" onClick={() => setShowCompareMonth(false)} />
              </div>
            }
          />
        </div>
      }
    />
  );
};
export default CompareBarGraph;
