import { Col, Row } from 'react-bootstrap';
import loginBackground from 'assets/images/login_background.png';
import poweredBy from 'assets/images/powered_by.png';
import vytracWhite from 'assets/images/vytrac_white.png';
import styles from './styles.module.css';

const RegisterLoginLayout = ({ children }) => {
  return (
    <div className={styles['login-container']}>
      <Row className={styles['login-row']}>
        <Col className={styles['login-background-image-gradient']} xs={6}>
          <img
            className={styles['login-background-image']}
            src={loginBackground}
            alt="login background"
          ></img>
          <div className={styles['login-user-thumb']}></div>
          <div className={styles['logo-container']}>
            <img className="login-logo-top" src={poweredBy} alt="login logo top"></img>
            <img className="login-logo-bottom" src={vytracWhite} alt="login logo bottom"></img>
          </div>
        </Col>
        <Col>
          <div className={`${styles['login-form']} h-100`}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};
export default RegisterLoginLayout;
