import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const OxygenLevelIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.89981 2.05288C4.74696 2.32851 5.12854 3.31906 5.29594 4.41223L2.58227 5.94349L3.18224 6.95925L5.40813 5.70363C5.43573 6.4977 5.39973 7.21847 5.39973 7.6243C5.39973 9.3837 4.79976 11.1431 2.39988 11.1431C0 11.1431 0 11.1431 0 8.79724C0 4.39874 2.0999 1.46641 3.89981 2.05288ZM12 8.79724V9.05294C11.997 11.1431 11.91 11.1431 9.60012 11.1431C7.20024 11.1431 6.60027 9.3837 6.60027 7.6243C6.60027 7.21906 6.56427 6.49829 6.59187 5.70421L8.81776 6.95925L9.41773 5.94349L6.70407 4.41282C6.87146 3.31906 7.25304 2.3291 8.1002 2.05288C9.90011 1.46641 12 4.39874 12 8.79724ZM6.59967 0.000244141V5.27844H5.39973V0.000244141H6.59967Z"
        fill={color}
      />
    </svg>
  );
};

export default OxygenLevelIcon;
