import DatePickerValue, { DatePickerRangeOption } from 'types/Shared/DatePicker';

export const convertDateToDatePickValue = (
  date: Date,
  convertHoursMinutes = false
): DatePickerValue | null => {
  if (!date) return null;
  const conversion = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
  if (convertHoursMinutes) {
    return {
      ...conversion,
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
  } else {
    return conversion;
  }
};

export const convertDatepickToDate = (datepick: DatePickerValue): Date => {
  if (!datepick) return null;
  const { year, month, day, hours, minutes } = datepick;
  const dateValue = new Date(year, month - 1, day);

  if (hours) dateValue.setHours(hours);
  if (minutes) dateValue.setMinutes(minutes);
  return dateValue;
};

export const convertDatepickRangeToDates = ({ from, to }: DatePickerRangeOption): [Date, Date] => [
  convertDatepickToDate(from),
  convertDatepickToDate(to),
];
