import Card from 'components/Card';
import { FC } from 'react';
import Header from './header';

interface PatientFilesProps {
  files?: string[];
}

const PatientFiles: FC<PatientFilesProps> = ({ files }: PatientFilesProps) => {
  return (
    <Card
      className="bg-white w-100"
      headerClassName="px-0"
      headers={[<Header key="patient-files-header" />]}
    >
      {files && (
        <div>
          {files.length > 0 ? (
            files.map((file) => <div key={file}>{file}</div>)
          ) : (
            <div className="text-center">No files found</div>
          )}
        </div>
      )}
    </Card>
  );
};
export default PatientFiles;
