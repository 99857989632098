import UserBubble from 'components/UserBubble';
import { FC } from 'react';
import { ProviderSignup } from 'types/ApiModels/Administration/Dashboard';
import { getUserDisplayName } from 'util/userUtils';
import activityPointsIcon from 'assets/icons/activity-points-gray-sm.svg';

interface ProviderSignupCardProps {
  providerSignup: ProviderSignup;
}

const ProviderSignupCard: FC<ProviderSignupCardProps> = ({
  providerSignup,
}: ProviderSignupCardProps) => {
  const [first_name, last_name] = providerSignup.full_name.split(' ');

  return (
    <div className="d-flex justify-content-between bg-light-gray rounded pl-2 pr-4 py-3">
      <div className="flex-grow-1 d-flex align-items-center gap">
        {providerSignup.photo ? (
          <img src={providerSignup.photo} height={36} width={36} />
        ) : (
          <>
            <UserBubble user={{ first_name, last_name, username: '' }} includeMargin={false} />
            {getUserDisplayName({ first_name, last_name, username: '' })}
          </>
        )}
      </div>
      <div className="d-flex gap-sm align-items-center">
        <img src={activityPointsIcon} alt="activity points" width={12} height={10} />
        <div className="text-gray-low-risk font-weight-md">{providerSignup.activity_points}</div>
      </div>
    </div>
  );
};
export default ProviderSignupCard;
