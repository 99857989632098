import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { Dispatch, useCallback, useEffect } from 'react';
import { CareTeam } from 'types/ApiModels/CareTeam';
import { useAdministrationContextV2 } from '..';
import { AllAdminActions } from '../administrationReducer';
import { CareTeamState, setAdminCareTeamBook } from '../CareTeam';

export const useAdminCareTeams = (): [
  state: CareTeamState,
  paginatedRequest: PaginatedRequestHook<CareTeam>,
  dispatch: Dispatch<AllAdminActions>
] => {
  const {
    administrationStateV2: { careTeams },
    dispatch,
    fetchRegistry,
  } = useAdministrationContextV2();

  const paginatedRequest = usePaginatedRequest(
    'administration/careteams/',
    10,
    careTeams.careTeamsBook,
    false
  );

  const fetchInitialCareTeams = useCallback(async () => {
    fetchRegistry.current.careTeams.list = true;
    await paginatedRequest.init();
    fetchRegistry.current.careTeams.list = false;
  }, [fetchRegistry, paginatedRequest]);

  useEffect(() => {
    if (!careTeams.careTeamsBook && !fetchRegistry.current.careTeams.list) {
      fetchInitialCareTeams();
    }
  }, [careTeams.careTeamsBook, fetchInitialCareTeams, fetchRegistry]);

  useEffect(() => {
    dispatch(setAdminCareTeamBook(paginatedRequest.book));
  }, [dispatch, paginatedRequest.book]);

  return [careTeams, paginatedRequest, dispatch];
};
