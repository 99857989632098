import AlertItemSvg from 'components/AlertItemSvg';
import { Tagitem } from 'components/Tagitem';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { formatVytracDate } from 'util/dateUtils';
import { colorBySeverity, IconComponentByType } from 'util/iconUtils';
import { mockedAlertsData, mockedPopulationsData } from './mockedPatientsReportData';

export const patientReportsMaxPillsCount = 9;

export const controlsEnum = {
  alerts: 'alerts',
  populations: 'populations',
};

//TODO: maybe these two should live somewhere else? we might have to use these in other places as well
const dateRenderer = ({ date }) => {
  return <div className="text-nowrap">{formatVytracDate(date)}</div>;
};

export const renderTextWithSubscript = (text) =>
  /**TODO: Can this be improved? */
  text.split(' ').map((r, idx) => {
    if (r === 'SpO2') {
      return (
        <React.Fragment key={`subscript-text-${idx}`}>
          {' '}
          SPO<sub>2</sub>
        </React.Fragment>
      );
    }
    return idx === 0 ? r : ` ${r}`;
  });

export const piechartColors = [
  'hsl(218, 25%, 75%)',
  'hsl(218, 25%, 78%)',
  'hsl(218, 25%, 81%)',
  'hsl(218, 25%, 84%)',
  'hsl(218, 25%, 87%)',
  'hsl(218, 25%, 90%)',
];

export const borderColors = Array(6).fill('#FFF');

export const alertsColumns = [
  {
    title: 'reason',
    key: 'reason',
    headerStyle: {
      width: '45%',
    },
    render: ({ reason, severity, type }) => {
      return (
        <div className="d-flex align-items-center">
          <AlertItemSvg
            SvgComponent={IconComponentByType[type]}
            color={colorBySeverity[severity]}
          />

          <NavLink to={'#'}>{renderTextWithSubscript(reason)}</NavLink>
        </div>
      );
    },
  },
  {
    title: 'occurrences',
    key: 'occurrences',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'status',
    key: 'status',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'last interaction',
    key: 'lastInteraction',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'date',
    key: 'date',
    render: dateRenderer,
  },
];

export const populationsColumns = [
  {
    title: 'reason',
    key: 'reason',
    render: ({ reason }) => (
      <div className="d-flex">
        <Tagitem text={reason} />
      </div>
    ),
    headerStyle: {
      width: '45%',
    },
  },
  {
    title: 'occurrences',
    key: 'occurrences',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'status',
    key: 'status',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'added by',
    key: 'addedBy',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'date',
    key: 'date',
    render: dateRenderer,
  },
];

//TODO: unhardcode on integration
export const mapAlertDataToTable = (data) => mockedAlertsData;

export const mapPopulationsDataToTable = (data) => mockedPopulationsData;

export const sortByOccurrence = (a, b) => (a.occurrences < b.occurrences ? 1 : -1);
