import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { addSuffixes } from './utils/addSuffixes';
import { getDayNumber } from './utils/getDayNumber';
import { sortDaysOfMonth } from './utils/sortDaysOfMonth';

const rows = Array.from(Array(5).keys());
const cols = Array.from(Array(7).keys());

interface IDayOfMonthPicker {
  actionStartDate: string;
  daysSelected: string[];
  onRepeatsParamChange: (newParam: string[]) => void;
}
const DayOfMonthPicker = ({
  actionStartDate,
  daysSelected,
  onRepeatsParamChange,
}: IDayOfMonthPicker) => {
  const [displayDaySelector, setDisplayDaySelector] = useState<boolean>(false);
  const daySelectorRef = useRef<HTMLDivElement>(null);

  //Event listener to click outside of calendar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (daySelectorRef.current && !daySelectorRef.current.contains(event.target)) {
        setDisplayDaySelector(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [daySelectorRef]);

  const onDayClick = (day: string) => {
    if (moment(actionStartDate).date().toString() === day) return;

    const updatedDaysSelected = [...daysSelected];
    if (!daysSelected.includes(day)) {
      updatedDaysSelected.push(day);
    } else {
      const dayPosition = updatedDaysSelected.indexOf(day);
      updatedDaysSelected.splice(dayPosition, 1);
    }
    onRepeatsParamChange(updatedDaysSelected);
  };

  return (
    <div className="w-100 h-100">
      <input
        className={`w-100 ${styles.input}`}
        type="text"
        placeholder="Select days"
        readOnly
        value={addSuffixes(sortDaysOfMonth(daysSelected))}
        onClick={() => setDisplayDaySelector(true)}
      />
      <div
        ref={daySelectorRef}
        className={styles.daySelectorContainer}
        style={{ display: displayDaySelector ? 'block' : 'none' }}
      >
        {rows.map((row) => (
          <div key={row} className="d-flex">
            {cols.map(
              (col) =>
                getDayNumber(row, col) <= 31 && (
                  <div
                    onClick={() => onDayClick(getDayNumber(row, col).toString())}
                    className={styles.numberContainer}
                    key={col}
                    style={
                      daysSelected.includes(getDayNumber(row, col).toString())
                        ? {
                            backgroundColor: 'black',
                            color: 'white',
                          }
                        : {}
                    }
                  >
                    {getDayNumber(row, col)}
                  </div>
                )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default DayOfMonthPicker;
