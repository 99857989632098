import {
  NavigationAction,
  NavigationActionTypes,
  navigationReducer,
} from 'util/commonContext/NavigationContext';
import {
  PatientCarePlanAction,
  PatientCarePlanActionTypes,
} from './PatientCarePlan/patientCarePlanActionType';
import patientCarePlanReducer from './PatientCarePlan/patientCarePlanReducer';
import { PatientState } from './PatientContext';
import { patientGoalsThresholdsReducer } from './PatientGoalsThresholds';
import {
  PatientGoalsThresholdsAction,
  PatientGoalsThresholdsActionTypes,
} from './PatientGoalsThresholds/patientGoalsThresholdsActionTypes';
import { patientListReducer } from './PatientList';
import { PatientListAction, PatientListActionTypes } from './PatientList/patientListActionType';
import {
  PatientManagementAction,
  PatientManagementActionTypes,
  patientManagementReducer,
} from './PatientManagement';
import { patientTimelineReducer } from './PatientTimeline';
import {
  PatientTimelineAction,
  PatientTimelineActionTypes,
} from './PatientTimeline/patientTimelineActionTypes';
import { patientTrackingReducer } from './PatientTracking';
import {
  PatientTrackingAction,
  PatientTrackingActionTypes,
} from './PatientTracking/patientTrackingActionTypes';

export type AllPatientActions =
  | NavigationAction
  | PatientListAction
  | PatientManagementAction
  | PatientCarePlanAction
  | PatientTimelineAction
  | PatientGoalsThresholdsAction
  | PatientTrackingAction;

enum PatientActionSubType {
  Navigation,
  PatientList,
  PatientManamement,
  PatientCarePlan,
  PatientTimeline,
  PatientGoalsThresholds,
  PatientTracking,
}

const getSubType = (action: AllPatientActions) => {
  if (action.type in NavigationActionTypes) return PatientActionSubType.Navigation;
  if (action.type in PatientListActionTypes) return PatientActionSubType.PatientList;
  if (action.type in PatientManagementActionTypes) return PatientActionSubType.PatientManamement;
  if (action.type in PatientCarePlanActionTypes) return PatientActionSubType.PatientCarePlan;
  if (action.type in PatientTimelineActionTypes) return PatientActionSubType.PatientTimeline;
  if (action.type in PatientGoalsThresholdsActionTypes)
    return PatientActionSubType.PatientGoalsThresholds;
  if (action.type in PatientTrackingActionTypes) return PatientActionSubType.PatientTracking;
};

const patientReducer = (state: PatientState, action: AllPatientActions): PatientState => {
  const subType = getSubType(action);
  switch (subType) {
    case PatientActionSubType.Navigation: {
      return {
        ...state,
        patientNavigation: navigationReducer(state.patientNavigation, action as NavigationAction),
      };
    }
    case PatientActionSubType.PatientList: {
      return {
        ...state,
        patientList: patientListReducer(state.patientList, action as PatientListAction),
      };
    }
    case PatientActionSubType.PatientManamement: {
      return {
        ...state,
        patientManagement: patientManagementReducer(
          state.patientManagement,
          action as PatientManagementAction
        ),
      };
    }
    case PatientActionSubType.PatientCarePlan: {
      return {
        ...state,
        patientCarePlan: patientCarePlanReducer(
          state.patientCarePlan,
          action as PatientCarePlanAction
        ),
      };
    }
    case PatientActionSubType.PatientTimeline: {
      return {
        ...state,
        patientTimeline: patientTimelineReducer(
          state.patientTimeline,
          action as PatientTimelineAction
        ),
      };
    }
    case PatientActionSubType.PatientGoalsThresholds: {
      return {
        ...state,
        patientGoalsThresholds: patientGoalsThresholdsReducer(
          state.patientGoalsThresholds,
          action as PatientGoalsThresholdsAction
        ),
      };
    }
    case PatientActionSubType.PatientTracking: {
      return {
        ...state,
        patientTracking: patientTrackingReducer(
          state.patientTracking,
          action as PatientTrackingAction
        ),
      };
    }
    default:
      return state;
  }
};

export default patientReducer;
