import Button from 'components/Button';
import { IButton } from 'components/Button/Button';
import React from 'react';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import EmergencyButton from '../../../EmergencyButton';

const Actions = () => {
  const {
    patientState: {
      patientManagement: { resetPatientForm, submitPatientForm, formDirty },
    },
  } = usePatientContext();

  const AbstractButton = (props: IButton) => <Button {...props} className="mx-1" />;

  return (
    <div className="d-flex">
      <AbstractButton
        label="cancel"
        variant="dull"
        onClick={resetPatientForm}
        disabled={!formDirty}
      />
      <AbstractButton
        label="save changes"
        variant="confirm"
        onClick={submitPatientForm}
        disabled={!formDirty}
      />
      <EmergencyButton />
    </div>
  );
};

export default Actions;
