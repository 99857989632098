import { Cross } from 'components/Icons';
import { FC, useState } from 'react';
import { DismissableDialogVariant } from './types';
import styles from './styles.module.css';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import colors from 'styles/colors';

interface DismissableDialogProps {
  label: string;
  text: string;
  variant: DismissableDialogVariant;
}

const DismissableDialogAlt: FC<DismissableDialogProps> = ({
  label,
  text,
  variant,
}: DismissableDialogProps) => {
  const { clearDialog } = useDialog();
  return (
    <div
      className={`d-flex justify-content-between m-2 py-2 px-3 rounded font-size-big ${styles[variant]}`}
    >
      <div className="d-flex gap-lg align-items-center">
        <p className={`m-0 font-weight-md`}>{label}</p>
        <p className={`m-0`}>{text}</p>
      </div>
      <button
        type="button"
        className={`unstyled-button cursor-pointer`}
        onClick={() => clearDialog()}
      >
        <Cross width="12" height="12" color={colors.regularBlack} />
      </button>
    </div>
  );
};
export default DismissableDialogAlt;
