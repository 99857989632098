import DayActivityItem from 'components/ActivityTimeline/ActivityTimelineView/DayActivityItem';
import { printBeginDate } from 'components/ActivityTimeline/utils';
import { FC } from 'react';
import { Activity, ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import ActionContent, { actionContentProps } from '../../DayActivityItem/ActionContent';
import AppointmentContent, {
  appointmentContentProps,
} from '../../DayActivityItem/AppointmenContent';
import { getActionLabel, getAppointmentLabel } from '../../utils/getItemLabel';

interface DayActivityItemsProps {
  items: Activity[];
}

const DayActivityItems: FC<DayActivityItemsProps> = ({ items }: DayActivityItemsProps) => {
  return (
    <div className="d-flex flex-column gap-sm mt-2 px-1 overflow-hidden">
      {items.map((item: Activity) => (
        <DayActivityItem
          key={`day-activity-item-${item.id}`}
          activityItemId={item.id}
          itemContainerProps={
            item.type === ActivityType.ACTION
              ? actionContentProps({
                  isCarePlan: item.action_props.is_part_of_rpm_plan,
                  isActive: item.action_props.is_active,
                })
              : appointmentContentProps()
          }
        >
          {item.type === ActivityType.ACTION && (
            <ActionContent
              dateAMPM={printBeginDate(item.start_date)}
              label={getActionLabel(item.action_props)}
              isActive={item.action_props.is_active}
              questionnaireId={item.action_props.questionnaire}
            />
          )}
          {item.type === ActivityType.APPOINTMENT && (
            <AppointmentContent
              dateAMPM={printBeginDate(item.start_date)}
              label={getAppointmentLabel(item.appointment_props)}
              type={item.appointment_props.appointment_type}
            />
          )}
        </DayActivityItem>
      ))}
    </div>
  );
};
export default DayActivityItems;
