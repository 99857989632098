import BackButton from 'components/BackButton';
import Button from 'components/Button';
import GraphBuilder from 'components/GraphBuilder';
import VyTracModal from 'components/Modal';
import VytracSurveyBuilder from 'components/VytracSurveyBuilder';
import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import {
  getQuestionnaire,
  pushNewQuestionnaireToPatient,
  pushQuestionnaireTemplateToPatient,
} from 'services/questionnaireService';
import { setBreadcrumbPathList } from 'util/commonContext/NavigationContext';
import QuestionnaireContext from '../../util/Context';
import AnswerNode from './components/AnswerNode';
import Controls from './components/Controls';
import CreateActions from './components/CreateActions';
import QuestionNode from './components/QuestionNode';
import TemplateActions from './components/TemplateActions';
import styles from './styles.module.css';
import tabEnum from './util/tabEnum';
import tabTitleMap from './util/tabTitleMap';

const CreateQuestionnaire = () => {
  const history = useHistory();
  const { selectedPatient } = usePatientFormikContext();
  const {
    dispatch,
    patientState: {
      patientNavigation: { breadcrumbPathList },
    },
  } = usePatientContext();
  // TODO: this context should be in the patient context
  const { questionnaireState } = useContext(QuestionnaireContext);
  const [tabType, setTabType] = React.useState(tabEnum.TEMPLATE);
  const [activeTemplateIndex, setActiveTemplateIndex] = React.useState(0);
  const [questionnaire, setQuestionnaire] = React.useState({
    id: '',
    name: '',
    usedFor: null,
  });
  const [initialValues, setInitialValues] = React.useState<any>([]);
  const [elements, setElements] = React.useState([]);
  const pendingState = useRef(Promise.resolve());
  const [showCancelDeleteModal, setShowCancelDeleteModal] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  useEffect(() => {
    if (elements && elements.length && elements.length > 0 && questionnaire.name) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [elements, questionnaire]);

  const onCreateFromTemplate = async (template, patientId) => {
    try {
      await pushQuestionnaireTemplateToPatient(template.id, patientId);
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const onCreateNew = async (newElements, name, patientId) => {
    try {
      await pushNewQuestionnaireToPatient(newElements, name, patientId);
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    setInitialValues([]);
    setQuestionnaire({
      id: '',
      name: '',
      usedFor: null,
    });
    setShowCancelDeleteModal(false);
  };

  //Set breadcrumbs
  useEffect(() => {
    // setState((prevState) => ({
    //   ...prevState,
    //   breadcrumbPathList: [
    //     ...prevState.breadcrumbPathList,
    // {
    //   title: 'Create questionnaire',
    //   url: `/patients/create`,
    // },
    //   ],
    // }));

    dispatch(
      setBreadcrumbPathList([
        ...breadcrumbPathList,
        {
          title: 'Create questionnaire',
          url: `/patients/create`,
        },
      ])
    );
  }, []);

  //load data into board depending on the tab
  useEffect(() => {
    if (tabType === tabEnum.TEMPLATE) {
      const fetchQuestionnaire = async () => {
        try {
          const id = questionnaireState.templateQuestionnaires[activeTemplateIndex].id;
          const data = await getQuestionnaire(id);
          setQuestionnaire({
            id: id,
            name: data.name,
            // usedFor: data.usedFor,
            usedFor: null,
          });
          setInitialValues(data);
        } catch (e) {
          throw e;
        }
      };
      pendingState.current = fetchQuestionnaire();
    } else {
      pendingState.current.then(() => {
        setInitialValues(() => []);
        setQuestionnaire({
          id: '',
          name: '',
          usedFor: null,
        });
      });
    }
    return () => {
      setInitialValues([]);
      setQuestionnaire({
        id: '',
        name: '',
        usedFor: null,
      });
    };
  }, [activeTemplateIndex, tabType, questionnaireState.templateQuestionnaires]);

  const onNameChange = (newVal) => {
    setQuestionnaire({ ...questionnaire, name: newVal });
  };

  const DeleteCancelBody = (
    <div className={styles.modalBody}>
      You have unsaved changes. Are you sure you want to discard them?
    </div>
  );

  const DeleteCancelFooter = (
    <div className={styles.modalFooter}>
      <Button
        label="cancel"
        variant="cancel"
        className={styles.button}
        onClick={() => setShowCancelDeleteModal(false)}
      />
      <Button
        label={'discard'}
        variant="delete"
        className={styles.button}
        onClick={() => onCancel()}
      />
    </div>
  );

  return (
    <div className="h-100 w-100">
      <BackButton
        label={'Back to all questionnaires'}
        color="#1890FF"
        className={styles.backContainer}
      />
      <div className={styles.controls}>
        <Controls
          questionnaire={questionnaire}
          onNameChange={onNameChange}
          tabType={tabType}
          setTabType={setTabType}
          availableQuestionnaires={questionnaireState.templateQuestionnaires}
          active={activeTemplateIndex}
          // setActive={setActiveTemplateIndex}
        />
        <GraphBuilder
          actions={() =>
            tabType === tabEnum.TEMPLATE ? (
              <TemplateActions
                onSave={() =>
                  onCreateFromTemplate(
                    questionnaireState.templateQuestionnaires[activeTemplateIndex],
                    selectedPatient.id
                  )
                }
              />
            ) : (
              <CreateActions
                style={{
                  opacity: hasUnsavedChanges ? 1 : 0.3,
                  cursor: hasUnsavedChanges ? 'pointer' : 'default',
                }}
                disabled={!hasUnsavedChanges}
                onSave={() => onCreateNew(elements, questionnaire.name, selectedPatient.id)}
                onCancel={() => setShowCancelDeleteModal(true)}
              />
            )
          }
          title={tabTitleMap[tabType]}
          elements={elements}
          setElements={setElements}
        >
          <VytracSurveyBuilder
            elements={elements}
            setElements={setElements}
            questionNodeType={QuestionNode}
            answerNodeType={AnswerNode}
            initialValues={initialValues}
            editable={tabType === 'create'}
          />
        </GraphBuilder>
      </div>
      <VyTracModal
        body={DeleteCancelBody}
        footer={DeleteCancelFooter}
        show={showCancelDeleteModal}
        onClose={() => setShowCancelDeleteModal(false)}
        title={'Are you sure?'}
      />
    </div>
  );
};

export default CreateQuestionnaire;
