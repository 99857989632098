import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getPatientQuestionnaires } from 'services/questionnaireService';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { setPatientQuestionnaires } from '../DashboardActionCreators';
import { DashboardAction } from '../DashboardActionTypes';
import { useDashboardContext } from '../DashboardContext';

const useDashboardPatientQuestionnaires = (
  patientId
): [IPatientQuestionnaire[], Dispatch<DashboardAction>, () => void] => {
  const {
    state: { patientQuestionnaires },
    dispatch,
    fetchRegistry,
  } = useDashboardContext();

  const [patientQuestionnaireState, setPatientQuestionnaireState] = useState<
    IPatientQuestionnaire[]
  >([]);

  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    fetchRegistry.current.patientQuestionnaires.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchPatientQuestionnaires = useCallback(async () => {
    const data = await getPatientQuestionnaires(patientId);
    setPatientQuestionnaireState(data);
  }, [patientId]);

  const patientQuestionnairesInit = useCallback(async () => {
    fetchRegistry.current.yellowAlerts.list = true;
    await fetchPatientQuestionnaires();
    fetchRegistry.current.yellowAlerts.list = false;
  }, [fetchRegistry, fetchPatientQuestionnaires]);

  useEffect(() => {
    if (!patientQuestionnaires[patientId] && !fetchRegistry.current.patientQuestionnaires.list) {
      patientQuestionnairesInit();
    } else if (
      patientQuestionnaires[patientId] &&
      patientQuestionnaires[patientId].length > 0 &&
      !fetchRegistry.current.patientQuestionnaires.list
    ) {
      setPatientQuestionnaireState(patientQuestionnaires[patientId]);
    }
  }, [
    fetchRegistry,
    fetchPatientQuestionnaires,
    patientId,
    patientQuestionnaires,
    patientQuestionnairesInit,
  ]);

  useEffect(() => {
    if (patientQuestionnaireState && patientId)
      dispatch(setPatientQuestionnaires(patientQuestionnaireState, patientId));
  }, [dispatch, patientQuestionnaireState, patientId]);

  return [patientQuestionnaireState, dispatch, refetch];
};

export default useDashboardPatientQuestionnaires;
