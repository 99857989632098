import { useContext, useEffect } from 'react';
import Button from 'components/Button';
import { breadcrumbsPathMap } from '../../util/breadcrumbsPathMap';
import { routeEnum } from '../../util/routeEnum';
import ActionsButton from './components/ActionsButton';
import Header from './components/Header';
import PatientBillingTable from './components/PatientBillingTable';
import SearchBar from './components/SearchBar';
import styles from './styles.module.css';
import sortEnum from './util/sortEnum';
import { defaultSorting } from './util';
import sortLabelMap from './util/sortLabelMap';
import { getBillingList } from '../../../../../../services/billingService';
import Context from 'screens/Billing/util/Context';
import FiltersButton from 'components/FiltersButton';
import {
  mapListToPagedList,
  usePagination,
  QuickFilters,
  PaginationControls,
} from 'components/Pagination';
import { PaginationActionType } from 'components/Pagination/store/types';
import { QuickFilter } from 'types/Shared/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';

const PatientListWithFilters = () => {
  const { setState, state } = useContext(Context);
  const { paginationDispatch: dispatch } = usePagination();
  const { path } = useRouteMatch();
  const history = useHistory();

  const Actions = () => (
    <div className="d-flex">
      <Button
        className={styles.button}
        onClick={() => history.push(`${path}/${routeEnum.CHARGES_CONFIG}`)}
        label="charges configuration"
      />
      <Button
        className={styles.button}
        onClick={() => history.push(`${path}/${routeEnum.CODES_CONFIG}`)}
        label="codes configuration"
      />
    </div>
  );

  useEffect(() => {
    if (state.searchValue.length > 0) {
      setState((prevState) => ({
        ...prevState,
        filteredPatientList: prevState.patientList.filter(({ patient }) =>
          patient.username.toLowerCase().includes(prevState.searchValue.toLowerCase())
        ),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filteredPatientList: [...prevState.patientList],
      }));
    }
  }, [state.searchValue]);

  //Fetch patients
  useEffect(() => {
    const fetchPatientList = async () => {
      try {
        const patientList = await getBillingList();
        setState((state) => ({
          ...state,
          patientList: patientList?.sort(defaultSorting),
        }));
      } catch (exception) {
        console.error(exception);
      }
    };
    fetchPatientList();
  }, []);

  //Map patient list to paged list
  useEffect(() => {
    if (state.filteredPatientList.length > 0) {
      const newState = {
        ...state,
        patientPageList: mapListToPagedList(state.filteredPatientList),
        selectedPatient: null,
        breadcrumbPathList: breadcrumbsPathMap()[routeEnum.PATIENT_LIST],
        actions: Actions,
      };
      setState(() => newState);
      dispatch({
        type: PaginationActionType.SET_MAX_PAGE,
        payload: { maxPage: newState.patientPageList.length - 1 },
      });
    }
  }, [state.filteredPatientList]);

  //If patient List changes set filtered patient list to patient list and chain reaction occurs
  useEffect(() => {
    if (state.patientList && state.patientList.length > 0)
      setState((state) => ({
        ...state,
        filteredPatientList: state.patientList ? [...state.patientList] : [],
      }));
  }, [state.patientList]);

  const filters: QuickFilter[] = Object.keys(sortEnum).map((sortKey) => ({
    sortKey,
    label: sortLabelMap[sortKey],
    onClick: () => null,
    selected: false,
  }));

  return (
    <>
      <Header />
      <div className={styles.main}>
        <QuickFilters filters={filters} />
        <div className="d-flex">
          <ActionsButton onClick={() => {}} label="Export selected" />
          <SearchBar />
          <FiltersButton />
        </div>
      </div>
      <PatientBillingTable onPatientClick={() => {}} />
      <div className={styles.footer}>
        <PaginationControls />
      </div>
    </>
  );
};

export default PatientListWithFilters;
