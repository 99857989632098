import AlertsReport from '../components/AlertsReport';
import AlertsReportPieChart from '../components/AlertsReport/AlertsReportPieChart';
import PopulationsReport from '../components/PopulationsReport';
import PopulationsReportPieChart from '../components/PopulationsReport/PopulationsReportPieChart';

export const sectionByControl = {
  alerts: { table: <AlertsReport />, pieChart: <AlertsReportPieChart /> },
  populations: {
    table: <PopulationsReport />,
    pieChart: <PopulationsReportPieChart />,
  },
};
