import { CareTeam, CareTeamCreateUpdateRequest } from 'types/ApiModels/CareTeam';
import { PaginatedResponse } from 'types/ApiModels/General';
import { CareTeamActionTypes, CareTeamAction } from './careTeamActionTypes';

export const setAdminCareTeamBook = (
  careTeamBook: Record<number, PaginatedResponse<CareTeam>>
): CareTeamAction => ({
  type: CareTeamActionTypes.SET_CARE_TEAMS_BOOK,
  payload: { careTeamBook },
});

export const setCurrentCareTeamId = (careTeamId: number): CareTeamAction => ({
  type: CareTeamActionTypes.SET_CURRENT_CARE_TEAM,
  payload: { careTeamId },
});

export const setCareTeamsBookAndMatchTable = (
  careTeamsBook: Record<number, PaginatedResponse<CareTeam>>
): CareTeamAction => ({
  type: CareTeamActionTypes.SET_CARE_TEAMS_BOOK_AND_MATCH_TABLE,
  payload: { careTeamsBook },
});

export const updateCareTeamDelete = (careTeamId: number, page: number): CareTeamAction => ({
  type: CareTeamActionTypes.UPDATE_CARE_TEAM_DELETE,
  payload: { careTeamId, page },
});

export const resetCareTeamForm = (): CareTeamAction => ({
  type: CareTeamActionTypes.RESET_CARE_TEAM_FORM,
});

export const setSubmitCareTeamForm = (submitForm: () => void): CareTeamAction => ({
  type: CareTeamActionTypes.SET_CARE_TEAMS_SUBMIT_FORM,
  payload: { submitForm },
});

export const resetDeleteCareTeams = (): CareTeamAction => ({
  type: CareTeamActionTypes.RESET_DELETED_CARE_TEAMS,
});

export const resetUpdatedCareTeams = (): CareTeamAction => ({
  type: CareTeamActionTypes.RESET_UPDATED_CARE_TEAMS,
});

export const setCreateCareTeam = (createCareTeam: () => void): CareTeamAction => ({
  type: CareTeamActionTypes.SET_CREATE_CARE_TEAM,
  payload: { createCareTeam },
});

export const appendCreatedCareTeam = (careTeam: CareTeamCreateUpdateRequest): CareTeamAction => ({
  type: CareTeamActionTypes.APPEND_CREATED_CARE_TEAM,
  payload: { careTeam },
});

export const removeCreatedCareTeam = (localId: number): CareTeamAction => ({
  type: CareTeamActionTypes.REMOVE_CREATED_CARE_TEAM,
  payload: { localId },
});

export const appendUpdatedCareTeam = (
  updatedCareTeam: CareTeamCreateUpdateRequest
): CareTeamAction => ({
  type: CareTeamActionTypes.APPEND_UPDATED_CARE_TEAM,
  payload: { updatedCareTeam },
});

export const resetUpdateCreatedCareTeams = (updatedCareTeams: CareTeam[]): CareTeamAction => ({
  type: CareTeamActionTypes.RESET_UPDATE_CREATED_CARE_TEAMS,
  payload: {
    updatedCareTeams,
  },
}); //--AppendCreator
