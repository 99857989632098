import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import { FormikState } from 'formik';
import {
  PatientManagementAction,
  PatientManagementActionTypes,
} from './patientManagementActionTypes';

export interface IPatientManagementState {
  submitPatientForm: () => void;
  resetPatientForm: (nextState: Partial<FormikState<PatientManagementFormikValues>>) => void;
  formDirty: boolean;
}

export const patientManagementInitialState: IPatientManagementState = {
  submitPatientForm: () => undefined,
  resetPatientForm: () => undefined,
  formDirty: false,
};

const patientManagementReducer = (
  state: IPatientManagementState,
  action: PatientManagementAction
): IPatientManagementState => {
  switch (action.type) {
    case PatientManagementActionTypes.SET_SUBMIT_PATIENT_FORM: {
      return { ...state, submitPatientForm: action.payload.submitForm };
    }
    case PatientManagementActionTypes.SET_RESET_PATIENT_FORM: {
      return { ...state, resetPatientForm: action.payload.resetForm };
    }
    case PatientManagementActionTypes.SET_FORM_DIRTY: {
      return { ...state, formDirty: action.payload.dirty };
    }
    //--AppendCase
    default:
      return state;
  }
};

export default patientManagementReducer;
