import { ActionModalType } from 'components/RPM/ActionModal/CarePlanActionModal';
import { EndDateTypeLabel, mapEndDateTypeLabel } from 'components/RPM/utils/endDateTypeMapper';
import React, { CSSProperties, useMemo } from 'react';
import { EndDateType } from 'types/ApiModels/CarePlan/EndDateType';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDateToDatePickValue } from 'util/dateUtils';
import DatePicker from '../../../../../../DatePicker';
import Select from '../../../../../../form/Select';
import DurationInput from '../RecurrentOptions/components/DurationInput';
import styles from './styles.module.css';

interface EndDateInput {
  startDate: string;
  endDate: string;
  endDateType: EndDateType;
  endDateDurationDays: number;
  onEndDateTypeChange: (value: EndDateTypeLabel) => void;
  onDurationDaysChange: (days: number) => void;
  onFixedDateChange: (value: DatePickerValue) => void;
  style?: CSSProperties;
  modalType: ActionModalType;
}

const EndDateInput = ({
  startDate,
  endDate,
  endDateType,
  endDateDurationDays,
  onEndDateTypeChange,
  onDurationDaysChange,
  onFixedDateChange,
  style,
  modalType,
}: EndDateInput) => {
  const endDateOptions = useMemo(() => {
    if (modalType === 'template') {
      return [...Object.values(EndDateTypeLabel)].filter(
        (value: EndDateTypeLabel) => value !== EndDateTypeLabel.FIXED_DATE
      );
    } else {
      return [...Object.values(EndDateTypeLabel)];
    }
  }, []);

  return (
    <div className={styles.rowContainer} style={style}>
      <div className="d-flex align-items-center">End date</div>
      <div style={{ width: 220 }} className="d-flex justify-content-between align-items-center">
        <Select
          options={endDateOptions}
          onChange={(e) => onEndDateTypeChange(e.target.value as EndDateTypeLabel)}
          currentValue={mapEndDateTypeLabel(endDateType)}
          style={{
            backgroundColor: 'white',
            marginRight: (endDateType === 'duration' || endDateType === 'fixed_date') && '5px',
          }}
        />
        {endDateType === 'duration' && (
          <DurationInput value={endDateDurationDays} onChange={onDurationDaysChange} />
        )}
        {endDateType === 'fixed_date' && endDate && (
          <DatePicker
            placeHolder="End date"
            value={convertDateToDatePickValue(new Date(endDate), true)}
            onChange={onFixedDateChange}
            minimumDate={convertDateToDatePickValue(new Date(startDate), true)}
            style={{
              backgroundColor: 'white',
              maxWidth: '105px',
              marginLeft: '5px',
              maxHeight: 19.5,
            }}
            format="MM.DD.YYYY"
          />
        )}
      </div>
    </div>
  );
};
export default EndDateInput;
