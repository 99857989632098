import moment from 'moment';
import React from 'react';
import UserBubble from '../../../../../../../../../../../../../components/UserBubble';
import File from './components/File';
import styles from './styles.module.css';

export default function FileMessage({ message, from, className, timeStampClassName }) {
  return (
    <div className={className}>
      <div>
        <UserBubble user={from} />
      </div>
      <div style={{ position: 'relative' }}>
        <div className={styles.filesContainer}>
          {message.data.map((file) => (
            <File name={file} />
          ))}
        </div>
        <div className={timeStampClassName}>{moment(message.createdAt).format('h:mm a')}</div>
      </div>
    </div>
  );
}
