import InputField from 'components/InputField';
import VytracSurveyBuilder from 'components/VytracSurveyBuilder';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import { getQuestionnaire } from 'services/questionnaireService';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import AnswerNode from '../QuestionnaireNodes/AnswerNode';
import QuestionNode from '../QuestionnaireNodes/QuestionNode';
import QuestionnaireTemplatesList from './QuestionnaireTemplatesList';
import styles from './styles.module.css';

interface IModalBody {
  action: 'add' | 'delete';
  selectedTemplate: IQuestionnaire;
  setSelectedTemplate: Dispatch<SetStateAction<IQuestionnaire>>;
  elements: any;
  setElements: Dispatch<SetStateAction<any>>;
  newTemplateName: string;
  setNewTemplateName: Dispatch<SetStateAction<string>>;
  isNameRepeated: boolean;
}
const ModalBody = ({
  action,
  selectedTemplate,
  setSelectedTemplate,
  elements,
  setElements,
  newTemplateName,
  setNewTemplateName,
  isNameRepeated,
}: IModalBody) => {
  const {
    templateState: {
      templateCarePlan: { questionnaireList },
    },
  } = useTemplateContext();
  const [initialValues, setInitialValues] = useState({ questions: [], answers: [], edges: [] });

  //Cache for templates questionnaires details
  const [questionnaireRegistry, setQuestionnaireRegistry] = useState<
    Record<number, { name: any; answers: any; questions: any; edges: any }>
  >({});

  const templateCopyQuestionnaires = useMemo(() => {
    return questionnaireList.filter((q) => q.template_id && !q.patient_id);
  }, [questionnaireList]);

  const templateQuestionnaires = useMemo(() => {
    return questionnaireList.filter((q) => !q.template_id && !q.patient_id);
  }, [questionnaireList]);

  const fetchQuestionnaire = useCallback(async () => {
    try {
      const id = selectedTemplate.id;
      const data = await getQuestionnaire(id);
      setInitialValues(data);
      setQuestionnaireRegistry((prev) => ({ ...prev, [id]: data }));
    } catch (e) {
      throw e;
    }
  }, [selectedTemplate]);

  // Fetch questionnaire template details (from api and from cache)
  useEffect(() => {
    if (selectedTemplate?.id && !questionnaireRegistry[selectedTemplate.id]) {
      fetchQuestionnaire();
    } else if (selectedTemplate?.id) {
      setInitialValues(questionnaireRegistry[selectedTemplate.id]);
    }
    return () => {
      setInitialValues({ questions: [], answers: [], edges: [] });
    };
  }, [selectedTemplate, questionnaireRegistry, fetchQuestionnaire]);

  //Render
  return (
    <>
      <div className="d-flex h-100">
        <div className={styles['questionnaire-builder']}>
          <div className="d-flex flex-column w-50">
            {action === 'add' && (
              <div>
                <label className="font-size-medium font-weight-bold m-0">Questionnaire name</label>
                <InputField
                  className={styles.nameInput}
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  placeholder="Input questionnaire name..."
                  errorLabel={isNameRepeated && 'Name is not unique'}
                  errorClassName={styles.errorLabel}
                />
              </div>
            )}

            <QuestionnaireTemplatesList
              templates={action === 'add' ? templateQuestionnaires : templateCopyQuestionnaires}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplate={selectedTemplate}
            />
          </div>

          <ReactFlowProvider>
            <VytracSurveyBuilder
              elements={elements}
              setElements={setElements as any}
              questionNodeType={QuestionNode}
              answerNodeType={AnswerNode}
              initialValues={initialValues}
              className={styles['questionnaire']}
              editable={action === 'add'}
            />
          </ReactFlowProvider>
        </div>
      </div>
    </>
  );
};
export default ModalBody;
