import { EmailSetting, EmailShortCode, EmailTypeTuple } from 'types/ApiModels/Administration';
import { negativeLocalIdCreator } from 'util/utils';
import { EmailSettingAction, EmailSettingActionTypes } from './emailSettingsActionTypes';

export interface EmailSettingsState {
  list: EmailSetting[];
  shortCodes: EmailShortCode[];
  types: EmailTypeTuple[];
  currentEmailSetting: EmailSetting;
  insertShortCodeToEditor: (shortCode: EmailShortCode) => void;
  addBlankEmailSetting: () => void;
}

export const emailSettingsInitialState: EmailSettingsState = {
  list: null,
  types: null,
  shortCodes: null,
  currentEmailSetting: null,
  insertShortCodeToEditor: () => undefined,
  addBlankEmailSetting: () => undefined,
};

const createLocalNegativeEmailSettingId = negativeLocalIdCreator();

const emailSettingsReducer = (
  state: EmailSettingsState,
  action: EmailSettingAction
): EmailSettingsState => {
  switch (action.type) {
    case EmailSettingActionTypes.SET_EMAIL_SETTINGS: {
      return { ...state, list: action.payload.emailSettings };
    }
    case EmailSettingActionTypes.SET_SHORT_CODES: {
      return { ...state, shortCodes: action.payload.shortCodes };
    }
    case EmailSettingActionTypes.SET_EMAIL_TYPES: {
      return { ...state, types: action.payload.types };
    }
    case EmailSettingActionTypes.SET_CURRENT_EMAIL_SETTING: {
      return { ...state, currentEmailSetting: action.payload.emailSetting };
    }
    case EmailSettingActionTypes.UPDATE_CURRENT_EMAIL_SETTING_TYPE: {
      if (state.currentEmailSetting && action.payload.type !== state.currentEmailSetting.type) {
        const emailSettingIdx = state.list.findIndex(
          (es) => es.id === state.currentEmailSetting.id
        );
        const listCopy = [...state.list];
        listCopy[emailSettingIdx] = { ...listCopy[emailSettingIdx], type: action.payload.type };
        return {
          ...state,
          list: listCopy,
          currentEmailSetting: { ...state.currentEmailSetting, type: action.payload.type },
        };
      }
      return state;
    }
    case EmailSettingActionTypes.SET_INSERT_SHORT_CODE_TO_EDITOR: {
      return { ...state, insertShortCodeToEditor: action.payload.insertShortCode };
    }
    case EmailSettingActionTypes.ADD_BLANK_EMAIL_SETTING: {
      const newEmailSetting: EmailSetting = {
        active: true,
        body: '',
        //the negative id would tell us that it is a new email setting.
        id: createLocalNegativeEmailSettingId(),
        type: state.types?.[0][0],
        name: state.types?.[0][1],
        subject: '',
      };
      return {
        ...state,
        currentEmailSetting: newEmailSetting,
        list: [...state.list, newEmailSetting],
      };
    }
    case EmailSettingActionTypes.DELETE_EMAIL_SETTING: {
      return { ...state, list: state.list.filter((es) => es.id !== action.payload.emailSettingId) };
    }
    case EmailSettingActionTypes.UPDATE_EMAIL_SETTING: {
      const id = action.payload.oldId ? action.payload.oldId : action.payload.emailSetting.id;
      const updatedIdx = state.list.findIndex((es) => es.id === id);
      const listCopy = [...state.list];
      listCopy[updatedIdx] = { ...listCopy[updatedIdx], ...action.payload.emailSetting };
      return { ...state, list: listCopy };
    }

    default:
      return state;
  }
};

export default emailSettingsReducer;
