import Card from 'components/Card';
import React, { useContext, useEffect } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import {
  getAllQuestionnaries,
  getAvailableQuestionnairesOfPatient,
  getPatientQuestionnaires,
} from 'services/questionnaireService';
import { setBreadcrumbPathList } from 'util/commonContext/NavigationContext';
import QuestionnaireContext from '../../util/Context';
import Header from '../Header';
import PatientQuestionnairesTable from './components/PatientQuestionnairesTable';
import AvailableQuestionnairesTable from './components/AvailableQuestionnairesTable';
import styles from './styles.module.css';
import { mapApiToPatientQuestionnaire } from './utils/mapPatientQuestionnaire';

const PatientQuestionnaires = () => {
  // const { setState } = useContext(Context);
  const { selectedPatient } = usePatientFormikContext();
  const {
    dispatch,
    patientState: {
      patientNavigation: { breadcrumbPathList },
    },
  } = usePatientContext();
  const { setQuestionnaireState, questionnaireState } = useContext(QuestionnaireContext);
  const [refetch, setRefetch] = React.useState(false);

  useEffect(() => {
    const fetchTemplateQuestionnaires = async () => {
      try {
        const data = await getAllQuestionnaries();
        setQuestionnaireState((prev) => ({
          ...prev,
          templateQuestionnaires: data,
        }));
      } catch (e) {
        console.error(e);
      }
    };

    const fetchQuestionnaires = async (id) => {
      try {
        const data = await getAvailableQuestionnairesOfPatient(id);
        setQuestionnaireState((prev) => ({
          ...prev,
          availableQuestionnaires: data,
        }));
      } catch (ex) {
        console.error(ex);
      }
    };
    const fetchPatientQuestionnaires = async (id) => {
      try {
        const data = await getPatientQuestionnaires(id);
        const parsedData = mapApiToPatientQuestionnaire(data);
        setQuestionnaireState((prev) => ({ ...prev, questionnaireList: parsedData }));
      } catch (ex) {
        console.error(ex);
      }
    };
    fetchTemplateQuestionnaires();
    if (selectedPatient.id) {
      fetchQuestionnaires(selectedPatient.id);
      fetchPatientQuestionnaires(selectedPatient.id);
    }
  }, [selectedPatient.id, refetch]);

  useEffect(() => {
    // setState((prevState) => ({
    //   ...prevState,
    //   breadcrumbPathList: [...prevState.breadcrumbPathList.slice(0, 4)],
    // }));
    dispatch(setBreadcrumbPathList([...breadcrumbPathList.slice(0, 4)]));
  }, []);

  return (
    <div className={styles.content}>
      <Card
        headers={[
          <Header
            title={`Patient questionnaires (${questionnaireState.questionnaireList.length})`}
          />,
        ]}
        className={styles.card}
      >
        <PatientQuestionnairesTable data={questionnaireState.questionnaireList} />
      </Card>
      <Card
        headers={[
          <Header
            title={`Available questionnaires (${questionnaireState.availableQuestionnaires.length})`}
          />,
        ]}
        className={styles.card}
      >
        <AvailableQuestionnairesTable
          data={questionnaireState.availableQuestionnaires}
          setRefetch={setRefetch}
        />
      </Card>
    </div>
  );
};

export default PatientQuestionnaires;
