import { AccountSettingsAction, AccountSettingsActionTypes } from "./accountSettingsActionTypes"

export interface AccountSettingsState {
  submitAccountSettingsForm: () => void
  resetAccountSettingsForm: () => void
  dirtyAccountSettingsForm: boolean
} 

export const accountSettingsInitialState:AccountSettingsState = {
  submitAccountSettingsForm: null,
  resetAccountSettingsForm: null,
  dirtyAccountSettingsForm: false
}

const accountSettingsReducer = (state:AccountSettingsState,action:AccountSettingsAction):AccountSettingsState => {
  switch (action.type) {
   
    case AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_FORM_DIRTY: {
      return { ...state, dirtyAccountSettingsForm: action.payload.dirty };
    }
    case AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_SUBMIT_FORM: {
      return { ...state, submitAccountSettingsForm: action.payload.submitForm };
    }
    case AccountSettingsActionTypes.SET_ACCOUNT_SETTINGS_RESET_FORM: {
      return { ...state, resetAccountSettingsForm: action.payload.resetForm };
    }
    default:
      return state;
  } 
} 

export default accountSettingsReducer;