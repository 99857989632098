import { useAuthContext } from 'auth/store';
import ActivityPointsIcon from 'components/ActivityPointsIcon';
import { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import { ProfileOption, profileOptions } from './utils';

interface PatientProfilePopupProps {
  onMouseLeave: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseEnter: (e: MouseEvent<HTMLDivElement>) => void;
}
const PatientProfilePopup: FC<PatientProfilePopupProps> = ({
  onMouseLeave,
  onMouseEnter,
}: PatientProfilePopupProps) => {
  const history = useHistory();
  const { currentUser, setCurrentUser, setProviderInfo } = useAuthContext();

  return (
    <div
      className={`${styles['patient-profile-popup']} rounded position-absolute d-flex flex-column`}
      tabIndex={0}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <div
        className={`d-flex flex-column gap-lg justify-content-center ${styles['image-points-container']}`}
      >
        <div className="d-flex justify-content-center">
          <ActivityPointsIcon />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          {currentUser?.activity_points ? (
            <>
              <div>Great day you have received </div>
              <div className={`${styles['activity-points-label']}`}>
                {currentUser.activity_points} activity points
              </div>
              <div>today </div>
            </>
          ) : (
            <div className="text-center">You have not yet earned any activity points, keep up!</div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center">
        {profileOptions.map((po, idx) => {
          const currentClass =
            idx === 0
              ? styles['both-borders']
              : idx !== profileOptions.length - 1
              ? styles['border-bottom']
              : '';
          return (
            <div
              className={`bg-transparent link text-center py-3 ${currentClass} ${
                po.props?.disabled ? styles['disabled'] : ''
              }`}
              key={po.label}
              onClick={() => {
                if (po.label === ProfileOption.LOGOUT) {
                  setCurrentUser(null);
                  setProviderInfo(null);
                }
                po?.action(history);
              }}
              hidden={po.props?.hidden}
            >
              {po.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PatientProfilePopup;
