import UserBubble from 'components/UserBubble';
import { FC } from 'react';
import { PatientSignup } from 'types/ApiModels/Administration/Dashboard';
import { getUserDisplayName } from 'util/userUtils';

interface PatientSignupProps {
  patientSignup: PatientSignup;
}

const PatientSignupCard: FC<PatientSignupProps> = ({ patientSignup }: PatientSignupProps) => {
  const [first_name, last_name] = patientSignup.full_name.split(' ');

  return (
    <div className="d-flex justify-content-between bg-light-gray rounded pl-2 pr-4 py-3">
      <div>
        <div className="flex-grow-1 d-flex align-items-center gap">
          <div>
            {patientSignup.photo ? (
              <img src={patientSignup.photo} className="mx-3" height={30} width={30} />
            ) : (
              <UserBubble user={{ first_name, last_name, username: '' }} includeMargin={false} />
            )}
          </div>
          <div>{getUserDisplayName({ first_name, last_name, username: '' })}</div>
        </div>
        <div></div>
      </div>
      <div className="text-gray-low-risk d-flex align-items-center font-weight-md">
        {patientSignup.alerts_count} alerts
      </div>
    </div>
  );
};
export default PatientSignupCard;
