import { FC } from 'react';
import { RoleSignup } from 'types/ApiModels/Administration/Dashboard';

interface RoleSignupCardProps {
  roleSignup: RoleSignup;
}

const RoleSignupCard: FC<RoleSignupCardProps> = ({ roleSignup }: RoleSignupCardProps) => {
  return (
    <div className="d-flex justify-content-between px-4 py-3 bg-light-gray rounded">
      <div>{roleSignup.name}</div>
      <div className="text-gray-low-risk">{roleSignup.user_count} users</div>
    </div>
  );
};
export default RoleSignupCard;
