import { FC } from 'react';
import styles from './styles.module.css';

interface PaginationControlsAltProps {
  currentPage: number;
  maxPage: number;
  handleNext: () => void;
  handlePrevious: () => void;
  handleLast: () => void;
  handleFirst: () => void;
}

const PaginationControlsAlt: FC<PaginationControlsAltProps> = ({
  currentPage,
  maxPage,
  handleNext,
  handlePrevious,
  handleLast,
  handleFirst,
}: PaginationControlsAltProps) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === maxPage;
  return (
    <div className={styles.main}>
      <button disabled={isFirst} type="button" onClick={handleFirst}>
        first
      </button>
      <button disabled={isFirst} type="button" onClick={handlePrevious}>
        previous
      </button>
      <span>
        {currentPage} of {maxPage} pages
      </span>
      <button disabled={isLast} type="button" onClick={handleNext}>
        next
      </button>
      <button disabled={isLast} type="button" onClick={handleLast}>
        last
      </button>
    </div>
  );
};
export default PaginationControlsAlt;
