import ProfileImage from 'components/ProfileImage';
import React, { CSSProperties } from 'react';
import AvatarStatus from 'types/ApiModels/Patients/AvatarStatus';
import { SexLabelId } from 'types/ApiModels/Users/Sex';
import PatientRingContainer from './components/PatientRingContainer';
import { convertCountToDegrees } from './utils/convertCountToDegrees';
import { convertCountToPercenteage } from './utils/convertCountToPercenteage';

interface PatientProfileRingProps {
  currentPatient: {
    avatar_status: AvatarStatus;
    imageUrl?: string;
    sex?: SexLabelId;
  };
  size?: number;
  padding?: number;
}

const blueColor = '#80CCE4';
const spacing = 3;

/**
 * Alternative with minimal props
 */
const PatientProfileRingAlt = ({
  currentPatient,
  padding = 0,
  size = 72,
}: PatientProfileRingProps) => {
  const { avatar_status } = currentPatient;
  if (!avatar_status) return null;
  const { adherence, normal_readings, yellow_alerts, read_alerts } = avatar_status;

  const imageStyle: CSSProperties = {
    width: size * 0.72,
    height: size * 0.72,
    margin: size * 0.14,
    borderRadius: '1rem',
    zIndex: 1,
  };

  return (
    <div className="d-flex" style={{ padding: padding }}>
      <ProfileImage src={currentPatient?.imageUrl} sex={currentPatient?.sex} style={imageStyle} />

      <PatientRingContainer size={size}>
        <svg className="patient-ring-big" style={{ width: size, height: size }} viewBox="0 0 36 36">
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={blueColor}
            strokeWidth="2"
            strokeDasharray={convertCountToPercenteage(adherence, avatar_status, spacing) + ', 100'}
          />
        </svg>
      </PatientRingContainer>
      <PatientRingContainer size={size}>
        <svg
          className="patient-ring-big"
          viewBox="0 0 36 36"
          style={{
            transform: 'rotate(' + convertCountToDegrees(adherence, avatar_status) + 'deg)',
            width: size,
            height: size,
          }}
        >
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke="#F8CA54"
            strokeWidth="2"
            strokeDasharray={
              convertCountToPercenteage(yellow_alerts, avatar_status, spacing) + ', 100'
            }
          />
        </svg>
      </PatientRingContainer>
      <PatientRingContainer size={size}>
        <svg
          className="patient-ring-big"
          viewBox="0 0 36 36"
          style={{
            transform:
              'rotate(' + convertCountToDegrees(adherence + yellow_alerts, avatar_status) + 'deg)',
            width: size,
            height: size,
          }}
        >
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke="#F5485C"
            strokeWidth="2"
            strokeDasharray={
              convertCountToPercenteage(read_alerts, avatar_status, spacing) + ', 100'
            }
          />
        </svg>
      </PatientRingContainer>
      <PatientRingContainer size={size}>
        <svg
          className="patient-ring-big"
          viewBox="0 0 36 36"
          style={{
            transform:
              'rotate(' +
              convertCountToDegrees(adherence + yellow_alerts + read_alerts, avatar_status) +
              'deg)',
            width: size,
            height: size,
          }}
        >
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke="#CFD6E2"
            strokeWidth="2"
            strokeDasharray={
              convertCountToPercenteage(normal_readings, avatar_status, spacing) + ', 100'
            }
          />
        </svg>
      </PatientRingContainer>
    </div>
  );
};

export default PatientProfileRingAlt;
