import styles from './styles.module.css';
import cloudArrowDown from 'assets/icons/cloudArrowDown.png';
import file from 'assets/icons/file.png';
import FiltersButton from 'components/FiltersButton';

export default function Files({ className, files }) {
  //Renders Download Button with cloudArrowDown image
  const downloadButton = (
    <img
      style={{ width: 15, height: 13, marginRight: 10 }}
      className="cursor-pointer"
      src={cloudArrowDown}
      alt=""
      onClick={(e) => e.stopPropagation()}
    />
  );

  //imageSrc could be null
  //Renders File Icon,  if file has image uses that, otherwise loads default file image
  const renderImage = (imageSrc) => {
    return (
      <div className={styles.imageContainer}>
        {imageSrc ? (
          <img src={imageSrc} alt="" className={styles.image} />
        ) : (
          <img src={file} alt="" className={styles.defaultImage} />
        )}
      </div>
    );
  };

  //Render File element
  const renderFiles = (fileList) =>
    fileList.map((file) => (
      <div className={styles.file}>
        <div className="d-flex">
          <div className={styles.fileImage}>{renderImage(file.image)}</div>
          <div className={styles.fileName}>{file.name}</div>
        </div>
        <div className="d-flex align-items-center">{downloadButton}</div>
      </div>
    ));

  return (
    <div className={className}>
      <div className="d-flex flex-column shadow-sm rounded">
        <div className={styles.header}>
          <div>Encounter files</div>
          <div className="d-flex align-items-center">
            {downloadButton}
            <FiltersButton />
          </div>
        </div>
        <hr className={styles.horizontalLine} style={{ margin: 0 }} />
        <div className={styles.content}>{renderFiles(files)}</div>
      </div>
    </div>
  );
}
