import BreadCrumbPath from "types/Shared/BreadCrumbPath";
import { setBreadcrumTitle } from "util/themeUtils";

export const billingInitialBreadcrumbPathList: BreadCrumbPath[] = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'revenue management',
    url: '/revenue-management',
  },
];
