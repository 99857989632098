import React from 'react';
import { convertDateToDatePickValue } from 'util/dateUtils';
import DatePicker from '../../../DatePicker';
import NoteCheckbox from '../NoteCheckbox';
import TextArea from '../TextArea';
import styles from './styles.module.css';

interface IScheduleNote {
  label: string;
  boxShadow: string;
  onChangeCheckbox: () => void;
  checked: boolean;
  onNoteChange: (newValue: string) => void;
  currentValue: string;
  textAreaClassName?: string;
}
export default function ScheduleNote({
  label,
  boxShadow,
  onChangeCheckbox,
  checked,
  onNoteChange,
  currentValue,
  textAreaClassName = '',
}: IScheduleNote) {
  return (
    <NoteCheckbox
      label={label}
      onChangeCheckbox={onChangeCheckbox}
      checked={checked}
      boxShadow={boxShadow}
    >
      <div>
        <div className={styles.rowContainer}>
          <DatePicker
            placeHolder="Select start date"
            value={convertDateToDatePickValue(new Date())}
            onChange={() => {}}
            style={{ height: 19.5 }}
          />
        </div>
        <TextArea
          value={currentValue}
          onTextAreaChange={(e) => onNoteChange(e.target.value)}
          placeholder="Add a note..."
          className={textAreaClassName}
        />
      </div>
    </NoteCheckbox>
  );
}
