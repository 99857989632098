import CareTeamTier from 'types/ApiModels/CareTeam/CareTeamTier';

export {
  CareTeamCreateValidationSchema,
  CareTeamEditValidationSchema,
} from './CareTeamCreateEditValidationSchema';

export const isInTier = (providerId: number, tiers: CareTeamTier[]) => {
  return tiers.some((t) => t.providers.some((p) => p.id === providerId));
};
