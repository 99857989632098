import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

/**
 * Fetch a list from remote, use when list does not change over time and you don't need to add/remove values ig: dropdown data
 * @param fetcher fetcher function
 * @deprecated Use `useQuery` from react-query instead
 */
export const useFetchList = <T>(fetcher: () => Promise<T[]>): [data: T[], error: AxiosResponse] => {
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<AxiosResponse>();

  const fetchFunction = useCallback(async () => {
    try {
      const res = await fetcher();
      setData(res);
    } catch (error) {
      setError((error as AxiosError).response);
    }
  }, [fetcher]);

  useEffect(() => {
    fetchFunction();
  }, [fetchFunction]);

  return [data, error];
};
