import {
  ChartData,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import Content from 'screens/Billing/components/Content';

export function getGradient(ctx, chartArea) {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    // gradient.addColorStop(0.5, Utils.CHART_COLORS.yellow);
    gradient.addColorStop(0.0617, '#EAF1F7');
    gradient.addColorStop(1, 'rgba(246, 250, 252, 0.59)');
  }

  return gradient;
}

export const mainDatasetConfig = (dataset: number[]) => ({
  data: dataset,
  borderColor: '#8FAAC3',
  pointBackgroundColor: '#8FAAC3',
  pointBorderWidth: 3,
  pointRadius: Array.from({ length: dataset.length }, (_, index) =>
    index === dataset.length - 1 ? 3 : 0
  ),
  fill: {
    target: 'origin',
    below: '#ffff',
    above: function (context) {
      const chart = context.chart;
      const { ctx, chartArea } = chart;

      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }
      return getGradient(ctx, chartArea);
    } as any, // Area will be red above the origin
  },
});

export const otherDatasetConfig = (dataset: number[]) => ({
  data: Array.from({ length: dataset.length }, () => Math.max(...dataset)),
  borderColor: 'transparent',
  pointRadius: 0,
  pointHitRadius: 0,
  fill: {
    target: '-1',
    above: 'white',
  },
});

export const mainChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  pointRadius: 1,
  tension: 0.5,
  height: 200,
  layout: {
    padding: {
      x: 1000,
    },
  },
  scales: {
    y: {
      grid: {
        color: '#transparent',
      },
      display: false,
    },
    x: {
      grid: {
        color: 'transparent',
      },
      ticks: {
        autoSkip: true,
        //TODO: get actual date
        callback: (value, index, ticks) => {
          const today = new Date();
          const date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - (ticks.length - index - 1)
          );
          return `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}/${
            date.getDate() < 10 ? '0' : ''
          }${date.getDate()}`;
        },
        font: {
          size: 11,
          weight: '500',
        },
        color: '#8FAAC3',
        minRotation: 0,
        maxRotation: 0,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
