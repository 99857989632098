export enum TrackingAction {
  VIEW = 'view',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum TrackingType {
  ALERT = 'alert',
  PATIENT_TODO = 'patient_todo',
  ADHERENCE = 'adherence',
  THRESHOLD = 'threshold',
  MESSAGE = 'message',
  CARE_PLAN_ACTION = 'care_plan_action',
  CARE_PLAN_TEMPLATE = 'care_plan_template',
}

export interface ITrackingLog {
  id?: number;
  tab: string;
  duration: number;
  action: TrackingAction;
  description: string;
  object_type: TrackingType;
  object_id: number;
  created_at?: string;
  updated_at?: string;
  activity: number;
  patient: number;
  created_by?: number;
}
