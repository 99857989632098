import UserBubble from 'components/UserBubble';
import { useEffect, useRef } from 'react';
import Hour12Format from 'types/Shared/Hour12format';
import { timeAgo, get12hFormatObject } from 'util/dateUtils';
import styles from './styles.module.css';
interface CareTeamProps {
  careTeam: string[];
  dateBelow?: string;
}
const CareTeam = ({ careTeam, dateBelow }: CareTeamProps) => {
  const date = useRef(new Date(dateBelow));
  const hourAmPm = useRef<Hour12Format>();
  useEffect(() => {
    hourAmPm.current = get12hFormatObject(date.current.getHours());
  }, [dateBelow]);
  return (
    <div className="d-flex justify-content-between">
      <div className={styles.careTeamContainer}>
        {careTeam.map((member, index) => (
          <UserBubble
            key={index}
            user={{ first_name: member.split(' ')[0], last_name: member.split(' ')[1] }}
          />
        ))}
        <UserBubble
          user={{ first_name: '', last_name: '+' }}
          containerClassNameList={[styles.add]}
        />
      </div>
      {dateBelow && (
        <div className="d-flex align-items-center">
          {timeAgo(date.current)}&nbsp;•&nbsp;{hourAmPm.current?.hour12Format}
          <span className="text-uppercase">{hourAmPm.current?.ampm}</span>
        </div>
      )}
    </div>
  );
};
export default CareTeam;
