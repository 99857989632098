import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface IConfirmDeleteModal {
  show: boolean;
  name: string;
  onCancel: () => void;
  onDelete: () => void;
}
const ConfirmDeleteModal = ({ onCancel, onDelete, show, name }: IConfirmDeleteModal) => {
  return (
    <VytracModal
      show={show}
      title="Delete template"
      body={
        <span className="font-size-big">
          Are you sure you want to delete template <b>{name}</b>?
        </span>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={onCancel} />
          <Button label="delete" variant="delete" onClick={onDelete} />
        </div>
      }
      onClose={onCancel}
    />
  );
};
export default ConfirmDeleteModal;
