import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import {
  IPatientCarePlan,
  IPatientCarePlanAction,
  IPatientTimelineAction,
} from 'types/ApiModels/Patients/CarePlan';
import { PatientCarePlanAction, PatientCarePlanActionTypes } from './patientCarePlanActionType';

export const setCarePlanState = (carePlanState: IPatientCarePlan): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_CAREPLAN_STATE,
  payload: {
    carePlanState,
  },
});

export const setSelectedDate = (selectedDate: Date): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_SELECTED_DATE,
  payload: {
    selectedDate,
  },
});

export const setPatientActions = (
  patientActions: IPatientCarePlanAction[]
): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_PATIENT_ACTIONS,
  payload: {
    patientActions,
  },
});

export const setPreviousPatientActions = (): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_PREVIOUS_PATIENT_ACTIONS,
});

export const setTimelineActions = (
  timelineActions: IPatientTimelineAction[]
): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_TIMELINE_ACTIONS,
  payload: {
    timelineActions,
  },
});

export const setPeriodSelected = (timePeriod: TimePeriodOptions): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_PERIOD_SELECTED,
  payload: {
    timePeriod,
  },
});

export const setSelectedAction = (
  selectedAction: IPatientTimelineAction
): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_SELECTED_ACTION,
  payload: {
    selectedAction,
  },
});

export const setCarePlanModalEventType = (
  carePlanModalEventType: CarePlanActionEventType
): PatientCarePlanAction => ({
  type: PatientCarePlanActionTypes.SET_CAREPLAN_MODAL_EVENT_TYPE,
  payload: {
    carePlanModalEventType,
  },
});
