import React from 'react';
import Button from '../../../../../../../../components/Button';
import styles from './styles.module.css';
const ModalBody = ({fun, val, setShowModal, message}) => {
  return (
    <>
      <p className={styles.modalBody}>{message}</p>
      <div className={styles.buttonsContainer}>
        <Button
          label="Cancel"
          variant="cancel"
          className={styles.cancelButton}
          onClick={() => setShowModal(false)}
        />
        <Button
          label="Confirm"
          variant="confirm"
          className={styles.confirmButton}
          onClick={() => {
            setShowModal(false);
            fun(val);
          }}
        />
      </div>
    </>
  );
};

export default ModalBody;
