import arrowLeftSrc from 'assets/icons/arrow_left.png';
import arrowRightSrc from 'assets/icons/arrow_right.png';
import styles from './styles.module.css';

interface ActivityTimelineColumnHeaderProps {
  label: string;
  arrowLeft: boolean;
  arrowRight: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  activitiesCount: number;
  columnCount: number;
  isCurrent?: boolean;
}

const ActivityTimelineColumnHeader = ({
  label,
  arrowLeft,
  arrowRight,
  handleNext,
  handlePrevious,
  activitiesCount,
  columnCount,
  isCurrent = false,
}: ActivityTimelineColumnHeaderProps) => (
  <>
    <div
      className={`d-flex ${!arrowLeft && !arrowRight ? 'justify-content-center' : ''} ${
        styles['header-container']
      } ${isCurrent && styles['current-date']}`}
      style={{ width: `${100 / (columnCount + 0.1)}%` }}
    >
      {arrowLeft && (
        <div
          className={`d-flex align-items-center h-100 pl-2 ${styles['arrow-left']}`}
          onClick={handlePrevious}
        >
          <img src={arrowLeftSrc} alt="timeline go back" />
        </div>
      )}

      <div className="flex-grow-1 text-center text-uppercase font-size-small">
        {label} ({activitiesCount ?? 0})
      </div>

      {arrowRight && (
        <div
          className={`d-flex align-items-center h-100 pr-2 ${styles['arrow-right']}`}
          onClick={handleNext}
        >
          <img src={arrowRightSrc} alt="timeline go forward" />
        </div>
      )}
    </div>
  </>
);

export default ActivityTimelineColumnHeader;
