import { VytalResponse } from 'types/ApiModels/Patients/Vytal';
import { ChartType } from 'types/VytalChart/chartType';
import { IDataset } from 'types/VytalChart/dataPoint';
import { nullWrap } from 'util/goalChart';

export const generateDataset = (dataPoints: VytalResponse[], chartType: ChartType): IDataset => {
  const dataset: IDataset = {
    data: nullWrap(dataPoints.map((dataPoint) => Number(dataPoint?.vytal?.value)).reverse()),
  };
  return dataset;
};
