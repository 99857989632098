import { createTrackingLog } from 'services/trackingService';
import { ITrackingLog, TrackingAction, TrackingType } from 'types/ApiModels/Tracking/TrackingLog';

const sendDashboardTrackingLog = (
  duration: number,
  chartingId: number,
  trackingType: TrackingType,
  patientId: number
) => {
  const log: ITrackingLog = {
    tab: null,
    duration: duration,
    action: TrackingAction.VIEW,
    description: 'Dashboard tracking',
    object_type: trackingType,
    object_id: null,
    activity: chartingId,
    patient: patientId,
  };
  createTrackingLog(log);
};
export default sendDashboardTrackingLog;
