import FiltersButton from 'components/FiltersButton';
import GenericButton from 'components/GenericButton';
import GenericTable from 'components/GenericTable';
import SearchBar from 'components/SearchBar';
import React, { useContext, useEffect, useState } from 'react';
import GenericColumn from 'types/Shared/GenericColumn';
import Context from '../../../../util/Context';
import { breadcrumbsPathMap } from '../../util/breadcrumbsPathMap';
import { routeEnum } from '../../util/routeEnum';
import EditIcon from 'assets/icons/edit.png';
import DeleteIcon from 'assets/icons/delete.png';
import styles from './styles.module.css';
import { FormCheck } from 'react-bootstrap';
import Button from 'components/Button';
import Checkbox from 'components/form/Checkbox';
import ChargesConfigModal from './ChargesConfigModal/ChangesConfigModal';
import { QuickFilters } from 'components/Pagination';
import { QuickFilter } from 'types/Shared/Pagination';
import sortEnum from '../../util/sortEnum';
import sortLabelMap from '../../util/sortLabelMap';

const ChargesConfig = () => {
  const Actions = () => (
    <div className="d-flex">
      <Button
        className={styles.button}
        onClick={() => {
          setShowModal(true);
        }}
        label="add insurer"
      />
    </div>
  );

  const { state, setState } = useContext(Context);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      breadcrumbPathList: breadcrumbsPathMap()[routeEnum.CHARGES_CONFIG],
      actions: Actions,
    }));
  }, []);

  const searchCodes = () => {};
  const onHandleEdit = () => {};
  const handleDelete = () => {};

  const data = [
    {
      insurance_company: 'Kaiser Foundation',
      _default: true,
      patients: 15,
      last_update: new Date().toDateString(),
      status: 'No changes',
      active: true,
    },
    {
      insurance_company: 'Medicare Minnesota',
      _default: false,
      patients: 20,
      last_update: new Date().toDateString(),
      status: '* New updates available',
      active: true,
    },
    {
      insurance_company: 'Humana',
      _default: false,
      patients: 25,
      last_update: new Date().toDateString(),
      status: 'No changes',
      active: true,
    },
  ];

  const columns: GenericColumn[] = [
    {
      title: 'Insurance company',
      key: 'insurance_company',
      style: {
        width: '50%',
      },
      render: ({ insurance_company }) => (
        <div className={styles.insuranceCompany}>{insurance_company}</div>
      ),
    },
    {
      title: 'Default',
      key: '_default',
      render: ({ _default }) => (
        <div className="">
          <Checkbox value={_default} hidden={!_default} />
        </div>
      ),
    },
    {
      title: 'Patients',
      key: 'patients',
      render: ({ patients }) => <div>{patients}</div>,
    },
    {
      title: 'Last Update',
      key: 'last_update',
    },
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ action, ...rowInfo }) => (
        <div className="d-flex">
          <GenericButton
            icon={EditIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
            onClick={() => onHandleEdit()}
          />
          <GenericButton
            icon={DeleteIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
            onClick={() => handleDelete()}
          />
        </div>
      ),
    },
    {
      title: 'Active',
      key: 'active',
      render: ({ active, ...rowInfo }) => (
        <div className="d-flex align-items-center" onClick={() => {}}>
          <FormCheck type="switch" checked={active} />
        </div>
      ),
    },
  ];
  const filters: QuickFilter[] = Object.keys(sortEnum).map((key) => ({
    sortKey: key,
    label: sortLabelMap[key],
    onClick: () => false,
    selected: false,
  }));

  return (
    <div
      className="dashboard-detail"
      style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05))' }}
    >
      <div className="dashboard-item-header d-flex flex-column gap">
        <div className="d-flex">
          <span className={styles.codeCharges}>Code charges</span>
          <span className={styles.checkUpdates}>Check for updates</span>
          <SearchBar
            placeholder="Quick search"
            className={styles.search}
            onChange={() => {
              searchCodes();
            }}
          />
          <FiltersButton />
        </div>
        <hr className={styles.horizontalLine} />

        <div className="d-flex w-100">
          <QuickFilters filters={filters} />
        </div>
      </div>
      <GenericTable rowClassName={styles.row} data={data} columns={columns} />
      <ChargesConfigModal
        handleChange={(months) => {}}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
      ></ChargesConfigModal>
    </div>
  );
};

export default ChargesConfig;
