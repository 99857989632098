import { History } from 'history';
import { logOut } from 'services/userService';

export const logOutAction = async (history: History) => {
  await logOut();
  history.push('/');
};
export const goToAccountAction = (history: History) => {
  history.push('/profile/account');
};
export const goToActivityPoints = (history: History) => {
  history.push('/profile/points');
};

export enum ProfileOption {
  MY_ACCOUNT = 'My account',
  MY_ACTIVITY_POINTS = 'My activity points',
  SWITCH_ACCOUNT = 'Switch account',
  LOGOUT = 'Log out',
}

export const profileOptions: {
  label: ProfileOption;
  props?: { disabled?: boolean; hidden?: boolean };
  action?: (history: History) => void;
}[] = [
  {
    label: ProfileOption.MY_ACCOUNT,
    action: goToAccountAction,
  },
  {
    label: ProfileOption.MY_ACTIVITY_POINTS,
    action: goToActivityPoints,
  },
  {
    label: ProfileOption.SWITCH_ACCOUNT,
    props: {
      hidden: true,
    },
  },
  {
    label: ProfileOption.LOGOUT,
    action: logOutAction,
  },
];
