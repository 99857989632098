import DateRangePicker from 'components/DateRangePicker';
import { useState } from 'react';
import styles from './styles.module.css';
interface HeaderProps {
  patientListLength: number;
}

const Header = ({ patientListLength }: HeaderProps) => {
  const [date, setDate] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  });
  return (
    <div className="dashboard-item-header">
      Highest Urgency ({patientListLength})
      <div
        className="date-selector"
        style={{ marginLeft: 15, marginTop: -2 }}
        onClick={(e) => e.stopPropagation()}
      >
        <DateRangePicker
          currentDateRange={date}
          handleDateRangePickerChange={(range) => setDate(range)}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.datePickerWrapper}
          inputClassName={styles.datePicker}
        />
      </div>
    </div>
  );
};
export default Header;
