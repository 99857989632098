import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import CalendarPicker from '../../../../../../../../components/CalendarPicker';
import { setOutOfOfficeDate } from '../ActiveSchedule/store/actions/actionCreators';
import { useActiveScheduleContext } from '../ActiveSchedule/store/context/ActiveScheduleContext';
import Footer from './components/Footer';
import styles from './styles.module.css';

const Calendar = () => {
  const { activeScheduleState, dispatch } = useActiveScheduleContext();

  const onChangeOutOfOfficeDate = (outOfOfficeDate) => {
    const newDate = convertDatepickToDate(outOfOfficeDate);
    dispatch(setOutOfOfficeDate(newDate));
  };

  return (
    <CalendarPicker
      value={
        activeScheduleState.outOfOfficeDate &&
        convertDateToDatePickValue(activeScheduleState.outOfOfficeDate)
      }
      onChange={(value) => onChangeOutOfOfficeDate(value)}
      title="Calendar"
      className={styles.calendar}
      containerClassName={styles.calendarContainer}
      titleClassName={styles.calendarTitle}
      renderFooter={() => <Footer />}
    />
  );
};

export default Calendar;
