import moment, { Moment } from 'moment';
import DatePickerValue, { DatePickerRangeOption } from 'types/Shared/DatePicker';
import Hour12Format, { AmPm } from 'types/Shared/Hour12format';
import HourInDay from 'types/Shared/HourInDay';

const DAYS_IN_A_WEEK = 7;
const MS_IN_DAY = 1000 * 24 * 60 * 60;

/**
 * Compares two dates without taking into account their times.
 */
export const areDatesEqual = (d1: Date, d2: Date) => {
  return d1.toDateString() === d2.toDateString();
};

/**
 * Add a certain amount of days to a date
 * @param {number} days number of days to add.
 * @returns a new date instance with the days already added
 */
export const addDays = (date: Date, days: number): Date => {
  if (days === 0) return date;
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const get12hFormatObject = (hours: number): Hour12Format => {
  let hour12Format: number;

  if (hours === 0) hour12Format = 12;
  else if (hours > 12) hour12Format = hours - 12;
  else hour12Format = hours;

  const ampm: AmPm = hours > 11 ? 'pm' : 'am';

  return {
    ampm,
    hour12Format,
  };
};

export const formatDottedDate = (date: Date): string =>
  `${(date.getMonth() + 1).toString().padStart(2, '0')}.${date
    .getDate()
    .toString()
    .padStart(2, '0')}.${date.getFullYear()}`;

export const formatDottedDateNoYear = (date: Date): string =>
  `${(date.getMonth() + 1).toString().padStart(2, '0')}.${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;

export const formatVytracDate = (date: Date): string => {
  const dottedDate = formatDottedDate(date);
  const { ampm, hour12Format } = get12hFormatObject(date.getHours());

  return `${dottedDate} · ${hour12Format}:${date.getMinutes().toString().padStart(2, '0')} ${ampm}`;
};

export const formatVytracDateNoYear = (date: Date): string => {
  const dottedDate = formatDottedDateNoYear(date);
  const { ampm, hour12Format } = get12hFormatObject(date.getHours());

  return `${dottedDate} · ${hour12Format}:${date.getMinutes().toString().padStart(2, '0')} ${ampm}`;
};

export const convertDateToDateTime = (date: Date): Date => {
  const mewDate = new Date(date);
  mewDate.setMinutes(mewDate.getMinutes() + mewDate.getTimezoneOffset());
  return mewDate;
};

export const hoursInDay: HourInDay[] = (() => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const { hour12Format, ampm } = get12hFormatObject(i);
    hours.push({
      value: i,
      label: `${hour12Format} ${ampm}`,
    });
  }
  return hours;
})();

/**
 * Given a date, get the week this date belongs to.
 */
export const getWeekArrayFromDate = (date: Date) => {
  let offset = date.getDay() * -1;
  return [...Array(DAYS_IN_A_WEEK).keys()].map((dayIdx) => {
    if (dayIdx !== 0) {
      offset++;
    }
    return addDays(date, offset);
  });
};

type DateFormat = 'yyyy-mm-dd';

export const formatDate = (date: Date, formatString: DateFormat): string => {
  switch (formatString) {
    case 'yyyy-mm-dd': {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    default:
      return date.toISOString();
  }
};

export const memoizedGetWeekArrayFromDate = () => {
  const memo: Date[][] = [];
  const memoRefs: Record<string, Date[]> = {};

  const addToMemo = (weeksArray: Date[]) => {
    const newLength = memo.push(weeksArray);
    weeksArray.forEach((d) => {
      memoRefs[formatDate(d, 'yyyy-mm-dd')] = memo[newLength - 1];
    });
  };

  return (date: Date) => {
    const formattedDate = formatDate(date, 'yyyy-mm-dd');

    if (memoRefs[formattedDate]) {
      return memoRefs[formattedDate];
    } else {
      let offset = date.getDay() * -1;
      const weekArray = [...Array(DAYS_IN_A_WEEK).keys()].map((dayIdx) => {
        if (dayIdx !== 0) {
          offset++;
        }
        return addDays(date, offset);
      });
      addToMemo(weekArray);
      return weekArray;
    }
  };
};

export const getDateIndexesFromWeek = (week: Date[], dates: Date[]): number[] => {
  const dateIndexes = [];
  dates.forEach((d, idx) => {
    dateIndexes[idx] = week.findIndex((day) => day.toDateString() === d.toDateString());
  });
  return dateIndexes;
};

/**
 * Determines whether a date is between two dates.
 */
export const dateIsBetween = (date: Date, begin: Date, end: Date) => date > begin && date < end;

export const getDateRange = (initialDate: Date, endDate: Date): Date[] => {
  let currentDate = initialDate;
  const result: Date[] = [];
  while (currentDate <= endDate) {
    result.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return result;
};

export const getFollowingWeeks = (start: Date, weeks: number): Date[][] => {
  const weekList: Date[][] = [];
  for (let i = 0; i < weeks; i++) {
    const week = getWeekArrayFromDate(addDays(start, i * DAYS_IN_A_WEEK));
    weekList.push(week);
  }
  return weekList;
};

/**
 *
 * @param reference the date which month's we want to get
 * @param forward the amount of weeks forward we want to get from that month
 * @returns
 */
export const getMonthWeeks = (reference: Date, forward = 0): Date[][] => {
  const monthStart = new Date(reference.getFullYear(), reference.getMonth(), 1);
  const end =
    forward === 0
      ? new Date(reference.getFullYear(), reference.getMonth() + 1, 0)
      : addDays(monthStart, DAYS_IN_A_WEEK * forward);
  const result: Date[][] = [];
  let currentDate = monthStart;
  while (currentDate < end) {
    result.push(getWeekArrayFromDate(currentDate));
    currentDate = addDays(currentDate, DAYS_IN_A_WEEK);
  }
  return result;
};

/**
 *
 * @param reference the date of the care plan period start we want to get
 * @param carePlanFrom the current start of the care plan period
 * @returns the previous closes start of the care plan period
 */
export const getPreviousCarePlanStart = (reference: Date, carePlanFrom: Date): Date => {
  const referenceMoment = moment(reference);
  const carePlanFromMoment = moment(carePlanFrom);

  if (referenceMoment.isBefore(carePlanFromMoment)) {
    while (referenceMoment.isBefore(carePlanFromMoment)) {
      carePlanFromMoment.subtract(30, 'days');
    }
    return carePlanFromMoment.toDate();
  }

  if (referenceMoment.isAfter(carePlanFromMoment)) {
    while (referenceMoment.diff(carePlanFromMoment, 'days') > 30) {
      carePlanFromMoment.add(30, 'days');
    }
    return carePlanFromMoment.toDate();
  }
};

/**
 *
 * @param reference the date of the care plan period start we want to get
 * @param carePlanFrom the current start of the care plan period
 * @returns
 */
export const getCarePlanPeriodWeeks = (reference: Date, carePlanFrom: Date): Date[][] => {
  let nearestCarePlanStart: Date = null;
  if (carePlanFrom.getTime() === reference.getTime()) {
    nearestCarePlanStart = carePlanFrom;
  } else {
    nearestCarePlanStart = getPreviousCarePlanStart(reference, carePlanFrom);
  }
  const nearestCarePlanPeriodEnd = addDays(nearestCarePlanStart, 30);

  const result: Date[][] = [];
  let currentDate = new Date(nearestCarePlanStart);
  while (currentDate < nearestCarePlanPeriodEnd) {
    result.push(getWeekArrayFromDate(currentDate));
    currentDate = addDays(currentDate, DAYS_IN_A_WEEK);
  }
  return result;
};

export const getTimelessDate = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());
export const getTimelessDateFromStr = (date: string) =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

/**
 * Calculate how many days there are between two dates regardless of their time values
 * @param date1
 * @param date2
 * @returns The difference in days between two dates
 */
export const diffDateDays = (date1: Date, date2: Date): number => {
  const stdDate1 = getTimelessDate(date1);
  const stdDate2 = getTimelessDate(date2);
  const diff = Math.abs(stdDate1.getTime() - stdDate2.getTime());

  return Math.floor(diff) / MS_IN_DAY;
};

export const diffStrDateDays = (date1: string, date2: string): number => {
  if (!date1 || !date2) return;
  const stdDate1 = getTimelessDateFromStr(date1);
  const stdDate2 = getTimelessDateFromStr(date2);
  const diff = Math.abs(stdDate1.getTime() - stdDate2.getTime());

  return Math.floor(diff) / MS_IN_DAY;
};

/**
 * Returns an array of the end dates of the weeks in between the two dates. It also intrinsically returns the amount of weeks between those two days (the array length)
 * @param date1
 * @param date2
 * @returns
 */
export const getWeekEndDatesBetweenDates = (date1: Date, date2: Date) => {
  //context : gonna use this for calculate fractions on activities which span more than one week, so I need to know how many week totals the activity spans
  //Even though I could simply count them, I thought it would be useful to have the endDates of each week
  const startDate = date2 > date1 ? date1 : date2;
  const weekEndDays: Date[] = [];
  const endDate = date2 > date1 ? date2 : date1;
  const currentWeek = getWeekArrayFromDate(startDate);
  let currentDate = currentWeek[currentWeek.length - 1];

  while (currentDate < endDate) {
    weekEndDays.push(new Date(currentDate));
    currentDate = addDays(currentDate, DAYS_IN_A_WEEK);
  }
  //? Idk why I need this but this is it. Rethink about it
  weekEndDays.push(currentDate);
  return weekEndDays;
};

export const timeAgoWithHours = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();
  const daysDiff = diff / (1000 * 3600 * 24);
  diffDateDays(new Date(), date);
  if (Math.round(daysDiff) === 0) {
    const hoursDiff = diff / (1000 * 3600);
    if (Math.round(hoursDiff) === 0) {
      const minutesDiff = diff / (1000 * 60);
      if (Math.round(minutesDiff) === 0) {
        return 'Just now';
      }
      return `${Math.round(minutesDiff)} mins ago`;
    }
    return `${Math.round(hoursDiff)} h${Math.round(hoursDiff) === 1 ? '' : 's'} ago`;
  }
  if (daysDiff < 7) {
    return `${Math.round(daysDiff)} days ago`;
  }
  if (daysDiff < 30) {
    return `${Math.round(daysDiff / 7)} weeks ago`;
  }
  return `${Math.round(daysDiff / 30)} months ago`;
};

export const timeAgo = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();
  const daysDiff = diff / (1000 * 3600 * 24);
  diffDateDays(new Date(), date);
  if (Math.round(daysDiff) === 0) {
    return 'Today';
  }
  if (daysDiff < 7) {
    return `${Math.round(daysDiff)} days ago`;
  }
  if (daysDiff < 30) {
    return `${Math.round(daysDiff / 7)} weeks ago`;
  }
  return `${Math.round(daysDiff / 30)} months ago`;
};

export const parseMs = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const totalMinutes = totalSeconds / 60;
  const totalHours = totalMinutes / 60;
  if (totalHours > 1) {
    const flooredHours = Math.floor(totalHours);
    const minutesDiff = (totalHours - flooredHours) * 60;
    const minutesDiffFloored = Math.floor(minutesDiff);
    return `${flooredHours}h ${minutesDiffFloored ? minutesDiffFloored + 'm' : ''}`;
  } else if (totalMinutes > 1) {
    const flooredMinutes = Math.floor(totalMinutes);
    const secondsDiff = (totalMinutes - flooredMinutes) * 60;
    const secondsDiffFloored = Math.floor(secondsDiff);
    return `${flooredMinutes}m ${secondsDiffFloored ? secondsDiffFloored + 's' : ''}`;
  } else {
    return totalSeconds ? `${totalSeconds}s` : '0';
  }
};
