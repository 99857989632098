import { ReactNode, useReducer } from 'react';
import { patientReducer } from '.';
import PatientContext, { patientStateInitialValue } from './PatientContext';

interface PatientCtxProviderProps {
  children: ReactNode;
}

const PatientCtxProvider = ({ children }: PatientCtxProviderProps) => {
  const [patientState, dispatch] = useReducer(patientReducer, patientStateInitialValue);

  return (
    <PatientContext.Provider
      value={{
        dispatch,
        patientState,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
export default PatientCtxProvider;
