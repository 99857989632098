import { ITimesHours } from 'types/Shared/ITimesHours';
import { get12hFormatObject } from './shared';

export const isTimeEqual = (time1: ITimesHours, time2: ITimesHours) => {
  if (time1.hour === time2.hour && time1.minute === time2.minute) return true;
  else return false;
};

/**
 *
 * @param time1 First Time value
 * @param time2 Second Time value
 * @returns True if first time value is equal or greater than the second time value
 */
export const isTimeGreater = (time1: ITimesHours, time2: ITimesHours) => {
  if (time1.hour * 60 + time1.minute > time2.hour * 60 + time2.minute) return true;
  else return false;
};

export const convertTimesHoursToString = (time: ITimesHours) => {
  const formatedTime = get12hFormatObject(time.hour);
  if (time.minute === 0) {
    return formatedTime.hour12Format + ' ' + formatedTime.ampm.toUpperCase();
  } else {
    const formatedMinutes = time.minute < 10 ? '0' + time.minute : time.minute;
    return (
      formatedTime.hour12Format + ':' + formatedMinutes + ' ' + formatedTime.ampm.toUpperCase()
    );
  }
};

export const convertStringToTimeHours = (formatedTime: string): ITimesHours => {
  const array = formatedTime.split(':');
  return {
    hour: Number(array[0]),
    minute: Number(array[1]),
  };
};
