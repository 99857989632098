import { useAuthContext } from 'auth';
import { canAccess } from 'auth/can';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import burger from '../../assets/icons/burger.png';
import vytracBlack from '../../assets/images/vytrac_black_sq.png';
import routes from '../../util/routes';
import { compareStringsCaseInsensitive } from '../../util/utils';

const SideNavigationMenu = ({ selectedRoute }) => {
  const { currentUser } = useAuthContext();
  const [showMenu, setShowMenu] = useState('');
  const history = useHistory();

  const toggleMenu = () => {
    if (showMenu === '') {
      setShowMenu('shrink');
    } else {
      setShowMenu('');
    }
  };
  return (
    <div className={'dashboard-menu ' + showMenu}>
      <div className="sticky-top">
        <div className={`dashboard-header-container ${showMenu}`}>
          <div className="d-flex justify-content-end">
            <img
              className={'dashboard-burger ' + showMenu}
              src={burger}
              onClick={toggleMenu}
              alt=""
            />
          </div>
          <div className={'dashboard-user-thumb-container ' + showMenu}>
            <div className="dashboard-user-thumb">
              <img className="dashboard-thumb" src={vytracBlack} alt="" />
            </div>
          </div>
        </div>
        <div className={'dashboard-buttons ' + showMenu}>
          {routes.flatMap(
            ({ name, path, icon: Icon, hiddenFromNav, stroke, relatedPermission }) => {
              if (
                relatedPermission &&
                !canAccess(currentUser.permissions, { access: relatedPermission, do: 'view' })
              )
                return [];
              return hiddenFromNav
                ? []
                : [
                    <div
                      key={path}
                      className={
                        compareStringsCaseInsensitive(selectedRoute, name)
                          ? 'dashboard-button-selected'
                          : 'dashboard-button'
                      }
                      onClick={() => history.push(path)}
                    >
                      <div
                        className={`d-flex align-items-center ${
                          showMenu ? 'justify-content-center' : ''
                        }`}
                      >
                        <div
                          className={`d-flex align-items-center h-100 ${!showMenu ? 'mr-3' : ''}`}
                        >
                          <Icon
                            fill={
                              compareStringsCaseInsensitive(selectedRoute, name)
                                ? 'var(--primary_color)'
                                : '#fff'
                            }
                            style={{
                              stroke: stroke
                                ? compareStringsCaseInsensitive(selectedRoute, name)
                                  ? 'var(--primary_color)'
                                  : null
                                : 'none',
                            }}
                          />
                        </div>
                        <div className="dashboard-button-text">
                          <p>{name}</p>
                        </div>
                      </div>
                    </div>,
                  ];
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default SideNavigationMenu;
