import {
  EmailSetting,
  EmailSettingType,
  EmailShortCode,
  EmailTypeTuple,
} from 'types/ApiModels/Administration';

export enum EmailSettingActionTypes {
  SET_EMAIL_SETTINGS = 'SET_EMAIL_SETTINGS',
  SET_SHORT_CODES = 'SET_SHORT_CODES',
  SET_EMAIL_TYPES = 'SET_EMAIL_TYPES',
  SET_CURRENT_EMAIL_SETTING = 'SET_CURRENT_EMAIL_SETTING',
  SET_INSERT_SHORT_CODE_TO_EDITOR = 'SET_INSERT_SHORT_CODE_TO_EDITOR',
  UPDATE_CURRENT_EMAIL_SETTING_TYPE = 'UPDATE_CURRENT_EMAIL_SETTING_TYPE',
  ADD_BLANK_EMAIL_SETTING = 'ADD_BLANK_EMAIL_SETTING',
  DELETE_EMAIL_SETTING = 'DELETE_EMAIL_SETTING',
  UPDATE_EMAIL_SETTING = 'UPDATE_EMAIL_SETTING',
}

export type EmailSettingAction =
  | {
      type: EmailSettingActionTypes.SET_EMAIL_SETTINGS;
      payload: { emailSettings: EmailSetting[] };
    }
  | {
      type: EmailSettingActionTypes.SET_SHORT_CODES;
      payload: { shortCodes: EmailShortCode[] };
    }
  | {
      type: EmailSettingActionTypes.SET_EMAIL_TYPES;
      payload: { types: EmailTypeTuple[] };
    }
  | {
      type: EmailSettingActionTypes.SET_CURRENT_EMAIL_SETTING;
      payload: { emailSetting: EmailSetting };
    }
  | {
      type: EmailSettingActionTypes.SET_INSERT_SHORT_CODE_TO_EDITOR;
      payload: { insertShortCode: (shortCode: EmailShortCode) => void };
    }
  | {
      type: EmailSettingActionTypes.UPDATE_CURRENT_EMAIL_SETTING_TYPE;
      payload: { type: EmailSettingType };
    }
  | {
      type: EmailSettingActionTypes.ADD_BLANK_EMAIL_SETTING;
    }
  | {
      type: EmailSettingActionTypes.UPDATE_EMAIL_SETTING;
      payload: {
        emailSetting: AtLeast<EmailSetting, 'id'>;
        oldId?: number;
      };
    }
  | { type: EmailSettingActionTypes.DELETE_EMAIL_SETTING; payload: { emailSettingId: number } };
