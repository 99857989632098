import Card from 'components/Card';
import { useState } from 'react';
import { ActivityPointsReward } from 'types/ActivityPoints';
import styles from './styles.module.css';
import image1 from 'assets/images/RewardsMock/image1.jpg';
import image2 from 'assets/images/RewardsMock/image2.jpg';
import image3 from 'assets/images/RewardsMock/image3.jpg';
import { Carousel, Col, Row } from 'react-bootstrap';
import ActivityPointsIcon from 'components/ActivityPointsIcon';

const RecentRewards = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [rewards, setRewards] = useState<ActivityPointsReward[]>([
    {
      images: [image1, image2, image3],
      title: 'Lorem ipsum dolor sit amet',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam imperdiet lectus elit, at sodales metus tempor sit amet.',
      points: 480,
    },
    {
      images: [image1, image2, image3],
      title: 'Lorem ipsum dolor sit amet',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam imperdiet lectus elit, at sodales metus tempor sit amet.',
      points: 500,
    },
  ]);
  const handleChange = (newIndex, e) => {
    setActiveIndex(newIndex);
  };
  return (
    <Card headers={['Recent activity points reward']} className="bg-white">
      <Carousel activeIndex={activeIndex} onSelect={handleChange} className={styles.carousel}>
        {rewards.map((reward, index) => (
          <Carousel.Item key={index} className="pr-3">
            <div className="d-flex justify-content-center">
              <Row>
                <Col>
                  <img src={reward.images[0]} alt="image1" />
                </Col>
                <Col className="align-items-center">
                  <Row className="justify-content-center">
                    <img src={reward.images[1]} alt="image2" />
                    <img
                      src={reward.images[2]}
                      alt="image3"
                      style={{ position: 'relative', left: -10 }}
                    />
                  </Row>
                </Col>
              </Row>
              {/* <div className="flex-column d-flex">
              </div> */}
            </div>
            <div>
              <div className={styles.title}>{reward.title}</div>
              <div className={styles.description}>{reward.description}</div>
            </div>
            <div className={styles.reward}>
              <div className="d-flex align-items-center">
                <ActivityPointsIcon width={18} height={15} fill="#8FAAC3" className="mr-2" />
                {reward.points}
              </div>
              <div>Redeem Now</div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Card>
  );
};
export default RecentRewards;
