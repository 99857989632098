import moment from 'moment';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';

/**
 * sets the ending of the recurrence to be at the start of the previous action selected
 * @param selectedTimelineAction
 * @param originalAction
 * @param type
 * @returns
 */
const updateRecurrenceEnding = (
  selectedTimelineAction: IPatientTimelineAction,
  originalAction: IPatientCarePlanAction,
  type: 'template' | 'patient'
) => {
  const updatedRecurrence = { ...originalAction.recurrence };
  if (type === 'template') {
    const dayDiff = moment(selectedTimelineAction.previousStartDate).diff(
      moment(originalAction.start_date),
      'days'
    );
    updatedRecurrence.end_date_type = 'duration';
    updatedRecurrence.end_date_duration_days = dayDiff;
  }

  if (type === 'patient') {
    updatedRecurrence.end_date_type = 'fixed_date';
    updatedRecurrence.end_date = selectedTimelineAction.previousStartDate;
  }

  return updatedRecurrence;
};

/**
 * Update the recurrence of the previous actions to finish on the start date of the action selected
 * @param selectedTimelineAction
 * @param updatedPatientActions
 * @param type determines if the action is from a template or from a patient care plan
 * @returns
 */
export const updatePreviousActionsRecurrence = (
  selectedTimelineAction: IPatientTimelineAction,
  updatedPatientActions: IPatientCarePlanAction[],
  type: 'template' | 'patient'
): IPatientCarePlanAction => {
  const originalId = selectedTimelineAction.originalActivityId;
  const originalActionIndex = updatedPatientActions.findIndex((a) => a.id === originalId);
  const originalAction = updatedPatientActions[originalActionIndex];

  const updatedRecurrence = updateRecurrenceEnding(selectedTimelineAction, originalAction, type);

  const updatedOriginalAction = { ...originalAction, recurrence: updatedRecurrence };

  updatedPatientActions[originalActionIndex] = updatedOriginalAction;
  return updatedOriginalAction;
};
