import ArrowDown from 'components/Icons/ArrowDown';
import { getIn, useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import colors from 'styles/colors';
import ProviderTierList from '../ProviderTierList';
import styles from '../styles.module.css';

const fieldName = 'tiers[0]';

interface PrimaryPhysicianTierComponentProps {
  isEditing: boolean;
}

const PrimaryPhysicianTierComponent: FC<PrimaryPhysicianTierComponentProps> = ({
  isEditing,
}: PrimaryPhysicianTierComponentProps) => {
  const [isOpen, setIsOpen] = useState(!isEditing);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    if (getIn(errors, `${fieldName}.providers`) && getIn(touched, `${fieldName}.providers`)) {
      setIsOpen(true);
    }
  }, [errors, touched]);

  return (
    <div className={`d-flex align-items-start ${styles['primary-physician-container']}`}>
      <div className={`${styles['flex-basis-priority']} text-center`}>
        <div
          className={`m-1 ${styles['item-container']} font-size-big font-weight-md d-flex align-items-center justify-content-center`}
        >
          1
        </div>
      </div>
      <div className={`d-flex flex-column gap-sm flex-grow-1`}>
        <div className="m-1 bg-transparent">
          <div className={`d-flex align-items-center gap w-100 ${styles['collapsable-header']}`}>
            <div
              className={`${styles['flex-basis-drag1-drop']}`}
              onClick={() => {
                setIsOpen((ppo) => !ppo);
              }}
            >
              <div
                className={`${styles['arrow-container']} rounded d-flex justify-content-center align-items-center cursor-pointer`}
              >
                <ArrowDown
                  width="9"
                  height="5"
                  color={colors.lowRiskAlerts}
                  style={{
                    transform: isOpen ? 'rotate(180deg)' : 'initial',
                    transition: 'transform 0.2s',
                  }}
                />
              </div>
            </div>
            <div className={`flex-grow-1 font-size-big font-weight-md`}>Primary Physician</div>
          </div>
        </div>
        <Collapse in={isOpen}>
          <div className="bg-trasparent">
            <ProviderTierList fieldName={fieldName} maxProviders={1} />
          </div>
        </Collapse>
      </div>
    </div>
  );
};
export default PrimaryPhysicianTierComponent;
