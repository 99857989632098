import styles from './styles.module.css';
import PropTypes from 'prop-types';
import { formatVytracDate } from 'util/dateUtils';
const Schedule = ({ title, message, completed, scheduleDate, handleCompletedChange }) => (
  <div
    className={`p-3 d-flex flex-column gap dashboard-profile-patient ${styles['schedule-container']}`}
  >
    <div className="d-flex align-items-center gap-sm">
      <input type="checkbox" checked={completed} onChange={handleCompletedChange} />
      <p className={`m-0 ${styles.title}`}>{title}</p>
    </div>
    <p className={`m-0 ${styles.date}`}>{formatVytracDate(scheduleDate)}</p>
    <p className={`m-0 ${styles.message}`}>{message}</p>
  </div>
);

Schedule.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  completed: PropTypes.bool,
  scheduleDate: PropTypes.instanceOf(Date),
  handleCompletedChange: PropTypes.func,
};
export default Schedule;
