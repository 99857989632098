import FiltersButton from 'components/FiltersButton';

const Header = ({ encounterCount }) => (
  <div className="dashboard-item-header d-flex align-items-center justify-content-between">
    <span>Encounter history ({encounterCount})</span>
    <FiltersButton />
  </div>
);

export default Header;
