import Button from 'components/Button';
import { useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setHasUnsavedChanges } from 'screens/Templates/store/templateCarePlan/templateCarePlanActionCreators';
import { useTemplateContext } from 'screens/Templates/store/templateContext';
import TabEnum from 'screens/Templates/util/TabEnum';
import {
  createCarePlanTemplate,
  deleteCarePlanTemplate,
  partialUpdateCarePlanTemplate,
} from 'services/templatesService';
import ConfirmCancelModal from '../confirmCancelModal';
import ConfirmDeleteModal from '../../../confirmDeleteModal';
import styles from './styles.module.css';
import TabRouteMap from 'screens/Templates/util/TabRouteMap';

const Actions = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    dispatch,
    templateState: {
      templateCarePlan: { hasUnsavedChanges, carePlanState, userIsStaff },
    },
  } = useTemplateContext();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const templateId = useMemo(() => {
    return Number(location.pathname.split('/')[3]);
  }, [location]);

  const onCancel = () => {
    dispatch(setHasUnsavedChanges(false));
    history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}`);
  };

  const onSave = async () => {
    await partialUpdateCarePlanTemplate(carePlanState);
    dispatch(setHasUnsavedChanges(false));
  };

  const onDelete = async () => {
    await deleteCarePlanTemplate(templateId);
    onCancel();
  };

  const onCreate = async () => {
    const newTemplate = await createCarePlanTemplate(carePlanState);
    history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}/${newTemplate.id}/`);
  };

  return (
    <div className={styles.rpmTemplateActions}>
      <ConfirmCancelModal
        show={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        onDiscard={onCancel}
      />
      <Button
        label="cancel"
        variant="cancel"
        onClick={() => setShowCancelModal(true)}
        disabled={!hasUnsavedChanges}
      />
      {isNaN(templateId) ? (
        <>
          <Button
            label="create template"
            variant="confirm"
            disabled={!hasUnsavedChanges}
            onClick={onCreate}
          />
        </>
      ) : (
        <>
          <Button
            label="save changes"
            variant="confirm"
            disabled={!hasUnsavedChanges}
            onClick={onSave}
          />
          <Button
            label="delete template"
            variant="delete"
            onClick={() => setShowDeleteModal(true)}
            disabled={carePlanState.creator_is_admin && !userIsStaff}
          />
          <ConfirmDeleteModal
            show={showDeleteModal}
            name={carePlanState.name}
            onCancel={() => setShowDeleteModal(false)}
            onDelete={onDelete}
          />
        </>
      )}
    </div>
  );
};

export default Actions;
