import { PaginationControls, usePagination } from 'components/Pagination';
import styles from './styles.module.css';

const Header = () => {
  const { paginationState } = usePagination();
  return (
    <div className={styles.main}>
      <span>All patients assigned to you ({paginationState.maxCount})</span>
      <PaginationControls disablePagination={paginationState.isLoading} />
    </div>
  );
};

export default Header;
