import Card from 'components/Card';
import { CircleAdd } from 'components/Icons';
import { FC, ReactNode } from 'react';
import colors from 'styles/colors';

interface CardAddHeaderProps {
  title: string;
  count?: number;
  onAdd: () => void;
}

export const CardAddHeader = ({ title, count, onAdd }: CardAddHeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <div className="font-size-big font-weight-md">{title}</div>
      <div className="d-flex gap">
        {count ? <div className="font-size-big font-weight-md">{count}</div> : null}
        <div onClick={onAdd} className="cursor-pointer">
          <CircleAdd
            height="16"
            width="16"
            color={colors.lowRiskAlerts}
            secondaryColor={colors.white}
          />
        </div>
      </div>
    </div>
  );
};

interface CardAddProps {
  title: string;
  children: ReactNode;
  count: number;
  handleAdd: () => void;
}

const CardAdd: FC<CardAddProps> = ({ title, count, handleAdd, children }: CardAddProps) => {
  return (
    <Card
      className="card-bg-border h-100"
      headers={[
        <CardAddHeader title={title} count={count} onAdd={handleAdd} key="card-add-header" />,
      ]}
    >
      {children}
    </Card>
  );
};
export default CardAdd;
