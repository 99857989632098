import { CSSProperties, FC, ReactNode } from 'react';
import styles from './styles.module.css';

interface ControlButtonProps {
  onClick?: () => void;
  selected?: boolean;
  style?: CSSProperties;
  children: ReactNode;
}
const ControlButton: FC<ControlButtonProps> = ({
  onClick = () => undefined,
  selected = false,
  style,
  children,
}: ControlButtonProps) => (
  <button
    className={`${styles['button-control']} ${selected ? styles.selected : ''}`}
    onClick={onClick}
    style={style}
    type="button"
  >
    {children}
  </button>
);
export default ControlButton;
