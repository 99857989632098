import { addSuffixes } from 'components/DayOfMonthPicker/utils/addSuffixes';
import { mapActionEventTypeToLabel } from 'components/RPM/utils/eventTypeMapper';
import { mapActionTypeToLabel } from 'components/RPM/utils/typeMapper';
import moment from 'moment';
import { FormCheck } from 'react-bootstrap';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { convertDateToDatePickValue, convertTimesHoursToString } from 'util/dateUtils';

const printDays = (action: IPatientCarePlanAction): string => {
  if (!action.recurrence) return 'Once';

  switch (action.recurrence.repeats_type) {
    case 'every_day': {
      return 'Every day';
    }

    case 'before_start_of_adherence_block': {
      return 'Before start of adherence block';
    }

    case 'start_of_adherence_block': {
      return 'Start of adherence block';
    }

    case 'every_week': {
      const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      const weekDayArray = [moment(action.start_date).day(), ...action.recurrence.repeats_param];

      let result = '';
      weekDayArray.forEach((day: number, index: number) => {
        if (index === weekDayArray.length) {
          result = result.concat(weekDays[day]);
        } else {
          result = result.concat(weekDays[day] + ', ');
        }
      });

      return result;
    }

    case 'every_month': {
      const monthDayArray: string[] = [
        moment(action.start_date).date().toString(),
        ...action.recurrence.repeats_param,
      ];
      return addSuffixes(monthDayArray);
    }

    default:
      null;
  }
};

const printTimesAday = (action: IPatientCarePlanAction): string => {
  let result = '';
  if (action.recurrence?.multiple_times_a_day) {
    action.recurrence.multiple_times_hours.forEach((time, index) => {
      if (index > 0) {
        result = result.concat(', ' + convertTimesHoursToString(time));
      } else {
        result = result.concat(convertTimesHoursToString(time));
      }
    });
  } else {
    const datePick = convertDateToDatePickValue(new Date(action.start_date), true);
    result = convertTimesHoursToString({ hour: datePick.hours, minute: datePick.minutes });
  }

  return result;
};

export const mapActionToBreakdownRow = (
  action: IPatientCarePlanAction,
  onActiveChange: () => void
) => {
  return [
    // name
    action.event_type === 'vitals_request'
      ? mapActionTypeToLabel(action.type)
      : mapActionEventTypeToLabel(action.event_type),

    // days
    printDays(action),

    // time of day
    printTimesAday(action),

    //status
    // TODO: modify is active with endpoint
    <FormCheck
      key={action.id}
      id="active-swtich"
      type="switch"
      checked={action.is_active}
      onChange={onActiveChange}
    />,
  ];
};
