import { mapGoalToLabel } from 'components/PatientGoalsTopBar/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { FormCheck } from 'react-bootstrap';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { updateThreshold } from 'screens/Patients/store/PatientGoalsThresholds';
import { updateGoalThreshold } from 'services/patientService';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import Select from 'components/form/Select';
import VytalTypeIconMapper from '../../util/GoalEnumIconMapper';
import styles from './styles.module.css';

const selectOptions = [
  { value: 10, label: '10 days' },
  { value: 30, label: '30 days' },
];

interface HeaderProps {
  vytalType: VytalType;
  timeSpan: number;
  setTimeSpan: Dispatch<SetStateAction<number>>;
}
const Header = ({ vytalType, timeSpan, setTimeSpan }: HeaderProps) => {
  const { selectedPatient } = usePatientFormikContext();
  const {
    dispatch,
    patientState: {
      patientGoalsThresholds: { thresholds },
    },
  } = usePatientContext();

  const [Icon, setIcon] = React.useState(null);

  //Set icon based on goal type
  React.useEffect(() => {
    setIcon(VytalTypeIconMapper[vytalType]);
  }, [vytalType]);

  const toggleEnableGoal = async () => {
    const updatedThreshold = { ...thresholds[vytalType] };
    updatedThreshold.is_enabled = !updatedThreshold.is_enabled;
    dispatch(updateThreshold(vytalType, updatedThreshold));
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {Icon && <Icon />}
        <div>{mapGoalToLabel(vytalType)}</div>
        <Select
          options={selectOptions}
          currentValue={timeSpan.toString()}
          containerClassName={styles.select}
          onChange={(e) => setTimeSpan(parseInt(e.target.value))}
        />
      </div>
      <div>
        <FormCheck
          id={`disable-check-${vytalType}`}
          type="switch"
          checked={thresholds[vytalType].is_enabled}
          onChange={toggleEnableGoal}
        />
      </div>
    </div>
  );
};

export default Header;
