import YellowAlertPopup from './YellowAlertPopup';
import { useCallback, useMemo, useState } from 'react';
import DashboardYellowAlert from 'types/ApiModels/Dashboard/DashboardYellowAlert';
import ListItem from 'components/ListItem';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import {
  setSelectedYellowAlert,
  setTrackingType,
  toggleTimeIsTracking,
} from 'screens/ProviderDashboard/store/DashboardActionCreators';
import DatePickerValue from 'types/Shared/DatePicker';
import { getSingleYellowAlert } from 'services/dashboardService';
import useAlertItemTracking from 'screens/ProviderDashboard/store/hooks/useAlertItemTracking';
import { TrackingType } from 'types/ApiModels/Tracking/TrackingLog';
import styles from './styles.module.css';

interface YellowAlertItemProps {
  alert: DashboardYellowAlert;
  dateFrom: DatePickerValue;
  dateTo: DatePickerValue;
}
const YellowAlertItem = ({ alert, dateFrom, dateTo }: YellowAlertItemProps) => {
  const {
    dispatch,
    state: { timerIsTracking, trackingPatientId, patientIdTimeMap, trackingActivityId },
    fetchRegistry,
  } = useDashboardContext();

  const [showPopup, setShowPopup] = useState(false);

  useAlertItemTracking(alert.id, TrackingType.ALERT, showPopup);

  const iconList = useMemo(
    () => alert?.vital_types?.map((vytal) => rpmActionTypeToIcon[vytal]),
    [alert]
  );

  const handleClosePopUp = useCallback(() => {
    setShowPopup(false);
    dispatch(setSelectedYellowAlert(null));
  }, [setShowPopup, dispatch]);

  const fetchSinglePatient = useCallback(
    async (yellowAlertId: number) => {
      let res;
      const dateFromString = `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`;
      const dateToString = `${dateTo.year}-${dateTo.month}-${dateTo.day}`;
      if (
        fetchRegistry.current.yellowAlertDetail.list[
          `${yellowAlertId}-${dateFromString}-${dateToString}`
        ]
      ) {
        res =
          fetchRegistry.current.yellowAlertDetail.list[
            `${yellowAlertId}-${dateFromString}-${dateToString}`
          ];
      } else {
        res = await getSingleYellowAlert(yellowAlertId, dateFromString, dateToString);
        fetchRegistry.current.yellowAlertDetail.list[
          `${yellowAlertId}-${dateFromString}-${dateToString}`
        ] = res;
      }
      dispatch(setSelectedYellowAlert(res));
    },
    [dispatch, fetchRegistry, dateFrom, dateTo]
  );

  const handleTogglePopup = useCallback(() => {
    if (showPopup) {
      handleClosePopUp();
    } else {
      setShowPopup(true);
      fetchSinglePatient(alert.id);
    }
  }, [showPopup, handleClosePopUp, fetchSinglePatient, alert.id]);

  const stopwatchClick = useCallback(() => {
    dispatch(setTrackingType(TrackingType.ALERT));
    dispatch(toggleTimeIsTracking(!timerIsTracking));
  }, [dispatch, timerIsTracking]);

  return (
    <div>
      <div
        onClick={handleTogglePopup}
        className={`dashboard-profile-patient ${styles.itemContainer}`}
      >
        <ListItem
          user={{
            first_name: alert.first_name,
            last_name: alert.last_name,
            middle_name: alert.middle_name,
            sex: alert.sex,
            imageUrl: alert.photo_thumbnail,
          }}
          patientId={alert.id}
          showStopwach={showPopup}
          tracking={{
            timerIsTracking,
            patientId: trackingPatientId,
            elapsedTime: patientIdTimeMap.get(trackingPatientId),
            activityId: trackingActivityId,
            stopwatchClick,
          }}
        />
        <div
          style={{
            color: '#8FAAC3',
            display: 'flex',
            justifyContent: 'end',
            gap: 10,
          }}
        >
          {iconList &&
            iconList.map((Icon, index) => (
              <Icon width="12px" height="11px" color="#8FAAC3" key={index} />
            ))}
        </div>
      </div>
      {showPopup && <YellowAlertPopup onClickOutSide={handleClosePopUp} />}
    </div>
  );
};
export default YellowAlertItem;
