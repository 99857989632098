import React from 'react';
import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker';
import styles from './styles.module.css';
import customLocale from './customLocale';
import './overrideStyles.css';
import DatePickerValue from 'types/Shared/DatePicker';
import { Day } from 'react-modern-calendar-datepicker';

interface ICalendarPicker {
  value: DatePickerValue;
  onChange: (newValue: DatePickerValue) => void;
  disabledDays?: Day[];
  renderFooter?: React.FC;
  minimumDate?: DatePickerValue;
  className?: string;
  title?: string;
  containerClassName?: string;
  titleClassName?: string;
}
const CalendarPicker = ({
  value,
  onChange,
  disabledDays,
  renderFooter,
  minimumDate,
  className,
  title,
  containerClassName,
  titleClassName,
}: ICalendarPicker) => {
  return (
    <div className={containerClassName}>
      {title && (
        <>
          <div className={titleClassName}>{title}</div>
          <hr className={styles.horizontalLine} />
        </>
      )}
      <Calendar
        value={value}
        onChange={onChange}
        disabledDays={disabledDays}
        renderFooter={renderFooter}
        locale={customLocale}
        colorPrimary="#393E48"
        calendarClassName={className || styles.calendar}
        //calendarPopperPosition="bottom"
        minimumDate={minimumDate}
      />
    </div>
  );
};

export default CalendarPicker;
