import moment from 'moment';
import { mainChartOptions, mainDatasetConfig } from 'screens/ProviderDashboard/utils/chartUtils';

export const convertDataIntoChartData = (data: (number | number[])[], type = 'normal') => {
  const labels = data.map((_, index) => moment().subtract(index, 'days').format('MM/DD')).reverse();
  labels.unshift('1');
  labels.push('1');
  let dataset;
  if (type === 'double-line') {
    dataset = [
      {
        label: 'High Pulse Pressure',
        fill: false,
        borderColor: '#8FAAC3',
        pointBackgroundColor: 'white',
        pointBorderWidth: 3,
        pointRadius: 3,
        data: [undefined, ...data.map((read) => read[0]), undefined],
        dragData: false,
      },
      {
        label: 'Low Pulse Pressure',
        fill: false,
        borderColor: '#8FAAC3',
        pointBackgroundColor: 'white',
        pointBorderWidth: 3,
        pointRadius: 3,
        data: [undefined, ...data.map((read) => read[1]), undefined],
        dragData: false,
      },
    ];
  } else {
    dataset = [
      mainDatasetConfig([
        (data.reverse() as number[])[0],
        (data.reverse() as number[])[0],
        ...(data.reverse() as number[]),
      ]),
    ];
  }

  return {
    labels: labels,
    datasets: [...dataset],
  };
};

export const getChartOptions = (data: (number | number[])[], type = 'normal') => {
  let options = mainChartOptions as any;
  if (type === 'double-line') {
    const newLines = [[undefined, undefined], ...data, [undefined, undefined]]
      .map((read, index) =>
        read[0] && read[1]
          ? {
              type: ['line'],
              xMax: index,
              xMin: index,
              yMax: read[0],
              yMin: read[1],
              annotationID: `DataPoint-${index}`,
              drawTime: 'beforeDatasetsDraw',
              borderColor: '#8FAAC3',
              borderWidth: 3,
            }
          : null
      )
      .filter((line) => line);
    options = {
      ...options,
      showLine: false,
      plugins: {
        ...options.plugins,
        annotation: {
          annotations: [...newLines],
        },
      },
    };
  }
  return options;
};
