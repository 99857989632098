import Button from 'components/Button';
import Warning from 'components/Icons/Warning';
import VytracModal from 'components/Modal';
import { FC } from 'react';
import colors from 'styles/colors';
import styles from './styles.module.css';

interface ConfirmationModalProps {
  onConfirm?: () => void;
  onCancel: () => void;
  show: boolean;
  title?: string;
  message?: string;
  variant?: 'caution' | 'danger';
  buttons?: 'yesno' | 'understand';
}

const colorByVariant = {
  danger: colors.highRiskAlerts,
  caution: colors.mediumRiskAlerts,
};

const SaveChangesConfirmationModal: FC<ConfirmationModalProps> = ({
  onConfirm = () => undefined,
  onCancel,
  show,
  title = 'Confirm action',
  message = 'Are you sure you want to save changes?',
  variant = 'caution',
  buttons = 'yesno',
}: ConfirmationModalProps) => {
  return (
    <VytracModal
      title={
        <div className="d-flex gap align-items-center">
          <Warning height="20" width="20" color={colorByVariant[variant]} />
          <div className="font-size-big font-weight-md">{title}</div>
        </div>
      }
      body={
        <div>
          <div className="font-size-medium text-center">{message}</div>
        </div>
      }
      footer={
        <>
          {buttons === 'yesno' ? (
            <div className="d-flex w-100 justify-content-between">
              <Button
                label="no"
                variant="dull"
                onClick={onCancel}
                className={styles['button-container']}
              />
              <Button
                label="yes"
                variant="confirm"
                onClick={onConfirm}
                className={styles['button-container']}
              />
            </div>
          ) : null}
          {buttons === 'understand' ? (
            <div className="d-flex w-100 justify-content-end">
              <Button
                label="I understand"
                variant="dull"
                onClick={onCancel}
                className={styles['button-container']}
              />
            </div>
          ) : null}
        </>
      }
      centered
      onClose={onCancel}
      show={show}
    />
  );
};

export default SaveChangesConfirmationModal;
