import { VytalType } from 'types/ApiModels/Patients/Vytal';
import styles from './styles.module.css';
import { mapGoalToLabel } from './utils';

interface IPatientGoalsTopBar {
  selectedGoal: VytalType;
  setSelectedGoal: (goal: VytalType) => void;
  disableAllOption?: boolean;
  disabledOptions?: VytalType[];
}

const PatientGoalsTopBar = ({
  selectedGoal,
  setSelectedGoal,
  disableAllOption,
  disabledOptions = [],
}: IPatientGoalsTopBar) => {
  return (
    <div className={styles.container}>
      {!disableAllOption && (
        <div
          className={`${styles.goal} ${selectedGoal === null && styles.goalSelected}`}
          onClick={() => setSelectedGoal(null)}
        >
          {mapGoalToLabel(null)}
        </div>
      )}

      {Object.values(VytalType).map((goal) => (
        <div
          key={goal}
          className={`${styles.goal} ${selectedGoal === goal && styles.goalSelected}`}
          onClick={() => (!disabledOptions.includes(goal) ? setSelectedGoal(goal) : null)}
          style={{ color: disabledOptions.includes(goal) ? '#8FAAC3' : '#1890FF' }}
        >
          {mapGoalToLabel(goal)}
        </div>
      ))}
    </div>
  );
};

export default PatientGoalsTopBar;
