import { ordinalSuffixOf } from './ordinalSuffixOf';

export const addSuffixes = (daysSelected: string[]): string => {
  let inputValue = '';
  daysSelected.forEach((day, index) => {
    index === 0
      ? (inputValue = inputValue.concat(ordinalSuffixOf(day)))
      : (inputValue = inputValue.concat(`, ${ordinalSuffixOf(day)}`));
  });
  return inputValue;
};
