import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { FC, Fragment, MutableRefObject } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GenericColumn from 'types/Shared/GenericColumn';
import styles from './styles.module.css';
interface FormikArrayTableProps {
  headers: GenericColumn[];
  name: string;
  arrayHelpersRef: MutableRefObject<FieldArrayRenderProps>;
}

const FormikArrayTable: FC<FormikArrayTableProps> = ({
  name,
  headers,
  arrayHelpersRef,
}: FormikArrayTableProps) => {
  const { values } = useFormikContext();
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        arrayHelpersRef.current = arrayHelpers;
        return (
          <div
            className={styles.container}
            style={{
              gridTemplateColumns: `repeat(${
                headers.filter((h) => !h.hidden).length - 1
              },minmax(0,1fr)) min-content`,
            }}
          >
            {headers.map((header, index) => (
              <Fragment key={index}>
                {!header.hidden && (
                  <div
                    className={`${styles.title} ${styles.field} ${header.headerClasses || ''}`}
                    style={{ ...header.headerStyle, textAlign: header.textAlign || 'initial' }}
                  >
                    {header.title}
                  </div>
                )}
              </Fragment>
            ))}
            {values[name]?.map((_, index) => (
              <Fragment key={index}>
                {headers.map((header, i) => (
                  <Fragment key={`${index}.${i}`}>
                    {!header.hidden && (
                      <div
                        className={styles.field}
                        style={{ ...header.style, textAlign: header.textAlign || 'initial' }}
                      >
                        {header.render
                          ? header.render({
                              ...arrayHelpers,
                              index,
                              name: `${name}[${index}].${header.key}`,
                            })
                          : values[name][index][header.key]}
                      </div>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </div>
        );
      }}
    />
  );
};
export default FormikArrayTable;
