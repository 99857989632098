import FiltersButton from 'components/FiltersButton';

import styles from './styles.module.css';

const Header = ({ counter, title }: { counter: number; title: string }) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className={styles['title']}>{title}</div>
      <div className="d-flex gap align-items-center">
        <div className={styles['counter']}>{counter}</div>
        <FiltersButton />
      </div>
    </div>
  );
};
export default Header;
