import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { CSSProperties } from 'react';
import SwitchActivityTimelineView from './SwitchActivityTimelineView';

interface ActivityTimelineProps {
  currentView: TimePeriodOptions;
  style?: CSSProperties;
}

const ActivityTimeline = ({ currentView, style }: ActivityTimelineProps) => {
  return (
    <div className={` d-flex w-100`} style={{ height: 'fit-content', ...style }}>
      <SwitchActivityTimelineView timePeriodOptionKey={currentView} />
    </div>
  );
};
export default ActivityTimeline;
