import EditDeleteActionButtons from 'components/EditDeleteActionButtons';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { FC } from 'react';
import { Surgery } from 'types/ApiModels/Patients/Patient';
import { formatDottedDate } from 'util/dateUtils';
import styles from '../../PatientManagementForm.module.css';

interface SurgeryListProps {
  surgeries: Surgery[];
  handleDeleteSurgery: (id: number) => void;
  handleEditSurgery: (id: number) => void;
}

const SurgeryList: FC<SurgeryListProps> = ({
  surgeries,
  handleDeleteSurgery,
  handleEditSurgery,
}: SurgeryListProps) => {
  if (!surgeries?.length)
    return <TextSurroundedWithLines text="No known history" className="text-gray-low-risk" />;

  return (
    <div className="d-flex flex-column gap-sm">
      {surgeries.map((s) => (
        <div className={`d-flex flex-column gap ${styles.card}`} key={s.id}>
          <div className="d-flex justify-content-between">
            <p className="m-0 p-0 font-size-big">{s.name}</p>
            <div className={styles['action-buttons']}>
              <EditDeleteActionButtons
                handleDelete={() => handleDeleteSurgery(s.id)}
                handleEdit={() => handleEditSurgery(s.id)}
              />
            </div>
          </div>
          <p className={`m-0 p-0 text-gray-low-risk`}>{formatDottedDate(new Date(s.date))}</p>
          <p className="m-0 p-0">{s.details}</p>
        </div>
      ))}
    </div>
  );
};
export default SurgeryList;
