import { mapLabelToPushType, PushTypeLabel } from 'components/RPM/utils/pushTypeMapper';
import { useFetchList } from 'hooks';
import React, { useEffect, useState } from 'react';
import { getResourceList } from 'services/resourcesService';
import { PushType } from 'types/ApiModels/CarePlan/PushType';
import { IModalSection } from '../RPM/ActionModal/components/ActionModalBody/components/utils/IModalSection';
import NormalNote from './components/NormalNote';
import NoteCheckbox from './components/NoteCheckbox';
import Push from './components/Push';
import ScheduleNote from './components/ScheduledNote';
import styles from './styles.module.css';

const Notes = ({ data, setData, questionnaireList, modalType }: IModalSection) => {
  const [showPushQuestionnaire, setShowPushQuestionnaire] = useState<boolean>();
  const [showPushResource, setShowPushResource] = useState<boolean>();
  const [showTeamNote, setShowTeamNote] = useState<boolean>();
  const [showScheduleNote, setShowScheduleNote] = useState<boolean>();
  const [showPatientMessage, setShowPatientMessage] = useState<boolean>();

  const [resourceList] = useFetchList(getResourceList);

  useEffect(() => {
    if (data.questionnaire) setShowPushQuestionnaire(true);
    else setShowPushQuestionnaire(false);

    if (data.team_note) setShowTeamNote(true);
    else setShowTeamNote(false);

    if (data.my_schedule_note) setShowScheduleNote(true);
    else setShowScheduleNote(false);

    if (data.patient_message) setShowPatientMessage(true);
    else setShowPatientMessage(false);
  }, []);

  useEffect(() => {
    // if initialization is pending
    if (showPushQuestionnaire === undefined) return;

    if (!showPushQuestionnaire) {
      setData({ ...data, questionnaire: null });
    }
  }, [showPushQuestionnaire]);

  useEffect(() => {
    // if initialization is pending
    if (showTeamNote === undefined) return;

    if (!showTeamNote) {
      setData({ ...data, team_note: null });
    }
  }, [showTeamNote]);

  useEffect(() => {
    // if initialization is pending
    if (showScheduleNote === undefined) return;

    if (!showScheduleNote) {
      setData({ ...data, my_schedule_note: null });
    }
  }, [showScheduleNote]);

  useEffect(() => {
    // if initialization is pending
    if (showPatientMessage === undefined) return;

    if (!showPatientMessage) {
      setData({ ...data, patient_message: null });
    }
  }, [showPatientMessage]);

  const onQuestionnaireChange = (questionnaireName: string) => {
    const selectedQuestionnaire = questionnaireList.find(
      (questionnaire) => questionnaire.name === questionnaireName
    );
    setData({ ...data, questionnaire: selectedQuestionnaire.id });
  };

  const onQuestionnaireWhenChange = (label: PushTypeLabel) => {
    const pushValue: PushType = mapLabelToPushType(label);
    setData({ ...data, questionnaire_when: pushValue });
  };

  const onResourceChange = (resourceName: string) => {
    const selectedResource = resourceList.find((resource) => resource.name === resourceName);
    setData({ ...data, resource: selectedResource.id });
  };

  const onResourceWhenChange = (label: PushTypeLabel) => {
    const pushValue: PushType = mapLabelToPushType(label);
    setData({ ...data, resource_when: pushValue });
  };

  return (
    <div className="d-flex flex-column">
      {data.event_type !== 'questionnaire' && (
        <Push
          boxShadow={styles.boxShadowTop}
          label="Questionnaire"
          checked={showPushQuestionnaire}
          onCheckChange={() => setShowPushQuestionnaire(!showPushQuestionnaire)}
          selectLabel={'Select Questionnaire'}
          options={questionnaireList.map((questionnaire) => questionnaire.name)}
          selectedOption={
            questionnaireList.find((questionnaire) => questionnaire.id === data.questionnaire)?.name
          }
          onOptionChange={onQuestionnaireChange}
          when={data.questionnaire_when}
          onWhenChange={onQuestionnaireWhenChange}
          howLong={data.questionnaire_when_minutes}
          onHowLongChange={(value: number) =>
            setData({ ...data, questionnaire_when_minutes: value })
          }
        />
      )}
      {data.event_type !== 'push_file' && (
        <Push
          boxShadow={styles.boxShadowTop}
          label="Resource"
          checked={showPushResource}
          onCheckChange={() => setShowPushResource(!showPushResource)}
          selectLabel={'Select Resource'}
          options={resourceList.map((resource) => resource.name)}
          selectedOption={resourceList.find((resource) => resource.id === data.resource)?.name}
          onOptionChange={onResourceChange}
          when={data.resource_when}
          onWhenChange={onResourceWhenChange}
          howLong={data.resource_when_minutes}
          onHowLongChange={(value: number) => setData({ ...data, resource_when_minutes: value })}
        />
      )}
      {modalType === 'patient' && (
        <>
          <NoteCheckbox
            label="Add as part of Care Plan"
            boxShadow={styles.boxShadowTop}
            onChangeCheckbox={() =>
              setData({ ...data, is_part_of_rpm_plan: !data.is_part_of_rpm_plan })
            }
            checked={data.is_part_of_rpm_plan}
          />
          <NormalNote
            currentValue={data.team_note}
            label="Add team note"
            boxShadow={styles.boxShadowTop}
            onChangeCheckbox={() => setShowTeamNote(!showTeamNote)}
            checked={showTeamNote}
            onNoteChange={(newValue) => setData({ ...data, team_note: newValue })}
          />
          <ScheduleNote
            label="Add note to my schedule"
            boxShadow={styles.boxShadow}
            currentValue={data.my_schedule_note}
            onChangeCheckbox={() => setShowScheduleNote(!showScheduleNote)}
            checked={showScheduleNote}
            onNoteChange={(value) => setData({ ...data, my_schedule_note: value })}
          />
          <NormalNote
            currentValue={data.patient_message}
            label="Send message to patient"
            onChangeCheckbox={() => setShowPatientMessage(!showPatientMessage)}
            checked={showPatientMessage}
            onNoteChange={(newValue) => setData({ ...data, patient_message: newValue })}
          />
        </>
      )}
    </div>
  );
};
export default Notes;
