import { createContext, useContext } from 'react';
import { InvitationStatus } from '../types';

interface RegisterContextState {
  hash: string;
  email: string;
}

const RegisterContext = createContext<RegisterContextState>(null);

export const useRegisterContext = () => useContext(RegisterContext);

export default RegisterContext;
