import { useFormikContext } from 'formik';
import { FC } from 'react';
import { CareTeamCreateEditFormikFields } from '../../types';

interface AddTierButtonProps {
  createLocalId: () => number;
}

const AddTierButton: FC<AddTierButtonProps> = ({ createLocalId }: AddTierButtonProps) => {
  const { setFieldValue, values } = useFormikContext<CareTeamCreateEditFormikFields>();

  const handleAddTier = () => {
    setFieldValue(`tiers[${values.tiers.length}]`, {
      id: createLocalId(),
      number: values.tiers.length + 1,
      providers: [],
      name: '',
    });
  };
  return (
    <div className={`d-flex justify-content-center bg-transparent`}>
      <div className="p-2 bg-white m-1 flex-grow-1 d-flex justify-content-center">
        <button className="link font-size-big font-weight-md" onClick={handleAddTier}>
          + Add tier
        </button>
      </div>
    </div>
  );
};
export default AddTierButton;
