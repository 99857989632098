import Button from 'components/Button';
import { useProviderProfileContext } from '../store/ProviderProfileContextProvider';

const ProfileMyAccountActions = () => {
  const {
    providerProfileState: {
      providerProfileForm: { formDirty, resetForm, submitForm },
    },
  } = useProviderProfileContext();
  return (
    <div className="d-flex gap-sm">
      <Button
        label="cancel"
        variant="cancel"
        disabled={!formDirty}
        onClick={() => {
          resetForm();
        }}
      />
      <Button
        label="save account changes"
        variant="confirm"
        disabled={!formDirty}
        onClick={submitForm}
      />
    </div>
  );
};
export default ProfileMyAccountActions;
