import { useActivityTimelineContext } from 'components/ActivityTimeline/store';
import { FC, HTMLProps } from 'react';
import { Draggable } from 'react-beautiful-dnd';

export interface DayActivityItemProps {
  activityItemId: number;
  itemContainerProps: HTMLProps<HTMLDivElement>;
}

const DayActivityItem: FC<DayActivityItemProps> = ({
  activityItemId,
  itemContainerProps,
  children,
}) => {
  const { clickable, onActivityClick, draggable } = useActivityTimelineContext();

  return (
    <>
      {draggable ? (
        <Draggable draggableId={`${activityItemId}`} index={-1}>
          {(provided) => (
            <div
              {...itemContainerProps}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              onClick={clickable ? (event) => onActivityClick(event, activityItemId) : null}
            >
              {children}
            </div>
          )}
        </Draggable>
      ) : (
        <div
          {...itemContainerProps}
          onClick={clickable ? (event) => onActivityClick(event, activityItemId) : null}
        >
          {children}
        </div>
      )}
    </>
  );
};
export default DayActivityItem;
