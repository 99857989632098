import { ProviderProfileAction, ProviderProfileActionTypes } from './providerProfileActions';
import { OptionsInfo } from './providerProfileReducer';

export const setProviderProfileFormReset = (resetForm: () => void): ProviderProfileAction => ({
  type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_FORM_RESET,
  payload: { resetForm },
});

export const setProviderProfileSubmitForm = (submitForm: () => void): ProviderProfileAction => ({
  type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_SUBMIT_FORM,
  payload: { submitForm },
});

export const setProviderProfileOptionsInfo = (optionsInfo: OptionsInfo): ProviderProfileAction => ({
  type: ProviderProfileActionTypes.SET_OPTIONS_INFO,
  payload: { optionsInfo },
});

export const setProviderProfileAction = (action: () => JSX.Element): ProviderProfileAction => ({
  type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_ACTION,
  payload: { action },
});

export const setProviderProfileFormDirty = (dirty: boolean): ProviderProfileAction => ({
  type: ProviderProfileActionTypes.SET_PROVIDER_PROFILE_FORM_DIRTY,
  payload: { dirty },
});
