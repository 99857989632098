import { useActivityTimelineContext } from 'components/ActivityTimeline/store';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  ActivityMonthColumn,
  ActivityMonthColumns,
} from 'types/Patients/PatientTimeline/ActivityTimeline';
import { addDays, convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import ActivityTimelineColumnHeader from '../ActivityColumnHeader';
import styles from '../ActivityItems.module.css';
import DateRangeActivityItems from '../DateRangeActivityItems';
import { getMonthColumns } from './utils/monthViewUtils';
import WeekActivityItems from './WeekActivityItems';

interface IColumnContent {
  abw: ActivityMonthColumn;
  idx: number;
}

interface MonthViewProps {
  isCarePlanPeriod?: boolean;
}
const MonthView: FC<MonthViewProps> = ({ isCarePlanPeriod = false }) => {
  const { activities, currentSelectedDate, setCurrentSelectedDate, draggable, carePlanFrom } =
    useActivityTimelineContext();

  const stringDateRef = useRef<string>(convertDatepickToDate(currentSelectedDate).toISOString());

  useEffect(() => {
    stringDateRef.current = convertDatepickToDate(currentSelectedDate).toISOString();
  }, [currentSelectedDate]);

  const monthColumns: ActivityMonthColumns = useMemo(
    () =>
      getMonthColumns(
        activities,
        convertDatepickToDate(currentSelectedDate),
        isCarePlanPeriod,
        carePlanFrom
      ),
    [activities, currentSelectedDate, carePlanFrom, isCarePlanPeriod]
  );

  const handleNext = () => {
    const convertedDate = convertDatepickToDate(currentSelectedDate);
    let newDate: Date = null;
    if (isCarePlanPeriod) newDate = addDays(convertedDate, 30);
    else newDate = new Date(convertedDate.getFullYear(), convertedDate.getMonth() + 1, 1);

    setCurrentSelectedDate(convertDateToDatePickValue(newDate));
  };

  const handlePrevious = () => {
    const convertedDate = convertDatepickToDate(currentSelectedDate);
    let newDate: Date = null;
    if (isCarePlanPeriod) newDate = addDays(convertedDate, -30);
    else newDate = new Date(convertedDate.getFullYear(), convertedDate.getMonth() - 1, 1);

    setCurrentSelectedDate(convertDateToDatePickValue(newDate));
  };

  const ColumnContent = ({ abw, idx }: IColumnContent) => {
    return (
      <div style={{ padding: '50px 0px 10px 0px' }}>
        <DateRangeActivityItems
          items={monthColumns.dateRangeMonthActivities[idx]}
          isFirst={idx === 0}
        />
        <WeekActivityItems
          items={abw.items}
          rangeItems={monthColumns.dateRangeWeekActivities[idx]}
          weekArray={monthColumns.weekArray[idx]}
        />
      </div>
    );
  };

  return (
    <>
      {monthColumns.activitiesByWeek.map((abw, idx, arr) => {
        const activitiesCount =
          abw.items.reduce<number>((res, i) => (i?.length ? res + i.length : res), 0) +
          (monthColumns.dateRangeWeekActivities[idx]?.length ?? 0);
        return (
          <div
            className={`d-flex flex-column ${styles['activity-column']}`}
            key={`header-${abw.headerLabel}`}
          >
            <ActivityTimelineColumnHeader
              arrowLeft={idx === 0}
              arrowRight={idx === arr.length - 1}
              label={abw.headerLabel}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              activitiesCount={activitiesCount}
              columnCount={arr.length}
            />
            {draggable ? (
              <Droppable droppableId={`monthView__${idx}__${stringDateRef.current}`}>
                {(provided, snapshot) => (
                  <div
                    className={`d-flex flex-column mt-1 gap-sm h-100`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={snapshot.isDraggingOver ? { backgroundColor: '#F7F7FA' } : {}}
                  >
                    <ColumnContent abw={abw} idx={idx} />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : (
              <ColumnContent abw={abw} idx={idx} />
            )}
          </div>
        );
      })}
    </>
  );
};
export default MonthView;
