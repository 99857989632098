import styles from './styles.module.css';

interface ControlTabLinkContainerProps {
  children: React.ReactNode;
  className?: string;
}
const ControlTabLinkContainer = ({ children, className }: ControlTabLinkContainerProps) => (
  <div className={`${styles['control-tab-container']} ${className || ''}`}>
    {children}
  </div>
);
export default ControlTabLinkContainer;
