import { useEffect } from 'react';
import PatientActions from 'screens/ProviderDashboard/PatientActions';
import { IDashboardToDoAlert } from 'types/ApiModels/Dashboard/DashboardToDo';
import AlertGraph from '../../../../AlertGraph';

interface PatientEventDetailsProps {
  event: IDashboardToDoAlert;
  patientId: number;
  left: number;
  onClick: () => void;
  outRef: React.RefObject<HTMLDivElement>;
}
const PatientEventDetails = ({
  left,
  event,
  onClick,
  outRef,
  patientId,
}: PatientEventDetailsProps) => {
  useEffect(() => {
    function handleClickOutside(e) {
      if (outRef.current && !outRef.current.contains(e.target)) {
        onClick();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [outRef, onClick]);

  return (
    <div
      onClick={onClick}
      className={
        event.alert_type === 'adherence'
          ? 'dashboard-patient-event-detail'
          : 'dashboard-patient-event-detail bigger-detail'
      }
      style={{
        left: left,
        cursor: 'default',
        height: 'auto',
        paddingBottom: '15px',
        top: 150,
        maxWidth: '600px',
      }}
    >
      {event.alert_type !== 'adherence' && (
        <div
          style={{
            paddingTop: 15,
            paddingBottom: 5,
            marginLeft: -31,
            marginRight: -31,
          }}
        >
          {(event.type === 'red' || event.type === 'yellow') && (
            <AlertGraph vytal={event.vytal} patientId={patientId} />
          )}
        </div>
      )}
      <div className="d-flex h-100 align-items-center">
        <PatientActions
          alertId={event.id}
          vytalsRequest={event.type === 'red'}
          provideQuestionnaire
          callPatient
          sendAutomatedChat
          requestTelehealthAppointment
          assign
          complete
        />
      </div>
    </div>
  );
};
export default PatientEventDetails;
