import {
  SET_ACTIVE_SCHEDULE_PROPS,
  SET_ACTIVE_UNTIL,
  SET_ACTIVE_UNTIL_DATE,
  SET_END_DATE_VACATION,
  SET_OUT_OF_OFFICE,
  SET_OUT_OF_OFFICE_DATE,
  SET_OUT_OF_OFFICE_MESSAGE,
  SET_START_DATE_VACATION,
  SET_VACATION_MESSAGE,
  SET_VACATION_MODE
} from './actionTypes';

export const setOutOfOffice = (outOfOffice: boolean) => ({
  type: SET_OUT_OF_OFFICE,
  payload: {
    outOfOffice,
  },
});

export const setOutOfOfficeDate = (outOfOfficeDate: Date) => ({
  type: SET_OUT_OF_OFFICE_DATE,
  payload: {
    outOfOfficeDate,
  },
});

export const setOutOfOfficeMessage = (outOfOfficeMessage: string) => ({
  type: SET_OUT_OF_OFFICE_MESSAGE,
  payload: {
    outOfOfficeMessage,
  },
});

export const setActiveUntil = (activeUntil: boolean) => ({
  type: SET_ACTIVE_UNTIL,
  payload: {
    activeUntil,
  },
});

export const setActiveUntilDate = (activeUntilDate: Date) => ({
  type: SET_ACTIVE_UNTIL_DATE,
  payload: {
    activeUntilDate,
  },
});

export const setVacationMode = (vacationMode: boolean) => ({
  type: SET_VACATION_MODE,
  payload: {
    vacationMode,
  },
});

export const setStartDateVacation = (startDateVacation: Date) => ({
  type: SET_START_DATE_VACATION,
  payload: {
    startDateVacation,
  },
});

export const setEndDateVacation = (endDateVacation: Date) => ({
  type: SET_END_DATE_VACATION,
  payload: {
    endDateVacation,
  },
});

export const setVacationMessage = (vacationMessage: string) => ({
  type: SET_VACATION_MESSAGE,
  payload: {
    vacationMessage,
  },
});

export const setActiveScheduleProps = (activeScheduleProps: any) => ({
  type: SET_ACTIVE_SCHEDULE_PROPS,
  payload: activeScheduleProps
});
