import sortEnum from './sortEnum';

const sortLabelMap = {
  [sortEnum.LEAST_EXPENSIVE]: 'least expensive',
  [sortEnum.LONGEST_UNCHANGED]: 'longest unchaged',
  [sortEnum.MOST_EXPENSIVE]: 'most expensive',
  [sortEnum.lASTEST_CHANGED]: 'latest changed',
};

export default sortLabelMap;
