import React from 'react';

interface IPatientRingContainer {
  children: JSX.Element;
  size?: number;
}
const PatientRingContainer = ({ children, size = 72 }: IPatientRingContainer) => {
  return <div style={{ marginLeft: -1 * size, zIndex: 1 }}>{children}</div>;
};
export default PatientRingContainer;
