import ArrowDownTodayDatePicker from 'components/ArrowDownTodayDatePicker';
import Card from 'components/Card';
import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import TimePeriodSelect, { TimePeriodOptions } from 'components/TimePeriodSelect';
import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import styles from './styles.module.css';

interface IRecentActivityHeader {
  selectedDate: DatePickerValue | undefined;
  handleDatePickerChange: (date: DatePickerValue) => void;
  selectedTimePeriod: TimePeriodOptions;
  handleTimePeriodClick: (key: TimePeriodOptions) => MouseEventHandler<HTMLParagraphElement>;
  handleSearch?: ChangeEventHandler<HTMLInputElement>;
  handleFiltersButtonClick?: (filters: any) => void;
}

const RecentActivityHeader = ({
  selectedDate,
  handleDatePickerChange,
  selectedTimePeriod,
  handleTimePeriodClick,
  handleSearch = null,
  handleFiltersButtonClick = null,
}: IRecentActivityHeader) => {
  return (
    <div className={`${styles.container} card-bg-border shadow-sm`}>
      <div className="d-flex gap align-items-center">
        <div style={{ whiteSpace: 'nowrap' }}>Recent activity</div>
        <TimePeriodSelect
          selectedTimePeriod={selectedTimePeriod}
          handleTimePeriodClick={handleTimePeriodClick}
          timeOptions={[TimePeriodOptions.HOUR, TimePeriodOptions.DAY, TimePeriodOptions.WEEK]}
        />
        <ArrowDownTodayDatePicker value={selectedDate} onChange={handleDatePickerChange} />
      </div>
      <div className="d-flex gap justify-content-end">
        <SearchBar placeholder="Quick search" onChange={handleSearch} />
        <FiltersButton onFiltersClick={handleFiltersButtonClick} />
      </div>
    </div>
  );
};
export default RecentActivityHeader;
