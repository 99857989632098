import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import Creatable from 'react-select/creatable';
import { bulkUpdateCareTeams } from 'services/administrationService';
import { CareTeam, CareTeamCreateUpdateRequest } from 'types/ApiModels/CareTeam';
import SelectValueLabel from 'types/Shared/SelectValueLabel';
import { getErrorMessage } from 'util/utils';

interface CareTeamTierProviderInviteFieldProps {
  careTeam: CareTeam;
  onTierCreate: (newCareTeam: CareTeam) => void;
}

const fieldName = 'careTeamTierId';

const CareTeamTierProviderInviteField: FC<CareTeamTierProviderInviteFieldProps> = ({
  careTeam,
  onTierCreate,
}: CareTeamTierProviderInviteFieldProps) => {
  const [careTeamTier, setCareTeamTier] = useState<SelectValueLabel>();
  const [newTierName, setNewTierName] = useState<string>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { errors, touched, setFieldValue, setFieldTouched, values } = useFormikContext();
  const [createTierError, setCreateTierError] = useState('');

  const handleChange = (option: SelectValueLabel) => {
    setCareTeamTier(option);
  };

  useEffect(() => {
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, careTeamTier?.value ?? null, true);
  }, [careTeamTier, setFieldTouched, setFieldValue]);

  const handleCreateTier = (tierName: string) => {
    setNewTierName(tierName);
    setShowConfirmModal(true);
  };

  const options = useMemo(() => {
    return careTeam.tiers.flatMap<SelectValueLabel>((t, idx) => {
      if (idx === 0 && t.providers.length > 0) return [];
      return [{ label: t.name, value: t.id }];
    });
  }, [careTeam.tiers]);

  const formatCreateLabel = (inputValue: string) => {
    return (
      <p className="m-0 p-0">
        Append tier &quot;<b>{inputValue}</b>&quot; to existing care team: <b>{careTeam.name}</b>
      </p>
    );
  };

  const handleCancelCreate = () => {
    setNewTierName(null);
    setCareTeamTier(null);
    setShowConfirmModal(false);
  };
  const handleConfirmCreate = async () => {
    const updatedCareTeam: CareTeamCreateUpdateRequest = {
      id: careTeam.id,
      name: careTeam.name,
      tiers: [
        ...careTeam.tiers,
        {
          name: newTierName,
          number: careTeam.tiers.length + 1,
          providers: [],
          id: undefined,
        },
      ],
    };
    const [res, error] = await bulkUpdateCareTeams([updatedCareTeam]);
    if (res) {
      setShowConfirmModal(false);
      const createdTier = res[0].tiers.find((t) => t.name === newTierName);
      setCareTeamTier({ value: createdTier.id, label: createdTier.name });
      onTierCreate(res[0]);
    }
    if (error) {
      setCreateTierError(getErrorMessage(error));
    }
  };

  return (
    <>
      <div className="flex-grow-1">
        <Field name={fieldName}>
          {() => (
            <Creatable<SelectValueLabel>
              styles={vytracSelectStyle}
              value={careTeamTier}
              onCreateOption={handleCreateTier}
              onChange={handleChange}
              options={options}
              formatCreateLabel={formatCreateLabel}
              isClearable
              placeholder="Select or create tier..."
              className="font-size-medium"
            />
          )}
        </Field>
        {errors[fieldName] && touched[fieldName] && typeof errors[fieldName] === 'string' ? (
          <ErrorMessage name={fieldName}>
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        ) : null}
        {createTierError ? (
          <div className="text-danger font-size-small">{createTierError}</div>
        ) : null}
      </div>
      <SaveChangesConfirmationModal
        message={`Are you sure you want to append a new empty tier to care team: ${careTeam.name}? `}
        variant="caution"
        show={showConfirmModal}
        onCancel={handleCancelCreate}
        onConfirm={handleConfirmCreate}
      />
    </>
  );
};
export default CareTeamTierProviderInviteField;
