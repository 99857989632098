import React, { useCallback } from 'react';

interface GaugeIndicatorProps {
  color?: string;
  percentage: number;
  text: string;
}
export const GaugeIndicator = ({
  color = '#80CCE4',
  percentage = 0,
  text = '',
}: GaugeIndicatorProps) => {
  const toDegrees = useCallback((percentage: number) => (percentage / 100) * 360, []);

  return (
    <>
      <div className="gauge-ring-container">
        <span className="gauge-ring-text text-center">{text}</span>
        <svg
          className="gauge-ring-big"
          viewBox="0 0 36 36"
          style={{
            transform: 'rotate(' + toDegrees(100 - percentage) + 'deg)',
          }}
        >
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={color}
            strokeWidth="4"
            strokeDasharray={percentage + ', 100'}
          />
        </svg>
      </div>
      <div className="gauge-ring-container">
        <svg className="gauge-ring-big" viewBox="0 0 36 36">
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke="#CFD6E2"
            strokeWidth="4"
            strokeDasharray={100 - percentage + ', 100'}
          />
        </svg>
      </div>
    </>
  );
};
export default GaugeIndicator;
