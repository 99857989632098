import Select from 'components/form/Select';
import VytracModal from 'components/Modal';
import React, { FC, useState } from 'react';
import { MarkType } from './utils';
import styles from './styles.module.css';
import TextArea from 'components/Notes/components/TextArea';
import Checkbox from 'components/form/Checkbox';
import DatePicker from 'components/DatePicker';
import { convertDateToDatePickValue } from 'util/dateUtils';
import Button from 'components/Button';

const markTypeOptions = Object.values(MarkType);

const Body = () => {
  const [createFollowUp, setCreateFollowUp] = useState<boolean>(false);
  return (
    <div className={styles.bodyContainer}>
      <div className={styles.rowContainer}>
        <div className={styles.rowTitle}>Type</div>
        <Select
          containerClassName={styles.markSelect}
          selectClassName={styles.markInput}
          options={markTypeOptions}
        />
      </div>
      <hr />
      <div className={styles.noteTitle}>Attach a note</div>
      <TextArea
        className={styles.noteField}
        value=""
        onTextAreaChange={() => null}
        placeholder="Add a note"
      />
      <hr />
      <Checkbox
        label="Create follow-up"
        labelClassName={styles.checkLabel}
        value={createFollowUp}
        onClick={() => setCreateFollowUp(!createFollowUp)}
      />
      {createFollowUp && (
        <>
          <hr />
          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>Select date and time</div>
            <DatePicker
              containerClassName={styles.markSelect}
              inputClassName={styles.markInput}
              value={convertDateToDatePickValue(new Date())}
              onChange={() => null}
            />
          </div>
        </>
      )}
    </div>
  );
};

interface MarkEventModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
const MarkEventModal: FC<MarkEventModalProps> = ({ show, onClose }) => {
  return (
    <div>
      <VytracModal
        show={show}
        onClose={onClose}
        title="Mark event"
        body={<Body />}
        footer={
          <div className="d-flex w-100 justify-content-between">
            <Button label="cancel" variant="cancel" />
            <Button label="confirm" variant="confirm" />
          </div>
        }
        centered
      />
    </div>
  );
};

export default MarkEventModal;
