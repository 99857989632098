import moment from 'moment';
import { createTemplateAction, updateTemplateAction } from 'services/templatesService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { updatePreviousActionsRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/updatePreviousActionsRecurrence';
import { transformToTemplateAction } from 'util/calendarUtils/transformers';

/**
 * updates the action along with the recurrent actions
 */
async function updateOriginalAction(
  updatedPatientActions: IPatientCarePlanAction[],
  originalAction: IPatientTimelineAction,
  blockStartDate: Date,
  templateId: number
) {
  const selectedActionIndex = updatedPatientActions.findIndex((a) => a.id === originalAction.id);

  // update action based on selected action
  const updatedTemplateAction = transformToTemplateAction(originalAction, blockStartDate);
  await updateTemplateAction(templateId, updatedTemplateAction);
  updatedPatientActions[selectedActionIndex] = originalAction as IPatientCarePlanAction;
}

/**
 * updated all the actions starting from the recurrenta action clicked. The actions before are not modified
 * If the selected action occurs the same day as the original one everything is updated
 * @returns the patient actions array with the updated recurrence of the action
 */
async function updateRecurrentAction(
  updatedOriginalAction: IPatientCarePlanAction,
  blockStartDate: Date,
  templateId: number,
  selectedTimelineAction: IPatientTimelineAction,
  updatedPatientActions: IPatientCarePlanAction[]
) {
  const updatedTemplateOriginalAction = transformToTemplateAction(
    updatedOriginalAction,
    blockStartDate
  );
  await updateTemplateAction(templateId, updatedTemplateOriginalAction);

  //create new original action based on selected action
  const selectedTemplateAction = transformToTemplateAction(selectedTimelineAction, blockStartDate);
  const newTemplateAction = await createTemplateAction(templateId, selectedTemplateAction);
  updatedPatientActions = [
    ...updatedPatientActions,
    { ...selectedTimelineAction, id: newTemplateAction.id },
  ];

  return updatedPatientActions;
}

/**
 * @returns the original action associated to the recurrent action selected
 */
const getOriginalAction = (
  selectedTimelineAction: IPatientTimelineAction,
  patientActions: IPatientCarePlanAction[]
): IPatientCarePlanAction => {
  const originalAction = patientActions.find(
    (action) => selectedTimelineAction.originalActivityId === action.id
  );
  return originalAction;
};

/**
 * @returns the updated start date for the original action
 */
const getNewStartDate = (
  selectedTimelineAction: IPatientTimelineAction,
  originalAction: IPatientCarePlanAction
): string => {
  const startMoment = moment(selectedTimelineAction.start_date);
  const previousStartMoment = moment(selectedTimelineAction.previousStartDate);
  const dayDiff = startMoment.diff(previousStartMoment, 'days');
  return moment(originalAction.start_date).add(dayDiff, 'days').toISOString();
};

/**
 * updates the original action taking props from the recurrent selected action
 */
async function updateOriginalFromRecurrent(
  selectedTimelineAction: IPatientTimelineAction,
  patientActions: IPatientCarePlanAction[],
  updatedPatientActions: IPatientCarePlanAction[],
  blockStartDate: Date,
  templateId: number
) {
  const originalAction = getOriginalAction(selectedTimelineAction, patientActions);
  const updatedStartDate = getNewStartDate(selectedTimelineAction, originalAction);
  await updateOriginalAction(
    updatedPatientActions,
    {
      ...selectedTimelineAction,
      id: originalAction.id,
      start_date: updatedStartDate,
      end_date: updatedStartDate,
    },
    blockStartDate,
    templateId
  );
}

export const updateAllEvents = async (
  templateId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction,
  blockStartDate: Date
): Promise<IPatientCarePlanAction[]> => {
  let updatedPatientActions = [...patientActions];

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    await updateOriginalAction(
      updatedPatientActions,
      selectedTimelineAction,
      blockStartDate,
      templateId
    );
  } else {
    // update recurrence of previous actions
    const updatedOriginalAction = updatePreviousActionsRecurrence(
      selectedTimelineAction,
      updatedPatientActions,
      'template'
    );

    if (updatedOriginalAction.recurrence.end_date_duration_days > 0) {
      updatedPatientActions = await updateRecurrentAction(
        updatedOriginalAction,
        blockStartDate,
        templateId,
        selectedTimelineAction,
        updatedPatientActions
      );
    } else {
      // means selected action occurs the same day as the original action. Original action needs to be updated
      await updateOriginalFromRecurrent(
        selectedTimelineAction,
        patientActions,
        updatedPatientActions,
        blockStartDate,
        templateId
      );
    }
  }

  return updatedPatientActions;
};
