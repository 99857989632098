import GenericQuickFilters from 'components/GenericQuickFilters';
import teamNotesQuickFilterList from '../../utils/teamNotesQuickFilters';

const TeamNotesQuickFilters = () => {
  return (
    <div className="d-flex gap align-items-center">
      <GenericQuickFilters filters={teamNotesQuickFilterList} onFilterClick={() => null} />
    </div>
  );
};
export default TeamNotesQuickFilters;
