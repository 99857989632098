import { TimePeriodOptions } from 'components/TimePeriodSelect';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import {
  IPatientCarePlan,
  IPatientCarePlanAction,
  IPatientTimelineAction,
} from 'types/ApiModels/Patients/CarePlan';

export enum PatientCarePlanActionTypes {
  SET_CAREPLAN_STATE = 'SET_CAREPLAN_STATE',
  SET_PREVIOUS_PATIENT_ACTIONS = 'SET_PREVIOUS_PATIENT_ACTIONS',
  SET_SELECTED_DATE = 'SET_SELECTED_DATE',
  SET_PATIENT_ACTIONS = 'SET_PATIENT_ACTIONS',
  SET_TIMELINE_ACTIONS = 'SET_TIMELINE_ACTIONS',
  SET_PERIOD_SELECTED = 'SET_PERIOD_SELECTED',
  SET_SHOW_CAREPLAN_MODAL = 'SET_SHOW_CAREPLAN_MODAL',
  SET_SELECTED_ACTION = 'SET_SELECTED_ACTION',
  SET_CAREPLAN_MODAL_EVENT_TYPE = 'SET_CAREPLAN_MODAL_EVENT_TYPE',
}

export type PatientCarePlanAction =
  | {
      type: PatientCarePlanActionTypes.SET_CAREPLAN_STATE;
      payload: {
        carePlanState: IPatientCarePlan;
      };
    }
  | {
      type: PatientCarePlanActionTypes.SET_SELECTED_DATE;
      payload: {
        selectedDate: Date;
      };
    }
  | {
      type: PatientCarePlanActionTypes.SET_PATIENT_ACTIONS;
      payload: {
        patientActions: IPatientCarePlanAction[];
      };
    }
  | {
      type: PatientCarePlanActionTypes.SET_PREVIOUS_PATIENT_ACTIONS;
    }
  | {
      type: PatientCarePlanActionTypes.SET_TIMELINE_ACTIONS;
      payload: {
        timelineActions: IPatientTimelineAction[];
      };
    }
  | {
      type: PatientCarePlanActionTypes.SET_PERIOD_SELECTED;
      payload: {
        timePeriod: TimePeriodOptions;
      };
    }
  | {
      type: PatientCarePlanActionTypes.SET_SELECTED_ACTION;
      payload: {
        selectedAction: IPatientTimelineAction;
      };
    }
  | {
      type: PatientCarePlanActionTypes.SET_CAREPLAN_MODAL_EVENT_TYPE;
      payload: {
        carePlanModalEventType: CarePlanActionEventType;
      };
    };
