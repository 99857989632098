import React from 'react';
import styles from './styles.module.css';
import { ReactComponent as ExchangeIcon } from 'assets/icons/exchange.svg';
import { RoundTagitem } from 'components/Tagitem';
const TableElement = ({ name, population, onClick }) => {
  return (
    <div className={styles.element} onClick={onClick}>
      <div className={styles.name}>
        <ExchangeIcon height={13} width={14} fill="#1890FF" />
        <div>{name}</div>
      </div>
      {population && <RoundTagitem text={population} containerClassNameList={[styles.usedFor]} />}
    </div>
  );
};

export default TableElement;
