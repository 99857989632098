import DayOfMonthPicker from 'components/DayOfMonthPicker/DayOfMonthPicker';
import MultipleTimeSelector from 'components/MultipleTimeSelector/MultipleTimeSelector';
import {
  mapRepetitionTypeToLabel,
  RepetitionTypeLabel,
} from 'components/RPM/utils/repetitionMapper';
import moment from 'moment';
import React, { useEffect } from 'react';
import Checkbox from '../../../../../../form/Checkbox';
import Select from '../../../../../../form/Select';
import EndDateInput from '../EndDateInput';
import { IModalSection } from '../utils/IModalSection';
import DurationInput from './components/DurationInput';
import WeekDays from './components/WeekDays';
import { useRepetition, useMultipleTimes, useEndDate } from './hooks';
import styles from './styles.module.css';

import { RecurrenceInitialState } from './utils/RecurrenceInitialState';

const repetitionTypeOptions = [...Object.values(RepetitionTypeLabel)];

const RecurrentOptions = ({ data, setData, modalType }: IModalSection) => {
  // add start date to repeats param
  useEffect(() => {
    if (!data?.recurrence) return;

    if (data.recurrence.repeats_type === 'every_week') {
      setData({
        ...data,
        recurrence: {
          ...data.recurrence,
          repeats_param: [moment(data.start_date).day().toString()],
        },
      });
    }

    if (data.recurrence.repeats_type === 'every_month') {
      setData({
        ...data,
        recurrence: {
          ...data.recurrence,
          repeats_param: [moment(data.start_date).date().toString()],
        },
      });
    }
  }, [data?.recurrence?.repeats_type, data?.start_date]);

  // add start time to multiple times a day
  useEffect(() => {
    if (!data?.recurrence) return;
    if (data?.recurrence?.multiple_times_hours?.length > 0) return;

    const startTime = moment(data.start_date);
    const startTimeIdx = data?.recurrence?.multiple_times_hours?.findIndex(
      (time) => time.hour === startTime.hour() && time.minute === startTime.minutes()
    );
    if (startTimeIdx !== -1) return;

    setData({
      ...data,
      recurrence: {
        ...data.recurrence,
        multiple_times_hours: [
          { hour: startTime.hour(), minute: startTime.minute() },
          ...data.recurrence.multiple_times_hours,
        ],
      },
    });
  }, [data?.recurrence?.multiple_times_a_day, data?.start_date, data, setData]);

  const onRecurrentEventChange = () => {
    if (data.recurrence) {
      // delete recurrence field
      const updatedData = { ...data };
      delete updatedData.recurrence;
      setData(updatedData);
    } else {
      // set initial state
      setData({ ...data, recurrence: RecurrenceInitialState });
    }
  };

  const {
    onRepeatsTypeChange,
    onTimesADayChange,
    onRepeatsParamChange,
    onDaysBeforeStartChange,
    onTimePeriodChange,
  } = useRepetition(data, setData);

  const { onAddTime, onDeleteTime, onTimeChange } = useMultipleTimes(data, setData);

  const { onEndDateTypeChange, onFixedDateChange, onDurationDaysChange } = useEndDate(
    data,
    setData
  );

  return (
    <div className="d-flex flex-column w-100">
      <div className={styles.rowContainer}>
        <Checkbox
          label="Recurring Event"
          onClick={() => onRecurrentEventChange()}
          value={data && 'recurrence' in data && data.recurrence !== null}
        />
      </div>
      {data?.recurrence && (
        <>
          <div className="d-flex flex-column">
            <div className={styles.rowContainer}>
              <div>Repeats</div>
              <div className={styles.select}>
                <Select
                  options={repetitionTypeOptions}
                  currentValue={mapRepetitionTypeToLabel(data.recurrence.repeats_type)}
                  style={{
                    backgroundColor: '#FFFF',
                    marginRight: data.recurrence.repeats_type === 'time_period' && '5px',
                  }}
                  onChange={(e) => onRepeatsTypeChange(e.target.value as RepetitionTypeLabel)}
                />
                {data.recurrence.repeats_type === 'time_period' && (
                  <DurationInput
                    value={Number(
                      data.recurrence.repeats_param ? data.recurrence.repeats_param[0] : 1
                    )}
                    onChange={onTimePeriodChange}
                  />
                )}
              </div>
            </div>
            {data.recurrence.repeats_type === 'every_week' && (
              <div className={styles.rowContainer}>
                <div>Days of the week</div>
                <div style={{ width: 230 }}>
                  <WeekDays
                    actionStartDate={data.start_date}
                    weekDaysSelected={data.recurrence.repeats_param ?? []}
                    onRepeatsParamChange={onRepeatsParamChange}
                  />
                </div>
              </div>
            )}
            {data.recurrence.repeats_type === 'every_month' && (
              <div className={styles.rowContainer}>
                <div>Days of the month</div>
                <div style={{ width: '220px' }}>
                  <DayOfMonthPicker
                    actionStartDate={data.start_date}
                    daysSelected={data.recurrence.repeats_param ?? []}
                    onRepeatsParamChange={onRepeatsParamChange}
                  />
                </div>
              </div>
            )}
            {data.recurrence.repeats_type === 'before_start_of_adherence_block' && (
              <div className={styles.rowContainer}>
                <div>Number of days</div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: '220px' }}
                >
                  <div style={{ flexWrap: 'nowrap', marginLeft: '10px' }}>
                    Select number of days
                  </div>
                  <input
                    className={styles.daySelector}
                    type="number"
                    value={data.recurrence.repeats_param}
                    min={1}
                    onChange={onDaysBeforeStartChange}
                  />
                </div>
              </div>
            )}
            <div className={styles.rowContainer}>
              <div>Multiple times a day</div>
              <div className={styles.select}>
                <Select
                  currentValue={data.recurrence.multiple_times_a_day ? 'Yes' : 'No'}
                  options={['Yes', 'No']}
                  style={{
                    backgroundColor: '#FFFF',
                    marginRight: data.recurrence.multiple_times_a_day && '5px',
                    width: '105px',
                  }}
                  onChange={(e) => onTimesADayChange(e.target.value as 'Yes' | 'No')}
                />
                {data.recurrence?.multiple_times_a_day && data.recurrence?.multiple_times_hours && (
                  <div className={styles.select}>
                    <MultipleTimeSelector
                      times={data.recurrence.multiple_times_hours}
                      onAddTime={onAddTime}
                      onDeleteTime={onDeleteTime}
                      onTimeChange={onTimeChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <EndDateInput
            startDate={data?.start_date}
            endDate={data?.recurrence?.end_date}
            endDateType={data?.recurrence?.end_date_type}
            endDateDurationDays={data?.recurrence?.end_date_duration_days}
            onEndDateTypeChange={onEndDateTypeChange}
            onDurationDaysChange={onDurationDaysChange}
            onFixedDateChange={onFixedDateChange}
            modalType={modalType}
          />
        </>
      )}
    </div>
  );
};
export default RecurrentOptions;
