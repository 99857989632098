import { FC, ReactNode } from 'react';
import loginBackground from 'assets/images/login_background.png';
import vytracLogo from 'assets/images/vytrac-logo.png';
import styles from './styles.module.css';

interface RegisterLayoutProps {
  children: ReactNode;
}

const RegisterLayout: FC<RegisterLayoutProps> = ({ children }: RegisterLayoutProps) => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center position-absolute h-100">
        <div className={`${styles['login-background-image-gradient']} ${styles['top-container']}`}>
          <img className={styles['register-bg']} src={loginBackground} alt="login background" />
        </div>
        <div className={styles['bottom-container']}></div>
      </div>
      <main
        className={`d-flex flex-column gap-lg justify-content-center align-items-center ${styles['main']}`}
      >
        <div className={styles['vytrac-logo-container']}>
          <img className={styles['vytrac-logo']} src={vytracLogo} />
        </div>
        {children}
      </main>
    </>
  );
};
export default RegisterLayout;
