import { datePickerDefault } from 'components/ArrowDownTodayDatePicker';
import { IComment } from 'components/CardComment/CardComment';
import { TimePeriodOptions } from 'components/TimePeriodSelect';
import React, { ChangeEventHandler, useState } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import DateCard from './date-card';
import EventCard from './event-card';
import RecentActivityHeader from './recent-activity-header';

interface Event {
  type: string;
  title: string;
  text: string;
}

interface IRecentActivity {
  handleSearch?: ChangeEventHandler<HTMLInputElement>;
  handleFiltersButtonClick?: (filter: any) => void;
}

const RecentActivity = ({
  handleSearch = null,
  handleFiltersButtonClick = null,
}: IRecentActivity) => {
  const [selectedDate, setSelectedDate] = useState<DatePickerValue | undefined>(datePickerDefault);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriodOptions>(
    TimePeriodOptions.DAY
  );

  const handleDatePickerChange = (date: DatePickerValue) => {
    setSelectedDate(date);
  };

  const handleTimePeriodClick = (timePeriod: TimePeriodOptions) => () => {
    setSelectedTimePeriod(timePeriod);
  };

  const eventComments: IComment[] = [
    {
      time: '6:35 PM',
      name: 'Amanda Spence',
      text: 'Completed',
      unreadComment: false,
    },
    {
      time: '6:35 PM',
      name: 'Amanda Spence',
      text: 'Prescribed cetrizine for allergies. Advised to wait for one hour for results. Added call-back appointment for 06.08, assigned to Thomas Wayne',
      unreadComment: false,
    },
    {
      time: '6:36 PM',
      name: 'Thomas Wayne',
      text: 'Pushed questionnaire',
      unreadComment: true,
    },
    {
      time: '6:37 PM',
      name: 'Amanda Spence',
      text: 'Done!',
      unreadComment: true,
    },
  ];

  return (
    <div className="w-100 h-100">
      <RecentActivityHeader
        selectedDate={selectedDate}
        handleDatePickerChange={handleDatePickerChange}
        selectedTimePeriod={selectedTimePeriod}
        handleTimePeriodClick={handleTimePeriodClick}
        handleSearch={handleSearch}
        handleFiltersButtonClick={handleFiltersButtonClick}
      />
      {selectedTimePeriod !== TimePeriodOptions.HOUR && <DateCard date={selectedDate} />}
      <EventCard comments={eventComments} />
      <EventCard
        questionnaire={true}
        comments={[
          {
            time: '6:35 PM',
            name: 'Amanda Spence',
            text: 'Prescribed cetrizine for allergies. Advised to wait for one hour for results. Added call-back appointment for 06.08, assigned to Thomas Wayne',
            unreadComment: false,
          },
        ]}
      />
      {Array.from(Array(6).keys()).map((value) => (
        <EventCard comments={[]} key={value} />
      ))}
    </div>
  );
};
export default RecentActivity;
