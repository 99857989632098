import React, { useEffect } from 'react';
import styles from './styles.module.css';

interface IItem {
  img : string
}

const Item = ({img}: IItem) => {

  return (
    <div className={styles.container}>
      <img className={styles.resourceImg} src={img}></img>
      <span className={styles.itemTitle}>Lorem ipsum dolor sit amet</span>
      <p className={styles.itemText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ac justo lacus. Curabitur sit
        amet mi blandit, eleifend mi eget, efficitur velit....
      </p>
    </div>
  );
};

export default Item;
