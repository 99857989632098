import React from 'react';
import Button from 'components/Button';
import EmergencyButton from '../../../EmergencyButton';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { usePatientFormikContext } from 'screens/Patients/store';
import { updateGoalThreshold } from 'services/patientService';
import { resetEditedGoals } from 'screens/Patients/store/PatientGoalsThresholds';

const Actions = () => {
  const {
    dispatch,
    patientState: {
      patientGoalsThresholds: { goalsUnsavedChanges, thresholds, editedGoals },
    },
  } = usePatientContext();
  const { selectedPatient } = usePatientFormikContext();

  const handleSaveChanges = async () => {
    const promiseArray = [];
    editedGoals.forEach((goal) =>
      promiseArray.push(async () => updateGoalThreshold(selectedPatient.id, thresholds[goal]))
    );
    await Promise.all(promiseArray.map((fn) => fn()));
    console.log('promise');
    dispatch(resetEditedGoals());
  };

  return (
    <div className="d-flex">
      <Button
        className="mx-1"
        label="save changes"
        variant="confirm"
        type="submit"
        disabled={!goalsUnsavedChanges}
        onClick={handleSaveChanges}
      />
      <EmergencyButton />
    </div>
  );
};

export default Actions;
