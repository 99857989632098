import moment, { Moment } from 'moment';
import {
  IPatientActionRecurrence,
  IPatientCarePlanAction,
} from 'types/ApiModels/Patients/CarePlan';

/** Updates the duration of the recurrence acording to every action of the recurrence */
export const updateDurationDays = (
  newDate: Moment,
  originalAction: IPatientCarePlanAction
): IPatientActionRecurrence => {
  const dayDiff = moment(newDate).diff(moment(originalAction.start_date), 'days');
  const updatedDuration = originalAction.recurrence.end_date_duration_days - dayDiff;
  return {
    ...originalAction.recurrence,
    end_date_duration_days: updatedDuration,
  };
};
