import { TimePeriodOptions } from './TimePeriodOptions';

export const mapTimePeriodToLabel = (timePeriod: TimePeriodOptions): string => {
  switch (timePeriod) {
    case TimePeriodOptions.HOUR:
      return 'Hour';
    case TimePeriodOptions.DAY:
      return 'Day';
    case TimePeriodOptions.WEEK:
      return 'Week';
    case TimePeriodOptions.MONTH:
      return 'Month';
    case TimePeriodOptions.CARE_PLAN_PERIOD:
      return 'Care plan period';
  }
};

export const mapLabelToTimePeriod = (label: string) => {
  switch (label) {
    case 'Hour':
      return TimePeriodOptions.HOUR;
    case 'Day':
      return TimePeriodOptions.DAY;
    case 'Week':
      return TimePeriodOptions.WEEK;
    case 'Month':
      return TimePeriodOptions.MONTH;
    case 'Case plan period':
      return TimePeriodOptions.CARE_PLAN_PERIOD;
  }
};
