import Button from 'components/Button';
import Card from 'components/Card';
import { FC } from 'react';
import { EncounterActions } from '../utils';

interface EncounterControlsProps {
  encounter?: any;
}

const EncounterControls: FC<EncounterControlsProps> = ({ encounter }: EncounterControlsProps) => {
  return (
    <Card className="bg-white w-100" bodyClassName='px-3' headers={['Encounter controls']}>
      {encounter && (
        <div className='d-flex gap flex-wrap'>
          {EncounterActions.map((action, index) => (<Button key={index} label={action.label} onClick={action.action} variant={action.complete && 'confirm'} />))}
        </div>
      )}
    </Card>
  );
};
export default EncounterControls;
