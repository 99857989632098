import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getAnalyticsAccruedPatients, getAnalyticsAlerts } from 'services/analyticsService';
import { Alerts } from 'types/ApiModels/Analytics/AnalyticsAlerts';
import { PatientAccrued } from 'types/ApiModels/Analytics/AnalyticsPatientAccrued';
import DatePickerValue from 'types/Shared/DatePicker';
import { setAlerts, setPatientAccrued } from '../AnalyticsActionCreators';
import { AnalyticsAction } from '../AnalyticsActionTypes';
import { useAnalyticsContext } from '../AnalyticsContext';

const usePatientsAccrued = (
dateFrom: DatePickerValue,
dateTo: DatePickerValue
): [PatientAccrued, Dispatch<AnalyticsAction>, () => void] => {
  const {
    state: { patientAccrued },
    dispatch,
    fetchRegistry,
  } = useAnalyticsContext();

  const [patientAccruedState, setPatientAccruedState] = useState<PatientAccrued>();

  const previousDateFrom = useRef(dateFrom);
  const previousDateTo = useRef(dateTo);
  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    previousDateFrom.current = { year: null, month: null, day: null };
    previousDateTo.current = { year: null, month: null, day: null };
    fetchRegistry.current.alerts.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchPatientAccrued = useCallback(async () => {
    if (!dateFrom || !dateTo) return;
    const data = await getAnalyticsAccruedPatients(
      `${dateFrom.year}-${dateFrom.month}-${dateFrom.day}`,
      `${dateTo.year}-${dateTo.month}-${dateTo.day}`
    );
    setPatientAccruedState(data);
  }, [dateFrom, dateTo]);

  const patientAccruedInit = useCallback(async () => {
    fetchRegistry.current.patientAccrued.list = true;
    await fetchPatientAccrued();
    fetchRegistry.current.patientAccrued.list = false;
  }, [fetchRegistry, fetchPatientAccrued]);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    if (
      previousDateFrom.current.year != dateFrom.year ||
      previousDateFrom.current.month != dateFrom.month ||
      previousDateFrom.current.day != dateFrom.day ||
      previousDateTo.current.year != dateTo.year ||
      previousDateTo.current.month != dateTo.month ||
      previousDateTo.current.day != dateTo.day ||
      (!patientAccrued && !fetchRegistry.current.patientAccrued.list)
    ) {
        patientAccruedInit();
      previousDateFrom.current = { ...dateFrom };
      previousDateTo.current = { ...dateTo };
    }
  }, [fetchRegistry, patientAccrued, patientAccruedInit, dateFrom, dateTo,  _refetch.current]);

  useEffect(() => {
     dispatch(setPatientAccrued(patientAccruedState));
  }, [dispatch, patientAccruedState]);

  return [patientAccruedState, dispatch, refetch];
};

export default usePatientsAccrued;
