import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';

const defaultRenderOption = (option, idx) => (
  <option
    value={option}
    key={`patient-management-select-option-${option}-${idx}`}
    className="text-capitalize"
  >
    {option}
  </option>
);

const PatientManagementSelect = ({
  value,
  name,
  onChange,
  error,
  options,
  renderOption = defaultRenderOption,
}) => {
  const [backgroundColor, setBackgroundColor] = useState('#F8F9FB');

  const handleFocus = () => setBackgroundColor('#FFFFFF');

  const handleBlur = () => setBackgroundColor('#F8F9FB');

  return (
    <div className={styles.main}>
      <Form.Control
        size="sm"
        style={{
          backgroundColor,
          borderColor: error ? 'red' : undefined,
        }}
        className={styles.main}
        onFocus={handleFocus}
        onBlur={handleBlur}
        as="select"
        value={value}
        name={name}
        onChange={onChange}
      >
        <option hidden value={null}>
          Select...
        </option>
        {options?.map(renderOption)}
      </Form.Control>
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  );
};

export default PatientManagementSelect;
