import {
  mapLabelToRepetitionType,
  RepetitionTypeLabel,
} from 'components/RPM/utils/repetitionMapper';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';

const useRepetition = (
  data: IPatientCarePlanAction,
  setData: (updatedSelectedAction: IPatientTimelineAction) => void
): {
  onRepeatsTypeChange: (value: RepetitionTypeLabel) => void;
  onTimesADayChange: (value: 'Yes' | 'No') => void;
  onRepeatsParamChange: (param: string[]) => void;
  onDaysBeforeStartChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTimePeriodChange: (days: number) => void;
} => {
  const onRepeatsTypeChange = (value: RepetitionTypeLabel) => {
    const repetition = mapLabelToRepetitionType(value);
    const updatedRecurrence = {
      ...data.recurrence,
      repeats_type: repetition,
      repeats_param: null,
    };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  const onTimesADayChange = (value: 'Yes' | 'No') => {
    const updatedRecurrence = {
      ...data.recurrence,
      multiple_times_a_day: value === 'Yes' ? true : false,
      multiple_times_hours: [],
    };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  const onRepeatsParamChange = (param: string[]) => {
    const updatedRecurrence = { ...data.recurrence, repeats_param: param };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  const onDaysBeforeStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value < 2 || value > 29) return;

    onRepeatsParamChange([e.target.value]);
  };

  const onTimePeriodChange = (days: number) => {
    if (days < 1) return;

    const updatedRecurrence = { ...data.recurrence, repeats_param: [days.toString()] };
    setData({ ...data, recurrence: updatedRecurrence });
  };

  return {
    onRepeatsTypeChange,
    onTimesADayChange,
    onRepeatsParamChange,
    onDaysBeforeStartChange,
    onTimePeriodChange,
  };
};
export default useRepetition;
