
import Card from 'components/Card';
import SatisfactionBar from 'components/SatisfactionBar';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Provider } from 'types/ApiModels/Providers/Provider';
import styles from './styles.module.css';


interface ProviderSatisfactionProps {
  selectedProvider: Provider;
  satisfaction: any;
}

const specialtiesField = {
  label: 'Specialties',
  name: 'specialties',
};

const ProviderSatisfactionHeader = () => {
  return (
    <div className="d-flex w-100 justify-content-between">
      <div>Satisfaction</div>
      <div className="d-flex">
        <div className="text-primary d-flex align-items-center mr-2 cursor-pointer">
           Encounters        
        </div>
      </div>
    </div>
  );
};

const ProviderSatisfaction = ({
  selectedProvider,
  satisfaction,
}: ProviderSatisfactionProps) => {
  const { setFieldTouched, values, setFieldValue } = useFormikContext<{ satisfaction: number }>();
  const [porcentage, setPorcentage] = useState<number>();

  useEffect(() => {
    if (satisfaction) setSatisfactionPorcentage();
  }, [satisfaction]);

  const setSatisfactionPorcentage = () => {
    const data = (satisfaction.bad / (satisfaction.bad + satisfaction.good)) * 100; 
    setPorcentage(data)
  };


  return (
    <Card className="w-100 card-bg-border" headers={[<ProviderSatisfactionHeader key={0} />]}>
      <div className="d-flex justify-content-center">
        <SatisfactionBar className={styles.satisfactionBar} value={porcentage} />
      </div>
    </Card>
  );
};
export default ProviderSatisfaction;
