import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import styles from './styles.module.css';

const MessageInput = ({
  className = '',
  disabled = false,
  value: controlledValue = undefined,
  onChange = undefined,
  handleSendMessage = (value: string) => {},
}) => {
  const deferredValue = useRef('');
  const [uncontrolledValue, setUncontrolledValue] = useState('');
  const handleUncontrolledValue = (e) => {
    deferredValue.current = e.target.value;
    setUncontrolledValue(e.target.value);
  };

  const handleControlledValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    deferredValue.current = e.target.value;
    onChange(e.target.value);
  };

  const inputRef = useRef(null);

  const handleClick = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const value = controlledValue != null ? controlledValue : uncontrolledValue;
  const handleChange =
    controlledValue != null ? handleControlledValueChange : handleUncontrolledValue;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage(value);
      return;
    }
  };

  const handleSendButtonClick = () => {
    handleSendMessage(value);
  };

  return (
    <div className={`${styles.main} ${className}`} onClick={() => handleClick(inputRef)}>
      <input
        disabled={disabled}
        type="text"
        className={styles.input}
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyDown}
      />
      {!disabled && (
        <button
          className={styles.button}
          type="button"
          onClick={handleSendButtonClick}
          disabled={!value}
        >
          Send
        </button>
      )}
    </div>
  );
};

export default MessageInput;
