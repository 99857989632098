import { UserBubbleGroupCollapsed } from 'components/UserBubbleGroup';
import React, { useMemo, useRef, useState } from 'react';
import { CareTeam, CareTeamProvider } from 'types/ApiModels/CareTeam';
import getCareTeamInitials from 'util/getTeamInitials';
import CareTeamPopup from './components/CareTeamPopup';
import ProviderPopup from './components/ProviderPopup';
import styles from './styles.module.css';

interface ICareTeam {
  careTeam: CareTeam;
}
const CareTeamPreview = ({ careTeam }: ICareTeam) => {
  const [showCareTeamPopup, setShowCareTeamPopup] = useState<boolean>(false);
  const [leftPopupValue, setLeftPopupValue] = useState<number>(0);
  const [topProviderPopupValue, setTopProviderPopupValue] = useState<number>(0);
  const [showProviderPopup, setShowProviderPopup] = useState<boolean>(false);
  const [providerSelected, setProviderSelected] = useState<CareTeamProvider>();

  const containerRef = useRef<HTMLDivElement>();

  const teamInitials: string[] = useMemo(() => {
    if (!careTeam) return [];
    return getCareTeamInitials(careTeam);
  }, [careTeam]);

  const onClickBubbleGroup = () => {
    setShowCareTeamPopup(!showCareTeamPopup);

    if (containerRef.current) {
      setLeftPopupValue(containerRef.current.getBoundingClientRect().left);
    }
  };

  const handleProviderClick = (provider: CareTeamProvider, topPosition: number) => {
    setProviderSelected(provider);
    setShowProviderPopup(!showProviderPopup);
    setTopProviderPopupValue(topPosition);
  };

  const closeCareTeamPopup = () => {
    setShowCareTeamPopup(false);
    setShowProviderPopup(false);
  };

  return (
    <div className={styles.main} ref={containerRef}>
      <div className={styles.title}>care team</div>
      <div className={styles.bottom}>
        {careTeam ? (
          <>
            <UserBubbleGroupCollapsed
              initials={teamInitials}
              clickable={true}
              onClick={onClickBubbleGroup}
            />
            {showCareTeamPopup && (
              <CareTeamPopup
                careTeam={careTeam}
                showPopup={showCareTeamPopup}
                leftPosition={leftPopupValue}
                onProviderClick={handleProviderClick}
                closeCareTeamPopup={closeCareTeamPopup}
              />
            )}
            {showProviderPopup && (
              <ProviderPopup
                provider={providerSelected}
                leftPosition={leftPopupValue}
                topPosition={topProviderPopupValue}
                showProviderPopup={showProviderPopup}
                closeProviderPopup={() => setShowProviderPopup(false)}
              />
            )}
            <div className={styles.text}>{careTeam.name}</div>
          </>
        ) : (
          <div className={styles.disabledText}>No Care Team assigned</div>
        )}
      </div>
    </div>
  );
};
export default CareTeamPreview;
