import { FC, Fragment, useMemo } from 'react';
import { ActivityType, DateRangeActivity } from 'types/Patients/PatientTimeline/ActivityTimeline';
import DateRangeActivityItem from './DateRangeActivityItem';
import { getFillerArray } from './DateRangeActivityItem/utils/getFillerArray';

interface DateRangeActivityItemsProps {
  items: DateRangeActivity[];
  isFirst: boolean;
}

const DateRangeActivityItems: FC<DateRangeActivityItemsProps> = ({
  items,
  isFirst,
}: DateRangeActivityItemsProps) => {
  const itemsSortedByRenderRow: DateRangeActivity[] = useMemo(() => {
    if (!items) return [];
    const itemsCopy = [...items];
    itemsCopy.sort((a, b) => (a.renderRow > b.renderRow ? 1 : -1));
    return itemsCopy;
  }, [items]);

  return (
    <>
      {itemsSortedByRenderRow.map((dai, idx, arr) => {
        const filler: JSX.Element[] = getFillerArray(idx, dai, arr);
        return (
          <Fragment key={`range-activity-${dai.id}`}>
            {filler}
            <DateRangeActivityItem
              activityId={dai.id}
              type={dai.type}
              chunk={dai.chunk}
              isEnding={dai.isEnding}
              isStarting={dai.isStarting}
              label={
                dai.type === ActivityType.ADHERENCE_BLOCK
                  ? 'Adherence Block'
                  : dai.action_props.goal_name
              }
              renderLabel={dai.isStarting || isFirst}
            />
          </Fragment>
        );
      })}
    </>
  );
};
export default DateRangeActivityItems;
