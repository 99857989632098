import React, { useState } from 'react';
import Button from 'components/Button';
import InvitePatientModal from 'components/InvitePatientModal/InvitePatientModal';

const PatientListActions = () => {
  const [showAddPatientModal, setShowAddPatientModal] = useState<boolean>(false);
  return (
    <div className="d-flex">
      <Button label="import patient" className="mx-1" />
      <Button label="add patient" className="mx-1" onClick={() => setShowAddPatientModal(true)} />
      <InvitePatientModal
        show={showAddPatientModal}
        handleClose={() => setShowAddPatientModal(false)}
        handleConfirm={() => setShowAddPatientModal(false)}
      />
    </div>
  );
};

export default PatientListActions;
