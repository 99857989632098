const today = new Date();
const lapsed = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + 30,
);
export const schedulesInitialStartDatepick = {
  year: lapsed.getFullYear(),
  month: lapsed.getMonth() + 1,
  day: lapsed.getDate(),
};
export const schedulesInitialEndDatepick = {
  year: today.getFullYear(),
  month: today.getMonth() + 1,
  day: today.getDate(),
};
export const schedulesInitialDatepickRange = {
  from: schedulesInitialEndDatepick,
  to: schedulesInitialStartDatepick,
};
