import { useDialog } from 'components/VyTracLayout/store/hooks';
import { convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  setCurrentEmailSetting,
  setInsertShortCodeToEditor,
  updateCurrentEmailSettingType,
  updateEmailSettingAction,
} from 'screens/Administration/store/EmailSettings';
import { useAdminEmailSettings } from 'screens/Administration/store/hooks';
import { createEmailSetting, updateEmailSetting } from 'services/administrationService';
import {
  EmailSetting,
  EmailSettingType,
  EmailShortCode,
  EmailTypeTuple,
} from 'types/ApiModels/Administration';
import { debouncePromiseValue } from 'util/utils';
import EmailSettingItemHeader from './EmailSettingItemHeader';
import styles from './styles.module.css';
import { EmailTypeOption } from './types';

interface EmailSettingCardProps {
  emailSetting: EmailSetting;
  allowedShortCodes: EmailShortCode[];
  types: EmailTypeTuple[];
}

const debouncedUpdate = debouncePromiseValue(updateEmailSetting, 1000) as (
  args
) => Promise<[res: any, error: any]>;

const EmailSettingItem = ({ emailSetting, allowedShortCodes, types }: EmailSettingCardProps) => {
  const [currentType, setCurrentType] = useState<EmailTypeOption>();
  const [editorState, setEditorState] = useState<EditorState>(() =>
    emailSetting?.editor_body
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(emailSetting.editor_body)))
      : EditorState.createEmpty()
  );
  const [subject, setSubject] = useState(emailSetting?.subject || '');
  const [collapsed, setCollapsed] = useState(emailSetting?.id > 0);
  const [isDirty, setIsDirty] = useState(false);
  const [customName, setCustomName] = useState(emailSetting.name);
  const [originalIsActive, setOriginalIsActive] = useState(emailSetting.active);
  const { confirmationDialog, errorDialog } = useDialog();

  const isSubmittingFormRef = useRef(false);

  const [{ currentEmailSetting }, dispatch] = useAdminEmailSettings();

  const handleTypeChange = (v: EmailTypeOption) => {
    setCurrentType(v);
  };

  const handleCustomNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomName(e.target.value);
    if (!collapsed) {
      setIsDirty(true);
    }
  };

  const updateCustomName = useCallback(async () => {
    if (emailSetting.name === customName || emailSetting.id < 0) return;
    const [, error] = await debouncedUpdate({ ...emailSetting, name: customName });
    if (error) {
      errorDialog('Error', 'Error while trying to save custom name');
    }
    setIsDirty(false);
  }, [customName, debouncedUpdate, emailSetting, errorDialog]);

  useEffect(() => {
    if (collapsed && !isSubmittingFormRef.current) {
      updateCustomName();
    }
  }, [collapsed, isDirty, updateCustomName]);

  useEffect(() => {
    if (currentType || !types) return;
    const [, expectedLabel] = types.find(([code]) => code === emailSetting.type);
    const isCustom = expectedLabel !== emailSetting.name;
    setCurrentType(
      isCustom
        ? { value: EmailSettingType.custom, label: 'Custom' }
        : {
            value: emailSetting.type,
            label: emailSetting.name,
          }
    );
  }, [currentType, emailSetting.name, emailSetting.type, types]);

  const customEntityTransform = (entity) => {
    if (entity.type === 'MENTION') {
      return `{{${entity.data.value}}}`;
    }
  };

  const getEmailSettingDataForCommit = useCallback(() => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return {
      subject,
      editorContentJson: JSON.stringify(raw),
      editorContentHtml: draftToHtml(raw, undefined, undefined, customEntityTransform),
    };
  }, [editorState, subject]);

  const handleEditorStateChange = (st) => {
    const currentContent = editorState.getCurrentContent();
    const newContent = st.getCurrentContent();
    if (currentContent !== newContent) {
      setIsDirty(true);
    }
    setEditorState(st);
  };

  useEffect(() => {
    if (collapsed) {
      dispatch(setCurrentEmailSetting(null));
    }
  }, [collapsed, dispatch]);

  const handleCollapse = () => {
    setCollapsed((c) => !c);
  };

  const handleAddShortCodeToEditor = useCallback(
    (shortCode: EmailShortCode) => {
      const newContentState = editorState
        .getCurrentContent()
        .createEntity('MENTION', 'IMMUTABLE', { text: shortCode.name, value: shortCode.code });
      const entityKey = newContentState.getLastCreatedEntityKey();

      const contentState = Modifier.replaceText(
        newContentState,
        editorState.getSelection(),
        `@${shortCode.code}`,
        editorState.getCurrentInlineStyle(),
        entityKey
      );

      handleEditorStateChange(EditorState.push(editorState, contentState, 'insert-characters'));
    },
    [editorState]
  );

  const updateIsActive = useCallback(async () => {
    const [, error] = await debouncedUpdate(emailSetting);
    if (error) {
      errorDialog(
        'Error',
        `Error while trying to save active status ${
          typeof error !== 'string' ? Object.values(error).join(', ') : ''
        }`
      );
      return;
    }
    setOriginalIsActive(emailSetting.active);
  }, [emailSetting, errorDialog]);

  useEffect(() => {
    if (
      collapsed &&
      emailSetting.id > 0 &&
      !isSubmittingFormRef.current &&
      emailSetting.active !== originalIsActive
    ) {
      updateIsActive();
    }
  }, [collapsed, emailSetting, originalIsActive, updateIsActive]);

  useEffect(() => {
    dispatch(setInsertShortCodeToEditor(handleAddShortCodeToEditor));
  }, [dispatch, handleAddShortCodeToEditor]);

  useEffect(() => {
    if (currentType && currentType?.value !== emailSetting.type) {
      dispatch(updateCurrentEmailSettingType(currentType?.value));
    }
  }, [currentType, dispatch, emailSetting.type]);

  const handleSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
    setIsDirty(true);
  };

  const templateHasData = useMemo(
    () => Boolean(editorState.getCurrentContent().hasText() || subject),
    [editorState, subject]
  );

  //Dangerous, it has a lot of dependencies and does a request.
  const handleSubmitOnCollapse = useCallback(async () => {
    if (!collapsed || !isDirty || !templateHasData) return;
    const { editorContentHtml, editorContentJson, subject } = getEmailSettingDataForCommit();
    isSubmittingFormRef.current = true;
    const emailSettingToSave: EmailSetting = {
      ...emailSetting,
      name: currentType.value === EmailSettingType.custom ? customName : currentType?.label,
      subject,
      editor_body: editorContentJson,
      body: editorContentHtml,
    };
    if (emailSetting.id < 0) {
      // save changes on collapse.
      const [createResponse, createError] = await createEmailSetting(emailSettingToSave);
      if (createResponse) {
        confirmationDialog('Success', 'Data saved successfully');
        setIsDirty(false);
        dispatch(updateEmailSettingAction(createResponse, emailSettingToSave.id));
      } else {
        errorDialog(
          'Error',
          `An error occurred when trying to save the data: ${
            typeof createError !== 'string' ? Object.values(createError).join(', ') : ''
          }`
        );
        isSubmittingFormRef.current = false;
        return;
      }
    } else {
      const [updateResponse, updateError] = await debouncedUpdate(emailSettingToSave);
      if (updateResponse) {
        setIsDirty(false);
        confirmationDialog('Success', 'Data saved successfully');
        dispatch(updateEmailSettingAction(updateResponse));
      } else {
        errorDialog(
          'Error',
          `An error occurred when trying to save the data: ${Object.values(updateError).join(', ')}`
        );
        isSubmittingFormRef.current = false;
        return;
      }
    }
    isSubmittingFormRef.current = false;
  }, [
    collapsed,
    confirmationDialog,
    currentType?.label,
    currentType?.value,
    customName,
    dispatch,
    emailSetting,
    errorDialog,
    getEmailSettingDataForCommit,
    isDirty,
    templateHasData,
  ]);

  useEffect(() => {
    handleSubmitOnCollapse();
  }, [handleSubmitOnCollapse]);

  useEffect(() => {
    if (!collapsed && emailSetting.id !== currentEmailSetting?.id) {
      dispatch(setCurrentEmailSetting(emailSetting));
    }
  }, [collapsed, currentEmailSetting?.id, dispatch, emailSetting]);

  return (
    <Card className={`${styles['email-setting-card']} overflow-visible`}>
      <EmailSettingItemHeader
        currentType={currentType}
        emailSetting={emailSetting}
        types={types ?? []}
        handleTypeChange={handleTypeChange}
        collapsed={collapsed}
        handleCollapse={handleCollapse}
        getEmailSettingEditorData={getEmailSettingDataForCommit}
        customName={customName}
        handleCustomNameChange={handleCustomNameChange}
      />
      <Accordion.Collapse
        eventKey={emailSetting.id.toString()}
        timeout={150}
        className="bg-light-gray"
      >
        <>
          <hr className="m-0" />
          <div className={`pl-4 pb-2 `}>
            <div className={`d-flex flex-column gap-sm`}>
              <div className={`d-flex gap align-items-center py-1 px-3 ${styles['subcard']} mt-1`}>
                <label className="m-0 font-size-big">Subject line</label>
                <input
                  className={`${styles['subject-input']} flex-grow-1 rounded pl-2`}
                  value={subject}
                  onChange={handleSubjectChange}
                />
              </div>
              <div className={`d-flex align-items-center py-1 px-3 ${styles['subcard']}`}>
                <label className="m-0 font-size-big">Email body</label>
              </div>
              <div>
                <div className="w-100">
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorStateChange}
                    editorClassName={styles['editor']}
                    wrapperClassName="overflow-visible"
                    toolbarClassName={styles['toolbar']}
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'list',
                        'textAlign',
                        'history',
                      ],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                    mention={{
                      separator: ' ',
                      trigger: '@',
                      suggestions: allowedShortCodes.map((sc) => ({
                        text: sc.name,
                        value: sc.code,
                      })),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </Accordion.Collapse>
    </Card>
  );
};
export default EmailSettingItem;
