import { CareTeam, CareTeamTier } from 'types/ApiModels/CareTeam';
import { getNameInitials, getUserFullName } from 'util/userUtils';

const getCareTeamInitials = (careTeam: CareTeam): string[] => {
  const initials: string[] = [];
  careTeam.tiers.forEach((tier) =>
    tier.providers.forEach((provider) => initials.push(getNameInitials(getUserFullName(provider))))
  );
  return initials;
};

export const getCareTeamInitialsByTiers = (careTeamTears: CareTeamTier[]): string[] => {
  const initials: string[] = [];
  careTeamTears.forEach((tier) =>
    tier.providers.forEach((provider) => initials.push(getNameInitials(getUserFullName(provider))))
  );
  return initials;
};

export default getCareTeamInitials;
