import addCircleButtonSrc from 'assets/icons/plus_button_icon.svg';
import FiltersButton from 'components/FiltersButton';
import DateRangePicker from 'components/DateRangePicker';
import { setSchedulesDateRange } from '../../store/schedules/schedules-action-creators';
import { useEffect } from 'react';
import { useSchedulesContext } from '../../store/schedules';
import { schedulesInitialDatepickRange } from '../utils/schedules-utils';

const ScheduleCardHeader = ({ handleFiltersButtonClick }) => {
  const { currentDateRange, dispatch } = useSchedulesContext();

  const handleDateRangePickerChange = (range) => {
    dispatch(setSchedulesDateRange(range));
  };

  useEffect(() => {
    dispatch(setSchedulesDateRange(schedulesInitialDatepickRange));
  }, [dispatch]);
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div>Schedule</div>
      <div className={`d-flex gap-sm`}>
        <DateRangePicker
          handleDateRangePickerChange={handleDateRangePickerChange}
          currentDateRange={currentDateRange}
        />
      </div>
      <div className="d-flex align-items-center gap">
        <img src={addCircleButtonSrc} height={20} width={20} alt="add sticky note button" />
        <FiltersButton onFiltersClick={handleFiltersButtonClick} />
      </div>
    </div>
  );
};
export default ScheduleCardHeader;
