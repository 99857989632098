import ControlButton from 'components/ControlButton';
import VytracSurveyBuilder from 'components/VytracSurveyBuilder';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import useDashboardPatientQuestionnaires from 'screens/ProviderDashboard/store/hooks/useDashboardPatientQuestionnaires';
import useDashboardQuestionnaireTemplates from 'screens/ProviderDashboard/store/hooks/useDashboardQuestionnaireTemplates';
import { getQuestionnaire } from 'services/questionnaireService';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';
import { IProviderAction } from 'types/ApiModels/Providers/ProviderAction';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import PatientQuestionnairesList from './PatientQuestionnairesList';
import AnswerNode from './QuestionnaireNodes/AnswerNode';
import QuestionNode from './QuestionnaireNodes/QuestionNode';
import QuestionnaireTemplatesList from './QuestionnaireTemplatesList';
import styles from './styles.module.css';
interface Step1Props {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
}
const Step1 = ({ currentTab, setCurrentTab }: Step1Props) => {
  const {
    state: { currentToDo },
  } = useDashboardContext();

  const [initialValues, setInitialValues] = useState({ questions: [], answers: [], edges: [] });
  const [elements, setElements] = useState([]); //Questionnaire Name
  const [template, setTemplate] = useState<IQuestionnaire>(null);
  const [patientQuestionnaire, setPatientQuestionnaire] = useState<IPatientQuestionnaire>(null);
  const [questionnaireRegistry, setQuestionnaireRegistry] = useState<
    Record<number, { name: any; answers: any; questions: any; edges: any }>
  >({});

  const { setFieldValue, values } = useFormikContext<Partial<IProviderAction>>();

  const [patientQuestionnaires] = useDashboardPatientQuestionnaires(currentToDo.id);
  const [{ book: templatesBook, currentPage }] = useDashboardQuestionnaireTemplates();
  const templates = useMemo(
    () => templatesBook?.[currentPage]?.results,
    [templatesBook, currentPage]
  );

  const fetchQuestionnaire = useCallback(async () => {
    try {
      const id = template.id;
      const data = await getQuestionnaire(id);
      setInitialValues(data);
      setQuestionnaireRegistry((prev) => ({ ...prev, [id]: data }));
    } catch (e) {
      throw e;
    }
  }, [template?.id]);

  //Fetch questionnaire template details (from api and from cache)
  useEffect(() => {
    if (template && template.id && !questionnaireRegistry[template.id]) {
      fetchQuestionnaire();
    } else if (template && template.id) {
      setInitialValues(questionnaireRegistry[template.id]);
    }
    return () => {
      setInitialValues({ questions: [], answers: [], edges: [] });
    };
  }, [template, questionnaireRegistry, fetchQuestionnaire]);

  //Load states data from formik values
  useEffect(() => {
    if (values.questionnaire_id && !patientQuestionnaire && patientQuestionnaires) {
      setPatientQuestionnaire(
        patientQuestionnaires.find((pq) => pq.id === values.questionnaire_id)
      );
    }
    if (values.questionnaire_id && !template && templates && currentTab == 1) {
      setTemplate(templates.find((t) => t.id === values.questionnaire_id));
    }
  }, [values, patientQuestionnaire, template, currentTab, patientQuestionnaires, templates]);

  //Load formik values when states change
  useEffect(() => {
    if (patientQuestionnaire) {
      setFieldValue('questionnaire_id', patientQuestionnaire.id);
    }
    if (template) {
      setFieldValue('questionnaire_id', template.id);
    }
  }, [
    currentTab,
    elements,
    patientQuestionnaire,
    template,
    setFieldValue,
    values.questionnaire_id,
  ]);
  //Render
  return (
    <>
      <div className="d-flex flex-column flex-grow-1" style={{ height: '95%' }}>
        <div className="d-flex gap pb-4">
          <ControlButton selected={currentTab === 0} onClick={() => setCurrentTab(0)}>
            Patient Questionnaires
          </ControlButton>
          <ControlButton selected={currentTab === 1} onClick={() => setCurrentTab(1)}>
            Questionnaires Templates
          </ControlButton>
        </div>
        <div className={styles['questionnaire-builder']}>
          {currentTab === 1 && (
            <QuestionnaireTemplatesList
              templates={templates}
              setTemplate={setTemplate}
              selectedTemplate={template}
            />
          )}
          {currentTab === 0 && (
            <PatientQuestionnairesList
              patientQuestionnaires={patientQuestionnaires}
              setPatientQuestionnaire={setPatientQuestionnaire}
              selectedPatientQuestionnaire={patientQuestionnaire}
            />
          )}
          {currentTab !== 0 && (
            <>
              <ReactFlowProvider>
                <VytracSurveyBuilder
                  elements={elements}
                  setElements={setElements as any}
                  questionNodeType={QuestionNode}
                  answerNodeType={AnswerNode}
                  initialValues={initialValues}
                  editable={currentTab === 2}
                  className={styles['questionnaire']}
                />
              </ReactFlowProvider>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Step1;
