import PopulationsModal from 'components/PopulationsModal/PopulationsModal';
import { Tagitem } from 'components/Tagitem';
import React, { useContext, useState } from 'react';
import { ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import useTagsManagement from './useTagsManagement';
import { AddIcon, DeleteIcon } from './utils/tagIcons';

interface ITagsManagement {
  options: any[];
  multiple: boolean;
}

const TagsManagement = ({options, multiple}: ITagsManagement) => {
  // const { selectedPatient } = useContext(PatientFormikContext);
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
  //  const { addOptions, deleteOption } = useTagsManagement();

  const onPopulationModalConfirm = (actions: ITemplateCarePlanAction[]) => {
    // addOptions(actions);
    setShowTagsModal(false);
  };

  const onDeleteOption = (optionId: number) => {
    //  deleteOption(optionId);
  };

  return (
    <div className="d-flex flex-wrap">
        {options?.map((option) => (
          <Tagitem
            text={option.name}
            className="tag-item font-size-medium cursor-pointer"
            icon={<DeleteIcon onClick={() => onDeleteOption(option.id)} />}
            key={`tag-item-${option.id}`}
          />
        ))}
        { multiple || options.length < 1 ?  
            <Tagitem
            text="Add"
            className="tag-item font-size-medium cursor-pointer"
            icon={<AddIcon />}
            onClick={() => setShowTagsModal(true)}
          /> : null
        }
      {/* <PopulationsModal
        onClose={() => setShowTagsModal(false)}
        onConfirm={onPopulationModalConfirm}
        show={showTagsModal}
        patientPopulations={options.map((population) => population.id)}
        patientActions={patientActions}
      /> */}
    </div>
  );
};

export default TagsManagement;
