import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import TimePeriodSelect, { TimePeriodOptions } from 'components/TimePeriodSelect';
import { setTimePeriodSelected } from 'screens/Appointments/store';
import { useAppointmentsContext } from 'screens/Appointments/store/AppointmentsContext';
import styles from './styles.module.css';

const timePeriodOptions = [TimePeriodOptions.DAY, TimePeriodOptions.WEEK, TimePeriodOptions.MONTH];
const timeZoneValue = 'UTC +3';

export default function Header() {
  const {
    dispatch,
    appointmentsState: { timePeriodSelected },
  } = useAppointmentsContext();
  return (
    <div className={styles.header}>
      <div>
        <div className={styles.title}>Calendar</div>
        <TimePeriodSelect
          selectedTimePeriod={timePeriodSelected}
          timeOptions={timePeriodOptions}
          handleTimePeriodClick={(timePeriod) => dispatch(setTimePeriodSelected(timePeriod))}
        />
      </div>
      <div>
        <div className="px-2 mt-1">
          <label className={styles.timeZoneLabel}> Current Time Zone: {timeZoneValue} </label>
        </div>
        <div>
          <SearchBar placeholder="Search calendar" onChange={() => null} />
        </div>
        <div>
          <FiltersButton className={styles.filterBtn} />
        </div>
      </div>
    </div>
  );
}
