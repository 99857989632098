import React from 'react';
import notes from '../assets/icons/notes.png';
import noNotes from '../assets/icons/notes_disabled.png';

interface INotesIndicator {
  hasNotes: boolean;
}
const NotesIndicator = ({ hasNotes }: INotesIndicator) =>
  hasNotes ? <img src={notes} alt="notes" /> : <img src={noNotes} alt="no notes" />;

export default NotesIndicator;
