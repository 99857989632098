import { templateCarePlanStartDate } from '../../components/Content/components/TemplateDetails/components/CarePlanDetails/util';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { TemplateCarePlanAction, TemplateCarePlanActionTypes } from './templateCarePlanActionType';
import { ITemplateCarePlan } from 'types/ApiModels/Templates/CarePlan';
import { CarePlanActionEventType } from 'types/ApiModels/CarePlan/EventType';
import { IQuestionnaire } from 'types/ApiModels/Templates/Questionnaire';
import { TimePeriodOptions } from 'components/TimePeriodSelect';

export interface ITemplateCarePlanState {
  carePlanState: ITemplateCarePlan;
  previousPatientActions: IPatientCarePlanAction[];
  selectedDate: Date;
  patientActions: IPatientCarePlanAction[];
  timelineActions: IPatientTimelineAction[];
  selectedAction: IPatientCarePlanAction;
  periodSelected: TimePeriodOptions;
  showCarePlanModal: boolean;
  carePlanModalEventType: CarePlanActionEventType;
  hasUnsavedChanges: boolean;
  userIsStaff: boolean;
  questionnaireList: IQuestionnaire[];
}
export const templateCarePlanInitialState: ITemplateCarePlanState = {
  carePlanState: {
    actions: [],
  },
  previousPatientActions: [],
  selectedDate: templateCarePlanStartDate,
  patientActions: [],
  timelineActions: [],
  selectedAction: null,
  periodSelected: TimePeriodOptions.WEEK,
  showCarePlanModal: false,
  carePlanModalEventType: null,
  hasUnsavedChanges: false,
  userIsStaff: false,
  questionnaireList: [],
};

const patientCarePlanReducer = (
  state = templateCarePlanInitialState,
  action: TemplateCarePlanAction
) => {
  switch (action.type) {
    case TemplateCarePlanActionTypes.RESET_STATE: {
      return templateCarePlanInitialState;
    }

    case TemplateCarePlanActionTypes.SET_CAREPLAN_STATE: {
      const stateCopy = { ...state };
      stateCopy.carePlanState = action.payload.carePlanState;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_PREVIOUS_PATIENT_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.previousPatientActions = [...stateCopy.patientActions];
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_SELECTED_DATE: {
      const stateCopy = { ...state };
      stateCopy.selectedDate = action.payload.selectedDate;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_PATIENT_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.patientActions = action.payload.patientActions;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_TIMELINE_ACTIONS: {
      const stateCopy = { ...state };
      stateCopy.timelineActions = action.payload.timelineActions;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_PERIOD_SELECTED: {
      const stateCopy = { ...state };
      stateCopy.periodSelected = action.payload.timePeriod;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_SHOW_CAREPLAN_MODAL: {
      const stateCopy = { ...state };
      stateCopy.showCarePlanModal = action.payload.showCarePlanModal;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_SELECTED_ACTION: {
      const stateCopy = { ...state };
      stateCopy.selectedAction = action.payload.selectedAction;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_CAREPLAN_MODAL_EVENT_TYPE: {
      const stateCopy = { ...state };
      stateCopy.carePlanModalEventType = action.payload.carePlanModalEventType;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_HAS_UNSAVED_CHANGES: {
      const stateCopy = { ...state };
      stateCopy.hasUnsavedChanges = action.payload.hasUnsavedChanges;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_USER_IS_STAFF: {
      const stateCopy = { ...state };
      stateCopy.userIsStaff = action.payload.userIsStaff;
      return stateCopy;
    }

    case TemplateCarePlanActionTypes.SET_QUESTIONNAIRE_LIST: {
      const stateCopy = { ...state };
      stateCopy.questionnaireList = action.payload.questionnaireList;
      return stateCopy;
    }

    default:
      return state;
  }
};

export default patientCarePlanReducer;
