import React from 'react';
import Select from '../../../../../../../../../../components/form/Select';
import styles from './styles.module.css';
const Insurer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Insurer</div>
      <Select containerClassName={styles.select} options={['Medicaid']} />
    </div>
  );
};

export default Insurer;
