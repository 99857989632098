import React from 'react';
import CalendarControl from './CalendarControl';
import FiltersButton from 'components/FiltersButton';
import { Col, Container, Row } from 'react-bootstrap';
import TemplateTimeline from './Timeline';

const Calendar = () => {
  return (
    <div className="dashboard-detail pb-0">
      <div className="dashboard-item-header d-flex- justify-content-between align-items-center">
        <div className="font-size-big">Care plan timeline</div>
        <FiltersButton />
      </div>
      <div>
        <Container className="px-0">
          <Row>
            <Col className="px-0" lg={2} style={{ minWidth: '260px' }}>
              <CalendarControl />
            </Col>
            <Col className="px-0">
              <TemplateTimeline />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Calendar;
