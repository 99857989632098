import addBubble from 'assets/icons/add_bubble.png';
import deleteBubble from 'assets/icons/delete_bubble.png';
import React from 'react';

const createIcon = (src: string, props: React.ImgHTMLAttributes<HTMLImageElement> = null) => (
  <img className="cursor-pointer mr-2" src={src} alt="" {...props} />
);

export const DeleteIcon = (props: React.ImgHTMLAttributes<HTMLImageElement>) =>
  createIcon(deleteBubble, props);
export const AddIcon = (props: React.ImgHTMLAttributes<HTMLImageElement>) =>
  createIcon(addBubble, props);
