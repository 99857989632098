import GenericTable from 'components/GenericTable';
import GenericColumn from 'types/Shared/GenericColumn';
import { formatVytracDate } from 'util/dateUtils';
import styles from './styles.module.css';
const AuditTable = () => {
  const columns: GenericColumn[] = [
    {
      title: 'Date and Time',
      key: 'date',
      style: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: ({ date }) => formatVytracDate(date),
    },
    {
      title: 'User',
      key: 'user',
      render: ({ user }) => <span className={styles['user']}>{user}</span>,
      style: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
    },
    {
      title: 'Record Type',
      key: 'recordType',
      style: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
    },
    {
      title: 'Record',
      key: 'record',
      style: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
    },
    {
      title: 'Description',
      key: 'description',
    },
  ];

  const data = [
    {
      date: new Date(),
      user: 'Thomas Wayne',
      recordType: 'Roles & Permissions',
      record: 'Patient name',
      description: 'Patient name changed to John Doe',
    },
    {
      date: new Date(),
      user: 'Thomas Wayne',
      recordType: 'Roles & Permissions',
      record: 'Patient name',
      description: 'Patient name changed to John Doe',
    },
  ];

  return (
    <GenericTable
      columns={columns}
      data={data}
      rowClassName={styles['row']}
      headerRowClassName={styles['header']}
    />
  );
};
export default AuditTable;
