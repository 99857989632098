import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import styles from './styles.module.css';

interface IHourFooter {
  label: string;
  hours: number;
  onHoursChange?: (hours: number) => void;
  onMinutesChange?: (minutes: number) => void;
  minutes: number;
  amPm: string;
  setAmPm?: Dispatch<SetStateAction<string>>;
  containerStyle?: CSSProperties;
  disabled?: boolean;
}

const HourFooter = ({
  label,
  hours,
  onHoursChange,
  minutes,
  onMinutesChange,
  amPm,
  setAmPm,
  containerStyle,
  disabled,
}: IHourFooter) => {
  const hoursHandler = (value: number) => {
    const maxValue = amPm === 'PM' ? 12 : 11;
    if (value >= 0 && value <= maxValue) onHoursChange(value);
  };

  const minutesHandler = (value) => {
    if (value >= 0 && value <= 59) onMinutesChange(value);
  };

  return (
    <div className={styles.hourContainer} style={containerStyle}>
      <div className={styles.textCenter}>{label}</div>
      <div className="d-flex flex-row ">
        <input
          type="number"
          min={0}
          max={12}
          onChange={(e) => hoursHandler(Number(e.target.value))}
          value={hours % 12}
          className={styles.numberInput}
          placeholder="Hr"
          disabled={disabled}
        />
        <input
          type="number"
          min={0}
          max={59}
          onChange={(e) => minutesHandler(Number(e.target.value))}
          value={minutes}
          className={styles.numberInput}
          placeholder="Min"
          disabled={disabled}
        />
        <span
          onClick={() => (disabled ? null : setAmPm('AM'))}
          className={amPm === 'AM' ? styles.selectedSelector : styles.selector}
        >
          AM
        </span>
        <span
          onClick={() => (disabled ? null : setAmPm('PM'))}
          className={amPm === 'PM' ? styles.selectedSelector : styles.selector}
        >
          PM
        </span>
      </div>
    </div>
  );
};
export default HourFooter;
