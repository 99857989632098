import { FC, MouseEventHandler } from 'react';

interface GenericButtonProps {
  onClick?: MouseEventHandler;
  className?: string;
  icon: string;
  alt: string;
}

const GenericButton: FC<GenericButtonProps> = ({
  onClick,
  className,
  icon,
  alt,
}: GenericButtonProps) => (
  <div onClick={onClick} className={`${className || ''} cursor-pointer`}>
    <img src={icon} alt={alt} />
  </div>
);

export default GenericButton;
