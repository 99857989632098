import EmptyList from 'components/EmptyList';
import styles from './styles.module.css';
import { ReactComponent as PatientIcon } from 'assets/icons/adherence_empty.svg';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';

interface QuestionnaireTemplatesListProps {
  setPatientQuestionnaire: (template: IPatientQuestionnaire) => void;
  selectedPatientQuestionnaire: IPatientQuestionnaire;
  patientQuestionnaires: IPatientQuestionnaire[];
}
const PatientQuestionnairesList = ({
  setPatientQuestionnaire,
  selectedPatientQuestionnaire,
  patientQuestionnaires,
}: QuestionnaireTemplatesListProps) => {
  return (
    <div className="w-100">
      {patientQuestionnaires.length > 0 ? (
        <div className={`${styles['list-container']} w-100`}>
          {patientQuestionnaires.map((patientQuestionnaire) => (
            <div
              key={patientQuestionnaire.id}
              className={`${styles.item} ${
                patientQuestionnaire.id === selectedPatientQuestionnaire?.id ? styles.active : ''
              }`}
              onClick={() => setPatientQuestionnaire(patientQuestionnaire)}
            >
              {patientQuestionnaire.name}
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <EmptyList Icon={PatientIcon} title="Patient has no questionnaires" fill="#E6F5FA" />
        </div>
      )}
    </div>
  );
};
export default PatientQuestionnairesList;
