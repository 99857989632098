import { Field } from 'formik';
import { FC } from 'react';

interface TextAreaFormikProps {
  name: string;
  className?: string;
  placeholder?: string;
}

const TextAreaFormik: FC<TextAreaFormikProps> = ({
  name,
  className,
  placeholder,
}: TextAreaFormikProps) => {
  return (
    <Field
      name={name}
      className={`vytrac-input rounded p-2 ${className ? className : ''}`}
      placeholder={placeholder}
      as="textarea"
    />
  );
};
export default TextAreaFormik;
