import moment, { Moment } from 'moment';
import colors from 'styles/colors';
import Adherence from 'types/ApiModels/Patients/Adherence';

const carePlanDateToMoment = (carePlanDate: string): Moment => {
  const date = moment(carePlanDate, 'YYYY-MM-DD');
  return date;
};

// calculates day difference between care plan dates
// adherence.care_plan_to always returns the actual date
export const getCarePlanDayDiff = (adherence: Adherence): number => {
  const { care_plan_from, care_plan_to } = adherence;
  let fromDate: Moment, toDate: Moment;

  try {
    fromDate = carePlanDateToMoment(care_plan_from);
    toDate = carePlanDateToMoment(care_plan_to);

    if (!fromDate.isValid() || !toDate.isValid()) {
      // throw new Error('Invalid care plan date');
      return;
    }

    const dayDiff = toDate.diff(fromDate, 'days');
    return dayDiff;
  } catch (error) {
    // console.error('Invalid care plan date');
    return 0;
  }
};

// generates Date array between care plan dates
const getDatesArray = (carePlanFromDate: string, dayDiff: number): string[] => {
  const datesArray: string[] = [carePlanFromDate];
  for (let i = 1; i <= dayDiff; i++) {
    const currentDate = carePlanDateToMoment(carePlanFromDate).add(i, 'day').format('YYYY-MM-DD');
    datesArray.push(currentDate);
  }

  return datesArray;
};

export const convertAdherenceToBooleanArray = (adherence: Adherence): boolean[] => {
  const dayDiff = getCarePlanDayDiff(adherence);
  if (dayDiff === 0) return [];

  const datesArray = getDatesArray(adherence.care_plan_from, dayDiff);
  const booleanArray = Array.from(datesArray, (date) => adherence.adherence_days.includes(date));
  return booleanArray;
};

export const getAdherenceColor = (adherence: Adherence): string => {
  const { adherence_days } = adherence;
  const dayDiff = getCarePlanDayDiff(adherence);
  const numberOfAdherenceDays = adherence_days.length;
  const percentage = (numberOfAdherenceDays / dayDiff) * 100;
  if (percentage > 53) {
    return colors.regularBlack;
  } else if (percentage > 27) {
    return colors.mediumRiskAlerts;
  } else {
    return colors.highRiskAlerts;
  }
};
