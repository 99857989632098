import Button from 'components/Button';
import { FC } from 'react';

interface CareTeamCreateEditFooterProps {
  isNew: boolean;
  onCancel: () => void;
  handleRemoveTeam: () => void;
  handleSubmit: () => void;
}

const CareTeamCreateEditFooter: FC<CareTeamCreateEditFooterProps> = ({
  isNew,
  handleRemoveTeam,
  handleSubmit,
  onCancel,
}: CareTeamCreateEditFooterProps) => {
  return (
    <div className="d-flex justify-content-between bg-white p-2">
      <div className="d-flex gap">
        <Button label="cancel" variant="dull" onClick={onCancel} />
        {!isNew ? <Button label="remove team" variant="delete" onClick={handleRemoveTeam} /> : null}
      </div>
      <Button variant="confirm" label="save" onClick={handleSubmit} />
    </div>
  );
};
export default CareTeamCreateEditFooter;
