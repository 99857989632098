import VytracSpinner from 'components/vytrac-spinner';
import { useEffect, useRef } from 'react';
import ActionButtons from 'screens/ProviderDashboard/ActionButtons';
import Populations from 'screens/ProviderDashboard/Populations';
import { useDashboardContext } from 'screens/ProviderDashboard/store/DashboardContext';
import AdherenceStatus from './AdherenceStatus';
import ComplaintAndNotes from './ComplaintAndNotes';
import GraphAlert from './GraphAlert';
import styles from './styles.module.css';

interface RedAlertPopupProps {
  onClickOutSide?: () => void;
}

const RedAlertPopup = ({ onClickOutSide }: RedAlertPopupProps) => {
  const {
    state: { selectedRedAlert },
  } = useDashboardContext();

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (ref && ref.current && !ref.current.contains(event.target)) {
        onClickOutSide?.();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      ref.current = null;
    };
  }, [ref, onClickOutSide]);
  return (
    <>
      {selectedRedAlert ? (
        <div className={styles['popup']} ref={ref}>
          <Populations
            birth_date={selectedRedAlert.birth_date}
            phone_number={selectedRedAlert.phone_number}
            email={selectedRedAlert.email}
            populations={selectedRedAlert.populations}
            user_id={selectedRedAlert.id}
          />
          <ComplaintAndNotes alerts={selectedRedAlert.alerts} />
          {selectedRedAlert.adherence.care_plan_from && selectedRedAlert.adherence.care_plan_to && (
            <AdherenceStatus adherence={selectedRedAlert.adherence} />
          )}
          <GraphAlert alerts={selectedRedAlert.alerts} patientId={selectedRedAlert.id} />
          <ActionButtons
            wrapperClassName={styles['actions']}
            alertId={selectedRedAlert?.id}
            vytalsRequest
            provideQuestionnaire
            callPatient
            sendAutomatedChat
            requestTelehealthAppointment
            assign
            complete
          />
          {/* <LastAction
        type={patient.lastAction}
        status={patient.lastActionStatus}
        actions={patient.lastActionActions}
        wrapperClassName={styles['last-action']}
      /> */}
        </div>
      ) : (
        <div className={`${styles['popup']} justify-content-center d-flex`} ref={ref}>
          <VytracSpinner />
        </div>
      )}
    </>
  );
};
export default RedAlertPopup;
