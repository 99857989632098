import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { Field, useFormikContext } from 'formik';
import { FC } from 'react';
import Select, { MenuPlacement } from 'react-select';

interface SelectFormikProps {
  name: string;
  options: { value: number | string; label: string }[];
  defaultValue?: { value: number | string; label: string };
  placeholder?: string;
  styles?: React.CSSProperties;
  stylesContainer?: React.CSSProperties;
  menuPlacement?: MenuPlacement;
  customOnChange?: (value) => void;
  maxMenuHeight?: number;
  value?: any;
  className?: string;
}

const SelectFormik: FC<SelectFormikProps> = ({
  options,
  name,
  defaultValue,
  placeholder,
  styles,
  stylesContainer,
  menuPlacement = 'auto',
  customOnChange,
  maxMenuHeight,
  value,
  className,
}: SelectFormikProps) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field name={name}>
      {() => (
        <Select
          name={name}
          value={value}
          options={options}
          defaultValue={defaultValue}
          onChange={customOnChange ? customOnChange : (option) => setFieldValue(name, option.value)}
          placeholder={placeholder}
          menuPlacement={menuPlacement}
          className={className}
          styles={{
            ...vytracSelectStyle,
            control: (base, state) => ({ ...vytracSelectStyle.control(base, state), ...styles }),
            container: (base) => ({ ...base, ...stylesContainer }),
          }}
          maxMenuHeight={maxMenuHeight}
        />
      )}
    </Field>
  );
};
export default SelectFormik;
