import React from 'react';

import styles from './styles.module.css';

const RegisteredCare = ({categories}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Registered care</div>
      <hr className={styles.horizontalLine} />
      <div className={styles.categories}>
        {categories.map((category, index) => (
          <>
            {index !== 0 && <>•</>}
            <span>{category}</span>
          </>
        ))}
      </div>
    </div>
  );
};

export default RegisteredCare;
