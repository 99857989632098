import { activityTimelineMaxColumnsCount } from 'components/ActivityTimeline/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ActivityColumn, ActivityHourColumns } from 'types/Patients/PatientTimeline';
import { convertDatepickToDate } from 'util/dateUtils';
import styles from '../ActivityItems.module.css';
import { useActivityTimelineContext } from '../../store';
import ActivityTimelineColumnHeader from '../ActivityColumnHeader';
import AllDayActivityItems from './AllDayActivityItems';
import InstantActivityItems from './InstantActivityItems';
import { getDayColumns, getFractionsByActionId, initialIndexes } from './utils/dayViewUtils';
import { Droppable } from 'react-beautiful-dnd';

interface IColumnContent {
  idx: number;
  c: ActivityColumn;
}

const DayView = () => {
  const { activities, currentSelectedDate, draggable } = useActivityTimelineContext();
  const [currentIndexes, setCurrentIndexes] = useState(initialIndexes);
  const stringDateRef = useRef<string>(convertDatepickToDate(currentSelectedDate).toISOString());

  useEffect(() => {
    stringDateRef.current = convertDatepickToDate(currentSelectedDate).toISOString();
  }, [currentSelectedDate]);

  const hourColumns: ActivityHourColumns = useMemo(
    () => getDayColumns(activities, convertDatepickToDate(currentSelectedDate)),
    [activities, currentSelectedDate]
  );

  const currentViewData = useMemo(() => {
    const SLICE_EXCLUDE_PARAMETER_OFFSET = 1;
    const indexes = [
      currentIndexes.startIndex,
      currentIndexes.endIndex + SLICE_EXCLUDE_PARAMETER_OFFSET,
    ];
    return hourColumns.hourActivities.slice(...indexes);
  }, [currentIndexes.endIndex, currentIndexes.startIndex, hourColumns]);

  const handleNext = () => {
    setCurrentIndexes(({ startIndex, endIndex }) =>
      endIndex + 1 >= hourColumns.hourActivities.length
        ? { startIndex, endIndex }
        : { startIndex: startIndex + 1, endIndex: endIndex + 1 }
    );
  };

  const handlePrevious = () => {
    setCurrentIndexes(({ startIndex, endIndex }) =>
      startIndex - 1 < 0
        ? { startIndex, endIndex }
        : { startIndex: startIndex - 1, endIndex: endIndex - 1 }
    );
  };

  const AllDayActivityItemsMemo = useMemo<JSX.Element[]>(() => {
    const columnsRange = [...Array(activityTimelineMaxColumnsCount).keys()];
    const fractionsByActionId = getFractionsByActionId(columnsRange, hourColumns);
    return columnsRange.map((val, idx, arr) => (
      <AllDayActivityItems
        allDayItems={hourColumns.allDayItems}
        fractions={fractionsByActionId}
        currentIdx={idx}
        isFirst={idx === 0}
        isLast={idx === arr.length - 1}
        key={`all-day-activity-items-${idx}`}
      />
    ));
  }, [hourColumns, hourColumns.allDayItems]);

  const ColumnContent = ({ c, idx }: IColumnContent) => {
    return (
      <div style={{ padding: '40px 0px 10px 0px' }}>
        {AllDayActivityItemsMemo[idx]}
        <InstantActivityItems items={c.items} />
      </div>
    );
  };

  return (
    <>
      {currentViewData.map((c, idx, arr) => {
        const bodyClass = idx === 0 ? `pl-1` : idx === arr.length - 1 ? `pr-1 ` : '';
        return (
          <div
            className={`d-flex flex-column ${styles['activity-column']}`}
            key={`header-${c.headerLabel}`}
          >
            <ActivityTimelineColumnHeader
              arrowLeft={idx === 0}
              arrowRight={idx === arr.length - 1}
              label={c.headerLabel}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              activitiesCount={c.items?.length}
              columnCount={arr.length}
            />
            {draggable ? (
              <Droppable
                droppableId={`dayView__${idx}__${stringDateRef.current}__${c.headerLabel}`}
              >
                {(provided, snapshot) => (
                  <div
                    className={`d-flex flex-column mt-1 gap-sm ${bodyClass} flex-grow-1`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={snapshot.isDraggingOver ? { backgroundColor: '#F7F7FA' } : {}}
                  >
                    <ColumnContent c={c} idx={idx} />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : (
              <div className={`d-flex flex-column mt-1 gap-sm ${bodyClass} `}>
                <ColumnContent c={c} idx={idx} />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
export default DayView;
