import Card from 'components/Card';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import { useEffect, useMemo } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useAdministrationContext } from 'screens/Administration/store';
import { useAdminEmailSettings } from 'screens/Administration/store/hooks';
import { EmailSettingType, EmailShortCode } from 'types/ApiModels/Administration';
import EmailSettingItem from './EmailSettingItem';
import EmailSettingsActions from './EmailSettingsActions';
import EmailSettingsHeader from './EmailSettingsHeader';

const mapShortCodeToTagItem = (onClick) => (scName: string) => ({
  text: scName,
  className: 'cursor-pointer',
  onClick,
});

const EmailSettings = () => {
  const [{ shortCodes, types, list: emailSettings, currentEmailSetting, insertShortCodeToEditor }] =
    useAdminEmailSettings();
  const { setActions } = useAdministrationContext();

  useEffect(() => {
    setActions(EmailSettingsActions);
  }, []);

  const allowedShortCodes = useMemo(() => {
    if (currentEmailSetting?.type === EmailSettingType.custom) {
      return shortCodes ?? [];
    } else {
      return shortCodes?.filter((sc) => sc.allowed_types.includes(currentEmailSetting?.type)) ?? [];
    }
  }, [shortCodes, currentEmailSetting?.type]);

  const shortCodesTags = useMemo(
    () =>
      allowedShortCodes?.map((sc) =>
        mapShortCodeToTagItem(() => {
          insertShortCodeToEditor(sc);
        })(sc.name)
      ) ?? [],
    [allowedShortCodes, insertShortCodeToEditor]
  );

  return (
    <div className="container-with-padding d-flex gap flex-column">
      <Row>
        <Col md={8}>
          <Card headers={['Emails']} className="card-bg-border" bodyClassName="p-0">
            <EmailSettingsHeader />
            {emailSettings?.length ? (
              <Accordion activeKey={currentEmailSetting?.id?.toString()}>
                {emailSettings.map((es) => (
                  <EmailSettingItem
                    emailSetting={es}
                    key={es.id}
                    allowedShortCodes={allowedShortCodes}
                    types={types}
                  />
                ))}
              </Accordion>
            ) : (
              <div className="text-center font-italic p-3">
                No email settings set yet, start adding with the button in the top right corner
              </div>
            )}
          </Card>
        </Col>
        <Col>
          <Card headers={['Enter shorcode']} className="card-bg-border">
            {currentEmailSetting ? (
              shortCodesTags.length ? (
                <TagList tagList={shortCodesTags} className="flex-wrap gap-sm" />
              ) : (
                <div className="text-center font-italic">
                  No shortcodes configured for this type yet.
                </div>
              )
            ) : (
              <div className="text-center font-italic">
                Create or edit an email template to see available shortcodes.
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmailSettings;
