import moment, { Moment } from 'moment';
import {
  IPatientActionRecurrence,
  IPatientCarePlanAction,
} from 'types/ApiModels/Patients/CarePlan';
import { ITimesHours } from 'types/Shared/ITimesHours';
import { isTimeGreater } from 'util/dateUtils';

const isException = (nextDate: Moment, exceptions: string[]): boolean => {
  let isException = false;
  for (const exception in exceptions) {
    const exceptionDate = moment(exception);
    if (nextDate.isSame(exceptionDate)) isException = true;
    break;
  }
  return isException;
};

const getNextMonthDate = (currentDate: Moment, recurrence: IPatientActionRecurrence): Moment => {
  const sortedMonthDays = [...recurrence.repeats_param]
    .map((monthDay) => Number(monthDay))
    .sort((a, b) => a - b);

  const nextDate = currentDate.clone();
  do {
    const nextMonthDay = sortedMonthDays.find((weekDay) => Number(weekDay) > nextDate.date());
    if (nextMonthDay) {
      nextDate.date(nextMonthDay);
    } else {
      // if no day of month was found that means you have to select the first selected day of the month
      nextDate.date(sortedMonthDays[0]).add(1, 'month');
    }
  } while (isException(nextDate, recurrence.exceptions as string[]));

  return nextDate;
};

const getNextWeekDate = (currentDate: Moment, recurrence: IPatientActionRecurrence): Moment => {
  const sortedWeekDays = [...recurrence.repeats_param]
    .map((weekDay) => Number(weekDay))
    .sort((a, b) => a - b);

  const nextDate = currentDate.clone();
  do {
    const nextWeekDay = sortedWeekDays.find((weekDay) => weekDay > nextDate.day());
    if (nextWeekDay) {
      nextDate.day(nextWeekDay);
    } else {
      // if no weekday was found that means you have to select the first selected day of the next week
      nextDate.day(sortedWeekDays[0]).add(1, 'week');
    }
  } while (isException(nextDate, recurrence.exceptions as string[]));

  return nextDate;
};

/**
 * Calculates the next end of the adherence block based on the current selected date
 * @param currentSelectedDate selected date on timeline
 * @param carePlanStartDate start of care plan
 * @returns Next adherence block start date
 */
export const getNextBlockStart = (
  currentSelectedDate: Moment,
  carePlanStartDate: Moment
): Moment => {
  const current = moment(currentSelectedDate);
  const firstBlockStart = moment(carePlanStartDate);

  const currentBlockStart = firstBlockStart.clone();
  while (currentBlockStart.isBefore(current)) {
    currentBlockStart.add(30, 'days');
  }
  return currentBlockStart;
};

/**
 *
 * @param currentDate current date on recurrence generation
 * @param action
 * @param blockStartDate next adherence block start date
 * @returns when to generate the next recurrent action
 */
export const getNextRecurrentDate = (
  currentDate: Moment,
  action: IPatientCarePlanAction,
  blockStartDate: Moment
): Moment => {
  if (action.recurrence.multiple_times_a_day) {
    const sortedTimes = action.recurrence.multiple_times_hours.sort(
      (a: ITimesHours, b: ITimesHours) => a.hour * 60 + a.minute - b.hour * 60 - b.minute
    );

    // Find next time in day
    for (const time of sortedTimes) {
      const currentTime: ITimesHours = { hour: currentDate.hours(), minute: currentDate.minutes() };
      if (isTimeGreater(time, currentTime)) {
        return currentDate.hours(time.hour).minutes(time.minute);
      }
    }

    // Set first time of day
    const firstTime = sortedTimes[0];
    currentDate.hours(firstTime.hour).minutes(firstTime.minute);
  }

  switch (action.recurrence.repeats_type) {
    case 'every_day': {
      return currentDate.add(1, 'days');
    }
    case 'time_period': {
      return currentDate.add(Number(action.recurrence.repeats_param), 'days');
    }
    case 'every_week': {
      return getNextWeekDate(currentDate, action.recurrence);
    }
    case 'every_month': {
      return getNextMonthDate(currentDate, action.recurrence);
    }
    case 'start_of_adherence_block': {
      return getNextBlockStart(currentDate, blockStartDate);
    }
    case 'before_start_of_adherence_block': {
      return getNextBlockStart(currentDate, blockStartDate).add(
        Number(action.recurrence.repeats_param),
        'days'
      );
    }
  }
};
