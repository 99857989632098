import React from 'react';
import styles from './styles.module.css';
const ActionsButton = ({onClick, label}) => {
  return (
    <button className={styles.export} onClick={onClick}>
      {label}
    </button>
  );
};

export default ActionsButton;
