import moment from 'moment';
import React from 'react';
import {useEffect} from 'react';
import Details from './components/Details';
import Footer from './components/Footer';
import Header from './components/Header';
import Status from './components/Status';
import styles from './styles.module.css';

const ReplyDetail = ({data, opened, closePanel}) => {
  const ref = React.useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closePanel();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className={`${styles.menu}`}
      style={{transform: opened && 'translateX(0)'}}
    >
      <div>
        <Header
          title={data.questionnaireName}
          date={moment(data.date).format('MM.DD.YYYY • h A')}
          criticalCount={data.critical}
          totalCount={data.answers.length}
          onClick={closePanel}
        />
      </div>
      <div>
        <Status answers={data.answers} />
      </div>
      <div className={styles.detail}>
        <Details questions={data.questions} answers={data.answers} />
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default ReplyDetail;
