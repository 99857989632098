import { ChatIcon, StarIcon, VideoCallIcon, VoiceCallIcon } from 'components/communication-icons';
import React from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { partialUpdateSinglePatient } from 'services/patientService';
import PatientGetSingle, {
  PreferredContactMethod,
} from 'types/ApiModels/Patients/PatientGetSingle';
import styles from './styles.module.css';

interface IPatientCommunications {
  currentPatient: PatientGetSingle;
}

const PatientCommunications = ({ currentPatient }: IPatientCommunications) => {
  const { setSelectedPatient } = usePatientFormikContext();
  const { preferred_contact_method, has_mobile_app } = currentPatient;

  const updatePreferredMethod = async (method: PreferredContactMethod) => {
    const updatedPatient = await partialUpdateSinglePatient(currentPatient.id, {
      preferred_contact_method: method,
    });
    setSelectedPatient(updatedPatient);
  };

  return (
    <div className={styles.patientCommunicationsContainer}>
      <div className={styles.topIconContainer}>
        <button className={styles.comsButton} disabled={!has_mobile_app}>
          <VideoCallIcon
            width="21"
            height="14"
            isActive={has_mobile_app}
            style={{ cursor: 'pointer' }}
          />
        </button>
        <button className={styles.comsButton} disabled={!has_mobile_app}>
          <VoiceCallIcon
            width="14"
            height="14"
            isActive={has_mobile_app}
            style={{ cursor: 'pointer' }}
          />
        </button>
        <button className={styles.comsButton} disabled={!has_mobile_app}>
          <ChatIcon
            width="17"
            height="17"
            isActive={has_mobile_app}
            style={{ cursor: 'pointer' }}
          />
        </button>
      </div>
      <hr className="my-0" />
      <div className={styles.bottomIconContainer}>
        <div>
          <StarIcon
            width="16"
            height="15"
            isActive={preferred_contact_method === 'video'}
            onClick={() => updatePreferredMethod('video')}
          />
        </div>
        <div>
          <StarIcon
            width="16"
            height="15"
            isActive={preferred_contact_method === 'call'}
            onClick={() => updatePreferredMethod('call')}
          />
        </div>
        <div>
          <StarIcon
            width="16"
            height="15"
            isActive={preferred_contact_method === 'message'}
            onClick={() => updatePreferredMethod('message')}
          />
        </div>
      </div>
    </div>
  );
};
export default PatientCommunications;
