import VytracModal from 'components/Modal';
import { useCallback } from 'react';
import { setOpenAddFormModal, updateCurrentForm } from 'screens/Administration/store/Forms';
import {
  addNewForm,
  clearDirtyForm,
  setCurrentForm,
} from 'screens/Administration/store/Forms/formActionCreators';
import { useAdminPopulations } from 'screens/Administration/store/hooks';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { createAdminForm, updateAdminForm } from 'services/administrationService';
import { AdminForm } from 'types/Administration/AdminForms/AdminForm';
import { IAPIAdminForm } from 'types/ApiModels/Administration/Form';
import AddFormBody from './AddFormBody';

const AddFormModal = ({}) => {
  const [{ list: populations }] = useAdminPopulations();
  const [{ openAddFormModal, currentForm, dirtyForms }, dispatch] = useAdminForms();
  const handleClose = useCallback(
    (id: number) => {
      dispatch(setOpenAddFormModal(false));
      dispatch(setCurrentForm(null));
      if (id && id > 0) {
        dispatch(clearDirtyForm(id));
      }
    },
    [dispatch]
  );

  const handleConfirm = useCallback(
    async (data: AdminForm) => {
      const apiForm: IAPIAdminForm = {
        ...data,
        population: data.population.map((pop) => pop.id),
        format: data.url ? 'external' : 'internal',
      };
      if (data.id > 0) {
        if (dirtyForms[data.id]) {
          const updatedForm = await updateAdminForm(apiForm, apiForm.id);
          const parsedForm = {
            ...updatedForm,
            population: populations.filter((pop) => updatedForm.population.includes(pop.id)),
          };
          dispatch(updateCurrentForm(parsedForm, data.id));
        } else {
          dispatch(setOpenAddFormModal(false));
        }
        dispatch(clearDirtyForm(data.id));
      } else {
        const newForm = await createAdminForm(apiForm);
        const parsedForm: AdminForm = {
          ...newForm,
          population: populations.filter((pop) => newForm.population.includes(pop.id)),
        };
        dispatch(addNewForm(parsedForm));
      }
      handleClose(data.id);
    },
    [populations, dispatch, handleClose, dirtyForms]
  );
  return (
    <VytracModal
      backdrop={false}
      centered
      title="Add new form"
      show={openAddFormModal}
      onClose={() => handleClose(currentForm?.id)}
      body={
        <AddFormBody
          onCancel={() => handleClose(currentForm?.id)}
          onConfirm={handleConfirm}
          currentForm={currentForm}
        />
      }
    />
  );
};
export default AddFormModal;
