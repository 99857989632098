import { ReactNode, useReducer } from 'react';
import AppointmentsContext from './AppointmentsContext';
import appointmentsReducer, { appointmentsInitialValue } from './appointmentsReducer';

interface AppointmentsCtxProviderProps {
  children: ReactNode;
}

const AppointmentsCtxProvider = ({ children }: AppointmentsCtxProviderProps) => {
  const [appointmentsState, dispatch] = useReducer(appointmentsReducer, appointmentsInitialValue);
  return (
    <AppointmentsContext.Provider value={{ dispatch, appointmentsState }}>
      {children}
    </AppointmentsContext.Provider>
  );
};
export default AppointmentsCtxProvider;
