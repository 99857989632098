import Button from 'components/Button';

const ModalFooter = ({ onCancel, onConfirm }) => {
  return (
    <div className="d-flex w-100 justify-content-between">
      <Button label="Cancel" variant="cancel" onClick={() => onCancel()} />
      <Button label="Confirm" variant="confirm" onClick={() => onConfirm()} />
    </div>
  );
};
export default ModalFooter;
