import UserBubble from 'components/UserBubble';
import ActivityPointsIcon from 'components/ActivityPointsIcon';
import ActivityPointsActions from 'types/ActivityPoints';
import styles from './styles.module.css';

interface PointsTableProps {
  actions: ActivityPointsActions[];
}
export const PointsTable = ({ actions }: PointsTableProps) => {
  return (
    <div className="d-flex flex-column">
      <div className={`${styles.headers} ${styles.row}`}>
        <div className="w-50">Action</div>
        <div>Patient</div>
        <div>Patient ID</div>
        <div>Reward</div>
        <div>Multiplier</div>
      </div>
      <div className={styles.rowContainer}>
        {actions.map((action, index) => (
          <div key={index} className={styles.row}>
            <div>{action.action}</div>
            <div className={`d-flex ${styles.imageName}`}>
              <UserBubble imgClassNameList={['ml-0']} user={action.patient.user} />
              {action.patient.user.full_name}
            </div>
            <div>#{action.patientId}</div>
            <div className="d-flex">
              <ActivityPointsIcon width={12} height={10} fill={'#8FAAC3'} className="mr-1" />
              <div className={styles.reward}>{action.reward}</div>
            </div>
            <div>x{action.multiplier}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
