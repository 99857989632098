import {
  EmailShortCode,
  EmailSetting,
  EmailTypeTuple,
  EmailSettingType,
} from 'types/ApiModels/Administration';
import { EmailSettingAction, EmailSettingActionTypes } from './emailSettingsActionTypes';

export const setEmailSettingsShortcodes = (shortCodes: EmailShortCode[]): EmailSettingAction => ({
  type: EmailSettingActionTypes.SET_SHORT_CODES,
  payload: { shortCodes },
});

export const setEmailSettings = (emailSettings: EmailSetting[]): EmailSettingAction => ({
  type: EmailSettingActionTypes.SET_EMAIL_SETTINGS,
  payload: { emailSettings },
});

export const setEmailSettingTypes = (types: EmailTypeTuple[]): EmailSettingAction => ({
  type: EmailSettingActionTypes.SET_EMAIL_TYPES,
  payload: { types },
});

export const setCurrentEmailSetting = (emailSetting: EmailSetting): EmailSettingAction => ({
  type: EmailSettingActionTypes.SET_CURRENT_EMAIL_SETTING,
  payload: { emailSetting },
});

export const updateCurrentEmailSettingType = (type: EmailSettingType): EmailSettingAction => ({
  type: EmailSettingActionTypes.UPDATE_CURRENT_EMAIL_SETTING_TYPE,
  payload: { type },
});

export const setInsertShortCodeToEditor = (
  insertShortCode: (shortCode: EmailShortCode) => void
): EmailSettingAction => ({
  type: EmailSettingActionTypes.SET_INSERT_SHORT_CODE_TO_EDITOR,
  payload: { insertShortCode },
});

export const addBlankEmailSetting = (): EmailSettingAction => ({
  type: EmailSettingActionTypes.ADD_BLANK_EMAIL_SETTING,
});

export const updateEmailSettingAction = (
  emailSetting: AtLeast<EmailSetting, 'id'>,
  oldId?: number
): EmailSettingAction => {
  return {
    type: EmailSettingActionTypes.UPDATE_EMAIL_SETTING,
    payload: { emailSetting, oldId },
  };
};

export const deleteEmailSettingAction = (emailSettingId: number): EmailSettingAction => ({
  type: EmailSettingActionTypes.DELETE_EMAIL_SETTING,
  payload: { emailSettingId },
});
