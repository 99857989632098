import { ErrorMessage, getIn, useFormikContext } from 'formik';
import { FC } from 'react';
import styles from './styles.module.css';

interface LabeledFieldProps {
  children: JSX.Element | JSX.Element[];
  name: string;
  label: string;
  className?: string;
}

const LabeledField: FC<LabeledFieldProps> = ({
  name,
  children,
  label,
  className,
}: LabeledFieldProps) => {
  const { errors, touched } = useFormikContext();
  return (
    <div className={`d-flex flex-column ${className ? className : ''}`}>
      <label className={styles['form-label']} htmlFor={name}>
        {label}
      </label>
      {children}
      {getIn(errors, name) && getIn(touched, name) ? (
        <ErrorMessage name={name}>
          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
        </ErrorMessage>
      ) : null}
    </div>
  );
};
export default LabeledField;
