import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import GenericButton from 'components/GenericButton';
import FiltersButton from 'components/FiltersButton';
import GenericTable from 'components/GenericTable';
import SearchBar from 'components/SearchBar';
import EditIcon from 'assets/icons/edit.png';
import DeleteIcon from 'assets/icons/delete.png';
import BlockIcon from 'assets/icons/blocked.png';
import styles from './styles.module.css';
import {
  deleteCarePlanTemplate,
  getTemplateCarePlanList,
  partialUpdateCarePlanTemplate,
} from 'services/templatesService';
import { Tagitem } from 'components/Tagitem';
import { useHistory } from 'react-router-dom';
import TabEnum from '../../../../../../util/TabEnum';
import TabRouteMap from '../../../../../../util/TabRouteMap';
import GenericColumn from 'types/Shared/GenericColumn';
import { ITemplateCarePlan } from 'types/ApiModels/Templates/CarePlan';
import ConfirmDeleteModal from '../../../confirmDeleteModal';

const rowActions = (editAction: () => void, deleteAction: () => void) => (
  <div className={styles.actions}>
    <GenericButton icon={EditIcon} alt="edit" onClick={editAction} />
    <GenericButton icon={DeleteIcon} alt="delete" onClick={deleteAction} />
  </div>
);

const CarePlanTemplates = () => {
  const history = useHistory();

  const [filterValue, setFilterValue] = useState<string>('');
  const [templates, setTemplates] = useState<ITemplateCarePlan[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<ITemplateCarePlan[]>([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const clickedIdRef = useRef<number>(null);
  const clickedNameRef = useRef<string>(null);
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

  const disabledRows = useMemo(() => {
    const rows: number[] = [];
    templates.forEach((template, index) => !template.is_active && rows.push(index));
    return rows;
  }, [templates]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templateCarePlanList = await getTemplateCarePlanList();
        setTemplates(templateCarePlanList);
      } catch (err) {
        throw err;
      }
    };
    fetchTemplates();
  }, []);

  useEffect(() => {
    const filteredTemplates = templates.filter((template) =>
      template.name.toLowerCase().includes(filterValue.toLowerCase())
    );
    setFilteredTemplates(filteredTemplates);
  }, [templates, filterValue]);

  const onStatusClick = async (id: number) => {
    setAwaitingResponse(true);
    const templateIndex = filteredTemplates.findIndex((template) => template.id === id);
    const updatedTemplate: ITemplateCarePlan = {
      ...filteredTemplates[templateIndex],
      is_active: !filteredTemplates[templateIndex].is_active,
    };
    const updatedTemplates = [...filteredTemplates];
    updatedTemplates[templateIndex] = updatedTemplate;

    try {
      await partialUpdateCarePlanTemplate({
        id: updatedTemplate.id,
        is_active: updatedTemplate.is_active,
      });
      setTemplates(updatedTemplates);
      setAwaitingResponse(false);
    } catch (e) {
      throw e;
    }
  };

  const onDeleteIconClick = (id: number) => {
    setShowConfirmDeleteModal(true);
    clickedNameRef.current = templates.find((template) => template.id === id).name;
    clickedIdRef.current = id;
  };

  const deleteTemplate = async () => {
    const templateIndex = templates.findIndex((template) => template.id === clickedIdRef.current);
    const updatedTemplates = [...templates];
    try {
      await deleteCarePlanTemplate(clickedIdRef.current);
      updatedTemplates.splice(templateIndex, 1);
      setShowConfirmDeleteModal(false);
    } catch (e) {
      throw e;
    }
  };

  const columns: GenericColumn[] = [
    {
      title: 'Id',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      key: 'name',
      render: (rowInfo) => (
        <Tagitem
          onClick={() => history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}/${rowInfo.id}`)}
          className={styles.tagItem}
          text={rowInfo.name}
        />
      ),
    },
    {
      title: 'Created by',
      key: 'created_by_name',
    },
    {
      title: 'Patients',
      key: 'patients_count',
    },
    {
      title: 'Questionnaires',
      key: 'questionnaires_count',
    },
    {
      title: 'Duration',
      key: 'duration',
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ creator_is_admin, id }) =>
        !creator_is_admin ? (
          rowActions(
            () => history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}/${id}`),
            () => onDeleteIconClick(id)
          )
        ) : (
          <GenericButton
            icon={BlockIcon}
            alt="block"
            className={`${styles.actions} justify-content-center`}
          />
        ),
    },
    {
      title: 'Status',
      key: 'is_active',
      render: ({ id, is_active }) => (
        <FormCheck
          id={`switch-${id}`}
          type="switch"
          checked={is_active}
          onChange={() => onStatusClick(id)}
          disabled={awaitingResponse}
        />
      ),
      textAlign: 'end',
      cellTextAlign: 'end',
    },
  ];

  return (
    <div className="container-with-padding">
      <div
        className="dashboard-detail"
        style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05))' }}
      >
        <div className="dashboard-item-header d-flex justify-content-between align-items-center">
          <div>All Care Plan Templates</div>
          <div className="d-flex">
            <SearchBar
              placeholder="Search templates"
              className={styles.search}
              onChange={(e) => setFilterValue(e.target.value)}
            />
            <FiltersButton />
          </div>
        </div>
        <GenericTable
          columns={columns}
          data={filteredTemplates}
          specificRowClassName={styles.disabledRows}
          specificRows={disabledRows}
        />
      </div>
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        name={clickedNameRef.current}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onDelete={deleteTemplate}
      />
    </div>
  );
};

export default CarePlanTemplates;
