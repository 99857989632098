import React from 'react';
import styles from './styles.module.css';

interface IDurationDays {
  value: number;
  onChange: (value: number) => void;
}
const DurationInput = ({ value, onChange }: IDurationDays) => {
  return (
    <div className={styles.inputContainer}>
      Days
      <input
        type="number"
        className={styles.input}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
};
export default DurationInput;
