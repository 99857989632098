import { Role } from 'types/ApiModels/Administration';
import { Provider, Title } from 'types/ApiModels/Providers/Provider';
import Specialty from 'types/ApiModels/Providers/Specialty';
import { Language } from 'types/ApiModels/Users';
import Sex from 'types/ApiModels/Users/Sex';
import { ProviderProfileAction, ProviderProfileActionTypes } from './providerProfileActions';

export interface OptionsInfo {
  currentProvider: Provider;
  titles: Title[];
  specialties: Specialty[];
  languages: Language[];
  roles: Role[];
  genderOptions: Sex[];
}

export interface ProviderProfileState {
  actions: {
    currentActions: () => JSX.Element;
  };
  optionsInfo: OptionsInfo;
  providerProfileForm: {
    resetForm: () => void;
    submitForm: () => void;
    formDirty: boolean;
  };
}

export const providerProfileInitialState: ProviderProfileState = {
  actions: {
    currentActions: () => null,
  },
  optionsInfo: {
    currentProvider: null,
    languages: [],
    roles: [],
    specialties: [],
    titles: [],
    genderOptions: [],
  },
  providerProfileForm: {
    formDirty: false,
    resetForm: () => undefined,
    submitForm: () => undefined,
  },
};

const providerProfileReducer = (
  state: ProviderProfileState = providerProfileInitialState,
  action: ProviderProfileAction
): ProviderProfileState => {
  switch (action.type) {
    case ProviderProfileActionTypes.SET_OPTIONS_INFO: {
      return { ...state, optionsInfo: action.payload.optionsInfo };
    }
    case ProviderProfileActionTypes.SET_PROVIDER_PROFILE_ACTION: {
      return { ...state, actions: { ...state.actions, currentActions: action.payload.action } };
    }
    case ProviderProfileActionTypes.SET_PROVIDER_PROFILE_FORM_DIRTY: {
      return {
        ...state,
        providerProfileForm: { ...state.providerProfileForm, formDirty: action.payload.dirty },
      };
    }
    case ProviderProfileActionTypes.SET_PROVIDER_PROFILE_FORM_RESET: {
      return {
        ...state,
        providerProfileForm: { ...state.providerProfileForm, resetForm: action.payload.resetForm },
      };
    }
    case ProviderProfileActionTypes.SET_PROVIDER_PROFILE_SUBMIT_FORM: {
      return {
        ...state,
        providerProfileForm: {
          ...state.providerProfileForm,
          submitForm: action.payload.submitForm,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default providerProfileReducer;
