import Button from 'components/Button';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import { ErrorMessage } from 'formik';
import { IModalBody } from '../ActionModalBody';
import styles from './styles.module.css';
const RequestAppointmentBody = ({ onCancel }: IModalBody) => {
  return (
    <div>
      <div className={styles['question']}>
        <div>Why are you requesting this?</div>
        <TextAreaFormik name="note" placeholder={"I'm requesting this because..."} />
        <ErrorMessage
          name="note"
          render={(msg) => <div className={styles['error-message']}>{msg}</div>}
        />
      </div>
      <div className="d-flex justify-content-between w-100">
        <Button label="Cancel" onClick={onCancel} variant="cancel" />
        <Button label="Confirm" type="submit" variant="confirm" />
      </div>
    </div>
  );
};
export default RequestAppointmentBody;
