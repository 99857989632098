import { Message, Paginator } from '@twilio/conversations';
import { ReactComponent as EmptyChatsIcon } from 'assets/icons/chats_empty.svg';
import { useAuthContext } from 'auth';
import Card from 'components/Card';
import { StarIcon, VideoCallIcon, VoiceCallIcon } from 'components/communication-icons';
import EmptyList from 'components/EmptyList';
import { TextMessageAlt } from 'components/Messages';
import { MessageInput } from 'components/SendMessageBox/components/MessageInput';
import { UserBubbleUser } from 'components/UserBubble';
import VytracSpinner from 'components/vytrac-spinner';
import { useEffect, useMemo, useState } from 'react';
import { setSelectedPatient } from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import { getConversationAttributes } from 'screens/messages/utils';
import { updateEncounterOwner } from 'services/encounterService';
import { impersonatePatient, useTwilioConversation } from 'services/twilio';
import { ConversationSelectors, useConversationsStore } from 'store/twilio';
import { useTwilioClient } from 'store/twilio-client/twilio-context';
import { ConversationMetadata } from 'types/ApiModels/conversations';
import ConversationAttributes from 'types/Shared/conversation-attributes';
import ConversationType from 'types/Shared/conversation-type';
import { timeAgo } from 'util/dateUtils';
import { useEncounterPatient } from './hooks';
import styles from './styles.module.css';

const Header = ({ urgent, subject }: { urgent: boolean; subject: string }) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className="d-flex gap">
        {urgent && <div className={styles['red-circle']}></div>}
        <div>
          {urgent && <span>Urgent, </span>}
          <span>{subject}</span>
        </div>
      </div>
    </div>
  );
};

const Chat = ({
  containerClass,
  getMetadataByParticipantSidExternal,
}: {
  containerClass: string;
  getMetadataByParticipantSidExternal?: (participantSid: string) => ConversationMetadata;
}) => {
  const { currentUser } = useAuthContext();
  const getMetadataByParticipantSidFromStore = useConversationsStore(
    ConversationSelectors.getMetadataByParticipantSid
  );
  const getMetadataByParticipantSid = useMemo(() => {
    if (getMetadataByParticipantSidExternal) return getMetadataByParticipantSidExternal;
    return getMetadataByParticipantSidFromStore;
  }, [getMetadataByParticipantSidExternal, getMetadataByParticipantSidFromStore]);

  const {
    state: { selectedConversationSID },
    dispatch,
  } = useMessagesContext();

  const { client: twilioClient, isLoading: isTwilioLoading } = useTwilioClient();
  const {
    conversation,
    isLoading: isConversationLoading,
    participantInfoBySID,
  } = useTwilioConversation(twilioClient, selectedConversationSID);
  const currentConversationAttributes: ConversationAttributes = useMemo(() => {
    return getConversationAttributes(conversation?.attributes);
  }, [conversation?.attributes]);

  const selectedPatient = useEncounterPatient(
    currentConversationAttributes?.encounter_id,
    currentConversationAttributes?.patient_id
  );
  useEffect(() => {
    dispatch(setSelectedPatient(selectedPatient));
  }, [dispatch, selectedPatient]);

  const [selfParticipantSID, setSelfParticipantSID] = useState<string>();

  useEffect(() => {
    if (!conversation || !currentUser?.twilio_identity) return;
    conversation.getParticipantByIdentity(currentUser?.twilio_identity).then((p) => {
      setSelfParticipantSID(p.sid);
    });
  }, [conversation, currentUser?.twilio_identity]);

  //useListenToNewMessages
  useEffect(() => {
    if (!conversation || !currentUser?.twilio_identity) return;
    const onMessageAdd = async (message) => {
      const currentAttributes = getConversationAttributes(conversation.attributes);
      if (
        currentAttributes.conversation_type === ConversationType.CareTeamRequest &&
        message.participantSid === selfParticipantSID
      ) {
        //the provider answered so conversation should get transformed into followup type
        try {
          const impersonatedClient = await impersonatePatient(currentAttributes.patient_identity);
          const patientConversation = await impersonatedClient.getConversationBySid(
            conversation.sid
          );

          await patientConversation.updateAttributes({
            ...currentAttributes,
            conversation_type: ConversationType.CareTeamRequestFollowUp,
            identityMembers: [currentUser.twilio_identity, currentAttributes.patient_identity],
          });
          //we have to update also the ownership of the encounter: we must assign the providerId to the encounter
          await updateEncounterOwner(currentAttributes.encounter_id, currentUser?.id);
        } catch (error) {
          console.error('Error while trying to update conversation attributes', error);
        }
      }
    };
    conversation.on('messageAdded', onMessageAdd);

    return () => {
      conversation.removeListener('messageAdded', onMessageAdd);
    };
  }, [conversation, currentUser?.twilio_identity, selfParticipantSID]);

  //useUpdateLastReadMessage
  useEffect(() => {
    if (!conversation?.lastMessage) return;
    conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
  }, [conversation]);

  const [messagesPaginator, setMessagesPaginator] = useState<Paginator<Message>>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageInput, setMessageInput] = useState('');
  const handleMessageChange = (value: string) => {
    setMessageInput(value);
  };

  useEffect(() => {
    if (!conversation) return;
    setMessages([]);
    conversation.getMessages().then((msPaginator) => {
      setMessagesPaginator(msPaginator);
      setMessages(msPaginator.items);
    });
  }, [conversation]);

  useEffect(() => {
    if (!conversation) return;
    conversation.on('messageAdded', (message) => {
      setMessages((m) => [...m, message]);
    });
    return () => {
      conversation.removeAllListeners();
    };
  }, [conversation]);

  const starPosition = useMemo(() => {
    //TODO: change this to the preferred communication. What is this ?
    if (selectedPatient) {
      return { top: -3, left: -7 };
    } else {
      return { top: -3, left: 22 };
    }
  }, [selectedPatient]);

  const lastInteraction = useMemo(() => {
    if (!messages?.length) return '';
    const lastMessageDate = messages[messages.length - 1].dateCreated;
    return timeAgo(lastMessageDate);
  }, [messages]);

  const handleSendMessage = async (value: string) => {
    if (!value) return;
    setMessageInput('');
    try {
      const messageIndex = await conversation.prepareMessage().setBody(value).build().send();
      //if we're the ones sending the message we don't want to have our last read updated
      await conversation.updateLastReadMessageIndex(messageIndex);
    } catch (error) {
      console.error(error);
    }
  };

  const thereIsPatient = !!selectedPatient;
  const isLoading = isTwilioLoading || isConversationLoading;

  return (
    <Card
      className={`${styles['card']}`}
      bodyClassName="p-0"
      headers={[
        thereIsPatient ? (
          <Header
            key="patient-chat-header"
            urgent={selectedPatient?.urgency_score === 100}
            subject={currentConversationAttributes?.subject}
          />
        ) : (
          <></>
        ),
      ]}
    >
      <div className={`${containerClass} py-5 mb-3 d-flex flex-column justify-content-end `}>
        {thereIsPatient && !isLoading ? (
          <div
            className={`d-flex flex-column flex-flow-reverse ${styles['messages-container']}`}
            ref={(ref) => {
              if (!ref) return;
              ref.scrollTop = ref.scrollHeight;
            }}
          >
            {messages.map((message, index) => {
              const isSelfMessage =
                participantInfoBySID && Object.keys(participantInfoBySID).length
                  ? participantInfoBySID[message.participantSid]?.identity ===
                    currentUser.twilio_identity
                  : false;

              //? why is this null at this point
              const metadata = getMetadataByParticipantSid?.(message.participantSid);
              const [first_name, last_name] = metadata?.full_name?.split(' ') ?? [];
              const bubbleUser: UserBubbleUser = {
                first_name,
                last_name,
                imageUrl: metadata?.photo_thumbnail,
              };
              return (
                <div key={index} className={`${isSelfMessage ? 'd-flex justify-content-end' : ''}`}>
                  <TextMessageAlt
                    message={message.body}
                    from={bubbleUser}
                    createdAt={message.dateCreated}
                    timeStampClassName={styles['message-time']}
                    className={`${styles['message']} ${isSelfMessage ? 'flex-row-reverse' : ''}`}
                    messageClassName={`${styles['message-text']}`}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <EmptyList
            Icon={EmptyChatsIcon}
            title="Select a thread to respond to a message"
            fill="#EBE3F1"
          >
            {isLoading && selectedConversationSID ? <VytracSpinner /> : undefined}
          </EmptyList>
        )}
      </div>
      <div className="px-3 pb-3 d-flex gap align-items-center">
        <div className="flex-grow-1">
          <MessageInput
            disabled={!thereIsPatient}
            onChange={handleMessageChange}
            value={messageInput}
            handleSendMessage={handleSendMessage}
          />
        </div>
        <div className="d-flex gap position-relative">
          <div className={`${thereIsPatient ? 'cursor-pointer' : ''}`}>
            <VideoCallIcon
              style={{ zIndex: 0 }}
              width="21px"
              height="14px"
              isActive={thereIsPatient}
            />
          </div>
          <div className={`${selectedPatient ? 'cursor-pointer' : ''}`}>
            <VoiceCallIcon
              style={{ zIndex: 0 }}
              width="14px"
              height="14px"
              isActive={thereIsPatient}
            />
          </div>
          {thereIsPatient && (
            <div className="position-absolute cursor-pointer" style={starPosition}>
              <StarIcon
                width="16px"
                height="15px"
                isActive
                style={{ zIndex: 10, position: 'absolute' }}
              />
              <StarIcon
                width="16px"
                height="15px"
                color="white"
                style={{ zIndex: 9, position: 'absolute', transform: 'scale(1.5)' }}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
export default Chat;
