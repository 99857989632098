import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const ArrowDown: FC<SVGIconProps> = ({ color, height, width, style }: SVGIconProps) => {
  return (
    <svg width={width} height={height} style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.707.738a.633.633 0 01.77.128l1.785 1.952a1 1 0 001.476 0L6.522.866a.633.633 0 01.934.854L4.738 4.693a1 1 0 01-1.476 0L.544 1.72A.633.633 0 01.707.738z"
        fill={color}
      />
    </svg>
  );
};
export default ArrowDown;
