import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getAnalyticsPatients } from 'services/analyticsService';
import { Patients } from 'types/ApiModels/Analytics/AnalyticsPatients';
import { setPatients } from '../AnalyticsActionCreators';
import { AnalyticsAction } from '../AnalyticsActionTypes';
import { useAnalyticsContext } from '../AnalyticsContext';

const usePatients = (
): [Patients, Dispatch<AnalyticsAction>, () => void] => {
  const {
    state: { patients },
    dispatch,
    fetchRegistry,
  } = useAnalyticsContext();

  const [patientsState, setPatientsState] = useState<Patients>(null);

  const _refetch = useRef(0);
  const refetch = useCallback(() => {
    fetchRegistry.current.patients.list = false;
    _refetch.current += 1;
  }, [fetchRegistry]);

  const fetchPatients = useCallback(async () => {
    if(!patientsState){
      const data = await getAnalyticsPatients();
      setPatientsState(data);
    }
  }, [patients]);

  const patientsInit = useCallback(async () => {
    fetchRegistry.current.patients.list = true;
    await fetchPatients();
    fetchRegistry.current.patients.list = false;
  }, [fetchRegistry, fetchPatients]);

  useEffect(() => {
    patientsInit();
  }, [fetchRegistry, patients, patientsInit, _refetch.current]);

  useEffect(() => {
    dispatch(setPatients(patientsState));
  }, [dispatch, patientsState]);

  return [patientsState, dispatch, refetch];
};

export default usePatients;
