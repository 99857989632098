import React from 'react';
import styles from './styles.module.css';
const PatientInfo = ({patient}) => {
  return (
    <div className={styles.container}>
      <img src={patient?.profileIcon} className={styles.pic} alt="patient" />
      <div className={styles.data}>
        <div>
          <span className={styles.name}>
            {patient.firstName} {patient.lastName}
          </span>
          •<span>Active</span>•<span>#{patient.id}</span>
        </div>
        <hr className={styles.horizontalLine} />
        <div>
          <span>20.10.3000 BC</span>•<span>daddyissues@amazon.com</span>•
          <span>813-951-2614</span>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
