import React, { FC } from 'react';
import Checkbox from '../../../form/Checkbox';
import styles from './styles.module.css';

interface INoteCheckBox {
  label: string;
  onChangeCheckbox: () => void;
  checked: boolean;
  boxShadow: string;
}
const NoteCheckbox: FC<INoteCheckBox> = ({
  label,
  onChangeCheckbox,
  checked,
  boxShadow,
  children,
}) => {
  return (
    <div className={boxShadow}>
      <div className={styles.checkBoxContainer}>
        <Checkbox label={label} onClick={onChangeCheckbox} value={checked} />
      </div>
      {checked && children}
    </div>
  );
};
export default NoteCheckbox;
