import moment from 'moment';
import React, {useRef} from 'react';
import {useEffect} from 'react';
import GoalPieChart from '../../../../../../../../../../components/GoalPieChart';
import styles from './styles.module.css';
const MonthlyGoalChart = ({proportion, name}) => {
  const [critical, setCritical] = React.useState(false);

  const currentMonth = useRef(moment().format('MMM'));

  const totalDays = moment().daysInMonth();

  const daysLeft = moment().endOf('month').diff(moment(), 'days');

  useEffect(() => {
    if (daysLeft < totalDays / 2) {
      setCritical(true);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.data}>
        <div className={styles.name}>
          {name} - {proportion * 100}%
        </div>
        <hr className={styles.horizontalLine} />
        <div
          className={styles.timeLeft}
          style={{color: critical ? '#F5485C' : '#393E48'}}
        >
          {daysLeft} day{daysLeft > 1 ? 's' : ''} left {currentMonth.current}
        </div>
      </div>
      <div className={styles.chart}>
        <GoalPieChart
          className
          data={[proportion, 1 - proportion]}
          width={57}
          height={57}
          radius={28.5}
          colors={['#CFD6E2', '#E7EAED']}
        />
      </div>
    </div>
  );
};

export default MonthlyGoalChart;
