import { VytalType } from './Vytal';

export enum UmbralType {
  CRITICAL_ZONE_UPPER = 'critical_zone_upper',
  RED_ZONE_UPPER = 'red_zone_upper',
  YELLOW_ZONE_UPPER = 'yellow_zone_upper',
  NORMAL_ZONE = 'normal_zone',
  YELLOW_ZONE_LOWER = 'yellow_zone_lower',
  RED_ZONE_LOWER = 'red_zone_lower',
  CRITICAL_ZONE_LOWER = 'critical_zone_lower',
}
export enum LimitType {
  MIN = 'min_value',
  MAX = 'max_value',
}
export interface IUmbralValue {
  type: UmbralType;
  limitType: LimitType;
}
export interface IGoalThresholdUmbral {
  id: number;
  deleted: boolean;
  type: UmbralType;
  min_value: number;
  max_value: number;
  date_created: string;
  created_by: number;
  referrer_alert: number;
}

export interface IGoalThreshold {
  id: number;
  patient_id: number;
  type: VytalType;
  is_enabled: boolean;
  min_value: number;
  max_value: number;
  threshold_umbrals: IGoalThresholdUmbral[];
}
