import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { iconType } from 'types/Patients/constants';
import { ReactComponent as HexagonIcon } from 'assets/icons/hexagon.svg';
import SVGIconProps from 'types/Shared/SVGIconProps';
import { formatVytracDateNoYear } from 'util/dateUtils/shared';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import styles from './styles.module.css';
import { alert } from '../GraphAlert';
import { RedAlert } from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';

interface RedAlertDetailProps {
  alerts: RedAlert[];
  showTopIcons?: boolean;
  selectedAlert: RedAlert;
  setSelectedAlertIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedAlertIndex: number;
}
const RedAlertDetail = ({
  alerts,
  showTopIcons = true,
  selectedAlert,
  setSelectedAlertIndex,
  selectedAlertIndex,
}: RedAlertDetailProps) => {
  useEffect(() => {
    if (alerts.length > 0) {
      setSelectedAlertIndex(0);
    }
  }, [alerts, setSelectedAlertIndex]);

  const Icon = useMemo(
    () => selectedAlert && rpmActionTypeToIcon[selectedAlert.vytal.type],
    [selectedAlert]
  );

  return (
    <div className="p-2 ml-2">
      <div className="mb-2 d-flex gap ml-2">
        {showTopIcons && (
          <>
            {alerts.map((alert, index) => (
              <div
                key={index}
                className={styles['circle']}
                style={{
                  backgroundColor: selectedAlertIndex === index ? '#F5485C' : 'white',
                }}
                onClick={() => setSelectedAlertIndex(index)}
              ></div>
            ))}
          </>
        )}
      </div>
      <div className="d-flex align-items-center">
        <div className="alert-item-container-hexagon mr-3" style={{ minWidth: 26, width: 26 }}>
          {Icon && <Icon width="12px" height="11px" color="#F5485C" />}
        </div>
        <div>
          <div className="d-flex gap align-items-center">
            <span className={styles['date']}>
              {formatVytracDateNoYear(new Date(selectedAlert?.created_at))}
            </span>
            <span className={styles['title']}>
              {parseFloat(selectedAlert?.vytal.value)} {selectedAlert?.vytal.name}
            </span>
          </div>
          <div className={styles['description']}>
            <span style={{ textTransform: 'capitalize' }}>{selectedAlert?.goal_umbral_type}</span>={' '}
            {selectedAlert?.goal_umbral_value} • {selectedAlert?.vytal.device_name}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RedAlertDetail;
