import styles from './styles.module.css';
interface PointsMultiplierItemProps {
  multiplier: number;
  days: number;
  current?: boolean;
}
export const PointsMultiplierItem = ({
  multiplier,
  days,
  current = false,
}: PointsMultiplierItemProps) => {
  return (
    <div
      className={styles.item}
      style={{
        backgroundColor: current ? '#E7EAED' : '',
        fontWeight: current ? '700' : '',
      }}
    >
      <div>{days} day activity streak</div>
      <div>{multiplier}</div>
    </div>
  );
};
