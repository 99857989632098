import { AlertImportanceOptions } from './AlertOptions';

export const mapAlertTypeToColor = (alertType: AlertImportanceOptions) => {
  switch (alertType) {
    case AlertImportanceOptions.NORMAL:
      return '#393E48';
    case AlertImportanceOptions.YELLOW:
      return '#F8CA54';
    case AlertImportanceOptions.RED:
      return '#F5485C';
    case AlertImportanceOptions.CRITICAL:
      return '#F5485C';
    case AlertImportanceOptions.MISSED:
      return '#8FAAC3';
    default:
      break;
  }
};
