import { SET_DATE_RANGE, SET_SCHEDULES, TOGGLE_SCHEDULE_COMPLETE } from './schedules-action-types';

export const setSchedules = (schedules) => ({
  type: SET_SCHEDULES,
  payload: schedules,
});

export const toggleScheduleCompleted = (id) => ({
  type: TOGGLE_SCHEDULE_COMPLETE,
  payload: id,
});

export const setSchedulesDateRange = (range) => ({
  type: SET_DATE_RANGE,
  payload: range,
});
