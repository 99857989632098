import { useFormikContext } from 'formik';
import Checkbox from 'components/form/Checkbox';
import InputField from 'components/InputField';
import ModalBody from '../ModalBody';
import styles from './styles.module.css';

const Header = ({
  setCurrentSchedule,
  dayShift,
  secondShift,
  thirdShift,
  setDayShift,
  setSecondShift,
  setThirdShift,
  setModalBody,
  setShowModal,
}) => {
  const { values, setFieldValue } = useFormikContext();

  //Toggles the checkbox for the shift and shows modal with dynamic onClick callbacks
  const toggleShift = (val, fun) => {
    //If value is true we don't need to show the modal
    if (val) {
      fun(true);
      return;
    }

    setShowModal((prev) => !prev);

    const message =
      'Disabling this shift will result in disabling time slots you have set as available, are you sure you want to continue?';

    //renders Modal body using the function passed as a parameter
    const modalBody = <ModalBody fun={fun} val={val} setShowModal={setShowModal} message={message} />;

    setModalBody(modalBody);

  };

  const timeZoneValue = "UTC +3";


  return (
    <div className={styles.scheduleCointainer}>
      <div className="pl-4 pt-2">
        <InputField
          value={values.name}
          onChange={(e) => {
            setFieldValue('name', e.target.value);
            setCurrentSchedule(e.target.value);
          }}
          className={styles.currentScheduleInput}
          label={'Schedule name'}
          labelClassName={styles.currentScheduleLabel}
          containerClassName={styles.currentScheduleContainer}
          placeholder="Enter schedule name"
          name="scheduleName"
          required
        />
      </div>
      <div className="d-flex justify-content-around">
        <div className="px-2 mt-1">
          <label className={styles.timeZoneLabel}> Current Time Zone: {timeZoneValue} </label>
        </div>
        <div className="px-2">
        <Checkbox label={'Day Shift'} value={dayShift} onChange={(e) => toggleShift(e.target.checked, setDayShift)} />
        </div>
        <div className="px-2">
          <Checkbox
            label={'Second Shift'}
            value={secondShift}
            onChange={(e) => toggleShift(e.target.checked, setSecondShift)}
          />
        </div>
        <div className="px-2">
          <Checkbox
            label={'Third Shift'}
            value={thirdShift}
            onChange={(e) => toggleShift(e.target.checked, setThirdShift)}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
