import React from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import TabEnum from '../../util/TabEnum';
import TabRouteMap from '../../util/TabRouteMap';
import TemplateTabs from './components/TemplateTabs';
import TemplateDetails from './components/TemplateDetails';

export interface ITemplateParams {
  id?: string;
  tab?: string;
}

const DynamicTabs = () => {
  const { tab } = useParams<ITemplateParams>();
  return <TemplateTabs selectedTabKey={tab} />;
};

const DynamicTemplateDetails = () => {
  const { tab, id } = useParams<ITemplateParams>();
  const templateId = Number(id);
  if (!isNaN(templateId)) {
    return <TemplateDetails templateType={tab} id={templateId} />;
  } else if (id !== 'create') {
    return <Redirect to={TabRouteMap[tab]} />;
  } else {
    return <TemplateDetails templateType={tab} create />;
  }
};

const Content = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]} />
      </Route>
      <Route path={`${match.path}/:tab`} exact>
        <DynamicTabs />
      </Route>
      <Route path={`${match.path}/:tab/:id`} exact>
        <DynamicTemplateDetails />
      </Route>
    </Switch>
  );
};

export default Content;
