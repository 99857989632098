import activityItemStyles from '../../../ActivityItems.module.css';
import { HTMLProps } from 'react';
import { AppointmentType } from 'types/ApiModels/Appointments/Appointment';
import { getComsIcon } from 'components/ActivityTimeline/utils';

export const appointmentContentProps = (): HTMLProps<HTMLDivElement> => ({
  className: `d-flex rounded-left align-items-center gap px-2 py-2 overflow-hidden ${activityItemStyles['activity-container']}`,
});

interface IAppointmentContent {
  type: AppointmentType;
  mainLabel: string;
  secondaryLabel?: string;
}
const AppointmentContent = ({ type, mainLabel, secondaryLabel }: IAppointmentContent) => {
  return (
    <div className="d-flex justify-content-between">
      <div className={`d-flex flex-column overflow-hidden`}>
        <p
          className={`font-size-medium ${activityItemStyles['main-label']} ${activityItemStyles['day-item-label']}`}
        >
          {mainLabel}
        </p>
        {secondaryLabel && (
          <p className={`font-size-medium ${activityItemStyles['secondary-label']}`}>
            {secondaryLabel}
          </p>
        )}
      </div>
      <div> {getComsIcon(type)}</div>
    </div>
  );
};
export default AppointmentContent;
