import VyTracLayout from '../../components/VyTracLayout';
import AnalyticsContent from './AnalyticsContent';
import NavigationHeader from './NavigationHeader';
import AnalyticsProvider from './store/AnalyticsProvider';

const AnalyticsComponent = () => {
  return (
    <VyTracLayout selectedRoute="analytics">
      <AnalyticsProvider>
        <NavigationHeader />
        <AnalyticsContent />
      </AnalyticsProvider>
    </VyTracLayout>
  );
};

export default AnalyticsComponent;
