export const REQUIRED_MESSAGE = 'Required';

export const PHONE_NUMBER_VALIDATION_MESSAGE = 'Phone number must be in the format XXX-XXX-XXXX';

export const checkFormHasAnyErrors = (touched, errors) => {
  const errorKeys = Object.keys(errors).sort();
  const filteredTouched = Object.keys(touched).filter((item) =>
    errorKeys.some((key) => key === item)
  );

  if (filteredTouched.length > 0) {
    return true;
  }
  return false;
};
