import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAdministrationContext } from 'screens/Administration/store';
import KeyWordTemplatesCard from './keyword-templates.card/keyword-templates.card';
import KeywordsCard from './keywords-card';
import NotesKeywordsActions from './notes-keywords-actions';

const NotesKeywords = () => {
  const { setActions } = useAdministrationContext();

  useEffect(() => {
    setActions(NotesKeywordsActions);
  }, []);

  return (
    <div>
      <Row>
        <Col md={8}>
          <KeywordsCard />
        </Col>
        <Col>
          <KeyWordTemplatesCard />
        </Col>
      </Row>
    </div>
  );
};
export default NotesKeywords;
