import Specialty from 'types/ApiModels/Providers/Specialty';
import { SpecialtyAction, SpecialtyActionTypes } from './specialtyActionTypes';

export interface SpecialtyState {
  list: Specialty[];
  showEditSpecialty: boolean;
  showAddSpecialty: boolean;
  showProvidersBySpecialty: boolean;
  currentSpecialtyId: number;
  resetSpecialtiesForm: () => void;
  deletedSpecialties: number[];
  addedSpecialties: Specialty[];
  updatedSpecialties: Specialty[];
  submitSpecialtiesForm: () => void;
}

export const specialtiesInitialState: SpecialtyState = {
  list: null,
  showEditSpecialty: false,
  showAddSpecialty: false,
  showProvidersBySpecialty: false,
  resetSpecialtiesForm: () => undefined,
  currentSpecialtyId: null,
  addedSpecialties: [],
  deletedSpecialties: [],
  updatedSpecialties: [],
  submitSpecialtiesForm: () => undefined,
};

const adminSpecialtyReducer = (state: SpecialtyState, action: SpecialtyAction): SpecialtyState => {
  switch (action.type) {
    case SpecialtyActionTypes.SET_SPECIALTIES: {
      return {
        ...state,
        list: action.payload.specialties,
      };
    }
    case SpecialtyActionTypes.TOGGLE_SHOW_ADD_SPECIALTY: {
      return { ...state, showAddSpecialty: !state.showAddSpecialty };
    }
    case SpecialtyActionTypes.TOGGLE_SHOW_EDIT_SPECIALTY: {
      return { ...state, showEditSpecialty: !state.showEditSpecialty };
    }
    case SpecialtyActionTypes.TOGGLE_SHOW_PROVIDERS_BY_SPECIALTY: {
      return { ...state, showProvidersBySpecialty: !state.showProvidersBySpecialty };
    }
    case SpecialtyActionTypes.SET_RESET_SPECIALTIES_FORM: {
      return { ...state, resetSpecialtiesForm: action.payload.resetForm };
    }
    case SpecialtyActionTypes.APPEND_ADDED_SPECIALTY: {
      return { ...state, addedSpecialties: [...state.addedSpecialties, action.payload.specialty] };
    }
    case SpecialtyActionTypes.APPEND_DELETED_SPECIALTY: {
      return {
        ...state,
        deletedSpecialties: [...state.deletedSpecialties, action.payload.specialtyId],
      };
    }
    case SpecialtyActionTypes.APPEND_UPDATED_SPECIALTY: {
      //check whether it already has been edited:
      const updatedIdx = state.updatedSpecialties.findIndex(
        (us) => us.id === action.payload.specialty.id
      );
      if (updatedIdx === -1) {
        return {
          ...state,
          updatedSpecialties: [...state.updatedSpecialties, action.payload.specialty],
        };
      }
      const copy = [...state.updatedSpecialties];
      copy[updatedIdx] = action.payload.specialty;
      return { ...state, updatedSpecialties: copy };
    }
    default: {
      return state;
    }
    case SpecialtyActionTypes.CLEAR_ALL_CHANGES: {
      return { ...state, updatedSpecialties: [], addedSpecialties: [], deletedSpecialties: [] };
    }
    case SpecialtyActionTypes.SET_CURRENT_SPECIALTYID: {
      return { ...state, currentSpecialtyId: action.payload.specialtyId };
    }
    case SpecialtyActionTypes.SET_SUBMIT_SPECIALTY_FORM: {
      return { ...state, submitSpecialtiesForm: action.payload.submitForm };
    }
  }
};

export default adminSpecialtyReducer;
