import { createContext, Dispatch, useContext } from 'react';
import { WebSocketAlertsState } from './Alerts';
import { AllWebSocketActions } from './WebSocketReducer';

export interface WebSocketState {
  alerts: WebSocketAlertsState;
  chatMessages: string[]; //TODO: add chat type
}

export interface WebSocketContextState {
  state: WebSocketState;
  dispatch: Dispatch<AllWebSocketActions>;
}

export const WebSocketContext = createContext<WebSocketContextState>({} as WebSocketContextState);
export const useWebSocketContext = () => useContext(WebSocketContext);

export default WebSocketContext;
