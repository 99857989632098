import { AdminForm } from 'types/Administration/AdminForms/AdminForm';
import { FormAction, FormActionTypes } from './formActionTypes';

export interface FormState {
  list: AdminForm[];
  currentForm: AdminForm;
  openAddFormModal: boolean;
  dirtyForms: Record<number, AdminForm>;
  openDeleteFormModal: boolean;
}

export const formsInitialState: FormState = {
  list: null,
  currentForm: null,
  openAddFormModal: false,
  dirtyForms: {},
  openDeleteFormModal: false,
};

const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case FormActionTypes.SET_FORMS:
      return {
        ...state,
        list: action.payload.forms,
      };
    case FormActionTypes.SET_CURRENT_FORM:
      return {
        ...state,
        currentForm: action.payload.form,
      };
    case FormActionTypes.DELETE_FORM:
      return {
        ...state,
        list: state.list.filter((form) => form.id !== action.payload.formId),
      };
    case FormActionTypes.UPDATE_FORM:
      const id = action.payload.oldId ? action.payload.oldId : action.payload.form.id;
      const updatedIdx = state.list.findIndex((es) => es.id === id);
      const listCopy = [...state.list];
      listCopy[updatedIdx] = { ...listCopy[updatedIdx], ...action.payload.form };
      return { ...state, list: listCopy };

    case FormActionTypes.OPEN_ADD_FORM_MODAL:
      return {
        ...state,
        openAddFormModal: action.payload.open,
      };
    case FormActionTypes.OPEN_DELETE_FORM_MODAL:
      return {
        ...state,
        openDeleteFormModal: action.payload.open,
      };
    case FormActionTypes.ADD_NEW_FORM:
      return {
        ...state,
        list: [...state.list, action.payload.form],
      };
    case FormActionTypes.SET_FORM_DIRTY:
      return {
        ...state,
        dirtyForms: {
          ...state.dirtyForms,
          [action.payload.form.id]: action.payload.form,
        },
      };
    case FormActionTypes.RESET_DIRTY_FORMS:
      return {
        ...state,
        dirtyForms: {},
      };
    case FormActionTypes.UPDATE_DIRTY_FORMS:
      return {
        ...state,
        list: state.list.map((form) => {
          if (state.dirtyForms[form.id]) {
            return { ...form, ...state.dirtyForms[form.id] };
          }
          return form;
        }),
        dirtyForms: {},
      };
    case FormActionTypes.CLEAR_DIRTY_FORM:
      const newDirty = { ...state.dirtyForms };
      delete newDirty[action.payload.formId];
      return {
        ...state,
        dirtyForms: newDirty,
      };

    default:
      return state;
  }
};

export default formReducer;
