import cloudArrowDown from 'assets/icons/cloudArrowDown.png';
import greenCheckCircle from 'assets/icons/greenCheckCircle.png';
import trashcan from 'assets/icons/trashcan.png';
import styles from './styles.module.css';

const PatientDocumentItem = ({ document, checked }) => {
  const { name, date } = document;

  return (
    <div className={`bg-light-gray font-size-medium ${styles.main}`}>
      <div>
        {checked && <img className={styles['check-icon']} src={greenCheckCircle} alt="" />}
        <span className={checked ? styles['green-color'] : undefined}>{name}</span>
      </div>

      <div>
        <span>{date}</span>
        <img className={styles.btn} src={cloudArrowDown} alt="" />
        <img className={styles.btn} src={trashcan} alt="" />
      </div>
    </div>
  );
};

export default PatientDocumentItem;
