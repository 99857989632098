import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface INotAnAdminModal {
  show: boolean;
  onConfirm: () => void;
}
const NotAnAdminModal = ({ show, onConfirm }: INotAnAdminModal) => {
  return (
    <VytracModal
      show={show}
      title="Not allowed"
      body={<span className="font-size-big">Only admins are allowed to modify this template</span>}
      footer={<Button label="confirm" variant="confirm" onClick={onConfirm} />}
      onClose={() => onConfirm}
    />
  );
};
export default NotAnAdminModal;
