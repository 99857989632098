import Button from 'components/Button';
import { FC } from 'react';
import { useAdministrationContextV2 } from 'screens/Administration/store';

const ProviderDetailsActions = () => {
  const {
    administrationStateV2: {
      providers: {
        submitSingleProviderForm,
        singleProviderFormDirty,
        resetSingleProviderForm,
        removeSingleProvider,
      },
    },
  } = useAdministrationContextV2();
  return (
    <div className="d-flex">
      <Button
        variant="cancel"
        disabled={!singleProviderFormDirty}
        label="Cancel"
        className="mr-2"
        onClick={resetSingleProviderForm}
      />
      <Button
        variant="confirm"
        disabled={!singleProviderFormDirty}
        label="Save Changes"
        className="mr-2"
        onClick={submitSingleProviderForm}
      />
      <Button variant="delete" label="Remove provider" onClick={removeSingleProvider} />
    </div>
  );
};
export default ProviderDetailsActions;
