import { text } from 'stream/consumers';
import { MockBroadcast, MockChat, ChatData } from './types';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const mockPopulations = [
  {
    id: 1,
    name: 'High BP',
  },
  {
    id: 2,
    name: 'Joint Pain',
  },
  {
    id: 3,
    name: 'Osteoporosis',
  },
  {
    id: 4,
    name: 'Anxiety',
  },
  {
    id: 5,
    name: 'Hypertension',
  },
  {
    id: 6,
    name: 'Depression',
  },
  {
    id: 7,
    name: 'Osteoarthritis',
  },
];

export const mockThreadData: MockChat[] = [
  {
    thread_sid: '0',
    messages: {
      id: '0',
      messages: [
        {
          text: 'I need you urgently',
          from: 'John Doe',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'John Doe',
          created_at: new Date(2022, 2, 12).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '1',
    messages: {
      id: '1',
      messages: [
        {
          text: 'I need you urgently',
          from: 'John Constantine',
          created_at: new Date(2022, 2, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'John Constantine',
          created_at: new Date(2022, 2, 11).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '2',
    messages: {
      id: '2',
      messages: [
        {
          text: 'Hello, how are you? Can we meet tomorrow?',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Barry Allen',
          created_at: new Date(2022, 3, 1, 12).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '3',
    messages: {
      id: '3',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '4',
    messages: {
      id: '4',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '5',
    messages: {
      id: '5',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '6',
    messages: {
      id: '6',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '7',
    messages: {
      id: '7',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '8',
    messages: {
      id: '8',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '9',
    messages: {
      id: '9',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '10',
    messages: {
      id: '10',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '11',
    messages: {
      id: '11',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '12',
    messages: {
      id: '12',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '13',
    messages: {
      id: '13',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
  {
    thread_sid: '14',
    messages: {
      id: '14',
      messages: [
        {
          text: 'I cannot sleep since yesterday. Please contact me ASAP',
          from: 'Shiera Sanders Hall',
          created_at: new Date(2022, 2, 13).toISOString(),
        },
      ],
    },
  },
];

export const mockRoles = [
  { id: 0, name: 'MA' },
  { id: 1, name: 'Office Admin' },
];

export const mockVia = [
  { id: 0, name: 'Push notification' },
  { id: 1, name: 'Email' },
];

export const mockBroadcastData: MockBroadcast[] = [
  {
    id: 0,
    title: 'New Provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Doctor Fauci, an infectious disease expert, has joined the practice. His hours are 9-5 Monday-Thursday.',
  },
  {
    id: 1,
    title: 'Signup Message',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
  {
    id: 2,
    title: 'New provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
  {
    id: 3,
    title: 'New provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
  {
    id: 4,
    title: 'New provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
  {
    id: 5,
    title: 'New provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
];

export const mockBroadcastTemplateData: MockBroadcast[] = [
  {
    id: 0,
    title: 'New Provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Doctor Fauci, an infectious disease expert, has joined the practice. His hours are 9-5 Monday-Thursday.',
  },
  {
    id: 1,
    title: 'Signup Message',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
  {
    id: 2,
    title: 'New provider has joined',
    to: [
      { id: 0, name: 'MA' },
      { id: 1, name: 'Office Admin' },
    ],
    via: [
      { id: 0, name: 'Push notification' },
      { id: 1, name: 'Email' },
    ],
    time: {
      year: 2022,
      month: 2,
      day: 13,
    },
    text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam vel eveniet suscipit sapiente dolore, enim molestiae sint sit temporibus distinctio architecto dicta inventore dolor, delectus vitae molestias iure reprehenderit dolores.',
  },
];
