import React, { useContext, useEffect } from 'react';
import Context from '../../../../../../util/Context';
import ActionsButton from '../ActionsButton';
import Item from './components/Item';
import CodeTabs from './components/CodeTabs';
import GenericPatientTableWithDropdown from '../../../../../../../../components/GenericPatientTableWithDropdown';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './styles.module.css';
const PatientBillingTable = ({ onPatientClick }) => {
  const { state } = useContext(Context);
  const { path } = useRouteMatch();
  const history = useHistory();

  const [data, setData] = React.useState(state.filteredPatientList);

  const [selected, setSelected] = React.useState([...Array(data?.length || 0).keys()]);

  const columns = [
    {
      title: 'Patient',
      key: 'patient',
      style: {
        flexBasis: '40%',
      },
      render: (rowData) => (
        <Item
          data={rowData}
          onClick={() => {
            history.push(`${path}/${rowData.id}`);
          }}
        />
      ),
    },
    {
      title: 'Patient ID',
      key: 'id',
      style: {
        flexBasis: '10%',
      },
      render: (row) => <>{`#${row.id}`}</>,
    },
    {
      title: 'Eligible codes',
      key: 'eligibleCodes',
      style: {
        flexBasis: '12%',
      },
    },
    {
      title: 'Ready to submit',
      key: 'readyToSubmit',
      style: {
        flexBasis: '12%',
      },
    },
    {
      title: 'Submitted',
      key: 'submitted',
      style: {
        flexBasis: '10%',
      },
    },
    {
      title: 'Paid',
      key: 'paid',
      style: {
        flexBasis: '8%',
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      style: {
        flexBasis: '3%',
      },
      render: () => (
        <div className="d-flex">
          <ActionsButton onClick={() => {}} label="Export" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setData(state.filteredPatientList);
  }, [state.filteredPatientList]);

  useEffect(() => {
    setSelected([...Array(data?.length || 0).keys()].map(() => false));
  }, [data]);

  return (
    <>
      {data && (
        <GenericPatientTableWithDropdown
          columns={columns}
          data={data}
          selectable
          selected={selected}
          setSelected={setSelected}
          rowChildren={(props) => <CodeTabs {...props} />}
          rowClassName="flex-nowrap"
          dataRowClassName={styles['row-data']}
          tableClassName="pb-0"
        />
      )}
    </>
  );
};
export default PatientBillingTable;
