import moment from 'moment';
import ScheduleSelector from 'react-schedule-selector';
import ModalBody from '../ModalBody';
import DateCell from './components/DateCell';
import DateLabel from './components/DateLabel';
import TimeLabel from './components/TimeLabel';
import { FIRST_SHIFT, SECOND_SHIFT, THIRD_SHIFT } from './constants';


const Body = ({
  selection,
  setSelection,
  dayShift,
  secondShift,
  thirdShift,
  setModalBody,
  setShowModal,
  weekToggles,
  setWeekToggles,
}) => {
  //Each index represents a day starting from sunday

  //Toggles the switch for the day at index
  const toggleWeekday = (index) => {
    const toggle = () =>
      setWeekToggles(weekToggles.map((_, i) => (i === index ? !weekToggles[i] : weekToggles[i])));
    //If false then set to true
    if (!weekToggles[index]) {
      toggle();
      return;
    }

    setShowModal((prev) => !prev);

    const message =
      'Disabling this day will result in disabling time slots you have set as available, are you sure you want to continue?';

    //renders Modal body using the function passed as a parameter
    const modalBody = <ModalBody fun={toggle} setShowModal={setShowModal} message={message} />;

    setModalBody(modalBody);

    //Else show modal before toggling
  };

  //Renders Time label
  const renderTimeLabel = (time) => {
    const timeHours = time.getHours();

    let parsedTime;

    //Defined by the design
    const firstCell = timeHours === 6;

    //This is because the library counts the hours after 12AM as hours from the next day
    if (timeHours >= 0 && timeHours < 6) parsedTime = moment(time).subtract(1, 'day').toDate();
    else parsedTime = time;

    let shift, startOfShift;

    if (timeHours >= FIRST_SHIFT.start && timeHours < FIRST_SHIFT.end) {
      shift = FIRST_SHIFT.id;
      startOfShift = FIRST_SHIFT.start === timeHours;
    }

    if (timeHours >= SECOND_SHIFT.start || timeHours < SECOND_SHIFT.end) {
      shift = SECOND_SHIFT.id;
      startOfShift = SECOND_SHIFT.start === timeHours;
    }

    if (timeHours >= THIRD_SHIFT.start && timeHours < THIRD_SHIFT.end) {
      shift = THIRD_SHIFT.id;
      startOfShift = THIRD_SHIFT.start === timeHours;
    }

    return (
      <TimeLabel
        time={parsedTime}
        shift={shift}
        startOfShift={startOfShift}
        firstCell={firstCell}
      />
    );
  };

  //Renders Date label
  const renderDateLabel = (date) => {
    const weekIndex = moment(date).weekday();
    const isToday = moment(date).isSame(new Date(), 'day');
    return (
      <DateLabel
        date={date}
        toggle={weekToggles[weekIndex]}
        onToggle={() => toggleWeekday(weekIndex)}
        isToday={isToday}
      />
    );
  };

  //Renders Date cell
  const renderDateCell = (date, selected, refSetter) => {
    const timeHours = date.getHours();

    let parsedTime;

    //Defined by the design
    const firstCell = timeHours === 6;

    //This is because the library counts the hours after 12AM as hours from the next day
    if (timeHours >= 0 && timeHours < 6) parsedTime = moment(date).subtract(1, 'day').toDate();
    else parsedTime = date;

    let shift, startOfShift, shiftDisabled;

    if (timeHours >= FIRST_SHIFT.start && timeHours < FIRST_SHIFT.end) {
      shift = 1;
      startOfShift = FIRST_SHIFT.start === timeHours;
      shiftDisabled = !dayShift;
    }

    if (timeHours >= SECOND_SHIFT.start || timeHours < SECOND_SHIFT.end) {
      shift = 2;
      startOfShift = SECOND_SHIFT.start === timeHours;
      shiftDisabled = !secondShift;
    }

    if (timeHours >= THIRD_SHIFT.start && timeHours < THIRD_SHIFT.end) {
      shift = 3;
      startOfShift = THIRD_SHIFT.start === timeHours;
      shiftDisabled = !thirdShift;
    }

    const disabled = !weekToggles[moment(parsedTime).weekday()] || shiftDisabled;

    return (
      <DateCell
        date={parsedTime}
        selected={selected}
        refSetter={refSetter}
        shift={shift}
        firstCell={firstCell}
        startOfShift={startOfShift}
        disabled={disabled}
      />
    );
  };
  return (
    <div>
      <ScheduleSelector
        selection={selection}
        numDays={7}
        startDate={moment().startOf('week')}
        minTime={6}
        maxTime={30}
        renderTimeLabel={renderTimeLabel}
        renderDateLabel={renderDateLabel}
        renderDateCell={renderDateCell}
        rowGap="0"
        columnGap="0"
        selectionScheme="linear"
        onChange={setSelection}
      />
    </div>
  );
};

export default Body;
