import SatisfactionBar from 'components/SatisfactionBar';
import { usePatientFormikContext } from 'screens/Patients/store';
import styles from './styles.module.css';

const PatientSatisfactionCardBody = () => {
  const { selectedPatient } = usePatientFormikContext();

  return (
    <div className={styles.main}>
      <span>Satisfaction</span>
      <SatisfactionBar
        className={styles.satisfactionBar}
        value={Number(selectedPatient.satisfaction)}
      />
    </div>
  );
};

export default PatientSatisfactionCardBody;
