import { FC } from 'react';
import { RoleSignup } from 'types/ApiModels/Administration/Dashboard';
import RoleSignupCard from './RoleSignupCard';

interface RolesSignupsProps {
  roleSignups: RoleSignup[];
}

const RolesSignups: FC<RolesSignupsProps> = ({ roleSignups }: RolesSignupsProps) => {
  return (
    <div className="d-flex flex-column gap-sm">
      {roleSignups.map((rs, idx) => (
        <RoleSignupCard roleSignup={rs} key={idx} />
      ))}
    </div>
  );
};
export default RolesSignups;
