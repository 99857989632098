import GenericPatientTable from 'components/GenericPatientTable';
import React from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { setPatientActions } from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { partialUpdatePatientAction } from 'services/patientService';
import { mapActionToBreakdownRow } from './BreakdownRow';

const CarePlanBreakdownBody = () => {
  const { selectedPatient } = usePatientFormikContext();
  const {
    patientState: {
      patientCarePlan: { patientActions },
    },
    dispatch,
  } = usePatientContext();

  const onActiveChange = async (clickedId: number) => {
    const updatedPatientActions = [...patientActions];
    const actionIndex = updatedPatientActions.findIndex((action) => action.id === clickedId);

    const updatedActiveValue = !updatedPatientActions[actionIndex].is_active;
    updatedPatientActions[actionIndex] = {
      ...updatedPatientActions[actionIndex],
      is_active: updatedActiveValue,
    };

    await partialUpdatePatientAction(selectedPatient.id, updatedPatientActions[actionIndex].id, {
      is_active: !updatedPatientActions[actionIndex].is_active,
    });
    dispatch(setPatientActions(updatedPatientActions));
  };

  const mapPropsToTableContent = () => ({
    header: ['Name', 'days', 'Time of day', 'Status'],
    body: patientActions
      ?.filter((action) => action.is_part_of_rpm_plan)
      .map((action) => mapActionToBreakdownRow(action, () => onActiveChange(action.id))),
  });

  // Variables and constants
  const { header, body } = mapPropsToTableContent();

  return <GenericPatientTable header={header} body={body} className="m-0" />;
};

export default CarePlanBreakdownBody;
