import { FC } from 'react';
import { AdminPatientRow } from '../AdminPatientsMain/AdminPatientsTable/utils/adminPatientsTableUtils';

const AdminPatientProfileCompletedElement: FC<Partial<AdminPatientRow>> = ({
  profileCompleted: { completed, totalSections },
}: Partial<AdminPatientRow>) => {
  return (
    <div>
      {completed} out of {totalSections} sections
    </div>
  );
};
export default AdminPatientProfileCompletedElement;
