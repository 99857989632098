import {
  IPatientActionRecurrence,
  IPatientCarePlanAction,
} from 'types/ApiModels/Patients/CarePlan';
import { ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import { getAbsoluteDate } from './getAbsoluteDate';

export const transformToPatientAction = (
  action: ITemplateCarePlanAction,
  blockStartDate: Date
): IPatientCarePlanAction => {
  const absoluteStartDate = getAbsoluteDate(
    action.relative_start_date,
    blockStartDate
  ).toISOString();

  const absoluteRecurrence: IPatientActionRecurrence = !action.recurrence
    ? null
    : {
        ...(action.recurrence as IPatientActionRecurrence),
      };

  const absoluteAction: IPatientCarePlanAction = {
    ...(action as IPatientCarePlanAction),
    recurrence: absoluteRecurrence,
    is_part_of_rpm_plan: true,
    start_date: absoluteStartDate,
    end_date: absoluteStartDate,
  };

  return absoluteAction;
};
