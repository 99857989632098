import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';

import Card from 'components/Card';
import InputField from 'components/InputField';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { ReactComponent as EmptyChatsIcon } from 'assets/icons/chats_empty.svg';

import styles from './styles.module.css';
import DatePicker from 'components/DatePicker';
import DatePickerValue from 'types/Shared/DatePicker';
import EmptyList from 'components/EmptyList';
import { Role } from 'types/ApiModels/Administration';
import { MockBroadcastViaType } from 'screens/messages/mocks/types';
import { mockRoles, mockVia } from 'screens/messages/mocks/data';
import { useMessagesContext } from 'screens/messages/store/messages-context';

interface BroadcastMessageCardProps {
  children: React.ReactNode;
}

const BroadcastMessageCard: FC<BroadcastMessageCardProps> = ({
  children: controls,
}: BroadcastMessageCardProps) => {
  const {
    state: { openedBroadcast, openedBroadcastTemplate },
  } = useMessagesContext();

  const { pathname } = useLocation();

  const selectedBroadcast = useMemo(() => {
    if (pathname.includes('templates')) {
      return openedBroadcastTemplate;
    } else {
      return openedBroadcast;
    }
  }, [openedBroadcast, openedBroadcastTemplate, pathname]);

  const optionsToSelect = useCallback((options: { id: number; name: string }[]) => {
    return options.map((role) => ({ label: role.name, value: role }));
  }, []);

  //All this states later will be controlled by formik
  const [title, setTitle] = useState<string>('');
  const [broadcastTo, setBroadcastTo] = useState<Partial<Role>[]>([]);
  const [broadcastVia, setBroadcastVia] = useState<MockBroadcastViaType[]>([]);
  const [selectedDate, setSelectedDate] = useState<DatePickerValue>({
    year: 2022,
    month: 1,
    day: 1,
  });
  const [broadcastText, setBroadcastText] = useState<string>('');

  useEffect(() => {
    if (selectedBroadcast) {
      setTitle(selectedBroadcast.title);
      setBroadcastTo(selectedBroadcast.to);
      setBroadcastVia(selectedBroadcast.via);
      setSelectedDate(selectedBroadcast.time);
      setBroadcastText(selectedBroadcast.text);
    }
  }, [selectedBroadcast]);

  return (
    <Card
      className="bg-white"
      headers={[selectedBroadcast ? 'Broadcast message' : '']}
      bodyClassName="p-0"
      headerClassName={styles['with-separator']}
    >
      {selectedBroadcast ? (
        <>
          <div className={`${styles['container']} ${styles['with-separator']}`}>
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Title</div>
            <InputField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              containerClassName="justify-content-start gap align-items-center rounded"
              className={`vytrac-input rounded px-2 w-100 ${styles['input']}`}
            />
          </div>
          <div className={`${styles['container']} ${styles['with-separator']}`}>
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Broadcast to</div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <ReactSelect
                isMulti
                onChange={(newValues) => setBroadcastTo(newValues.map((v) => v.value))}
                options={optionsToSelect(mockRoles)}
                value={broadcastTo.map((v) => ({ label: v.name, value: v }))}
                styles={{
                  ...vytracSelectStyle,
                  control: (base, state) => ({
                    ...vytracSelectStyle.control(base, state),
                    ...styles,
                  }),
                  container: (base) => ({ ...base, maxWidth: 300 }),
                }}
              />
            </div>
          </div>
          <div className={`${styles['container']} ${styles['with-separator']}`}>
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Broadcast via</div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <ReactSelect
                isMulti
                onChange={(newValues) => setBroadcastVia(newValues.map((v) => v.value))}
                options={optionsToSelect(mockVia)}
                value={broadcastVia.map((v) => ({ label: v.name, value: v }))}
                styles={{
                  ...vytracSelectStyle,
                  control: (base, state) => ({
                    ...vytracSelectStyle.control(base, state),
                    ...styles,
                  }),
                  container: (base) => ({ ...base, maxWidth: 300 }),
                }}
              />
            </div>
          </div>
          <div className={`${styles['container']} ${styles['with-separator']}`}>
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Broadcast time </div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <DatePicker
                containerClassName={styles['date-picker']}
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
              />
            </div>
          </div>
          <div className={styles['container']}>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <textarea
                className={`vytrac-input rounded p-2`}
                value={broadcastText}
                onChange={(e) => setBroadcastText(e.target.value)}
              />
            </div>
          </div>
          {controls}
        </>
      ) : (
        <EmptyList
          className="p-5"
          Icon={EmptyChatsIcon}
          title="Select a broadcast"
          fill="#EBE3F1"
        />
      )}
    </Card>
  );
};
export default BroadcastMessageCard;
