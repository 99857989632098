import React from 'react';
import { RoundTagitem } from '../Tagitem';
import styles from './styles.module.css';

interface IOthers {
  medicalGroup: string[];
}
const Others = ({ medicalGroup }: IOthers) => (
  <div className="d-flex">
    {medicalGroup?.map((tag, index) => (
      //TODO: add better key (or revisit whether its ok)
      <div key={`${tag}-${index}`} className="d-flex">
        <RoundTagitem text={tag} containerClassNameList={[styles.roundTagItem]} />
      </div>
    ))}
  </div>
);

export default Others;
