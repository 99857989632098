import moment from 'moment';
import { Appointment, AppointmentType } from 'types/ApiModels/Appointments/Appointment';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { Activity, ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import { ITimesHours } from 'types/Shared/ITimesHours';
import { convertTimesHoursToString } from 'util/dateUtils';
import voiceIcon from 'assets/icons/phone_call_white.png';
import videoIcon from 'assets/icons/video_white.png';

export const activityTimelineMaxColumnsCount = 7;

export const mapActionsToActivities = (actions: IPatientTimelineAction[]): Activity[] =>
  actions.map((action: IPatientTimelineAction) => ({
    start_date: action.start_date,
    end_date: action.end_date,
    type: ActivityType.ACTION,
    id: action.id,
    action_props: action,
  }));

export const mapAppointmentsToActivities = (appointments: Appointment[]): Activity[] =>
  appointments.map((appointment: Appointment) => ({
    start_date: appointment.appointment_from,
    end_date: appointment.appointment_to,
    type: ActivityType.APPOINTMENT,
    id: appointment.id,
    appointment_props: appointment,
  }));

export const printBeginDate = (beginDate: string): string => {
  const momentBeginDate = moment(beginDate);
  const time: ITimesHours = { hour: momentBeginDate.hours(), minute: momentBeginDate.minutes() };

  return convertTimesHoursToString(time);
};

export const getComsIcon = (type: AppointmentType) =>
  type === AppointmentType.CALL ? (
    <img src={voiceIcon} alt="voice" />
  ) : (
    <img src={videoIcon} alt="video" />
  );
