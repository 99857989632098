import Card from 'components/Card';
import AddFormModal from './AddForm';
import DeleteAdminFormModal from './DeleteAdminFormModal';
import FormsTable from './FormsTable';
import Header from './Header';

const Forms = () => {
  return (
    <>
      <Card
        headers={[<Header key="consent-forms-header" />]}
        bodyClassName="p-0"
        className="bg-white m-3"
      >
        <FormsTable />
      </Card>
      <AddFormModal />
      <DeleteAdminFormModal />
    </>
  );
};

export default Forms;
