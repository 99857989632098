import VytracModal from 'components/Modal';
import { useCallback } from 'react';
import { providerActionTypeToString } from 'util/providerActionTypeToString';
import { setShowActionModal } from '../store/DashboardActionCreators';
import { useDashboardContext } from '../store/DashboardContext';
import ActionModalBody from './ActionModalBody';
import styles from './styles.module.css';

const ActionsModal = () => {
  const {
    state: { showActionModal, actionModalType },
    dispatch,
  } = useDashboardContext();
  const handleClose = useCallback(() => {
    dispatch(setShowActionModal(false, null, null));
  }, [dispatch]);

  return (
    <VytracModal
      title={providerActionTypeToString[actionModalType]}
      show={showActionModal}
      body={<ActionModalBody />}
      onClose={handleClose}
      size={actionModalType === 'provide_questionnaire' ? 'xl' : 'lg'}
      contentClassName={
        actionModalType === 'provide_questionnaire' ? styles['questionnaire-modal'] : null
      }
      dialogClassName={
        actionModalType === 'provide_questionnaire' ? styles['questionnaire-modal'] : null
      }
      bodyClassName={actionModalType === 'provide_questionnaire' ? styles['body'] : null}
    />
  );
};
export default ActionsModal;
