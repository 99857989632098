import { getIn } from 'formik';
import React from 'react';

export const getStyles = (errors, touched, name, important = false) => {
  let styles: React.CSSProperties = {
    height: '35px',
  };
  if (getIn(errors, name) && getIn(touched, name)) {
    styles = { ...styles, border: `1px solid #F5485C${important ? ' !important' : ''}` };
  }
  return styles;
};
