import React, { useEffect, useReducer } from 'react';
import { FormCheck } from 'react-bootstrap';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import DatePicker from '../../../../../../../../../../components/DatePicker';
import Select from '../../../../../../../../../../components/form/Select';
import { setActiveUntil, setActiveUntilDate } from '../../store/actions/actionCreators';
import { useActiveScheduleContext } from '../../store/context/ActiveScheduleContext';
import styles from './styles.module.css';

interface IActiveUntil {
  schedules: any;
}

const ActiveUntil = ({ schedules }: IActiveUntil) => {
  const { activeScheduleState, dispatch } = useActiveScheduleContext();

  const onChangeActiveUntil = (activeUntil: boolean) => {
    dispatch(setActiveUntil(activeUntil));
  };

  const onChangeActiveUntilDate = (activeUntilDate: Date) => {
    dispatch(setActiveUntilDate(activeUntilDate));
  };

  return (
    <div className={`d-flex flex-column mb-2 ${styles.activeContainer}`}>
      <div className={styles.container}>
        <div className={styles.text}>Keep schedule active until</div>
        <FormCheck
          type="switch"
          id={'active-switch'}
          checked={activeScheduleState.activeUntil}
          onChange={() => onChangeActiveUntil(!activeScheduleState.activeUntil)}
        />
      </div>
      {activeScheduleState.activeUntil && (
        <div className="d-flex flex-column pb-3">
          <div className={styles.rowContainer}>
            <div className={styles.title}>End Date</div>
            <div className={styles.content}>
              <DatePicker
                placeHolder="Select end date..."
                value={
                  activeScheduleState.activeUntilDate &&
                  convertDateToDatePickValue(activeScheduleState.activeUntilDate)
                }
                onChange={(value) => onChangeActiveUntilDate(convertDatepickToDate(value))}
                containerClassName={styles.datePickerContainer}
                inputClassName={styles.datePickerInput}
              />
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.title}>Change to</div>
            <Select
              containerClassName={styles.content}
              options={schedules}
              selectClassName={styles.select}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveUntil;
