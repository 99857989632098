import AdherenceGrid from 'components/AdherenceGrid';
import { useMemo } from 'react';
import Adherence from 'types/ApiModels/Patients/Adherence';
import { getAdherenceColor } from 'util/adherenceUtils';
import { diffStrDateDays } from 'util/dateUtils';
import styles from './styles.module.css';
interface AdherenceStatusProps {
  adherence: Adherence;
}
const AdherenceStatus = ({ adherence }: AdherenceStatusProps) => {
  const completedDays = useMemo(() => adherence.adherence_days.length, [adherence]);
  const totalDays = useMemo(
    () => diffStrDateDays(new Date().toISOString(), adherence.care_plan_from) ?? 0,
    [adherence]
  );

  const textColor = useMemo(() => getAdherenceColor(adherence), [adherence]);
  return (
    <div className={styles['container']}>
      <div style={completedDays / totalDays < 0.5 ? { color: textColor } : {}}>
        Completed {completedDays} days of {totalDays}
      </div>
      <AdherenceGrid adherence={adherence} />
    </div>
  );
};
export default AdherenceStatus;
