import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { IDashboardToDoSingle } from 'types/ApiModels/Dashboard/DashboardToDo';
import { IProviderActionResponse } from 'types/ApiModels/Providers/ProviderAction';
import { addSingleToDo, setShowActionModal } from '../store/DashboardActionCreators';
import { useDashboardContext } from '../store/DashboardContext';
import { initialState } from './formik/initialState';
import { onSubmit } from './formik/onSubmit';
import { validationSchema } from './formik/validationSchema';
import AssignBody from './ModalBodies/AssignBody';
import CompleteBody from './ModalBodies/CompleteBody';
import ProvideQuestionnaireBody from './ModalBodies/ProvideQuestionnaireBody/ProvideQuestionnaireBody';
import RequestAppointmentBody from './ModalBodies/RequestAppointment';
import SendAutomatedChatBody from './ModalBodies/SendAutomatedChatBody';
import VytalRequestBody from './ModalBodies/VytalRequestBody';
import styles from './styles.module.css';
export interface IModalBody {
  onCancel: () => void;
}
const ActionModalBody = () => {
  const {
    state: { actionModalType, openedAlertId, currentToDo },
    dispatch,
  } = useDashboardContext();

  const onCancel = useCallback(() => {
    dispatch(setShowActionModal(false, null, null));
  }, [dispatch]);

  const Body: React.FC<IModalBody> = useCallback(
    (props) => {
      switch (actionModalType) {
        case 'vytal_request':
          return <VytalRequestBody {...props} />;
        case 'provide_questionnaire':
          return <ProvideQuestionnaireBody {...props} />;
        case 'call_patient':
          return <div>Call Patient</div>;
        case 'request_appointment':
          return <RequestAppointmentBody {...props} />;
        case 'send_automated_chat':
          return <SendAutomatedChatBody {...props} />;
        case 'assign':
          return <AssignBody {...props} />;
        case 'complete':
          return <CompleteBody {...props} />;
        default:
          return null;
      }
    },
    [actionModalType]
  );

  const getUpdatedToDo = (actionOnAlert: IProviderActionResponse): IDashboardToDoSingle => {
    const { alert: updatedAlert } = actionOnAlert;
    const alertIndex = currentToDo.alerts.findIndex((alert) => alert.id === updatedAlert.id);

    const updatedAlerts = [...currentToDo.alerts];
    if (actionModalType === 'assign') {
      updatedAlerts.splice(alertIndex, 1);
    } else if (actionModalType === 'complete') {
      updatedAlerts[alertIndex] = {
        ...updatedAlerts[alertIndex],
        is_completed: updatedAlert.is_completed,
      };
    }
    // TODO: subtract alert count
    return { ...currentToDo, alerts: updatedAlerts };
  };

  const initialValues = useMemo(() => initialState[actionModalType], [actionModalType]);
  const schema = useMemo(() => validationSchema[actionModalType], [actionModalType]);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const actionToDoResponse = await onSubmit(values, actionModalType, openedAlertId);
          const updatedToDo = getUpdatedToDo(actionToDoResponse);
          dispatch(addSingleToDo(updatedToDo));
          dispatch(setShowActionModal(false, null, null));
        } catch (e) {
          throw e;
        }
      }}
      validationSchema={schema}
      enableReinitialize
    >
      <Form className={styles['form']}>
        <Body onCancel={onCancel} />
      </Form>
    </Formik>
  );
};
export default ActionModalBody;
