import React from 'react';
import { Table } from 'react-bootstrap';
import './styles.css';

interface IGenericTable {
  header: any;
  body: any;
  className?: string;
  thClassName?: string;
  tdClassName?: string;
  onIdClick?: (rowInfo: any) => void;
}

const GenericPatientTable = ({
  header,
  body,
  className,
  thClassName,
  tdClassName,
  onIdClick,
}: IGenericTable) => (
  <Table className={`patient-generic-table ${className}`} borderless size="sm">
    <thead>
      <tr>
        {header.map((headerItem) => (
          <th
            className={`font-size-small ${thClassName || ''}`}
            key={`generic-patient-table-header-item-${headerItem}`}
          >
            {headerItem}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {/* TODO: add a good key for this if possbile */}
      {body.map((rowInfo, idx) => (
        <tr key={`generic-patient-table-row-${idx}`}>
          {rowInfo.map((colInfo, index) => (
            <td
              onClick={() => (onIdClick && index === 0 ? onIdClick(rowInfo) : null)}
              className={`font-size-medium ${tdClassName || ''}`}
              //TODO: add a better key
              key={`generic-patient-table-cell-${index}`}
            >
              {colInfo}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);

export default GenericPatientTable;
