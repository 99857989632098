import moment from 'moment';
import { IPatientQuestionnaire } from 'types/ApiModels/Patients/PatientQuestionnaire';

export const mapApiToPatientQuestionnaire = (
  patientQuestionnaires: IPatientQuestionnaire[] = []
) => {
  const questionnaireMap = {};

  patientQuestionnaires.forEach((patientQuestionnaire) => {
    const key = patientQuestionnaire.template_id || patientQuestionnaire.id;
    if (!questionnaireMap[key]) {
      questionnaireMap[key] = {
        key: key,
        id: patientQuestionnaire.id,
        name: patientQuestionnaire.name || 'no-name',
        usedFor: patientQuestionnaire.population || '',
        questions: patientQuestionnaire.questions || 'no-questions',
        completed: patientQuestionnaire.completed_date ? '1/1' : '0/1',
        lastSent: moment(patientQuestionnaire.creation_date).format('MM.DD.YYYY') || '',
        lastResult: patientQuestionnaire.completed_date
          ? moment(patientQuestionnaire.completed_date).format('MM.DD.YYYY')
          : '-',
        instances: [],
      };
    } else {
      const newLastSent = moment(patientQuestionnaire.creation_date).format('MM.DD.YYYY');
      const newLastResult = moment(patientQuestionnaire.completed_date).format('MM.DD.YYYY');
      const newWasCompleted = !!patientQuestionnaire.completed_date;
      const lastCompleted = parseInt(questionnaireMap[key].completed.split('/')[0]);
      const total = parseInt(questionnaireMap[key].completed.split('/')[1]);
      questionnaireMap[key] = {
        ...questionnaireMap[key],
        lastResult: moment(questionnaireMap[key].lastResult).isBefore(newLastResult)
          ? newLastResult
          : questionnaireMap[key].lastResult,
        lastSent: moment(questionnaireMap[key].lastSent).isBefore(newLastSent)
          ? newLastSent
          : questionnaireMap[key].lastSent,
        completed: `${newWasCompleted ? lastCompleted + 1 : lastCompleted}/${total + 1}`,
      };
    }
    questionnaireMap[key].instances.push(patientQuestionnaire);
  });
  return Object.values(questionnaireMap);
};
