import { Dispatch, useEffect } from 'react';
import {
  getEmailSettings,
  getEmailSettingsShortcodes,
  getEmailSettingsTypes,
} from 'services/administrationService';
import { useAdministrationContextV2 } from '..';
import { AllAdminActions } from '../administrationReducer';
import { EmailSettingsState } from '../EmailSettings';
import {
  setEmailSettings,
  setEmailSettingsShortcodes,
  setEmailSettingTypes,
} from '../EmailSettings/emailSettingActionCreators';

export const useAdminEmailSettings = (): [
  state: EmailSettingsState,
  dispatch: Dispatch<AllAdminActions>
] => {
  const {
    administrationStateV2: { emailSettings },
    dispatch,
    fetchRegistry,
  } = useAdministrationContextV2();

  useEffect(() => {
    if (emailSettings.list || fetchRegistry.current.emailSettings.list) return;
    (async () => {
      fetchRegistry.current.emailSettings.list = true;
      const settings = await getEmailSettings();
      dispatch(setEmailSettings(settings));
      fetchRegistry.current.emailSettings.list = false;
    })();
  }, [dispatch, emailSettings.list, fetchRegistry]);

  useEffect(() => {
    if (emailSettings.shortCodes || fetchRegistry.current.emailSettings.shortCodes) return;
    (async () => {
      fetchRegistry.current.emailSettings.shortCodes = true;
      const shortcodes = await getEmailSettingsShortcodes();
      dispatch(setEmailSettingsShortcodes(shortcodes));
      fetchRegistry.current.emailSettings.shortCodes = false;
    })();
  }, [dispatch, emailSettings.shortCodes, fetchRegistry]);

  useEffect(() => {
    if (emailSettings.types || fetchRegistry.current.emailSettings.types) return;
    (async () => {
      fetchRegistry.current.emailSettings.types = true;
      const types = await getEmailSettingsTypes();
      dispatch(setEmailSettingTypes(types));
      fetchRegistry.current.emailSettings.types = false;
    })();
  }, [dispatch, emailSettings.types, fetchRegistry]);

  return [emailSettings, dispatch];
};
