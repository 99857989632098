import React, { useEffect, useState } from 'react';
import Card from '../../../../../../../../../../../../components/Card';
import GenericTable from '../../../../../../../../../../../../components/GenericTable';
import ActivityTrackerModal from './ActivityTrackerModal/ActivityTrackerModal';
import styles from './styles.module.css';
const Tab = ({ data }) => {

  const mapActionByStatus = (status) => {
    return status === 'Eligible' ? 'Submit' : 'Pending';
  };
  const [showModal, setShowModal] = useState<boolean>(false);

  const columns = [
    {
      title: 'Code',
      key: 'name',
      render: (row) => <div onClick={() => setShowModal(true)} className={styles.codeName}>{row.name}</div>,
      style: {
        width: '20%',
      },
    },
    {
      title: 'Category',
      key: 'category',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Value',
      key: 'value',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Time spent',
      key: 'timeSpent',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Due bill date',
      key: 'dueBillDate',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Last bill date',
      key: 'lastBillDate',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Date of service',
      key: 'dateOfService',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Providers',
      key: 'providers',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Required time',
      key: 'requiredTime',
      style: {
        paddingRight: '80px',
      },
    },
    {
      title: 'Status',
      key: 'status',
      style: {
        paddingRight: '73px',
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row) => (
        <div onClick={() => setShowModal(true)} className={`${row.status === 'Eligible' ? styles.actions : ''}`}>
          {mapActionByStatus(row.status)}
        </div>
      ),
    },
  ];

  return (
    <Card bodyClassName="p-0">
      <GenericTable
        tableClassName="mb-0"
        rowClassName={styles['row']}
        columns={columns}
        data={data}
      />
      <ActivityTrackerModal handleClose={() => setShowModal(false)} showModal={showModal} data={[]} handleChange={()=> {}}></ActivityTrackerModal>
    </Card>

  );
};

export default Tab;
