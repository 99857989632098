export const mockedAlertsData = [
  {
    reason: 'Current SpO2 at 46%',
    occurrences: 5,
    status: 'Escalated to Hugo Strange',
    lastInteraction: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
    type: 'respiratory',
    category: 'SpO2',
    severity: 'critical',
  },
  {
    reason: 'Current SpO2 at 96%',
    occurrences: 15,
    status: 'Escalated to Hugo Strange',
    lastInteraction: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
    type: 'respiratory',
    category: 'SpO2',
    severity: 'critical',
  },
  {
    reason: 'Current SpO2 at 96%',
    occurrences: 25,
    status: 'Escalated to Hugo Strange',
    lastInteraction: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
    type: 'respiratory',
    category: 'SpO2',
    severity: 'critical',
  },
  {
    reason: 'Current SpO2 at 97%',
    occurrences: 10,
    lastInteraction: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
    type: 'respiratory',
    category: 'SpO2',
    severity: 'medium',
  },
  {
    reason: 'BPM is 120',
    occurrences: 50,
    lastInteraction: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
    type: 'cardiac',
    category: 'BPM',
    severity: 'low',
  },
  {
    reason: 'Current SpO2 at 46%',
    occurrences: 20,
    lastInteraction: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
    type: 'respiratory',
    category: 'SpO2',
    severity: 'low',
  },
];

export const mockedPopulationsData = [
  {
    reason: 'High BP',
    occurrences: 10,
    category: 'High BP',
    status: 'Escalated to Hugo Strange',
    addedBy: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
  },
  {
    reason: 'Joint Pain',
    occurrences: 21,
    category: 'Joint Pain',
    status: 'Escalated to Hugo Strange',
    addedBy: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
  },
  {
    reason: 'Osteoporosis',
    occurrences: 40,
    category: 'Osteoporosis',
    status: 'Escalated to Hugo Strange',
    addedBy: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
  },
  {
    reason: 'Hypertension',
    occurrences: 30,
    category: 'Hypertension',
    addedBy: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
  },
  {
    reason: 'Depression',
    occurrences: 10,
    category: 'Depression',
    addedBy: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
  },
  {
    reason: 'Osteoporosis',
    occurrences: 2,
    category: 'Osteoporosis',
    status: 'Escalated to Hugo Strange',
    addedBy: 'Thomas Wayne',
    date: new Date(2021, 10, 5, 15, 25),
  }
];
