import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface DialogContextState {
  dialog: () => JSX.Element;
  setDialog: Dispatch<SetStateAction<() => JSX.Element>>;
}

export const DialogContext = createContext<DialogContextState>(null);

export const useDialogContext = () => useContext(DialogContext);
