import { useMemo } from 'react';
import { YellowAlert } from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { formatVytracDateNoYear } from 'util/dateUtils';
import { rpmActionToUnit } from 'util/rpmActionToUnit';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import styles from './styles.module.css';
interface AlertEventProps {
  alert: YellowAlert;
}
const AlertEvent = ({ alert }: AlertEventProps) => {
  const Icon = useMemo(() => {
    return rpmActionTypeToIcon[alert.vytal.type];
  }, [alert]);

  const dottedDate = useMemo(() => {
    return formatVytracDateNoYear(new Date(alert.created_at));
  }, []);

  const value = useMemo(
    () => `${parseFloat(alert.vytal.value).toFixed(0)} ${rpmActionToUnit[alert.vytal.type]}`,
    [alert]
  );

  return (
    <div className={styles['event']}>
      <div className={styles['event-status-container']}>
        <div
          className="alert-item-container mr-3"
          style={{ minWidth: 26, width: 26, border: '1px solid #F8CA54' }}
        >
          <Icon width="12px" height="11px" color="#F8CA54" />
        </div>
        <div className={styles['event-status']}>{value}</div>
      </div>
      <div className={styles['date']}>
        {dottedDate} · {alert.goal_umbral_type} = {alert.goal_umbral_value}
      </div>
    </div>
  );
};
export default AlertEvent;
