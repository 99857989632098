import Card from 'components/Card';
import Warning from 'components/Icons/Warning';
import { FC } from 'react';
import colors from 'styles/colors';
import RegisterLayout from '../RegisterLayout';
import styles from '../styles.module.css';

const InvalidInvitationFallback: FC = () => {
  return (
    <RegisterLayout>
      <Card
        headers={[
          <div key="invalid-invitation" className="d-flex gap-lg align-items-center">
            <div>
              <Warning width="21" height="20" color={colors.mediumRiskAlerts} />
            </div>
            <p className="m-0 p-0 font-size-large">Invalid invitation</p>
          </div>,
        ]}
        className={`vytrac-card ${styles['register-card']}`}
      >
        <p className="m-0 p-0 font-size-big">
          The invitation link is invalid or it has already been used
        </p>
      </Card>
    </RegisterLayout>
  );
};
export default InvalidInvitationFallback;
