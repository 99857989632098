import { AlertImportanceOptions } from 'components/AlertIcons/utils/AlertOptions';
import Card from 'components/Card';
import PatientEvent from 'components/patient/PatientEvent';
import React, { useRef, useState } from 'react';
import { mockEvent } from 'screens/ProviderDashboard/utils';
import styles from './styles.module.css';
import EventCardHeader from './event-card-header';
import SendMessageBox from 'components/SendMessageBox';
import QuestionnaireDetails from './questionnaire-details';
import CardComment, { IComment } from 'components/CardComment/CardComment';

interface IEventCard {
  event?: PatientEvent;
  eventIdx?: number;
  handleEventCardClick?: (eventIdx: number) => void;
  comments: IComment[];
  questionnaire?: boolean;
}

const EventCard = ({ eventIdx = 0, comments = [], questionnaire = false }: IEventCard) => {
  const heartRateEvent: mockEvent = {
    type: 'heart rate',
    title: 'Pulse 150 bpm',
    text: '3 days ago • 2pm • High Alert Limit = 120',
    importance: AlertImportanceOptions.CRITICAL,
  };
  const questionnaireEvent: mockEvent = {
    type: 'questionnaire',
    title: 'COPD Questionnaire',
    text: 'Questionnaire (10 answers of 24 critical)',
    importance: AlertImportanceOptions.YELLOW,
  };

  const [openCard, setOpenCard] = useState<boolean>(false);
  const cardContainerRef = useRef<HTMLDivElement>();

  const changeCardState = () => {
    setOpenCard(!openCard);
  };

  const shadowEffect: React.CSSProperties = {
    filter: 'drop-shadow(0px 5px 20px rgba(173, 182, 189, 0.4))',
  };

  const openCardContainerStyle: React.CSSProperties = {
    maxHeight: `${cardContainerRef.current?.scrollHeight}px`,
    marginBottom: 15,
  };

  const handleSendMessage = () => undefined;

  return (
    <Card
      className={`d-flex flex-column flex-grow-1 card-bg-border ${styles.cardContainer}`}
      bodyClassName={styles.cardBody}
      style={openCard ? shadowEffect : {}}
      onClick={changeCardState}
    >
      <EventCardHeader
        key={0}
        comments={comments}
        event={questionnaire ? questionnaireEvent : heartRateEvent}
      />
      <div
        ref={cardContainerRef}
        className={styles.cardContent}
        style={openCard ? openCardContainerStyle : {}}
      >
        {questionnaire && <QuestionnaireDetails />}
        {comments.map((comment: IComment, commentIdx: number) => (
          <CardComment cardComment={comment} key={commentIdx} />
        ))}
        <SendMessageBox handleSendMessage={handleSendMessage} />
      </div>
    </Card>
  );
};
export default EventCard;
