import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface IDisabledCalendarModal {
  show: boolean;
  onConfirm: () => void;
}
const DisabledCalendarModal = ({ show, onConfirm }: IDisabledCalendarModal) => {
  return (
    <VytracModal
      show={show}
      title="Disabled calendar"
      body={
        <span className="font-size-big">
          Set a template name and click on <b>Create template</b> to be able to create actions
        </span>
      }
      footer={<Button label="confirm" variant="confirm" onClick={onConfirm} />}
      onClose={onConfirm}
    />
  );
};
export default DisabledCalendarModal;
