import { UmbralType, IUmbralValue, LimitType } from 'types/ApiModels/Patients/Goal';
import { ThresholdFormikKeys } from './thresholdFormikUtils';

const mapKeyToUmbralValues = (key: ThresholdFormikKeys): IUmbralValue[] => {
  switch (key) {
    case ThresholdFormikKeys.UPPER_RED_ZONE_TOP: {
      return [{ type: UmbralType.RED_ZONE_UPPER, limitType: LimitType.MAX }];
    }

    case ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM: {
      return [
        { type: UmbralType.RED_ZONE_UPPER, limitType: LimitType.MIN },
        { type: UmbralType.YELLOW_ZONE_UPPER, limitType: LimitType.MAX },
      ];
    }

    case ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM: {
      return [
        { type: UmbralType.YELLOW_ZONE_UPPER, limitType: LimitType.MIN },
        { type: UmbralType.NORMAL_ZONE, limitType: LimitType.MAX },
      ];
    }

    case ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP: {
      return [
        { type: UmbralType.NORMAL_ZONE, limitType: LimitType.MIN },
        { type: UmbralType.YELLOW_ZONE_LOWER, limitType: LimitType.MAX },
      ];
    }

    case ThresholdFormikKeys.LOWER_RED_ZONE_TOP: {
      return [
        { type: UmbralType.YELLOW_ZONE_LOWER, limitType: LimitType.MIN },
        { type: UmbralType.RED_ZONE_LOWER, limitType: LimitType.MAX },
      ];
    }

    case ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM: {
      return [{ type: UmbralType.RED_ZONE_LOWER, limitType: LimitType.MIN }];
    }
  }
};
export default mapKeyToUmbralValues;
