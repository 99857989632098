import { FC } from 'react';
import SVGIconProps from 'types/Shared/SVGIconProps';

const BloodPressureIcon: FC<SVGIconProps> = ({ width, height, color }: SVGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57667 5.41237C7.88864 4.72346 7.50961 3.807 7.50961 2.83208C7.50961 2.59853 7.53175 2.37017 7.57386 2.14863C6.81909 2.17841 6.06298 2.48408 5.42333 3.123C4.75144 2.45184 3.97863 2.14754 3.14401 2.14754C2.30366 2.14754 1.51361 2.47424 0.919652 3.06755C-0.306551 4.29241 -0.306551 6.2854 0.919652 7.51053L5.13346 11.7194C5.29427 11.88 5.55349 11.8795 5.71375 11.7194L9.92701 7.5108C10.2347 7.20377 10.472 6.84183 10.629 6.44492C9.85373 6.33211 9.13891 5.97536 8.57667 5.41237ZM8.25614 6.93115H6.99385L6.18166 8.46605C6.0174 8.77718 5.56303 8.74918 5.43646 8.42234L4.39975 5.74481L3.88153 6.71426C3.81016 6.84784 3.67095 6.93115 3.51974 6.93115H2.32444C2.09773 6.93115 1.91425 6.74759 1.91425 6.52141C1.91425 6.29496 2.09776 6.11167 2.32444 6.11167H3.27362L4.09757 4.56994C4.26278 4.26192 4.71537 4.28842 4.84222 4.61528L5.87702 7.28817L6.38403 6.32992C6.45512 6.19553 6.5946 6.11167 6.74663 6.11167H8.25614C8.48285 6.11167 8.66633 6.29496 8.66633 6.52141C8.66636 6.74759 8.48285 6.93115 8.25614 6.93115Z"
        fill={color}
      />
      <path
        d="M11.5541 2.84383C11.5541 3.06427 11.3745 3.24374 11.1535 3.24374C10.9325 3.24374 10.7529 3.06427 10.7529 2.84383C10.7529 2.62312 10.9326 2.44365 11.1535 2.44365C11.3745 2.44365 11.5541 2.62312 11.5541 2.84383Z"
        fill={color}
      />
      <path
        d="M11.165 0.000488281C9.61691 0.000488281 8.33 1.25212 8.33 2.83208C8.33 4.39375 9.59178 5.66395 11.165 5.66395C12.7284 5.66395 14 4.39375 14 2.83208C14 1.27069 12.7284 0.000488281 11.165 0.000488281ZM12.4957 2.08253L12.2567 2.32127C12.3321 2.47971 12.3745 2.65699 12.3745 2.84383C12.3745 3.51608 11.8268 4.06322 11.1535 4.06322C10.4802 4.06322 9.93248 3.51608 9.93248 2.84383C9.93248 2.17131 10.4802 1.62416 11.1535 1.62416C11.3405 1.62416 11.5177 1.6665 11.6764 1.7419L11.9154 1.50315C12.0756 1.34308 12.3354 1.34308 12.4957 1.50315C12.6559 1.66322 12.6559 1.92246 12.4957 2.08253Z"
        fill={color}
      />
    </svg>
  );
};

export default BloodPressureIcon;
