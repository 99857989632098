import DatePicker from '@amir04lm26/react-modern-calendar-date-picker';
import { Field, useField, useFormikContext } from 'formik';
import { CSSProperties, FC, ReactNode, RefObject } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, formatDottedDate } from 'util/dateUtils';

interface DatePickerFormikProps {
  name: string;
  inputClassName?: string;
  styles?: CSSProperties;
  children?: ReactNode;
  inputContainerClass?: string;
  inputComputedStyle?: CSSProperties;
  maximumDate?: DatePickerValue;
}

const DatePickerFormik: FC<DatePickerFormikProps> = ({
  name,
  inputClassName = 'px-2 vytrac-input',
  styles,
  children,
  inputContainerClass,
  inputComputedStyle = {},
  maximumDate,
}: DatePickerFormikProps) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field name={name}>
      {({ field }) => (
        <DatePicker
          value={field.value}
          onChange={(datepickValue: DatePickerValue) => {
            setFieldValue(name, datepickValue);
          }}
          maximumDate={maximumDate}
          renderInput={({ ref }) => (
            <div className={inputContainerClass} style={inputComputedStyle}>
              <input
                readOnly
                className={inputClassName}
                style={styles}
                name={name}
                ref={ref as RefObject<HTMLInputElement>}
                value={field.value ? formatDottedDate(convertDatepickToDate(field.value)) : ''}
              />
              {children}
            </div>
          )}
        />
      )}
    </Field>
  );
};
export default DatePickerFormik;
