import { setBreadcrumTitle } from 'util/themeUtils';
import AnalyticsTabEnum from './AnalyticsTabEnum';
import TabRouteMap from './TabRouteMap';

export const analyticsInitialBreadCrumbPathList = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'Analytics',
    url: TabRouteMap[AnalyticsTabEnum.ELIGIBILITY_AND_ALERTS],
  },
];
