import { Reducer } from 'react';
import { MessagesAction, MessagesTypes } from './messages-action-types';
import { MessagesState } from './messages-context';

const messagesReducer: Reducer<MessagesState, MessagesAction> = (state, action): MessagesState => {
  switch (action.type) {
    case MessagesTypes.SET_SELECTED_PATIENT: {
      return { ...state, selectedPatient: action.payload.selectedPatient };
    }
    case MessagesTypes.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case MessagesTypes.SWITCH_LAST_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: [...state.breadcrumbs.slice(0, -1), action.payload],
      };
    case MessagesTypes.SET_ACTIONS:
      return {
        ...state,
        Actions: action.payload,
      };
    case MessagesTypes.TOGGLE_NEW_CONVERSATION_MODAL:
      return {
        ...state,
        newConversationModal: !state.newConversationModal,
      };
    case MessagesTypes.CLEAR_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: null,
      };
    case MessagesTypes.SET_PATIENT_LIST:
      return {
        ...state,
        patientList: action.payload,
      };

    case MessagesTypes.SET_SELECTED_CHAT:
      return {
        ...state,
        selectedConversationSID: action.payload.conversationSID,
      };
    case MessagesTypes.SET_BROADCASTS:
      return {
        ...state,
        broadcasts: action.payload,
      };
    case MessagesTypes.SET_BROADCAST_TEMPLATES:
      return {
        ...state,
        broadcastTemplates: action.payload,
      };
    case MessagesTypes.ADD_TO_SELECTED_BROADCAST:
      return {
        ...state,
        selectedBroadcast: [...state.selectedBroadcast, action.payload],
      };
    case MessagesTypes.REMOVE_FROM_SELECTED_BROADCAST:
      return {
        ...state,
        selectedBroadcast: state.selectedBroadcast.filter((id) => id !== action.payload),
      };
    case MessagesTypes.SET_OPENED_BROADCAST:
      return {
        ...state,
        openedBroadcast: action.payload,
      };
    case MessagesTypes.SET_OPENED_BROADCAST_TEMPLATE:
      return {
        ...state,
        openedBroadcastTemplate: action.payload,
      };

    case MessagesTypes.SET_METADATA_MAPPER: {
      return { ...state, getMetadataByParticipantSID: action.payload.getMetadataByParticipantSID };
    }
    default:
      throw new Error('Reducer was called with an unidentified action type');
  }
};

export default messagesReducer;
