import { setBreadcrumTitle } from "util/themeUtils";

export const patientsInitialBreadcrumbPathList = [
  {
    title: `${setBreadcrumTitle()} Dashboard`,
    url: '/dashboard',
  },
  {
    title: 'patients',
    url: '/patients',
  },
];
