import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { setBreadcrumbPathList } from 'util/commonContext/NavigationContext';
import BackButton from 'components/BackButton';
import Card from 'components/Card';
import GenericTable from 'components/GenericTable';
import { getQuestionnaireReplies } from 'services/questionnaireService';
import QuestionnaireContext from '../../util/Context';
import Header from '../Header';
import ReplyDetail from './components/ReplyDetail';
import styles from './styles.module.css';

const QuestionnaireDetails = () => {
  const { questionnaireState } = useContext(QuestionnaireContext);
  const {
    dispatch,
    patientState: {
      patientNavigation: { breadcrumbPathList },
    },
  } = usePatientContext();
  // const { setState } = useContext(Context);
  const { selectedQuestionnaire } = questionnaireState;
  const [opened, setOpened] = React.useState(false);
  const [replyData, setReplyData] = React.useState([]);
  const [selectedReply, setSelectedReply] = React.useState(null);

  useEffect(() => {
    dispatch(
      setBreadcrumbPathList([
        ...breadcrumbPathList,
        {
          title: questionnaireState.selectedQuestionnaire?.name,
          url: `/patients/${questionnaireState.selectedQuestionnaire?.id}`,
        },
      ])
    );
  }, []);

  useEffect(() => {
    const fetchReplies = async (id) => {
      const data = await getQuestionnaireReplies(id);
      setReplyData((prev) => [...prev, data]);
    };
    questionnaireState.selectedQuestionnaire?.instances.forEach((instance) => {
      if (instance.completed || instance.completed_date) fetchReplies(instance.id);
    });
  }, [
    questionnaireState.selectedQuestionnaire,
    questionnaireState.selectedQuestionnaire?.instances,
  ]);
  const columns = [
    {
      title: 'Completed',
      key: 'date',
      style: { width: '80%' },
      render: ({ date, idx }) => (
        <div
          className={styles.name}
          onMouseDown={(e) => {
            if (!opened) {
              setOpened(true);
              e.stopPropagation();
            }
            setSelectedReply(replyData[idx]);
          }}
        >
          {moment(date).format('MM.DD.YYYY')}
        </div>
      ),
    },
    {
      title: 'Critical responses',
      key: 'critical',
    },
    {
      title: 'Questions',
      key: 'questionLen',
      style: { width: '3%' },
    },
  ];

  return (
    <div>
      <BackButton
        label={'Back to all questionnaires'}
        color="#1890FF"
        className={styles.backContainer}
      />
      <div className={styles.container}>
        <Card headers={[<Header title={selectedQuestionnaire?.name} />]}>
          <GenericTable columns={columns} data={replyData} />
        </Card>
      </div>
      {selectedReply && (
        <ReplyDetail opened={opened} data={selectedReply} closePanel={() => setOpened(false)} />
      )}
    </div>
  );
};

export default QuestionnaireDetails;
